import React, { useState } from "react";
import { Card, Radio } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import VirtualTablePrice from './VirtualTablePrice';
import * as config from '../../../../../../../config';

interface TablePriceProps extends React.Attributes {
    exchange?: string,
    stockType?: string,
    priceRatio?: number,
    volumeRatio?: number,
    stocks: NSApp.Stock[] | null,
    extendColumns?: ColumnsType<NSApp.Stock>,
    preColumns?: ColumnsType<NSApp.Stock>,
    columns?: ColumnsType<NSApp.Stock>,
    height?: number,
    glContainer?: {
        height: number,
        width: number
    },
    tableProps?: any,
    loading?: boolean,
    changeTradingView?: (stockSymbol: string) => void,
};

const TABLE_DEFAULT_HEIGHT = 500;
const TAB_HEADER_HEIGHT = 80;

const TablePrice = (props: TablePriceProps) => {
    const { glContainer, height, loading, changeTradingView } = props;
    const { t } = useTranslation();
    const [ priceChangeType, setPriceChangeType ] = useState<string>('pricePercentChange')

    let layoutHeight = height || glContainer?.height || TABLE_DEFAULT_HEIGHT;

    layoutHeight = layoutHeight - config.HEADER_HEIGHT - TAB_HEADER_HEIGHT;

    let columns: ColumnsType<NSApp.Stock> = [
        {
            title: t('Mã CK'),
            key: 'stockCode',
            width: 75,
            defaultSortOrder: 'ascend',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.stockCode.localeCompare(b.stockCode),
            showSorterTooltip: false,
        },
        {
            title: t('Trần'),
            className: 'highlight-column',
            key: 'ceilingPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.ceilingPrice - b.ceilingPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Sàn'),
            className: 'highlight-column',
            key: 'floorPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.floorPrice - b.floorPrice,
            showSorterTooltip: false,
        },
        {
            title: t('TC'),
            className: 'highlight-column',
            key: 'referencePrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.referencePrice - b.referencePrice,
            showSorterTooltip: false,
        },
        {
            title: t('Bên mua'),
            children: [
                {
                    title: t('Giá 3'),
                    key: 'best3Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3Bid - b.best3Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 3'),
                    key: 'best3BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3BidVol - b.best3BidVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 2'),
                    key: 'best2Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2Bid - b.best2Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 2'),
                    key: 'best2BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2BidVol - b.best2BidVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 1'),
                    key: 'best1Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1Bid - b.best1Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 1'),
                    key: 'best1BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1BidVol - b.best1BidVol,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Khớp lệnh'),
            children: [
                {
                    title: t('Giá'),
                    key: 'matchedPrice',
                    className: 'highlight-column',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.matchedPrice - b.matchedPrice,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL'),
                    className: 'highlight-column',
                    key: 'volume',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.volume - b.volume,
                    showSorterTooltip: false,
                },
                {
                    title: () => <span>{priceChangeType === 'pricePercentChange' ? '%' : '+/-'}</span>,
                    filterDropdown: () => {
                        return <Radio.Group onChange={(e) => setPriceChangeType(e.target.value)} value={priceChangeType}>
                            <Radio value={'pricePercentChange'}>+/-(%)</Radio>
                            <Radio value={'priceChange'}>+/-</Radio>
                        </Radio.Group>
                    },
                    filterIcon: <CaretRightOutlined />,
                    key: priceChangeType,
                    className: 'highlight-column',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.pricePercentChange - b.pricePercentChange,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Bên bán'),
            children: [
                {
                    title: t('Giá 1'),
                    key: 'best1Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1Offer - b.best1Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 1'),
                    key: 'best1OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1OfferVol - b.best1OfferVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 2'),
                    key: 'best2Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2Offer - b.best2Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 2'),
                    key: 'best2OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2OfferVol - b.best2OfferVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 3'),
                    key: 'best3Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3Offer - b.best3Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 3'),
                    key: 'best3OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3OfferVol - b.best3OfferVol,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Cao'),
            className: 'highlight-column',
            key: 'highestPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.highestPrice - b.highestPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Thấp'),
            className: 'highlight-column',
            key: 'lowestPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.lowestPrice - b.lowestPrice,
            showSorterTooltip: false,
        },
        {
            title: t('TB'),
            className: 'highlight-column',
            key: 'averagePrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.lowestPrice - b.lowestPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Tổng KL'),
            key: 'totalVolume',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.totalVolume - b.totalVolume,
            showSorterTooltip: false,
        },
        {
            title: t('ĐTNN'),
            children: [
                {
                    title: t('Mua'),
                    key: 'foreignBuyVolume',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.foreignBuyVolume - b.foreignBuyVolume,
                    showSorterTooltip: false,
                },
                {
                    title: t('Bán'),
                    key: 'foreignSellVolume',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.foreignSellValue - b.foreignSellValue,
                    showSorterTooltip: false,
                },
                // {
                //     title: 'Dư',
                //     key: 'remainForeignRoom',
                //     sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.ratioRemainForeignRoom - b.ratioRemainForeignRoom,
                //     showSorterTooltip: false,
                // }
            ]
        },
    ];

    let derivativeColumns: ColumnsType<NSApp.Stock> = [
        {
            title: t('Mã CK'),
            width: 75,
            key: 'stockCode',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.stockCode.localeCompare(b.stockCode),
            showSorterTooltip: false,

        },
        {
            title: t('Ngày ĐH'),
            width: 85,
            key: 'maturityDate',
            // sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.ceilingPrice - b.ceilingPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Phiên'),
            key: 'marketStatus',
            // sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.floorPrice - b.floorPrice,
            render: (marketStatus) => {
                return <span>{marketStatus}</span>
            },
            showSorterTooltip: false,
        },
        {
            title: t('OI'),
            key: 'openInterest',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.openInterest - b.openInterest,
            render: (openInterest) => {
                return <span>{openInterest}</span>
            },
            showSorterTooltip: false,
        },
        {
            title: t('Trần'),
            className: 'highlight-column',
            key: 'ceilingPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.ceilingPrice - b.ceilingPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Sàn'),
            className: 'highlight-column',
            key: 'floorPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.floorPrice - b.floorPrice,
            showSorterTooltip: false,
        },
        {
            title: t('TC'),
            className: 'highlight-column',
            key: 'referencePrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.referencePrice - b.referencePrice,
            showSorterTooltip: false,
        },
        {
            title: t('Độ lệch'),
            key: 'differentValue',
            // sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.differentValue - b.differentValue,
            showSorterTooltip: false,
        },
        {
            title: t('Bên mua'),
            children: [
                {
                    title: t('Giá 3'),
                    key: 'best3Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3Bid - b.best3Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 3'),
                    key: 'best3BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3BidVol - b.best3BidVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 2'),
                    key: 'best2Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2Bid - b.best2Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 2'),
                    key: 'best2BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2BidVol - b.best2BidVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 1'),
                    key: 'best1Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1Bid - b.best1Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 1'),
                    key: 'best1BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1BidVol - b.best1BidVol,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Khớp lệnh'),
            children: [
                {
                    title: t('Giá'),
                    key: 'matchedPrice',
                    className: 'highlight-column',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.matchedPrice - b.matchedPrice,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL'),
                    className: 'highlight-column',
                    key: 'volume',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.volume - b.volume,
                    showSorterTooltip: false,
                },
                {
                    title: t('+/-'),
                    key: 'priceChange',
                    className: 'highlight-column',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.priceChange - b.priceChange,
                    showSorterTooltip: false,
                }
            ]
        },
        {
            title: t('Bên bán'),
            children: [
                {
                    title: t('Giá 1'),
                    key: 'best1Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1Offer - b.best1Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 1'),
                    key: 'best1OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1OfferVol - b.best1OfferVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 2'),
                    key: 'best2Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2Offer - b.best2Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 2'),
                    key: 'best2OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2OfferVol - b.best2OfferVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 3'),
                    key: 'best3Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3Offer - b.best3Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 3'),
                    key: 'best3OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3OfferVol - b.best3OfferVol,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Tổng KL'),
            key: 'totalVolume',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.totalVolume - b.totalVolume,
            showSorterTooltip: false,
        },
        {
            title: t('Cao'),
            className: 'highlight-column',
            key: 'highestPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.highestPrice - b.highestPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Thấp'),
            className: 'highlight-column',
            key: 'lowestPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.lowestPrice - b.lowestPrice,
            showSorterTooltip: false,
        },
        {
            title: t('ĐTNN'),
            children: [
                {
                    title: t('Mua'),
                    key: 'foreignBuyVolume',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.foreignBuyVolume - b.foreignBuyVolume,
                    showSorterTooltip: false,
                },
                {
                    title: t('Bán'),
                    key: 'foreignSellVolume',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.foreignSellValue - b.foreignSellValue,
                    showSorterTooltip: false,
                },
            ]
        },
    ];

    let warrantColumns: ColumnsType<NSApp.Stock> = [
        {
            title: t('Mã CK'),
            key: 'stockCode',
            width: 75,
            defaultSortOrder: 'ascend',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.stockCode.localeCompare(b.stockCode),
            showSorterTooltip: false,
        },
        {
            title: t('TCPH'),
            key: 'issuerName',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.issuerName.localeCompare(b.issuerName),
            showSorterTooltip: false,
        },
        {
            title: t('GDCC'),
            width: 70,
            key: 'lastTradingDate',
            showSorterTooltip: false,
        },
        {
            title: t('Trần'),
            className: 'highlight-column',
            key: 'ceilingPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.ceilingPrice - b.ceilingPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Sàn'),
            className: 'highlight-column',
            key: 'floorPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.floorPrice - b.floorPrice,
            showSorterTooltip: false,
        },
        {
            title: t('TC'),
            className: 'highlight-column',
            key: 'referencePrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.referencePrice - b.referencePrice,
            showSorterTooltip: false,
        },
        {
            title: t('Bên mua'),
            children: [
                {
                    title: t('Giá 3'),
                    key: 'best3Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3Bid - b.best3Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 3'),
                    key: 'best3BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3BidVol - b.best3BidVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 2'),
                    key: 'best2Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2Bid - b.best2Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 2'),
                    key: 'best2BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2BidVol - b.best2BidVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 1'),
                    key: 'best1Bid',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1Bid - b.best1Bid,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 1'),
                    key: 'best1BidVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1BidVol - b.best1BidVol,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Khớp lệnh'),
            children: [
                {
                    title: t('Giá'),
                    key: 'matchedPrice',
                    className: 'highlight-column',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.matchedPrice - b.matchedPrice,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL'),
                    className: 'highlight-column',
                    key: 'volume',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.volume - b.volume,
                    showSorterTooltip: false,
                },
                // {
                //     title: t('+/-'),
                //     key: 'priceChange',
                //     width: 40,
                //     shouldCellUpdate: (record, prevRecord) => {
                //         return !_.isEqual(record.priceChange, prevRecord.priceChange)
                //     },
                //     className: 'highlight-column',
                //     sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.priceChange - b.priceChange,
                //     showSorterTooltip: false,
                // },
                {
                    title: () => <span>{priceChangeType === 'pricePercentChange' ? '(%)': '+/-'}</span>,
                    filterDropdown: () => {
                        return <Radio.Group onChange={(e) => setPriceChangeType(e.target.value)} value={priceChangeType}>
                            <Radio value={'pricePercentChange'}>+/-(%)</Radio>
                            <Radio value={'priceChange'}>+/-</Radio>
                        </Radio.Group>
                    },
                    filterIcon: filtered => <CaretRightOutlined />,
                    key: priceChangeType,
                    className: 'highlight-column',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.pricePercentChange - b.pricePercentChange,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Bên bán'),
            children: [
                {
                    title: t('Giá 1'),
                    key: 'best1Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1Offer - b.best1Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 1'),
                    key: 'best1OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best1OfferVol - b.best1OfferVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 2'),
                    key: 'best2Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2Offer - b.best2Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 2'),
                    key: 'best2OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best2OfferVol - b.best2OfferVol,
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá 3'),
                    key: 'best3Offer',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3Offer - b.best3Offer,
                    showSorterTooltip: false,
                },
                {
                    title: t('KL 3'),
                    key: 'best3OfferVol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.best3OfferVol - b.best3OfferVol,
                    showSorterTooltip: false,
                },
            ]
        },
        {
            title: t('Cao'),
            className: 'highlight-column',
            key: 'highestPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.highestPrice - b.highestPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Thấp'),
            className: 'highlight-column',
            key: 'lowestPrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.lowestPrice - b.lowestPrice,
            showSorterTooltip: false,
        },
        {
            title: t('Tổng KL'),
            key: 'totalVolume',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.totalVolume - b.totalVolume,
            showSorterTooltip: false,
        },
        {
            title: t('CK Cơ sở'),
            children: [
                {
                    title: t('CK'),
                    key: 'underlyingSymbol',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.stockCode.localeCompare(b.stockCode),
                    showSorterTooltip: false,
                },
                {
                    title: t('Giá'),
                    key: 'underlyingStockData',
                    sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.foreignSellValue - b.foreignSellValue,
                    showSorterTooltip: false,
                },
                // {
                //     title: t('Dư'),
                //     key: 'remainForeignRoom',
                //     sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.ratioRemainForeignRoom - b.ratioRemainForeignRoom,
                //     showSorterTooltip: false,
                // }
            ]
        },
        {
            title: t('TH'),
            key: 'exercisePrice',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.totalVolume - b.totalVolume,
            showSorterTooltip: false,
        },
        // {
        //     title: t('Độ lệch'),
        //     key: 'differentValue',
        //     sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.totalVolume - b.totalVolume,
        //     showSorterTooltip: false,
        // },
        {
            title: t('Tỉ lệ chuyển đổi'),
            key: 'exerciseRatio',
            sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.totalVolume - b.totalVolume,
            showSorterTooltip: false,
        },
        // {
        //     title: t('Điểm hòa vốn'),
        //     key: '222',
        //     sorter: (a: NSApp.Stock, b: NSApp.Stock) => a.totalVolume - b.totalVolume,
        //     showSorterTooltip: false,
        // }
    ];

    let tableFooter = `${t('Giá')} x ${config.PRICE_RATIO.toLocaleString()} ${t('VNĐ')}. ${t('Khối lượng')} x ${config.VOLUME_RATIO.toLocaleString()}. ${t('Giá trị')} x ${config.VALUE_RATIO.toLocaleString()} ${t('VNĐ')}`;

    if (props.stockType === 'derivative') {
        columns = derivativeColumns;
        tableFooter = `${t('Giá')} x 1 ${t('VNĐ')}. ${t('Khối lượng')} x 1 ${t('hợp đồng')}`;
    }

    if (props.stockType === 'warrant') {
        columns = warrantColumns;
    }

    if (props.preColumns) {
        columns = [...props.preColumns, ...columns];
    }

    if (props.extendColumns) {
        columns = [...columns, ...props.extendColumns];
    }

    if (props.columns) {
        columns = props.columns;
    }

    return (
        <Card bodyStyle={{padding: 0}} bordered={false}>
            <VirtualTablePrice
                {...props.tableProps}
                bordered
                className="table-stock-price"
                priceRatio={props.priceRatio}
                volumeRatio={props.volumeRatio}
                scroll={{ y: layoutHeight, x: 'max-content' }}
                rowKey={(record: NSApp.Stock) => record.stockCode}
                columns={columns}
                dataSource={props.stocks}
                pagination={false}
                changeTradingView={changeTradingView}
                layoutHeight={layoutHeight}
                loading={loading}
                footer={() => <small className="text-one-line">{tableFooter}</small>}
            />
        </Card>
    )
}

export default TablePrice;