import React from "react";
import { Switch, Route } from "react-router-dom";

import PushNotificationForm from "./PushNotification/Form";
import NotificationList from "./PushNotification/List";
import EditNotification from "./PushNotification/Edit";

import routes from "../../../../routes";
import NotificationSampleList from "./Sample/List";
import AddNotificationSampleForm from "./Sample/Form";
import EditNotificationSample from "./Sample/Edit";

const NewsDashboard = () => {
	return (
		<Switch>
			<Route
				path={routes.ADMIN_NOTIFICATION_SAMPLE_LIST}
				component={NotificationSampleList}
			/>
			<Route
				path={routes.ADMIN_NOTIFICATION_LIST}
				component={NotificationList}
			/>
			<Route
				path={routes.ADMIN_EDIT_NOTIFICATION}
				component={EditNotification}
			/>
			<Route
				path={routes.ADMIN_PUSH_NOTIFICATION}
				component={PushNotificationForm}
			/>
			<Route
				path={routes.ADMIN_ADD_NOTIFICATION_SAMPLE}
				component={AddNotificationSampleForm}
			/>
			<Route
				path={routes.ADMIN_EDIT_NOTIFICATION_SAMPLE}
				component={EditNotificationSample}
			/>
		</Switch>
	);
};

export default NewsDashboard;
