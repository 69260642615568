import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card, Table, Tooltip, Tag, message } from "antd";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import { useReducedState, toRoute } from "../../../../../services/utils";
import * as config from "../../../../../config";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import routes from "../../../../../routes";
import AdminPermission from "../../../../../components/AdminPermission";

const NotificationListComponent = () => {
	const [listNotifications, setListNotifications] = useState([]);
	const [pagination, setPagination] = useReducedState({
		current: 1,
		pageSize: config.PAGE_SIZE,
		total: 0,
	});

	const fetchNotificationsList = async () => {
		let [err, resp]: any = await api.get(
			"/notification/all-notifications",
			{
				page: pagination.current,
				pageSize: config.PAGE_SIZE,
			}
		);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		let { notifications, total } = resp.data;
		setListNotifications(notifications);
		setPagination({ total });
	};

	useEffect(() => {
		fetchNotificationsList();
	}, [pagination.current]); // eslint-disable-line

	const columns: any = [
		{
			title: "Tiêu đề",
			key: "title",
			width: 300,
			textWrap: "word-break",
			ellipsis: true,
			render: (notification) => (
				<Link
					to={toRoute(routes.ADMIN_NOTIFICATION_LIST, {
						notificationId: notification._id,
					})}
				>
					<Tooltip placement="top" title={notification?.title}>
						<span>{notification?.title}</span>
					</Tooltip>
				</Link>
			),
		},
		{
			title: "Loại thông báo",
			dataIndex: "type",
			render: (type) => <span>{type}</span>,
		},
		{
			title: "Mô tả",
			dataIndex: "description",
			className: "whitespace-normal-important",
			render: (description) => <span>{description}</span>,
		},
		{
			title: "Trạng thái xem",
			dataIndex: "seen",
			render: (seen) => (
				<span>
					{seen === true && <Tag color="green">Đã xem</Tag>}
					{seen === false && <Tag color="red">Chưa xem</Tag>}
				</span>
			),
		},
		{
			title: "Trạng thái gửi",
			dataIndex: "sent",
			render: (sent) => (
				<span>
					{" "}
					<span>
						{sent === true && <Tag color="green">Đã gửi</Tag>}
						{sent === false && <Tag color="red">Chưa gửi</Tag>}
					</span>
				</span>
			),
		},
		{
			title: "Nền tảng",
			dataIndex: "platform",
			render: (platform) => <span>{platform}</span>,
		},
		{
			title: "Ngày gửi",
			dataIndex: "createdAt",
			render: (createdAt) => <span>{moment(createdAt).format("L")}</span>,
		},
	];

	return (
		<Card>
			<MyBreadcrumd items={[{ label: "Thông báo" }]} />

			<div className="mt-3">
				<Table
					columns={columns}
					dataSource={listNotifications}
					rowKey={(r: any) => r.id}
					pagination={{
						onChange: (page) => {
							setPagination({ current: page });
						},
						...pagination,
					}}
				/>
			</div>
		</Card>
	);
};

const NotificationList = () => {
	return <AdminPermission roleName={[]}>
		<NotificationListComponent />
	</AdminPermission>
}

export default NotificationList;
