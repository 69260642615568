import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { initFacebookSdk } from './services/init-facebook-sdk';
import './services/analytics';
import './i18n';
import "./assets/css/jquery-confirm.css";
import "./assets/css/index.css";

Sentry.init({
    dsn: "https://328cefa56eeb4c378f3267e69cb0bb3e@o265263.ingest.sentry.io/4504755475644416",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

declare global {
    interface Window {
        $: any;
        jQuery: any,
        jquery: any
    }
}

const startApp = ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

initFacebookSdk().then(startApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
