import React from "react";
import { Typography, Space } from "antd";
import { LeftOutlined, CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Loading from "../../../../../../../components/Loading";

type RecommendationDetailProps = {
    recommendation: NSApp.Recommendation | undefined | null,
    backToRecommendationsList?: Function | undefined | null,
    errorMessage: string,
    hasBreadcrumb: boolean
}

const { Title, Paragraph } = Typography;

const RecommendationDetailPage = (props: RecommendationDetailProps) => {
    const { recommendation, errorMessage } = props;
    const history = useHistory();
    const { t } = useTranslation();

    if (errorMessage) {
        return <div className="text-center p-2">
            <Typography.Text>{errorMessage}</Typography.Text>
        </div>
    }

    return (
        <>
            {!recommendation &&
                <Loading/>
            }

            {recommendation &&
                <div className="relative h-full p-2">
                    <p className='cursor-pointer mb-2' onClick={() => history.goBack()}><LeftOutlined /> {t('Quay lại')}</p>
                    <Typography>
                        <Title level={2}>{recommendation.title}</Title>
                        <Space className="text-gray mb-3">
                            <span><CalendarOutlined/> {moment(recommendation?.createdAt).format('L')}</span>
                            <span><ClockCircleOutlined/> {moment(recommendation?.createdAt).format('LT')}</span>
                        </Space>
                        <Paragraph>
                            <div dangerouslySetInnerHTML={{__html: recommendation.content}}/>
                        </Paragraph>
                    </Typography>
                </div>
            }
        </>
    );
}

export default RecommendationDetailPage;