import React, { useEffect, useState } from "react";
import api from "../../../../services/api";
import Form from "./Form";
import { useHistory, useParams } from "react-router-dom";
import { message } from "antd";
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";
import routes from "../../../../routes";

const EditFeature = () => {
    const [feature, setFeature]: any = useState();
    const { featureId } = useParams<any>();
    const history = useHistory();

    const fetchFeature = async () => {
        const [err, resp]: any = await api.get(`/plan/feature-detail/${featureId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        setFeature(resp.data);
    };

    const onFinish = async (formValues: any) => {
        let [err, resp]: any = await api.post(`/plan/update-feature`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (resp.data) {
            message.success("Cập nhật thành công");
            history.replace(routes.ADMIN_FEATURE_LIST);
        }
    };

    useEffect(() => {
        fetchFeature();
        // eslint-disable-next-line
    }, []);

    if (!feature) {
        return <Loading/>;
    }
    
    return <div>
            <MyBreadcrumd
                items={[
                    { label: "Quản lý tính năng" },
                    { label: "Chỉnh sửa" },
                ]}
            />
            <Form initialValues={feature} mode="edit" onFinish={onFinish}/>
    </div>
}

export default EditFeature;