import React, { useState, useEffect } from "react";
import { Card, message } from "antd";
import { useParams } from "react-router-dom";

import api from "../../../../../services/api";
import routes from "../../../../../routes";

import CategoryForm from "./_Form";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import Loading from "../../../../../components/Loading";

const CategoryEditPage = () => {
    const { categoryId } = useParams();
    const [ category, setCategory ]: any = useState();

    const fetchCategory = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/news/category/${categoryId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setCategory(resp?.data);
    };

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/news/category/${categoryId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật thành công");
    };

    useEffect(() => {
        fetchCategory();
        // eslint-disable-next-line
    }, []);

    if (!category) {
        return <Loading/>;
    }

    return <Card>
        <MyBreadcrumd items={[
            { label: 'Tin tức', path: routes.ADMIN_NEWS_LIST },
            { label: 'Danh mục', path: routes.ADMIN_NEWS_CATEGORY_LIST },
            { label: category.name },
            { label: 'Chỉnh sửa' },
        ]} />

        <div className="m-auto mt-5 w-3/6">
            <CategoryForm
                mode='edit'
                onFinish={onFinish}
                initialValues={{...category, parent: category?.parent?._id}}
                parent={category?.parent}
            />
        </div>
    </Card>
}

export default CategoryEditPage;