import React, { useState } from "react";
import { Select, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import { useHistory } from "react-router-dom";
import { OverviewStocksType } from "../types/modules/overviewStock";
// import { toRoute } from "../services/utils";
import api from "../services/api";
// import routes from "../routes";
import { useDebounce } from 'react-use';
import useStockDetailModalState from "../contexts/StockDetailModal";

const { Option } = Select;

type SearchInputProps = {
    placeholder: string,
    onSelect?: (symbol: string) => void,
    style: object,
};

const SearchStocks = (props: SearchInputProps) => {
    const { placeholder, style } = props;
    const [loading, setLoading] = useState(false);
    const [stockSymbol, setStockSymbol] = useState<string | undefined>();
    const [stocksList, setStocksList] = useState<Array<OverviewStocksType>>([]);
    const [, setStockDetailModalState] = useStockDetailModalState();

    const [,] = useDebounce(
        () => {
            if (stockSymbol) {
                fetchStocksByCode(stockSymbol)
            } else {
                setStocksList([]);
            }
        },
        300,
        [stockSymbol]
    );
    const sortStocks = (a , b) => {
        return a.code.length - b.code.length
    }
    const fetchStocksByCode = async (code, pageSize=50) => {
        setLoading(true);

        // eslint-disable-next-line
        let [err, resp]: any = await api.get('symbol', { code, pageSize });

        if (err) return;
        if( resp ){
            resp.data.sort(sortStocks)
        }
        setStocksList(resp.data);
        setLoading(false);
    };

    const goToStockDetail = (symbol: string) => {
        setStockDetailModalState({
            isVisible: true,
            stockCode: symbol,
        });
    };

    const handleSearch = (symbol) => {
        setStockSymbol(symbol);
        // if (value) {
        //     fetchStockMarket(value);
        // } else {
        //     setStocksList([]);
        // }
    };

    const options = (
        stocksList.map((stock: any) => {
            return (
                <Option className="select-item-option-wrap" key={stock?.code} value={stock?.code}>
                    <strong>{stock?.code?.toUpperCase()}</strong> - {stock?.name} - {stock?.exchange?.toUpperCase()}
                </Option>
            )
        })
    );

    return (
        <>
            <Select
                showSearch
                value={stockSymbol}
                placeholder={placeholder}
                style={style}
                dropdownStyle={{ minWidth: 350 }}
                defaultActiveFirstOption={true}
                filterOption={false}
                onSearch={handleSearch}
                onSelect={(symbol) => {
                    if (!props.onSelect) {
                        goToStockDetail(symbol);
                        return;
                    }
                    props.onSelect(symbol)
                }}
                notFoundContent={loading ? <Spin size="small" /> : null}
                suffixIcon={<SearchOutlined />}
            >
                {options}
            </Select>
        </>
    )
};

export default SearchStocks;
