import axios from 'axios';
import localStorage from "../services/local-storage";
import * as config from "../config";

class Api {
    accessToken: string | null = null;

    constructor() {
        axios.defaults.baseURL = config.API_BASE_URL;
        axios.defaults.headers.common.platform = 'web';

        // TODO: check why we cannot use local-storage module here
        const accessToken = localStorage.get('accessToken');
        if (accessToken) {
            this.setAccessToken(accessToken);
        }
    }

    setAccessToken = (accessToken: string) => {
        this.accessToken = accessToken;
        axios.defaults.headers.common.Authorization = `bearer ${accessToken}`;
    };

    get = async (url: string, params = {}) => {
        return new Promise((resolve) => {
            axios.get(url, { params })
                .then(data => resolve([null, data]))
                .catch(e => resolve([e, null]))
        });
    }

    _request = async (method, url, params, headers = {}) => {
        params = params || {};
        headers = {
            ...axios.defaults.headers.common,
            ...headers
        }

        return new Promise((resolve) => {
            axios[method](url, params, {
                headers
            })
                .then(data => resolve([null, data]))
                .catch(e => resolve([e, null]))
        });
    };

    post = async (url, params, headers = {}) => this._request('post', url, params, headers);
    patch = async (url, params) => this._request('patch', url, params);
    put = async (url, params) => this._request('put', url, params);
    delete = async (url, params = {}) => this._request('delete', url, params);
}

export default new Api();
