import React, { useState } from "react";
import { useEffectOnce } from 'react-use';
import { Select } from "antd";
import api from "../../../../services/api";
import _ from 'lodash';

const { Option } = Select;

type IndustryListInput = {
    style?: object,
    onChange: (industry: NSApp.Industry) => void,
}

const IndustryListInput = (props: IndustryListInput) => {
    const [ , setIndustryCodeSelected ] = useState<string>();
    const [ industries, setIndustries ] = useState<NSApp.Industry[]>([]);

    const fetchIndustriesList = async () => {
        const [err, resp]: any = await api.get('plugins/industry/all');

        if (err) {
            return;
        }

        const _industries = resp.data;

        if (!_industries.length) {
            return;
        }

        // const _industryDefault = _industries[0];
        setIndustries(_industries);
        // props.setIndustrySelected(_industryDefault);
        // props.onChange(_industryDefault);
    };

    useEffectOnce(() => {
        fetchIndustriesList();
    });

    const onChange = (industryId: string) => {
        setIndustryCodeSelected(industryId);
        const industrySelected = _.find(industries, (i) => i.id === industryId);
        if (!industrySelected) {
            return;
        }

        props.onChange(industrySelected);
    };
    const onClear = () => {

    }

    return <Select
        showSearch
        style={props.style || { width: 200 }}
        onChange={onChange}
        onClear={onClear}
        placeholder="Ngành"
        optionLabelProp="label"
        filterOption={(input, option) =>{
            return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        allowClear={true}
    >
        {industries.map((industry: NSApp.Industry) => {
            return <Option
                key={industry.id}
                value={industry.id}
                label={`${industry.industryName}`}>
                    {industry.industryName}
            </Option>
        })}
    </Select>
}

export default IndustryListInput;
