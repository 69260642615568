import React from "react";
import MacroeconomicsContainer from "./container";
import UserACL from '../../../../../../components/UserACL';

const MacroeconomicsWindow = (props) => {
    let { glContainer } = props;

    return (
        <UserACL feature="macroeconomics_statistics">
            <MacroeconomicsContainer glContainer={glContainer} />
        </UserACL>
    )
};

export default MacroeconomicsWindow;