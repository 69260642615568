import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { StockContext } from '../../../../../contexts/StocksProvider';
import NameWithChartIcon from "..//NameWithChartIcon";
import { getStockColorClass } from "../../../../../services/utils";
import themeModeGlobalState from '../../../../../contexts/ThemeMode';

type BaseStockCodeProps = {
    warrantStock: NSApp.Stock;
}

const BaseStockCode = (props: BaseStockCodeProps) => {
    const { warrantStock } = props;
    const [themeMode,] = themeModeGlobalState();

    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][warrantStock.underlyingSymbol.toLowerCase()],
    );

    return (
        <NameWithChartIcon
            color={getStockColorClass(stockState, themeMode)}
            symbol={stockState.stockCode.toUpperCase()}
        />
    );
}

export default BaseStockCode;
