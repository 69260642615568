import React, { useContext } from 'react'

// Auth Context
export const AuthContext = React.createContext({});

export const useAuthState = () => {
    // @ts-ignore
    return useContext(AuthContext);
};

// Dashboard Context
export const DashboardContext = React.createContext({});

export const useDashboardState = () => {
    // @ts-ignore
    return useContext(DashboardContext);
};

// Layout Context
export const LayoutContext = React.createContext({});

export const useLayoutState = () => {
    // @ts-ignore
    return useContext(LayoutContext);
};
