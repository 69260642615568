import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { Table, Space, Button, Tag, Popconfirm, message } from 'antd';
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import api from "../../../../services/api";
import routes from "../../../../routes";
import { toRoute } from "../../../../services/utils";
import AdminPermission from '../../../../components/AdminPermission';

const DashboardManagerListComponent = () => {

    const [ dashboardTemplateList, setDashboardTemplateList ] = useState<NSApp.DashboardTemplate[]>([]);
    const fetchDashboardTemplates = async () => {
        const [err, resp]: any = await api.get('plugins/dashboard-template/list');

        if (err) {
            message.error('Có lỗi khi tải danh sách dashboard template')
            return;
        }

        setDashboardTemplateList(resp.data)
    }

    const deleteDashboardTemplate = async (id: string) => {
        //eslint-disable-next-line
        const [err, resp]: any = await api.delete(`plugins/dashboard-template/${id}`);

        if (err) {
            message.error('Có lỗi khi xóa template')
            return;
        }

        message.success('Xóa thành công');
        await fetchDashboardTemplates()
    }

    useEffect(() => {
        fetchDashboardTemplates();
    }, [])

    const columns: any = [
        {
            title: 'Tiêu đề',
            key: 'title',
            width: 300,
            align: "center",
            textWrap: 'word-break',
            ellipsis: true,
            render: (template) => <Link to={toRoute(routes.ADMIN_DASHBOARD_MANAGER_EDIT, { dashboardId: template.id })}>
                    <span>{template.name}</span>
            </Link>
        },
        {
            title: "Trạng thái",
            align: "center",
            dataIndex: 'active',
            render: (active) => <span>
                {active ? <Tag color="green">Active</Tag> : <Tag color="red">Un active</Tag>}
            </span>
        },
        {
            title: 'Action',
            width: 200,
            render: (template) => {
                return <Space>
                    <Link to={toRoute(routes.ADMIN_DASHBOARD_MANAGER_EDIT, { dashboardId: template.id })}>
                        <Button>Sửa</Button>
                    </Link>

                    <Popconfirm
                        title="Bạn chắc chắn muốn xóa template này?"
                        onConfirm={() => deleteDashboardTemplate(template.id)}
                        okText="Xóa"
                        cancelText="Hủy"
                    >
                        <Button type="primary" danger>Xóa</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ];

    return <>
        <MyBreadcrumd items={[{ label: 'Dashboard' }]} />

        <div className="mt-2">
            <Space>
                <Button type="primary">
                    <Link to={routes.ADMIN_DASHBOARD_MANAGER_ADD}>Thêm mới</Link>
                </Button>
            </Space>
        </div>

        <div className="mt-2">
            <Table
                columns={columns}
                dataSource={dashboardTemplateList}
                rowKey={(r: NSApp.DashboardTemplate) => r.id}
            />
        </div>
    </>
}

const DashboardManagerList = () => {
    return <AdminPermission roleName={[]}>
        <DashboardManagerListComponent />
    </AdminPermission>
}

export default DashboardManagerList;