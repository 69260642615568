import React, { useState } from 'react';
import { Card, Col, Row, Table, Tag, Typography } from 'antd';
import { useTranslation } from "react-i18next";

import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useReducedState } from '../../../../services/utils';
import * as config from "../../../../config";
import api from "../../../../services/api";
import { useEffectOnce } from 'react-use';
import fontSizes from '../../../../themes/fontsizes';
import colors from "../../../../themes/colors";
import InvitedAccount from './InvitedAccount';

const { Title, Text } = Typography;

type AffiliateDashboardProps = {
    userId: string,
    affiliate: any,
};

const Affiliate = (props: AffiliateDashboardProps) => {
    const { t } = useTranslation();

    const { userId, affiliate } = props;

    const [withdrawHistory, setWithdrawHistory] = useState<NSApp.UserAffiliateTransaction[]>([]);
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchWithdrawHistory = async () => {
        const [error, response]: any = await api.get(`/affiliate/transactions-user/`,{
            type: 'withdraw',
            page: pagination.current,
            limit: pagination.pageSize,
            userId: userId
        });

        if (error) {
            return;
        }

        setWithdrawHistory(response.data.transactions);
    }
    useEffectOnce(() => {
        fetchWithdrawHistory()
    });

    const columns: ColumnsType<NSApp.UserAffiliateTransaction> = [
        {
            title: t('Ngày'),
            dataIndex: 'createdAt',
            align: 'center',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: t('Nội dung'),
            align: 'center',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('Số tiền'),
            align: 'center',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => amount.toLocaleString(),
        },
        {
            title: t('Trạng thái'),
            key: 'status',
            align: 'center',
            dataIndex: 'status',
            render: (status) => {
                let color = '';
                let text = '';

                if (status === 'success') {
                    color = 'green';
                    text = t('Đã thực hiện');
                }

                if (status === 'pending') {
                    color = 'yellow';
                    text = t('Chờ xử lý');
                }

                if (status === 'failed') {
                    color = 'red';
                    text = t('Đã hủy');
                }

                return (
                    <Tag color={color} key={status}>
                        {text}
                    </Tag>
                )
            },
        },
        {
            title: t('Ghi chú'),
            key: 'note',
            dataIndex: 'note',
        },
    ];
    return (
        <div>
            <div>
                <Title className="text-center">{t('Hoa hồng')}</Title>
            </div>
            <Card>
                <Row className="text-center">
                    <Col span={4}>
                        <Text strong>{t('Hoa hồng khả dụng')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large, color: colors.primary }}>{affiliate.balance.toLocaleString()}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong>{t('Hoa hồng chờ rút')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.commissionWaitingForWithdrawal.toLocaleString()}</Text>
                    </Col>
                    <Col span={8}>
                        <Text strong>{t('Tổng doanh thu khách hàng đã thanh toán (sau thuế VAT)')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.totalRevenue.toLocaleString()}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong>{t('Tổng hoa hồng sau thuế TNCN')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.commissionReceived.toLocaleString()}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong>{t('Tổng hoa hồng đã thanh toán')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.totalCommissionPaid.toLocaleString()}</Text>
                    </Col>
                </Row>
            </Card>

            <Card className="mt-5">
                <Text strong style={{ fontSize: fontSizes.large }}>{t('Lịch sử rút tiền')}</Text>
                <Table 
                    columns={columns} 
                    dataSource={withdrawHistory} 
                    bordered                     
                    pagination={{
                            onChange: page => {
                                setPagination({ current: page });
                            },
                            ...pagination
                    }}
                />
            </Card>

            <Card>
                <InvitedAccount userId={userId}/>
            </Card>
        </div>
    )
};

export default Affiliate;