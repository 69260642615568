import React from 'react';
import { Button } from 'antd';
import { createFromIconfontCN } from "@ant-design/icons";
import api from "../../../services/api";

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
});

type FacebookLoginButtonProps = {
    referenceCode?: string,
    onSuccess: (accessToken: string, user: any) => void,
    onError: (error: any) => void,
}


const FacebookLoginButton = (props: FacebookLoginButtonProps) => {

    const loginByFacebook = async () => {
        const { authResponse } = await new Promise(window.FB.login);

        if (!authResponse) return;

        const fbAccessToken = authResponse.accessToken;

        let payloads: any = {
            accessToken: fbAccessToken,
        }

        if (props.referenceCode) {
            payloads = {
                ...payloads,
                referenceCode: props.referenceCode
            }
        }

        const [error, response]: any = await api.post('user/facebook/signin', payloads);

        if (error) {
            props.onError(error);
        }

        let {accessToken, user} = response.data;

        props.onSuccess(accessToken, user);
    };

    return (
        <Button
            block
            disabled
            onClick={loginByFacebook}
            size="middle"
            className="btn-login-facebook"
            icon={<IconFont type="icon-facebook" />}
        >
            Facebook
        </Button>
    );
}

export default FacebookLoginButton;
