import React from "react";
import { useHistory } from "react-router-dom";
import { Card, message } from "antd";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import RecommendationForm from "./_Form";
import api from "../../../../services/api";
import routes from "../../../../routes";
import AdminPermission from "../../../../components/AdminPermission";

const RecommendationAddPageComponent = () => {
    const history = useHistory();

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.post('plugins/recommendation/add', formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Thêm mới khuyến nghị thành công");
        history.replace(routes.ADMIN_RECOMMENDATION_LIST);
    };

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Khuyến nghị', path: routes.ADMIN_RECOMMENDATION_LIST },
                { label: 'Thêm mới' },
            ]} />

            <div className="m-auto mt-5 w-3/6">
                <RecommendationForm mode='add' onFinish={onFinish} initialValues={{ userPlans: ['basic', 'advanced', 'professional'] }}/>
            </div>
        </Card>
    )
}

const RecommendationAddPage = () => {
    return <AdminPermission roleName={['editor', 'moderator']}>
        <RecommendationAddPageComponent />
    </AdminPermission>
}

export default RecommendationAddPage;