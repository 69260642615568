import React from "react";
import { Button, Divider, Result, Alert } from "antd";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { vnPayResponseCode } from "../../../../config";
import routes from "../../../../routes";
import { useAuthState } from "../../../../contexts";
import colors from '../../../../themes/colors';

const PaymentResult = () => {
    const { authState } = useAuthState() as any;
    const { currentUser } = authState;

    const { t } = useTranslation();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    let vnpParams: any = {};

    params.forEach((value, key) => {
        vnpParams[`${key}`] = value;
    });

    let status;
    let title;
    let subTitle;
    let titleColor;

    if (vnpParams.vnp_TransactionStatus === "00") {
        status = "success";
        title = t('Thanh toán thành công!');
        subTitle = t('Cảm ơn Quý khách đã tin tưởng và sử dụng dịch vụ của Fstock');
        titleColor = 'black';
    } else {
        status = "error";
        title = t('Thanh toán không thành công');
        titleColor = 'red';
    }

    const userEmail = currentUser?.email ? currentUser.email : vnpParams?.email;
    const userFullname = currentUser?.fullname ? currentUser.fullname : vnpParams?.fullname;

    const Success = () => {
        return (
            <div className="max-w-lg mx-auto text-sm">
                <div className="my-8">
                    <div className="text-center text-base mb-2">{t('Chi tiết đơn hàng')}</div>
                    <div className="flex justify-between">
                        <div className="text-left w-48">{t('Khách hàng')}:</div>
                        <div className="text-right font-bold">{userFullname}</div>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-left w-48">{t('Email')}:</div>
                        <div className="text-right font-bold">{userEmail}</div>
                    </div>
                    <Divider className="my-2" />
                    <div className="flex justify-between">
                        <div className="text-left w-48">{t('Mã đơn hàng')}:</div>
                        <div className="text-right font-bold">{vnpParams.vnp_TxnRef}</div>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-left w-48">{t('Ngày thanh toán')}:</div>
                        <div className="text-right font-bold">{moment(vnpParams.vnp_PayDate, 'YYYYMMDDHHmmss').format('DD-MM-YYYY HH:mm:ss')}</div>
                    </div>
                    <div className="flex justify-between">
                        <div className="text-left w-48">{t('Nội dung thanh toán')}:</div>
                        <div className="text-right font-bold">{vnpParams.vnp_OrderInfo}</div>
                    </div>
                    <Divider className="my-2" />
                    <div className="flex justify-between">
                        <div className="text-left w-48">{t('Số tiền')}:</div>
                        <div className="text-right font-bold">
                            {new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(vnpParams.vnp_Amount as number / 100)}
                        </div>
                    </div>
                </div>

                {vnpParams.userType === 'new' &&
                    <Alert message={<>
                        <span style={{ color: colors.black }}>Để bắt đầu sử dụng dịch vụ Fstock, vui lòng truy cập email: <a href={userEmail}>{userEmail}</a> để kích hoạt và có thêm 14 ngày miễn phí sử dụng gói Chuyên Nghiệp Fstock</span>
                    </>} type="info" style={{ backgroundColor: "#C7E8CA" }} />
                }

                <Link to={routes.HOME}>
                    <Button type="link" size="large">{t('Quay về trang chủ')}</Button>
                </Link>
            </div>
        );
    };

    const Failed = () => {
        return (
            <div className="max-w-sm mx-auto text-base text-center">
                <div className="font-bold">{`${t(`${vnPayResponseCode[`${vnpParams.vnp_ResponseCode}`]}`)}`}</div>
                <div className="mb-4">{t('Vui lòng liên hệ 096 669 8969 để được hỗ trợ')}</div>

                {vnpParams.userType === 'new' &&
                    <Alert message={<>
                        <span style={{ color: colors.black }}>Để bắt đầu sử dụng dịch vụ Fstock, vui lòng truy cập email: <a href={userEmail}>{userEmail}</a> để kích hoạt và có thêm 14 ngày miễn phí sử dụng gói Chuyên Nghiệp Fstock</span>
                    </>} type="info" style={{ backgroundColor: "#C7E8CA" }} />
                }

                <Link to={routes.UPGRADE}>
                    <Button block type="primary" size="large">{t('Thực hiện lại giao dịch')}</Button>
                </Link>
                <Link to={routes.HOME}>
                    <Button type="link" size="large">{t('Quay về trang chủ')}</Button>
                </Link>
            </div>
        );
    };

    return (
        <div className="max-w-screen-md mx-auto p-1">
            <div className="bg-white">
                <Result
                    status={status}
                    title={<span className={`text-${titleColor}`}>{title}</span>}
                    subTitle={subTitle}
                    extra={status === 'success' ? <Success /> : <Failed />}
                />
            </div>
        </div>
    );
};

export default PaymentResult;
