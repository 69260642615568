import React, { useEffect, useState } from "react";
import { fetchIndustryStats } from "../services";
import { useTranslation } from "react-i18next";
import IndustryStats from "../components/IndustryStatistics";


const IndustryStatisticsContainer = (props) => {
    let { glContainer } = props;
    const { t } = useTranslation();
    const [industryStats, setIndustryStats] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const fetchData = async () => {
        let [err, resp] = await fetchIndustryStats();

        if (err) return;

        setIndustryStats(formatIndustryName(resp));
        setLoading(false);
    };

    const formatIndustryName = (data: any) => {
        data = data.filter((industry: any) => {
            return industry.code !== 'CODE';
        });

        data.map((industry: any) => {
            let shortName = '';
            switch (industry.code) {
                case 'retail':
                    shortName = t('Bán lẻ');
                    break;
                case 'insurance':
                    shortName = t('Bảo hiểm');
                    break;
                case 'estate':
                    shortName = t('Bất động sản');
                    break;
                case 'it':
                    shortName = t('CNTT');
                    break;
                case 'oil':
                    shortName = t('Dầu khí');
                    break;
                case 'travel':
                    shortName = t('Du lịch Giải trí');
                    break;
                case 'financial':
                    shortName = t('Dv tài chính');
                    break;
                case 'electrical':
                    shortName = t('Điện, Nước, XDKĐ');
                    break;
                case 'houseware':
                    shortName = t('Hàng CN & Gia dụng');
                    break;
                case 'industrial':
                    shortName = t('Hàng & DVCN');
                    break;
                case 'chemical':
                    shortName = t('Hoá chất');
                    break;
                case 'bank':
                    shortName = t('Ngân hàng');
                    break;
                case 'car':
                    shortName = t('Ô tô Phụ tùng');
                    break;
                case 'resources':
                    shortName = t('Tài nguyên cơ bản');
                    break;
                case 'food':
                    shortName = t('TP & Đồ uống');
                    break;
                case 'media':
                    shortName = t('Truyền thông');
                    break;
                case 'telecommunication':
                    shortName = t('Viễn thông');
                    break;
                case 'construction':
                    shortName = t('Xây dựng Vật liệu');
                    break;
                case 'medical':
                    shortName = t('Y tế');
                    break;
                case 'vnindex':
                    shortName = t('VNINDEX');
                    break;
                case 'hnxindex':
                    shortName = t('HNXINDEX');
                    break;
                case 'upcomindex':
                    shortName = t('UPCOMINDEX');
                    break;
            }

            industry.shortName = shortName;
            return industry;
        });

        const industrySortedList = [
            'retail',
            'insurance',
            'estate',
            'it',
            'oil',
            'travel',
            'financial',
            'electrical',
            'houseware',
            'industrial',
            'chemical',
            'bank',
            'car',
            'resources',
            'food',
            'media',
            'telecommunication',
            'construction',
            'medical',
            'vnindex',
            'hnxindex',
            'upcomindex',
        ];

        return data.sort(function (a: any, b: any) {
            return (
                industrySortedList.indexOf(a.code) -
                industrySortedList.indexOf(b.code)
            );
        });
    };

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line

    return <IndustryStats
        data={industryStats}
        glContainer={glContainer}
        loading={loading}
    />
};

export default IndustryStatisticsContainer;