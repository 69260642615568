import React, { useState, useEffect } from 'react';
import { Input, InputNumber, DatePicker, Table, Tag, Typography, Row, Col, Button, Modal, Alert, Form } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from "react-i18next";
import type { ColumnsType } from 'antd/es/table';

import * as config from "../../../../../config";
import fontSizes from "../../../../../themes/fontsizes";
import api from "../../../../../services/api";
import { useReducedState } from "../../../../../services/utils";

const { Text, Paragraph } = Typography;

type AffiliateWithDrawHistoryProps = {
    affiliate: NSApp.UserAffiliate
};

const WithDrawHistory = (props: AffiliateWithDrawHistoryProps) => {
    const { affiliate } = props;
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRequestSuccessfully, setIsRequestSuccessfully] = useState(false);
    const [transactions, setTransactions] = useState<NSApp.UserAffiliateTransaction[]>([]);
    const [dateRange, setDateRange] = useState<[string, string]>();
    const [ModalAlert, contextHolder] = Modal.useModal();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const showModal = () => {
        if (['not-submited', 'rejected', 'processing'].includes(affiliate.status)) {
            ModalAlert.error({
                content: (
                    <Text>
                        {t('Bạn chỉ có thể Rút Tiền hoa hồng khi đã trở thành Cộng Tác Viên của Fstock')}
                    </Text>
                ),
            });
            return;
        }
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsRequestSuccessfully(false);
    };

    const onChangeRangePicker = (date: any, dateString: [string, string]) => {
        setDateRange(dateString);
    }

    const fetchTransactionHistories = async () => {
        const [err, resp]: any = await api.get('/affiliate/transactions', {
            type: 'withdraw',
            page: pagination.current,
            limit: pagination.pageSize,
            startDate: dateRange ? dateRange[0] : null,
            endDate: dateRange? dateRange[1] : null,
        });

        if (err) {
            return;
        }

        const _transactions = resp.data.transactions;
        const total = resp.data.pagination.total;
        setTransactions(_transactions);
        setPagination({ total });
    }


    const onFinish = async (values: any) => {
        const { amount } = values;

        const [err, resp]: any = await api.post('/affiliate/withdraw', {
            amount,
        });

        if (err) {
            ModalAlert.error({
                content: (
                    <Text>
                        {err.response.data.message}
                    </Text>
                ),
            });
            return;
        }

        setIsRequestSuccessfully(true);
        fetchTransactionHistories();
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        fetchTransactionHistories();
    }, [pagination.current]);

    const columns: ColumnsType<NSApp.UserAffiliateTransaction> = [
        {
            title: t('Ngày'),
            dataIndex: 'createdAt',
            align: 'center',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: t('Nội dung'),
            align: 'center',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('Số tiền'),
            align: 'center',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => amount.toLocaleString(),
        },
        {
            title: t('Trạng thái'),
            key: 'status',
            align: 'center',
            dataIndex: 'status',
            render: (status) => {
                let color = '';
                let text = '';

                if (status === 'success') {
                    color = 'green';
                    text = t('Đã thực hiện');
                }

                if (status === 'pending') {
                    color = 'yellow';
                    text = t('Chờ xử lý');
                }

                if (status === 'failed') {
                    color = 'red';
                    text = t('Đã hủy');
                }

                return (
                    <Tag color={color} key={status}>
                        {text}
                    </Tag>
                )
            },
        },
        {
            title: t('Ghi chú'),
            key: 'note',
            dataIndex: 'note',
        },
    ];

    return (
        <div>
            <Row>
                <Col span={8}>
                    <Text strong style={{ fontSize: fontSizes.large }}>{t('Lịch sử rút tiền')}</Text>
                    <Button danger type="primary" style={{ marginLeft: 10, width: '20%' }} onClick={showModal}>
                        {t('Rút tiền')}
                    </Button>
                </Col>
                <Col span={8} offset={8} className="text-right">
                    <Input.Group compact>
                        <DatePicker.RangePicker picker="date" style={{ width: '50%' }} onChange={onChangeRangePicker} format="DD-MM-YYYY"/>
                        <Button type="primary" style={{ width: '10%' }} onClick={fetchTransactionHistories}>{t('Lọc')}</Button>
                    </Input.Group>
                </Col>
            </Row>

            <Table columns={columns} dataSource={transactions} bordered />
            <Modal title={t('Yêu cầu rút tiền')} visible={isModalOpen} onCancel={handleCancel} footer={null}>
                {isRequestSuccessfully &&
                    <Alert
                        message={t('Gửi yêu cầu rút tiền thành công')}
                        description={t('Fstock sẽ xử lý yêu cầu rút tiền của bạn vào cuối tháng')}
                        type="success"
                        showIcon
                    />
                }
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        className="text-right"
                        label={t('Hoa hồng khả dụng')}
                    >
                        <Text>{affiliate.balance}</Text>
                    </Form.Item>

                    <Form.Item
                        label={t('Nhập số tiền')}
                        name="amount"
                        rules={[
                            { required: true, message: 'Số tiền không được để trống' },
                            { min: 500000, type: 'number', message: 'Số tiền tối thiếu từ 500.000vnd' },
                            { max: affiliate.balance, type: 'number', message: 'Hoa hồng khả dụng không đủ' },
                        ]}
                    >
                        <InputNumber className="w-full"/>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit" disabled={isRequestSuccessfully}>
                            {t('Gửi')}
                        </Button>
                    </Form.Item>
                </Form>
                <Paragraph>{t('Ghi chú:')}</Paragraph>
                <Paragraph>
                    <ul>
                        <li>
                            <Text>{t('Số tiền tối thiểu có thể rút: 500.000 VND')}</Text>
                        </li>
                        <li>
                            <Text>{t('Yêu cầu rút tiền sẽ được xử lý vào cuối tháng')}</Text>
                        </li>
                    </ul>
                </Paragraph>

            </Modal>
            {contextHolder}
        </div>
    )
}

export default WithDrawHistory;
