import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import DashboardManagerList from "./List";
import DashboardManagerAdd from "./Add";
import DashboardManagerEdit from "./Edit";

import routes from "../../../../routes";

const DashboardManager = () => {
    return <div>
        <Switch>
            <Route path={routes.ADMIN_DASHBOARD_MANAGER_LIST} component={DashboardManagerList} />
            <Route path={routes.ADMIN_DASHBOARD_MANAGER_ADD} component={DashboardManagerAdd} />
            <Route path={routes.ADMIN_DASHBOARD_MANAGER_EDIT} component={DashboardManagerEdit} />

            <Route path={routes.ADMIN_DASHBOARD_MANAGER}>
                <Redirect to={routes.ADMIN_DASHBOARD_MANAGER_LIST} />
            </Route>
        </Switch>
    </div>
}

export default DashboardManager;