import React, { useState } from 'react';
import { Card, Typography, Radio } from 'antd';
import ReactPlayer from 'react-player';
import { useTranslation } from "react-i18next";

const UserManual = () => {
    const { t } = useTranslation();

    const [currentId, setCurrentId] = useState('c6HSAgRh6TE');

    const onChange = (e) => {
        setCurrentId(e.target.value);
    };

    return <Card
        bordered={false}
        className="h-full"
        bodyStyle={{
            height: '100%',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <Typography.Title>{t('Hướng dẫn sử dụng Fstock')}</Typography.Title>

        <Radio.Group
            onChange={onChange}
            defaultValue="c6HSAgRh6TE"
            optionType="button"
            buttonStyle="solid"
            size="large"
            style={{ marginBottom: 48 }}
        >
            <Radio.Button value="c6HSAgRh6TE">{t('Nền tảng Web Platform')}</Radio.Button>
            <Radio.Button value="RNg8muDW_H0">{t('Nền tảng Mobile App')}</Radio.Button>
        </Radio.Group>

        <div className="flex justify-center items-center">
            <ReactPlayer
                controls={true}
                url={`https://www.youtube.com/watch?v=${currentId}`}
                width={960}
                height={540}
            />
        </div>
    </Card>;
};

export default UserManual;