import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Select, message, Card } from "antd";

import api from "../../../../services/api";

import Loading from "../../../../components/Loading";
import AdminPermission from "../../../../components/AdminPermission";

const { Option } = Select;

const UserRoleEditPageComponent = () => {
    const { userRoleId } = useParams<any>();
    const [ role, setRole ]: any = useState();
    const [ permissions, setPermissions ]: any = useState([]);

    const fetchRole = async () => {
        const [err, resp]: any = await api.get(`permission/roles/${userRoleId}`);

        if (err) {
            // handle error
            return;
        }

        let role = resp.data;
        let flatPermissions = role.permissions.map(p => p.codeName);
        role.permissions = flatPermissions;

        setRole(role);
    };

    const fetchPermissions = async () => {
        const [err, resp]: any = await api.get(`permission`);

        if (err) {
            // handle error
            return;
        }

        setPermissions(resp.data);
    };

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        const [err, resp]: any = await api.put(`permission/roles/${userRoleId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật quyền truy cập thành công");
    };

    useEffect(() => {
        fetchRole();
        fetchPermissions();
        // eslint-disable-next-line
    }, []);


    if (!role) {
        return <Loading />
    }

    return (
        <Card>
            <div className="m-auto mt-5 w-3/6">
                <Form
                    layout="vertical"
                    name="user_role_form"
                    className="user-role-form"
                    initialValues={role}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="permissions"
                        label="Quyền truy cập"
                        rules={[
                            {
                                required: true,
                                type: 'array'
                            },
                        ]}
                    >
                        <Select mode="tags" placeholder="Quyền truy cập" filterOption={false}>
                            {permissions.map(p => {
                                return <Option key={p.id} value={p.codeName}>{p.codeName}</Option>
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button className="mb-2" type="primary" htmlType="submit" block>
                            Cập nhật
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Card>
    )
}

const UserRoleEditPage = () => {
    return <AdminPermission roleName={[]}>
        <UserRoleEditPageComponent />
    </AdminPermission>
}

export default UserRoleEditPage;