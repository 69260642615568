import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Form,
	Button,
	Input,
	Card,
	Modal,
	message,
	Checkbox,
	Row,
	Col,
	Select,
	Radio,
	Space,
	Spin,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import api from "../../../../../services/api";
import DraftEditor from "../../../../../components/DraftEditor";
import Loading from "../../../../../components/Loading";
import debounce from "lodash/debounce";

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

type NotificationFormProps = {
	initialValues?: any;
	onFinish: (values: any) => void;
};

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);
	const fetchRef = useRef(0);

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value: string) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);

			fetchOptions(value).then((newOptions: any) => {
				console.log("SET OPTIONS ....", newOptions);
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}

				setOptions(newOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	return (
		<Select
			filterOption={false}
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			{...props}
			options={options}
		/>
	);
}

async function fetchUserList(keyword: string) {
	let [err, resp]: any = await api.post(`/notification/search-receiver`, {
		key: keyword,
	});
	if (err) return;
	const users = resp.data;
	return users.map((user) => ({
		label: `${user.email}`,
		value: user.email,
	}));
}

const NotificationSampleForm = (props: NotificationFormProps) => {
	const showConfirmEditNotif = (formValues: any) => {
		confirm({
			title: "Bạn có chắc chắn muốn gửi thông báo này",
			icon: <ExclamationCircleOutlined />,
			content: formValues.title,
			onOk: async () => {
				await props.onFinish(formValues);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const { initialValues } = props;
	const [emails, setEmails] = useState<Array<string>>([]);
	const [receiver, setReceiver] = useState(1);

	const onChangeReceiver = (e) => {
		setReceiver(e.target.value);
	};

	return (
		<Card>
			<MyBreadcrumd
				items={[
					{ label: "Thông báo" },
					{ label: "Chỉnh sửa thông báo" },
				]}
			/>

			<div className="m-auto mt-5 w-3/6">
				<Form
					layout="vertical"
					name="push_notification_form"
					className="push-notification-form"
					initialValues={initialValues}
					validateMessages={{
						// eslint-disable-next-line no-template-curly-in-string
						required: "${label} không được để trống",
					}}
					onFinish={showConfirmEditNotif}
				>
					<Form.Item
						name="title"
						label="Tiêu đề"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="Tiêu đề" />
					</Form.Item>

					<Form.Item
						name="description"
						label="Mô tả"
						rules={[
							{
								required: true,
							},
						]}
					>
						<TextArea placeholder="Mô tả" />
					</Form.Item>

					<Form.Item name="platform" label="Nền tảng">
						<Select defaultValue={"all"} style={{ width: 150 }}>
							<Option value="all">Tất cả</Option>
							<Option value="web">Web</Option>
							<Option value="email">Email</Option>
							<Option value="app">App</Option>
						</Select>
					</Form.Item>

					<Form.Item
						name="content"
						label="Nội dung"
						rules={[
							{
								required: true,
							},
						]}
					>
						<DraftEditor initEditorState={initialValues.content} />
					</Form.Item>

					<Radio.Group onChange={onChangeReceiver} value={receiver}>
						<Space direction="vertical">
							<Radio value={1}>
								Gửi theo nhóm người dùng
								{receiver === 1 ? (
									<Form.Item
										name="group_user"
										rules={[
											{
												required: true,
											},
										]}
									>
										<Checkbox.Group
											style={{
												width: "100%",
											}}
										>
											<Row>
												<Col span={8}>
													<Checkbox value="user">
														User
													</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="premium_user">
														Premium User
													</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="moderator">
														Moderator
													</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="validator">
														Validator
													</Checkbox>
												</Col>
												<Col span={8}>
													<Checkbox value="editor">
														Editor
													</Checkbox>
												</Col>
											</Row>
										</Checkbox.Group>
									</Form.Item>
								) : null}
							</Radio>
							<Radio value={2}>
								Gửi người dùng cụ thể
								{receiver === 2 ? (
									<Form.Item
										name="emails"
										rules={[
											{
												required: true,
												type: "array",
											},
										]}
									>
										<DebounceSelect
											mode="multiple"
											value={emails}
											placeholder="Select users"
											fetchOptions={fetchUserList}
											autoFocus={true}
											onChange={(newValue) => {
												setEmails(newValue);
											}}
											style={{ width: "100%" }}
										/>
									</Form.Item>
								) : null}
							</Radio>
						</Space>
					</Radio.Group>

					<Form.Item>
						<Button
							className="mb-3"
							type="primary"
							htmlType="submit"
							block
						>
							Gửi thông báo
						</Button>
					</Form.Item>
				</Form>
			</div>
		</Card>
	);
};

function PushNotificationForm() {
	const [notificationSample, setNotificationSample] = useState();
	const { notificationSampleId } = useParams<{
		notificationSampleId: string;
	}>();

	const fetchNotification = async () => {
		let [err, resp]: any = await api.get(
			`/notification/sample/${notificationSampleId}`
		);
		if (err) return;
		setNotificationSample(resp.data);
	};

	const onFinish = async (formValues) => {
		// eslint-disable-next-line
		let [err, resp]: any = await api.post(
			`notification/push-notification`,
			formValues
		);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		message.success("Gửi thông báo thành công");
	};

	useEffect(() => {
		fetchNotification();
	}, []); // eslint-disable-line

	if (!notificationSample) return <Loading />;

	return (
		<NotificationSampleForm
			initialValues={notificationSample}
			onFinish={onFinish}
		></NotificationSampleForm>
	);
}

export default PushNotificationForm;
