import React from "react";
import { Form, Button, Checkbox, Row, Col } from "antd";

import UploadImage from "../components/UploadImage";
import DraftEditor from "../../../../components/DraftEditor";
import TextArea from "antd/lib/input/TextArea";

type RecommendationFormProps = {
    initialValues?: any,
    mode: "add" | "edit",
    onFinish: (values: any) => void,
}

const RecommendationForm = (props: RecommendationFormProps) => {
    const [form] = Form.useForm();

    const { initialValues, mode, onFinish } = props;

    return (
        <Form
            layout="vertical"
            name="recommendation_form"
            className="recommendation-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}
        >
            <Form.Item
                name="title"
                label="Tiêu đề"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <TextArea rows={4} placeholder="Tiêu đề" />
            </Form.Item>

            <Form.Item
                name="description"
                label="Mô tả"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <TextArea rows={4} placeholder="Mô tả" />
            </Form.Item>

            <Form.Item name="userPlans" label="Gói users khả dụng với khuyến nghị này">
                <Checkbox.Group>
                    <Row>
                        <Col span={24}>
                            <Checkbox value="basic">
                                Cơ bản
                            </Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="advanced">
                                Nâng cao
                            </Checkbox>
                        </Col>
                        <Col span={24}>
                            <Checkbox value="professional">
                                Chuyên nghiệp
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>

            <Form.Item
                label='Hình minh họa'
                name='thumbnail'
                valuePropName='file'
            >
                <UploadImage defaultImage={initialValues?.thumbnail} name='thumbnail' url='media/thumbnail/upload' />
            </Form.Item>

            <Form.Item
                label="Nội dung"
                name="content"
            >
                <DraftEditor defaultState={initialValues?.content}/>
            </Form.Item>

            <Form.Item>
                <Button className="mb-2" type="primary" htmlType="submit" block>
                    {mode === 'add' ? 'Thêm mới' : 'Cập nhật'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default RecommendationForm;
