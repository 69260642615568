import React from 'react';
import { Card } from 'antd';
import { TechnicalAnalysis } from 'react-tradingview-embed';
import _ from 'lodash';
import { useTranslation } from "react-i18next";

import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import StockOrderHistoryChart from "../EconomicInfo/StockOrderHistoryChart";
import theme from "../../../../../../../../themes/theme";
import * as config from "../../../../../../../../config";

type TechnicalAnalysisProps = {
    width: number;
    height: number;
    symbol: string;
    widgetSymbol: string;
    stockType: string;
};

const TechnicalAnalysisWidget = React.memo((props: TechnicalAnalysisProps) => {
    const { widgetSymbol, symbol, width, height, stockType } = props;
    const { i18n } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const locales = {
        cn: 'zh_CN',
        en: 'en',
        vi: 'vi_VN',
    };

    return (
        <>
            <Card
                bordered={false}
                style={{height: height * 2, marginBottom: 4, background: theme.colors.chartBackground[themeMode]}}
                bodyStyle={{
                    height: height * 2,
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <TechnicalAnalysis
                    widgetProps={{
                        symbol: widgetSymbol,
                        width: Math.round(width),
                        height: Math.round((width * 450 / 425)),
                        locale: locales[i18n.language],
                        colorTheme: themeMode,
                        interval: '1D',
                        showIntervalTabs: true,
                        isTransparent: true,
                    }}
                />
            </Card>
            <Card
                bordered={false}
                style={{height: height, background: theme.colors.chartBackground[themeMode]}}
                bodyStyle={{padding: 4}}
            >
                <StockOrderHistoryChart
                    width={width - 30}
                    height={height}
                    symbol={symbol}
                    priceRatio={stockType === 'derivative' ? 1: config.PRICE_RATIO}
                />
            </Card>
        </>
    )
}, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});

export default TechnicalAnalysisWidget;