import React from 'react';
import StockMarketOverviewContainer from './container';
import UserACL from '../../../../../../components/UserACL';

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 85;

const StockMarketOverview = (props) => {
    let { glContainer } = props;

    let containerWidth = glContainer.width > 0 ? glContainer.width : window.innerWidth - SIDER_WIDTH;
    let containerHeight = glContainer.height > 0 ? glContainer.height : window.innerHeight - HEADER_HEIGHT;

    let chartWidth = (containerWidth - 15) / 4 - 5; // 4 items each row
    let chartHeight = (containerHeight - 200) / 3; // 3 items each column

    return (
        <UserACL feature="stock_market_overview">
            <StockMarketOverviewContainer
                width={chartWidth}
                height={chartHeight}
            />
        </UserACL>
    )
};

export default StockMarketOverview;
