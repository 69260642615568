import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import NewsDetail from '../components/detail';
import { getNewsDetail } from "../services";

type NewsDetailContainerProps = {};

const NewsDetailContainer = (props: NewsDetailContainerProps) => {
    const { newsId } = useParams<any>();
    const [ news, setNews ] = useState<NSApp.New | undefined | null>();
    // const [ errorMessage, setErrorMessage ] = useState('');

    const fetchNewsDetail = async () => {
        let [err, resp]: any = await getNewsDetail(newsId);

        if (err) {
            // setErrorMessage('Có lỗi xảy ra khi tải tin tức hoặc tài khoản của bạn chưa được kích hoạt plugin này. Vui lòng liên hệ admin để biết thêm chi tiết.');
            return;
        }

        if (resp) {
            setNews(resp.data.news);
        }
    };

    useEffect(() => {
        if (newsId) {
            fetchNewsDetail();
        }
        //eslint-disable-next-line
    }, [newsId]);

    return (
        <NewsDetail
            newDetail={news}
        />
    )
};

export default NewsDetailContainer;