import React from "react";
import { Menu, Modal } from "antd";
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const PluginsList = (props) => {
    const { plugins } = props;
    const { t } = useTranslation();

    const onSelect = () => {
        Modal.info({
            title: t('Để sử dụng plugin vui lòng kéo thả chúng vào màn hình chính'),
            onOk() {},
        });
    };

    return (
        <Menu mode="inline" className="plugins-menu" onSelect={onSelect}>
            {plugins.map((group) => {
                return (
                    <Menu.ItemGroup key={group?.key} title={group?.group_name}>
                        {group?.plugins.map((plugin) => {
                            return (
                                <Menu.Item
                                    className="flex justify-between items-center"
                                    id={`${plugin?.key}`}
                                    key={plugin?.key}
                                    disabled={plugin?.disabled || false}
                                >
                                    {t(`${plugin?.title}`)}
                                    {plugin?.disabled && <LockOutlined/>}
                                </Menu.Item>
                            );
                        })}
                    </Menu.ItemGroup>
                )
            })}
            <Menu.Item key="news_world" disabled>- {t('Thế giới')}</Menu.Item>
            <Menu.Item key="news_market" disabled>- {t('Kinh tế - Thị trường')}</Menu.Item>
            <Menu.Item key="news_enterprise" disabled>- {t('Doanh nghiệp')}</Menu.Item>
            <Menu.Item key="news_analysis" disabled>- {t('Báo cáo phân tích')}</Menu.Item>
            <Menu.Item key="news_others" disabled>- {t('Tin tức khác')}</Menu.Item>
        </Menu>
    );
};

export default PluginsList;
