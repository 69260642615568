import React from "react";
import { Layout, Row, Col, Card, Form, Input, Button, Avatar, Space, message } from "antd";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NormalFooter from "../../components/NormalFooter";

import routes from "../../routes";
import themeModeGlobalState from "../../contexts/ThemeMode";
import LogoWhite from "../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../assets/image/logo/Fstock_black.svg";
import api from "../../services/api";

const ForgotPassword = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();
    const logo = themeMode === 'dark' ? LogoWhite : LogoBlack;

    const sendMailRecoveryPassword = async (formValues) => {
        let { email } = formValues;

        // eslint-disable-next-line
        let [err, resp]: any = await api.post("user/forgot-password", {
            email,
        });

        if (err) {
            message.error({
                key: "forgot-password-error",
                content: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
            });
            return;
        }

        message.success({
            key: "forgot-password-success",
            content: t('Gửi yêu cầu khôi phục mật khẩu thành công!'),
        });

        history.push(routes.LOGIN)
    };

    return (
        <Layout className="auth-page">
            <Row justify="center" align="middle" className="h-full">
                <Col xs={22} sm={18} md={12} lg={10} xl={5}>
                    <Space
                        className="brand flex justify-center mb-4"
                        align="center"
                    >
                        <Avatar shape="square" size={128} src={logo} />
                    </Space>
                    <Card
                        title={
                            <div className="px-3">
                                <strong className="text-lg">{t('Quên mật khẩu')}</strong>
                            </div>
                        }
                    >
                        <Form
                            size="middle"
                            name="forgot_password_form"
                            initialValues={{ remember: true }}
                            onFinish={sendMailRecoveryPassword}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    { type: "email", message: t('Email không đúng định dạng') },
                                    { required: true, message: t('Không được để trống') },
                                ]}
                                normalize={(value) => value.trim()}
                            >
                                <Input
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder={t('Nhập email để nhận đường dẫn khôi phục mật khẩu')}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    className="mb-2"
                                    type="primary"
                                    htmlType="submit"
                                    block
                                >
                                    <span className="font-bold">{t('Gửi')}</span>
                                </Button>
                            </Form.Item>
                        </Form>
                        <Link to={routes.LOGIN} className="text-gray"><ArrowLeftOutlined /> {t('Trở lại đăng nhập')}</Link>
                    </Card>
                </Col>
            </Row>

            <NormalFooter />
        </Layout>
    );
};

export default ForgotPassword;
