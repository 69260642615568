import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Space, Typography } from "antd";
import { LeftOutlined, CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import Loading from "../../../../../components/Loading";
import Api from '../../../../../services/api';

const { Title, Paragraph } = Typography;

const NotificationDetail = () => {
    const { notificationId } = useParams<{ notificationId: string}>();
    const history = useHistory();

    const [notification, setNotification] = useState<NSApp.Notification>();

    const fetchNotification = useCallback(async () => {
        const [err, resp]: any = await Api.get(`notification/${notificationId}`);

        if (err) {
            return;
        }

        const _notification = resp.data;
        const content = _notification.data.content;

        _notification.data.content = content
            .replace(/background-color: rgb\([0-9,]+\);/gm, '')
            .replace(/rgb\(0,0,0\);/gm, '');
        setNotification(_notification);
    }, [notificationId]);

    useEffect(() => {
        fetchNotification();
    }, [fetchNotification]);

    if(!notification) {
        return <Loading />
    }
    return (
        <div className="p-2">
            <p className='cursor-pointer mb-2' onClick={() => history.goBack()}><LeftOutlined /> Quay lại</p>
            <Typography>
                <Title level={2}>{notification.title}</Title>
                <Space className="text-gray mb-3">
                    <span><CalendarOutlined/> {moment(notification?.createdAt).format('L')}</span>
                    <span><ClockCircleOutlined/> {moment(notification?.createdAt).format('LT')}</span>
                </Space>
                <Paragraph>
                    <div dangerouslySetInnerHTML={{__html: notification.data.content}}/>
                </Paragraph>
            </Typography>
        </div>
    )
}

export default NotificationDetail;
