import React, { useEffect, useState, useCallback, useRef } from "react";
import { useEffectOnce, useUnmount } from 'react-use';
import { Card, Skeleton } from "antd";
import { ResponsiveContainer, BarChart, Bar, Cell, XAxis, YAxis, LabelList } from "recharts";
import { useTranslation } from "react-i18next";
import theme from "../../../../../../../themes/theme";
import * as StockMarketOverviewService from "../services/index";
import { useContextSelector } from 'use-context-selector';
import { MainIndexContext } from '../../../../../../../contexts/MainIndexProvider';
import animateGlobalState from '../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

const hoseDeclinesTitle = ['<-7%', '-7~-5%', '-5~-3%', '-3~-2%', '-2~-1%', '-1~0%'];
const hnxDeclinesTitle = ['<-10%', '-10~-8%', '-8~-6%', '-6~-4%', '-4~-2%', '-2~0%'];
const upcomDeclinesTitle = ['<-15%', '-15~-10%', '-10~-5%', '-5~-2%', '-2~0%'];
const noChangesTitle = ['0%'];
const hoseAdvancesTitle = ['0~1%', '1~2%', '2~3%', '3~5%', '5~7%', '>7%'];
const hnxAdvancesTitle = ['0~2%', '2~4%', '4~6%', '6~8%', '8~10%', '>10%'];
const upcomAdvancesTitle = ['0~2%', '2~5%', '5~10%', '10~15%', '>15%'];

type StockAmountChangeChartProps = {
    width: number,
    height: number,
    exchange: string,
    indexId: string
};

const CustomizedAxisTick = (props) => {
    const [themeMode,] = themeModeGlobalState();
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={4} y={0} dy={4} textAnchor="end"
                fontSize={10} fill={theme.colors.chartAxis[themeMode]} transform="rotate(-35)"
            >
                {payload.value}
            </text>
        </g>
    );
};

const StockAmountChangeChart = (props: StockAmountChangeChartProps) => {
    const { width, height, exchange, indexId } = props;
    const [ loading, setLoading ] = useState(true);
    const [ barChartData, setBarChartData ] = useState<NSApp.BarChartItemType[]>([]);
    const [ stackChartData, setStackChartData ] = useState<NSApp.StackChartItemType[]>([]);
    const [ stockAmountData, setStockAmountData ] = useState<any>();
    const dataIntervalRef = useRef<any>(null);
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();
    const { t } = useTranslation();

    const mainIndexState = useContextSelector(
        MainIndexContext,
        (s) => s[0][indexId],
    );

    const itemHeight = height && height > 150 ? height - 33 : 118;

    const fetchStockAmount = async () => {
        let response: any = await StockMarketOverviewService.fetchStockAmountChange(exchange);
        setStockAmountData(response.stocks);
    };

    const formatBarChartData = useCallback((data) => {
        switch (exchange) {
            case "hose":
                return formatStockGroup(data.stockDown, hoseDeclinesTitle, 'declines')
                    .concat(formatStockGroup(data.stockUnchanged, noChangesTitle, 'noChanges'))
                    .concat(formatStockGroup(data.stockUp, hoseAdvancesTitle, 'advances'));
            case "hnx":
                return formatStockGroup(data.stockDown, hnxDeclinesTitle, 'declines')
                    .concat(formatStockGroup(data.stockUnchanged, noChangesTitle, 'noChanges'))
                    .concat(formatStockGroup(data.stockUp, hnxAdvancesTitle, 'advances'));
            case "upcom":
                return formatStockGroup(data.stockDown, upcomDeclinesTitle, 'declines')
                    .concat(formatStockGroup(data.stockUnchanged, noChangesTitle, 'noChanges'))
                    .concat(formatStockGroup(data.stockUp, upcomAdvancesTitle, 'advances'));
        }
    }, [exchange]);

    const formatStackChartData = (data) => {
        const returnObj = {
            name: `${data.indexID}-stocksChange`,
            floor: data.floor,
            declines: data.declines,
            nochanges: data.nochanges,
            advances: data.advances,
            ceiling: data.ceiling
        }

        return [returnObj];
    };

    const formatAmountChartData = useCallback(() => {
        if (stockAmountData) {
            let formattedBarChartData: any = formatBarChartData(stockAmountData);
            let formattedStackChartData = formatStackChartData(mainIndexState || {});
            setBarChartData(formattedBarChartData);
            setStackChartData(formattedStackChartData);
            setLoading(false);
        }
    }, [formatBarChartData, mainIndexState, stockAmountData]);

    const formatStockGroup = (data, titleArr, stockStatus) => {
        let formattedData: NSApp.BarChartItemType[] = [];

        data?.data.map((entry, index) => {
            formattedData.push({
                title: titleArr[index],
                total: entry?.length,
                status: stockStatus
            });
        });

        return formattedData;
    };

    const fetchMainIndexValuesInterval = async () => {
        if (dataIntervalRef.current) {
            clearInterval(dataIntervalRef.current);
        }

        dataIntervalRef.current = setInterval(async () => {
            fetchStockAmount();
        }, 60 * 1000);
    };

    useEffectOnce(() => {
        fetchStockAmount();
        fetchMainIndexValuesInterval();
    });

    useEffect(() => {
        formatAmountChartData();
    }, [formatAmountChartData]);

    useUnmount(() => {
        if (dataIntervalRef.current) {
            clearInterval(dataIntervalRef.current);
            dataIntervalRef.current = undefined;
        }
    });

    const renderCell = (data) => {
        return data.map((entry: NSApp.BarChartItemType, index) => {
            let barColor = '';

            switch (entry.status) {
                case "declines":
                    barColor = theme.colors.red.background;
                    break;
                case "noChanges":
                    barColor = theme.colors.yellow.background;
                    break;
                case "advances":
                    barColor = theme.colors.green.background;
                    break;
            }

            return <Cell key={`cell-${index}`} fill={barColor}/>
        })
    };

    return (
        <Card
            size="small"
            bodyStyle={{
                padding: '5px 0 0 0',
                backgroundColor: theme.colors.chartBackground[themeMode]
            }}
        >
            {loading
                ? <div className="p-1" style={{height: itemHeight + 38}}><Skeleton /></div>
                : <>
                    {!barChartData
                        ? <div className="flex justify-center items-center text-gray" style={{height: itemHeight + 38}}>{t('Không có dữ liệu')}</div>
                        : <>
                            <BarChart
                                width={width}
                                height={itemHeight}
                                data={barChartData}
                            >
                                <XAxis
                                    dataKey="title"
                                    tickLine={false}
                                    tick={<CustomizedAxisTick/>}
                                    interval={0}
                                    padding={{left: 10}}
                                    stroke={theme.colors.chartAxis[themeMode]}
                                    strokeWidth={0.5}
                                />
                                <YAxis hide padding={{top: 10}}/>

                                <Bar dataKey="total" isAnimationActive={animate} radius={theme.chart.bar.radius.vertical}>
                                    <LabelList
                                        dataKey="total"
                                        position="top"
                                        style={{fontSize: theme.fontSizes.tiny, fontWeight: 'bold'}}
                                    />
                                    {renderCell(barChartData)}
                                </Bar>
                            </BarChart>

                            <ResponsiveContainer height={18} width={"100%"}>
                                <BarChart
                                    layout="vertical"
                                    data={stackChartData}
                                    stackOffset="expand"
                                >
                                    <XAxis hide type="number"/>

                                    <YAxis
                                        hide
                                        type="category"
                                        dataKey="name"
                                    />

                                    <Bar dataKey="floor" isAnimationActive={animate} fill={theme.colors.teal.background} stackId={stackChartData[0].name}/>
                                    <Bar dataKey="declines" isAnimationActive={animate} fill={theme.colors.red.background} stackId={stackChartData[0].name}/>
                                    <Bar dataKey="nochanges" isAnimationActive={animate} fill={theme.colors.yellow.background} stackId={stackChartData[0].name}/>
                                    <Bar dataKey="advances" isAnimationActive={animate} fill={theme.colors.green.background} stackId={stackChartData[0].name}/>
                                    <Bar dataKey="ceiling" isAnimationActive={animate} fill={theme.colors.purple.background} stackId={stackChartData[0].name}/>
                                </BarChart>
                            </ResponsiveContainer>

                            <div className="flex justify-between px-1 mb-1">
                                <small className="font-bold" style={{color: theme.colors.red.background}}>
                                    {t('Giảm')} {stackChartData[0].declines}
                                </small>
                                <small className="font-bold" style={{color: theme.colors.green.background}}>
                                    {t('Tăng')} {stackChartData[0].advances}
                                </small>
                            </div>
                        </>
                    }
                </>
            }

            <div className="text-center mb-1">
                <span className={`text-${themeMode === 'dark' ? 'white' : 'black'} font-bold`}>{t('Biến động cổ phiếu')}</span>
            </div>
        </Card>
    )
}

export default StockAmountChangeChart;