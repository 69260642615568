import React from 'react';
import ReactDOM from 'react-dom';
import GoldenLayout from 'golden-layout';
// import "golden-layout/src/css/goldenlayout-base.css";
// import "golden-layout/src/css/goldenlayout-light-theme.css";
import $ from 'jquery';
import TagManager from 'react-gtm-module';
import { DEFAULT_LAYOUT_CONFIG } from '../../config';

window.$ = $;
window.jQuery = $;
window.jquery = $;

export class GoldenLayoutComponent extends React.Component<any> {
    state: {[key:string]: any} = {
        renderPanels: [],
        forceUpdate: true
    };

    containerRef = React.createRef();
    goldenLayoutInstance;

    render() {
        let panels: any = Array.from(this.state.renderPanels || []);

        return (
            <div ref={this.containerRef} {...this.props.htmlAttrs}>
                {panels.map((panel, index) => {
                    const isHidden = panel._container.isHidden;

                    if (isHidden) {
                        return ReactDOM.createPortal(
                            <div></div>,
                            panel._container.getElement()[0]
                        );
                    }

                    return ReactDOM.createPortal(
                        panel._getReactComponent(this.handleClick(panel._container._config.title)),
                        panel._container.getElement()[0]
                    );
                })}
            </div>
        );
    }

    //google tag manager 
    handleClick (tabName) {
        TagManager.dataLayer({
            dataLayer: {
                event: tabName,
            },
        });
    }

    componentRender(reactComponentHandler) {
        this.setState((state: any) => {
            let newRenderPanels = new Set(state.renderPanels);
            try {
                newRenderPanels.add(reactComponentHandler);
            } catch (err) {
                console.log(err)
            }
            return { renderPanels: newRenderPanels };
        });
    }

    componentDestroy(reactComponentHandler) {
        this.setState((state: any) => {
            let newRenderPanels = new Set(state.renderPanels);
            newRenderPanels.delete(reactComponentHandler);
            return { renderPanels: newRenderPanels };
        });
    }

    initGoldenLayout(config) {
        this.goldenLayoutInstance = new GoldenLayout(config, this.containerRef.current);

        if (this.props.registerComponents instanceof Function) {
            this.props.registerComponents(this.goldenLayoutInstance);
        }

        this.goldenLayoutInstance.reactContainer = this;
        this.goldenLayoutInstance.init();

        this.goldenLayoutInstance.on('stateChanged', () => {
            this.setState({forceUpdate: true})
        })
    }

    componentDidMount() {
        try {
            this.initGoldenLayout(this.props.config || DEFAULT_LAYOUT_CONFIG);
        } catch (err) {
            this.initGoldenLayout(DEFAULT_LAYOUT_CONFIG);
        }
    }
}

//Patching internal GoldenLayout.__lm.utils.ReactComponentHandler:

const ReactComponentHandler = GoldenLayout["__lm"].utils.ReactComponentHandler;

class ReactComponentHandlerPatched extends ReactComponentHandler {
    [x: string]: any;
    _render() {
        var reactContainer = this._container.layoutManager.reactContainer; //Instance of GoldenLayoutComponent class
        if (reactContainer && reactContainer.componentRender) {
            reactContainer.componentRender(this);
        }
    }

    _destroy() {
        // ReactDOM.unmountComponentAtNode( this._container.getElement()[ 0 ] );

        // Unmount react component when close window golden layout
        const reactContainer = this._container.layoutManager.reactContainer;
        if (reactContainer && reactContainer.componentDestroy) {
            reactContainer.componentDestroy(this);
        }

        this._container.off("open", this._render, this);
        this._container.off("destroy", this._destroy, this);
    }

    _getReactComponent() {
        //console.log(this._container);
        //the following method is absolute copy of the original, provided to prevent depenency on window.React
        var defaultProps = {
            glEventHub: this._container.layoutManager.eventHub,
            glContainer: this._container,
            id: this._container._config.id
        };

        var props = $.extend(defaultProps, this._container._config.props);

        return React.createElement(this._reactClass, props);
    }
}

GoldenLayout["__lm"].utils.ReactComponentHandler = ReactComponentHandlerPatched;
