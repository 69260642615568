import React, { useState, useEffect, useCallback, useRef } from "react";
import { Card } from "antd";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell } from "recharts";
import { useTranslation } from "react-i18next";
import _ from 'lodash';

import { aggregateVolumeByStockPrice } from "../../../../../../../../services/stock-price";
import { getChartColor, compactNumberFormat } from "../../../../../../../../services/utils";
import { priceFormat } from "../../../../../../../../services/stock-price";
import theme from "../../../../../../../../themes/theme";
import * as config from '../../../../../../../../config';
import { useContextSelector } from 'use-context-selector';
import { StockContext } from '../../../../../../../../contexts/StocksProvider';
import animateGlobalState from '../../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import Skeleton from "../../../../../../../../components/Skeleton";
import CustomEmpty from "../../../../../../../../components/CustomEmpty";
import * as StockDetailServices from '../../services';
import { subscribeNewMatchedStockOrder } from "../../../../../../../../services/socket";

type VolumeByStockPriceChartProps = {
    width: number;
    height: number;
    priceRatio?: number;
    symbol: string;
};

const VolumeByStockPriceChart = React.memo((props: VolumeByStockPriceChartProps) => {
    const { width, height, symbol } = props;
    const [volumeByStockPrice, setVolumeByStockPrice] = useState<Array<any>>([]);
    const [stockDeals, setStockDeals] = useState<NSApp.MatchedStockOrder[]>([]);
    const [newMatchedStockOrders, setNewMatchedStockOrders] = useState<NSApp.MatchedStockOrder[]>([]);
    const [loading, setLoading] = useState(false);
    const [themeMode,] = themeModeGlobalState();
    const [animate,] = animateGlobalState();

    const { t } = useTranslation();
    const matchedOrdersSocketRef = useRef<any>();
    const stockDetail = useContextSelector(
        StockContext,
        (s) => s[0][symbol],
    );


    const fetchStockDealsList = async (symbol: string) => {
        setLoading(true);
        // eslint-disable-next-line
        let [err, resp] = await StockDetailServices.fetchStockDeals(symbol);
        setLoading(false);

        if (err) {
            return;
        }

        const { stockDeals } = resp;

        setStockDeals(stockDeals);
        const volumeByStockPrice = aggregateVolumeByStockPrice(stockDeals);
        setVolumeByStockPrice(volumeByStockPrice);
    };

    useEffect(() => {
        fetchStockDealsList(symbol);
    }, [symbol]);

    const initMatchedOrderSocket = useCallback(() => {
        subscribeNewMatchedStockOrder((matchedOrders: NSApp.MatchedStockOrder[]) => {
            let matchedOrdersFiltered = matchedOrders.filter(matchedOrder => matchedOrder.stockCode === symbol);
            if (matchedOrdersFiltered.length) {
                setNewMatchedStockOrders(matchedOrdersFiltered);
            }
        }).then((socket) => matchedOrdersSocketRef.current = socket);
    }, [symbol]);

    useEffect(() => {
        initMatchedOrderSocket();
    }, [initMatchedOrderSocket]);

    useEffect(() => {
        if (newMatchedStockOrders.length) {
            let newStockDeals = [...newMatchedStockOrders, ...stockDeals];
            let newVolumeByStockPrice = aggregateVolumeByStockPrice(newStockDeals);

            setStockDeals(newStockDeals);
            setVolumeByStockPrice(newVolumeByStockPrice);
        }
    }, [newMatchedStockOrders]); // eslint-disable-line

    const cardBodyHeight = height - 8;
    const mark = 25; // to hide tick + label of bar if the amount of prices too much
    const priceRatio = props.priceRatio ? props.priceRatio : config.PRICE_RATIO;
    let YaxisInterval = 0;
    for (let i = mark; i < volumeByStockPrice.length; i = i + mark) {
        YaxisInterval += 1;
    }

    const renderCell = (volumeByStockPrice) => {
        return volumeByStockPrice.map((record, index) => {
            if (stockDetail) {
                return <Cell
                    key={`cell-${index}`}
                    fill={getChartColor(stockDetail, themeMode, record?.price)}
                />
            }
            return <div key={index}></div>;
        })
    };

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-chart-tooltip">
                    <div className="text-black">
                        <div>{t('Giá')}: <strong>{priceFormat(label, priceRatio)}</strong></div>
                        <div>{t('KL')}: <strong>{payload[0]?.value.toLocaleString(config.LOCALE_NUMBER_FORMAT)}</strong></div>
                    </div>
                </div>
            )
        }

        return null;
    };

    return (
        <Card
            bordered={false}
            style={{height}}
            size="small"
            bodyStyle={{ padding: 4, backgroundColor: theme.colors.chartBackground[themeMode] }}
        >
            {loading
                ? <div style={{height: cardBodyHeight}}><Skeleton /></div>
                : !volumeByStockPrice.length
                    ? <CustomEmpty height={cardBodyHeight} />
                    : <BarChart
                        layout="vertical"
                        width={width}
                        height={cardBodyHeight}
                        data={volumeByStockPrice}
                    >
                        <CartesianGrid
                            horizontal={false}
                            stroke={theme.colors.chartAxis[themeMode]}
                            strokeWidth={0.1}
                            strokeDasharray="8 4"
                        />

                        <XAxis
                            orientation="top"
                            type="number"
                            tick={{fontSize: 12}}
                            tickLine={false}
                            tickFormatter={(tickValue) => compactNumberFormat(tickValue, true)}
                            stroke={theme.colors.chartAxis[themeMode]}
                            strokeWidth={0.5}
                            padding={{right: 50}}
                            height={20}
                        />

                        <YAxis
                            type="category"
                            dataKey="price"
                            tick={{fontSize: 12}}
                            tickLine={false}
                            tickFormatter={(tickValue) => priceFormat(tickValue, priceRatio)}
                            stroke={theme.colors.chartAxis[themeMode]}
                            strokeWidth={0.5}
                            interval={YaxisInterval}
                        />

                        <Tooltip cursor={{ fill: theme.colors.blurWhite, opacity: 0.2 }} content={renderTooltip}/>

                        <Bar dataKey="totalVolume" isAnimationActive={animate} radius={theme.chart.bar.radius.horizontal}>
                            {/* <LabelList
                                dataKey="totalVolume"
                                position="right"
                                content={(label) => (label.index % (YaxisInterval+1) === 0) ? label.value.toLocaleString(config.LOCALE_NUMBER_FORMAT) : ""}
                                style={{fontSize: theme.fontSizes.tiny, fill: theme.colors.white, fontWeight: 'bold'}}
                            /> */}
                            {renderCell(volumeByStockPrice)}
                        </Bar>
                    </BarChart>
                }
        </Card>
    )
}, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});

export default VolumeByStockPriceChart;
