import api from '../../../../../../../services/api';
import moment from "moment";

export const fetchStockDeals = async (stockSymbol: string) => {
    let [error, response]: any = await api.get('stock/stockDeals', {
        code: stockSymbol,
    });

    if (error) {
        return [error, null];
    }

    return [null, response.data];
};

export const fetchCompanyProfile = async (stockSymbol: string) => {
    let [error, response]: any = await api.get(`company/profile/${stockSymbol}`);

    if (error) {
        return [error, null];
    }

    return [null, response.data];
};

export const fetchStockDetail = async (stockSymbol: string) => {
    let [error, response]: any = await api.get('stock/detail', {
        stockCode: stockSymbol,
    });

    if (error) {
        return [error, null];
    }

    return [null, response.data];
};

export const fetchFinancialReportsYearly = async (stockSymbol: string) => {
    let [error, response]: any = await api.get(`financial-report/${stockSymbol}/yearly`, {})

    if (error) {
        return [error, null];
    }

    return [null, response.data];
}

export const fetchFinancialReportsQuarter = async (stockSymbol: string) => {
    let [error, response]: any = await api.get(`financial-report/${stockSymbol}/quarter`, {})

    if (error) {
        return [error, null];
    }

    return [null, response.data];
}

export const fetchCorporateActions = async (stockSymbol: string, query: any) => {
    return await api.get(`company/corporate-actions/${stockSymbol}`, query);
}


export const fetchHistoriesOfDay = async (stockSymbol: string) => {
    let [error, response]: any = await api.get(`history/${stockSymbol}/histories-of-day`);

    if (error) {
        return [error, null];
    }

    return [null, response.data];
};

export const fetchForeign = async (stockCode: string, limit: number = 10) => {
    let [error, response]: any = await api.get(`plugins/foreign-data/stock/${stockCode}`, {
        limit
    });

    if (error) {
        return [error, null];
    }

    return [null, response.data];
};

export const fetchForeignMonthly = async (stockCode: string) => {
    let [error, response]: any = await api.get(`plugins/foreign-data/stock-monthly/${stockCode}`);

    if (error) {
        return [error, null];
    }

    const data = Object.values(response.data);

    return [null, data.sort((a: any, b: any) => moment(a.date) < moment(b.date) ? 1 : -1)];
};