import React, { useState, useEffect } from "react";
import { Table, Button, Space, Popconfirm, message } from "antd";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import * as config from "../../../../../config";
import routes from "../../../../../routes";
import MyBreadcrumb from "../../../../../components/MyBreadcrumb";
import { NewsProviderType } from "../../../../../types/plugins/news";
import { useReducedState, toRoute } from "../../../../../services/utils";
import AdminPermission from "../../../../../components/AdminPermission";

const NewsProviderListPageComponent = () => {
    const [ providerList, setProviderList ] = useState();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchProviders = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/news/provider', {
            filter: {}, page: pagination.current, pageSize: config.PAGE_SIZE
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { providers, total } = resp?.data;

        setProviderList(providers);
        setPagination({ total });
    };

    const deleteProvider = async (providerId) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.delete(`plugins/news/provider/${providerId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Xóa thành công');
        fetchProviders();
    };

    useEffect(() => {
        fetchProviders();
    // eslint-disable-next-line
    }, [pagination.current]);

    const columns = [
        {
            title: 'Tên',
            textWrap: 'word-break',
            ellipsis: true,
            render: (provider) => <a href={provider?.link} rel="noopener noreferrer" target='_blank'>{provider?.name}</a>
        },
        {
            title: 'Action',
            render: (provider) => {
                return <Space>
                    <Link to={toRoute(routes.ADMIN_NEWS_PROVIDER_EDIT, { providerId: provider._id })}>
                        <Button type="primary">Sửa</Button>
                    </Link>
                    <Popconfirm
                        title="Bạn chắc chắn muốn xóa nguồn cung cấp này?"
                        onConfirm={() => deleteProvider(provider._id)}
                        okText="Xóa"
                        cancelText="Hủy"
                    >
                        <Button type="primary" danger>Xóa</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ];

    return (
        <>
            <MyBreadcrumb items={[
                { label: 'Tin tức' },
                { label: 'Nguồn cung cấp' },
            ]} />

            <div className="mt-3">
                <Button type="primary">
                    <Link to={routes.ADMIN_NEWS_PROVIDER_ADD}>Thêm mới nguồn cung cấp</Link>
                </Button>
            </div>

            <div className="mt-3">
                <Table
                    columns={columns}
                    dataSource={providerList}
                    rowKey={(r: NewsProviderType) => r?._id}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                />
            </div>
        </>
    );
}

const NewsProviderListPage = () => {
    return <AdminPermission roleName={[]}>
        <NewsProviderListPageComponent />
    </AdminPermission>
}

export default NewsProviderListPage;