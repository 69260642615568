import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Form, message, Input, Card, Checkbox, Button, Modal, Space, InputNumber, Select } from "antd";
import api from "../../../../services/api";
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Loading from "../../../../components/Loading";

const { confirm } = Modal;

type PlanFormProps = {
    initialValues?: any;
    onFinish: (values: any) => void;
    onDelete?: () => void;
    mode?: "add" | "edit";
};

const PlanForm = (props: PlanFormProps) => {
    const [featureOptions, setFeatureOptions]: any = useState();
    const { initialValues, mode, onFinish, onDelete } = props;

    const fetchFeatures = async () => {
        const [err, resp]: any = await api.get("/plan/features");

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let _features: any = [];
        const groupsFeature = resp.data;
        for (let data of groupsFeature) {
            _features = _features.concat(data.features)
        }

        let features = _features.map((feature) => {
            return {
                style: { display: "flex" },
                label: feature.name,
                value: feature.id,
            };
        });

        setFeatureOptions(features);
    };

    useEffectOnce(() => {
        fetchFeatures();
    });

    const showConfirmSubmit = (formValues: any) => {
        confirm({
            title: `Bạn có chắc chắn muốn ${
                mode === "add" ? "thêm" : "cập nhật"
            } dịch vụ này không?`,
            icon: <ExclamationCircleOutlined />,
            content: formValues.title,
            onOk: async () => {
                onFinish(formValues);
            },
            onCancel() {
                console.log("Hủy");
            },
        });
    };

    const showConfirmDelete = () => {
        confirm({
            title: "Bạn có chắc chắn muốn xoá ?",
            icon: <ExclamationCircleOutlined />,
            onOk: onDelete,
            onCancel() {
                console.log("Huỷ");
            },
        });
    };

    if (!featureOptions) {
        return <Loading />;
    }

    return (
        <Card>
            <Form
                className="m-auto mt-5 w-3/6"
                layout="vertical"
                onFinish={showConfirmSubmit}
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Tên dịch vụ"
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng nhập tên dịch vụ"
                        },
                    ]}
                >
                    <Input placeholder="Nhập tên dịch vụ" />
                </Form.Item>

                <Form.Item
                    name="key"
                    label="Key"
                >
                    <Input placeholder="Key của gói dịch vụ" />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Mô tả"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập mô tả'
                        },
                    ]}
                >
                    <Input placeholder="Nhập mô tả" />
                </Form.Item>

                <Form.Item name="isFree" label="Tính phí" className="w-20">
                    <Select defaultValue={false}>
                        <Select.Option value={false}> Có</Select.Option>
                        <Select.Option value={true}> Không</Select.Option>
                    </Select>

                </Form.Item>

                <p>Các mức giá:</p>
                <Form.List name="infoPlans">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name}) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: "flex",
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        name={[name, "period"]}
                                        label="Kỳ hạn"
                                    >
                                        <InputNumber 
                                            placeholder="Tháng" 
                                            min={0} 
                                            style={{width: '100%'}} 
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, "price"]}
                                        label="Giá"
                                    >
                                        <InputNumber 
                                            placeholder="VNĐ" 
                                            step={1000} min={0} 
                                            style={{width: '100%'}}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        onClick={() => remove(name)}
                                    />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Thêm
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                
                <Form.Item name="featureIds" label="Chọn tính năng">
                    <Checkbox.Group options={featureOptions} ></Checkbox.Group>
                </Form.Item>

                <Button
                    className="mb-2 float-right"
                    type="primary"
                    htmlType="submit"
                    block
                >
                    {mode === "edit" ? "Cập nhật" : "Thêm mới"}
                </Button>

                <Button
                    className="mb-2"
                    hidden={!(mode === "edit")}
                    block
                    onClick={showConfirmDelete}
                >
                    Xoá
                </Button>
            </Form>
        </Card>
    );
};

export default PlanForm;
