import { useEffect } from 'react';
import _ from 'lodash';

import * as WatchlistServices from '../services';
import { useReducedState } from "../../../../../services/utils";
import { useWatchListState } from "../store";

export const useWatchlistClient = () => {
    const watchListStoreData = useWatchListState();
    const [ watchListState, setWatchListState ] = useReducedState(watchListStoreData);

    const fetchWatchlist = async () => {
        let watchlists = await WatchlistServices.fetchWatchlist();

        watchlists = watchlists.map(w => {
            if (w.id === 'FU') {
                w.stocks = w.stocks.filter(s => s.startsWith('vn30f'));
            }
            return w;
        })

        setWatchListState({ watchlists });
        return watchlists;
    }

    const getStocksWatchListDetail = async (id: string) => {
        let response = await WatchlistServices.getStocksWatchListDetail(id);

        return response;
    };

    const addWatchList = async (name: string) => {
        let [error, response] = await WatchlistServices.addWatchList(name);

        if (error) {
            return [error, null];
        }

        let { watchlists } = watchListState;

        watchlists = [...watchlists, response];
        setWatchListState({ watchlists });

        return [null, response];
    };

    const updateWatchList = async (activeList, newName) => {
        let [error, watchlistUpdated] = await WatchlistServices.updateWatchList(activeList, newName);

        if (error) {
            return [error, null];
        }

        let watchlists = watchListState.watchlists.map(watchlist => {
            if (watchlistUpdated.id === watchlist.id) {
                return watchlistUpdated;
            }

            return watchlist;
        });

        setWatchListState({ watchlists });
        return [null, watchlistUpdated];
    };

    const deleteWatchList = async (id: string) => {
        let [error, watchlistDeleted] = await WatchlistServices.deleteWatchList(id);

        if (error) {
            return [error, null];
        }

        let { watchlists } = watchListState;
        _.remove(watchListState.watchlists, (w: NSApp.Watchlist) => {
            return w.id === watchlistDeleted.id
        });
        setWatchListState({ watchlists });

        return [null, watchlistDeleted];
    };

    const updateStockWatchList = async (watchlist: NSApp.Watchlist, stockCode: string, action: string) => {
        let [error, response] = await WatchlistServices.updateStockToWatchList(watchlist.id, stockCode, action);

        if (error) {
            return [error, null];
        }

        let { watchlists } = watchListState;

        watchlists = watchlists.map((list: NSApp.Watchlist) => {
            if (response.id === list.id) return response;
            return list;
        });

        setWatchListState({ watchlists: watchlists });
        return [null, response];
    };


    useEffect(() => {
        const { watchlists } = watchListState;

        if (!watchlists.length) {
            fetchWatchlist();
        }
    //eslint-disable-next-line
    }, [])

    return {
        watchListState,
        fetchWatchlist,
        getStocksWatchListDetail,
        addWatchList,
        updateWatchList,
        deleteWatchList,
        updateStockWatchList
    }
};

