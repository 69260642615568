import React, { useState } from "react";
import { useEffectOnce } from 'react-use';
import { ComposedChart, Line, Bar, YAxis, XAxis, ReferenceLine, Label, CartesianGrid } from "recharts";
import { priceFormat } from "../../../../../../../../services/stock-price";
import moment from "moment";
import _ from 'lodash';
import theme from "../../../../../../../../themes/theme";
import * as config from "../../../../../../../../config";
import { useContextSelector } from 'use-context-selector';
import { StockContext } from '../../../../../../../../contexts/StocksProvider';
import animateGlobalState from '../../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import * as StockDetailServices from '../../services';
import Skeleton from "../../../../../../../../components/Skeleton";
import CustomEmpty from "../../../../../../../../components/CustomEmpty";

type OrderHistoryChartContentProps = {
    width: number;
    height: number;
    symbol: string;
    priceRatio?: number
};

const StockOrderHistoryChart = (props: OrderHistoryChartContentProps) => {
    const { width, height, symbol } = props;

    const [dayHistories, setDayHistories] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();

    const stockDetail = useContextSelector(
        StockContext,
        (s) => s[0][symbol],
    );

    const fetchHistoriesOfDay = async () => {
        setLoading(true);
        let [error, response] = await StockDetailServices.fetchHistoriesOfDay(symbol);

        if (error) {
            return;
        }

        setDayHistories(response);
        setLoading(false);
    };

    useEffectOnce(() => {
        fetchHistoriesOfDay();
    });

    if (loading) {
        return <Skeleton />
    }

    if (!stockDetail || !dayHistories.length) {
        return <div style={{height}}><CustomEmpty /></div>
    }

    const maxCurrentPrice = _.max(dayHistories.map((deal) => deal.close)) as number;
    const minCurrentPrice = _.min(dayHistories.map((deal) => deal.close)) as number;

    let deals = dayHistories.map(s => {
        return {
            ...s,
            dateTimestamp: moment(s.date).valueOf()
        }
    });
    deals = _.unionBy(deals, 'dateTimestamp');

    // Set amplitude of chart
    const marginAmpValue = Math.abs(stockDetail.priceChange * 2);
    const YAxisDomainCurrentPrice = [
        (dataMin) => dataMin - marginAmpValue,
        (dataMax) => dataMax + marginAmpValue,
    ];

    const YAxisDomainTotalVolume = ["auto", (dataMax) => dataMax * 2];
    // End Set amplitude of chart

    const generateTimelineTicks = (startTime) => {
        let tickValuesInit = new Array(6).fill(1);

        return tickValuesInit.map((tick, index) => {
            if (index === 0) {
                return startTime;
            }
            return moment(startTime).add(index, "hours").valueOf();
        });
    };

    const startTime = moment(deals[0].date).startOf('hour').valueOf();
    const endTime = moment(startTime).add(6, "hours").valueOf();

    // const endTime = moment(startTime).valueOf();
    const priceRatio = props.priceRatio ? props.priceRatio : config.PRICE_RATIO;

    // Set color for price line chart
    const gradientOffset = () => {
        if (!stockDetail) {
            return;
        }

        if (maxCurrentPrice <= stockDetail.referencePrice) {
            return 0; // 100% red line
        } else if (minCurrentPrice >= stockDetail.referencePrice) {
            return 1; // 100% green line
        } else {
            return (
                1 - (stockDetail.referencePrice - minCurrentPrice) / (maxCurrentPrice - minCurrentPrice)
            ); // percent of red line / Difference of maxIndex and minIndex
        }
    };
    // End Set color for price line chart

    return (
        <ComposedChart width={width} height={height} data={deals}>
            <defs>
                <linearGradient id={`${symbol}_${width}_${height}`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset={gradientOffset()} stopColor={theme.colors.green.background}/>
                    <stop offset={gradientOffset()} stopColor={theme.colors.red.background}/>
                </linearGradient>
            </defs>

            <ReferenceLine
                yAxisId="close"
                y={stockDetail.referencePrice}
                label={<Label
                    value={priceFormat(stockDetail.referencePrice, priceRatio)}
                    offset={5}
                    position="top"
                    style={{
                        fill: theme.colors.gray.text[themeMode],
                        fontSize: theme.fontSizes.tiny,
                        fontWeight: 700
                    }}
                />}
                stroke={theme.colors.yellow.background}
                strokeWidth={0.3}
                strokeDasharray="3 3"
            />

            <XAxis
                dataKey="dateTimestamp"
                type="number"
                interval={"preserveStartEnd"}
                stroke={theme.colors.chartAxis[themeMode]}
                strokeWidth={0}
                domain={[startTime, endTime]}
                tick={{fontSize: 11}}
                tickLine={false}
                ticks={generateTimelineTicks(startTime)}
                // offset 0 because timestamp from exchange is GMT+7.
                tickFormatter={(tickValue) => `${moment(tickValue).format("HH")}:00`}
            />

            <YAxis
                yAxisId="close"
                domain={YAxisDomainCurrentPrice}
                stroke={theme.colors.chartAxis[themeMode]}
                strokeWidth={0}
                tick={{fontSize: 11}}
                tickLine={false}
                tickFormatter={(tickValue) => priceFormat(tickValue, priceRatio)}
            />

            <YAxis yAxisId="volume" domain={YAxisDomainTotalVolume} hide />

            <CartesianGrid
                vertical={false}
                stroke={theme.colors.gray.background}
                strokeWidth={0.1}
            />

            <Bar
                yAxisId="volume"
                dataKey='volume'
                barSize={2}
                fill={theme.colors.teal.background}
                isAnimationActive={animate}
            />

            <Line
                isAnimationActive={animate}
                yAxisId="close"
                connectNulls
                type="monotone"
                dataKey="close"
                stroke={`url(#${symbol}_${width}_${height})`}
                strokeWidth={1.5}
                dot={false}
            />
        </ComposedChart>
    )
}

export default StockOrderHistoryChart;
