import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Card, Table, Button, Space, message } from "antd";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import { toRoute } from "../../../../services/utils";

import api from "../../../../services/api";
import routes from "../../../../routes";
import AdminPermission from "../../../../components/AdminPermission";

const UserRoleListPageComponent = () => {
    const [ roleList, setRoleList ] = useState();

    const fetchRoleList = async () => {
        const [err, resp]: any = await api.get('/permission/roles');

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setRoleList(resp.data);
    };

    useEffect(() => {
        fetchRoleList();
    }, []);

    const columns: any = [
        {
            title: 'Tên',
            textWrap: 'word-break',
            dataIndex: 'name',
            ellipsis: true,
            render: (name) => <span className="capitalize">{name}</span>
        },

        {
            title: 'Action',
            render: (role) => {
                return <Space>
                    <Link to={toRoute(routes.ADMIN_USER_ROLES_EDIT, { userRoleId: role.id })}>
                        <Button type="primary">Chỉnh sửa quyền truy cập</Button>
                    </Link>
                </Space>
            }
        }
    ];

    return (
        <Card>
            <MyBreadcrumd items={[{ label: 'Nhóm người dùng' }]} />

            <div className="mt-2">
                <Table
                    columns={columns}
                    dataSource={roleList}
                    rowKey={(r: any) => r?.id}
                />
            </div>
        </Card>
    )
};

const UserRoleListPage = () => {
    return <AdminPermission roleName={[]}>
        <UserRoleListPageComponent />
    </AdminPermission>
}

export default UserRoleListPage;