import React, { useState, useEffect, useRef, useMemo } from "react";
import { VariableSizeGrid as Grid } from "react-window";
import ResizeObserver from "rc-resize-observer";
import classNames from "classnames";
import { Table } from "antd";
import _ from 'lodash';
import { useDebounce } from 'react-use';

import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import PriceTableCell from './PriceTableCell';
// import Loading from '../../../../../../../components/Loading';

const MIN_TABLE_WIDTH = 1140;

const VirtualTablePrice = (props: any) => {
    const { scroll, changeTradingView, layoutHeight, id } = props;
    const prevDataSourceRef = useRef<any>();

    const [ tableWidth, setTableWidth ] = useState(0);
    const [ tableWidthDebouncedValue, setTableWidthDebouncedValue ] = useState(0);
    const [ groupColumns, setGroupColumns ] = useState<any[]>(props.columns);
    const [ flatColumns, setFlatColumns ] = useState<any[]>();

    const [hoveredRowIndex, setHoveredRowIndex] = useState<number>();
    const preIndexClass = id ? id : _.random(0, 100);

    const [themeMode,] = themeModeGlobalState();

    const itemData = useMemo(
        () => ({
            hoveredRowIndex,
            setHoveredRowIndex
        }),
        [hoveredRowIndex]
    );

    const [,] = useDebounce(
        () => { setTableWidthDebouncedValue(tableWidth) },
        500,
        [tableWidth]
    );

    const getColumnsWithoutGroup = (groupColumns) => {
        let columnsWithoutGroup: any[] = [];

        for (const column of groupColumns) {
            if (column.children) {
                columnsWithoutGroup = columnsWithoutGroup.concat(column.children)
            } else {
                columnsWithoutGroup.push(column)
            }
        }

        return columnsWithoutGroup;
    }

    const setWidthColumn = (column, widthColumnEmptyCount, reduceWidth = 0) => {

        let columnWidth = column.width ? column.width : (tableWidthDebouncedValue - reduceWidth) / widthColumnEmptyCount;

        return {
            ...column,
            width: columnWidth,
            onHeaderCell: () => {
                return {
                    width: columnWidth,
                }
            }
        };
    }

    const getTableColumns = () => {
        let flatArrColumns = getColumnsWithoutGroup(props.columns);

        const widthColumnEmptyCount = flatArrColumns.filter(({ width }) => !width).length;

        let reduceWidth = _.sumBy(flatArrColumns, (c) => c.width);

        flatArrColumns = flatArrColumns.map((column) => {
            return setWidthColumn(column, widthColumnEmptyCount, reduceWidth);
        });

        // flatArrColumns = flatArrColumns.map((col, index) => {
        //     return {
        //         ...col,
        //         onHeaderCell: column => {
        //             return {
        //                 width: column.width,
        //             }
        //         }
        //     }
        // });

        const mergedColumns = props.columns!.map((column) => {
            if (column.children) {
                column.children = column.children!.map((child => {
                    return setWidthColumn(child, widthColumnEmptyCount, reduceWidth);
                }))
            }

            return setWidthColumn(column, widthColumnEmptyCount, reduceWidth);
        });

        setGroupColumns(mergedColumns);
        setFlatColumns(flatArrColumns);
    }

    const gridRef = useRef<any>();
    const [connectObject] = useState<any>(() => {
        const obj = {};
        Object.defineProperty(obj, "scrollLeft", {
            get: () => null,
            set: (scrollLeft: number) => {
                if (gridRef.current) {
                    gridRef.current.scrollTo({ scrollLeft });
                }
            },
        });

        return obj;
    });

    const resetVirtualGrid = () => {
        if (gridRef.current) {
            gridRef.current.resetAfterIndices({
                columnIndex: 0,
                shouldForceUpdate: false,
            });
        }
    };

    useEffect(() => {
        prevDataSourceRef.current = props.dataSource;
    }, [props.dataSource])

    useEffect(() => {
        if (tableWidthDebouncedValue) {
            resetVirtualGrid();
            getTableColumns();
        }
    }, [tableWidthDebouncedValue, props.columns]); // eslint-disable-line

    const renderVirtualList = (
        rawData: NSApp.Stock[],
        { scrollbarSize, ref, onScroll }: any
    ) => {
        ref.current = connectObject;
        const totalHeight = rawData.length * 25;

        if (!flatColumns) {
            return <div style={{ height: layoutHeight }}>
                {/* <Loading /> */}
            </div>;
        }

        return (
            <Grid
                ref={gridRef}
                className="stock-price-grid"
                columnCount={flatColumns.length}
                columnWidth={(index) => {
                    let { width } = flatColumns[index];

                    return totalHeight > scroll!.y! &&
                        index === flatColumns.length - 1
                        ? (width as number) - scrollbarSize - 1
                        : (width as number);
                }}
                height={scroll!.y as number}
                rowCount={rawData.length}
                rowHeight={() => 28}
                width={(tableWidthDebouncedValue)}
                onScroll={({ scrollLeft }: { scrollLeft: number }) => {
                    onScroll({ scrollLeft });
                }}
                itemData={itemData}
            >
                {({
                    columnIndex,
                    rowIndex,
                    style,
                }: {
                    columnIndex: number;
                    rowIndex: number;
                    style: React.CSSProperties;
                }) => {
                    const currentColumn = flatColumns[columnIndex];
                    const currentRawData: NSApp.Stock = rawData[rowIndex];
                    const textAlign = currentColumn.key === 'stockCode' ? 'left' : 'right';
                    // let hoverClass = hoveredRowIndex === rowIndex ? "cell-hovered" : "";

                    return (
                        <div
                            className={classNames(`virtual-table-cell cell-row-${preIndexClass}-${rowIndex} ${currentColumn.className} ${currentColumn.key}`)}
                            style={{ ...style, textAlign }}
                            onMouseOver={() => {
                                let coll: any = window.document.getElementsByClassName(`cell-row-${preIndexClass}-${rowIndex}`)

                                for (let i=0, len=coll.length; i<len; i++) {
                                    coll[i].style["background-color"] = themeMode === 'dark' ? '#313849' : '#adb5bd';
                                }
                            }}
                            onMouseLeave={() => {
                                let coll: any = window.document.getElementsByClassName(`cell-row-${preIndexClass}-${rowIndex}`)

                                for (let i=0, len=coll.length; i<len; i++) {
                                    if (coll[i].classList.contains("highlight-column")) {
                                        coll[i].style["background-color"] = themeMode === 'dark' ? '#2a2f3c' : '#e5e5e5';
                                    } else {
                                        coll[i].style["background-color"] = 'transparent';
                                    }
                                }
                            }}
                        >
                            <PriceTableCell
                                priceRatio={props.priceRatio}
                                volumeRatio={props.volumeRatio}
                                currentColumn={currentColumn}
                                currentRawData={currentRawData}
                                changeTradingView={changeTradingView}
                            />
                        </div>
                    )
                }}
            </Grid>
        );
    };

    let minWidth = props.minWidth ? props.minWidth : MIN_TABLE_WIDTH;

    return (
        <ResizeObserver
            onResize={({ width }) => {
                if (width) {
                    width = ( width > minWidth ) ? width : minWidth
                    setTableWidth(width);
                }
            }}
        >
            <Table
                {...props}
                size="small"
                columns={groupColumns}
                pagination={false}
                components={{
                    body: renderVirtualList,
                }}
            />
        </ResizeObserver>
    );
}

export default VirtualTablePrice;