import React, { useState } from "react";
import { Row, Col, Card, Select } from "antd";
import { useTranslation } from "react-i18next";
import Skeleton from "../../../../../../../components/Skeleton";
import * as config from "../../../../../../../config";
import theme from "../../../../../../../themes/theme";
import Chart from "./IndustryStatsChart";
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

const IndustrySection = (props) => {
    const { charts, data, chartWidth, chartHeight, title } = props;
    const [selectedChart, setSelectedChart] = useState(charts[0]);

    const onChangeSelectedChart = (value: string) => {
        const chart = charts.find(c => c.key === value);
        setSelectedChart(chart);
    }

    return <div>
        <div className="text-one-line text-sm font-bold">{title}</div>
        <Select
            style={{width: '100%', margin: '6px 0'}}
            value={selectedChart.key}
            onChange={onChangeSelectedChart}
        >
            {charts.map(chart => <Select.Option key={chart.key} value={chart.key}>{chart.title}</Select.Option>)}
        </Select>
        <Chart
            width={chartWidth}
            height={chartHeight}
            title={selectedChart.title}
            data={data}
            dataKey={selectedChart.key}
            unit={selectedChart.unit}
        />
    </div>
};

const IndustryStatistics = (props) => {
    let { glContainer, data, loading } = props;
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    let layoutWidth = glContainer.width;
    let layoutHeight = glContainer.height;
    let layoutRows = 1;
    let layoutColumns = 1;
    if (layoutWidth > config.RESPONSIVE_SCREEN.sm) {
        layoutColumns = 2;
    }
    if (layoutWidth > config.RESPONSIVE_SCREEN.lg) {
        layoutColumns = 4;
    }
    let chartWidth = (layoutWidth - 100) / layoutColumns;
    let chartHeight = layoutHeight > 576 ? (layoutHeight - 80) / layoutRows - 8 : 576;

    if (loading) {
        return <div style={{height: glContainer.height-20}}><Skeleton column={4}/></div>
    }

    const INDUSTRY_STATISTICS = [
        {
            key: 'valuation',
            title: t('Chỉ tiêu định giá'),
            columns: 4,
            charts: [
                {
                    key: 'eps',
                    title: t('EPS'),
                    unit: t('VNĐ'),
                },
                {
                    key: 'bookValue',
                    title: t('Book Value'),
                    unit: t('VNĐ'),
                },
                {
                    key: 'pe',
                    title: t('P/E'),
                    unit: t('Lần'),
                },
                {
                    key: 'pb',
                    title: t('P/B'),
                    unit: t('Lần'),
                },
            ],
        },
        {
            key: 'effective',
            title: t('Chỉ tiêu hiệu quả'),
            columns: 3,
            charts: [
                {
                    key: 'roa',
                    title: t('ROA'),
                    unit: '%',
                },
                {
                    key: 'roe',
                    title: t('ROE'),
                    unit: '%',
                },
                {
                    key: 'roce',
                    title: t('ROCE'),
                    unit: '%',
                },
            ],
        },
        {
            key: 'growth',
            title: t('Chỉ tiêu tăng trưởng'),
            columns: 2,
            charts: [
                {
                    key: 'revenueGrowthRate',
                    title: t('Tăng trưởng doanh thu'),
                    unit: '%',
                },
                {
                    key: 'profitGrowthRate',
                    title: t('Tăng trưởng lợi nhuận'),
                    unit: '%',
                },
            ],
        },
        {
            key: 'liquidity',
            title: t('Chỉ tiêu thanh khoản'),
            columns: 2,
            charts: [
                {
                    key: 'averageVolumeOneMonth',
                    title: t('KLGDTB 1 Tháng'),
                    unit: t('Cổ phiếu'),
                },
                {
                    key: 'averageValueOneMonth',
                    title: t('GTGDTB 1 Tháng'),
                    unit: t('Tỷ VNĐ'),
                },
            ],
        },
    ];

    return <Row gutter={[4, 4]}>
        {INDUSTRY_STATISTICS.map(section => {
            return <Col span={24/layoutColumns} key={section.key}>
                <Card bodyStyle={{ padding: 8, backgroundColor: theme.colors.chartBackground[themeMode] }}>
                    <IndustrySection
                        title={section.title}
                        charts={section.charts}
                        data={data}
                        chartWidth={chartWidth}
                        chartHeight={chartHeight}
                    />
                </Card>
            </Col>
        })}
    </Row>;
};

export default IndustryStatistics;