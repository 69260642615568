import React, { useState, useEffect } from "react";

import { Card, message } from "antd";
import { useParams } from "react-router-dom";

import CategoryForm from "./_Form";
import api from "../../../../../services/api";
import routes from "../../../../../routes";

import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import Loading from "../../../../../components/Loading";

const CategoryEditPage = () => {
    const { categoryId } = useParams<{categoryId: any}>();
    const [category, setCategory]: any = useState();

    const fetchCategory = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/article/category/${categoryId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setCategory(resp?.data);
    }

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/article/category/${categoryId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật thành công");
    }

    useEffect(() => {
        fetchCategory();
        // eslint-disable-next-line
    }, []);

    if (!category) {
        return <Loading/>;
    }

    return <Card>
        <MyBreadcrumd items={[
            { label: 'Bài viết', path: routes.ADMIN_ARTICLE_LIST },
            { label: 'Danh mục', path: routes.ADMIN_ARTICLE_CATEGORY_LIST },
            { label: category.name },
            { label: 'Chỉnh sửa' },
        ]} />

        <div className="m-auto mt-5 w-3/6">
            <CategoryForm mode='edit' onFinish={onFinish} initialValues={{...category, parent: category?.parent?._id}} parent={category?.parent}/>
        </div>
    </Card>
}

export default CategoryEditPage;