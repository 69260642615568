import React, { useEffect, useState } from "react";
import { GoldenLayoutComponent } from "./GoldenLayoutComponent";
import themeModeGlobalState from "../../contexts/ThemeMode";
import "golden-layout/src/css/goldenlayout-base.css";

const themes = {
    dark: `${process.env.PUBLIC_URL}/golden-layout/goldenlayout-dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/golden-layout/goldenlayout-light-theme.css`,
};

const GoldenLayoutWrapper = (props) => {
    const [themeMode,] = themeModeGlobalState();
    const [stylePath, setStylePath] = useState();

    useEffect(() => {
        setStylePath(themes[themeMode]);
    }, [themeMode]);

    return (
        <div>
            <link rel="stylesheet" type="text/css" href={stylePath} />
            <GoldenLayoutComponent {...props} />
        </div>
    )
};

export default GoldenLayoutWrapper;