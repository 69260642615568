import React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { useAuthState } from "../../contexts";
import PrivateClientPage from "./client";
import routes from "../../routes";

import Loading from "../../components/Loading";
import localStorage from "../../services/local-storage";
import TrialModal from "../../components/TrialModal";
import GoogleAd from "../../components/GoogleAd";

const HomePage = () => {
    const { authState } = useAuthState() as any;
    const location = useLocation();
    const history = useHistory();
    const { currentUser } = authState;
    const seenTrialModal = localStorage.get(`fstock.seenTrialModal.${currentUser?.id}`);

    const [isShowTrialModal, setIsShowTrialModal] = React.useState(true);

    if (!currentUser) {
        return <Loading/>
    }

    if (currentUser?.isModerator || currentUser?.isSupervisor) {
        return <Redirect to={{
            pathname: routes.ADMIN_DASHBOARD,
            state: { referrer: location.pathname }
        }}/>
    }

    const handleCancel = () => {
        localStorage.set(`fstock.seenTrialModal.${currentUser?.id}`, "true");
        setIsShowTrialModal(false);
    }

    const handleOk = () => {
        localStorage.set(`fstock.seenTrialModal.${currentUser?.id}`, "true");
        return history.push(routes.UPGRADE);
    }

    return (
        <>
            {(!seenTrialModal && currentUser?.plan?.purchaseId === 'trial') &&
                <TrialModal plan={currentUser?.plan} handleOk={handleOk} handleCancel={handleCancel} visible={isShowTrialModal} />
            }
            { currentUser?.userACL?.key == 'basic' && <GoogleAd />}
            <PrivateClientPage />
        </>
    )
}

export default HomePage;