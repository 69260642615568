import api from '../../../../../../services/api';

export const fetchHistoryStocks = async (exchange, sortBy, startDate, endDate) => {
    let [err, resp]: any = await api.get('plugins/proprietary/stocks', { exchange, sortBy, startDate, endDate });

    if (err) {
        console.log(err);
        return;
    }

    if (resp) {
        return resp.data;
    }
};

export const fetchHistoryExchanges = async (exchange, time) => {
    let [err, resp]: any = await api.get(`plugins/proprietary/${exchange}/${time}`);

    if (err) {
        console.log(err);
        return;
    }

    if (resp) {
        return resp.data;
    }
};