import React, { useState, useEffect } from 'react';

import { useParams } from "react-router-dom";
import { Card, message } from 'antd';

import api from "../../../../services/api";

import UserForm from './_Form';
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";
import AdminPermission from '../../../../components/AdminPermission';

const UserEditPageComponent = () => {
    const [user, setUser] = useState<NSApp.User>();
    const { userId } = useParams<{userId: string}>();


    const fetchUser = async () => {
        let [err, resp]: any = await api.get(`/user/${userId}`);

        if (err) return;

        setUser(resp.data);
    };

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`user/${userId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật thông tin user thành công");
    };

    useEffect(() => {
        fetchUser();
    }, []); // eslint-disable-line

    if (!user) return <Loading/>
    
    return (
        <Card>
            <MyBreadcrumd items={[{ label: 'Chỉnh sửa người dùng' }]} />

            <UserForm initialValues={user} onFinish={onFinish}/>
        </Card>
    )
}

const UserEditPage = () => {
    return <AdminPermission roleName={[]}>
        <UserEditPageComponent />
    </AdminPermission>
}

export default UserEditPage;