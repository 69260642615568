import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Card, Form, Input, Button, Checkbox, Spin, notification, Space, Avatar, Divider } from "antd";
import { Link, useHistory } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { useAuthState } from "../../contexts";
import themeModeGlobalState from "../../contexts/ThemeMode";
import api from "../../services/api";
import localStorage from "../../services/local-storage";
import routes from "../../routes";
import SelectLanguage from "../../components/SelectLanguage";
import NormalFooter from "../../components/NormalFooter";
import GoogleLoginButton from "./components/GoogleLoginButton";
import FacebookLoginButton from "./components/FacebookLoginButton";
import LogoWhite from "../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../assets/image/logo/Fstock_black.svg";

const Login = ({ location }) => {
    let history = useHistory();
    const { setAuthState } = useAuthState() as any;
    const [ isLoading, setIsLoading ] = useState(true);
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();
    const logo = themeMode === 'dark' ? LogoWhite : LogoBlack;

    const handleError = (err) => {
        const status = err?.response?.status;

        if (status && [401].includes(status)) {
            return notification.error({
                message: t('Tài khoản chưa được kích hoạt, quý khách vui lòng kiểm tra email hoặc liên hệ đội ngũ admin qua fanpage để kích hoạt tài khoản.'),
            });
        }

        if (status && [400, 404].includes(status)) {
            return notification.error({
                message: t('Tài khoản hoặc mật khẩu không đúng, quý khách vui lòng kiểm tra lại!'),
            });
        }

        return notification.error({
            message: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
        });
    }

    const handleAfterLogin = (accessToken, user) => {
        if (!accessToken || !user) {
            return notification.error({
                message: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
            });
        }

        notification.success({
            message: t('Đăng nhập thành công!'),
            description: <span>{t('Chào mừng trở lại')}, <strong>{user.fullname}</strong></span>,
        });
        // api.setToken(token);
        api.setAccessToken(accessToken);
        setAuthState({ currentUser: user });
        localStorage.set('accessToken', accessToken);

        if (location?.state?.referrer) {
            history.push(location.state.referrer)
        } else {
            history.push(routes.HOME);
        }
    }

    const onFinish = async (values) => {
        let [err, resp]: any = await api.post('user/signin', {
            email: values?.email,
            password: values?.password
        });

        if (err) {
            handleError(err);
        }

        if (resp) {
            let { accessToken, user, userACL } = resp.data;
            const _user = {
                ...user,
                userACL,
            }
            handleAfterLogin(accessToken, _user);
        }
    };

    const autoLogin = async () => {
        const accessToken = localStorage.get('accessToken');
        if (!accessToken) {
            return setIsLoading(false);
        }

        const [err, resp]: any = await api.get('user/profile');

        if (!err && resp?.data?.user) {
            setAuthState({ currentUser: resp?.data?.user });
            history.push(routes.HOME);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        autoLogin();
    }, []); // eslint-disable-line

    if (isLoading) {
        return <Spin/>
    }

    return (
        <Layout className="auth-page">
            <div className="flex flex-1 flex-col justify-center items-center">
                <Space className="brand flex justify-center" align="center">
                    <Avatar shape="square" size={128} src={logo}/>
                </Space>
                <div className="w-full p-4">
                    <Card
                        className="w-full max-w-lg mx-auto"
                        title={
                            <div className="flex justify-between items-center px-3">
                                <strong className="text-lg">{t('Đăng nhập')}</strong>
                                <SelectLanguage />
                            </div>
                        }
                    >
                        <Form
                            name="login_form"
                            className="login-form"
                            size="middle"
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    { type: 'email', message: t('Email không đúng định dạng') },
                                    { required: true, message: t('Không được để trống') },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder={t('Email')} />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{required: true, message: t('Không được để trống')}]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    placeholder={t('Mật khẩu')}
                                />
                            </Form.Item>
                            <Form.Item className="mb-0">
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>{t('Ghi nhớ đăng nhập')}</Checkbox>
                                </Form.Item>

                                <Link className={`login-form-forgot text-${themeMode === 'dark' ? 'white' : 'black'}`} to={routes.FORGOT_PASSWORD}>
                                    {t("Quên mật khẩu")}?
                                </Link>
                            </Form.Item>
                            <div className="text-right mb-4">
                                {t("Chưa có tài khoản")}? <Link to={routes.REGISTER}>{t("Đăng ký")}!</Link>
                            </div>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <span className="font-bold">{t('Đăng nhập')}</span>
                                </Button>
                            </Form.Item>
                        </Form>
                        <Divider plain>{t("hoặc đăng nhập với")}</Divider>
                        <Row gutter={[8, 8]}>
                            <Col xs={24} lg={12}>
                                <GoogleLoginButton
                                    onSuccess={handleAfterLogin}
                                    onError={handleError}
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <FacebookLoginButton
                                    onSuccess={handleAfterLogin}
                                    onError={handleError}
                                />
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
            <NormalFooter/>
        </Layout>
    );
};

export default Login;
