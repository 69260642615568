import { Button, DatePicker, Input, Select, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import MyBreadcrumb from "../../../../components/MyBreadcrumb";
import api from "../../../../services/api";
import { useReducedState } from "../../../../services/utils";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";

import AdminPermission from "../../../../components/AdminPermission";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input

const PaymentManageTableComponent = () => {
    const [paymentInfo, setPaymentInfo] = useState()
    const [pagination, setPagination] = useReducedState({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const [newsStatusFilter, setNewsStatusFilter] = useState<string>('');
    const [newsKeySearchFilter, setNewsKeySearchFilter] = useState<string>('');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [filter, setFilter] = useState({ status: 'success'});
    const [planKey, setPlanKey] = useState<string>('')
    const fetchPayment = async (filter = {}) => {
        const [err, resp]: any = await api.get("/payment/purchases",
            {
                ...filter,
                page: pagination.current,
                pageSize: pagination.pageSize,
                planKey
            });
        if (err) {
            return;
        }
        if (resp) {
            let { purchases, total } = resp?.data;
            setPaymentInfo(purchases);
            setPagination({ total })
        }
    }
    useEffect(() => {
        fetchPayment(filter);
    }, [pagination.current]); // eslint-disable-line
    const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
        const today = new Date;
        return current && current > moment(today);
    };
    const formatDate = (date: any) => {
        date = moment(date).format('DD/MM/YYYY')
        return date
    }
    const formatPhoneNumber = (phoneNumberString: string) => {
        var match = ('' + phoneNumberString).match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return match[1] + ' ' + match[2] + ' ' + match[3];
        }
        return null;
    }
    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(amount);
    }
    const onChangeDate = (date: any) => {
        if(date){
            setStartDate(date[0]);
            setEndDate(date[1]);
        } else {
            setStartDate(undefined);
            setEndDate(undefined);
        }
    };
    const btnSearchOnClick = async () => {
        let filter = {};
        if (startDate) {
            filter['startDate'] = formatDate(startDate);
        };
        if (endDate) {
            filter['endDate'] = formatDate(moment(endDate).add(1,'d'));
        };
        if (newsKeySearchFilter) {
            filter['searchKey'] = newsKeySearchFilter;
        }
        if (newsStatusFilter) {
            filter['status'] = newsStatusFilter;
        }
        fetchPayment(filter)
        setFilter(filter);
        setPagination({current : 1})
    };
    const columns: any = [
        {
            title: "Họ và tên",
            dataIndex: "User.fullname",
            align: 'left',
            width: '10%',
            fixed: 'left',
        },
        {
            title: "Email",
            dataIndex: "email",
            align: 'center',
            width: '15%',
        },
        {
            title: "Số điện thoại",
            dataIndex: "User.phone",
            align: 'center',
            width: '10%',
            render: formatPhoneNumber
        },
        {
            title: "Mã đơn hàng",
            dataIndex: "referenceCode",
            align: 'center',
            width: '16%',
        },
        {
            title: "Gói dịch vụ",
            dataIndex: "plan.name",
            align: 'center',
            width: '10%',
        },
        {
            title: "Hạn sử dụng",
            dataIndex: "periodPlan",
            align: 'center',
            width: '10%',
            render: (period) => {
                return <div>{period} tháng</div>
            }
        },
        {
            title: "Số tiền",
            dataIndex: "pricePlan",
            align: 'center',
            width: '8%',
            render: formatCurrency
        },
        {
            title: "Ngày thanh toán",
            dataIndex: "createdAtDate",
            align: 'center',
            width: '15%',
            render: (date: any) => {
                date = moment(date).format('DD/MM/YYYY HH:mm')
                return date
            }
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            align: 'center',
            width: '8%',
            render: (payment) => {
                let status = ""
                switch (payment) {
                    case 'pending':
                        status = 'Chờ xử lý';
                        break;
                    case 'success':
                        status = 'Thành công';
                        break;
                    case 'failed':
                        status = 'Thất bại';
                        break;
                }
                return status
            }
        },
        {
            title: "Cổng thanh toán",
            dataIndex: "purchaseType",
            align: 'center',
            width: '8%',
            render: (purchaseType) => {
                let type: string = "";
                switch (purchaseType) {
                    case 'vnpay':
                        type = 'VNPAY';
                        break;
                    case 'APP_STORE':
                        type = 'APP STORE';
                        break;
                    case 'PLAY_STORE':
                        type = 'Google Play Billing';
                        break;
                }

                return type;
            }
        },
        {
            title: "Lí do thất bại",
            dataIndex: "failureReason",
            align: 'left',
            className: 'whitespace-normal-important',
            width: '20%',
        },
    ]
    return (
        <div>
            <MyBreadcrumb items={[{ label: 'Quản lý thanh toán' }]} />
            <div className="my-2">Tổng thanh toán: {pagination.total} </div>
            <div className="mt-2">
                <Space>
                    <RangePicker
                        disabledDate={disabledEndDate}
                        format='DD/MM/YYYY'
                        onCalendarChange={onChangeDate}
                        allowEmpty={[true,true]}
                    />
                    <Select defaultValue="success" onChange={(val: any) => setNewsStatusFilter(val)} style={{ width: 150 }}>
                        <Option value="">Tất cả trạng thái</Option>
                        <Option value="pending">Chờ xử lý</Option>
                        <Option value="success">Thành công</Option>
                        <Option value="failed">Thất bại</Option>
                    </Select>
                    <Select defaultValue="" onChange={(val: any) => setPlanKey(val)} style={{ width: 150 }}>
                        <Option value="">Tất cả các gói</Option>
                        <Option value="advanced">Nâng cao</Option>
                        <Option value="professional">Chuyên nghiệp</Option>
                    </Select>
                    <Search
                        allowClear
                        placeholder="Họ tên, email, sđt hay mã đơn hàng"
                        onChange={(e: any) => setNewsKeySearchFilter(e.target.value)}
                        onSearch={() => {
                            setNewsKeySearchFilter('');
                            btnSearchOnClick();
                        }}
                    />

                    <Button type="primary" onClick={btnSearchOnClick}>Tìm kiếm</Button>
                </Space>
            </div>
            <div className="mt-3">
                <Table
                    columns={columns}
                    dataSource={paymentInfo}
                    scroll={{x:'150%'}}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                />
            </div>
        </div>
    )
}

const PaymentManageTable = () => {
    return <AdminPermission roleName={['validator']}>
        <PaymentManageTableComponent />
    </AdminPermission>
}

export default PaymentManageTable;