import React, { useEffect} from 'react';

const GoogleAd = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9583286842586939';
        script.setAttribute('crossorigin', 'anonymous');
        document.body.appendChild(script);
        (window as any).adsbygoogle = (window as any).adsbygoogle || [];
    }, [])

    return (
        <ins className="adsbygoogle"
             style={{display: 'block'}}
             data-ad-client="ca-pub-9583286842586939"
             data-ad-format="auto"
             data-full-width-responsive="true"
        />  
    )
}

export default GoogleAd;