import React, { useState, useEffect }  from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Image, Tooltip, Button, Space, Input, Select, Tag, message } from "antd";

import api from "../../../../services/api";
import * as config from "../../../../config";
import routes from "../../../../routes";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import { ArticleType } from "../../../../types/plugins/article";
import { useReducedState, toRoute } from "../../../../services/utils";
import { ARTICLE_ACCEPTED, ARTICLE_DENIED, ARTICLE_PENDING } from "../../../../types/plugins/article";
import ChangeStatusButton from './_ChangeStatusButton';
import AdminPermission from "../../../../components/AdminPermission";

const { Option } = Select;

const ArticleListPageComponent = () => {
    const [ articleKeySearchFilter, setArticleKeySearchFilter ] = useState<string>('');
    const [ articleStatusFilter, setArticleStatusFilter ] = useState<string>('');
    const [ listArticles, setListArticles ] = useState([]);

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchArticles = async (filter = {}) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/article', {
            filter, page: pagination.current, pageSize: config.PAGE_SIZE
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { articles, total } = resp?.data;

        setListArticles(articles);
        setPagination({ total });
    };

    const btnSearchOnClick = () => {
        let filter = {};
        if (articleKeySearchFilter) {
            filter['title'] = articleKeySearchFilter;
        }

        if ([ARTICLE_ACCEPTED, ARTICLE_DENIED, ARTICLE_PENDING].includes(articleStatusFilter)) {
            filter['status'] = articleStatusFilter;
        }

        fetchArticles(filter);
    };

    useEffect(() => {
        fetchArticles();
    }, [pagination.current]); // eslint-disable-line


    const columns: any = [
        {
            title: 'Tiêu đề',
            width: 300,
            textWrap: 'word-break',
            ellipsis: true,
            render: (article) => <Link to={toRoute(routes.ADMIN_ARTICLE_DETAIL, { articleId: article.id })}>
                <Tooltip placement="top" title={article.title}>
                    <span>{article.title}</span>
                </Tooltip>
            </Link>
        },
        {
            title: "Danh mục",
            dataIndex: 'category',
            textWrap: 'word-break',
            ellipsis: true,
            render: (category) => <Tooltip placement="top" title={category?.name}>
                <a href={category?.link}>{category?.name}</a>
            </Tooltip>
        },
        {
            title: "Ảnh",
            dataIndex: 'thumbnail',
            render: (thumbnail) => <Image width={100} src={thumbnail}/>
        },
        {
            title: "Ngày xuất bản",
            dataIndex: 'createdAt',
            render: (createdAt) => <span>{moment(createdAt).format('L')}</span>
        },
        {
            title: "Trạng thái",
            dataIndex: 'status',
            render: (status) => <span>
                {status === ARTICLE_PENDING &&
                    <Tag color="gold">Chờ duyệt</Tag>
                }

                {status === ARTICLE_ACCEPTED &&
                    <Tag color="green">Đã duyệt</Tag>
                }

                {status === ARTICLE_DENIED &&
                    <Tag color="red">Đã từ chối</Tag>
                }
            </span>
        },
        {
            title: 'Action',
            render: (article) => {
                return <Space>
                    <Link to={toRoute(routes.ADMIN_ARTICLE_EDIT, { articleId: article.id })}>
                        <Button type="primary">Sửa</Button>
                    </Link>
                    <ChangeStatusButton article={article} onChange={fetchArticles}/>
                </Space>
            }
        }
    ];

    return (
        <>
            <MyBreadcrumd items={[{ label: 'Bài viết' }]} />

            <div className="mt-3">
                <Space>
                    <Input placeholder="Tên bài viết" onChange={(e: any) => setArticleKeySearchFilter(e.target.value)} />
                    <Select defaultValue="select-status" onChange={(val: any) => setArticleStatusFilter(val)} style={{ width: 150 }}>
                        <Option value="select-status">Chọn trạng thái</Option>
                        <Option value={ARTICLE_PENDING}>Chờ phê duyệt</Option>
                        <Option value={ARTICLE_ACCEPTED}>Đã phê duyệt</Option>
                        <Option value={ARTICLE_DENIED}>Bị từ chối</Option>
                    </Select>

                    <Button type="primary" onClick={btnSearchOnClick}>Tìm kiếm</Button>

                    <Button type="primary">
                        <Link to={routes.ADMIN_ARTICLE_ADD}>Thêm mới bài viết</Link>
                    </Button>
                </Space>
            </div>
            <div className="mt-2">
                <Table
                    columns={columns}
                    dataSource={listArticles}
                    rowKey={(r: ArticleType) => r?._id}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                />
            </div>
        </>
    )
}

const ArticleListPage = () => {
    return <AdminPermission roleName={['editor', 'moderator']}>
        <ArticleListPageComponent />
    </AdminPermission>
}

export default ArticleListPage;