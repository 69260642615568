import React, { useContext } from 'react';

export const initialWatchListStore: Context.WatchListContext = {
    watchlists: []
}

export const WatchListContext = React.createContext<Context.WatchListContext>(initialWatchListStore);

export const useWatchListState = () => {
    // @ts-ignore
    return useContext(WatchListContext);
};
