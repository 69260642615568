import React from "react";
import { Breadcrumb, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Text } = Typography;

type BreadcrumbItem = {
    label: string;
    path?: string;
};

type MyBreadcrumbProps = {
    items: Array<BreadcrumbItem>;
};

export default (props: MyBreadcrumbProps) => {
    const { items } = props;
    return (
        <Breadcrumb>
            <Breadcrumb.Item href="">
                <HomeOutlined />
            </Breadcrumb.Item>
            {items.map((item: BreadcrumbItem, idx: number) => (
                <Breadcrumb.Item key={idx}>
                    {item.path ? (
                        <Link to={item.path}>
                            <Text type="success">{item.label}</Text>
                        </Link>
                    ) : (
                        <Text>{item.label}</Text>
                    )}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
};
