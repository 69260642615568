import React from "react";
import { Avatar, Layout } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import routes from "../routes";
import FStockLogo from "../assets/image/logo/FStock_transparent_black.svg";

const UpgradeHeader = () => {
    const { t } = useTranslation();

    return (
        <Layout.Header className="h-16">
            <div className="max-w-screen-xl mx-auto flex justify-between items-center h-full sm:px-1">
                <div className="flex items-center">
                    <Link to={{pathname: "https://fstock.vn/"}} target="_top"><Avatar shape="square" size={64} src={FStockLogo} /></Link>
                    <div className="hidden md:block text-base font-bold ml-4">{t('Mua / Gia hạn / Nâng cấp dịch vụ')}</div>
                </div>

                <div className="flex flex-col justify-center">
                    <div className="text-sm leading-5 text-red font-bold">{t('Hotline')}: 090 227 6166</div>
                    <div className="text-sm leading-5 text-black font-bold">{t('Email')}: contact@fcapital.vn</div>
                </div>
            </div>
        </Layout.Header>
    );
};

export default UpgradeHeader;
