import React, { useEffect, useState } from "react";
import { Card, Table, Select, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { priceChangePercentFormat, priceFormat } from "../../../../../../../services/stock-price";
import { foreignValueFormat } from "../services";
import { getStockColorClass } from "../../../../../../../services/utils";
import NameWithChartIcon from "../../../../components/NameWithChartIcon";
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

type ForeignerTradingTableProps = {
    exchangeDetail: NSApp.ExchangeDetail,
    stocks: NSApp.Stock[],
    sortBy: string,
    exchange: string,
    sortByValue: boolean,
    checkedStock: string,
    height: number,
    buyKey: string,
    sellKey: string,
    amountKey: string,
    titleSortBy: string,
    titleUnitSortBy: string,
    changeSortBy: (sortBy) => void,
    changeExchange: (exchange) => void,
    changeCheckedStock: (checkedStock) => void,
    totalForeignValue: any
}

const ForeignerTradingTable = (props: ForeignerTradingTableProps) => {
    let { totalForeignValue, exchangeDetail, stocks, exchange, sortBy, sortByValue, height, buyKey, sellKey, amountKey, titleSortBy, titleUnitSortBy, changeSortBy, changeExchange, changeCheckedStock } = props;

    const [ tableData, setTableData ] = useState<NSApp.Stock[]>([]);
    const [themeMode] = themeModeGlobalState();
    const { t } = useTranslation();

    useEffect(() => {
        setTableData(stocks);
    }, [stocks]); // eslint-disable-line

    const formatType = sortByValue ? 'value' : 'volume';

    const columns: ColumnsType<NSApp.Stock> = [
        {
            title: <div className="text-one-line text-center" title={t('CK')}>{t('CK')}</div>,
            width: 80,
            dataIndex: 'stockCode',
            key: 'stockCode',
            align: 'center',
            // render: (value, stock) => <span style={{color: getStockColorClass(stock, themeMode)}}><strong>{value?.toUpperCase()}</strong></span>
            render: (value, stock) =>
                <NameWithChartIcon
                    color={getStockColorClass(stock, themeMode)}
                    symbol={value.toUpperCase()}
                />
        },
        {
            title: <div className="text-one-line text-center" title={t('Giá')}>{t('Giá')}</div>,
            width: 70,
            dataIndex: 'matchedPrice',
            key: 'matchedPrice',
            align: 'right',
            render: (matchedPrice, stock) => <span style={{color: getStockColorClass(stock, themeMode)}}><strong>{priceFormat(matchedPrice)}</strong></span>
        },
        {
            title: <div className="text-one-line text-center" title="+/-">+/-(%)</div>,
            // width: 60,
            dataIndex: 'pricePercentChange',
            key: 'pricePercentChange',
            align: 'right',
            render: (priceChangePercent, stock) => <span style={{color: getStockColorClass(stock, themeMode)}}><strong>{priceChangePercent > 0 && '+'}{priceChangePercentFormat(priceChangePercent)}%</strong></span>
        },
        {
            title: <div className="text-one-line text-center" title={`${titleSortBy} ${t('Mua')} ${titleUnitSortBy}`}>{t('Mua')}</div>,
            dataIndex: buyKey,
            key: buyKey,
            align: 'right',
            render: (value) => <span><strong>{foreignValueFormat(value, formatType)}</strong></span>
        },
        {
            title: <div className="text-one-line text-center" title={`${titleSortBy} ${t('Bán')} ${titleUnitSortBy}`}>{t('Bán')}</div>,
            dataIndex: sellKey,
            key: sellKey,
            align: 'right',
            render: (value) => <span><strong>{foreignValueFormat(value, formatType)}</strong></span>
        },
        {
            title: <div className="text-one-line text-center" title={`${titleSortBy} ${t('Ròng')} ${titleUnitSortBy}`}>{t('Ròng')}</div>,
            dataIndex: amountKey,
            key: amountKey,
            align: 'right',
            render: (value) => <span><strong>{foreignValueFormat(value, formatType)}</strong></span>
        }
    ];

    const onChangeSortBy = (value) => {
        changeSortBy(value);
    };

    const onChangeExchange = (value) => {
        changeExchange(value);
        changeCheckedStock('all');
    };

    const renderFilter = () => {
        return (
            <div>
                <div className="flex justify-between">
                    <div>
                        <span className="text-one-line text-sm font-semibold uppercase" title={t('Top giao dịch nước ngoài')}>{t('Top GD nước ngoài')}</span>
                        <small className="italic">({t('Đơn vị')}: {sortByValue ? t('Tỷ VNĐ') : t('Cổ phiếu')})</small>
                    </div>
                    <Space>
                        <div>
                            <div className="text-gray">{t('Lọc theo')}</div>
                            <Select style={{width: 100}} value={exchange} size="small" onChange={onChangeExchange}>
                                <Select.Option value="">{t('Tất cả')}</Select.Option>
                                <Select.Option value="hose">HOSE</Select.Option>
                                <Select.Option value="hnx">HNX</Select.Option>
                                <Select.Option value="upcom">UPCOM</Select.Option>
                            </Select>
                        </div>
                        <div>
                            <div className="text-gray">{t('Sắp xếp theo')}</div>
                            <Select style={{width: 150}} value={sortBy} size="small" onChange={onChangeSortBy}>
                                <Select.Option value="valueBuy">{t('Giá trị mua')}</Select.Option>
                                <Select.Option value="valueSell">{t('Giá trị bán')}</Select.Option>
                                <Select.Option value="netValueBuy">{t('Giá trị mua ròng')}</Select.Option>
                                <Select.Option value="netValueSell">{t('Giá trị bán ròng')}</Select.Option>
                                <Select.Option value="volumeBuy">{t('Khối lượng mua')}</Select.Option>
                                <Select.Option value="volumeSell">{t('Khối lượng bán')}</Select.Option>
                                <Select.Option value="netVolumeBuy">{t('Khối lượng mua ròng')}</Select.Option>
                                <Select.Option value="netVolumeSell">{t('Khối lượng bán ròng')}</Select.Option>
                            </Select>
                        </div>
                    </Space>
                </div>
                <div className="flex justify-between items-center pt-4 pb-1">
                    <span className="text-one-line">
                        {t('Mua')}: <span className="font-bold">
                            {!exchange ? foreignValueFormat(totalForeignValue[buyKey], formatType) : foreignValueFormat(exchangeDetail[buyKey], formatType)}
                        </span>
                    </span>
                    <span className="text-one-line">
                        {t('Bán')}: <span className="font-bold">
                            {!exchange ? foreignValueFormat(totalForeignValue[sellKey], formatType) : foreignValueFormat(exchangeDetail[sellKey], formatType)}
                        </span>
                    </span>
                    <span className="text-one-line">
                        {t('Ròng')}: <span className="font-bold">
                            {!exchange ? foreignValueFormat(totalForeignValue[amountKey], formatType) : foreignValueFormat(exchangeDetail[amountKey], formatType)}
                        </span>
                    </span>
                </div>
            </div>
        )
    };

    // if (loading || !tableData || !indices) {
    //     return <Skeleton active />;
    // }

    return (
        <Card
            className="overflow-auto top-stock-card"
            bodyStyle={{padding: 0}}
            bordered={false}
        >
            <Table
                className="table-specific-row-class"
                bordered
                scroll={{ y: height - 170 }}
                title={renderFilter}
                pagination={false}
                rowKey={(record: NSApp.Stock) => record.stockCode}
                // rowClassName={(record: NSApp.Stock) => getTableRowClass(record, checkedStock)}
                columns={columns}
                dataSource={tableData}
            />
        </Card>

    )
};

export default ForeignerTradingTable;
