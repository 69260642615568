import { Layout } from "antd";
import React from "react";
// import Loading from "../../../components/Loading";
// import { useAuthState } from "../../../contexts";
import UpgradeHeader from "../../../components/UpgradeHeader";
import UpgradeFooter from "../../../components/UpgradeFooter";
import UpgradeContent from "./Payment";

const UpgradePage = () => {
    // const { authState } = useAuthState() as any;
    // const { currentUser } = authState;

    // if (!currentUser) {
    //     return <Loading />;
    // }

    return (
        <Layout className="justify-between light-theme">
            <div className="bg-gray-200">
                <UpgradeHeader />
                <UpgradeContent />
            </div>
            <UpgradeFooter />
        </Layout>
    );
};

export default UpgradePage;
