import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getArticleDetail } from "../service";
import ArticleDetailPage from "../components/Detail";

const ArticleDetailContainer = () => {
    const [article, setArticle] = useState<NSApp.Article>();
    const { articleId } = useParams<any>();

    const fetchArticle = async () => {
        let [err, resp]: any = await getArticleDetail(articleId);

        if (err) {
            // setErrorMessage('Có lỗi xảy ra khi tải tin tức hoặc tài khoản của bạn chưa được kích hoạt plugin này. Vui lòng liên hệ admin để biết thêm chi tiết.');
            return;
        }
        
        if (resp) {
            setArticle(resp.data.news);
        }
    };

    useEffect(() => {
        if (articleId) {
            fetchArticle();
        }
        //eslint-disable-next-line
    }, [articleId]);

    return (
        <ArticleDetailPage
            articleDetail={article}
        />
    )
}

export default ArticleDetailContainer