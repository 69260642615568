import React from 'react';
import NewsListContainer from '../container/list';

type NewsContainerProps = {
    stockCodes?: string[],
    height: number,
    listNoImage?: boolean,
    column?: number
}

const NewsContainer = (props: NewsContainerProps) => {
    const { stockCodes, height, listNoImage, column } = props;

    return (
        <NewsListContainer
            enableWatchListFilter={false}
            stockCodes={stockCodes}
            height={height}
            listNoImage={listNoImage}
            column={column}
        />
    )
};

export default NewsContainer;
