import React, { useState, useEffect } from "react";
import { Card, message } from "antd";
import { useParams } from "react-router-dom";

import api from "../../../../../services/api";
import routes from "../../../../../routes";

import NewsProviderForm from "./_Form";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import Loading from "../../../../../components/Loading";

const NewsProviderEditPage = () => {
    const { providerId } = useParams();
    const [ provider, setProvider ]: any = useState();

    const fetchProvider = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/news/provider/${providerId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setProvider(resp?.data);
    };

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/news/provider/${providerId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật thành công");
    };

    useEffect(() => {
        fetchProvider();
        // eslint-disable-next-line
    }, []);

    if (!provider) {
        return <Loading/>;
    }

    return <Card>
        <MyBreadcrumd items={[
            { label: 'Tin tức', path: routes.ADMIN_NEWS_LIST},
            { label: 'Nguồn cung cấp', path: routes.ADMIN_NEWS_PROVIDER_LIST },
            { label: provider.name },
            { label: 'Chỉnh sửa' },
        ]} />

        <div className="m-auto mt-5 w-3/6">
            <NewsProviderForm mode='edit' onFinish={onFinish} initialValues={provider}/>
        </div>
    </Card>
}

export default NewsProviderEditPage;