export const calcAccumulationData = (data: NSApp.MacroeconomicsStatistic[]) => {
    let calculatedData: NSApp.MacroeconomicsStatistic[] = [];
    calculatedData = data.map(
        (entry: NSApp.MacroeconomicsStatistic, index: number) => {
            let returnObj = { ...entry };
            const newValue = entry?.value - data[index - 1]?.value;

            if (index > 0) {
                returnObj = {
                    ...entry,
                    value: newValue > 0 ? newValue : 0,
                };
            }
            return returnObj;
        },
    );
    return calculatedData.slice(1);
};