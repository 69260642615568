import React, { useState, useEffect } from 'react';
import { Menu, message, Modal, Divider, Tooltip, Button, Dropdown } from 'antd';
import { AppstoreOutlined, SaveOutlined, FileDoneOutlined, PlusOutlined, DashOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import api from '../../../../services/api';
import routes from '../../../../routes';
import { toRoute } from '../../../../services/utils';
import { useDashboardState, useLayoutState } from '../../../../contexts';
import DashboardFrom from '../Dashboard/components/DashboardForm';

const { SubMenu, Item } = Menu;

const DashboardMenuItem = (props) => {
    const { dashboardState, setDashboardState } = useDashboardState() as any;
    const { layoutState } = useLayoutState() as any;
    const { listDashboard, defaultDashboard, currentDashboard, layoutConfig } = dashboardState;
    const { pluginDrawerVisible } = layoutState;

    const [ modalVisible, setModalVisible ] = useState(false);
    const [ formMode, setFormMode ] = useState<"add" | "edit">('add');
    const [ dashboardEditing, setDashboardEditing ] = useState<NSApp.Dashboard | null>(null)

    useEffect(() => {
        fetchListDashboard();
    }, []); // eslint-disable-line

    const fetchListDashboard = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/list-dashboard');
        let listDashboard = resp?.data;

        if (err) {
            message.error({
                content: "Có lỗi xảy ra",
                key: 'list-dashboard-err'
            })
            return;
        };

        let defaultDashboard = listDashboard.find(dashboard => dashboard.isDefault)
        setDashboardState({ listDashboard, defaultDashboard });
    };

    const setDefault = async (dashboardId) => {
        let id = dashboardId ?  dashboardId : currentDashboard?.id;

        // eslint-disable-next-line
        let [err, resp]: any = await api.post(`plugins/dashboard/set-default`, {
            dashboardId: id
        });

        if (err) {
            message.success({
                content: "Có lỗi xảy ra",
                key: "set-default-error",
            });
            return;
        }

        setDashboardState({ defaultDashboard: currentDashboard });
        message.success({
            content: `Đặt ${currentDashboard?.name} làm mặc định thành công.`,
            key: "set-default-success",
        });

        await fetchListDashboard();
    };

    const saveLayout = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.patch(
            `plugins/dashboard/${currentDashboard?.id}`, { layoutConfig }
        );

        if (err) {
            message.error({
                content: "Có lỗi xảy ra",
                key: "save-layout-error",
            });
            return;
        }

        message.success({
            content: "Cập nhật tổng quan thành công",
            key: "save-layout-success",
        });
    };

    const deleteDashboard = async (id) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.delete(`plugins/dashboard/${id}`);
        if (err) {
            message.error({
                content: "Có lỗi xảy ra",
                key: "delete-layout-error",
            });
            return;
        }

        message.success({
            content: "Xóa tổng quan thành công",
            key: "delete-layout-success",
        });

        await fetchListDashboard();
    }

    const confirmDeleteDashboard = (dashboard: NSApp.Dashboard) => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Bạn chắc chắn muốn xóa bảng tổng quan ${dashboard.name}`,
            onOk: () => {deleteDashboard(dashboard.id)},
            okText: 'Đồng ý',
            cancelText: 'Hủy bỏ',
        });
    }

    if (!listDashboard) {
        return <></>;
    }

    return <>
        <SubMenu {...props} icon={<AppstoreOutlined />} title="Dashboard">
            {listDashboard.map((dashboard: NSApp.Dashboard) => (
                <Item className="flex justify-between items-center" key={dashboard.id}>
                    <Tooltip placement="top" title={`${dashboard.name}`}>
                        <Link to={toRoute(routes.DASHBOARD_CONTENT, { dashboardId: dashboard.id })}>
                            <span>{dashboard.name.substr(0, 15)}{dashboard.name.length > 15 && `...`}</span>
                            <span>{dashboard.id === defaultDashboard?.id && <FileDoneOutlined />}</span>
                        </Link>
                    </Tooltip>
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu>
                                <Menu.Item key="edit" onClick={() => {
                                    setFormMode('edit')
                                    setDashboardEditing(dashboard)
                                    setModalVisible(true)
                                }}>
                                    Cập nhật
                                </Menu.Item>
                                {!dashboard.isDefault &&
                                    <>
                                        <Menu.Item key="set-default" onClick={() => setDefault(dashboard.id)}>Đặt làm mặc định</Menu.Item>
                                        <Menu.Item key="delete" onClick={() => confirmDeleteDashboard(dashboard)}>Xóa</Menu.Item>
                                    </>
                                }
                            </Menu>
                        }
                    >
                        <Button type="link" icon={<DashOutlined />}/>
                    </Dropdown>
                </Item>
            ))}

            <Item key="item-divider" className="sider-item-divider">
                <Divider/>
            </Item>

            <Item key="layout_action" style={{paddingBottom: 4}}>
                <Tooltip placement="top" title="Lưu cấu hình">
                    <Button
                        size="middle"
                        className="sider-button"
                        disabled={pluginDrawerVisible}
                        icon={<SaveOutlined />}
                        onClick={saveLayout}
                    />
                </Tooltip>

                <Tooltip placement="top" title="Đặt làm mặc định">
                    <Button
                        size="middle"
                        className="sider-button"
                        disabled={pluginDrawerVisible}
                        icon={<FileDoneOutlined />}
                        onClick={() => setDefault(null)}
                    />
                </Tooltip>

                <Tooltip placement="top" title="Tạo mới">
                    <Button
                        disabled={pluginDrawerVisible}
                        size="middle"
                        className="sider-button"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setFormMode('add')
                            setModalVisible(true)
                        }}
                    />
                </Tooltip>
            </Item>
        </SubMenu>

        <Modal
            title={formMode === 'add' ? "Tạo mới bảng tổng quan" : "Chỉnh sửa bảng tổng quan"}
            closable={true}
            visible={modalVisible}
            footer={null}
            onCancel={() => setModalVisible(false)}
            destroyOnClose={true}
        >
            <DashboardFrom
                initialValues={dashboardEditing}
                mode={formMode}
                cbCreate={() => {setModalVisible(false)}}
                cbUpdate={async () => {
                    setDashboardEditing(null)
                    setModalVisible(false)
                    await fetchListDashboard();
                }}
            />
        </Modal>
    </>
}

export default DashboardMenuItem;
