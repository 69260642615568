import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useEffectOnce } from 'react-use';
import { useTranslation } from "react-i18next";
import _ from 'lodash';

import { compactNumberFormat, getColorStatus, localeStringTwoDigits } from "../../../../../../services/utils";
import api from '../../../../../../services/api';
import colors from '../../../../../../themes/colors';
import Skeleton from "../../../../../../components/Skeleton";
import theme from '../../../../../../themes/theme';
import { useContextSelector } from 'use-context-selector';

import themeModeGlobalState from "../../../../../../contexts/ThemeMode";
import { MainIndexContext } from '../../../../../../contexts/MainIndexProvider';
import { StockContext } from '../../../../../../contexts/StocksProvider';
import UserACL from '../../../../../../components/UserACL';

type IndicesProps = {
    key: string;
    title: string;
    data: any;
};

const IndexCell = ({ item, index }) => {
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const mainIndexState = useContextSelector(
        MainIndexContext,
        (s) => s[0][item.indexID],
    );

    const change = mainIndexState?.change ? mainIndexState.change : item.change;
    const indexID = mainIndexState?.indexID ? mainIndexState.indexID : item.indexID;
    const totalValue = mainIndexState?.totalValue ? mainIndexState.totalValue : item.totalValue;
    const indexValue = mainIndexState?.indexValue ? mainIndexState.indexValue : item.indexValue;
    const totalVolume = mainIndexState?.totalVolume ? mainIndexState.totalVolume : item.totalVolume;
    const ratioChange = mainIndexState?.ratioChange ? mainIndexState.ratioChange : item.ratioChange;

    return (
        <Row
            className="px-2 py-1"
            style={{
                color: getColorStatus(0, change, themeMode),
                backgroundColor: index % 2 ? colors.chartBackground[themeMode] : colors.tableRowDarker[themeMode],
            }}
        >
            <Col span={12}>
                <div className="uppercase font-bold">{indexID}</div>
                <div className='text-gray'>
                    {t('GT')}: {compactNumberFormat(totalValue)}
                </div>
            </Col>
            <Col span={8}>
                <div className="font-bold">{indexValue}</div>
                <div className='text-gray'>
                    {t('KL')}: {compactNumberFormat(totalVolume)}
                </div>
            </Col>
            <Col span={4} className="text-right">
                <div className="font-bold">
                    {ratioChange <= 0 ? '' : '+'}{localeStringTwoDigits(ratioChange)}%
                </div>
                <div className=''>
                    ({change <= 0 ? '' : '+'}{localeStringTwoDigits(change)})
                </div>
            </Col>
        </Row>
    )
}

const FutureIndexCell = ({ item, index }) => {
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][item.stockCode],
    );

    const priceChange = stockState?.priceChange ? stockState.priceChange : item.priceChange;
    const stockCode = stockState?.stockCode ? stockState.stockCode : item.stockCode;
    const totalValue = stockState?.totalValue ? stockState.totalValue : item.totalValue;
    const matchedPrice = stockState?.matchedPrice ? stockState.matchedPrice : item.matchedPrice;
    const totalVolume = stockState?.totalVolume ? stockState.totalVolume : item.totalVolume;
    const pricePercentChange = stockState?.pricePercentChange ? stockState.pricePercentChange : item.pricePercentChange;

    return (
        <Row
            className="px-2 py-1"
            style={{
                color: getColorStatus(0, priceChange, themeMode),
                backgroundColor: index % 2 ? colors.chartBackground[themeMode] : colors.tableRowDarker[themeMode],
            }}
        >
            <Col span={12}>
                <div className="uppercase font-bold">{stockCode}</div>
                <div className='text-gray'>
                    {t('GT')}: {compactNumberFormat(totalValue)}
                </div>
            </Col>
            <Col span={8}>
                <div className="font-bold">{matchedPrice}</div>
                <div className='text-gray'>
                    {t('KL')}: {compactNumberFormat(totalVolume)}
                </div>
            </Col>
            <Col span={4} className="text-right">
                <div className="font-bold">
                    {pricePercentChange <= 0 ? '' : '+'}{localeStringTwoDigits(pricePercentChange)}%
                </div>
                <div className=''>
                    ({priceChange <= 0 ? '' : '+'}{localeStringTwoDigits(priceChange)})
                </div>
            </Col>
        </Row>
    )
}

const IndicesTable = ({ data, isFutureIndices }) =>  {
    return (
        <>
            {isFutureIndices ?
                <>
                    {data.map((item: any, index: number) => {
                        return <FutureIndexCell key={`${item.indexID}_${index}`} item={item} index={index} />
                    })}
                </>
            :
                <>
                    {data.map((item: any, index: number) => {
                        return <IndexCell key={`${item.stockCode}_${index}`} item={item} index={index} />
                    })}
                </>
            }
        </>
    )
}

const Indices = ({ ...props }) => {
    const [indices, setIndices] = useState<IndicesProps[]>([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const formatIndicesData = (data) => {
        return [
            {
                key: 'main',
                title: t('Chỉ số thị trường'),
                data: sortMainIndex(data.main),
            },
            {
                key: 'fu',
                title: t('Chỉ số phái sinh'),
                data: sortIndices(data.fu, 'stockCode', 'asc'),
            },
            {
                key: 'others',
                title: t('Chỉ số nhóm ngành'),
                data: sortIndices(data.other, 'indexID', 'asc'),
            },
        ];
    };

    const sortIndices = (data, sortBy, order) => {
        let _data = data;
        if (sortBy === 'stockCode') {
            _data = data.filter(entry => entry[sortBy].startsWith('vn30'));
        }
        return _.orderBy(_data, [sortBy], [order]);
    };

    const sortMainIndex = (data) => {
        const orderMainIndex = [
            'vnindex',
            'vn30',
            'hnxindex',
            'hnx30',
            'upcomindex',
            'vn100',
            'vnsml',
            'vnmid',
            'vnxall',
        ];
        return data.sort(function (a, b) {
            return (
                orderMainIndex.indexOf(a.indexID) -
                orderMainIndex.indexOf(b.indexID)
            );
        });
    };

    const fetchIndicesData = async () => {
        setLoading(true);

        let [error, response]: any = await api.get('plugins/main-index/index-all');

        // @TODO handle error
        if (error) {
            console.log(error);
            return;
        }

        if (response) {
            const _data = formatIndicesData(response.data);
            setIndices(_data);
        }
        setLoading(false);
    };

    useEffectOnce(() => {
        fetchIndicesData();
    });

    const styles = {
        overflow: 'auto',
        height: props.height,
        backgroundColor: theme.colors.chartBackground[themeMode],
    };

    return (
        <UserACL feature="indices" style={styles}>
            {loading
                ? <Skeleton />
                : <>
                    {indices.map(section => {
                        return (
                            <div key={section.key}>
                                <div className="px-2 py-2 text-sm font-bold" style={{ background: themeMode === 'dark' ? '#0F121A' : '#CBD5E0' }}>{section.title}</div>
                                <IndicesTable
                                    data={section.data}
                                    isFutureIndices={section.key === 'fu'}
                                />
                            </div>
                        )
                    })}
                </>
            }
        </UserACL>
    )
};

export default Indices;