import React from "react";
import useStockDetailModalState from "../../../../contexts/StockDetailModal";

type NameWithChartIconProps = {
    color: string,
    symbol: string,
    relateSymbol?: string,
    changeTradingView?: (stockSymbol: string) => void,
    onClick?: () => void,
}

const NameWithChartIcon = (props: NameWithChartIconProps) => {
    const { color, symbol, changeTradingView, relateSymbol, onClick: customOnClick } = props;
    const [, setStockDetailModalState] = useStockDetailModalState();

    if (changeTradingView) {
        return <div className="cursor-pointer" onClick={() => changeTradingView(symbol)}>
            <span className="font-bold" style={{color}}>{symbol}</span>
        </div>
    }

    const onClick = () => {
        const _redirectSymbol = relateSymbol ? relateSymbol : symbol
        setStockDetailModalState({
            stockCode: _redirectSymbol,
            isVisible: true
        })
    }

    return (
        <>
            <span
                className="font-bold cursor-pointer"
                style={{color}}
                onClick={customOnClick ? customOnClick : onClick}
            >
                {symbol}
            </span>
        </>
    )
}

export default NameWithChartIcon;
