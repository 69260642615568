import React from "react";
import { Card, message } from "antd";
import { useHistory } from "react-router-dom";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import CategoryForm from "./_Form";
import api from "../../../../../services/api";
import routes from "../../../../../routes";

const CategoryAddPage = () => {
    const history = useHistory();

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.post('plugins/news/category/add', formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Thêm mới danh mục thành công");
        history.replace(routes.ADMIN_NEWS_CATEGORY_LIST);
    }

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Tin tức', path: routes.ADMIN_NEWS_PROVIDER_LIST },
                { label: 'Danh mục', path: routes.ADMIN_NEWS_CATEGORY_LIST },
                { label: 'Thêm mới' },
            ]} />

            <div className="m-auto mt-5 w-3/6">
                <CategoryForm mode='add' onFinish={onFinish}/>
            </div>
        </Card>
    )
}

export default CategoryAddPage;