import React, { useState, useImperativeHandle, Ref } from "react";
import { Select, Skeleton } from "antd";
import _ from 'lodash';

import localStorage from "../../../../services/local-storage";
import { useAuthState } from "../../../../contexts";
import { WatchListType } from "../../../../types/modules/watchList";
import { useWatchlistClient } from '../WatchList/client';

const { Option } = Select;

export type SelectWatchListInputProps = {
    onChangeSelectWatchListInput?: (stockCodes: string[]) => void,
    style?: object,
};

export interface SelectWatchListRef {
    getWatchListSelected: () => WatchListType | undefined,
};

const SelectWatchListInput = (props: SelectWatchListInputProps, ref: Ref<SelectWatchListRef>) => {
    const [ watchListSelected, setWatchListSelected ] = useState<WatchListType>();
    const watchlistClient = useWatchlistClient();
    const { authState, setAuthState } = useAuthState() as any;

    const { watchListState } = watchlistClient;
    const { watchlists } = watchListState;

    const onChange = async (value: string) => {
        const watchlist = _.find(watchlists, (w) => {
            return w.id === value
        })

        setWatchListSelected(watchlist);
        setAuthState({ selectedWatchlist: watchlist });
        localStorage.set('selectedWatchlist', watchlist);

        if (watchlist && props.onChangeSelectWatchListInput) {
            props.onChangeSelectWatchListInput(watchlist.stocks)
        }
    };

    useImperativeHandle(ref, () => ({
        getWatchListSelected: () => {
            return watchListSelected
        }
    }));

    let selectDefaultValue = {};

    if (authState.selectedWatchlist) {
        selectDefaultValue = {
            defaultValue: authState.selectedWatchlist.id
        }
    }

    if (!watchlists) return <Skeleton/>;

    return <Select
        showSearch
        style={props.style || { width: 200 }}
        onChange={onChange}
        placeholder="Danh mục quan tâm"
        optionLabelProp="label"
        filterOption={(input, option) =>{
            return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        {...selectDefaultValue}
    >
        {watchlists.map((list: WatchListType) => <Option key={list.id} value={list.id} label={list.name}>{list.name}</Option>)}
    </Select>
}

export default React.forwardRef(SelectWatchListInput);
