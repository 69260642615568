import * as RecommendationsServices from '../services';

export const useRecommendationsClient = () => {
    const fetchRecommendationsData = async (categoryId, filter, page) => {
        let [error, response] = await RecommendationsServices.fetchRecommendations(categoryId, filter, page);

        if (error) return [error, null];

        return [null, response];
    };

    const fetchRecommendationDetail = async (recommendationId) => {
        let [error, response] = await RecommendationsServices.fetchRecommendationDetail(recommendationId);

        if (error) return [error, null];

        return [null, response];
    };

    return {
        fetchRecommendationsData,
        fetchRecommendationDetail
    }
}