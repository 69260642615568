import React, { useEffect } from "react";
import { notification } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useAuthState } from "../../contexts";
import { useTranslation } from "react-i18next";
import localStorage from "../../services/local-storage";
import api from "../../services/api";
import routes from "../../routes";
import Loading from "../../components/Loading";

const UserActivatePage = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { token }: any = useParams();
    const { setAuthState } = useAuthState() as any;

    const activeUser = async () => {
        let [err, resp]: any = await api.post('user/active', { token });

        if (err) return <Loading/>;

        if (resp) {
            const { accessToken, user } = resp.data;

            api.setAccessToken(accessToken);
            setAuthState({ currentUser: user });
            localStorage.set('accessToken', accessToken);
            history.push(routes.HOME);
            notification.success({
                message: t('Chúc mừng quý khách đã đăng ký tài khoản thành công trên Fstock. Bắt đầu trải nghiệm ngay!'),
            });
        }
    }

    useEffect(() => {
        activeUser();
    }, []); // eslint-disable-line

    return <Loading/>
}

export default UserActivatePage;