import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { fetchMarketReports } from "./services";
import { calcAccumulationData } from "./utils";
import { groupComposedData, groupPieData } from "../Macroeconomics/utils";
import { genPeriod } from "../../../../../../services/utils";
import * as appConfig from "../../../../../../config/index";
import useConfig from "../../../../../../contexts/useConfig";
import ChartCard from "../../../../../../components/CustomComposedChart";
import Skeleton from "../../../../../../components/Skeleton";

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 80;
const SLICE_MONTH = -24;

const MarketReportsContainer = (props) => {
    let { glContainer } = props;
    const { t } = useTranslation();
    const [config] = useConfig();

    let containerWidth = glContainer.width > 0 ? glContainer.width : window.innerWidth - SIDER_WIDTH;
    let containerHeight = glContainer.height > 0 ? glContainer.height : window.innerHeight - HEADER_HEIGHT;

    const [ marketReports, setMarketReports ] = useState<NSApp.MarketReports>();
    const [ loading, setLoading ] = useState(true);

    const fetchData = async () => {
        let [err, resp] = await fetchMarketReports();

        if (err) return;

        setMarketReports(resp);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []); // eslint-disable-line

    const getTabs = () => {
        return [
            { key: "marketCap", title: t('Vốn hoá'), data: getMarketCapitalChart(marketReports), column: 1, row: 1 },
            { key: "totalEnterprise", title: t('Số lượng doanh nghiệp'), data: getTotalEnterpriseChart(marketReports), column: 2, row: 2 },
            { key: "newAccounts", title: t('Tài khoản mở mới'), data: getNewAccountsChart(marketReports), column: 2, row: 2 },
            { key: "marketShare", title: t('Thị phần môi giới'), data: getMarketShareChart(marketReports), column: 2, row: 4 },
            { key: "derivatives", title: t('Phái sinh'), data: getDerivativesChart(marketReports), column: 1, row: 1 },
            { key: "liquidity", title: t('Thanh khoản'), data: getLiquidityChart(marketReports), column: 2, row: 2 },
        ]
    }

    const getMarketCapitalChart = (data) => {
        if (!data) return [];

        return [
            {
                chartType: "composed",
                title: "",
                data: groupComposedData([
                    data.capitalHSX,
                    data.capitalHNX,
                    data.capitalUpcom,
                    data.capitalTPCPTPDN,
                    data.capitalTotal,
                ]),
                timeType: "month",
                YAxisUnit: t('Tỷ VNĐ'),
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [
                        { dataKey: "value1", name: t('HOSE'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value2", name: t('HNX'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value3", name: t('UPCOM'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value4", name: t('TPCP/TPDN'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value5", name: t('Tổng cộng'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                    ],
                },
            },
        ];
    };

    const getTotalEnterpriseChart = (data) => {
        if (!data) return [];

        const totalEnterpriseChartStructure = (title: string, timeKey: string) => {
            return {
                chartType: "composed",
                title: title,
                data: groupPieData(
                    [
                        { name: t('HOSE'), data: data.totalEnterpriseHOSE },
                        { name: t('HNX'), data: data.totalEnterpriseHNX },
                        { name: t('UPCOM'), data: data.totalEnterpriseUPCOM },
                        { name: t('ETF'), data: data.totalEnterpriseETF },
                        { name: t('CCQ đóng'), data: data.totalEnterpriseCCQ },
                        { name: t('CW'), data: data.totalEnterpriseCW },
                        { name: t('TPCP'), data: data.totalEnterpriseTCTP },
                        { name: t('TP/HNX'), data: data.totalEnterpriseTPCPHNX },
                        { name: t('TP/HOSE'), data: data.totalEnterpriseTPCPHOSE },
                    ],
                    'month',
                    timeKey,
                ),
                timeType: '',
                XAxisKey: 'name',
                YAxisUnit: t('Doanh nghiệp'),
                chartStructure: {
                    bar: [{ dataKey: 'value', name: t('SL Doanh nghiệp'), yAxisType: 'left', unit: '' }],
                    line: [],
                    area: [],
                },
            };
        };

        const periods = genPeriod({
            value: config.TOTAL_ENTERPRISE_BY_TIME,
            type: 'month',
            period: 6,
        });

        return periods.map((period) => {
            const label = `${t('Tháng')} ${period}`;
            return totalEnterpriseChartStructure(label, period);
        });
    }

    const getNewAccountsChart = (data) => {
        if (!data) return [];

        return [
            {
                chartType: "composed",
                title: t('Nhà đầu tư trong nước'),
                data: calcAccumulationData(data.newAccountIndividualDomestic).slice(SLICE_MONTH),
                timeType: "month",
                YAxisUnit: t('TK'),
                chartStructure: {
                    area: [],
                    bar: [{ dataKey: "value", name: t('NĐT'), yAxisType: "left", unit: "" }],
                    line: [],
                },
            },
            {
                chartType: "composed",
                title: t('Lũy kế nhà đầu tư trong nước'),
                data: data.newAccountIndividualDomestic.slice(SLICE_MONTH),
                timeType: "month",
                YAxisUnit: t('TK'),
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [{ dataKey: "value", name: t('NĐT'), yAxisType: "left", unit: "" }],
                },
            },
            {
                chartType: "stackBar",
                title: t('Nhà đầu tư nước ngoài & Tổ chức'),
                data: groupComposedData([
                    calcAccumulationData(data.newAccountIndividualForeign),
                    calcAccumulationData(data.newAccountOrgDomestic),
                    calcAccumulationData(data.newAccountOrgForeign),
                ]).slice(SLICE_MONTH),
                timeType: "month",
                YAxisUnit: t('TK'),
                chartStructure: {
                    bar: [
                        { dataKey: "value1", name: t('NĐT nước ngoài'), yAxisType: "left", unit: "" },
                        { dataKey: "value2", name: t('Tổ chức trong nước'), yAxisType: "left", unit: "" },
                        { dataKey: "value3", name: t('Tổ chức nước ngoài'), yAxisType: "left", unit: "" },
                    ],
                    line: [],
                    area: [],
                },
            },
            {
                chartType: "composed",
                title: t('Lũy kế nhà đầu tư nước ngoài & Tổ chức'),
                data: groupComposedData([
                    data.newAccountIndividualForeign,
                    data.newAccountOrgDomestic,
                    data.newAccountOrgForeign,
                ]).slice(SLICE_MONTH),
                timeType: "month",
                YAxisUnit: t('TK'),
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [
                        { dataKey: "value1", name: t('NĐT nước ngoài'), yAxisType: "left", unit: "" },
                        { dataKey: "value2", name: t('Tổ chức trong nước'), yAxisType: "left", unit: "" },
                        { dataKey: "value3", name: t('Tổ chức nước ngoài'), yAxisType: "left", unit: "" },                    ],
                },
            },
        ]
    };

    const getMarketShareChart = (data) => {
        if (!data) return [];

        const stockMarketShareStructure = (title: string, timeKey: string) => {
            return {
                chartType: "composed",
                title: `${t('Cổ phiếu và chứng chỉ quỹ')} (${title})`,
                data: groupPieData(
                    [
                        { name: 'VPS', data: data.stockVPSQuarter },
                        { name: 'SSI', data: data.stockSSIQuarter },
                        { name: 'VNDS', data: data.stockVNDSQuarter },
                        { name: 'HSC', data: data.stockHSCQuarter },
                        { name: 'TCBS', data: data.stockTCBSQuarter },
                        { name: 'MAS', data: data.stockMASQuarter },
                        { name: 'MBS', data: data.stockMBSQuarter },
                        { name: 'VCSC', data: data.stockVCSCQuarter },
                        { name: 'FPTS', data: data.stockFPTSQuarter },
                        { name: 'KIS', data: data.stockKISQuarter },
                        { name: 'BSC', data: data.stockBSCQuarter },
                    ],
                    'quarter',
                    timeKey,
                    true,
                ),
                timeType: '',
                XAxisKey: 'name',
                YAxisUnit: '',
                chartStructure: {
                    bar: [{ dataKey: 'value', name: `${t('Thị phần')} (%)`, yAxisType: 'left', unit: '' }],
                    line: [],
                    area: [],
                },
            };
        };

        const bondMarketShareStructure = (title: string, timeKey: string) => {
            return {
                chartType: "composed",
                title: `${t('Trái phiếu')} (${title})`,
                data: groupPieData(
                    [
                        { name: 'ABS', data: data.bondABSQuarter },
                        { name: 'BSC', data: data.bondBSCQuarter },
                        { name: 'EVS', data: data.bondEVSQuarter },
                        { name: 'HSC', data: data.bondHSCQuarter },
                        { name: 'KBSV', data: data.bondKBSVQuarter },
                        { name: 'KIS', data: data.bondKISQuarter },
                        { name: 'KSS', data: data.bondKSSQuarter },
                        { name: 'MAS', data: data.bondMASQuarter },
                        { name: 'MBS', data: data.bondMBSQuarter },
                        { name: 'PHS', data: data.bondPHSQuarter },
                        { name: 'PSI', data: data.bondPSIQuarter },
                        { name: 'SHS', data: data.bondSHSQuarter },
                        { name: 'SSV', data: data.bondSSVQuarter },
                        { name: 'TCBS', data: data.bondTCBSQuarter },
                        { name: 'TPS', data: data.bondTPSQuarter },
                        { name: 'TVSI', data: data.bondTVSIQuarter },
                        { name: 'VCBS', data: data.bondVCBSQuarter },
                        { name: 'VCSC', data: data.bondVCSCQuarter },
                        { name: 'VDSC', data: data.bondVDSCQuarter },
                        { name: 'VIS', data: data.bondVISQuarter },
                        { name: 'VNDS', data: data.bondVNDSQuarter },
                        { name: 'VPS', data: data.bondVPSQuarter },
                    ],
                    'quarter',
                    timeKey,
                    true,
                ),
                timeType: '',
                XAxisKey: 'name',
                YAxisUnit: '',
                chartStructure: {
                    bar: [{ dataKey: 'value', name: `${t('Thị phần')} (%)`, yAxisType: 'left', unit: '' }],
                    line: [],
                    area: [],
                },
            };
        };

        const stockPeriods = genPeriod({
            value: config.STOCK_MARKET_SHARE_BY_TIME,
            type: 'quarter',
        });

        const bondPeriods = genPeriod({
            value: config.BOND_MARKET_SHARE_BY_TIME,
            type: 'quarter',
        });

        const stocks = stockPeriods.map((period) => {
            const label = `${t('Quý')} ${period}`;
            return stockMarketShareStructure(label, period);
        });

        const bonds = bondPeriods.map((period) => {
            const label = `${t('Quý')} ${period}`;
            return bondMarketShareStructure(label, period);
        });

        const chartsList = [...stocks, ...bonds];

        return chartsList;
    };

    const getDerivativesChart = (data) => {
        if (!data) return [];

        return [
            {
                chartType: "composed",
                title: "",
                data: groupComposedData([
                    data.derivativeAverageVolume,
                    data.derivativeOIVolume,
                ]),
                timeType: "month",
                YAxisUnit: "",
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [
                        { dataKey: "value1", name: t('KLGDBQ phiên'), yAxisType: "left", unit: "" },
                        { dataKey: "value2", name: t('KL mở OI cuối kỳ'), yAxisType: "left", unit: "" },
                    ],
                },
            },
        ]
    };

    const getLiquidityChart = (data) => {
        if (!data) return [];

        return [
            {
                chartType: "composed",
                title: t('Giá trị giao dịch bình quân'),
                data: groupComposedData([
                    data.averageValueHOSEMonthly,
                    data.averageValueHNXMonthly,
                    data.averageValueUPCOMMonthly,
                    data.averageValueTotalMonthly,
                ]),
                timeType: "month",
                YAxisUnit: t('Tỷ VNĐ'),
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [
                        { dataKey: "value1", name: t('HOSE'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value2", name: t('HNX'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value3", name: t('UPCOM'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value4", name: t('Tổng cộng'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                    ],
                },
            },
            {
                chartType: "composed",
                title: t('Khối lượng giao dịch bình quân'),
                data: groupComposedData([
                    data.averageVolumeHOSEMonthly,
                    data.averageVolumeHNXMonthly,
                    data.averageVolumeUPCOMMonthly,
                    data.averageVolumeTotalMonthly,
                ]),
                timeType: "month",
                YAxisUnit: t('Tỷ VNĐ'),
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [
                        { dataKey: "value1", name: t('HOSE'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value2", name: t('HNX'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value3", name: t('UPCOM'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value4", name: t('Tổng cộng'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                    ],
                },
            },
            {
                chartType: "composed",
                title: t('Giá trị giao dịch luỹ kế'),
                data: groupComposedData([
                    data.valueHOSEMonthly,
                    data.valueHNXMonthly,
                    data.valueUPCOMMonthly,
                    data.valueTotalMonthly,
                ]),
                timeType: "month",
                YAxisUnit: t('Tỷ VNĐ'),
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [
                        { dataKey: "value1", name: t('HOSE'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value2", name: t('HNX'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value3", name: t('UPCOM'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value4", name: t('Tổng cộng'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                    ],
                },
            },
            {
                chartType: "composed",
                title: t('Khối lượng giao dịch luỹ kế'),
                data: groupComposedData([
                    data.volumeHOSEMonthly,
                    data.volumeHNXMonthly,
                    data.volumeUPCOMMonthly,
                    data.volumeTotalMonthly,
                ]),
                timeType: "month",
                YAxisUnit: t('Tỷ VNĐ'),
                chartStructure: {
                    area: [],
                    bar: [],
                    line: [
                        { dataKey: "value1", name: t('HOSE'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value2", name: t('HNX'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value3", name: t('UPCOM'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                        { dataKey: "value4", name: t('Tổng cộng'), yAxisType: "left", unit: t('Tỷ VNĐ') },
                    ],
                },
            },
        ]
    };

    return (
        <div className="overflow-y-auto px-1" style={{ height: containerHeight }}>
            <Tabs defaultActiveKey="marketCap">
                {getTabs().map(tab => {
                    const { key, title, data, column, row } = tab;
                    let chartWidth = containerWidth > appConfig.RESPONSIVE_SCREEN.lg ? (containerWidth - 100) / column : (containerWidth - 100) / 2;
                    let magicNumber = -12;
                    if (row === 2) { magicNumber = 29 }
                    if (row === 3) { magicNumber = 8 }
                    if (row === 4) { magicNumber = 40 }
                    // let chartHeight = containerHeight > 570 ? (containerHeight - 80) / row - magicNumber : 200;
                    let chartHeight = (containerHeight - 83) / row - magicNumber;

                    if (loading) {
                        return <Tabs.TabPane key={key} tab={title}>
                            <div style={{height: containerHeight/3-20}}><Skeleton column={3}/></div>
                            <div style={{height: containerHeight/3-20}}><Skeleton column={3}/></div>
                            <div style={{height: containerHeight/3-20}}><Skeleton column={3}/></div>
                        </Tabs.TabPane>
                    }

                    return <Tabs.TabPane key={key} tab={title}>
                        <Row gutter={[4, 4]}>
                            {data.map(item => {
                                return <Col span={24/column} key={item.title}>
                                    <ChartCard
                                        title={item.title}
                                        width={chartWidth}
                                        height={chartHeight}
                                        data={item.data}
                                        timeType={item.timeType}
                                        chartType={item.chartType}
                                        chartStructure={item.chartStructure}
                                        XAxisKey={item.XAxisKey}
                                        YAxisUnit={item.YAxisUnit}
                                        secondYAxis={item.secondYAxis}
                                        secondYAxisUnit={item.secondYAxisUnit}
                                    />
                                </Col>
                            })}
                        </Row>
                    </Tabs.TabPane>
                })}
            </Tabs>
        </div>
    )
};

export default MarketReportsContainer;