import React, { useEffect, useState, useRef } from "react";
import { useUnmount } from 'react-use';
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useForeignerTradingClient } from "./client";
import ScatterAndBarChart from "./components/ScatterAndBarChart";
import LineChart from "./components/LineChart";
import Table from "./components/Table";
import Skeleton from "../../../../../../components/Skeleton";

type ForeignerTradingContainerProps = {
    glContainer: any
}

const initialExchangeDetail: NSApp.ExchangeDetail = {
    _id: "",
    advances: 0,
    ceiling: 0,
    closePrice: 0,
    currentPrice: 0,
    declines: 0,
    exchange: "",
    floor: 0,
    netGain: 0,
    noChanges: 0,
    percentGain: 0,
    time: 0,
    qttyInTime: 0,
    formattedTime: "",
    totalValue: 0,
    totalVolume: 0,
    foreignBuyValue: 0,
    foreignSellValue: 0,
    foreignBuyVolume: 0,
    foreignSellVolume: 0
}

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 80;

const ForeignerTradingContainer = (props: ForeignerTradingContainerProps) => {
    let { glContainer } = props;
    const intervalRef = useRef<any>(null);
    const { t } = useTranslation();

    let containerWidth = glContainer.width > 0 ? glContainer.width : window.innerWidth - SIDER_WIDTH;
    let containerHeight = glContainer.height > 0 ? glContainer.height : window.innerHeight - HEADER_HEIGHT;

    let itemWidth = (containerWidth - 8) * 2 / 3 - 25; // grid col 16 - 8
    let itemHeight = (containerHeight - 50) / 3; // 3 items

    const [ exchangeDetail, setExchangeDetail ] = useState<NSApp.ExchangeDetail>(initialExchangeDetail);
    const [ stocks, setStocks ] = useState<NSApp.Stock[]>([]);
    const [ foreignerTradingByTime, setForeignerTradingByTime ] = useState<NSApp.ExchangeDetail[]>([]);
    const [ exchange, setExchange ] = useState('');
    const [ orderBy, setOrderBy ] = useState('valueBuy');
    const [ checkedStock, setCheckedStock ] = useState('all');
    const [ loading, setLoading ] = useState(true);
    const [ totalForeignValue, setTotalForeignValue ] = useState({});

    let foreignerClient = useForeignerTradingClient();

    const fetchForeignerTopStocks = async () => {
        let stocks = await foreignerClient.fetchForeignerTopStocks(exchange, orderBy);

        if (stocks) {
            setStocks(stocks);
        }
    };

    const fetchExchangeDetail = async () => {
        let detail = await foreignerClient.fetchExchangeData(exchange);

        if (detail) {
            setExchangeDetail(detail);
        }

        return detail;
    };

    const fetchTotalForeignValue = async () => {
        const totalForeignValue = await foreignerClient.fetchTotalForeignValue();

        if (totalForeignValue) {
            setTotalForeignValue(totalForeignValue);
        }

        return totalForeignValue;
    }

    useEffect(() => {
        if (stocks.length && foreignerTradingByTime) {
            setLoading(false);
        }
    }, [stocks, foreignerTradingByTime, exchangeDetail]); // eslint-disable-line

    const fetchData = async () => {
        await fetchExchangeDetail();
        await fetchForeignerTopStocks();
        await fetchTotalForeignValue();
        let indicesAfterFetch = await foreignerClient.fetchIndiceDataByTime(exchange);

        if (checkedStock === 'all') {
            setForeignerTradingByTime(indicesAfterFetch);
        } else {
            //await fetchForeignerTradingByTime();
        }
    }

    const fetchDataInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(async () => {
            await fetchData();
        }, 60 * 1000);
    }

    useEffect(() => {
        fetchData();
        fetchDataInterval();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchange, orderBy, checkedStock]);

    useUnmount(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = undefined;
        }
    })

    let sortByValue = ['valueBuy', 'valueSell', 'netValueBuy', 'netValueSell'].includes(orderBy);
    let buyKey = sortByValue ? "foreignBuyValue" : "foreignBuyVolume";
    let sellKey = sortByValue ? "foreignSellValue" : "foreignSellVolume";
    let formattedSellKey = sortByValue ? "formattedSellForeignValue" : "formattedSellForeignVolume";
    let amountKey = sortByValue ? "amountForeignValue" : "amountForeignVolume";
    let titleSortBy = sortByValue ? t('GTGD') : t('KLGD');
    let titleUnitSortBy = sortByValue ? `(${t('Tỷ VNĐ')})` : "";

    return (
        <div>
            {loading ?
                <Row gutter={2} justify="center">
                    <Col span={16}>
                        <div style={{height: itemHeight*2}}><Skeleton/></div>
                        <div style={{height: itemHeight}}><Skeleton/></div>
                    </Col>
                    <Col span={8}><Skeleton/></Col>
                </Row>
                :
                <Row gutter={[4, 2]} justify="center">
                    <Col span={16}>
                        <ScatterAndBarChart
                            stocks={stocks}
                            sortByValue={sortByValue}
                            width={itemWidth}
                            height={itemHeight}
                            buyKey={buyKey}
                            formattedSellKey={formattedSellKey}
                            amountKey={amountKey}
                            titleSortBy={titleSortBy}
                            titleUnitSortBy={titleUnitSortBy}
                        />
                        <LineChart
                            stocks={stocks}
                            foreignerTradingByTime={foreignerTradingByTime}
                            exchange={exchange}
                            sortBy={orderBy}
                            sortByValue={sortByValue}
                            checkedStock={checkedStock}
                            width={itemWidth}
                            height={itemHeight}
                            buyKey={buyKey}
                            sellKey={sellKey}
                            amountKey={amountKey}
                            titleSortBy={titleSortBy}
                            titleUnitSortBy={titleUnitSortBy}
                            changeCheckedStock={setCheckedStock}
                        />
                    </Col>
                    <Col span={8}>
                        <Table
                            stocks={stocks}
                            exchange={exchange}
                            exchangeDetail={exchangeDetail}
                            sortBy={orderBy}
                            sortByValue={sortByValue}
                            checkedStock={checkedStock}
                            height={glContainer.height}
                            buyKey={buyKey}
                            sellKey={sellKey}
                            amountKey={amountKey}
                            titleSortBy={titleSortBy}
                            titleUnitSortBy={titleUnitSortBy}
                            changeSortBy={setOrderBy}
                            changeExchange={setExchange}
                            changeCheckedStock={setCheckedStock}
                            totalForeignValue={totalForeignValue}
                        />
                    </Col>
                </Row>
            }
        </div>
    )
}

export default ForeignerTradingContainer;
