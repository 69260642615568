import React from "react";
import { Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import StockDetail from "../../pages/private/client/Dashboard/plugins/StockDetail";

const PADDING = 40;
const WIDTH = window.innerWidth - (PADDING * 2);
const HEIGHT = window.innerHeight - (PADDING * 2);

type StockDetailModalProps = {
    stockCode: string;
    isVisible: boolean;
    setIsVisible: (isVisible: boolean) => void;
}

const StockDetailModal = (props: StockDetailModalProps) => {
    return (
        <Modal
            visible={props.isVisible}
            onCancel={() => props.setIsVisible(false)}
            footer={null}
            destroyOnClose={true}
            width={WIDTH}
            bodyStyle={{ padding: 4 }}
            maskStyle={{ backgroundColor: 'rgba(15, 15, 15, 0.8)' }}
            style={{ top: PADDING }}
            closeIcon={<CloseOutlined style={{ fontSize: 20 }} />}
        >
            <StockDetail
                id={uuidv4()}
                symbol={props.stockCode.toLowerCase()}
                width={WIDTH}
                height={HEIGHT}
            />
        </Modal>
    )
}

export default StockDetailModal;