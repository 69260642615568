import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import RecommendationListPage from "./List";
import RecommendationAddPage from "./Add";
import RecommendationEditPage from "./Edit";
import RecommendationDetailPage from "./Detail";


import routes from "../../../../routes";

const NewsDashboard = () => {
    return (
        <div>
            <Switch>
                <Route path={routes.ADMIN_RECOMMENDATION_LIST} component={RecommendationListPage} />
                <Route path={routes.ADMIN_RECOMMENDATION_ADD} component={RecommendationAddPage} />
                <Route path={routes.ADMIN_RECOMMENDATION_EDIT} component={RecommendationEditPage} />
                <Route path={routes.ADMIN_RECOMMENDATION_DETAIL} component={RecommendationDetailPage} />

                <Route path={routes.ADMIN_RECOMMENDATION}>
                    <Redirect to={routes.ADMIN_RECOMMENDATION_LIST} />
                </Route>
            </Switch>
        </div>
    )
}

export default NewsDashboard;