import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useAuthState } from "../../contexts";
import routes from '../../routes';

const UserACL = ({ children, feature, ...props }) => {
    const { authState } = useAuthState() as any;
    const { t } = useTranslation();
    const styles = { ...props.style };

    const userACLKey = authState?.currentUser?.userACL?.key;

    let userACLName = '';

    if (userACLKey === 'basic') {
        userACLName = t('gói Nâng cao và gói Chuyên nghiệp');
    }

    if (userACLKey === 'advanced') {
        userACLName = t('Chuyên nghiệp');
    }

    if(props.userACLName) {
        userACLName = props.userACLName;
    }

    if (!authState?.currentUser?.userACL?.features.includes(feature)) {
        return (
            <div className="flex flex-col justify-center items-center h-full" style={styles}>
                {!!userACLName && <div className="text-2xl font-bold mb-1">{t('Tính năng')} {userACLName}</div>}
                <div className="text-sm text-gray mb-4">{t('Vui lòng nâng cấp tài khoản để sử dụng tính năng này')}</div>
                <Link to={routes.UPGRADE_PLAN}>
                    <Button type="primary" size="large" className="font-bold">{t('Nâng cấp tài khoản')}</Button>
                </Link>
            </div>
        );
    }

    return <div style={styles}>{children}</div>;
};

export default UserACL;