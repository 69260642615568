import React, { useState, useRef } from "react";
import { Skeleton } from "antd";
import { useEffectOnce, useUnmount } from 'react-use';
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { ComposedChart, Line, Bar, YAxis, XAxis, Label, ReferenceLine } from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import _ from 'lodash';

import { roundedNumber } from "../../../../../../../services/utils";
import theme from "../../../../../../../themes/theme";
import MainIndexInfo from './MainIndexInfo';
import useMainIndexDefaultState from '../../../../../../../contexts/MainIndexDefault';
import * as MainIndexService from "../services/index";
import animateGlobalState from '../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import exchangeStatusGlobalState from '../../../../../../../contexts/exchangeStatus';

type MainIndexChartProps = {
    loading?: boolean;
    width: number;
    height: number;
    indexId: string;
    indexLabel?: string;
}

type MainIndexLineChartProps = {
    width?: number;
    height?: number;
    indexId: string;
    exchangeStatus: any;
}

const MainIndexLineChart = (props: MainIndexLineChartProps) => {
    const { width, height, indexId, exchangeStatus } = props;
    const [mainIndexDefault] = useMainIndexDefaultState();
    const [values, setValues] = useState<NSApp.MainIndexValue[]>([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();
    const valuesIntervalRef = useRef<any>(null);
    const itemHeight = height && height > 134 ? height : 134;

    const fetchMainIndexValues = async () => {
        setLoading(true);
        let values = await MainIndexService.fetchMainIndexValues(indexId);
        let sortedValues = values.sort((a, b) => a.time - b.time);
        setValues(sortedValues);
        setLoading(false);
    }

    const fetchMainIndexValuesInterval = async () => {
        if (valuesIntervalRef.current) {
            clearInterval(valuesIntervalRef.current);
        }

        valuesIntervalRef.current = setInterval(async () => {
            fetchMainIndexValues();
        }, 60 * 1000);
    }

    useEffectOnce(() => {
        fetchMainIndexValues();
        fetchMainIndexValuesInterval();
    });

    useUnmount(() => {
        if (valuesIntervalRef.current) {
            clearInterval(valuesIntervalRef.current);
            valuesIntervalRef.current = undefined;
        }
    });

    let dataDefault = _.find(mainIndexDefault, (m) => m.indexID === indexId);

    if (loading) {
        return <div style={{height: itemHeight, width: '100%'}}><Skeleton /></div>
    }

    if (!dataDefault || !values.length) {
        return <div className="flex justify-center items-center text-gray" style={{height: itemHeight}}>{t('Không có dữ liệu')}</div>
    }

    let refValue = dataDefault.refValue;

    // Min, max value of mainIndex
    const minValue = Math.min(
        ...values.map((mainIndex) => mainIndex.indexValue)
    );
    const maxValue = Math.max(
        ...values.map((mainIndex) => mainIndex.indexValue)
    );
    // End Min, max value of mainIndex

    // Set amplitude of chart
    let ampValue = Math.abs(maxValue - refValue);

    if (ampValue < Math.abs(minValue - refValue)) {
        ampValue = Math.abs(minValue - refValue);
    }

    const YAxisDomainIndexValue = [
        (dataMin: number) => dataMin - ampValue,
        (dataMax: number) => dataMax + ampValue,
    ];

    const YAxisDomainTotalVolume = ['auto', (dataMax: number) => dataMax * 2];
    // End Set amplitude of chart

    // Set color for mainIndex line chart
    const gradientOffset = () => {
        if (!dataDefault) {
            return 0;
        }

        if (maxValue <= refValue) {
            return 0; // 100% red
        } else if (minValue >= refValue) {
            return 1; // 100% green
        } else {
            return 1 - (refValue - minValue) / (maxValue - minValue); // percent of red / Difference of maxValue and minValue
        }
    };
    // End Set color for mainIndex line chart

    const generateTimelineTicks = (startTime) => {
        let tickValuesInit = new Array(7).fill(1);

        tickValuesInit = tickValuesInit.map((tick, index) => {
            if (index === 0) {
                return startTime.valueOf();
            }

            return moment(startTime).add(index, "hours").valueOf();
        });

        return tickValuesInit;
    };

    const startTime = values.length ? moment(values[0].time).startOf('hour') :moment().set({hour: 9}).startOf('hour');
    const ticks = generateTimelineTicks(startTime);

    let changeArrow = <></>;

    if (values.length) {
        if (values.length > 2) {
            let currentIndex = values[values.length - 1];

            let prevIndex = values[values.length - 2];

            if (currentIndex.indexValue > prevIndex.indexValue) {
                changeArrow = (
                    <ArrowUpOutlined className="arrow-up-chart"/>
                );
            } else if (currentIndex.indexValue < prevIndex.indexValue) {
                changeArrow = (
                    <ArrowDownOutlined className="arrow-down-chart"/>
                );
            }
        }
    }

    if (exchangeStatus.status === 'OFFLINE') {
        if (dataDefault.change > 0) {
            changeArrow = (
                <ArrowUpOutlined className="arrow-up-chart"/>
            );
        }
        if (dataDefault.change < 0) {
            changeArrow = (
                <ArrowDownOutlined className="arrow-down-chart"/>
            );
        }
    }

    return (
        <>
            <ComposedChart width={width} height={itemHeight} data={values}>
                {/*Generate color of Line Chart*/}
                <defs>
                    <linearGradient id={`${indexId}_${width}_${itemHeight}`} x1="0" y1="0" x2="0" y2="1">
                        <stop offset={gradientOffset()} stopColor={theme.colors.green.background}/>
                        <stop offset={gradientOffset()} stopColor={theme.colors.red.background}/>
                    </linearGradient>
                </defs>
                {/*End Generate color of Line Chart*/}

                {/*Reference Price Line*/}
                <ReferenceLine
                    yAxisId="indexValue"
                    y={refValue}
                    label={<Label
                        value={roundedNumber(refValue as number, 2)}
                        offset={5}
                        position="top"
                        style={{
                            fill: theme.colors.gray.text[themeMode],
                            fontSize: theme.fontSizes.tiny,
                            fontWeight: 700
                        }}
                    />}
                    stroke={theme.colors.yellow.background}
                    strokeWidth={0.3}
                    strokeDasharray="3 3"
                />
                {/*End Reference Price Line*/}

                {/*Time Axis*/}
                <XAxis
                    type="number"
                    dataKey="time"
                    domain={[ ticks[0], ticks[ticks.length - 1] ]}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.2}
                    tick={{fontSize: 11}}
                    ticks={ticks}
                    tickLine={false}
                    tickFormatter={(tickValue) => `${moment(tickValue).format("HH:mm")}`}
                    padding={{ left: 20, right: 20 }}
                />
                {/*End Time Axis*/}

                {/*Index value Axis*/}
                <YAxis type="number" yAxisId="indexValue" domain={YAxisDomainIndexValue} hide />
                {/*End Index value Axis*/}

                {/*Volume Axis*/}
                <YAxis type="number" yAxisId="totalVolume" domain={YAxisDomainTotalVolume} hide />
                {/*End Volume Axis*/}

                {/*Index Value Line*/}
                <Line
                    connectNulls
                    isAnimationActive={animate}
                    yAxisId="indexValue"
                    // type="basis"
                    dataKey="indexValue"
                    stroke={`url(#${indexId}_${width}_${itemHeight})`}
                    dot={false}
                    strokeWidth={2}
                />
                {/*End Index Value Line*/}

                {/*Volume Bar*/}
                <Bar
                    isAnimationActive={animate}
                    yAxisId="totalVolume"
                    legendType="diamond"
                    dataKey="volume"
                    barSize={2}
                    fill={theme.colors.teal.background}
                />
                {/*End Volume Bar*/}
            </ComposedChart>
            <div className="absolute inset-0 flex justify-center items-center" style={{height: itemHeight}}>
                {changeArrow}
            </div>
        </>
    )
}

const MainIndexChart = (props: MainIndexChartProps) => {
    const { width, height, indexId, indexLabel } = props;
    const [exchangeStatus] = exchangeStatusGlobalState();
    return (
        <>
            <MainIndexLineChart
                indexId={indexId}
                height={height}
                width={width}
                exchangeStatus={exchangeStatus}
            />

            <MainIndexInfo
                indexId={indexId}
                indexLabel={indexLabel}
                height={height}
                width={width}
                exchangeStatus={exchangeStatus}
            />
        </>
    )
};

export default MainIndexChart;