import React, { useRef } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useTranslation } from "react-i18next";
import { FormInstance } from 'antd/es/form';

import api from "../../../../services/api";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const UpdatePassword = () => {
    const formRef = useRef<FormInstance>(null);
    const { t } = useTranslation();

    const onFinishUpdatePassword = async values => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.post('user/change-password', {
            newPassword: values?.newpassword,
            confirmPassword: values?.confirm,
            oldPassword: values?.oldpassword,
        });

        if (resp) {
            notification.success({
                message: t('Thay đổi mật khẩu thành công'),
            })
            formRef.current!.resetFields();
        } else {
            return notification.error({
                message: err.response.data.message,
            })
        }
    };

    return (
        <Form
            ref={formRef}
            {...layout}
            className="my-4 max-w-screen-sm"
            name="updatePassword"
            labelAlign="left"
            size="middle"
            onFinish={onFinishUpdatePassword}
        >
            <Form.Item label={t('Mật khẩu cũ')} name="oldpassword">
                <Input.Password />
            </Form.Item>

            <Form.Item label={t('Mật khẩu mới')} name="newpassword">
                <Input.Password />
            </Form.Item>

            <Form.Item label={t('Xác nhận mật khẩu mới')} name="confirm" dependencies={['newpassword']}>
                <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    {t('Đổi mật khẩu')}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdatePassword;
