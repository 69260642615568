import React, { useState } from "react";
import { List, Space, Divider, Menu } from "antd";
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import * as H from 'history';
import InfiniteScroll from "react-infinite-scroll-component";

import { onNotificationClick } from '../services';
import { NotificationContext } from '../../../../../contexts/NotificationProvider';
import themeModeGlobalState from "../../../../../contexts/ThemeMode";

import DefaultThumbnail from "../../../../../assets/image/default/FStock_thumbnail.png";
import theme from "../../../../../themes/theme";
import Loading from "../../../../../components/Loading";
import { useEffectOnce } from "react-use";

type NotificationListProps = {
    type: string;
    history: H.History;
    notifications: NSApp.Notification[];
    handleInfiniteOnLoad: Function;
    isFullList: boolean;
    isCompact?: boolean;
    setDropdownVisible: Function;
}

const NotificationListItem = (props) => {
    const { history, notification, isCompact, setDropdownVisible } = props;
    const [isUnread, setIsUnread] = useState(true);
    const [themeMode,] = themeModeGlobalState();

    useEffectOnce(() => {
        setIsUnread(!notification.seen);
    });

    const onErrorThumbnail = (e) => {
        e.target.src = DefaultThumbnail;
    };

    const setNotificationGlobalState = useContextSelector(
        NotificationContext,
        (s) => s[1],
    );

    const onPressNotif = async () => {
        await onNotificationClick(history, notification)

        if (isUnread) {
            setNotificationGlobalState((oldState: any) => {
                let unReadState = oldState[notification.type] - 1;
                return {
                    ...oldState,
                    [notification.type]: unReadState,
                };
            });
        }
        setIsUnread(false);
        setDropdownVisible(false);
    };

    const unreadTextClassName = isUnread ? "text-primary" : "text-gray";
    const unreadTitleClassName = isUnread ? `text-${themeMode === 'dark' ? 'white' : 'black'}` : "text-gray";

    if (isCompact) {
        return (
            <Menu.Item
                key={`${notification.id}`}
                className="flex items-center"
                onClick={onPressNotif}
            >
                <div style={{width: 260}}>
                    {notification.type === 'new-notification-info' ?
                        <div className={`dropdown-menu-item-title ${unreadTitleClassName}`}>
                            {notification.title}
                        </div>
                    :
                        <div
                            className={`dropdown-menu-item-title whitespace-normal ${unreadTitleClassName}`}
                            // dangerouslySetInnerHTML={{__html: notification.content}}
                        >
                            <strong>{notification.data.title}</strong>
                        </div>
                    }

                    <span className={unreadTextClassName}>
                        {moment(notification.createdAt).fromNow()}
                    </span>
                </div>

                {isUnread &&
                <div className="text-right" style={{width: 24}}>
                    <span className="text-3xl" style={{color: theme.colors.orange.background}}>•</span>
                </div>
                }
            </Menu.Item>
        )
    }

    return (
        <List.Item onClick={onPressNotif}>
            <List.Item.Meta
                style={{ marginBottom: 0, paddingLeft: 5 }}
                avatar={
                    <img className="rounded"
                        width={160}
                        height={90}
                        src={notification.data.thumbnail || ""}
                        alt={notification.data.title}
                        onError={onErrorThumbnail}
                    />
                }
                title={<span className="text-sm font-bold">{notification.data.title}</span>}
                description={
                    <>
                        <div className="text-two-line">{notification.data.description}</div>
                        <Space>
                            <span className={unreadTextClassName}><CalendarOutlined/> {moment(notification.createdAt).format('L')}</span>
                            <span className={unreadTextClassName}><ClockCircleOutlined/> {moment(notification.createdAt).format('LT')}</span>
                        </Space>
                    </>
                }
            />
            <div className="w-8 text-center">
                {isUnread && (
                    <span className="text-5xl" style={{color: theme.colors.orange.background}}>•</span>
                )}
            </div>
        </List.Item>
    );
};

const NotificationList = (props: NotificationListProps) => {
    const { type, history, notifications, handleInfiniteOnLoad, isFullList, isCompact, setDropdownVisible } = props;

    const { t } = useTranslation();

    const loadMoreData = () => {
        handleInfiniteOnLoad();
    };

    return (
        <div
            id={`scrollableDiv-${type}${isCompact ? '_isCompact' : ''}`}
            style={{ maxHeight: 600, overflow: 'auto' }}
        >
            <InfiniteScroll
                dataLength={notifications.length}
                next={loadMoreData}
                hasMore={!isFullList}
                loader={<Loading/>}
                endMessage={<Divider plain>{t('Không còn thông báo cũ')}</Divider>}
                scrollableTarget={`scrollableDiv-${type}${isCompact ? '_isCompact' : ''}`}
            >
                <List
                    className="hovered-list"
                    rowKey={(n) => `notif-${n.id}`}
                    dataSource={notifications}
                    renderItem={(notification: NSApp.Notification) => {
                        return <NotificationListItem
                            key={notification.id}
                            history={history}
                            notification={notification}
                            isCompact={isCompact}
                            setDropdownVisible={setDropdownVisible}
                        />;
                    }}
                />
            </InfiniteScroll>
        </div>
    )
};

export default NotificationList;
