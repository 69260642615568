import React from 'react';
import { Switch, Route } from "react-router-dom";

import QueueJobsList from "./List";

import routes from "../../../../routes";

const QueueJobsManager = () => {
    return <div>
        <Switch>
            <Route path={routes.ADMIN_QUEUE_JOBS_LIST} component={QueueJobsList} />
        </Switch>
    </div>
}

export default QueueJobsManager;