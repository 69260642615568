import React, { useState, useEffect }  from "react";
import moment from "moment";
import { Table, Tooltip, Button, Space, Input, Select, Tag, message, Popconfirm, Typography } from "antd";

import api from "../../../../services/api";
import * as config from "../../../../config";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import { toRoute, useReducedState } from "../../../../services/utils";
import AdminPermission from "../../../../components/AdminPermission";
import { Link } from "react-router-dom";
import routes from "../../../../routes";

const { Option } = Select;
const { Title } = Typography;

export const USER_AFFILIATE_STATUS = {
    NOT_SUBMITED: 'not-submited',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    PROCESSING: 'processing'
}

const AffiliateListPageComponent = () => {
    const [ keySearchFilter, setKeySearchFilter ] = useState<string>('');
    const [ statusFilter, setStatusFilter ] = useState<string>('');
    const [ listAffiliate, setListAffiliate ] = useState([]);
    const [ activedCount, setActivedCount ] = useState(0);

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchAffiliates = async () => {
        // eslint-disable-next-line
        let filter = {};
        if (keySearchFilter) {
            filter['keySearch'] = keySearchFilter;
        }

        if (statusFilter) {
            filter['status'] = statusFilter;
        }

        let [err, resp]: any = await api.get('/affiliate/users', {
            ...filter,
            page: pagination.current, limit: config.PAGE_SIZE
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { userAffiliates, pagination: _pagination } = resp?.data;

        setListAffiliate(userAffiliates);
        setActivedCount(_pagination.activedCount);
        setPagination({ total: _pagination.total });
    };

    const approveAffiliateSubmission = async (id: any) => {
        let [err, resp]: any = await api.post('/affiliate/approve', {
            id
        });

        if (err) {
            message.error(err);
            return;
        }

        message.success('Phê duyệt thành công!');
        fetchAffiliates();
    }

    const rejectAffiliateSubmission = async (id: any) => {
        let [err, resp]: any = await api.post('/affiliate/reject', {
            id
        });

        if (err) {
            message.error(err);
            return;
        }

        message.success('Từ chối thành công!');
        fetchAffiliates();
    }
    const btnSearchOnClick = () => {
        fetchAffiliates();
    };

    useEffect(() => {
        fetchAffiliates();
    }, [pagination.current]); // eslint-disable-line


    const columns: any = [
        {
            title: 'Tên',
            textWrap: 'word-break',
            ellipsis: true,
            align: 'center',
            render: (affiliate) =><Link to={toRoute(routes.ADMIN_MANAGE_AFFILIATE_USER_DETAILS, { userId: affiliate.userId })}>
                <Tooltip placement="top" title={affiliate.name}>
                    <span>{affiliate.name}</span>
                </Tooltip>
            </Link>
        },
        {
            title: "Email",
            dataIndex: 'email',
            align: 'center',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: "SDT",
            dataIndex: 'phone',
            align: 'center',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: "Ngày đăng ký",
            align: 'center',
            dataIndex: 'updatedAt',
            render: (updatedAt) => moment(updatedAt).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: "Hoa hồng khả dụng",
            align: 'center',
            dataIndex: 'balance',
            render: (balance) => balance.toLocaleString(),
        },
        // {
        //     title: "Ngày xuất bản",
        //     dataIndex: 'createdAt',
        //     render: (createdAt) => <span>{moment(createdAt).format('L')}</span>
        // },
        {
            title: "Trạng thái",
            dataIndex: 'status',
            render: (status) => <span>
                {status === USER_AFFILIATE_STATUS.NOT_SUBMITED &&
                    <Tag color="gray">Chưa đăng ký</Tag>
                }

                {status === USER_AFFILIATE_STATUS.APPROVED &&
                    <Tag color="green">Đã duyệt</Tag>
                }

                {status === USER_AFFILIATE_STATUS.PROCESSING &&
                    <Tag color="gold">Đang xử lý</Tag>
                }

                {status === USER_AFFILIATE_STATUS.REJECTED &&
                    <Tag color="red">Đã từ chối</Tag>
                }
            </span>
        },
        {
            title: 'Action',
            render: (affiliate) => {
                return <Space>
                    {affiliate.status === USER_AFFILIATE_STATUS.PROCESSING &&
                        <Popconfirm
                            title={`Bạn có chắc chắn muốn phê duyệt cho tài khoản ${affiliate.email}?`}
                            onConfirm={() => approveAffiliateSubmission(affiliate.id)}
                            okText="Có"
                            cancelText="Không"
                        >
                            <Button type="primary">Phê duyệt</Button>
                        </Popconfirm>
                    }

                    {(affiliate.status === USER_AFFILIATE_STATUS.PROCESSING || affiliate.status === USER_AFFILIATE_STATUS.APPROVED) &&
                        <Popconfirm
                            title={`Bạn có chắc chắn muốn từ chối tài khoản ${affiliate.email}?`}
                            onConfirm={() => rejectAffiliateSubmission(affiliate.id)}
                            okText="Có"
                            cancelText="Không"
                        >
                            <Button type="primary" danger>Từ chối</Button>
                        </Popconfirm>
                    }

                </Space>
            }
        }
    ];

    return (
        <>
            <MyBreadcrumd items={[{ label: 'Cộng tác viên' }]} />
            <div className="mt-3">
                <Space>
                    <Input placeholder="Tên, email" onChange={(e: any) => setKeySearchFilter(e.target.value)} />
                    <Select defaultValue="" onChange={(val: any) => setStatusFilter(val)} style={{ width: 150 }}>
                        <Option value=''>Chọn trạng thái</Option>
                        <Option value={USER_AFFILIATE_STATUS.NOT_SUBMITED}>Chưa đăng ký</Option>
                        <Option value={USER_AFFILIATE_STATUS.APPROVED}>Đã phê duyệt</Option>
                        <Option value={USER_AFFILIATE_STATUS.PROCESSING}>Đang xử lý</Option>
                        <Option value={USER_AFFILIATE_STATUS.REJECTED}>Từ chối</Option>
                    </Select>

                    <Button type="primary" onClick={btnSearchOnClick}>Tìm kiếm</Button>
                </Space>
            </div>

            <Title level={4} >Tổng số cộng tác viên đang hoạt động: {activedCount}</Title>
            <div className="mt-2">
                <Table
                    columns={columns}
                    dataSource={listAffiliate}
                    rowKey={(r: any) => r?.id}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                />
            </div>
        </>
    )
}

const AffiliateListPage = () => {
    return <AdminPermission roleName={['validator']}>
        <AffiliateListPageComponent />
    </AdminPermission>
}

export default AffiliateListPage;