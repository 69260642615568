import React, { useState } from "react";
import { Button, Card, Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { RESPONSIVE_SCREEN } from "../../../config";
import api from "../../../services/api";
import SelectPeriod from "./SelectPeriod";
import routes from "../../../routes";
import Loading from "../../../components/Loading";
import colors from "../../../themes/colors";

type FeatureProps = {
    name: string;
    id?: number;
    key?: string;
    isTitle?: boolean;
    code?: string;
};

const PlanInfo = () => {
    const [featureList, setFeatureList]: any = useState();
    const [columnPlan, setColumnPlan] = useState();
    const { t } = useTranslation();

    const fetchPlans = async () => {
        const [err, resp]: any = await api.get('/plan');

        if (err) {
            return;
        }

        let columns: any = [];

        for (let plan of resp.data) {
            columns.push({
                title: <span className="text-base font-bold uppercase">{plan.name}</span>,
                key: `${plan.id}`,
                dataIndex: 'plan_features',
                align: 'center',
                width: 160,
                render: (planIds, record) => {
                    if (record.isTitle) {
                        return {
                            props: {
                                colSpan: 0,
                            },
                        }
                    }
                    if (record.key === 'select_period') {
                        if (plan.isFree) {
                            return (
                                <div>
                                    <div style={{height: 28}} />
                                    <div className="text-2xl font-bold my-3">{t('Miễn phí')}</div>
                                    <Link to={routes.LOGIN}>
                                        <Button ghost type="primary" className="w-5/6 h-10 px-4 py-2 text-primary text-sm font-bold">{t('DÙNG NGAY')}</Button>
                                    </Link>
                                </div>
                            )
                        }

                        return <SelectPeriod planId={plan.id} />
                    }

                    planIds = _.map(planIds,_.ary((p) => p.planId));
                    let isPlan = planIds.includes(plan.id);

                    return isPlan
                        ? <CheckOutlined className="text-xl" style={{color: colors.green.background}} />
                        : <CloseOutlined className="text-xl" style={{color: colors.red.text.light}} />;
                }
            });
        }

        setColumnPlan(columns);
    };

    const fetchFeatures = async () => {
        const [err, resp]: any = await api.get('/plan/features');
        if (err) {
            return;
        }

        if (resp) {
            let _featureList: FeatureProps[] = [];
            resp.data.map(group => {
                const titleObj = {
                    name: group.title,
                    isTitle: true,
                };
                _featureList.push(titleObj);
                const sortedList = _.orderBy(group.features, f => f?.plan_features?.length, 'desc');
                sortedList.map(feature => _featureList.push(feature));
                return group;
            });

            let selectRow = { key: 'select_period', name: t('Mua dịch vụ') };
            let platform = [
                { name: 'Các nền tảng', isTitle: true },
                { name: 'Web Platform', plan_features: [ { planId: 1 }, { planId: 2 }, { planId: 3 } ] },
                { name: 'Mobile App', plan_features: [ { planId: 1 }, { planId: 2 }, { planId: 3 } ] }
            ];
            let fullFeatureList = [selectRow, ...platform, ..._featureList];

            setFeatureList(fullFeatureList);
        }
    };

    useEffectOnce(() => {
        fetchFeatures();
        fetchPlans();
    });

    if (!columnPlan) {
        return <Loading />;
    }

    const columns: any = [
        {
            title: <span className="text-base font-bold uppercase">{t('Tính năng')}</span>,
            key: 'feature',
            dataIndex: 'name',
            // width: '60%',
            render: (value, record) => {
                if (record.isTitle) {
                    return {
                        children: <span className="text-lg font-bold">{value}</span>,
                        props: {
                            colSpan: 4,
                            style: { backgroundColor: '#FDEBD8', borderRadius: 7 },
                        },
                    }
                }
                if (record.key === 'select_period') {
                    return <div/>;
                }
                return <span className="text-sm whitespace-normal">{value}</span>
            }
        },
        ...columnPlan,
    ];

    return (
        <div className="max-w-screen-xl mx-auto p-1">
            <Card className="text-center" bordered={false}>
                <div className="text-3xl text-black font-semibold mb-4">{t('Chọn gói dịch vụ')}</div>
                <Table
                    rowKey={(r) => r.name}
                    size="middle"
                    pagination={false}
                    columns={columns}
                    dataSource={featureList}
                    scroll={{ x: RESPONSIVE_SCREEN.md }}
                />
            </Card>
        </div>
    );
};

export default PlanInfo;
