import React, { useState, useEffect, useRef } from "react";
import { Drawer, message } from "antd";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";

import api from "../.././../../services/api";
import routes from "../../../../routes";
import PluginsList from "./components/PluginsList";
// import { GoldenLayoutComponent } from "../../../../components/GoldenLayout/GoldenLayoutComponent";
import GoldenLayoutWrapper from "../../../../components/GoldenLayout/GoldenLayoutWrapper";
import { useDashboardState, useLayoutState } from '../../../../contexts';
import { DEFAULT_LAYOUT_CONFIG } from '../../../../config';
import { fetchDashboardPlugins } from "../../../../services/dashboard";
import Loading from "../../../../components/Loading";

const DashboardContent = () => {
    const { dashboardState, setDashboardState } = useDashboardState() as any;
    const { layoutState, setLayoutState } = useLayoutState() as any;
    const [ dashboardPlugins, setDashboardPlugins ] = useState<any>();
    const { layoutConfig, currentDashboard } = dashboardState;
    const { siderCollapsed, pluginDrawerVisible } = layoutState;
    const { t } = useTranslation();

    const MARGIN_HEIGHT = 60;
    const MARGIN_WIDTH = siderCollapsed ? 58 : 210; // = SIDER_WIDTH + 10px padding

    let history = useHistory();
    let { dashboardId } = useParams<{dashboardId: string}>();
    let dashboardLayout: any = useRef(null);

    const [ dimensions, setDimensions ] = React.useState({
        height: $(window).height(),
        width: $(window).width()
    });

    const fetchConfig = async () => {
        let [err, resp]: any = await api.get(
            `plugins/dashboard/${dashboardId}`
        );

        if (err) {
            history.replace(routes.DASHBOARD);
            setDashboardState({ layoutConfig: DEFAULT_LAYOUT_CONFIG });
            return;
        }

        // setDashboard(resp?.data);
        setDashboardState({
            currentDashboard: resp?.data,
            layoutConfig: resp?.data?.layoutConfig || DEFAULT_LAYOUT_CONFIG
        });
    };

    const saveLayout = async (layoutConfig) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.patch(
            `plugins/dashboard/${currentDashboard?.id}`, { layoutConfig }
        );

        if (err) {
            message.error({
                content: t('Có lỗi xảy ra'),
                key: "save-layout-error",
            });
            return;
        }

        // message.success({
        //     content: "Cập nhật tổng quan thành công",
        //     key: "save-layout-success",
        // });
    };

    useEffect(() => {
        async function fetchData() {
            // reset golden layout config and load new config
            setDashboardState({ layoutConfig: null });
            await fetchConfig();
        }

        function handleResize(e) {
            e.preventDefault();

            if (!window.screenTop && !window.screenY) {
                return;
            }

            dashboardLayout.current.updateSize($(window).width() - MARGIN_WIDTH, $(window).height() - MARGIN_HEIGHT);

            setDimensions({
                height: $(window).height(),
                width: $(window).width()
            })
        }

        window.addEventListener('resize', handleResize)

        setDashboardState({ selectedDashboard: dashboardId })
        fetchData();
    }, [dashboardId, MARGIN_WIDTH]); // eslint-disable-line

    const initDashboardPlugins = async () => {
        let plugins = await fetchDashboardPlugins();
        setDashboardPlugins(plugins);
    }
    useEffect(() => {
        initDashboardPlugins();
    }, [])

    if (!dashboardPlugins) return <Loading/>

    return (
        <div className="site-drawer-render-in-current-wrapper">
            {layoutConfig && (
                <GoldenLayoutWrapper
                    htmlAttrs={{
                        style: {
                            height: dimensions.height - MARGIN_HEIGHT,
                            width: dimensions.width - MARGIN_WIDTH,
                        },
                    }}
                    config={layoutConfig}
                    registerComponents={(layout) => {
                        // assign ref of golden layout
                        dashboardLayout.current = layout;

                        // eslint-disable-next-line
                        dashboardPlugins.map((group) => {
                            group?.plugins.map((plugin) => {
                                try{
                                    layout.registerComponent(
                                        plugin?.key,
                                        plugin?.component
                                    );
                                } catch (err) {
                                    // do nothing
                                }

                                let element = $(`#${plugin?.key}`);

                                let pluginConfig = {
                                    type: "react-component",
                                    title: t(`${plugin?.title}`),
                                    componentName: t(`${plugin?.title}`),
                                    component: plugin?.key,
                                };
                                layout.createDragSource(element, pluginConfig);
                            });
                        });

                        layout.on("stateChanged", () => {
                            try {
                                if (layout.isInitialised) {
                                    let layoutConfig = layout.toConfig();
                                    saveLayout(layoutConfig);
                                    setDashboardState({ layoutConfig });
                                }
                            } catch (err) {
                                setDashboardState({ layoutConfig });
                                saveLayout(layoutConfig);
                            }
                        });

                        layout.on("componentCreated", (item) => {
                            if (!item.config.id || item.config.id.length <= 0) {
                                item.addId(uuidv4());
                                setLayoutState({ pluginDrawerVisible: false });
                            }
                        });
                    }}
                />
            )}

            <Drawer
                className="absolute"
                title={t('Danh sách chức năng')}
                visible={pluginDrawerVisible}
                getContainer={false}
                mask={false}
                onClose={() => setLayoutState({ pluginDrawerVisible: false })}
                bodyStyle={{ padding: 1 }}
            >
                <PluginsList plugins={dashboardPlugins}/>
            </Drawer>
        </div>
    );
};

export default DashboardContent;
