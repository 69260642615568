import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList } from "recharts";
import { useTranslation } from "react-i18next";

import theme from "../../../../../../../themes/theme";
import animateGlobalState from '../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import { compactNumberFormat } from "../../../../../../../services/utils";

const IndustryStatisticsChart = (props) => {
    const { width, height, data, dataKey, unit } = props;
    const { t } = useTranslation();

    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();

    const formatTickAxis = (value: number) => {
        if (unit === '%') {
            return `${Math.round(value * 100)}`;
        }

        if (unit === t('Lần')) {
            return Math.round(value);
        }

        return compactNumberFormat(value, true);
    };

    const labelColor = themeMode === 'dark' ? theme.colors.white : theme.colors.black;

    return (
        <BarChart
            layout="vertical"
            width={width}
            height={height-30}
            data={data}
        >
            <CartesianGrid
                horizontal={false}
                stroke={theme.colors.chartAxis[themeMode]}
                strokeWidth={0.1}
            />

            <XAxis
                type="number"
                tick={{fontSize: 10}}
                tickLine={false}
                tickFormatter={(tickValue) => formatTickAxis(tickValue)}
                stroke={theme.colors.chartAxis[themeMode]}
                strokeWidth={0.5}
                padding={{right: 50}}
                height={40}
                label={{ value: `(${unit})`, position: 'insideBottom', fill: labelColor }}
            />

            <YAxis
                type="category"
                dataKey="shortName"
                tick={{fontSize: 10}}
                stroke={theme.colors.chartAxis[themeMode]}
                strokeWidth={0.5}
                interval={0}
                width={120}
            />

            <Bar dataKey={dataKey} isAnimationActive={animate} fill={theme.colors.primary} radius={theme.chart.bar.radius.horizontal}>
                <LabelList
                    dataKey={dataKey}
                    position="right"
                    content={(label) => compactNumberFormat(unit === '%' ? label.value * 100 : label.value)}
                    style={{fontSize: 10, fill: labelColor, fontWeight: 'bold'}}
                />
            </Bar>
        </BarChart>
    )
};

export default IndustryStatisticsChart;