import api from '../../../../../services/api';
import * as config from "../../../../../config";
import * as H from 'history';
import routes from '../../../../../routes';
import { toRoute } from '../../../../../services/utils';

export const fetchNotifications = async (platform: string, type: string, page=1, pageSize: number): Promise<
    { notifications: NSApp.Notification[], total: number, totalUnread: number } | undefined> => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get('notification', { platform, type, page, pageSize });

    if (err) return;
    let { notifications, total, totalUnread } = resp.data;
    return { notifications, total, totalUnread };
};

export const onNotificationClick = async (history: H.History, notification: NSApp.Notification) => {
    if (notification.type === config.NEW_PENDING_RECOMMENDATION_NOTIFICATION_TYPE) {
        history.push(toRoute(routes.ADMIN_RECOMMENDATION_DETAIL, { recommendationId: notification.data.id }));
    }

    if (notification.type === config.DENY_RECOMMENDATION_NOTIFICATION_TYPE) {
        history.push(toRoute(routes.ADMIN_RECOMMENDATION_EDIT, { recommendationId: notification.data.id }));
    }
    if (notification.type === config.NEW_RECOMMENDATION_NOTIFICATION_TYPE) {
        // go to recommendation detail page
        history.push(toRoute(routes.RECOMMENDATION_DETAIL, { recommendationId: notification.data.id }));
    }
    if (notification.type === config.NEW_ARTICLE_NOTIFICATION_TYPE) {
        // go to blog detail page
        history.push(toRoute(routes.ARTICLE_DETAIL, { articleId: notification.data.id }));
    }

    if (notification.type === config.NEW_NOTIFICATION_INFO) {
        // go to blog detail page
        history.push(toRoute(routes.NOTIFICATION_INFO_DETAIL, { notificationId: notification.id }));
    }

    if (!notification.seen) {
        const [err, resp]: any = await api.patch(
            `notification/mark-notification-as-read/${notification.id}`, {});

        if (err) {
            return;
        }

        return resp.data.seen;
    }
}

export const markAllNotificationAsRead = async () => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.patch(`notification/mark-all-notification-as-read/`, {});

    if (err) {
        return
    }

    return resp;
}

export const fetchTotalUnreadNotifications = async () => {
    let [err, resp]: any = await api.get('notification/total-unread', {
        platform: 'app',
    });

    if (err) {
        return
    }

    return resp;
};