import React from 'react';
import DerivativesAnalysisWrapper from "../../../DerivativesAnalysis/wrapper";

const DerivativesAnalysis = (props) => {
    const { stockSymbol, layoutWidth, layoutHeight } = props;

    return <DerivativesAnalysisWrapper
        stockSymbol={stockSymbol}
        layoutWidth={layoutWidth}
        layoutHeight={layoutHeight}
    />
}

export default DerivativesAnalysis;