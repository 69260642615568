import React from "react";
import { Divider, Layout } from "antd";
import { ReadOutlined, VideoCameraOutlined, TeamOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import GlobalSignLogo from '../assets/image/logo/logo_global-sign.svg';
import PCIDSSLogo from '../assets/image/logo/logo_pcidss.svg';
import VnPayLogo from '../assets/image/logo/logo_vnpay.svg';

const UpgradeFooter = () => {
    const { t } = useTranslation();

    return (
        <Layout.Footer className="p-0">
            <div className="max-w-screen-xl mx-auto sm:px-1">
                <div className="bg-white">
                    <div className="flex justify-between px-6 pt-4">
                        <div className="flex">
                            <img src={GlobalSignLogo} className="h-8 mr-2" alt="Global_Sign_Logo" />
                            <img src={PCIDSSLogo} className="h-8 mr-2" alt="PCIDSS_Logo" />
                            <div>
                                <div className="font-bold">{t('Giao dịch an toàn 100%')}</div>
                                <div className="text-gray-600">{t('Mã hoá SSL')}</div>
                            </div>
                        </div>
                        <img src={VnPayLogo} className="h-8" alt="VnPay_Logo" />
                    </div>
                    <Divider className="my-2" />
                    <div className="px-6 py-1">
                        <div className="text-xl text-center font-bold mb-4">{t('Quyền lợi khi mua dịch vụ của Fstock')}</div>
                        <div className="flex flex-col sm:flex-row justify-between mb-6">
                            <div className="card mb-2 sm:mb-0">
                                <div className="icon"><ReadOutlined /></div>
                                <div className="description">{t('Tham gia các khoá huấn luyện đầu tư')}</div>
                            </div>
                            <div className="card mb-2 sm:mb-0">
                                <div className="icon"><VideoCameraOutlined /></div>
                                <div className="description">{t('Tham gia các buổi livestream chia sẻ kinh nghiệm của chuyên gia')}</div>
                            </div>
                            <div className="card mb-2 sm:mb-0">
                                <div className="icon"><TeamOutlined /></div>
                                <div className="description">{t('Giao lưu cùng cộng đồng Fstock và các chuyên gia chứng khoán hàng đầu')}</div>
                            </div>
                        </div>
                        <div className="text-center">
                            <strong>FSTOCK</strong> ©2021 {t('Created by')} <strong>FCAPITAL</strong>
                        </div>
                    </div>
                </div>
            </div>
        </Layout.Footer>
    )
};

export default UpgradeFooter;
