import React, { useState, useEffect } from 'react';
import { useReducedState } from '../../../../../../../services/utils';
import * as config from '../../../../../../../config';
import NewsListComponent from '../components/list';
import * as NewsServices from '../services';

type NewListContainerProps = {
    category?: NSApp.NewsCategory,
    stockCodes?: string[],
    enableWatchListFilter?: boolean,
    hasTitle?: boolean,
    hasLatestNews?: boolean,
    isLatestTab?: boolean,
    isExternal?: boolean,
    height: number,
    width?: number,
    horizontal?: boolean,
    listNoImage?: boolean,
    column?: number,
    pageSize?: number
};

const NewListContainer = (props: NewListContainerProps) => {
    const { category, stockCodes, enableWatchListFilter, hasTitle, hasLatestNews, isLatestTab, height, width, horizontal, listNoImage, column, pageSize, isExternal } = props;

    const [ newsList, setNewsList ] = useState<NSApp.New[]>([]);
    const [ latestNews, setLatestNews ] = useState<NSApp.New[]>([]);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ queryFilter, setQueryFilter ] = useState<any>({});
    const [ loading, setLoading ] = useState(true);
    const [ pagination, setPagination ] = useReducedState({
        total: 0,
        current: 1,
        pageSize: config.PAGE_SIZE
    });

    const fetchNews = async (filter = {}) => {
        if (category) {
            filter = {
                ...queryFilter,
                ...filter,
                categoryId: category.id
            };
        }

        if (isExternal) {
            filter = {
                ...queryFilter,
                ...filter,
                isExternal: true
            };
        }

        if (stockCodes && stockCodes.length > 0) {
            let stockCodesFilter = stockCodes.map(stockCode => {
                return stockCode.toLowerCase();
            });

            filter = {
                ...queryFilter,
                ...filter,
                tags: stockCodesFilter
            };
        }

        // eslint-disable-next-line
        let [err, resp] = await NewsServices.fetchNewsData(filter, pagination.current, pageSize ? pageSize : config.PAGE_SIZE);

        if (err) {
            setErrorMessage(`
                Có lỗi xảy ra khi tải tin tức hoặc tài khoản của bạn chưa được kích hoạt plugin này.
                Vui lòng liên hệ admin để biết thêm chi tiết.
            `);
            return;
        }

        if (resp) {
            let newsResp = resp?.news;
            let total = resp?.total;

            let amountOfLatestNews = isLatestTab ? 4 : 7;
            let newsList = newsResp.slice();
            let latestNews = newsList.splice(0, amountOfLatestNews);

            setPagination({ total });
            setLatestNews(latestNews);
            setLoading(false);

            if (hasLatestNews) {
                setNewsList(newsList);
            } else {
                setNewsList(newsResp);
            }
        }
    };

    const onChangeQueryFilter = (filter) => {
        let newFilter = {};

        if (category) {
            newFilter = {
                ...filter,
                category: category.id
            };
        }

        if (stockCodes) {
            let stockCodesFilter = stockCodes.map(stockCode => {
                return stockCode.toLowerCase();
            });

            newFilter = {
                ...filter,
                tags: stockCodesFilter
            }
        }

        setQueryFilter(newFilter);
    }

    useEffect(() => {
        fetchNews(queryFilter);
    }, [queryFilter, stockCodes, pagination.current]); // eslint-disable-line


    return (
        <NewsListComponent
            isExternal={isExternal}
            category={category}
            newsList={newsList}
            latestNews={latestNews}
            pagination={pagination}
            errorMessage={errorMessage}
            enableWatchListFilter={enableWatchListFilter}
            changePagination={setPagination}
            onChangeQueryFilter={onChangeQueryFilter}
            hasTitle={hasTitle}
            hasLatestNews={hasLatestNews}
            height={height}
            width={width}
            horizontal={horizontal}
            listNoImage={listNoImage}
            column={column}
            loading={loading}
        />
    )
}

export default NewListContainer;
