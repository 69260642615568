import React, { useState, useEffect }  from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Image, Tooltip, Button, Space, Input, Select, Tag, message } from "antd";

import api from "../../../../services/api";
import * as config from "../../../../config";
import routes from "../../../../routes";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import { useReducedState, toRoute } from "../../../../services/utils";
import { RECOMMENDATION_ACCEPTED, RECOMMENDATION_DENIED, RECOMMENDATION_PENDING } from "../../../../types/plugins/recommendation";
import ChangeStatusButton from './_ChangeStatusButton';
import AdminPermission from "../../../../components/AdminPermission";

const { Option } = Select;

const RecommendationListPageComponent = () => {
    const [ recommendationKeySearchFilter, setRecommendationKeySearchFilter ] = useState<string>('');
    const [ recommendationStatusFilter, setRecommendationStatusFilter ] = useState<string>('');
    const [ listRecommendation, setListRecommendation ] = useState([]);

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchRecommendations = async (filter = {}) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/recommendation', {
            filter, page: pagination.current, pageSize: config.PAGE_SIZE
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { recommendations, total } = resp?.data;

        setListRecommendation(recommendations);
        setPagination({ total });
    };

    const btnSearchOnClick = () => {
        let filter = {};
        if (recommendationKeySearchFilter) {
            filter['title'] = { $regex: `.*${recommendationKeySearchFilter}.*` };
        }

        if ([RECOMMENDATION_ACCEPTED, RECOMMENDATION_DENIED, RECOMMENDATION_PENDING].includes(recommendationStatusFilter)) {
            filter['status'] = recommendationStatusFilter;
        }

        fetchRecommendations(filter);
    };

    useEffect(() => {
        fetchRecommendations();
    }, [pagination.current]); // eslint-disable-line


    const columns: any = [
        {
            title: 'Tiêu đề',
            width: 300,
            textWrap: 'word-break',
            ellipsis: true,
            render: (recommendation) => <Link to={toRoute(routes.ADMIN_RECOMMENDATION_DETAIL, { recommendationId: recommendation.id })}>
                <Tooltip placement="top" title={recommendation.title}>
                    <span>{recommendation.title}</span>
                </Tooltip>
            </Link>
        },
        {
            title: "Danh mục",
            dataIndex: 'category',
            textWrap: 'word-break',
            ellipsis: true,
            render: (category) => <Tooltip placement="top" title={category?.name}>
                <a href={category?.link}>{category?.name}</a>
            </Tooltip>
        },
        {
            title: "Ảnh",
            dataIndex: 'thumbnail',
            render: (thumbnail) => <Image width={100} src={thumbnail}/>
        },
        {
            title: "Ngày xuất bản",
            dataIndex: 'createdAt',
            render: (createdAt) => <span>{moment(createdAt).format('L')}</span>
        },
        {
            title: "Trạng thái",
            dataIndex: 'status',
            render: (status) => <span>
                {status === RECOMMENDATION_PENDING &&
                    <Tag color="gold">Chờ duyệt</Tag>
                }

                {status === RECOMMENDATION_ACCEPTED &&
                    <Tag color="green">Đã duyệt</Tag>
                }

                {status === RECOMMENDATION_DENIED &&
                    <Tag color="red">Đã từ chối</Tag>
                }
            </span>
        },
        {
            title: 'Action',
            render: (recommendation) => {
                return <Space>
                    <Link to={toRoute(routes.ADMIN_RECOMMENDATION_EDIT, { recommendationId: recommendation.id })}>
                        <Button type="primary">Sửa</Button>
                    </Link>
                    <ChangeStatusButton recommendation={recommendation} onChange={fetchRecommendations}/>
                </Space>
            }
        }
    ];

    return (
        <>
            <MyBreadcrumd items={[{ label: 'Khuyến nghị' }]} />

            <div className="mt-3">
                <Space>
                    <Input placeholder="Tên bài viết" onChange={(e: any) => setRecommendationKeySearchFilter(e.target.value)} />
                    <Select defaultValue="select-status" onChange={(val: any) => setRecommendationStatusFilter(val)} style={{ width: 150 }}>
                        <Option value="select-status">Chọn trạng thái</Option>
                        <Option value={RECOMMENDATION_PENDING}>Chờ phê duyệt</Option>
                        <Option value={RECOMMENDATION_ACCEPTED}>Đã phê duyệt</Option>
                        <Option value={RECOMMENDATION_DENIED}>Bị từ chối</Option>
                    </Select>

                    <Button type="primary" onClick={btnSearchOnClick}>Tìm kiếm</Button>

                    <Button type="primary">
                        <Link to={routes.ADMIN_RECOMMENDATION_ADD}>Thêm mới khuyến nghị</Link>
                    </Button>
                </Space>
            </div>
            <div className="mt-2">
                <Table
                    columns={columns}
                    dataSource={listRecommendation}
                    rowKey={(r: NSApp.Recommendation) => r.id}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                />
            </div>
        </>
    )
}

const RecommendationListPage = () => {
    return <AdminPermission roleName={['editor', 'moderator']}>
        <RecommendationListPageComponent />
    </AdminPermission>
}

export default RecommendationListPage;