import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { useTranslation } from "react-i18next";

import WithDrawHistory from './WithDrawHistory';
import InvitedAccount from './InvitedAccount';
import fontSizes from "../../../../../themes/fontsizes";
import colors from "../../../../../themes/colors";

const { Title, Text } = Typography;

type AffiliateDashboardProps = {
    affiliate: NSApp.UserAffiliate
};

const Affiliate = (props: AffiliateDashboardProps) => {
    const { t } = useTranslation();

    const { affiliate } = props;

    return (
        <div>
            <div>
                <Title className="text-center">{t('Hoa hồng')}</Title>
            </div>
            <Card>
                <Row className="text-center">
                    <Col span={4}>
                        <Text strong>{t('Hoa hồng khả dụng')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large, color: colors.primary }}>{affiliate.balance.toLocaleString()}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong>{t('Hoa hồng chờ rút')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.commissionWaitingForWithdrawal.toLocaleString()}</Text>
                    </Col>
                    <Col span={8}>
                        <Text strong>{t('Tổng doanh thu khách hàng đã thanh toán (sau thuế VAT)')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.totalRevenue.toLocaleString()}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong>{t('Tổng hoa hồng sau thuế TNCN')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.commissionReceived.toLocaleString()}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong>{t('Tổng hoa hồng đã thanh toán')}</Text>
                        <br/>
                        <Text strong style={{ fontSize: fontSizes.large }}>{affiliate.totalCommissionPaid.toLocaleString()}</Text>
                    </Col>
                </Row>
            </Card>

            <Card className="mt-5">
                <WithDrawHistory affiliate={affiliate} />
            </Card>

            <Card className="mt-5">
                <InvitedAccount />
            </Card>
        </div>
    )
};

export default Affiliate;
