import React, { useState } from "react";
import { useEffectOnce } from 'react-use';
import { Layout, Row, Col, Card, message as Notif, Avatar, Space, Alert } from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NormalFooter from "../../components/NormalFooter";
import api from "../../services/api";
import Loading from "../../components/Loading";
import themeModeGlobalState from "../../contexts/ThemeMode";
import LogoWhite from "../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../assets/image/logo/Fstock_black.svg";

const DeleteAccount = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState<{content: string, type: "success" | "info" | "error" | "warning" | undefined}>({
        content: "",
        type: "success",
    });

    const { token }: any = useParams();
    const [themeMode,] = themeModeGlobalState();
    const logo = themeMode === 'dark' ? LogoWhite : LogoBlack;

    const doDeleteAccount = async () => {
        if (!token) {
            Notif.error({
                key: "lock-account-error",
                content: t('Yêu cầu không hợp lệ, quý khách vui lòng thử lại!'),
            });
            return;
        }

        let [err, ]: any = await api.post("user/delete-account", {
            token
        });

        setIsLoading(false);
        if (err) {

            const errBody = err?.response?.data;

            if (errBody) {
                if (errBody.name === "TokenExpiredError") {
                    setMessage({
                        type: "error",
                        content: t('Yêu cầu đã hết hạn, quý khách vui lòng thử lại!'),
                    });
                    return;
                }
            }

            setMessage({
                type: "error",
                content: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
            });

            Notif.error({
                key: "delete-account-error",
                content: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
            });
            return;
        }

        Notif.success({
            key: "delete-account-success",
            content: t('Xóa tài khoản thành công!'),
        });

        setMessage({
            type: "success",
            content: t('Xóa tài khoản thành công!'),
        })
    };

    useEffectOnce(() => {
        doDeleteAccount();
    });

    return (
        <Layout className="auth-page">
            <Row justify="center" align="middle" className="h-full">
                <Col xs={22} sm={18} md={12} lg={10} xl={5}>
                    <Space className="brand flex justify-center mb-4" align="center">
                        <Avatar shape="square" size={128} src={logo} />
                    </Space>
                    <Card title={t('Xác nhận xóa tài khoản')} headStyle={{ textAlign: "center" }}>
                        {isLoading ?
                            <Loading />
                            :
                            <Alert message={message.content} type={message.type} />
                        }
                    </Card>
                </Col>
            </Row>

            <NormalFooter />
        </Layout>
    );
};

export default DeleteAccount;
