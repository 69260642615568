import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { ScatterChart, BarChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, ReferenceLine, Bar, Cell, LabelList } from "recharts";
import { useTranslation } from "react-i18next";
import { getChartColor } from "../../../../../../../services/utils";
import { foreignValueFormat } from "../services";
import theme from "../../../../../../../themes/theme";
import animateGlobalState from '../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

type ScatterAndBarChartProps = {
    stocks: NSApp.Stock[],
    sortByValue: boolean,
    width: number,
    height: number,
    buyKey: string,
    formattedSellKey: string,
    amountKey: string,
    titleSortBy: string,
    titleUnitSortBy: string,
}

const ScatterAndBarChart = (props: ScatterAndBarChartProps) => {
    let { stocks, sortByValue, height, width, buyKey, formattedSellKey, amountKey, titleSortBy, titleUnitSortBy } = props;
    const [ chartData, setChartData ] = useState([]);
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();
    const { t } = useTranslation();

    const formatType = sortByValue ? 'value' : 'volume';
    let chartLeftTitleDisplay = height > 95 ? "block" : "none";

    const formatData = (stocks) => {
        let formattedData = stocks.map(stock => {
            // add formatted sell prop for bar chart
            stock.formattedSellForeignValue = 0 - stock.foreignSellValue;
            stock.formattedSellForeignVolume = 0 - stock.foreignSellVolume;
            return stock;
        });

        // sort by price change percent
        return formattedData.sort((a, b) => parseFloat(a.pricePercentChange) - parseFloat(b.pricePercentChange));
    };

    useEffect(() => {
        setChartData(formatData(stocks));
    }, [stocks]); // eslint-disable-line

    const renderCell = (data, type) => {
        return data.map((stock, index) => {
            let color = getChartColor(stock, themeMode);

            return (
                <Cell key={`${type}-${index}`} fill={color} />
            )
        })
    };

    const renderTooltip = (data, type) => {
        const { active, label, payload } = data;

        if (active && payload && payload.length) {
            return (
                <div className="custom-chart-tooltip">
                    {type ==="scatter" ?
                        <>
                            <div className="text-primary text-base uppercase"><strong>{payload[0]?.value}</strong></div>
                            <div className="text-black"><strong>{titleSortBy} {t('Ròng')}: {foreignValueFormat(payload[1]?.value, formatType)} {titleUnitSortBy}</strong></div>
                            <div className="text-black"><strong>{t('Vốn hoá')}: {foreignValueFormat(payload[2]?.value, 'value')} (Tỷ VNĐ)</strong></div>
                        </>
                    :
                        <>
                            <div className="text-primary text-base uppercase"><strong>{label}</strong></div>
                            <div className="text-black"><strong>{titleSortBy} {t('Mua')}: {foreignValueFormat(payload[0]?.value, formatType)} {titleUnitSortBy}</strong></div>
                            <div className="text-black"><strong>{titleSortBy} {t('Bán')}: {foreignValueFormat(0 - payload[1]?.value, formatType)} {titleUnitSortBy}</strong></div>
                        </>
                    }
                </div>
            )
        }

        return null;
    }

    // if (loading || !chartData) {
    //     return <Skeleton active />;
    // }

    return (
        <Card
            className="mb-1"
            size="small"
            bodyStyle={{
                padding: 0,
                paddingTop: 20,
                paddingBottom: 20,
                backgroundColor: theme.colors.chartBackground[themeMode],
            }}
        >
            <div className="chart-wrapper-has-left-title">
                <h4 className="chart-left-title" style={{width: height, display: chartLeftTitleDisplay}}>{titleSortBy} {t('Ròng')} {titleUnitSortBy}</h4>
                <ScatterChart
                    width={width}
                    height={height - 20}
                    data={chartData}
                >
                    <XAxis hide dataKey="stockCode"/>

                    <YAxis
                        type="number"
                        dataKey={amountKey}
                        padding={{ top: 30, bottom: 30 }}
                        tick={{fontSize: 10}}
                        tickLine={false}
                        tickFormatter={(tickValue) => foreignValueFormat(tickValue, formatType)}
                        stroke={theme.colors.chartAxis[themeMode]}
                        strokeWidth={0.5}
                    />

                    <ZAxis dataKey="marketCapital" range={[50, 2000]}/>

                    <CartesianGrid
                        vertical={false}
                        stroke={theme.colors.gray.background}
                        strokeWidth={0.2}
                    />

                    <Tooltip cursor={false} content={chartData => renderTooltip(chartData, 'scatter')}/>

                    <Scatter data={chartData} isAnimationActive={animate}>
                        <LabelList dataKey="stockCode" position="top" style={{fontSize: 8, textTransform: 'uppercase'}}/>
                        {renderCell(chartData, 'total')}
                    </Scatter>

                </ScatterChart>
            </div>

            <div className="chart-wrapper-has-left-title">
                <h4 className="chart-left-title" style={{width: height, display: chartLeftTitleDisplay}}>{titleSortBy} {titleUnitSortBy}</h4>
                <BarChart
                    width={width}
                    height={height - 20}
                    data={chartData}
                    stackOffset="sign"
                >
                    <XAxis hide dataKey="stockCode"/>

                    <YAxis
                        padding={{ top: 10 }}
                        tick={{fontSize: 10}}
                        tickLine={false}
                        tickFormatter={(tickValue) => foreignValueFormat(tickValue, formatType)}
                        stroke={theme.colors.chartAxis[themeMode]}
                        strokeWidth={0.5}
                    />

                    <CartesianGrid
                        vertical={false}
                        stroke={theme.colors.gray.background}
                        strokeWidth={0.2}
                    />

                    <Tooltip cursor={false} content={chartData => renderTooltip(chartData, 'bar')} />

                    <ReferenceLine y={0} stroke={theme.colors.primary} />

                    <Bar dataKey={buyKey} isAnimationActive={animate} stackId="stack">
                        <LabelList dataKey="stockCode" position="top" style={{fontWeight: 'bold', fontSize: 8, textTransform: 'uppercase'}}/>
                        {renderCell(chartData, 'buy')}
                    </Bar>

                    <Bar dataKey={formattedSellKey} isAnimationActive={animate} stackId="stack">
                        {renderCell(chartData, 'sell')}
                    </Bar>
                </BarChart>
            </div>
        </Card>
    )
};

export default ScatterAndBarChart;