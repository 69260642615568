import React from 'react';
import { Card, Tabs, Badge } from 'antd';
import { useContextSelector } from 'use-context-selector';
import { NotificationContext } from '../../../../contexts/NotificationProvider';

import NotificationContainer from './container';

const NotificationTabTitle = (props: { label: string; type: string }) => {
    const { label, type } = props;

    const unReadCount = useContextSelector(
        NotificationContext,
        (s) => s[0][type],
    );

    if (unReadCount !== undefined && unReadCount > 0) {
        return (
            <Badge count={unReadCount} offset={[15, 5]}>
                <span>{label}</span>
            </Badge>
        )
    }

    return <span>{label}</span>;
};

const Notification = () => {
    return (
        <Card
            className="h-full overflow-auto"
            bodyStyle={{ padding:5 }}
            style={{ maxWidth: 600, margin: '0 auto' }}
        >
            <span className="text-lg font-bold">Thông báo</span>
            <Tabs
                className="full-width-tabs"
                defaultActiveKey="recommendations"
                size="small"
                tabBarStyle={{ fontSize: 10 }}
            >
                <Tabs.TabPane
                    forceRender={true}
                    key="notification-recommendations"
                    tab={
                        <NotificationTabTitle
                            label="Khuyến nghị"
                            type="new-recommendation"
                        />
                    }
                >
                    <NotificationContainer type="new-recommendation" />
                </Tabs.TabPane>
                <Tabs.TabPane
                    forceRender={true}
                    key="new-notification-info"
                    tab={
                        <NotificationTabTitle
                            label="Thông báo"
                            type="new-notification-info"
                        />
                    }
                >
                    <NotificationContainer type="new-notification-info" />
                </Tabs.TabPane>
                {/* <Tabs.TabPane
                    forceRender={true}
                    key="notification-blogs"
                    tab={
                        <NotificationTabTitle
                            label="Bài viết"
                            type="new-article"
                        />
                    }
                >
                    <NotificationContainer type="new-article" />
                </Tabs.TabPane> */}
            </Tabs>
        </Card>
    );
}

export default Notification;
