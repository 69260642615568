import React, { useRef } from 'react';
import { useEffectOnce, useUnmount } from 'react-use';
import { Layout, Avatar, Button, Dropdown, Space, Menu, notification, Tag, Popover } from "antd";
import { CaretUpOutlined, CaretDownOutlined, UserOutlined, LogoutOutlined, AppstoreAddOutlined, ShoppingCartOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import { useAuthState, useLayoutState } from "../../contexts";
import exchangeStatusGlobalState from '../../contexts/exchangeStatus';
import { MainIndexContext } from '../../contexts/MainIndexProvider';
import { MAIN_INDEX } from '../../config';
import { roundedNumber } from "../../services/utils";
import localStorage from "../../services/local-storage";
import theme from "../../themes/theme";
import routes from "../../routes";
import api from "../../services/api";

import SearchStocks from "../SearchStocks";
import NotificationBell from "../NotificationBell";
import SelectLanguage from "../SelectLanguage";
import SwitchTheme from "../SwitchTheme";

type MainIndexHeaderProps = {
    indexId: string,
    label: string,
}

const MainIndexHeader = (props: MainIndexHeaderProps) => {
    const { indexId, label } = props;

    const mainIndexState = useContextSelector(
        MainIndexContext,
        (s) => s[0][indexId],
    );

    if (!mainIndexState) {
        return <></>
    }
    // let indexValue = isVnIndex ? mainIndexState.indexValue / 100 : mainIndexState.indexValue;
    let indexValue = mainIndexState.indexValue;

    let change = 0;

    if (mainIndexState.change) {
        // change = isVnIndex ? mainIndexState.change / 100 : mainIndexState.change;
        change = mainIndexState.change;
    }

    let roundedIndexValue = roundedNumber(indexValue, 2);
    let arrowIcon = (change > 0) ? <CaretUpOutlined/> : <CaretDownOutlined/>;
    let colorClassName = (mainIndexState.ratioChange > 0) ? "text-green mr-1" : "text-red mr-1";

    return (
        <div className="marquee-item" key={mainIndexState.indexID}>
            <span className="uppercase font-bold mr-1">{label}</span>
            <span className={`${colorClassName} `}>
                {roundedIndexValue}
                {arrowIcon}
                {change > 0 && '+'}{roundedNumber(change, 2)}
            </span>
            <span className={`${colorClassName}`}>{change > 0 && '+'}{roundedNumber(mainIndexState.ratioChange, 2)}%</span>
        </div>
    )
}

const PrivateHeader = () => {
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { authState, setAuthState } = useAuthState() as any;
    const { setLayoutState } = useLayoutState() as any;
    const [exchangeStatus, setExchangeStatus] = exchangeStatusGlobalState();
    const { currentUser } = authState;

    const isDashboardPage = location.pathname.includes("/dashboard");
    const isDashboardDefaultPage = location.pathname.includes("/dashboard-default");

    let subStrFullname =  currentUser?.fullname.length > 13 ? currentUser?.fullname.substr(0,10) + '...' : currentUser?.fullname;
    let subStrEmail = currentUser?.email.length > 13 ? currentUser?.email.substr(0,10) + '...' : currentUser?.email;
    const exchangeStatusInterval = useRef<any>(null);

    const generateExchangeStatus = () => {
        const timeFormat = 'hh:mm:ss';
        const currentTime = moment();
        const isWeekend = moment().day() % 6 === 0;

        const openATO = moment('09:00:00', timeFormat);
        const openLO1 = moment('09:15:00', timeFormat);
        const openBreak = moment('11:30:00', timeFormat);
        const openLO2 = moment('13:00:00', timeFormat);
        const openATC = moment('14:30:00', timeFormat);
        const closeATC = moment('15:00:00', timeFormat);

        let status = 'OFFLINE';
        let label = t('Hết giờ');
        let color = "";

        if (currentTime.isBetween(openATO, openLO1)) {
            status = 'ATO';
            label = t('Phiên mở cửa');
            color = 'gold';
        }
        if (
            currentTime.isBetween(openLO1, openBreak) ||
            currentTime.isBetween(openLO2, openATC)
        ) {
            status = 'LO';
            label = t('Liên tục');
            color = 'green';
        }
        if (currentTime.isBetween(openBreak, openLO2)) {
            status = 'BREAK';
            label = t('Nghỉ trưa');
            color = '';
        }
        if (currentTime.isBetween(openATC, closeATC)) {
            status = 'ATC';
            label = t('Phiên đóng cửa');
            color = 'gold';
        }
        if (isWeekend) {
            status = 'OFFLINE';
            label = t('Hết giờ');
            color = '';
        }

        setExchangeStatus({ status, label, color });
    };

    const generateExchangeStatusInterval = () => {
        if (exchangeStatusInterval.current) {
            clearInterval(exchangeStatusInterval.current);
        }
        exchangeStatusInterval.current = setInterval(generateExchangeStatus, 60 * 1000);
    }

    useEffectOnce(() => {
        generateExchangeStatus();
        generateExchangeStatusInterval();
    });


    useUnmount(() => {
        if (exchangeStatusInterval.current) {
            clearInterval(exchangeStatusInterval.current);
            exchangeStatusInterval.current = undefined;
        }
    });

    const logout = async () => {
        localStorage.remove("accessToken");
        setAuthState({ currentUser: null });

        await api.post('user/signout', {});

        notification.success({
            message: t('Đăng xuất thành công'),
        });

        history.push(routes.LOGIN);
    };

    const AccountDropdownMenu = (
        <Menu style={{ width: 200 }}>
            {!!currentUser.userACL && (
                <Menu.Item key="text" className="text-base text-primary font-bold px-2 cursor-default">
                    {t('Hội viên')} {currentUser.userACL.plan}
                </Menu.Item>
            )}
            <Menu.Item key="account" className="text-sm" icon={<ShoppingCartOutlined />}>
                <Link to={routes.UPGRADE}>{t('Nâng cấp/Gia hạn')}</Link>
            </Menu.Item>
            <Menu.Item key="info" className="text-sm" icon={<UserOutlined/>}>
                <Link to={routes.PROFILE}>{t('Hồ sơ cá nhân')}</Link>
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="logout" className="text-sm" icon={<LogoutOutlined/>} onClick={logout}>
                {t('Đăng xuất')}
            </Menu.Item>
        </Menu>
    );

    return <Layout.Header className="home-layout-background home-header flex justify-between">
        <div className="header-left-component">
            <div className="flex items-center h-full">
                <SearchStocks placeholder={t('Tìm kiếm')} style={{width: 150, marginRight: 10}}/>

                {exchangeStatus && <Tag color={exchangeStatus.color}>{exchangeStatus.label}</Tag>}

                <div className="header-marquee-wrap hidden md:block relative">
                    <div className="header-marquee">
                        <div className="header-marquee-list">
                            {MAIN_INDEX.map(mainIndex => {
                                return <MainIndexHeader
                                    key={mainIndex.indexId}
                                    indexId={mainIndex.indexId}
                                    label={mainIndex.label}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Space className="space-align-center" align="center">
            <Link to={routes.UPGRADE_PLAN}>
                <Button type="primary" className="font-bold mr-2">{t('Mua dịch vụ')}</Button>
            </Link>

            {isDashboardPage && !isDashboardDefaultPage && (
                <Popover content='Thêm chức năng' trigger='hover'>
                    <AppstoreAddOutlined
                        style={{fontSize: theme.fontSizes.medium}}
                        onClick={() => setLayoutState({ pluginDrawerVisible: true })}
                    />
                </Popover>
            )}

            <NotificationBell />
            <SelectLanguage />
            <SwitchTheme />

            <Dropdown overlay={AccountDropdownMenu} placement="bottomRight" trigger={['click']}>
                <Button type="text">
                    <Avatar
                        size="small"
                        icon={<UserOutlined/>}
                        className="mr-2"
                        style={{backgroundColor: theme.colors.primary}}
                    />
                    {subStrFullname || subStrEmail}
                </Button>
            </Dropdown>
        </Space>
    </Layout.Header>
}

export default PrivateHeader;