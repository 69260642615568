import React, { useState, useEffect } from "react";
import { message } from "antd";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import api from "../../services/api";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./index.less";

const DraftEditor = (props) => {
  const { defaultState, onChange, initEditorState } = props;

  const [editorState, setEditorState] = useState(
    initEditorState
      ? () =>
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(initEditorState))
          )
      : () => EditorState.createEmpty()
  );

  useEffect(() => {
    if (defaultState) {
      const blocksFromHtml = htmlToDraft(defaultState);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);

      setEditorState(editorState);
    }
  }, [defaultState]);

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    if (onChange) {
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }
  };

  const uploadImage = async (image) => {
    let formData = new FormData();
    formData.append("image", image);
    let [err, resp]: any = await api.post("media/image/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (err) {
      message.error(err?.response?.data?.message);
      return;
    }

    const imageUrl = resp.data.path;

    return { data: { link: imageUrl } };
  };

  return (
    <div className="w-full border-2">
      <Editor
        editorState={editorState}
        toolbarClassName="editor-toolbar"
        wrapperClassName="editor-wrapper"
        editorClassName="editor"
        toolbar={{
          image: {
            uploadCallback: uploadImage,
            previewImage: true,
            defaultSize: {height: 200, width: 200},
          },
        }}
        onEditorStateChange={handleEditorChange}
      />
    </div>
  );
};

export default DraftEditor;
