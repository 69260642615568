import React from "react";
import { Col, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import MainIndexContainer from '../MainIndex/container';
import StockAmountChangeChart from "./components/StockAmountChangeChart";
import LiquidityChart from "./components/LiquidityChart";
import TableStockTable from "./components/TopStockTable";

type StockMarketOverviewProps = {
    width: number;
    height: number;
};

const exchanges = [
    { indexID: 'vnindex', name: 'hose', label: 'VNINDEX', position: true },
    { indexID: 'hnxindex', name: 'hnx', label: 'HNX' },
    { indexID: 'upcomindex', name: 'upcom', label: 'UPCOM' },
];

const StockMarketOverviewContainer = (props:StockMarketOverviewProps) => {
    const { width, height } = props;
    const { t } = useTranslation();

    const containerWidth = width;
    const containerHeight = height;

    return (
        <div className="p-1 overflow-auto h-full">
            {exchanges.map(exchange => {
                const { indexID, name, label, position } = exchange;

                return (
                    <div key={indexID}>
                        <Typography.Text>
                            {t('Dữ liệu thống kê')} <strong>{name.toUpperCase()}</strong>
                        </Typography.Text>

                        <Row gutter={2}>
                            <Col span={6}>
                                <MainIndexContainer
                                    width={containerWidth}
                                    height={containerHeight - 17}
                                    indexId={indexID}
                                    indexLabel={label}
                                />
                            </Col>
                            <Col span={6}>
                                <LiquidityChart
                                    key={indexID}
                                    height={containerHeight}
                                    width={containerWidth}
                                    indexID={indexID}
                                    exchange={name}
                                    position={position}
                                />
                            </Col>
                            <Col span={6}>
                                <StockAmountChangeChart
                                    height={containerHeight}
                                    width={containerWidth}
                                    exchange={name}
                                    indexId={indexID}
                                />
                            </Col>
                            <Col span={6}>
                                <TableStockTable
                                    height={containerHeight}
                                    exchange={name}
                                />
                            </Col>
                        </Row>
                    </div>
                )
            })}
        </div>
    )
};

export default StockMarketOverviewContainer;