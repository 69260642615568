import React from "react";
import { Form, Button, Input } from "antd";

type NewsProviderFormProps = {
    initialValues?: any,
    mode: "add" | "edit",
    onFinish: (values: any) => void,
}

const NewsForm = (props: NewsProviderFormProps) => {

    const { initialValues, mode, onFinish } = props;

    return (
        <Form
            layout="vertical"
            name="news_provider_form"
            className="news-provider-form"
            initialValues={initialValues}
            onFinish={onFinish}
        >
            <Form.Item
                name="name"
                label="Tên nguồn cung cấp"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input placeholder="Tên nguồn cung cấp" />
            </Form.Item>

            <Form.Item
                name="link"
                label="Đường dẫn"
            >
                <Input placeholder="Đường dẫn" />
            </Form.Item>

            <Form.Item>
                <Button className="mb-2" type="primary" htmlType="submit" block>
                    {mode === 'add' ? 'Thêm mới' : 'Cập nhật'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default NewsForm;
