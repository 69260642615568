import React from "react";
import { Space } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, LineOutlined, CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useContextSelector } from 'use-context-selector';
import { roundedNumber } from "../../../../../../../services/utils";
import { nmTotalTradedFormat } from "../../../../../../../services/stock-price";
import * as config from "../../../../../../../config";
import { MainIndexContext } from '../../../../../../../contexts/MainIndexProvider';

type MainIndexInfoProps = {
    width: number,
    height: number,
    indexId: string,
    indexLabel?: string,
    exchangeStatus: any,
}

const MainIndexInfo = (props: MainIndexInfoProps) => {
    const { width, indexId, indexLabel, exchangeStatus } = props;
    const { t } = useTranslation();

    const mainIndexState = useContextSelector(
        MainIndexContext,
        (s) => s[0][indexId],
    );

    if (!mainIndexState) {
        return <></>;
    }

    let indexValue = mainIndexState.indexValue;
    let change = mainIndexState.change;
    let totalValue = mainIndexState.totalValue / (1000 * 1000 * 1000);

    let valueColor = mainIndexState.change > 0 ? "text-green" : "text-red";
    let arrowIcon = mainIndexState.change > 0 ? <CaretUpOutlined/> : <CaretDownOutlined/>;

    // const spaceSize = width < 300 ? 0 : 5;
    const spaceSize = 4;

    return (
        <>
            <div className="content-bottom text-center mb-1">
                <Space size={spaceSize} className="flex justify-center font-bold">
                    {width * 4 > config.RESPONSIVE_SCREEN.lg &&
                        <span className="text-primary">{indexLabel}</span>
                    }
                    <span className={valueColor}>
                        {arrowIcon}{roundedNumber(indexValue, 2)}
                    </span>
                    <span className={valueColor}>
                        ({change > 0 && '+'}{roundedNumber(change, 2)} {change > 0 && '+'}{roundedNumber(mainIndexState.ratioChange, 2)}%)
                    </span>
                    <span>{exchangeStatus.label}</span>
                </Space>
                <Space size={spaceSize} className="flex justify-center font-bold">
                    <span className="text-green">
                        <ArrowUpOutlined/>{mainIndexState.advances}
                    </span>
                    <span className="text-purple">({mainIndexState.ceiling})</span>
                    <span className="text-yellow">
                        <LineOutlined/>{mainIndexState.nochanges}
                    </span>
                    <span className="text-red">
                        <ArrowDownOutlined/>{mainIndexState.declines}
                    </span>
                    <span className="text-teal">({mainIndexState.floor})</span>

                    {width * 4 > config.RESPONSIVE_SCREEN.lg &&
                    <>
                        <span>
                            {nmTotalTradedFormat(mainIndexState.totalVolume)}
                            <span className="text-primary">{t('CP')}</span>
                        </span>
                        <span>
                            {roundedNumber(totalValue, 2)}
                            <span className="text-primary">{t('Tỷ')}</span>
                        </span>
                    </>
                    }
                </Space>
            </div>
        </>
    )
}

export default MainIndexInfo;
