import React, { useState } from 'react';
import { Dropdown, Menu, Tabs, Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import theme from "../../../themes/theme";
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from "react-i18next";
import { NotificationContext } from '../../../contexts/NotificationProvider';
import NotificationContainer from '../../../pages/private/client/Notification/container';

const NotificationTabTitle = (props: { label: string; type: string }) => {
    const { label, type } = props;

    const unReadCount = useContextSelector(
        NotificationContext,
        (s) => s[0][type],
    );

    if (unReadCount !== undefined && unReadCount > 0) {
        return (
            <Badge count={unReadCount} offset={[15, 5]}>
                <span>{label}</span>
            </Badge>
        )
    }

    return <span>{label}</span>;
};

type NotificationBellDropDownProps = {
    // history: H.History,
    totalUnread: number,
    // notifications: NSApp.Notification[],
    onMarkAllNotificationAsRead: () => void,
    onGoToNotificationsPage: () => void,
    // onBellClick: () => void,
    // onVisibleChange: (visible: boolean) => void,
};

const NotificationBellDropDown = (props: NotificationBellDropDownProps) => {
    // const { totalUnread, onGoToNotificationsPage, onMarkAllNotificationAsRead } = props;
    const { totalUnread } = props;

    const [visible, setVisible] = useState(false);

    const { t } = useTranslation();

    const NotificationDropdownMenu = (
        <Menu className="header-dropdown-menu">
            <Menu.ItemGroup title={
                <div className="flex justify-between items-center">
                    <h5 className="text-lg font-bold mb-0">{t('Thông báo')}</h5>
                    {/* <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key="checkedNotif"
                                    icon={<CheckOutlined/>}
                                    onClick={onMarkAllNotificationAsRead}
                                >
                                    Đánh dấu tất cả là đã đọc
                                </Menu.Item>
                                <Menu.Item
                                    key="seeAllNotif"
                                    icon={<DesktopOutlined/>}
                                    onClick={goToNotifPage}
                                >
                                    Xem tất cả thông báo
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button type="text" shape="circle" icon={<EllipsisOutlined/>}/>
                    </Dropdown> */}
                </div>
            }>
                <Tabs
                    className="full-width-tabs"
                    defaultActiveKey="recommendations"
                    size="small"
                    tabBarStyle={{ fontSize: 10 }}
                >
                    <Tabs.TabPane
                        forceRender={true}
                        key="recommendations"
                        tab={
                            <NotificationTabTitle
                                label={t('Khuyến nghị')}
                                type="new-recommendation"
                            />
                        }
                    >
                        <NotificationContainer
                            isCompact
                            type="new-recommendation"
                            setDropdownVisible={setVisible}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        forceRender={true}
                        key="new-notification-info"
                        tab={
                            <NotificationTabTitle
                                label={t('Thông báo')}
                                type="new-notification-info"
                            />
                        }
                    >
                        <NotificationContainer
                            isCompact
                            type="new-notification-info"
                            setDropdownVisible={setVisible}
                        />
                    </Tabs.TabPane>
                    {/* <Tabs.TabPane
                        forceRender={true}
                        key="blogs"
                        tab={
                            <NotificationTabTitle
                                label="Bài viết"
                                type="new-article"
                            />
                        }
                    >
                        <NotificationContainer
                            isCompact
                            type="new-article"
                        />
                    </Tabs.TabPane> */}
                </Tabs>

                {/* {notifications.length === 0 &&
                <div className="px-3 py-1 text-gray">
                    Không có thông báo
                </div>
                }

                {notifications.map((notification: NSApp.Notification) => {
                    let notifTextColor = notification.readed ? theme.colors.gray.text : theme.colors.white;
                    let timeStyle = notification.readed ? {} : {color: theme.colors.orange.background};

                    return (
                        <Menu.Item className="flex items-center" key={notification._id}
                                   onClick={() => onNotificationClick(history, notification)}>
                            <div style={{width: 260}}>
                                <div className="dropdown-menu-item-title" style={{color: notifTextColor}}
                                     dangerouslySetInnerHTML={{__html: notification.content}}/>
                                <span style={timeStyle}>{notification.readed} {moment(notification.createdAt).fromNow()}</span>
                            </div>

                            {!notification.readed &&
                            <div className="text-right" style={{width: 24}}>
                                <span className="text-3xl" style={{color: theme.colors.orange.background}}>•</span>
                            </div>
                            }
                        </Menu.Item>
                    )
                })} */}
            </Menu.ItemGroup>
        </Menu>
    );

    return <Dropdown
        visible={visible}
        onVisibleChange={(visible) => setVisible(visible)}
        overlay={NotificationDropdownMenu}
        placement="bottomCenter"
        trigger={["click"]}
    >
        <Badge count={totalUnread} overflowCount={99} size="small">
            <BellOutlined
                onClick={() => setVisible(!visible)}
                style={{fontSize: theme.fontSizes.medium}}
            />
        </Badge>
    </Dropdown>
}

export default NotificationBellDropDown;