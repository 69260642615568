import React from "react";
import { Layout, Row, Col, Card, Form, Input, Button, Checkbox, notification, Avatar, Space, Divider } from "antd";
import { Link, useHistory } from "react-router-dom";
import { LockOutlined, UserOutlined, PhoneOutlined, MailOutlined, TagOutlined } from "@ant-design/icons";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useAuthState } from "../../contexts";
import themeModeGlobalState from "../../contexts/ThemeMode";
import localStorage from "../../services/local-storage";
import api from "../../services/api";
import routes from "../../routes";
import SelectLanguage from "../../components/SelectLanguage";
import NormalFooter from "../../components/NormalFooter";
import GoogleLoginButton from "./components/GoogleLoginButton";
import FacebookLoginButton from "./components/FacebookLoginButton";

import LogoWhite from "../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../assets/image/logo/Fstock_black.svg";

const Register = () => {
    let history = useHistory();
    const { t } = useTranslation();
    const { setAuthState } = useAuthState() as any;

    const [themeMode,] = themeModeGlobalState();
    const logo = themeMode === 'dark' ? LogoWhite : LogoBlack;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const code = query.get('code') || "";

    const handleError = (err) => {
        const status = err?.response?.status;
        if (status && [400, 404].includes(status)) {
            return notification.error({
                message: t('Tài khoản hoặc mật khẩu không đúng, quý khách vui lòng kiểm tra lại!'),
            });
        }

        return notification.error({
            message: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
        });
    }

    const handleAfterLoginWithSocial = (accessToken: string, user: NSApp.User) => {
        if (!accessToken || !user) {
            return notification.error({
                message: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
            });
        }

        notification.success({
            message: t('Đăng nhập thành công!'),
            description: <span>{t('Chào mừng trở lại')}, <strong>{user.fullname}</strong></span>,
        });
        // api.setToken(token);
        api.setAccessToken(accessToken);
        setAuthState({ currentUser: user });
        localStorage.set('accessToken', accessToken);

        history.push(routes.HOME);
    }

    const onFinish = async values => {
        let [err, resp]: any = await api.post('user/signup', {
            email: values.email,
            fullname: values.fullname,
            phone: values.phone,
            password: values.password,
            referenceCode: values.referenceCode,
        });

        // err.response
        if (err) {
            notification.error({
                message: t('Vui lòng thử lại!'),
                description: err?.response?.data?.message,
            });
            return;
        }

        if (resp) {
            notification.success({
                message: t('Đăng ký tài khoản thành công!'),
                description: t('Quý khách vui lòng kiểm tra email để kích hoạt tài khoản')
            });

            history.push(routes.REGISTER_SUCCESSFULLY, {
                email: values.email
            });
        }
    };

    return (
        <Layout className="auth-page">
            <div className="flex flex-1 flex-col justify-center items-center">
                <Space className="brand flex justify-center" align="center">
                    <Avatar shape="square" size={128} src={logo}/>
                </Space>
                <div className="w-full p-4">
                    <Card
                        className="w-full max-w-lg mx-auto"
                        title={
                            <div className="flex justify-between items-center px-3">
                                <strong className="text-lg">{t('Đăng ký')}</strong>
                                <SelectLanguage />
                            </div>
                        }
                    >
                        <Form
                            name="register"
                            className="register-form"
                            size="middle"
                            onFinish={onFinish}
                            initialValues={{
                                agreement: true,
                                referenceCode: code
                            }}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    { type: 'email', message: t('Email không đúng định dạng') },
                                    { required: true, message: t('Không được để trống') },
                                ]}
                            >
                                <Input prefix={<MailOutlined className="site-form-item-icon"/>} placeholder={t('Email')} />
                            </Form.Item>

                            <Form.Item
                                name="fullname"
                                rules={[
                                    { required: true, message: t('Không được để trống') },
                                ]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder={t('Họ và tên')} />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                rules={[
                                    { len: 10, message: t('Số điện thoại phải có 10 số') },
                                    () => ({
                                        validator(rule, value) {
                                            if (!isNaN(value) && value.startsWith('0')) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(t('Số điện thoại phải bắt đầu bằng số 0'));
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <Input prefix={<PhoneOutlined className="site-form-item-icon"/>} placeholder={t('Số điện thoai')} />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: t('Không được để trống') },
                                    { min: 5, message: t('Mật khẩu phải có ít nhất 5 ký tự') }
                                ]}
                                hasFeedback
                            >
                                <Input.Password prefix={<LockOutlined className="site-form-item-icon"/>} placeholder={t('Mật khẩu')} />
                            </Form.Item>

                            <Form.Item
                                name="confirmPassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    { required: true, message: t('Không được để trống') },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(t('Mật khẩu không khớp'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password prefix={<LockOutlined className="site-form-item-icon"/>} placeholder={t('Xác nhận mật khẩu')} />
                            </Form.Item>

                            <Form.Item
                                name="referenceCode"
                                hasFeedback
                            >
                                <Input prefix={<TagOutlined className="site-form-item-icon"/>} placeholder={t('Mã giới thiệu')} />
                            </Form.Item>

                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                    { validator: (_, value) => value ? Promise.resolve() : Promise.reject(t('Vui lòng đồng ý với điều khoản sử dụng')) },
                                ]}
                            >
                                <Checkbox>{t('Điều khoản sử dụng')}</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button className="mb-2" type="primary" htmlType="submit" block>
                                    <span className="font-bold">{t('Đăng ký')}</span>
                                </Button>
                                {t('Bạn đã có tài khoản')}? <Link to={routes.LOGIN}>{t('Đăng nhập')}</Link>
                            </Form.Item>

                            <Divider plain>{t('hoặc đăng ký với')}</Divider>
                            <Row gutter={8}>
                                <Col xs={24} lg={12}>
                                    <GoogleLoginButton
                                        onSuccess={handleAfterLoginWithSocial}
                                        onError={handleError}
                                        referenceCode={code}
                                    />
                                </Col>
                                <Col xs={24} lg={12}>
                                    <FacebookLoginButton
                                        onSuccess={handleAfterLoginWithSocial}
                                        onError={handleError}
                                        referenceCode={code}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </div>
            </div>
            <NormalFooter/>
        </Layout>
    )
};

export default Register;
