import React from 'react';
import { Card, List } from 'antd';
import { PhoneOutlined, MailOutlined, GlobalOutlined, FacebookOutlined, InstagramOutlined, YoutubeOutlined, UsergroupAddOutlined } from '@ant-design/icons';

const ContactAndSupport = () => {
    const iconFontSize = 18;

    const contactList = [
        { title: 'Hotline', description: '096 669 89 89', icon: <PhoneOutlined style={{ fontSize: iconFontSize }} /> },
        { title: 'Email', description: 'contact@fcapital.vn', icon: <MailOutlined style={{ fontSize: iconFontSize }} /> },
        { title: 'Website', description: 'https://fstock.vn', link: 'https://fstock.vn', icon: <GlobalOutlined style={{ fontSize: iconFontSize }} /> },
        { title: 'Facebook Fanpage', description: 'Fstock - Trợ lý đầu tư chứng khoán', link: 'https://www.facebook.com/fstock.vn', icon: <FacebookOutlined style={{ fontSize: iconFontSize }} /> },
        { title: 'Facebook Group', description: 'Thực chiến đầu tư cùng Fstock', link: 'https://www.facebook.com/groups/550004869613160', icon: <UsergroupAddOutlined style={{ fontSize: iconFontSize }} /> },
        { title: 'Youtube', description: 'Fstock - Trợ lý đầu tư chứng khoán', link: 'https://www.youtube.com/channel/UCQvxYdK3NjKDwfaBupW15Qg', icon: <YoutubeOutlined style={{ fontSize: iconFontSize }} /> },
        { title: 'Instagram', description: 'Fstock.vn', link: 'https://www.instagram.com/fstock.vn/', icon: <InstagramOutlined style={{ fontSize: iconFontSize }} /> },
    ];

    return <Card bordered={false} className="h-full">
        <List
            itemLayout="horizontal"
            dataSource={contactList}
            renderItem={item => (
                <List.Item>
                    <List.Item.Meta
                    avatar={<div style={{ marginTop: 2 }}>{item.icon}</div>}
                    title={<span className="text-base font-bold">{item.title}</span>}
                    description={
                        item.link
                            ? <a href={item.link} target="_blank" rel="noopener noreferrer">{item.description}</a>
                            : <span className="text-primary">{item.description}</span>
                    }
                    />
                </List.Item>
            )}
        />
    </Card>;
};

export default ContactAndSupport;