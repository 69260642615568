import api from "../../../../../../../services/api";

export const fetchStockAmountChange = async (exchange: string) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get('plugins/overview-stock-market/exchange', { name: exchange });
    if (err) return;
    let { stocks} = resp.data;
    return { stocks }
};

export const fetchTopStocks = async (exchange: string, sortBy: string) => {
    const [err, resp]: any = await api.get('plugins/overview-stock-market/top-stock', {exchange: exchange, sortBy});

    if (err) return [];

    return resp.data;
};

export const fetchMainIndexDataAverage = async (indexID: string) => {
    const [err, resp]: any = await api.get(`plugins/main-index/data-average/${indexID}/five-prev-session`);

    if (err) return [];
    return resp.data;
}

export const fetchMainInDexDataPrevSession = async (indexID: string) => {
    const [err, resp]: any = await api.get(`plugins/main-index/data-average/${indexID}/prev-session/`);

    if (err) return [];
    return resp.data;
}

export const fetchMainInDexDataPerMin = async (indexID: string) => {
    const [err, resp]: any = await api.get(`plugins/main-index/data-average/${indexID}`);

    if (err) return [];
    return resp.data;
}
