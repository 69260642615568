import React, { useState } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import theme from "../../../../../../themes/theme";
import DerivativesAnalysisWrapper from "./wrapper";
import UserACL from '../../../../../../components/UserACL';

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 80;

const DerivativesAnalysisWindow = (props) => {
    const { glContainer } = props;
    const { t } = useTranslation();
    const [stockSymbol, setStockSymbol] = useState('vn30f1m');

    const DERIVATIVES_LIST = ['vn30f1m', 'vn30f2m', 'vn30f1q', 'vn30f2q'];

    const onChangeStockSymbol = (value) => {
        setStockSymbol(value);
    };

    let layoutWidth = glContainer.width ? glContainer.width : window.innerWidth - SIDER_WIDTH;
    let layoutHeight = glContainer.height ? glContainer.height : window.innerHeight - HEADER_HEIGHT;

    return (
        <UserACL feature="derivatives_analysis">
            <div className="flex align-items-center m-2">
                <div className="text-sm font-bold mr-2">{t('Mã phái sinh')}:</div>
                <Select
                    value={stockSymbol}
                    onChange={onChangeStockSymbol}
                    style={{ width: 200, color: theme.colors.primary, fontWeight: 'bold' }}
                >
                    {DERIVATIVES_LIST.map(code => {
                        return <Select.Option key={code} value={code}>{code.toUpperCase()}</Select.Option>
                    })}
                </Select>
            </div>
            <DerivativesAnalysisWrapper
                stockSymbol={stockSymbol}
                layoutWidth={layoutWidth}
                layoutHeight={layoutHeight}
            />
        </UserACL>
    )
};

export default DerivativesAnalysisWindow;