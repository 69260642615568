import React, { useEffect, useState, useRef } from "react";
import { Tabs } from "antd";
import TradingViewWrapper from "./Wrapper";
import { v4 as uuidv4 } from "uuid";
import TradingViewServices from './services';
import UserACL from '../../../../../../components/UserACL';

const { TabPane } = Tabs;

const TradingViewTabs = (props) => {
    const [panes, setPanes] = useState<any[]>([]);
    const [isReady, setIsReady] = useState(false);
    const [activeKey, setActiveKey] = useState("");

    const panesRef = useRef(panes)

    // id is id of golden layout container
    const { id, glContainer } = props;

    useEffect(() => {
        //const panes = getTabsPane
        (async () => {
            const tab = await getTab();
            const panesFetched = tab?.panes || [];

            if (!panesFetched || panesFetched.length <= 0) {
                const key = uuidv4()
                const newPanes = [{
                    id: key,
                    key,
                    tab: 'HPG',
                    symbol: 'hpg'
                }]
                setPanes(newPanes)
                panesRef.current = newPanes;
                saveTab();
                setActiveKey(key)
            } else {
                setPanes(panesFetched)
                panesRef.current = panesFetched;
                setActiveKey(panesFetched[0].key)
            }

            setIsReady(true);
        })()
    }, []); // eslint-disable-line

    const getTab = async () => {
        return await TradingViewServices.getTab(id)
    }

    const saveTab = async () => {
        await TradingViewServices.saveTab({
            id: id,
            panes: panesRef.current
        })
    }

    const add = async () => {
        const currentPane = panes.find((pane) => pane.key === activeKey)
        const key = uuidv4();
        const newPanes = [...panes, {tab: currentPane.tab || '', id: activeKey, key, symbol: currentPane.symbol}]
        setPanes(newPanes);
        panesRef.current = newPanes;
        setActiveKey(key);
    }

    const onSymbolChange = async (symbol, id) => {
        const newPanes = panesRef.current.map(pane => {
            if (pane.key === id) {
                pane.tab = symbol
                pane.symbol = symbol
            }
            return pane
        })

        setPanes(newPanes)
        panesRef.current = newPanes;
        saveTab();
    }

    const remove = async (targetKey) => {
        const newPanes = panes.filter(pane => pane.key !== targetKey)
        setPanes(newPanes)
        setActiveKey(newPanes[0].key)
        panesRef.current = newPanes
    }

    const onEdit = async (targetKey, action) => {
        if (action === 'add') {
            add()
        }

        if (action === 'remove') {
            remove(targetKey)
        }

        await saveTab();
    }

    const onChange = (targetKey) => {
        setActiveKey(targetKey)
    }

    return (
        <UserACL feature="tradingview">
            <Tabs type="editable-card" onEdit={onEdit} onChange={onChange} activeKey={activeKey}>
                {isReady && panes.map(pane => {
                    return <TabPane tab={pane.tab} key={pane.key} closable={panes.length > 1 ? true : false} style={{width: '100%', height: glContainer.height}}>
                        <TradingViewWrapper key={`tradingView${pane.key}`}symbol={pane.symbol} id={pane.key} glContainer={glContainer} onChange={onSymbolChange} />
                    </TabPane>}
                )}
            </Tabs>
        </UserACL>
    )
}

export default TradingViewTabs;

