import { UserType } from "../user";

export const ARTICLE_ACCEPTED = "accepted";
export const ARTICLE_PENDING = "pending";
export const ARTICLE_DENIED = "denied";

export interface ArticleCategoryType {
    _id: string,
    name: string,
    link: string
}

export interface ArticleType {
    _id: string,
    title: string,
    link: string,
    description: string,
    content: string,
    thumbnail: string,
    createdAt: string,
    category?: ArticleCategoryType,
    author: UserType,
    status: string
}