import React from 'react';
import { Button, Space, message } from 'antd';
import { RECOMMENDATION_ACCEPTED, RECOMMENDATION_DENIED, RECOMMENDATION_PENDING } from "../../../../types/plugins/recommendation";
import api from "../../../../services/api";

type ChangeStatusButtonProps = {
    recommendation: NSApp.Recommendation,
    onChange: Function
}

const ChangeStatusButton = (props: ChangeStatusButtonProps) => {
    const { recommendation } = props;

    const setRecommendationStatus = async (id: number, status: string) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.patch(`plugins/recommendation/${id}/set-recommendation-status`, {status});

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (status === RECOMMENDATION_ACCEPTED) {
            message.success('Đã duyệt bài viết');
        }
        if (status === RECOMMENDATION_DENIED) {
            message.success('Từ chối bài viết');
        }

        props.onChange();
    };

    return <Space>
        {recommendation.status === RECOMMENDATION_PENDING &&
            <>
                <Button type="primary" onClick={() => setRecommendationStatus(recommendation.id, RECOMMENDATION_ACCEPTED)}>Phê duyệt</Button>
                <Button type="primary" danger onClick={() => setRecommendationStatus(recommendation.id, RECOMMENDATION_DENIED)}>Từ chối</Button>
            </>
        }

        {recommendation.status !== RECOMMENDATION_PENDING &&
            <>
                {recommendation.status === RECOMMENDATION_DENIED ?
                    <Button type="primary" onClick={() => setRecommendationStatus(recommendation.id, RECOMMENDATION_ACCEPTED)}>Phê duyệt</Button>
                    :
                    <Button type="primary" danger onClick={() => setRecommendationStatus(recommendation.id, RECOMMENDATION_DENIED)}>Từ chối</Button>
                }
            </>
        }
    </Space>
}

export default ChangeStatusButton;