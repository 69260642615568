import React, { useState, useRef, useEffect } from "react";
import { useEffectOnce, useUnmount } from "react-use";
import { useContextSelector } from 'use-context-selector';
import _ from "lodash";
import moment from 'moment';

import { priceChangePercentFormat, priceFormat, localeFormat } from "../../../../../../../services/stock-price";
import { stockColors } from "../../../../../../../themes/colors";
import { getStockColorClass, formatNumberWithComma, roundedNumber } from "../../../../../../../services/utils";
import NameWithChartIcon from "../../../../components/NameWithChartIcon";
import BaseStockCode from "../../../../components/warrantStock/BaseStockCode";
import BaseStockPrice from "../../../../components/warrantStock/BaseStockPrice";
import DifferenceValue from "../../../../components/derivativeStock/DifferenceValue";
import * as config from '../../../../../../../config';
import { getCellColorAnimation } from '../services';
import { StockContext } from '../../../../../../../contexts/StocksProvider';
import { StockForeignerContext } from '../../../../../../../contexts/StocksForeignerProvider';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

type PriceTableCellProps = {
    currentColumn: any,
    currentRawData: NSApp.Stock,
    priceRatio?: number,
    volumeRatio?: number,
    valueRatio?:number,
    changeTradingView?: (stockSymbol: string) => void
};

const PriceTableCell = (props: PriceTableCellProps) => {
    const [animation, setAnimation] = useState<string>('');
    const [color, setColor] = useState<string>('');
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const [themeMode,] = themeModeGlobalState();

    const animationTimeoutRef = useRef<any>(null);

    let { currentColumn, currentRawData, changeTradingView } = props;
    let { priceRatio, volumeRatio, valueRatio } = props;
    let columnName = currentColumn.key;

    priceRatio = priceRatio ? priceRatio : 1000;
    volumeRatio = volumeRatio ? volumeRatio : config.VOLUME_RATIO;
    valueRatio = valueRatio ? valueRatio : config.VALUE_RATIO;

    let priceFixed = currentRawData.stockType === 'derivative' ? 1 : 2;

    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][currentRawData.stockCode],
    );

    const stockForeignerState = useContextSelector(
        StockForeignerContext,
        (s) => s[0][currentRawData.stockCode],
    );

    const columnValue = useRef<any>(currentRawData[columnName]);

    useEffectOnce(() => {
        setIsFirstRender(false);
    });

    useEffect(() => {
        if (columnValue.current) {
            let { animation, color }: any = getCellColorAnimation(columnName, columnValue.current, stockState, themeMode);
            if (!isFirstRender) {
                setAnimation(animation);
            }
            setColor(color);
        }

        if (!_.isNull(stockState[columnName]) && !_.isUndefined(stockState[columnName])) {
            columnValue.current = stockState[columnName];
        }

        if (!_.isNull(stockForeignerState[columnName]) && !_.isUndefined(stockForeignerState[columnName])) {
            columnValue.current = stockForeignerState[columnName];
        }
    }, [isFirstRender, stockState, stockForeignerState, columnName, themeMode]);

    useEffect(() => {
        animationTimeoutRef.current = setTimeout(() => {
            setAnimation('');
        }, 500);
    }, [animation])

    useUnmount(() => {
        clearTimeout(animationTimeoutRef.current);
    });

    const columnsBest1VolumeFormat = ['best1BidVol', 'best1OfferVol'];
    const columnsForeigner = ['foreignBuyVolume', 'foreignSellVolume', 'remainForeignRoom'];
    const columnsVolumeFormat = ['best3BidVol', 'best2BidVol', 'best2OfferVol', 'best3OfferVol', 'totalVolume'];

    const columnsFixed = ['stockCode', 'maturityDate', 'issuerName', 'lastTradingDate', 'ceilingPrice', 'floorPrice', 'referencePrice'];

    const columnsAverageVolume = [
        'averageTotalVolume5Days', 'averageTotalVolume10Days', 'averageTotalVolume30Days',
        'averageTotalVolume60Days', 'averageTotalVolume90Days',
    ];

    const columnsPriceFormat = [
        'best3Bid', 'best2Bid', 'matchedPrice', 'best2Offer',
        'best3Offer', 'highestPrice', 'lowestPrice', 'averagePrice',
        'averagePrice5Days', 'averagePrice10Days', 'averagePrice30Days',
    ];

    const columnsPercent = [
        'percentChangeTotalVolume5Days', 'percentChangeTotalVolume10Days', 'percentChangeTotalVolume30Days',
        'percentChangeTotalVolume60Days', 'percentChangeTotalVolume90Days', 'percentChangePrice5Days',
        'percentChangePrice10Days', 'percentChangePrice30Days', 'percentChangePrice60Days', 'percentChangePrice90Days'
    ];

    const cellValue = stockState[columnName] ? stockState[columnName] : columnValue.current;
    const ATO_ATC_SESSION = ['ATO', 'ATC', 'ato', 'atc'];

    if (stockState.marketStatus === 'C' && !stockState.matchedPrice && !columnsFixed.includes(columnName)) {
        return <></>;
    }

    return <div className={animation} style={{
        color: color,
        display: "block",
        padding: '0.25rem 0.375rem'
    }}>
        {columnName === 'exchange' &&
            <span>{currentColumn.render(stockState.exchange)}</span>
        }

        {columnName === 'marketStatus' &&
            <div className="text-center">
                <span>{cellValue}</span >
            </div>
        }

        {columnName === 'openInterest' &&
            <span>{stockState.openInterest}</span>
        }

        {columnName === 'maturityDate' &&
            <div className="text-center">
                <span>{moment(stockState.maturityDate).format(config.DATE_TIME_FORMAT.DATE)}</span>
            </div>
        }

        {columnName === 'action' &&
            <>{currentColumn.render(currentRawData)}</>
        }

        {columnName === 'stockCode' &&
            <NameWithChartIcon
                changeTradingView={changeTradingView}
                color={getStockColorClass(stockState, themeMode)}
                symbol={stockState.stockCode.toUpperCase()}
                relateSymbol={currentRawData?.relateFuCode}
            />
        }

        {columnName === 'ceilingPrice' &&
            <span style={{color: stockColors.ceiling[themeMode]}}>
                {priceFormat(stockState.ceilingPrice, priceRatio, priceFixed)}
            </span>
        }

        {columnName === 'floorPrice' &&
            <span style={{color: stockColors.floor[themeMode]}}>
                {priceFormat(stockState.floorPrice, priceRatio, priceFixed)}
            </span>
        }

        {columnName === 'referencePrice' &&
            <span style={{color: stockColors.keep[themeMode]}}>
                {priceFormat(stockState.referencePrice, priceRatio, priceFixed)}
            </span>
        }

        {columnsPriceFormat.includes(columnName) &&
            <span>{priceFormat(columnValue.current, priceRatio, priceFixed, true)}</span>
        }

        {columnsVolumeFormat.includes(columnName) &&
            <span>{formatNumberWithComma(columnValue.current, volumeRatio)}</span>
        }

        {columnsBest1VolumeFormat.includes(columnName) &&
            <span className={ATO_ATC_SESSION.includes(stockState.marketStatus) ? `text-${themeMode === 'dark' ? 'white' : 'black'}` : ''}>
                {formatNumberWithComma(columnValue.current, volumeRatio)}
            </span>
        }

        {columnsForeigner.includes(columnName) &&
            <span>{formatNumberWithComma(columnValue.current, volumeRatio)}</span>
        }

        {columnName === 'best1Bid' &&
            <span>
                {ATO_ATC_SESSION.includes(stockState.marketStatus) ?
                    <span className={`text-${themeMode === 'dark' ? 'white' : 'black'}`}>{stockState.marketStatus.toUpperCase()}</span>
                    :
                    <>{priceFormat(cellValue, priceRatio, priceFixed, true)}</>
                }
            </span>
        }

        {columnName === 'volume' &&
            <span>{formatNumberWithComma(cellValue, volumeRatio)}</span>
        }

        {columnName === 'priceChange' &&
            <span style={{ color: getStockColorClass(stockState, themeMode) }}>
                {cellValue > 0 && '+'}{priceFormat(cellValue, priceRatio)}
            </span>
        }

        {columnName === 'pricePercentChange' &&
            <span style={{ color: getStockColorClass(stockState, themeMode) }}>
                {cellValue > 0 && '+'}
                {`${priceChangePercentFormat(cellValue)}%`}
            </span>
        }

        {columnName === 'best1Offer' &&
            <span>
                {ATO_ATC_SESSION.includes(stockState.marketStatus) ?
                    <span className={`text-${themeMode === 'dark' ? 'white' : 'black'}`}>{stockState.marketStatus.toUpperCase()}</span>
                    :
                    <>{priceFormat(cellValue, priceRatio, priceFixed, true)}</>
                }
            </span>
        }

        {columnName === 'totalValue' &&
            <span>{roundedNumber(cellValue / valueRatio, 0)}</span>
        }

        {columnName === 'issuerName' &&
            <div className="text-left">{cellValue}</div>
        }

        {columnName === 'lastTradingDate' &&
            <div className="text-left">{moment(cellValue).format(config.DATE_TIME_FORMAT.DATE)}</div>
        }
        {/* warrant underlyingStockData */}
        {columnName === 'underlyingSymbol' && stockState.underlyingSymbol &&
            <BaseStockCode warrantStock={stockState} />
        }

        {columnName === 'underlyingStockData' && stockState.underlyingSymbol &&
            <BaseStockPrice warrantStock={stockState} />
        }

        {columnName === 'differentValue' && currentRawData.differentValue &&
            <DifferenceValue value={stockState.matchedPrice} />
        }
        {/* end warrant underlyingStockData */}

        {columnName === 'exercisePrice' &&
            <span>{priceFormat(cellValue, 10000)}</span>
        }

        {columnName === 'exerciseRatio' &&
            <span>{cellValue}</span>
        }

        {columnsAverageVolume.includes(columnName) &&
            <span>{localeFormat(cellValue)}</span>
        }

        {columnsPercent.includes(columnName) &&
            <span>{priceChangePercentFormat(cellValue)}%</span>
        }
    </div>
};

export default PriceTableCell;