import React, { useState, useEffect } from "react";
import { Card, Tree, Button, Space, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import api from "../../../../../services/api";
import routes from "../../../../../routes";

import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import { toRoute } from "../../../../../services/utils";
import theme from "../../../../../themes/theme";
import AdminPermission from "../../../../../components/AdminPermission";

type CategoryItem = {
    key: string,
    title: string,
    children?: Array<CategoryItem>
}

const ArticleCategoryListPageComponent = () => {
    const [ categoryList, setCategoryList ] = useState<Array<CategoryItem>>([]);
    const fetchCategories = async (filter) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/article/category', { filter });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { categories = [] } = resp?.data;

        let categoryList = categories.map(cate => {
            return {
                title: cate.name,
                key: cate.id
            }
        });

        return categoryList;
    };

    const assignChildCategory = (categoriesArr, childCategories, parentKey) => {
        let categories = categoriesArr.map((c: CategoryItem) => {
            if (c.children) {
                c.children = assignChildCategory(c.children, childCategories, parentKey);
            } else {
                if (c.key === parentKey) {
                    return {
                        ...c,
                        children: childCategories
                    };
                }
            }

            return c;
        });
        return categories;
    };

    const deleteCategory = async (categoryId) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.delete(`plugins/article/category/${categoryId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Xóa thành công');
        initData();
    };

    const onLoadData = (treeNode): Promise<void> => {
        return new Promise(async resolve => {
            let { key } = treeNode;

            let childCategories = await fetchCategories({ parent: key });
            treeNode.children = childCategories;

            let categoryListState = assignChildCategory(categoryList, childCategories, key);

            setCategoryList(categoryListState);
            resolve();
        })
    };

    const initData = async () => {
        let categories = await fetchCategories({ parentId: null });
        setCategoryList(categories);
    }

    useEffect(() => {
        initData();
        //eslint-disable-next-line
    }, []);

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Bài viết' },
                { label: 'Danh mục' },
            ]} />

            <div className="mt-3">
                <Button type="primary">
                    <Link to={routes.ADMIN_ARTICLE_CATEGORY_ADD}>Thêm mới danh mục</Link>
                </Button>
            </div>

            <div className="mt-3">
                <Tree
                    titleRender={(node) => {
                        let { title, key } = node;

                        return <Space>
                            <span>{title}</span>

                            <Link to={{
                                pathname: toRoute(routes.ADMIN_ARTICLE_CATEGORY_EDIT, { categoryId: key }),
                            }}>
                                <EditOutlined/>
                            </Link>

                            <Popconfirm
                                title="Bạn chắc chắn muốn xóa danh mục này?"
                                onConfirm={() => deleteCategory(key)}
                                okText="Xóa"
                                cancelText="Hủy"
                            >
                                <DeleteOutlined style={{color: theme.colors.red.background}}/>
                            </Popconfirm>
                        </Space>
                    }}
                    loadData={onLoadData}
                    treeData={categoryList}
                />
            </div>
        </Card>
    );
};

const ArticleCategoryListPage = () => {
    return <AdminPermission roleName={['editor', 'moderator']}>
        <ArticleCategoryListPageComponent />
    </AdminPermission>
}

export default ArticleCategoryListPage;