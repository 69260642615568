import React from "react";
import { Card } from "antd";
import WatchListContainer from "./container";

const WatchlistScreen = () => {
    return (
        <Card className="h-full" bodyStyle={{ padding: 4 }} bordered={false}>
            <WatchListContainer/>
        </Card>
    )
};

export default WatchlistScreen;
