import React, { useEffect, useState, useRef } from "react";
import { Tabs, Row, Col, Card } from "antd";
import { useTranslation } from "react-i18next";
import * as StockDetailServices from './services';
import * as config from "../../../../../../config";

import TransactionInfo from "./components/TransactionInfo";
import EconomicInfo from "./components/EconomicInfo";
import CompanyProfile from "./components/CompanyProfile";
import Shareholders from "./components/Shareholders";
import CorporateActions from "./components/CorporateActions";
import DerivativesAnalysis from "./components/DerivativesAnalysis";
import TradingView, { TradingViewWindowRef } from "../TradingView";
import NewsList from "../News/container";
import Foreign from "./components/Foreign";
import EtfStockList from "./components/EtfStockList";

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 80;
const NEWS_FILTER_HEIGHT = 35;
const INITIAL_FINANCIALBASIC = {
    bookvalue: 0,
    date: 0,
    dividendyield: 0,
    eps: 0,
    foreignpercent: 0,
    foreignvolumeroom: 0,
    freeloadpercent: 0,
    marketcap: 0,
    pb: 0,
    pe: 0,
    roa: 0,
    roe: 0,
    sharesoutstanding: 0,
    valuepershare: 0,
    valueaveragethreemonth: 0,
    volumeaveragemonth: 0,
    volumeaveragesixmonth: 0,
    volumeaveragethreemonth: 0,
    volumeaverageyear: 0,
}

type StockDetailContainerProps = {
    id: string,
    symbol: string,
    glContainer: any,
    width: number,
    height: number
}

const StockDetailContainer = (props: StockDetailContainerProps) => {
    const { id, symbol, glContainer, width, height } = props;
    const [ stockSymbol, setStockSymbol ] = useState<string>(symbol);
    const [ financialReportsYearly, setFinancialReportsYearly ] = useState<NSApp.FinancialReportsYearly[]>([]);
    const [ financialReportsQuarterly, setFinancialReportsQuarterly ] = useState<NSApp.FinancialReportsYearly[]>([]);
    const [ financialBasic, setFinancialBasic ] = useState<NSApp.FinancialBasic>(INITIAL_FINANCIALBASIC);
    const [ companyProfile, setCompanyProfile ] = useState<NSApp.CompanyProfile>();
    const [ leaderships, setLeaderships ] = useState<NSApp.CompanyLeadership[]>([]);
    const [ relatedCompanies, setRelatedCompanies ] = useState<NSApp.RelatedCompanies>();
    const [ sameIndustryCompany, setSameIndustryCompany ] = useState<NSApp.CompanyProfile[]>([]);
    const [ shareholders, setShareholders ] = useState<NSApp.Shareholders>();
    const [ shareHoldersStructure, setShareHoldersStructure ] = useState<NSApp.ShareHoldersStructure>();
    const [ foreignData, setForeignData ] = useState([]);
    const [ stockType, setStockType ] = useState<string>('stock');

    const { t } = useTranslation();
    const tradingViewEle = useRef<TradingViewWindowRef>(null);
    const stockSymbolRef = useRef<any>();

    let layoutWidth = window.innerWidth - SIDER_WIDTH;
    let layoutHeight = window.innerHeight - HEADER_HEIGHT;

    if (glContainer) {
        layoutWidth = glContainer.width > 0 ? glContainer.width : layoutWidth;
        layoutHeight = glContainer.height > 0 ? glContainer.height : layoutHeight;
    }

    if (width > 0) {
        layoutWidth = width;
    }

    if (height > 0) {
        layoutHeight = height;
    }

    let column = 1;
    if (layoutWidth > config.RESPONSIVE_SCREEN.md) {
        column = 2;
    }
    if (layoutWidth > config.RESPONSIVE_SCREEN.lg) {
        column = 3;
    }
    if (layoutWidth > config.RESPONSIVE_SCREEN.xxl) {
        column = 4;
    }
    if (layoutWidth > config.RESPONSIVE_SCREEN.xxxl) {
        column = 6;
    }
    let childFullHeight = layoutHeight - 45;

    const changeStockSymbolState = (symbol) => {
        setStockSymbol(symbol);

        if (symbol.startsWith('vn30')) {
            setStockType('derivative');
        } else if (symbol.startsWith('c') && symbol.length > 3) {
            setStockType('warrant');
        }else if (!symbol.startsWith('c') && symbol.length > 3) {
            setStockType('fundStock');
        } else {
            setStockType('stock');
        }
    }

    const onChangeStockInfo= (symbol:string) => {
        stockSymbolRef.current = symbol.toLowerCase();
        changeStockSymbolState(symbol.toLowerCase());
    };

    const fetchFinancialReportsYearly = async () => {
        let [err, resp] = await StockDetailServices.fetchFinancialReportsYearly(stockSymbol);

        if (err) {
            return;
        }

        setFinancialReportsYearly(resp);

        if (resp.length > 0) {
            setFinancialBasic(resp[resp.length - 1]);
        } else {
            setFinancialBasic(INITIAL_FINANCIALBASIC);
        }
    }

    const fetchFinancialReportsQuarterly = async () => {
        let [err, resp] = await StockDetailServices.fetchFinancialReportsQuarter(stockSymbol);

        if (err) return;

        const slicedData = resp.slice(-8);
        setFinancialReportsQuarterly(slicedData);
    }

    const fetchCompanyProfile = async () => {
        let [err, resp] = await StockDetailServices.fetchCompanyProfile(stockSymbol);

        if (err) {
            return;
        }

        let { profile, leaderships, relatedCompanies, sameIndustryCompany, shareholders, shareHoldersStructure } = resp;

        setCompanyProfile(profile);
        setRelatedCompanies(relatedCompanies);
        setLeaderships(leaderships);
        setSameIndustryCompany(sameIndustryCompany);
        setShareholders(shareholders);
        setShareHoldersStructure(shareHoldersStructure);
    }

    const fetchForeignData = async (period) => {
        let err: any;
        let resp: any;

        if (!period || period === 'daily') {
            [err, resp] = await StockDetailServices.fetchForeign(stockSymbol, 60);
        }

        if (period === 'monthly') {
            [err, resp] = await StockDetailServices.fetchForeignMonthly(stockSymbol);
        }

        if (err) {
            return;
        }

        setForeignData(resp.reverse());
    };

    useEffect(() => {
        if (symbol) {
            changeStockSymbolState(stockSymbol);
        }

        if (stockSymbol) {
            if (stockType === 'stock') {
                fetchCompanyProfile();
                fetchFinancialReportsYearly();
                fetchFinancialReportsQuarterly();
                fetchForeignData('daily');
            }
        }

    }, [stockSymbol, stockType]); // eslint-disable-line

    return (
        <div className="plugin-wrapper p-1">
            <Tabs defaultActiveKey="transaction" size="small" tabBarStyle={{fontSize: 10}} animated={false}>
                <Tabs.TabPane tab={t('Giao dịch')} key="transaction">
                    <Row gutter={4} className="overflow-y-auto" style={{height: childFullHeight + 5}}>
                        <Col span={24*(1-1/column)}>
                            <div style={{height: layoutWidth > config.RESPONSIVE_SCREEN.lg ? childFullHeight : layoutHeight / 2}}>
                                <TradingView
                                    id={id}
                                    symbol={symbol}
                                    layoutWidth={layoutWidth / 2}
                                    layoutHeight={childFullHeight}
                                    ref={tradingViewEle}
                                    onChange={onChangeStockInfo}
                                />
                            </div>
                        </Col>
                        <Col span={24/column}>
                            <TransactionInfo
                                tradingViewEle={tradingViewEle}
                                key={stockSymbol}
                                id={id}
                                symbol={stockSymbol}
                                companyProfile={companyProfile}
                                stockType={stockType}
                                glContainer={glContainer}
                                layoutWidth={layoutWidth}
                                layoutHeight={layoutHeight}
                                onChange={onChangeStockInfo}
                            />
                        </Col>
                    </Row>
                </Tabs.TabPane>
                {stockType === 'fundStock' &&
                    <Tabs.TabPane tab={t('Danh mục cổ phiếu')} key="etfStockList">
                        <EtfStockList
                            key={stockSymbol}
                            etfCode={stockSymbol}
                            layoutHeight={layoutHeight}
                        />
                    </Tabs.TabPane>
                }
                {['stock', 'fundStock'].includes(stockType) &&
                    <>
                        <Tabs.TabPane tab={t('Tài chính')} key="economic">
                            <EconomicInfo
                                key={stockSymbol}
                                financialReportsYearly={financialReportsYearly}
                                financialReportsQuarterly={financialReportsQuarterly}
                                financialBasic={financialBasic}
                                layoutWidth={layoutWidth}
                                layoutHeight={layoutHeight}
                                symbol={stockSymbol}
                                companyProfile={companyProfile}
                                onChange={onChangeStockInfo}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('Hồ sơ')} key="profile">
                            <CompanyProfile
                                key={stockSymbol}
                                layoutWidth={layoutWidth}
                                layoutHeight={layoutHeight}
                                companyProfile={companyProfile}
                                relatedCompanies={relatedCompanies}
                                leaderships={leaderships}
                                sameIndustryCompany={sameIndustryCompany}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={t('Cổ đông')} key="shareholders">
                            <Shareholders
                                key={stockSymbol}
                                layoutWidth={layoutWidth}
                                layoutHeight={layoutHeight}
                                shareholders={shareholders}
                                shareHoldersStructure={shareHoldersStructure}
                            />
                        </Tabs.TabPane>
                    </>
                }
                {stockType === 'derivative' &&
                    <Tabs.TabPane tab={t('Phân tích')} key="derivatives_analysis">
                        <DerivativesAnalysis
                            stockSymbol={stockSymbol}
                            layoutWidth={layoutWidth}
                            layoutHeight={layoutHeight}
                        />
                    </Tabs.TabPane>
                }
                <Tabs.TabPane tab={t('Tin tức và sự kiện')} key="news_and_actions">
                    <Row gutter={[4, 4]} style={{height: layoutHeight - 45}} className="overflow-auto">
                        <Col span={(layoutWidth > config.RESPONSIVE_SCREEN.lg) && (stockType === 'stock') ? 8 : 24}>
                            <Card bodyStyle={{ padding: 4 }} title={<strong>Tin tức</strong>} style={{height: layoutHeight - 45}}>
                                <NewsList
                                    key={stockSymbol}
                                    height={layoutHeight - NEWS_FILTER_HEIGHT}
                                    stockCodes={[stockSymbol]}
                                />
                            </Card>
                        </Col>
                        {['stock', 'fundStock'].includes(stockType) &&
                            <Col span={layoutWidth > config.RESPONSIVE_SCREEN.lg ? 16 : 24}>
                                <CorporateActions
                                    key={stockSymbol}
                                    symbol={stockSymbol}
                                    height={layoutHeight - 45}
                                />
                            </Col>
                        }

                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t('Nước ngoài')} key="foreign">
                    <Foreign
                        layoutWidth={layoutWidth}
                        layoutHeight={layoutHeight - 45}
                        foreignData={foreignData}
                        fetchForeignData={fetchForeignData}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    )
};

export default StockDetailContainer;
