import React, { useState } from 'react';
import { Tabs, Input } from "antd";
import { useTranslation } from "react-i18next";
import TablePrice from './TablePrice';
import SelectWatchListInput from '../../../../components/SelectWatchListInput';
import IndustryListInput from '../../../../components/IndustryListInput';
import * as config from '../../../../../../../config';
import { useAuthState } from "../../../../../../../contexts";

const { TabPane } = Tabs;

type ComposedTablePricesProps = {
    glContainer?: any,
    dataSource: {[key: string]: NSApp.Stock[]},
    loading?: boolean,
    activeTab?: string,
    isDerivativesDashboard?: boolean;
    setIndustryStockCodes: (stockCodes: string[]) => void,
    setStockFavouritesCodes: (stockCodes: string[]) => void,
    setStockCodeSearchedName: (code: string) => void,
}

const ComposedTablePrices = (props: ComposedTablePricesProps) => {
    const {
        glContainer, dataSource,
        loading, activeTab, isDerivativesDashboard,
        setStockFavouritesCodes, setStockCodeSearchedName, setIndustryStockCodes
    } = props;
    const { authState } = useAuthState() as any;
    const { t } = useTranslation();
    const defaultTabKey = authState.selectedWatchlist ? 'watchList' : 'hose';
    const [activeKey, setActiveKey] = useState<string>(activeTab || defaultTabKey);

    if (isDerivativesDashboard) {
        return <TablePrice
            stocks={dataSource['derivatives']}
            loading={loading}
            stockType={'derivative'}
            glContainer={glContainer}
            priceRatio={1}
            volumeRatio={1}
        />
    }

    return (
        <Tabs
            defaultActiveKey={activeTab || defaultTabKey}
            size="small"
            tabBarStyle={{fontSize: 10}}
            animated={false}
            activeKey={activeKey}
            onChange={(key) => {
                if (key === 'watchList' && !dataSource['favourites'].length) {
                    return;
                }

                if (key === 'stockSearched' && !dataSource['searched'].length) {
                    return;
                }
                setActiveKey(key);
            }}
            destroyInactiveTabPane={true}
        >
            <TabPane
                key="stockSearched"
                tab={<Input
                    onChange={(e) => {
                        let value = e.target.value.toLowerCase();
                        setStockCodeSearchedName(value);
                        if (dataSource['searched'].length) {
                            setActiveKey('stockSearched');
                        }
                    }}
                    style={{ width: 200 }}
                    placeholder={t('Mã cổ phiếu')}
                />}
            >
                <TablePrice
                    stocks={dataSource['searched']}
                    glContainer={glContainer}
                    priceRatio={config.PRICE_RATIO}
                    volumeRatio={config.VOLUME_RATIO}
                />
            </TabPane>
            <TabPane
                key="watchList"
                tab={<SelectWatchListInput
                    onChangeSelectWatchListInput={(stockCodes: string[]) => {
                        setStockFavouritesCodes(stockCodes);
                        setActiveKey('watchList');
                    }}
                />}
            >
                <TablePrice
                    stocks={dataSource['favourites']}
                    loading={loading}
                    stockType={dataSource['favourites'].length && dataSource['favourites'][0].stockCode.startsWith('vn30') ? 'derivative' : 'stock' }
                    glContainer={glContainer}
                    priceRatio={dataSource['favourites'].length && dataSource['favourites'][0].stockCode.startsWith('vn30') ? 1 : config.PRICE_RATIO}
                    volumeRatio={dataSource['favourites'].length && dataSource['favourites'][0].stockCode.startsWith('vn30') ? 1 : config.VOLUME_RATIO}
                />
            </TabPane>
            <TabPane
                key="industry"
                tab={<IndustryListInput
                    onChange={(industry) => {
                        setIndustryStockCodes(industry.codeList.toLowerCase().split(','));
                    }}
                    style={{ width: 200 }}
                />}
            >
                <TablePrice
                    stocks={dataSource['industry']}
                    loading={loading}
                    glContainer={glContainer}
                    priceRatio={config.PRICE_RATIO}
                    volumeRatio={config.VOLUME_RATIO}
                />
            </TabPane>
            <TabPane key="hose" tab="HOSE">
                <TablePrice
                    stocks={dataSource['hose']}
                    loading={loading}
                    glContainer={glContainer}
                    priceRatio={config.PRICE_RATIO}
                    volumeRatio={config.VOLUME_RATIO}
                />
            </TabPane>
            <TabPane key="hnx" tab="HNX">
                <TablePrice
                    stocks={dataSource['hnx']}
                    loading={loading}
                    glContainer={glContainer}
                    priceRatio={config.PRICE_RATIO}
                    volumeRatio={config.VOLUME_RATIO}
                />
            </TabPane>
            <TabPane key="upcom" tab="UPCOM">
                <TablePrice
                    stocks={dataSource['upcom']}
                    loading={loading}
                    glContainer={glContainer}
                    priceRatio={config.PRICE_RATIO}
                    volumeRatio={config.VOLUME_RATIO}
                />
            </TabPane>
            <TabPane key="derivatives" tab={t('Phái sinh')}>
                <TablePrice
                    stocks={dataSource['derivatives']}
                    loading={loading}
                    stockType={'derivative'}
                    glContainer={glContainer}
                    priceRatio={1}
                    volumeRatio={1}
                />
            </TabPane>
            <TabPane key="warrant" tab={t('Chứng quyền')}>
                <TablePrice
                    stocks={dataSource['warrant']}
                    loading={loading}
                    stockType={'warrant'}
                    glContainer={glContainer}
                    priceRatio={config.PRICE_RATIO}
                    volumeRatio={config.VOLUME_RATIO}
                />
            </TabPane>
        </Tabs>
    )
};

export default ComposedTablePrices;