import React, { useEffect, useReducer } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ConfigProvider, Layout } from "antd";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "jquery-confirm";
import 'moment/min/locales';
import TagManager from 'react-gtm-module';

import PrivateRoute from "./components/PrivateRoute";
import HomePage from "./pages/private/Home";
import LoginPage from "./pages/public/Login";
import RegisterPage from "./pages/public/Register";
import ActivatePage from "./pages/public/UserActivate";
import UserRegisterSuccessfully from "./pages/public/UserRegisterSuccessfully";
import LockAccountPage from "./pages/public/LockAccount";
import DeleteAccountPage from "./pages/public/DeleteAccount";
import ForgotPasswordPage from "./pages/public/ForgotPassword";
import ResetPasswordPage from "./pages/public/ResetPassword";
import TradingViewPage from "./pages/private/client/TradingView";
import PrivateAdminPage from "./pages/private/admin";
import PolicyEn from "./pages/public/policy/en";
import PolicyVi from "./pages/public/policy/vi";
import UpgradePage from "./pages/private/upgrade";
import UpgradePublic from "./pages/public/upgrade";
import PaymentInfo from "./pages/private/upgrade/Payment/Info";

import routes from "./routes";
import { AuthContext } from "./contexts";
import StocksProvider from "./contexts/StocksProvider";
import StocksForeignerProvider from "./contexts/StocksForeignerProvider";
import MainIndexProvider from "./contexts/MainIndexProvider";
import useConfig from "./contexts/useConfig";
import themeModeGlobalState from "./contexts/ThemeMode";
import animateGlobalState from "./contexts/Animate";
import NotificationProvider from "./contexts/NotificationProvider";

import localStorage from "./services/local-storage";
import reducer from "./reducer";
import api from "./services/api";

import enUS from 'antd/es/locale/en_US';
import viVN from 'antd/es/locale/vi_VN';
import zhCN from 'antd/es/locale/zh_CN';

const themes = {
    dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

const App = () => {
    const initialAuthState = {
        currentUser: null,
        watchLists: [],
        selectedWatchlist: null,
    };

    const [authState, setAuthState] = useReducer(reducer, initialAuthState);
    const [themeMode, setThemeMode] = themeModeGlobalState();
    const [, setAnimate] = animateGlobalState();
    const [, setConfig] = useConfig();
    const { i18n } = useTranslation();

    const locales = {
        cn: { moment: 'zh-cn', antd: zhCN },
        en: { moment: 'en', antd: enUS },
        vi: { moment: 'vi', antd: viVN },
    };

    moment.locale(locales[i18n.language].moment);

    const initConfig = async () => {
        let [error, response]: any = await api.get('config/parameters');

        if (error) {
            return;
        }

        if (response) {
            setConfig(response.data);
        }
    };

    const initThemeMode = async () => {
        let darkModeFromLocalStorage = await localStorage.get('themeMode');

        if (
            darkModeFromLocalStorage == null ||
            darkModeFromLocalStorage === undefined
        ) {
            setThemeMode('dark');
        } else {
            setThemeMode(darkModeFromLocalStorage);
        }
    };

    const initAnimate = () => {
        let animateFromLocalStorage = localStorage.get('animate');

        if (
            animateFromLocalStorage == null ||
            animateFromLocalStorage === undefined
        ) {
            setAnimate(true);
        } else {
            setAnimate(animateFromLocalStorage);
        }
    };

    const initSelectedWatchlist = () => {
        const selectedWatchlistFromLocalStorage = localStorage.get('selectedWatchlist');
        const selectedWatchlist = selectedWatchlistFromLocalStorage ? selectedWatchlistFromLocalStorage : null;
        setAuthState({ selectedWatchlist });
    };

    const initLanguage = () => {
        const locale = localStorage.get('siteLanguage');
        if (locale) {
            i18n.changeLanguage(locale);
        }
    }

    useEffectOnce(() => {
        TagManager.initialize({gtmId: 'GTM-T7KS3424'});
        initConfig();
        initThemeMode();
        initAnimate();
        initSelectedWatchlist();
        initLanguage();
    });

    return (
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={themeMode}>
            <ConfigProvider componentSize="small" locale={locales[i18n.language].antd}>
                <Layout className="h-full">
                    <Router>
                        <AuthContext.Provider value={{authState, setAuthState}}>
                            <StocksProvider>
                                <StocksForeignerProvider>
                                    <MainIndexProvider>
                                        <NotificationProvider>
                                            <Switch>
                                                <Route component={LoginPage} path={routes.LOGIN} />
                                                <Route component={RegisterPage} path={routes.REGISTER} />
                                                <Route component={UserRegisterSuccessfully} path={routes.REGISTER_SUCCESSFULLY} />
                                                <Route component={ForgotPasswordPage} path={routes.FORGOT_PASSWORD} />
                                                <Route component={ResetPasswordPage} path={routes.RESET_PASSWORD} />
                                                <Route component={ActivatePage} path={routes.USER_ACTIVE} />
                                                <Route component={LockAccountPage} path={routes.USER_LOCK_ACCOUNT} />
                                                <Route component={DeleteAccountPage} path={routes.USER_DELETE_ACCOUNT} />
                                                <Route component={PolicyEn} path={routes.POLICY_EN} />
                                                <Route component={PolicyVi} path={routes.POLICY_VI} />
                                                <Route component={UpgradePublic} path={routes.UPGRADE_PLAN} />
                                                {/* <Route component={PaymentInfo} path={routes.UPGRADE_PAYMENT_INFO} /> */}
                                                <Route component={UpgradePage} path={routes.UPGRADE} />

                                                <PrivateRoute component={TradingViewPage} path={routes.TRADINGVIEW} />
                                                <PrivateRoute component={PrivateAdminPage} path={routes.ADMIN_DASHBOARD} adminRequired={true} />
                                                {/* <PrivateRoute component={UpgradePage} path={routes.UPGRADE} /> */}
                                                <PrivateRoute component={HomePage} path={routes.HOME} />
                                            </Switch>
                                        </NotificationProvider>
                                    </MainIndexProvider>
                                </StocksForeignerProvider>
                            </StocksProvider>
                        </AuthContext.Provider>
                    </Router>
                </Layout>
            </ConfigProvider>
        </ThemeSwitcherProvider>
    );
};

export default App;
