import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

export const NotificationContext = createContext<any>(null);

const NotificationProvider = ({ children }: { children: any }) => {
    const [state, setState] = useState({});

    return (
        <NotificationContext.Provider value={[state, setState]}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;