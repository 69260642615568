import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { StockContext } from '../../../../../contexts/StocksProvider';
import { priceFormat } from "../../../../../services/stock-price";
import { getCellColorAnimation } from '../../Dashboard/plugins/TablePrice/services';
import themeModeGlobalState from "../../../../../contexts/ThemeMode";

type BaseStockPriceProps = {
    warrantStock: NSApp.Stock;
}

const DifferenceValue = (props: BaseStockPriceProps) => {
    const { warrantStock } = props;
    const [themeMode,] = themeModeGlobalState();

    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][warrantStock.underlyingSymbol.toLowerCase()],
    );

    // const differentValue = warrantStock.matchedPrice - stockState.matchedPrice
    let { animation, color }: any = getCellColorAnimation('matchedPrice', stockState.matchedPrice, stockState, themeMode);

    return (
        <div
            className={animation}
            style={{
                color: color,
                display: "block"
            }}
        >
            <span>
                {priceFormat(stockState.matchedPrice, 1000)}
            </span>
        </div>
    )
}

export default DifferenceValue;
