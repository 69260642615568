import i18n from '../i18n';

const getProtocolAndHostName = () => {
    const {href} = window.location;
    const url = new URL(href);
    const {protocol, hostname} = url;
    return {protocol, hostname}
};

const getApiBaseUrl = () => {
    let {protocol, hostname} = getProtocolAndHostName();

    if (hostname === "fstock.vn") {
        return 'https://api.fstock.vn/';
    }

    if (hostname === "app-dev.fstock.vn") {
        return 'https://api-development.fstock.vn/';
    }

    if (hostname === "app.fstock.vn") {
        return 'https://api.fstock.vn/';
    }

    return `${protocol}//${hostname}:3000/`;
};

const getSocketBaseUrl = () => {
    let {protocol, hostname} = getProtocolAndHostName();

    if (hostname === "fstock.vn") {
        return 'https://socket.fstock.vn';
    }

    if (hostname === "app-dev.fstock.vn") {
        return 'https://socket-development.fstock.vn';
    }

    if (hostname === "app.fstock.vn") {
        return 'https://socket.fstock.vn';
    }

    return `${protocol}//${hostname}:3001/`;
};

const getWebBaseUrl = () => {
    let {protocol, hostname} = getProtocolAndHostName();
    return `${protocol}//${hostname}`;
}

export const API_BASE_URL = getApiBaseUrl();
export const SOCKET_BASE_URL = getSocketBaseUrl();
export const WEB_BASE_URL = getWebBaseUrl();
export const PAGE_SIZE = 10;
export const PAGE_SIZE_TABLE_PRICE = 50;

export const DEFAULT_LAYOUT_CONFIG = {
    type: "row",
    settings: {
        showPopoutIcon: true,
        showCloseIcon: true
    },
    dimensions: {
        headerHeight : 25
    },
    content: [],
};

export const NEW_RECOMMENDATION_NOTIFICATION_TYPE = 'new-recommendation';
export const NEW_NEWS_NOTIFICATION_TYPE = 'new-news';
export const NEW_PENDING_RECOMMENDATION_NOTIFICATION_TYPE = 'new-pending-recommendation';
export const DENY_RECOMMENDATION_NOTIFICATION_TYPE = 'deny-pending-recommendation';
export const NEW_ARTICLE_NOTIFICATION_TYPE = 'new-article';
export const NEW_NOTIFICATION_INFO = 'new-notification-info';

export const PLATFORM_APP = "app";
export const PLATFORM_EMAIL = "email";
export const PLATFORM_SMS = "sms";

export const NOTIFICATION_SETTING_TYPES = [
    {
        type: NEW_RECOMMENDATION_NOTIFICATION_TYPE,
        label: `${i18n.t('Khuyến nghị')}`
    },
    {
        type: NEW_NEWS_NOTIFICATION_TYPE,
        label: `${i18n.t('Tin tức')}`
    }
];

export const PLATFORMS_SETTING = [
    {
        type: PLATFORM_EMAIL,
        label: "Email"
    }
];

export const RESPONSIVE_SCREEN = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
    xxxl: 1920,
};

export const HEADER_HEIGHT = 60;
export const VOLUME_RATIO = 1;
export const PRICE_RATIO = 1000;
export const VALUE_RATIO = 1000000;

export const EXCHANGES = [
    {
        mainIndexId: 'vnindex',
        exchange: 'hose'
    },
    {
        mainIndexId: 'hnxindex',
        exchange: 'hnx'
    },
    {
        mainIndexId: 'upcomindex',
        exchange: 'upcom'
    },
]

export const DEFAULT_STOCK_CODE = "aaa"

export const DERIVATIVE_DEFAULT_LIST = ['vn30f1m', 'vn30f2m', 'vn30f1q', 'vn30f2q'];

export const MAIN_INDEX = [
    { indexId: "vnindex", label: "VNINDEX" },
    { indexId: "hnxindex", label: "HNX" },
    { indexId: "upcomindex", label: "UPCOM" },
    { indexId: "vn30", label: "VN30" },
    { indexId: "hnx30", label: "HNX30" },
    { indexId: "vnxall", label: "VNXALL" },
];

export const LOCALE_NUMBER_FORMAT = 'en-GB';
export const DATE_TIME_FORMAT = {
    DATE: 'DD/MM/YYYY',
    MONTH: 'MM/YYYY',
    QUARTER: 'Q/YYYY',
    YEAR: 'YYYY',
};

export const STOCK_REALTIME_FIELDS = [
    'marketStatus',
    'best1Bid',
    'best1BidVol',
    'best2Bid',
    'best2BidVol',
    'best3Bid',
    'best3BidVol',
    'best4Bid',
    'best4BidVol',
    'best5Bid',
    'best5BidVol',
    'best6Bid',
    'best6BidVol',
    'best7Bid',
    'best7BidVol',
    'best8Bid',
    'best8BidVol',
    'best9Bid',
    'best9BidVol',
    'best10Bid',
    'best10BidVol',

    'best1Offer',
    'best1OfferVol',
    'best2Offer',
    'best2OfferVol',
    'best3Offer',
    'best3OfferVol',
    'best4Offer',
    'best4OfferVol',
    'best5Offer',
    'best5OfferVol',
    'best6Offer',
    'best6OfferVol',
    'best7Offer',
    'best7OfferVol',
    'best8Offer',
    'best8OfferVol',
    'best9Offer',
    'best9OfferVol',
    'best10Offer',
    'best10OfferVol',

    'matchedPrice',
    'volume',
    'priceChange',
    'pricePercentChange',
    'totalVolume',
    'totalValue',
    'highestPrice',
    'lowestPrice',
    'averagePrice',
    'foreignBuyVolume',
    'foreignSellVolume',
    'remainForeignRoom',
    'ceilingPrice',
    'floorPrice',
    'referencePrice',
    'openPrice',
    'closePrice',
    'tradingStatus'
];

export const vnPayResponseCode: any = {
    "00": i18n.t("Giao dịch thành công"),
    "05": i18n.t("Tài khoản của quý khách không đủ số dư để thực hiện giao dịch."),
    "06": i18n.t("Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch."),
    "07": i18n.t("Trừ tiền thành công. Giao dịch bị nghi ngờ (liên quan tới lừa đảo, giao dịch bất thường)."),
    "09": i18n.t("Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng."),
    "10": i18n.t("Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần"),
    "11": i18n.t("Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch."),
    "12": i18n.t("Thẻ/Tài khoản của khách hàng bị khóa."),
    "13": i18n.t("Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch."),
    "24": i18n.t("Khách hàng hủy giao dịch"),
    "51": i18n.t("Tài khoản của quý khách không đủ số dư để thực hiện giao dịch."),
    "65": i18n.t("Tài khoản của Quý khách đã vượt quá hạn mức giao dịch trong ngày."),
    "75": i18n.t("Ngân hàng thanh toán đang bảo trì."),
    "79": i18n.t("KH nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch"),
    "99": i18n.t("Không rõ nguyên nhân")
}

export const vnPayTransactionStatus: any = {
    "00": i18n.t("Giao dịch thành công"),
    "01": i18n.t("Giao dịch chưa hoàn tất"),
    "02": i18n.t("Giao dịch bị lỗi"),
    "05": i18n.t("VNPAY đang xử lý giao dịch này (GD hoàn tiền)"),
    "04": i18n.t("Giao dịch đảo (Khách hàng đã bị trừ tiền tại Ngân hàng nhưng GD chưa thành công ở VNPAY)"),
    "07": i18n.t("Giao dịch bị nghi ngờ gian lận"),
    "06": i18n.t("VNPAY đã gửi yêu cầu hoàn tiền sang Ngân hàng (GD hoàn tiền)"),
    "09": i18n.t("GD Hoàn trả bị từ chối"),
    "10": i18n.t("Đã giao hàng"),
    "20": i18n.t("Giao dịch đã thanh quyết toán cho merchant")
}