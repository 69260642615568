import React from "react";
import StockDetailModal from "./StockDetailModal";
import useStockDetailModalState from "../../contexts/StockDetailModal";


const StockDetailModalWrapper = () => {

    const [stockDetailModalState, setStockDetailModalState] = useStockDetailModalState();

    return (
        <StockDetailModal
            stockCode={stockDetailModalState.stockCode}
            isVisible={stockDetailModalState.isVisible}
            setIsVisible={(isVisible) => {
                setStockDetailModalState({
                    stockCode: stockDetailModalState.stockCode,
                    isVisible: isVisible,
                });
            }}
        />
    )
}

export default StockDetailModalWrapper;