import React from "react";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";

const NormalFooter = () => {
    const { t } = useTranslation();

    return (
        <Layout.Footer className="text-center py-2">
            <strong>FSTOCK</strong> ©2021 {t('Created by')} <strong>FCAPITAL</strong>
        </Layout.Footer>
    )
};

export default NormalFooter;
