import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Input, Typography, Divider, Button, Checkbox, Alert, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAuthState } from "../../../../contexts";
import api from "../../../../services/api";
import routes from "../../../../routes";
import Loading from "../../../../components/Loading";

const PaymentInfo = () => {
    const { planId, period, price } = useParams<any>();
    const [checkbox, setCheckbox] = useState(true);
    const [info, setInfo]: any = useState();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { t } = useTranslation();
    const { authState } = useAuthState() as any;
    const { currentUser } = authState;

    const fetchPaymentInfo = async () => {
        let [err, resp]: any = await api.post(`/payment/info-purchase`, {
            planId: planId,
            period: period,
            price: price
        });

        if (err) {
            if (err?.response?.data?.message === "Bạn không thể mua dịch vụ này! Dịch vụ hiện tại đã bao gồm các tính năng trên." ) {
                message.config({ maxCount: 1 })
                message.open({
                    type: 'info',
                    content: t('Không thể mua gói dịch vụ thấp hơn gói bạn đang sử dụng. Bạn chỉ mua được gói này khi gói hiện tại hết thời gian sử dụng'),
                    duration: 5,
                    style: { fontSize: 16 }
                })
                window.history.back();
                return;
            }
            message.error(err?.response?.data?.message);
            return;
        }
        setInfo(resp.data);
    };

    const onFinish = async (formValues: any) => {
        let [err, resp]: any = await api.post("/payment/create-purchase", {
            email: formValues?.email,
            fullname: formValues?.fullname,
            phone: formValues?.phone,
            planId: info?.plan.id,
            period: info?.plan['plan_prices.period'],
            price: info?.plan['plan_prices.price'],
        });

        if (err) {
            message.error(err?.response?.data?.message);
            setErrorMessage(err?.response?.data?.message);
            return;
        }
        window.location.replace(resp.data as string);
    };

    useEffect(() => {
        fetchPaymentInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planId, period, price]);

    if (!info) {
        return <Loading />;
    }

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat("vi-VN", { style: "currency", currency: "VND" }).format(amount);
    }

    return (
        <div className="max-w-screen-xl mx-auto p-1">
            <Link to={routes.UPGRADE_PLAN}>
                <div className="inline-block cursor-pointer text-black text-sm my-2"><LeftOutlined /> {t('Quay lại')}</div>
            </Link>
            <Form
                size="large"
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    account: currentUser?.email,
                    fullname: currentUser?.fullname,
                    email: currentUser?.email,
                    phone: currentUser?.phone,
                    privacy_policy: true,
                }}
            >
                <Row gutter={[4, 4]}>
                    <Col xs={24} lg={14} xl={16}>
                        <Card bordered={false}>
                            <Typography.Title level={4}>{t('THÔNG TIN')}</Typography.Title>
                            {errorMessage &&
                                <Alert
                                    message={errorMessage}
                                    type="error"
                                    closable
                                />
                            }

                            <Row gutter={[16, 16]} className="mt-5">
                                <Col xs={24} sm={12}>
                                    <Form.Item name="account" label={<span className="text-sm">{t('Tài khoản')}</span>}>
                                        <Input disabled />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label={<span className="text-sm">{t('Địa chỉ email')}</span>}
                                        rules={[
                                            {
                                                type: 'email',
                                                required: true,
                                                message: t('Email không đúng định dạng')
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item name="fullname" label={<span className="text-sm">{t('Họ và tên')}</span>}>
                                        <Input disabled={!!currentUser?.fullname} />
                                    </Form.Item>
                                    <Form.Item name="phone" label={<span className="text-sm">{t('Số điện thoại')}</span>}>
                                        <Input disabled={!!currentUser?.phone}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} lg={10} xl={8}>
                        <Card bordered={false}>
                            <Typography.Title level={4}>{t('THANH TOÁN')}</Typography.Title>
                            <div className="flex justify-between text-base">
                                <div>{t('Gói dịch vụ')}</div>
                                <div>{info.plan.name}</div>
                            </div>
                            <div className="flex justify-between text-base">
                                <div>{t('Kỳ hạn')}</div>
                                <div>{info.plan['plan_prices.period']} {t('tháng')}</div>
                            </div>
                            <Divider className="my-2" />
                            <div className="flex justify-between text-base">
                                <div>{t('Tổng tiền thanh toán')}</div>
                                <div className="font-bold">{formatCurrency(info.plan['plan_prices.price'])}</div>
                            </div>
                        </Card>
                        <Form.Item
                            name="privacy_policy"
                            valuePropName="checked"
                            rules={[
                                {
                                    validator: (_, value) =>
                                      value ? Promise.resolve() : Promise.reject(new Error(t('Vui lòng chấp nhận điều khoản'))),
                                },
                            ]}
                        >
                            <Checkbox value={checkbox} onChange={e => setCheckbox(e.target.checked)}>
                                <span className="text-sm">{t('Tôi đã đọc và đồng ý với các')}</span>
                                <Button
                                    className="px-1"
                                    type="link"
                                    href="https://fstock.vn/pages/payment-policy.html"
                                    target="_blank"
                                >
                                    {t('điều khoản sử dụng')}
                                </Button>
                            </Checkbox>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                block
                                size="large"
                                type="primary"
                                htmlType="submit"
                                className="text-sm font-bold"
                                disabled={!checkbox}
                            >
                                {t('Thanh toán')}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default PaymentInfo;
