import React from 'react';
import { Switch, Route } from "react-router-dom";

import UploadDataList from './List';
import routes from "../../../../routes";


const PlanDashboard = () => {
    return <div>
        <Switch>
            <Route path={routes.ADMIN_UPLOAD_DATA_LIST} component={UploadDataList}/>
        </Switch>
    </div>
}

export default PlanDashboard;