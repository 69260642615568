import React, { useState, useEffect } from "react";
import { message } from 'antd';
import PlanForm from "./Form";
import api from "../../../../services/api";
import MyBreadcrumb from "../../../../components/MyBreadcrumb";
import { useParams, useHistory } from 'react-router-dom';
import Loading from "../../../../components/Loading";
import _ from "lodash";
import routes from "../../../../routes";
import AdminPermission from "../../../../components/AdminPermission";

const EditPlanFormComponent = () => {
    const [plan, setPlan]: any = useState();
    const { planId } = useParams<any>();
    const history = useHistory();

    const fetchPlan = async () => {
        const [err, resp]: any = await api.get(`/plan/${planId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        setPlan(resp.data);
    };

    const onFinish = async (formValues: any) => {
        let [err, resp]: any = await api.put(`/plan/update/${planId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (resp.data) {
            message.success("Cập nhật thành công");
            history.replace(routes.ADMIN_PLAN_LIST);
        }
    };

    const deletePlan = async () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let [err, resp]: any = await api.delete(`/plan/delete/${planId}`);
        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (resp.data) {
            message.success("Xoá thành công");
            history.replace(routes.ADMIN_PLAN_LIST);
        }
    }

    useEffect(() => {
        fetchPlan();
        // eslint-disable-next-line
    }, []);

    if (!plan) {
        return <Loading/>;
    }

    plan.featureIds = _(plan.plan_features).map((i) => i.featureId).value();
    plan.infoPlans = plan.plan_prices

    return (
        <div>
            <MyBreadcrumb
                items={[
                    { label: "Quản lý gói dịch vụ" },
                    { label: "Chỉnh sửa" },
                ]}
            />
            <PlanForm mode={"edit"} onFinish={onFinish} initialValues={plan} onDelete={deletePlan}/>
        </div>
    );
};

const EditPlanForm = () => {
    return <AdminPermission roleName={[]}>
        <EditPlanFormComponent />
    </AdminPermission>
}

export default EditPlanForm;
