import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../../../../routes";
import NewsListPage from "./List";
import NewsAddPage from "./Add";
import NewsDetailPage from "./Detail";
import NewsEditPage from "./Edit";

import NewsProviderListPage from "./Provider/List";
import NewsProviderAddPage from "./Provider/Add";
import NewsProviderEditPage from "./Provider/Edit";

import NewsCategoryListPage from "./Category/List";
import NewsCategoryAddPage from "./Category/Add";
import NewsCategoryEditPage from "./Category/Edit";

const NewsDashboard = () => {
    return (
        <Switch>
            <Route path={routes.ADMIN_NEWS_PROVIDER_LIST} component={NewsProviderListPage} />
            <Route path={routes.ADMIN_NEWS_PROVIDER_ADD} component={NewsProviderAddPage} />
            <Route path={routes.ADMIN_NEWS_PROVIDER_EDIT} component={NewsProviderEditPage} />

            <Route path={routes.ADMIN_NEWS_CATEGORY_LIST} component={NewsCategoryListPage} />
            <Route path={routes.ADMIN_NEWS_CATEGORY_ADD} component={NewsCategoryAddPage} />
            <Route path={routes.ADMIN_NEWS_CATEGORY_EDIT} component={NewsCategoryEditPage} />

            <Route path={routes.ADMIN_NEWS_LIST} component={NewsListPage}/>
            <Route path={routes.ADMIN_NEWS_ADD} component={NewsAddPage} />
            <Route path={routes.ADMIN_NEWS_EDIT} component={NewsEditPage} />
            <Route path={routes.ADMIN_NEWS_DETAIL} component={NewsDetailPage} />

            <Route path={routes.ADMIN_NEWS}>
                <Redirect to={routes.ADMIN_NEWS_LIST} />
            </Route>
        </Switch>
    )
}

export default NewsDashboard;