import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from "react-i18next";

import { localeFormat, priceFormat, priceChangePercentFormat } from "../../../../../../../services/stock-price";
import { StockContext } from '../../../../../../../contexts/StocksProvider';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import { getStockColorClass } from "../../../../../../../services/utils";

import NameWithChartIcon from "../../../../components/NameWithChartIcon";

const CellValueHasColor = ({ value, stock, columnKey }) => {
    const [themeMode,] = themeModeGlobalState();
    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][stock.stockCode],
    );

    const _stockData = stockState ? stockState : stock;

    if (columnKey === 'stockCode') {
        return <NameWithChartIcon
            color={getStockColorClass(_stockData, themeMode)}
            symbol={_stockData.stockCode.toUpperCase()}
        />
    }

    if (columnKey === 'matchedPrice') {
        return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>
            {priceFormat(_stockData.matchedPrice, 1000, 2, true)}
        </span>
    }

    if (columnKey === 'pricePercentChange') {
        return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>
            {_stockData.priceChange > 0 && '+'}
            {`${priceChangePercentFormat(_stockData.pricePercentChange)}%`}
        </span>
    }

    return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>{localeFormat(value)}</span>
};

const BreakoutTable = (props) => {
    const { stocks, isLoading, layoutHeight, criteriaExpanded } = props;
    const _criteriaHeight = criteriaExpanded ? 421 : 144;
    const tableContentHeight = layoutHeight - _criteriaHeight > 300 ? layoutHeight - _criteriaHeight : 300;
    const { t } = useTranslation();

    const renderPriceFormat = (value) => {
        return <span className="font-bold">{priceFormat(value, 1000, 2, true)}</span>;
    }

    const renderNormalValue = (value) => {
        return <span className="font-bold">{localeFormat(value)}</span>;
    }

    const renderPercentFormat = (value) => {
        return <span className="font-bold">{priceChangePercentFormat(value)}%</span>;
    }

    const renderColorText = (value, stock, columnKey) => {
        return <CellValueHasColor value={value} stock={stock} columnKey={columnKey} />
    }

    let columns: ColumnsType<NSApp.StockBreakout> = [
        {
            width: 100,
            title: t('Mã CK'),
            key: 'stockCode',
            dataIndex: 'stockCode',
            showSorterTooltip: false,
            fixed: 'left',
            render: (value, stock) => renderColorText(value, stock, 'stockCode'),
        },
        {
            width: 100,
            title: t('Giá'),
            key: 'matchedPrice',
            dataIndex: 'matchedPrice',
            showSorterTooltip: false,
            align: 'right',
            render: (value, stock) => renderColorText(value, stock, 'matchedPrice'),
        },
        {
            width: 100,
            title: '+/-(%)',
            key: 'pricePercentChange',
            dataIndex: 'pricePercentChange',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.pricePercentChange - b.pricePercentChange,
            showSorterTooltip: false,
            align: 'right',
            render: (value, stock) => renderColorText(value, stock, 'pricePercentChange'),
        },
        {
            width: 100,
            title: `+/-(%) ${t('KLTB5N')}`,
            key: 'percentChangeTotalVolume5Days',
            dataIndex: 'percentChangeTotalVolume5Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangeTotalVolume5Days - b.percentChangeTotalVolume5Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('KLTB10N')}`,
            key: 'percentChangeTotalVolume10Days',
            dataIndex: 'percentChangeTotalVolume10Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangeTotalVolume10Days - b.percentChangeTotalVolume10Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('KLTB30N')}`,
            key: 'percentChangeTotalVolume30Days',
            dataIndex: 'percentChangeTotalVolume30Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangeTotalVolume30Days - b.percentChangeTotalVolume30Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('KLTB60N')}`,
            key: 'percentChangeTotalVolume60Days',
            dataIndex: 'percentChangeTotalVolume60Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangeTotalVolume60Days - b.percentChangeTotalVolume60Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('KLTB90N')}`,
            key: 'percentChangeTotalVolume90Days',
            dataIndex: 'percentChangeTotalVolume90Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangeTotalVolume90Days - b.percentChangeTotalVolume90Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('GTB5N')}`,
            key: 'percentChangePrice5Days',
            dataIndex: 'percentChangePrice5Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangePrice5Days - b.percentChangePrice5Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('GTB10N')}`,
            key: 'percentChangePrice10Days',
            dataIndex: 'percentChangePrice10Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangePrice10Days - b.percentChangePrice10Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('GTB30N')}`,
            key: 'percentChangePrice30Days',
            dataIndex: 'percentChangePrice30Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangePrice30Days - b.percentChangePrice30Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('GTB60N')}`,
            key: 'percentChangePrice60Days',
            dataIndex: 'percentChangePrice60Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangePrice60Days - b.percentChangePrice60Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: `+/-(%) ${t('GTB90N')}`,
            key: 'percentChangePrice90Days',
            dataIndex: 'percentChangePrice90Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.percentChangePrice90Days - b.percentChangePrice90Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 100,
            title: t('KLTB5N'),
            key: 'averageTotalVolume5Days',
            dataIndex: 'averageTotalVolume5Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averageTotalVolume5Days - b.averageTotalVolume5Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('KLTB10N'),
            key: 'averageTotalVolume10Days',
            dataIndex: 'averageTotalVolume10Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averageTotalVolume10Days - b.averageTotalVolume10Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('KLTB30N'),
            key: 'averageTotalVolume30Days',
            dataIndex: 'averageTotalVolume30Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averageTotalVolume30Days - b.averageTotalVolume30Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('KLTB60N'),
            key: 'averageTotalVolume60Days',
            dataIndex: 'averageTotalVolume60Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averageTotalVolume60Days - b.averageTotalVolume60Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('KLTB90N'),
            key: 'averageTotalVolume90Days',
            dataIndex: 'averageTotalVolume90Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averageTotalVolume90Days - b.averageTotalVolume90Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('GTB5N'),
            key: 'averagePrice5Days',
            dataIndex: 'averagePrice5Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averagePrice5Days - b.averagePrice5Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPriceFormat(value),
        },
        {
            width: 100,
            title: t('GTB10N'),
            key: 'averagePrice10Days',
            dataIndex: 'averagePrice10Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averagePrice10Days - b.averagePrice10Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPriceFormat(value),
        },
        {
            width: 100,
            title: t('GTB30N'),
            key: 'averagePrice30Days',
            dataIndex: 'averagePrice30Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averagePrice30Days - b.averagePrice30Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPriceFormat(value),
        },
        {
            width: 100,
            title: t('GTB60N'),
            key: 'averagePrice60Days',
            dataIndex: 'averagePrice60Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averagePrice60Days - b.averagePrice60Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPriceFormat(value),
        },
        {
            width: 100,
            title: t('GTB90N'),
            key: 'averagePrice90Days',
            dataIndex: 'averagePrice90Days',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.averagePrice90Days - b.averagePrice90Days,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPriceFormat(value),
        },
        {
            width: 100,
            title: t('EPS'),
            key: 'eps',
            dataIndex: 'eps',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.eps - b.eps,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('BV'),
            key: 'bookvalue',
            dataIndex: 'bookvalue',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.bookvalue - b.bookvalue,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => <span className="font-bold">{localeFormat(value / 1000)}</span>,
        },
        {
            width: 100,
            title: t('VPS'),
            key: 'valuepershare',
            dataIndex: 'valuepershare',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.valuepershare - b.valuepershare,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => <span className="font-bold">{localeFormat(value / 1000)}</span>,
        },
        {
            width: 100,
            title: t('P/E'),
            key: 'pe',
            dataIndex: 'pe',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.pe - b.pe,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('P/B'),
            key: 'pb',
            dataIndex: 'pb',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.pb - b.pb,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('ROA'),
            key: 'roarecent',
            dataIndex: 'roarecent',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.roarecent - b.roarecent,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 100,
            title: t('ROE'),
            key: 'roerecent',
            dataIndex: 'roerecent',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.roerecent - b.roerecent,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 100,
            title: t('Tỷ suất cổ tức'),
            key: 'dividendyield',
            dataIndex: 'dividendyield',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.dividendyield - b.dividendyield,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('Vốn hoá'),
            key: 'marketcap',
            dataIndex: 'marketcap',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.marketcap - b.marketcap,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => <span className="font-bold">{localeFormat(value / (1000 * 1000 * 1000))}</span>,
        },
        {
            width: 100,
            title: t('SL CPLH'),
            key: 'sharesoutstanding',
            dataIndex: 'sharesoutstanding',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.sharesoutstanding - b.sharesoutstanding,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('SHNN'),
            key: 'foreignpercent',
            dataIndex: 'foreignpercent',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.foreignpercent - b.foreignpercent,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 100,
            title: t('Free-float'),
            key: 'freefloatpercent',
            dataIndex: 'freefloatpercent',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.freefloatpercent - b.freefloatpercent,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('Room NN còn lại'),
            key: 'foreignvolumeroom',
            dataIndex: 'foreignvolumeroom',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.foreignvolumeroom - b.foreignvolumeroom,
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
    ];

    return <div>
        <Table
            {...props.tableProps}
            bordered
            className="breakout-stock-table"
            priceRatio={props.priceRatio}
            volumeRatio={props.volumeRatio}
            scroll={{ y: tableContentHeight }}
            rowKey={(record: NSApp.StockBreakout) => record.stockCode}
            columns={columns}
            dataSource={stocks}
            pagination={false}
            layoutHeight={tableContentHeight}
            loading={isLoading}
            footer={() => <small className="text-one-line">{t('Giá')} x 1,000 {t('VNĐ')}. {t('Khối lượng')} x 1 {t('CP')}. {t('Vốn hoá')} x 1 {t('Tỷ VNĐ')}</small>}
        />
    </div>
};

export default BreakoutTable;