import api from '../../../../../../../services/api';
import * as config from "../../../../../../../config";

export const fetchForeignerTradingData = async (exchange, sortBy) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get('stock/foreignerTrading', { exchange, sortBy });

    let stocks = resp?.data?.foreignerTradingTopStocks;

    return stocks;
};

export const fetchForeignTradingTopStocks = async (exchange, orderBy) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get('plugins/foreign-data/top25', { orderBy, exchange });

    let stocks = resp?.data;

    return stocks;
};

export const fetchHistoryExchanges = async (exchange: string, time: string) => {
    let [err, resp]: any = await api.get(`plugins/foreign-data/${exchange}/${time}`);

    if (err) {
        return;
    }

    return resp;
};

export const fetchHistoryStocks = async (exchange: string, sortBy: string, startDate: string, endDate: string) => {
    let [err, resp]: any = await api.get('plugins/foreign-data/foreign-trade', {
        exchange,
        sortBy,
        startDate,
        endDate,
    });

    if (err) {
        return;
    }

    return resp;
};

export const fetchForeignerTradingByTime = async (stockSymbol) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get('stock/stockForeigner', { stockSymbol });

    let stock = resp?.data?.stockForeignerTrading?.records;
    return stock;
};

export const fetchIndice = async (exchange) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get(`plugins/main-index/${exchange}`);

    let indice = resp?.data?.today;
    return indice;
};

export const foreignValueFormat = (value: number, type: string) => {
    if (type === 'value') {
        if (Number(value) === 0) return Number(value);
        if (Number(value) !== 0) return (Number(value)/1000000000).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 });
    } else {
        return Number(value).toLocaleString(config.LOCALE_NUMBER_FORMAT);
    }
};

export const fetchIndiceDataByTime = async (indexID) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get(`plugins/foreign-data/${indexID}`);
    return resp?.data;
}
