import React, { ReactNode, useState } from "react";
import { Collapse, Row, Col, Card, List, Select, Button, InputNumber, Tag, Typography, Space, Modal } from 'antd';
import { MinusCircleFilled, LockOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import _ from 'lodash';

import { DEFAULT_BREAKOUT_FILTERS, FSTOCK_BREAKOUT_FILTERS_ADVANCED, FSTOCK_BREAKOUT_FILTERS_PRO } from "../utils";
import theme from "../../../../../../../themes/theme";
import { useAuthState } from "../../../../../../../contexts";
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

const { Text } = Typography;
const PLAN_BASIC_KEY = 'basic';

type CriteriaGroup = {
    key: string;
    label: string | ReactNode;
    isDefaultCriteria?: boolean;
    options: {
        value: string;
        label: string | ReactNode;
        disabled?: boolean;
    }[];
};

const FilterForm = (props) => {
    const { criteria, selectedField, disabled, setSelectedField, onSubmit } = props;
    const [operator, setOperator] = useState('gte');
    const [sort, setSort] = useState('desc');
    const [valueCompare, setValueCompare] = useState<any>();
    const isSelected = selectedField === criteria.value;
    const { t } = useTranslation();
    const { authState } = useAuthState() as any;

    const userACLKey = authState?.currentUser?.userACL?.key;
    const isBasicUser = userACLKey === PLAN_BASIC_KEY;

    const inputStyles = { width: 100, marginRight: 5 }

    const onOk = () => {
        onSubmit({
            field: criteria.value,
            sort,
            operator,
            value: valueCompare,
        });
    };

    return <List.Item className={isSelected ? 'selected' : ''} onClick={() => setSelectedField(criteria.value)}>
        <List.Item.Meta title={<Text type={isBasicUser ? 'secondary' : undefined}>{criteria.label}</Text>} />
        {isSelected && !disabled && (
            <div>
                <Select
                    value={sort}
                    onChange={(value) => setSort(value)}
                    style={inputStyles}
                >
                    <Select.Option value="desc">{t('Giảm dần')}</Select.Option>
                    <Select.Option value="asc">{t('Tăng dần')}</Select.Option>
                </Select>
                <Select
                    value={operator}
                    onChange={(value) => setOperator(value)}
                    style={inputStyles}
                >
                    <Select.Option value="gte">{t('Lớn hơn')}</Select.Option>
                    <Select.Option value="lte">{t('Nhỏ hơn')}</Select.Option>
                </Select>
                <InputNumber
                    type="number"
                    onChange={(value) => setValueCompare(value)}
                    style={inputStyles}
                />
                <Button type="primary" style={{marginRight: 5}} onClick={onOk}>{t('Áp dụng')}</Button>
            </div>
        )}
    </List.Item>
};

const BreakoutCriteria = (props) => {
    const {
        filterList,
        isDefaultFilters,
        defaultFiltersLabel,
        addFilter,
        removeFilter,
        setDefaultFilters,
        setIsDefaultFilters,
        criteriaExpanded,
        setCriteriaExpanded,
        BASIC_LABEL,
        ADVANCED_LABEL,
        PRO_LABEL,
    } = props;

    const [selectedCriteriaGroup, setSelectedCriteriaGroup] = useState<CriteriaGroup>();
    const [selectedField, setSelectedField] = useState('');
    const { t } = useTranslation();
    const { authState } = useAuthState() as any;
    const [themeMode] = themeModeGlobalState();
    const isDarkMode = themeMode === 'dark';

    const userACLKey = authState?.currentUser?.userACL?.key;
    const isBasicUser = userACLKey === PLAN_BASIC_KEY;

    const BREAKOUT_FIELD_OPTIONS = [
        {
            key: 'volume_change',
            label: `${t('Biến động khối lượng')} (%)`,
            options: [
                {
                    value: 'percentChangeTotalVolume5Days',
                    label: `+/-(%) ${t('KLTB 5 Ngày')}`,
                },
                {
                    value: 'percentChangeTotalVolume10Days',
                    label: `+/-(%) ${t('KLTB 10 Ngày')}`,
                },
                {
                    value: 'percentChangeTotalVolume30Days',
                    label: `+/-(%) ${t('KLTB 30 Ngày')}`,
                },
                {
                    value: 'percentChangeTotalVolume60Days',
                    label: `+/-(%) ${t('KLTB 60 Ngày')}`,
                },
                {
                    value: 'percentChangeTotalVolume90Days',
                    label: `+/-(%) ${t('KLTB 90 Ngày')}`,
                },
            ],
        },
        {
            key: 'volume',
            label: `${t('Khối lượng')} (${t('CP')})`,
            options: [
                {
                    value: 'averageTotalVolume5Days',
                    label: t('KLTB 5 Ngày'),
                },
                {
                    value: 'averageTotalVolume10Days',
                    label: t('KLTB 10 Ngày'),
                },
                {
                    value: 'averageTotalVolume30Days',
                    label: t('KLTB 30 Ngày'),
                },
                {
                    value: 'averageTotalVolume60Days',
                    label: t('KLTB 60 Ngày'),
                },
                {
                    value: 'averageTotalVolume90Days',
                    label: t('KLTB 90 Ngày'),
                },
            ],
        },
        {
            key: 'price_change',
            label: `${t('Biến động giá')} (%)`,
            options: [
                {
                    value: 'percentChangePrice5Days',
                    label: `+/-(%) ${t('Giá TB 5 Ngày')}`,
                },
                {
                    value: 'percentChangePrice10Days',
                    label: `+/-(%) ${t('Giá TB 10 Ngày')}`,
                },
                {
                    value: 'percentChangePrice30Days',
                    label: `+/-(%) ${t('Giá TB 30 Ngày')}`,
                },
                {
                    value: 'percentChangePrice60Days',
                    label: `+/-(%) ${t('Giá TB 60 Ngày')}`,
                },
                {
                    value: 'percentChangePrice90Days',
                    label: `+/-(%) ${t('Giá TB 90 Ngày')}`,
                },
            ],
        },
        {
            key: 'price',
            label: `${t('Giá')} (${t('VNĐ')})`,
            options: [
                {
                    value: 'averagePrice5Days',
                    label: t('Giá TB 5 Ngày'),
                },
                {
                    value: 'averagePrice10Days',
                    label: t('Giá TB 10 Ngày'),
                },
                {
                    value: 'averagePrice30Days',
                    label: t('Giá TB 30 Ngày'),
                },
                {
                    value: 'averagePrice60Days',
                    label: t('Giá TB 60 Ngày'),
                },
                {
                    value: 'averagePrice90Days',
                    label: t('Giá TB 90 Ngày'),
                },
            ],
        },
        {
            key: 'financial_basic',
            label: t('Các chỉ tiêu tài chính cơ bản'),
            options: [
                {
                    value: 'eps',
                    label: t('EPS'),
                },
                {
                    value: 'bookvalue',
                    label: t('Giá trị sổ sách'),
                },
                {
                    value: 'valuepershare',
                    label: t('Giá trị/CP'),
                },
                {
                    value: 'pe',
                    label: t('P/E'),
                },
                {
                    value: 'pb',
                    label: t('P/B'),
                },
                {
                    value: 'roarecent',
                    label: t('ROA'),
                },
                {
                    value: 'roerecent',
                    label: t('ROE'),
                },
                {
                    value: 'dividendyield',
                    label: t('Tỷ suất cổ tức'),
                },
                {
                    value: 'marketcap',
                    label: t('Vốn hoá'),
                },
                {
                    value: 'sharesoutstanding',
                    label: t('Số lượng cổ phiếu lưu hành'),
                },
                {
                    value: 'foreignpercent',
                    label: t('Sở hữu nước ngoài'),
                },
                {
                    value: 'freefloatpercent',
                    label: t('Free-float'),
                },
                {
                    value: 'foreignvolumeroom',
                    label: t('Room nước ngoài còn lại'),
                },
            ],
        },
    ];

    const updateSelectedCriteriaOptions = (key) => {
        const selected = BREAKOUT_FIELD_OPTIONS.find(section => section.key === key);
        setSelectedCriteriaGroup(selected);
    };

    const renderFilterList = () => {
        const hasDefaultItems = filterList.some((item) => item.default === true);

        return (
            <>
                {hasDefaultItems && (
                    <List.Item key="default-filters">
                        <MinusCircleFilled className="mr-2" onClick={() => {
                            removeFilter(filterList, true);
                            setIsDefaultFilters(false);
                        }} />
                        {defaultFiltersLabel}
                    </List.Item>
                )}
                {filterList.map((f: any) => {
                    return renderFilterItem(f);
                })}
            </>
        );
    };

    const renderFilterItem = (filterItem: any) => {
        if (filterItem.default) {
            return;
        }

        let _BREAKOUT_FIELD_OPTIONS: any = [];
        BREAKOUT_FIELD_OPTIONS.map(group => {
            group.options.map(option => {
                _BREAKOUT_FIELD_OPTIONS.push(option);
                return option;
            })
            return group;
        });

        const _field = _.find(_BREAKOUT_FIELD_OPTIONS,
            (f) => f.value === filterItem.field,
        );

        if (!_field) {
            return <></>;
        }

        let sortLabel = '';

        if (filterItem.sort) {
            sortLabel = filterItem.sort === 'asc' ? `(${t('Tăng dần')})` : `(${t('Giảm dần')})`;
        }

        let operatorLabel = '';
        if (
            filterItem.operator &&
            !_.isNull(filterItem.value) &&
            !_.isUndefined(filterItem.value)
        ) {
            const unit = _field.value.includes('percent') ? '%' : '';
            operatorLabel =
                filterItem.operator === 'gte'
                    ? `>= ${filterItem.value}${unit}`
                    : `<= ${filterItem.value}${unit}`;
        }

        return (
            <List.Item key={`${filterItem.field}-${filterItem.operator}-${filterItem.sort}-${filterItem.value}`}>
                <MinusCircleFilled className="mr-2" onClick={() => removeFilter(filterItem)} />
                {_field.label}: {operatorLabel} {sortLabel}
            </List.Item>
        );
    };

    const headStyle = {
        backgroundColor: theme.colors.primary,
        color: 'white',
        fontWeight: 700,
    };

    const onConfirm = (planName) => {
        const filterItems = {
            'basic': { field: 'ai-fstock-basic', isDefault: true, valuesDefault: DEFAULT_BREAKOUT_FILTERS },
            'advanced': { field: 'ai-fstock-advanced', isDefault: true, valuesDefault: FSTOCK_BREAKOUT_FILTERS_ADVANCED },
            'professional': { field: 'ai-fstock-pro', isDefault: true, valuesDefault: FSTOCK_BREAKOUT_FILTERS_PRO },
        };

        Modal.confirm({
            title: <span>Bạn muốn áp dụng bộ lọc <span className="capitalize font-bold">A.I Fstock {planName}</span>?</span>,
            onOk: () => addFilter(filterItems[planName]),
            okText: t('Áp dụng'),
            cancelText: t('Huỷ'),
        });
    };

    return <div className="breakout-criteria">
        <Collapse
            defaultActiveKey={['filter']}
            onChange={() => setCriteriaExpanded(!criteriaExpanded)}
        >
            <Collapse.Panel header={t('Điều kiện lọc')} key="filter">
                <Row gutter={8}>
                    <Col xs={24} md={16}>
                        <Card title={t('Bộ lọc A.I FSTOCK')} headStyle={headStyle} className="mb-2">
                            <div className="px-2 py-2">
                                <Space>
                                    <Button size="middle" type={defaultFiltersLabel === BASIC_LABEL ? 'primary' : 'default'} onClick={() => onConfirm('basic')} disabled={isBasicUser}>
                                        {isBasicUser && <LockOutlined className="mr-1" />}A.I Fstock Basic
                                    </Button>
                                    <Button size="middle" type={defaultFiltersLabel === ADVANCED_LABEL ? 'primary' : 'default'} onClick={() => onConfirm('advanced')} disabled={isBasicUser}>
                                        {isBasicUser && <LockOutlined className="mr-1" />}A.I Fstock Advanced
                                    </Button>
                                    <Button size="middle" type={defaultFiltersLabel === PRO_LABEL ? 'primary' : 'default'} onClick={() => onConfirm('professional')} disabled={(userACLKey !== 'professional')}>
                                        {userACLKey !== 'professional' && <LockOutlined className="mr-1" />}
                                        A.I Fstock Pro
                                    </Button>
                                </Space>
                            </div>
                        </Card>
                        <Card
                            headStyle={isBasicUser ? {fontWeight: 700} : {fontWeight: 700, color: theme.colors.primary, backgroundColor: isDarkMode ? '#34251a' : '#eaded7' }}
                            title={<span>
                                {t('Thêm tiêu chí')}
                                {isBasicUser && <Tag className="ml-2" color="orange">{t('Pro')}</Tag>}
                            </span>}
                        >
                            <Row>
                                <Col xs={24} md={6}>
                                    {BREAKOUT_FIELD_OPTIONS.map(section => {
                                        return <List.Item
                                            key={section.key}
                                            className={`ratio-item ${section.key === selectedCriteriaGroup?.key && 'selected'}`}
                                            onClick={() => updateSelectedCriteriaOptions(section.key)}
                                        >
                                            <Text type={isBasicUser ? 'secondary' : undefined}>{section.label}</Text>
                                        </List.Item>
                                    })}
                                </Col>
                                <Col xs={24} md={18}>
                                    {selectedCriteriaGroup && (
                                        <div className="options-wrapper">
                                            {selectedCriteriaGroup.options.map(criteria => {
                                                return <FilterForm
                                                    key={criteria.value}
                                                    criteria={criteria}
                                                    disabled={isBasicUser}
                                                    selectedField={selectedField}
                                                    setSelectedField={setSelectedField}
                                                    onSubmit={addFilter}
                                                />
                                            })}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card
                            headStyle={headStyle}
                            className="filters-list"
                            title={t('Tiêu chí đã chọn')}
                            extra={!isDefaultFilters
                                ? <Button className="btn-white" onClick={setDefaultFilters}>
                                      {t('Đặt lại mặc định A.I Fstock Basic')}
                                  </Button>
                                : <></>
                            }
                        >
                            {renderFilterList()}
                        </Card>
                    </Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    </div>
};

export default BreakoutCriteria;