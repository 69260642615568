import React from "react";
import { message } from "antd";
import api from "../../../../services/api";
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import PlanForm from "./Form";
import { useHistory } from 'react-router-dom';
import routes from "../../../../routes";
import AdminPermission from "../../../../components/AdminPermission";

const AddPlanComponent = () => {
    const history = useHistory();
    const onFinish = async (formValues: any) => {

        let [err, resp]: any = await api.post("/plan/create", formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (resp.data) {
            message.success("Thêm gói dịch vụ thành công");
            history.replace(routes.ADMIN_PLAN_LIST)
        }
    };

    return (
        <div>
            <MyBreadcrumd
                items={[
                    { label: "Quản lý gói dịch vụ" },
                    { label: "Thêm mới" },
                ]}
            />
            <PlanForm mode="add" onFinish={onFinish} />
        </div>
    );
};

const AddPlan = () => {
    return <AdminPermission roleName={[]}>
        <AddPlanComponent />
    </AdminPermission>
}

export default AddPlan;
