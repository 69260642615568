import React, { useState } from "react";
import { useEffectOnce } from 'react-use';
import { Layout, Row, Col, Card, Avatar, Space, Alert } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import routes from "../../routes";
import NormalFooter from "../../components/NormalFooter";
import themeModeGlobalState from "../../contexts/ThemeMode";
import LogoWhite from "../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../assets/image/logo/Fstock_black.svg";

const UserActivatePage = () => {
    const [email, setEmail] = useState('');
    const { t } = useTranslation();
    const location: any = useLocation();
    const [themeMode,] = themeModeGlobalState();
    const logo = themeMode === 'dark' ? LogoWhite : LogoBlack;

    useEffectOnce(() => {
        if (location.state?.email) {
            setEmail(location.state.email);
        }
    });

    return (
        <Layout className="auth-page">
            <Row justify="center" align="middle" className="h-full">
                <Col xs={22} sm={18} md={12} lg={10} xl={5}>
                    <Space className="brand flex justify-center mb-4" align="center">
                        <Avatar shape="square" size={128} src={logo} />
                    </Space>
                    <Card title={t('Đăng ký tài khoản thành công')} headStyle={{ textAlign: "center" }}>
                        <Alert
                            className="mb-4"
                            message={<Space direction="vertical" size="small" style={{ display: 'flex' }}>
                                <span>{t('Hệ thống đã gửi một email kích hoạt cho quý khách.')}</span>
                                <span>
                                    <span>{t('Vui lòng kiểm tra hòm thư')} <a href={`mailto:${email}`}>{email}</a> </span>
                                    <span>{t('và click vào đường link kích hoạt trước khi đăng nhập.')}</span>
                                </span>
                            </Space>}
                            type="success"
                        />

                        <Link to={routes.LOGIN} className="text-gray"><ArrowLeftOutlined /> {t('Trở lại đăng nhập')}</Link>
                    </Card>
                </Col>
            </Row>

            <NormalFooter />
        </Layout>
    );
}

export default UserActivatePage;