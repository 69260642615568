import React from "react";
import { Route, Switch } from "react-router";
import routes from "../../../../routes";
import PaymentInfo from "./Info";
import PaymentResult from "./Result";
import { Redirect } from 'react-router-dom';

const RegisterPlan = () => {
    return (
        <Switch>
            <Route path={routes.UPGRADE_PAYMENT_INFO} component={PaymentInfo}></Route>
            <Route path={routes.UPGRADE_PAYMENT_RESULT} component={PaymentResult}></Route>
            <Route path={routes.UPGRADE}>
                <Redirect to={routes.UPGRADE_PLAN}></Redirect>
            </Route>
        </Switch>
    );
};

export default RegisterPlan;
