import { message, Table, Button , Card, Space } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffectOnce } from "react-use";
import PeriodPriceTable from "./PeriodPrice";
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import routes from "../../../../routes";
import api from "../../../../services/api";
import _ from "lodash";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { toRoute } from "../../../../services/utils";
import AdminPermission from "../../../../components/AdminPermission";

const PlansListComponent = () => {
    const [plansList, setPlansList]: any = useState();
    const [featuresList, setFeaturesList] = useState();
    const [childrenColumns, setChildrenColumns]: any = useState();

    const renderTitle = (plan: any) => {
        return (
            <Space>
                <Link to={toRoute(routes.ADMIN_PLAN_EDIT, { planId: plan.id })}>
                    <Button type="link" style={{fontSize: 16, fontWeight: 'bold'}}>{plan.name} </Button>
                    <p>{plan.isFree ? ("Miễn phí") : ("Thu phí")} </p>
                </Link>
            </Space>
        );
    };

    const fetchPlansList = async () => {
        const [err, resp]: any = await api.get("/plan");

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        let columns: any = [];
        for (let plan of resp.data) {
            columns.push({
                title: renderTitle(plan),
                key: `${plan.id}`,
                align: "center",
                dataIndex: "plan_features",
            });
        }

        let childColumns: any = [];
        // eslint-disable-next-line array-callback-return
        columns.map((c) => {
            c = {
                ...c,
                render: (planIds) => {
                    planIds = _.map(planIds,_.ary((p) => p.planId.toString()));
                    let isPlan = planIds.includes(c.key);
                    return (
                        isPlan ? (
                            <CheckCircleFilled
                                style={{ color: "#52c41a", fontSize: "150%" }}
                            />
                        ) : (
                            <CloseCircleFilled
                                style={{ color: "red", fontSize: "150%" }}
                            />
                        )
                    );
                },
            };
            childColumns.push(c);
        });
        setChildrenColumns(childColumns);
        setPlansList(resp.data);
    };

    const fetchFeatures = async () => {
        const [err, resp]: any = await api.get("/plan/features");

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        let features: any = [];
        const groupsFeature = resp.data;
        for (let data of groupsFeature) {
            features = features.concat(data.features)
        }
        setFeaturesList(features);
    };

    useEffectOnce(() => {
        fetchFeatures();
        fetchPlansList();
    });

    const columns: any = [
        {
            title: "Tính năng",
            dataIndex: "name",
            width: "50%",
        },
        {
            title: "Gói",
            children: childrenColumns,
        },
    ];

    return (
        <div>
            <MyBreadcrumd items={[{ label: "Quản lý gói dịch vụ" }]} />
            <Card>
                <Button type="primary" className="">
                    <Link to={routes.ADMIN_PLAN_ADD}>Thêm mới gói dịch vụ</Link>
                </Button>

                <Button type="primary" className="mx-5">
                    <Link to={routes.ADMIN_FEATURE_LIST}>Quản lý tính năng</Link>
                </Button>

                <div className="m-auto mt-5">
                    <Table
                        size="middle"
                        tableLayout="fixed"
                        columns={columns}
                        dataSource={featuresList}
                        bordered
                        pagination={false}
                    />
                </div>

                <PeriodPriceTable planList={plansList} />
            </Card>
        </div>
    );
};

const PlansList = () => {
    return <AdminPermission roleName={[]}>
        <PlansListComponent />
    </AdminPermission>
}

export default PlansList;
