import { Button, Space, Table } from "antd";
import React from "react";

import _ from "lodash";
import { Link } from "react-router-dom";
import { toRoute } from "../../../../services/utils";
import routes from "../../../../routes";

type  PeriodPriceProps = {
    planList: any
}

const PeriodPriceTable = (props: PeriodPriceProps) => {
    let plans = props.planList ? props.planList : [];

    let children: any = [];
    let planPrices: any = [];

    const renderTitle = (plan: any) => {
        return (
            <Space>
                <Link to={toRoute(routes.ADMIN_PLAN_EDIT, { planId: plan.id })}>
                    <Button type="link" style={{fontSize: 16, fontWeight: 'bold'}}>{plan.name} </Button>
                    <p>{plan.isFree ? ("Miễn phí") : ("Thu phí")} </p>
                </Link>
            </Space>
        );
    };

    for (let plan of plans) {
        children.push({
            title: renderTitle(plan),
            key: `${plan.id}`,
            dataIndex: 'plan_prices',
            align: 'center',
            render: (plan_prices) => {
                const planPrice = _.find(plan_prices, i => i.planId === plan.id);
                const price = planPrice?.price?.toLocaleString('vi', {style : 'currency', currency : 'VND'})
                return <span>{price}</span>
            }
        });
        planPrices = _.concat(planPrices, plan.plan_prices);
    }

    planPrices = _(planPrices).groupBy('period')
                .map((items, period) => ({period: period, plan_prices: items}))
                .value()

    const columns: any = [
        {
            title: 'Kỳ hạn',
            width: '50%',
            dataIndex: 'period',
            align: 'center',
            render: (period) => {
                return <span>{period} tháng</span>
            }
        },
        {
            title: 'Gói',
            children: children
        }
    ]
    return <div className="m-auto mt-5 ">
        <Table
            size="middle"
            columns={columns}
            dataSource={planPrices}
            tableLayout='fixed'
            bordered
        >

        </Table>
    </div>
}

export default PeriodPriceTable;