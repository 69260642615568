import React from "react";
import StockDetailContainer from "./container";
import * as config from "../../../../../../config";
import UserACL from '../../../../../../components/UserACL';

const StockDetailWindow = (props) => {
    let { id, symbol, glContainer, width, height } = props;

    symbol = symbol ? symbol : config.DEFAULT_STOCK_CODE;

    return (
        <UserACL feature="stock_detail">
            <StockDetailContainer
                id={id}
                symbol={symbol}
                glContainer={glContainer}
                width={width}
                height={height}
            />
        </UserACL>
    )
};

export default StockDetailWindow;