import React, { useState } from 'react';
import { Select, Avatar } from 'antd';
import { useTranslation } from "react-i18next";
import localStorage from "../../services/local-storage";

import FlagVI from '../../assets/image/flag/vi.png';
import FlagEN from '../../assets/image/flag/us.png';
import FlagCN from '../../assets/image/flag/cn.png';

const SelectLanguage = () => {
    const { i18n } = useTranslation();

    const _locale = localStorage.get('siteLanguage') || 'vi';
    const [locale, setLocale] = useState(_locale);

    const changeLanguage = (value) => {
        i18n.changeLanguage(value);
        localStorage.set('siteLanguage', value);
        setLocale(value);
        window.location.reload();
    };

    return (
        <Select
            size="small"
            defaultValue="vi"
            className="select-language ml-2"
            style={{ width: 130 }}
            onChange={changeLanguage}
            value={locale}
        >
            <Select.Option value="vi"><Avatar size={16} src={FlagVI} className="mr-2" />Tiếng Việt</Select.Option>
            <Select.Option value="en"><Avatar size={16} src={FlagEN} className="mr-2" />English</Select.Option>
            <Select.Option value="cn"><Avatar size={16} src={FlagCN} className="mr-2" />中文</Select.Option>
        </Select>
    );
};

export default SelectLanguage;