import React, { useState } from 'react';
import _ from 'lodash';
import { useEffectOnce } from 'react-use';

import ComposedTablePrices from '../components/ComposedTablePrices';
import { EXCHANGES } from '../../../../../../../config';
import { DERIVATIVE_DEFAULT_LIST } from '../../../../../../../config';
import useStocksDefaultState from '../../../../../../../contexts/StocksDefault';
import { useAuthState } from "../../../../../../../contexts";

type TablePriceContainerProps = {
    glContainer?: any;
    activeTab?: string;
    isDerivativesDashboard?: boolean;
}

const TablePricePluginContainer = (props: TablePriceContainerProps) => {
    const { glContainer, activeTab, isDerivativesDashboard } = props;
    const [ industryStockCodes, setIndustryStockCodes ] = useState<string[]>([]);
    const [ stockFavouritesCodes, setStockFavouritesCodes ] = useState<string[]>([]);
    const [ stockCodeSearchedName, setStockCodeSearchedName ] = useState<string>('');
    const [stocksDefault] = useStocksDefaultState();
    const { authState } = useAuthState() as any;

    useEffectOnce(() => {
        if (authState?.selectedWatchlist?.stocks) {
            setStockFavouritesCodes(authState.selectedWatchlist.stocks);
        };
    });

    let dataSource: { [key: string]: NSApp.Stock[] } = {};

    for (let exchange of EXCHANGES) {
        let stocksByExchange = _.filter(stocksDefault, (stockData) => {
            return stockData.exchange === exchange.exchange;
        });

        dataSource[exchange.exchange] = stocksByExchange;
    }

    let stockWarrantsFiltered = _.filter(stocksDefault, (stockData) => {
        return stockData.stockType === 'warrant';
    });

    let stockDerivativesFiltered = _.filter(stocksDefault, (stockData) => {
        return (
            stockData.stockType === 'derivative' &&
            stockData.stockCode?.startsWith('vn30f') &&
            !DERIVATIVE_DEFAULT_LIST.includes(stockData.stockCode)
        );
    });

    dataSource['warrant'] = stockWarrantsFiltered;

    dataSource['derivatives'] = stockDerivativesFiltered.map(stockData => {
        return {...stockData, differentValue: stockData.matchedPrice };
    });

    let stockFavouritesFiltered: any = [];

    if (stockFavouritesCodes) {
        stockFavouritesFiltered = _.filter(stocksDefault, (stockData) => {
            return stockFavouritesCodes.includes(stockData.stockCode);
        });
    }

    dataSource['favourites'] = stockFavouritesFiltered

    let stockIndustryFiltered = _.filter(stocksDefault, (stockData) => {
        return industryStockCodes.includes(stockData.stockCode);
    });

    dataSource['industry'] = stockIndustryFiltered;

    for (let key of Object.keys(dataSource)) {
        dataSource[key] = _.orderBy(dataSource[key], ['stockCode'], ['asc']);
    }

    dataSource['derivatives'] = dataSource['derivatives'].map((sd, index) => {
        let relateFuCode = DERIVATIVE_DEFAULT_LIST[index];
        return {
            ...sd,
            relateFuCode
        };
    });

    let stockFilteredByCode = _.filter(stocksDefault, (stockData) => {
        if (stockCodeSearchedName) {
            return stockData.stockCode.startsWith(stockCodeSearchedName);
        }
        return true;
    });

    dataSource['searched'] = _.values(stockFilteredByCode);

    return <div className="plugin-table-price p-1">
        <ComposedTablePrices
            dataSource={dataSource}
            glContainer={glContainer}
            activeTab={activeTab}
            isDerivativesDashboard={isDerivativesDashboard}
            setStockCodeSearchedName={setStockCodeSearchedName}
            setIndustryStockCodes={setIndustryStockCodes}
            setStockFavouritesCodes={setStockFavouritesCodes}
        />
    </div>
}

export default TablePricePluginContainer;
