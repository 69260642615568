import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { widget } from '../../../../library/charting_library/charting_library/charting_library.min';
import getDefaultOptions from '../Dashboard/plugins/TradingView/Options';
import { Layout } from 'antd';

const TradingView = () => {
    let { symbol } = useParams<{symbol: string}>();

    const options = getDefaultOptions(symbol, symbol, false);

    useEffect(() => {
        new widget(options);
    }, []); //eslint-disable-line

    return (
        <Layout className='min-h-screen'>
            <div id={`wrapper_${symbol}`} style={{width: '100%', height: '100%'}}/>
        </Layout>
    );
}

export default TradingView;
