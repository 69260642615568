import React, { useEffect, useState } from "react";
import ArticlesListComponent from "../components/list";
import { useReducedState } from "../../../../../../../services/utils";
import * as ArticleServices from "../service"
import * as config from '../../../../../../../config';

type ArticleListContainerProps = {
    category?: NSApp.ArticleCategory,
    stockCodes?: string[],
    enableWatchListFilter?: boolean,
    hasTitle?: boolean,
    hasLatestNews?: boolean,
    isLatestTab?: boolean,
    // isExternal?: boolean,
    height: number,
    width?: number,
    horizontal?: boolean,
    listNoImage?: boolean,
    column?: number,
    pageSize?: number,
    searchKey?: string
};



const ArticleListContainer = (props: ArticleListContainerProps) => {
    const { category, searchKey, height, horizontal, column, listNoImage, isLatestTab, width, pageSize } = props

    const [ pagination, setPagination ] = useReducedState({
        total: 0,
        current: 1,
        pageSize: config.PAGE_SIZE
    });
    const [articlesList, setArticleList] = useState<any>()
    const [latestArticle, setLatestArticle] = useState<any>()

    const fetchArticlesList = async (filter = {}) => {
        if(category){
            filter = {
                ...filter,
                categoryId : category.id
            }
        }

        if(searchKey){
            filter = {
                ...filter,
                title: searchKey.toUpperCase(),
            }
        }

        let [err, resp] = await ArticleServices.fetchArticleData(
            filter, 
            pagination.current, 
            pageSize ? pageSize : config.PAGE_SIZE,
        )

        if (resp) {
            let articlesResp = resp?.articles;
            let total = resp?.total;

            let amountOfLatestNews = isLatestTab ? 4 : 7;
            let articlesList = articlesResp.slice();
            let latestNews = articlesList.splice(0, amountOfLatestNews);
            setPagination({ total });
            setLatestArticle(latestNews);
            // setLoading(false);
            setArticleList(articlesList);
        }
    }

    useEffect(()=>{
        fetchArticlesList()
    },[searchKey ,pagination.current])

    return(
        <ArticlesListComponent
            category={category}
            searchKey={searchKey}
            height={horizontal ? 400 : height}
            horizontal={horizontal}
            column={column}
            listNoImage={listNoImage}
            articleList={articlesList}
            latestArticle={latestArticle}
            pagination={pagination}
            onChangePagination={setPagination}
            width={width}
        />
    )
}

export default ArticleListContainer