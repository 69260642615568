import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from "react-i18next";

const CustomizeEmpty = (props: { height?: number }) => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-center items-center" style={{height: props.height ? props.height : '100%'}}>
            <Empty description={<span className="text-placeholder">{t('Không có dữ liệu')}</span>}/>
        </div>
    )
};

export default CustomizeEmpty;