import React from "react";
import HeatMapContainer from "./container";
import UserACL from '../../../../../../components/UserACL';

const HeatMapWindow = (props) => {
    const { glContainer } = props;

    return (
        <UserACL feature="heat_map">
            <HeatMapContainer glContainer={glContainer}/>
        </UserACL>
    )
};

export default HeatMapWindow;