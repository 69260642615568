import React from "react";
import { Layout, Avatar, Menu } from "antd";
import {
    FileTextOutlined,
    ExclamationCircleOutlined,
    DesktopOutlined,
    BellOutlined,
    TableOutlined,
    UserOutlined,
    DollarOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import themeModeGlobalState from "../../../../contexts/ThemeMode";
import LogoWhite from "../../../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../../../assets/image/logo/Fstock_black.svg";
import routes from "../../../../routes";

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const AdminSider = () => {
    const [themeMode,] = themeModeGlobalState();

    return (
        <Sider className="home-sidebar" collapsedWidth={48}>
            <div className="logo">
                <Avatar shape="square" size={48} src={themeMode === 'dark' ? LogoWhite : LogoBlack} />
            </div>

            <Menu  mode="inline" defaultOpenKeys={["listDashboard"]} triggerSubMenuAction="hover">
                <SubMenu key="news-sub-menu" icon={<FileTextOutlined />} title="Tin tức">
                    <Item key="news">
                        <Link to={routes.ADMIN_NEWS_LIST}>Tin tức</Link>
                    </Item>
                    <Item key="provider">
                        <Link to={routes.ADMIN_NEWS_PROVIDER_LIST}>Nguồn cung cấp</Link>
                    </Item>
                    <Item key="category">
                        <Link to={routes.ADMIN_NEWS_CATEGORY_LIST}>Danh mục</Link>
                    </Item>
                </SubMenu>

                <SubMenu key="recommendation-sub-menu" icon={<ExclamationCircleOutlined />} title="Khuyến nghị">
                    <Item key="recommendation">
                        <Link to={routes.ADMIN_RECOMMENDATION}>Khuyến nghị</Link>
                    </Item>
                    <Item key="category_recommendation">
                        <Link to={routes.ADMIN_RECOMMENDATION_CATEGORY_LIST}>Danh mục</Link>
                    </Item>
                </SubMenu>

                <SubMenu key="article-sub-menu" icon={<ExclamationCircleOutlined />} title="Bài viết">
                    <Item key="article">
                        <Link to={routes.ADMIN_ARTICLE}>Bài viết</Link>
                    </Item>
                    <Item key="category_article">
                        <Link to={routes.ADMIN_ARTICLE_CATEGORY_LIST}>Danh mục</Link>
                    </Item>
                </SubMenu>

                <SubMenu key="user-group-sub-menu" icon={<UserOutlined />} title="Người dùng">
                    <Item key="user">
                        <Link to={routes.ADMIN_USER}>Người dùng</Link>
                    </Item>
                    <Item key="user-group">
                        <Link to={routes.ADMIN_USER_ROLES}>Nhóm người dùng</Link>
                    </Item>
                </SubMenu>

                <Item key="dashboard" icon={<DesktopOutlined />}>
                    <Link to={routes.ADMIN_DASHBOARD_MANAGER}>Dashboard</Link>
                </Item>

                <SubMenu key="notification" icon={<BellOutlined />} title = "Thông báo" >
                    <Item key="push-notification" >
                        <Link to={routes.ADMIN_NOTIFICATION_LIST}>Thông báo</Link>
                    </Item>
                    <Item key="notification-sample" >
                        <Link to={routes.ADMIN_NOTIFICATION_SAMPLE_LIST}>Mẫu thông báo</Link>
                    </Item>
                </SubMenu>

                <Item key="queue-jobs" icon={<ExclamationCircleOutlined />}>
                    <Link to={routes.ADMIN_QUEUE_JOBS_LIST}>Quản lý queue</Link>
                </Item>

                <Item key="plan" icon={<TableOutlined />}>
                    <Link to={routes.ADMIN_PLAN_LIST}>Quản lý gói dịch vụ</Link>
                </Item>

                <Item key="upload-data" icon={<UploadOutlined />}>
                    <Link to={routes.ADMIN_UPLOAD_DATA_LIST}>Upload dữ liệu</Link>
                </Item>

                <Item key="payment" icon={<DollarOutlined />}>
                    <Link to={routes.ADMIN_MANAGE_PAYMENT}>Quản lý thanh toán</Link>
                </Item>

                <SubMenu key="affiliate-group-sub-menu" icon={<UserOutlined />} title="Cộng tác viên">
                    <Item key="affiliate">
                        <Link to={routes.ADMIN_MANAGE_AFFILIATE}>Quản lý cộng tác viên</Link>
                    </Item>
                    <Item key="user-group">
                        <Link to={routes.ADMIN_MANAGE_AFFILIATE_WITHDRAW}>Yêu cầu rút tiền</Link>
                    </Item>
                </SubMenu>
            </Menu>
        </Sider>
    );
};

export default AdminSider;
