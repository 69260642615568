import api from "./api";
import TablePriceView from "../pages/private/client/Dashboard/plugins/TablePrice";
import MainIndex from "../pages/private/client/Dashboard/plugins/MainIndex";
import HeatMapWindow from "../pages/private/client/Dashboard/plugins/HeatMap";
// import TradingViewWrapper from "../pages/private/client/Dashboard/plugins/TradingView/Wrapper";
import TradingViewTabs from "../pages/private/client/Dashboard/plugins/TradingView/Tabs";
import WatchlistWindow from "../pages/private/client/Dashboard/plugins/Watchlist";
import ForeignerTradingView from "../pages/private/client/Dashboard/plugins/ForeignerTrading";
import RecommendationsWindow from "../pages/private/client/Dashboard/plugins/Recommendations";
import StockDetailView from "../pages/private/client/Dashboard/plugins/StockDetail";
import NewsWindow from "../pages/private/client/Dashboard/plugins/News";
import StockMarketOverview from "../pages/private/client/Dashboard/plugins/StockMarketOverview";
import MacroeconomicsWindow from "../pages/private/client/Dashboard/plugins/Macroeconomics";
// import InvestorWindow from "../pages/private/client/Dashboard/plugins/Investor";
import StocksScreenerWindow from "../pages/private/client/Dashboard/plugins/StocksScreener";
import MarketReportsWindow from "../pages/private/client/Dashboard/plugins/MarketReports";
import DerivativesAnalysisWindow from "../pages/private/client/Dashboard/plugins/DerivativesAnalysis";
import ProprietaryWindow from "../pages/private/client/Dashboard/plugins/Proprietary";
// import WorldMarketWindow from "../pages/private/client/Dashboard/plugins/WorldMarket";
import i18n from '../i18n';
import ArticlesWindow from "../pages/private/client/Dashboard/plugins/Article";

let pluginsList: Array<any> = [
    {
        'group_name': i18n.t('Kinh tế vĩ mô'),
        'key': 'macroeconomics',
        'plugins': [
            {
                'component': MacroeconomicsWindow,
                'title': i18n.t('Thống kê các chỉ tiêu kinh tế'),
                'key': 'macroeconomics_statistics',
            },
        ],
    },
    {
        'group_name': i18n.t('Thị trường chứng khoán'),
        'key': 'stock_market',
        'plugins': [
            {
                'component': TablePriceView,
                'title': i18n.t('Bảng giá'),
                'key': 'table_price',
            },
            {
                'component': StockMarketOverview,
                'title': i18n.t('Toàn cảnh thị trường'),
                'key': 'stock_market_overview',
            },
            {
                'component': HeatMapWindow,
                'title': i18n.t('Bản đồ nhiệt'),
                'key': 'heat_map',
            },
            {
                'component': ForeignerTradingView,
                'title': i18n.t('Giao dịch khối ngoại'),
                'key': 'foreigner_trading',
            },
            {
                'component': ProprietaryWindow,
                'title': i18n.t('Giao dịch tự doanh'),
                'key': 'proprietary',
            },
            {
                'component': StocksScreenerWindow,
                'title': i18n.t('Bộ lọc cổ phiếu'),
                'key': 'stocks_screener',
            },
            {
                'component': StockDetailView,
                'title': i18n.t('Thông tin cổ phiếu'),
                'key': 'stock_detail',
            },
            {
                'component': TradingViewTabs,
                'title': i18n.t('Phân tích kỹ thuật'),
                'key': 'tradingview',
            },
            {
                'component': WatchlistWindow,
                'title': i18n.t('Danh mục quan tâm'),
                'key': 'favourites',
            },
            {
                'component': DerivativesAnalysisWindow,
                'title': i18n.t('Phân tích phái sinh'),
                'key': 'derivatives_analysis',
            },
            {
                'component': MainIndex,
                'title': i18n.t('Biến động chỉ số chính'),
                'key': 'main_index',
                'disabled': false
            },
            {
                'component': MarketReportsWindow,
                'title': i18n.t('Thống kê TTCK'),
                'key': 'market_reports',
            },
            // {
            //     'component': InvestorWindow,
            //     'title': 'Giao dịch nhóm nhà đầu tư',
            //     'key': 'investor',
            // },
            // {
            //     'component': WorldMarketWindow,
            //     'title': 'Thị trường thế giới',
            //     'key': 'world_market',
            // },
        ]
    },
    {
        'group_name': i18n.t('Tin tức'),
        'key': 'news',
        'plugins': [
            {
                'component': NewsWindow,
                'title': i18n.t('Tin tổng hợp'),
                'key': 'news',
            },
        ]
    },
    {
        'group_name': i18n.t('Bài viết'),
        'key': 'articles',
        'plugins': [
            {
                'component': ArticlesWindow,
                'title': i18n.t('Bài viết'),
                'key': 'articles',
            },
        ]
    },
];

export const fetchRecommendationPlugins = async () => {
    let [err, resp]: any = await api.get('plugins/recommendation/category', {filter: {}});

    if (err) {
        return;
    }

    let { categories } = resp.data;
    return categories.map(c => {
        return {
            'component': RecommendationsWindow,
            'title': c.name,
            'key': c._id
        }
    });
}

export const fetchDashboardPlugins = async () => {
    // const recommendationPlugins = await fetchRecommendationPlugins()

    // let recommendationPluginConfig = {
    //     'group_name': 'Fstock - Khuyến nghị',
    //     'key': 'recommendations',
    //     'plugins': recommendationPlugins
    // };

    if (pluginsList.length && pluginsList[0].key === "recommendations") return pluginsList;

    // pluginsList.unshift(recommendationPluginConfig);
    return pluginsList;
}
