import { UDFCompatibleDatafeed } from "../../../../../../library/charting_library_v2/datafeeds/udf/src/udf-compatible-datafeed";
import api from "../../../../../../services/api";
// import {
//     ResolutionString,
//     ChartingLibraryWidgetOptions,
// } from "../../../../../../library/charting_library_v2/charting_library/charting_library";
import { API_BASE_URL, DEFAULT_STOCK_CODE } from "../../../../../../config";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import themeModeGlobalState from "../../../../../../contexts/ThemeMode";

export default (
    chartID: string,
    symbol: string = DEFAULT_STOCK_CODE.toUpperCase(),
    enableSaveLoadAdapter: boolean = true
) => {
    const [themeMode,] = themeModeGlobalState();
    const chartsStorageUrl: string = "tradingview/1.1/";
    const UDFCompatibleDatafeedUrl: string = `${API_BASE_URL}feed`;
    let options: any = {
        symbol,
        interval: "D" as any,
        container: "wrapper_" + chartID,
        datafeed: new UDFCompatibleDatafeed(UDFCompatibleDatafeedUrl),
        library_path: "/charting_library_v2/charting_library/",
        fullscreen: false,
        autosize: true,
        locale: "vi",
        timezone: "Asia/Ho_Chi_Minh",
        theme: themeMode,
        disabled_features: ["use_localstorage_for_settings"],
        enabled_features: [""],

        save_load_adapter: enableSaveLoadAdapter ? {
            getAllCharts: async () => {
                // eslint-disable-next-line
                const [err, res]: any = await api.get(
                    chartsStorageUrl + "charts"
                );
                return Promise.resolve(res.data);
            },

            removeChart: async (chartId) => {
                // eslint-disable-next-line
                const [err, res]: any = await api.delete(
                    chartsStorageUrl + `charts/${chartId}`, {}
                );
                return Promise.resolve();
            },

            saveChart: async (chartData: any) => {
                if (chartData.name.indexOf("autosave-") === 0) {
                    chartData.id = chartID;
                    chartData.type = "autosave";
                }

                // eslint-disable-next-line
                const [err, res]: any = await api.post(
                    chartsStorageUrl + "charts", { chartData }
                );

                if (chartData.name.indexOf("autosave-") < 0) {
                    chartData.id = chartID;
                    chartData.type = "autosave";
                    const [err, res]: any = await api.post(
                        chartsStorageUrl + "charts", { chartData }
                    );
                }

                if (chartData.name.indexOf("autosave-") === 0) {
                    return Promise.reject();
                }

                return Promise.resolve(res.data);
            },

            getChartContent: async (chartId) => {
                // eslint-disable-next-line
                const [err, res]: any = await api.get(
                    chartsStorageUrl + `charts/${chartId}`, {}
                );

                if (res.data.content) {
                    // console.log(res.data.content)
                    return Promise.resolve(res.data.content);
                }

                return Promise.reject();
            },

            removeStudyTemplate: function (studyTemplateData) {
                return Promise.resolve();
            },

            getStudyTemplateContent: function (studyTemplateData) {
                return Promise.resolve("");
            },

            saveStudyTemplate: function (studyTemplateData) {
                return Promise.resolve();
            },

            getAllStudyTemplates: function () {
                return Promise.resolve([]);
            },

            getDrawingTemplates: async (toolName: string) => {
                return Promise.resolve([]);
            },

            removeDrawingTemplate: async (toolName: string, templateName: string) => {
                return Promise.resolve();
            },

            saveDrawingTemplate: async (toolName: string, templateName: string, templateData: any) => {
                return Promise.resolve();
            },

            loadDrawingTemplate: async (toolName: string, templateName: string) => {
                return Promise.resolve("");
            }
        } : undefined,
    };

    return options;
};
