import React, {useState, useEffect} from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Spin } from "antd";

import { useAuthState } from "../contexts";
import api from "../services/api";
import routes from "../routes";
import localStorage from "../services/local-storage";

const PrivateRoute = ({ component: Component, adminRequired=false, ...rest }) => {
    const { authState, setAuthState } = useAuthState() as any;
    const [ isLoading, setIsLoading ] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const checkJwtTokenIsLive = async () => {
        if (authState.currentUser) {
            return setIsLoading(false);
        }

        const accessToken = localStorage.get('accessToken');
        if (!accessToken) {
            return history.push(routes.LOGIN, { referrer: location.pathname });
        }

        let [err, resp]: any = await api.get('user/profile');

        if (err) {
            console.error('Error when trying to authenticate user:', err);
            return history.push(routes.LOGIN, { referrer: location.pathname });
        }

        const user = resp?.data?.user;

        setAuthState({ currentUser: user });
        setIsLoading(false);
    };

    useEffect(() => {
        checkJwtTokenIsLive();
    }, []); // eslint-disable-line

    if (isLoading) {
        return <Spin />;
    }

    return <Route {...rest} render={(props) => <Component {...props}/>}/>;
};

export default PrivateRoute;
