import { UDFCompatibleDatafeedBase } from './udf-compatible-datafeed-base';
import { QuotesProvider } from './quotes-provider';
import { Requester } from './requester';
import localStorage from '../../../../../services/local-storage';

export class UDFCompatibleDatafeed extends UDFCompatibleDatafeedBase {
	public constructor(datafeedURL: string, updateFrequency: number = 10 * 1000) {
		const accessToken = localStorage.get('accessToken');
		const requester = new Requester({
            Authorization: `Bearer ${accessToken}`
        });
		const quotesProvider = new QuotesProvider(datafeedURL, requester);
		super(datafeedURL, quotesProvider, requester, updateFrequency);
	}
}
