import React, { useState, useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { useTranslation } from "react-i18next";

import SelectWatchListInput, { SelectWatchListRef } from '../../../../../components/SelectWatchListInput';
import { priceFormat, priceChangePercentFormat } from '../../../../../../../../services/stock-price';
import { getStockColorClass, formatNumberWithComma } from '../../../../../../../../services/utils';
import TablePriceContainer from '../../../TablePrice/container/TablePrice';
import { ColumnsType } from 'antd/lib/table';
import { useAuthState } from "../../../../../../../../contexts";
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";

type WatchListProps = {
    onStockSymbolClick?: (stockCode: string) => void,
    height: number
}

const WatchList = (props: WatchListProps) => {
    const { height, onStockSymbolClick } = props;
    const [ stockCodes, setStockCodes ] = useState<string[]>([]);
    const { authState } = useAuthState() as any;
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const selectWatchListEl = useRef<SelectWatchListRef>(null);

    const onChangeTradingView = (stockSymbol) => {
        if (onStockSymbolClick) {
            onStockSymbolClick(stockSymbol)
        }
    }

    useEffectOnce(() => {
        if (authState.selectedWatchlist) {
            setStockCodes(authState.selectedWatchlist.stocks);
        };
    });

    const stocksTableColumns: ColumnsType<NSApp.Stock> = [
        {
            title: <strong>{t('Mã')}</strong>,
            key: 'stockCode',
            dataIndex: 'stockCode',
            width: 75,
            sorter: (a, b) => a.stockCode.localeCompare(b.stockCode),
            render: (value) => <strong className="cursor-pointer">
                {value.toUpperCase()}
            </strong>
        },
        {
            title: <strong>{t('Giá')}</strong>,
            key: 'matchedPrice',
            dataIndex: 'matchedPrice',
            align: 'right',
            render: (value, record) => <span style={{color: getStockColorClass(record, themeMode)}}>
                {priceFormat(value)}
            </span>
        },
        {
            title: <strong>+/-(%)</strong>,
            key: 'pricePercentChange',
            dataIndex: 'pricePercentChange',
            align: 'right',
            render: (value, record) => <span style={{color: getStockColorClass(record, themeMode)}}>
                {priceChangePercentFormat(value)}%
            </span>
        },
        {
            title: <strong>{t('Cao')}</strong>,
            key: 'highestPrice',
            dataIndex: 'highestPrice',
            align: 'right',
            render: (value, record) => <span
                style={{color: getStockColorClass(record, themeMode, value)}}>{priceFormat(value)}</span>
        },
        {
            title: <strong>{t('Thấp')}</strong>,
            key: 'lowestPrice',
            dataIndex: 'lowestPrice',
            align: 'right',
            render: (value, record) => <span style={{color: getStockColorClass(record, themeMode, value)}}>
                {priceFormat(value)}
            </span>
        },
        {
            title: <strong>{t('KL')}</strong>,
            key: 'totalVolume',
            dataIndex: 'totalVolume',
            align: 'right',
            width: 80,
            render: (value) => <span className="text-xs">
                {formatNumberWithComma(value)}
            </span>
        },
    ];

    return (
        <div className="table-custom-background table-has-border-right">
            <div className='mb-1'>
                <SelectWatchListInput
                    ref={selectWatchListEl}
                    onChangeSelectWatchListInput={(stockCodes: string[]) => setStockCodes(stockCodes)}
                    style={{ width: 180 }}
                />
            </div>

            {stockCodes && stockCodes.length > 0 &&
                <TablePriceContainer
                    columns={stocksTableColumns}
                    stockCodes={stockCodes}
                    height={height + 40}
                    tableProps={{ minWidth: 250 }}
                    changeTradingView={onChangeTradingView}
                />
            }
        </div>
    )
}

export default WatchList;