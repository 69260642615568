import { Card, Col, List, Modal, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import * as ArticleServices from "../service"
import * as config from '../../../../../../../config';
import { toRoute, useReducedState } from "../../../../../../../services/utils";
import { useHistory } from "react-router-dom";
import routes from "../../../../../../../routes";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import ArticleDetailPage from "./Detail";
import colors from "../../../../../../../themes/colors";

type ArticlesListProps = {
    category?: NSApp.ArticleCategory,
    searchKey?: string,
    height: number,
    width?: number,
    pagination: any,
    horizontal?: boolean,
    column?: number,
    listNoImage?: boolean,
    hasTitle?: boolean,
    articleList: NSApp.Article[],
    latestArticle: NSApp.Article[],
    onChangePagination: (pagination) => void;
}

const ArticlesListComponent = (props: ArticlesListProps) => {
    const { category, searchKey, listNoImage, articleList, latestArticle, width, height, pagination, horizontal, column,
        onChangePagination } = props
    const [firstArticle, setFirstArticle] = useState<NSApp.Article>()
    const [ articleDetail, setArticleDetail] = useState<NSApp.Article>()
    const [ isModalVisible, setIsModalVisible ] = useState(false);
    const [themeMode,] = themeModeGlobalState()
    const history = useHistory()

    const goToArticleDetail = (article: NSApp.Article) => {
        setArticleDetail(article);
        setIsModalVisible(true);    
    }

    const hideModal = () => {
        setIsModalVisible(false);
    }

    const renderArticle = (item: NSApp.Article, imageWidth?: number, noImage?: boolean) => {
        const width = imageWidth ? imageWidth : 125
        const height = width * 9 / 16
        return(
            <List.Item>
                <List.Item.Meta
                    avatar={(listNoImage || noImage) ? <></> :
                        <div
                            className="overflow-hidden relative"
                            style={{ height: height }}
                        >
                            <img
                                className="rounded-sm"
                                width={width}
                                height={height}
                                src={item?.thumbnail || ""}
                                alt={item.title}
                                // onError={onErrorThumbnail}
                                style={{ width: width }}
                            />
                        </div>
                    }
                    title={
                        <div className={` text-two-line`}>
                            <span className="cursor-pointer hover:text-orange-500"
                                onClick={() => goToArticleDetail(item)}>
                                {item?.title}
                            </span>
                        </div>
                    }
                    description={
                        <Space>
                            <span className="font-bold">{item?.link}</span>
                            <>
                                <span><CalendarOutlined/> {moment(item?.createdAt).format("L")}</span>
                                <span><ClockCircleOutlined/> {moment(item?.createdAt).format("LT")}</span>
                            </>
                        </Space>
                    }
                />
            </List.Item>
        )
    }

    const renderLastestArticle = () => {
        return <Row className="my-1">
                    <Col span={8}>
                        <div
                            className="overflow-hidden relative"
                            style={{ height: width && (width > config.RESPONSIVE_SCREEN.xxl) ? 180 : 150 }}
                        >
                            <img
                                className="rounded-sm w-full absolute m-auto inset--100"
                                // height={width && (width > config.RESPONSIVE_SCREEN.xxl) ? 230 : 130}
                                src={firstArticle?.thumbnail || ""}
                                alt={firstArticle?.title}
                                // onError={onErrorThumbnail}
                            />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-two-line">
                                <span className="cursor-pointer text-base font-medium hover:text-orange-500"
                                    onClick={() => firstArticle && goToArticleDetail(firstArticle)}
                                >
                                    {firstArticle?.title}
                                </span>
                            </div>
                            <Space className="mt-2 text-description">
                                <span>{moment(firstArticle?.createdAt).fromNow()}</span>
                            </Space>
                        </div>
                    </Col>
                    <Col span={16}>
                        <List
                            size="small"
                            split={false}
                            dataSource={latestArticle}
                            grid={{ column: 2 }}
                            renderItem={(item: any) => renderArticle(item, height/6+10)}
                        />
                    </Col>
                </Row>
    }

    let cardProps: any = {};

    if (category) {
        cardProps = {
            ...cardProps,
            title: <div className="flex justify-between items-center">
                {category &&
                    <Typography.Text className="text-xl leading-6" strong>{category.name}</Typography.Text>
                }
            </div>
        };
    }
    
    let latestArticlesColSpan = 24;
    if (width && width > config.RESPONSIVE_SCREEN.md) latestArticlesColSpan = 12;
    if (width && width > config.RESPONSIVE_SCREEN.lg) latestArticlesColSpan = 8;
    let articleListColSpan = (width && width > config.RESPONSIVE_SCREEN.lg) ? 8 : 24;

    useEffect(() => {
        if (latestArticle && latestArticle.length) {
            setFirstArticle(latestArticle.shift())
        } 
    },[articleList])

    return(
        <Card
            bordered={false}
            size="small"
            headStyle={{
                padding: "0 0.25rem",
                backgroundColor: colors.customTabBackground[themeMode],
            }}
            bodyStyle={{ padding: 4, height: height - 77, overflow: 'auto' }}
            {...cardProps}
        >
            {horizontal ?
                <Row gutter={8}>
                    <Col span={latestArticlesColSpan}>
                        <div
                            className="overflow-hidden mt-1 relative"
                            style={{ height: 165 }}
                        >
                            <img
                                className="rounded-sm w-full absolute m-auto inset--100"
                                // height={height/2-20}
                                src={firstArticle?.thumbnail || ""}
                                alt={firstArticle?.title}
                                // onError={onErrorThumbnail}
                            />
                        </div>
                        <div className="flex flex-col">
                            <div className="text-two-line">
                                <span className="cursor-pointer text-base font-medium hover:text-orange-500"
                                    onClick={() => firstArticle && goToArticleDetail(firstArticle)}
                                >
                                    {firstArticle?.title}
                                </span>
                            </div>
                            <Space className="mt-2 text-description">
                                <span>{moment(firstArticle?.createdAt).fromNow()}</span>
                            </Space>
                        </div>
                    </Col>
                    <Col span={latestArticlesColSpan}>
                        {latestArticle?.map((item, index) => {
                            if (index < 3) {
                                return <div key={item.id}>{renderArticle(item, 140, false)}</div>
                            }
                            return <div key={`"empty"+${index}`}></div>;
                        })}
                    </Col>
                    <Col span={articleListColSpan}>
                        <List
                            size="small"
                            split={false}
                            dataSource={articleList?.slice(0, 5)}
                            renderItem={(item: any) => (
                                <List.Item key={item._id}>
                                    <List.Item.Meta
                                        title={<div className="text-one-line">
                                                <span className="cursor-pointer hover:text-orange-500"
                                                    onClick={() => goToArticleDetail(item)}>
                                                    {item?.title}
                                                </span>
                                            </div>
                                        }
                                        description={<Space>
                                            <span><CalendarOutlined/> {moment(item?.createdAt).format("L")}</span>
                                            <span><ClockCircleOutlined/> {moment(item?.createdAt).format("LT")}</span>
                                        </Space>}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
                :
                <>
                    {renderLastestArticle()}
                    <List
                        className={column === 3 ? "mt-4 -ml-2" : ""}
                        size="small"
                        split={false}
                        dataSource={articleList}
                        renderItem={(item: any) => renderArticle(item, 0, true)}
                        grid={{ gutter: 8, column: column || 1 }}
                        pagination={latestArticle?.length ?
                            {
                                onChange: (page) => {
                                    onChangePagination({ current: page });
                                },
                                pageSize: config.PAGE_SIZE,
                                total: pagination.total,
                            } : false
                        }
                    />
                </>
            }
        <Modal
            visible={isModalVisible}
            onCancel={hideModal}
            footer={null}
            width={992}
            style={{ top: 50 }}
        >
            {articleDetail && (
                <>
                    <ArticleDetailPage articleDetail={articleDetail} />
                </>
            )}
        </Modal>
        </Card>
    )
}

export default ArticlesListComponent