import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { Card, Table, Col, Row, Typography, Divider } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from "react-i18next";
import moment from 'moment';

import NameWithChartIcon from "../../../../../components/NameWithChartIcon";
import { StockContext } from '../../../../../../../../contexts/StocksProvider';
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import { getStockColorClass } from "../../../../../../../../services/utils";
import { localeFormat, priceFormat, priceChangePercentFormat } from "../../../../../../../../services/stock-price";
import api from '../../../../../../../../services/api';
import colors from '../../../../../../../../themes/colors';

type EtfStockListProps = {
    etfCode: string;
    layoutHeight: number;
}

const TAB_HEADER_HEIGHT = 45;

const CellValueHasColor = ({ value, stock, columnKey }) => {
    const [themeMode,] = themeModeGlobalState();

    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][stock.stockCode],
    );

    if (columnKey === 'stockCode') {
        if (stockState?.stockCode) {
            return <NameWithChartIcon
                color={getStockColorClass(stockState, themeMode)}
                symbol={stockState?.stockCode.toUpperCase()}
            />
        }
        return <span style={{ fontWeight: 'bold' }}>{stock.stockCode.toUpperCase()}</span>
    }

    if (columnKey === 'matchedPrice') {
        if (stockState?.matchedPrice) {
            return <span style={{ color: getStockColorClass(stockState, themeMode), fontWeight: 'bold' }}>
                {priceFormat(stockState.matchedPrice, 1000, 2, true)}
            </span>
        }
        return <></>;
    }

    if (columnKey === 'pricePercentChange') {
        if (stockState?.priceChange) {
            return <span style={{ color: getStockColorClass(stockState, themeMode), fontWeight: 'bold' }}>
                {stockState.priceChange > 0 && '+'}
                {`${priceChangePercentFormat(stockState.pricePercentChange)}%`}
            </span>
        }
        return <></>;
    }

    return <span style={{ color: getStockColorClass(stockState, themeMode), fontWeight: 'bold' }}>{localeFormat(value)}</span>
};


const EtfStockList = (props: EtfStockListProps) => {
    const { etfCode, layoutHeight } = props;
    const [etfDetail, setEtfDetail] = useState<NSApp.EtfDetail>();
    const [stockList, setStockList] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const wrapperHeight = layoutHeight > 400 ? layoutHeight : 400;
    const tableContentHeight = wrapperHeight * 2/3;

    const renderNormalValue = (value) => {
        if (!value) {
            return <></>;
        }

        return <span className="font-bold">{localeFormat(value)}</span>;
    }

    const renderPercentFormat = (value) => {
        if (!value) {
            return <></>;
        }

        return <span className="font-bold">{priceChangePercentFormat(value)}%</span>;
    }

    const renderColorText = (value, stock, columnKey) => {
        return <CellValueHasColor value={value} stock={stock} columnKey={columnKey} />
    }

    const fetchEtfStockList = async () => {
        setLoading(true);
        let [error, response]: any = await api.get(`stock/etf/${etfCode}/stock-list`);
        setLoading(false);

        if (error) {
            return [error, null];
        }

        setStockList(response.data);
    };


    const fetchEtfDetail = async () => {
        setLoading(true);
        let [error, response]: any = await api.get(`stock/etf/${etfCode}`);
        setLoading(false);

        console.log(error, response);
        if (error) {
            return [error, null];
        }

        setEtfDetail(response.data);
    };

    useEffectOnce(() => {
        fetchEtfDetail();
        fetchEtfStockList();
    });

    const columns: ColumnsType<NSApp.EtfStockList> = [
        {
            width: 80,
            title: t('Mã cổ phiếu'),
            dataIndex: 'stockCode',
            key: 'stockCode',
            showSorterTooltip: false,
            fixed: 'left',
            render: (value, stock) => renderColorText(value, stock, 'stockCode'),
        },
        {
            width: 80,
            title: t('Giá'),
            key: 'matchedPrice',
            dataIndex: 'matchedPrice',
            showSorterTooltip: false,
            align: 'right',
            render: (value, stock) => renderColorText(value, stock, 'matchedPrice'),
        },
        {
            width: 80,
            title: t('+/-(%)'),
            key: 'pricePercentChange',
            dataIndex: 'pricePercentChange',
            showSorterTooltip: false,
            align: 'right',
            render: (value, stock) => renderColorText(value, stock, 'pricePercentChange'),
        },
        {
            width: 80,
            title: t('Số lượng'),
            dataIndex: 'totalVolume',
            key: 'totalVolume',
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 120,
            title: t('Giá ngày báo cáo'),
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 150,
            title: t('Tổng giá trị'),
            dataIndex: 'totalValue',
            key: 'totalValue',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 80,
            title: t('Tỷ trọng'),
            dataIndex: 'ratio',
            key: 'ratio',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },

        {
            width: 80,
            title: t('EPS'),
            dataIndex: 'eps',
            key: 'eps',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 80,
            title: t('BV'),
            dataIndex: 'bookvalue',
            key: 'bookvalue',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => value ? <span className="font-bold">{localeFormat(value / 1000)}</span> : <></>,
        },
        {
            width: 80,
            title: t('VPS'),
            dataIndex: 'valuepershare',
            key: 'valuepershare',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => value ? <span className="font-bold">{value ? localeFormat(value / 1000) : 0}</span> : <></>,
        },
        {
            width: 80,
            title: t('P/E'),
            dataIndex: 'pe',
            key: 'pe',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 80,
            title: t('P/B'),
            dataIndex: 'pb',
            key: 'pb',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 80,
            title: t('ROA'),
            dataIndex: 'roarecent',
            key: 'roarecent',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 80,
            title: t('ROE'),
            dataIndex: 'roerecent',
            key: 'roerecent',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 80,
            title: t('Tỷ suất cổ tức'),
            dataIndex: 'dividendyield',
            key: 'dividendyield',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('Vốn hóa'),
            dataIndex: 'marketcap',
            key: 'marketcap',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => value ? <span className="font-bold">{localeFormat(value / (1000 * 1000 * 1000))}</span> : <></>,
        },
        {
            width: 150,
            title: t('SLCPLH'),
            dataIndex: 'sharesoutstanding',
            key: 'sharesoutstanding',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 80,
            title: t('SHNN'),
            dataIndex: 'foreignpercent',
            key: 'foreignpercent',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value * 100),
        },
        {
            width: 80,
            title: t('Free Float'),
            dataIndex: 'freefloatpercent',
            key: 'freefloatpercent',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 100,
            title: t('Room NN còn lại'),
            dataIndex: 'foreignvolumeroom',
            key: 'foreignvolumeroom',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
    ];

    return (
        <div style={{height: wrapperHeight - TAB_HEADER_HEIGHT, overflow: 'scroll'}}>
            <Table
                dataSource={stockList}
                columns={columns}
                scroll={{ y: tableContentHeight }}
                rowKey={(record: NSApp.EtfStockList) => record.stockCode}
                loading={loading}
                pagination={false}
                bordered
                footer={() => <small className="text-one-line">{t('Giá')} x 1,000 {t('VNĐ')}. {t('Khối lượng')} x 1 {t('CP')}. {t('Vốn hoá')} x 1 {t('Tỷ VNĐ')}</small>}
            />
            <Card className="mt-1">
                <Row>
                    <Col xs={24} md={12} lg={8}>
                        <Row className="mb-2">
                            <Col span={8}></Col>
                            <Col span={8}></Col>
                            <Col span={8} className="text-sm text-right">{t('Tỷ trọng')}</Col>
                        </Row>
                        <Row className="mb-2">
                            <Col span={8} className="text-sm">{t('Tổng giá trị cổ phiếu')}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.totalShareValue ? renderNormalValue(etfDetail?.totalShareValue) : '-'}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.ratioShare ? renderPercentFormat(etfDetail?.ratioShare) : '-'}</Col>
                        </Row>
                        <Row className="mb-2">
                            <Col span={8} className="text-sm">{t('Tiền, tương đương tiền')}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.totalCash ? renderNormalValue(etfDetail?.totalCash) : '-'}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.ratioCash ? renderPercentFormat(etfDetail?.ratioCash) : '-'}</Col>
                        </Row>
                        <Row className="mb-2">
                            <Col span={8} className="text-sm">{t('Tài sản khác')}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.totalOtherAssets ? renderNormalValue(etfDetail?.totalOtherAssets) : '-'}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.ratioOtherAssets ? renderPercentFormat(etfDetail?.ratioOtherAssets) : '-'}</Col>
                        </Row>
                        <Divider className="my-2" />
                        <Row className="mb-2">
                            <Col span={8} className="text-sm">{t('Tổng giá trị danh mục')}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.totalPortfolioValue ? renderNormalValue(etfDetail?.totalPortfolioValue) : '-'}</Col>
                            <Col span={8} className="text-sm text-right">{etfDetail?.totalPortfolioValue ? renderPercentFormat(etfDetail ? 100 : null) : '-'}</Col>
                        </Row>
                        <Typography.Text italic>* {t('Ngày cập nhật')}: <span className="font-bold">{etfDetail?.dataUploadAt ? moment(etfDetail.dataUploadAt).format('DD/MM/YYYY HH:mm:ss') : '--/--/--'}</span></Typography.Text>
                    </Col>
                    <Col xs={24} md={12} lg={12} className="ml-5">
                        <Row className="mb-2">
                            <Col span={8} className="text-sm text-left">{t('Thông tin')}</Col>
                            <Col span={8}></Col>
                            <Col span={16}></Col>
                        </Row>
                        <Row className="mb-2">
                            <Col span={8} className="text-sm">{t('Tên quỹ')}</Col>
                            <Col span={16} className="text-sm text-left">{etfDetail?.name}</Col>
                        </Row>
                        <Row className="mb-2">
                            <Col span={8} className="text-sm">{t('Công ty quản lý quỹ')}</Col>
                            <Col span={16} className="text-sm text-left">{etfDetail?.company}</Col>
                        </Row>
                        <Row className="mb-2">
                            <Col span={8} className="text-sm">{t('Mục tiêu đầu tư')}</Col>
                            <Col span={16} className="text-sm text-left">{etfDetail?.investmentGoal}</Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </div>
    );
}

export default EtfStockList;
