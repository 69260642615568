import React from 'react';

import { message } from 'antd';
import { useHistory } from "react-router-dom";

import DashboardForm from './_Form';
import api from "../../../../services/api";
import routes from "../../../../routes";
import AdminPermission from '../../../../components/AdminPermission';

const DashboardAddPage = () => {
    const history = useHistory();

    const addNewDashboard = async (payloads: any) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.post('plugins/dashboard-template/add', payloads);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Thêm mới dashboard thành công");
        history.replace(routes.ADMIN_DASHBOARD_MANAGER_LIST);
    }

    return <AdminPermission roleName={[]}>
        <>
            <DashboardForm onSubmit={addNewDashboard} mode="add" />
        </>
    </AdminPermission>
}

export default DashboardAddPage;
