const colorsClass = {
    primary: '#F88616',
    black: '#0F0F0F',
    white: '#FFFFFF',
    lightGray: '#FAFAFA',
    darkGray: '#ced4da',
    blurWhite: 'rgba(255, 255, 255, .3)',

    chartBackground: {
        dark: '#131722',
        light: '#f8f9fa',
    },

    customTabBackground: {
        dark: '#2C2C2C',
        light: '#efeff1',
    },

    componentBackground: {
        dark: '#1F1F1F',
        light: '#efeff1',
    },

    chartAxis: {
        dark: '#E5E5E5',
        light: '#1F1F1F',
    },
    orange: {
        background: '#F88616',
        light: '#F48C06',
        text: '#E2BC5B'
    },
    purple: {
        background: '#9D3FA7',
        text: {
            dark: '#C879FF',
            light: '#be00be',
        }
    },
    green: {
        background: '#129072',
        text: {
            dark: '#03DD5E',
            light: '#03A66D',
        },
        opacity: 'rgba(#129072, .3)'
    },
    yellow: {
        background: '#E7BB26',
        text: {
            dark: '#f6de26',
            light: '#C99400',
        }
    },
    red: {
        background: '#D62D43',
        text: {
            dark: '#e5383b',
            light: '#CF304A',
        },
        opacity: 'rgba(#D62D43, .3)'
    },
    teal: {
        background: '#2EBCE4',
        text: {
            dark: '#4cc9f0',
            light: '#088db7',
        }
    },
    gray: {
        background: '#A0AEC0',
        text: {
            dark: '#CBD5E0',
            light: '#666666',
        },
    },
    tableRowDarker: {
        dark: '#181c27',
        light: '#FFF'
    }
};

export const stockColors = {
    ceiling: colorsClass.purple.text,
    increase: colorsClass.green.text,
    keep: colorsClass.yellow.text,
    reduced: colorsClass.red.text,
    floor: colorsClass.teal.text,
};

export default colorsClass;
