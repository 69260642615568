import api from '../../../../../../../services/api';
import * as config from "../../../../../../../config";

export const fetchNewsData = async (filter, page, pageSize) => {
    // eslint-disable-next-line
    let [error, response]: any = await api.get(`plugins/news`, {
        filter,
        page,
        pageSize,
    });

    if (error) {
        return [error.response.data.message, null];
    }

    return [null, response.data];
};

export const fetchNewsCategories = async (filter, page) => {
    let [error, response]: any = await api.get(`plugins/news/category`, {
        filter,
        page,
        pageSize: config.PAGE_SIZE,
    });

    if (error) {
        return [error.response.data.message, null];
    }

    return [null, response.data];
}

export const getNewsDetail = async (id: number) => {
    return await api.get(`plugins/news/${id}`);
};

export const getComments = async (id: number) => {
    return await api.get(`plugins/news/${id}/comments`);
};

export const postComment = async (id: number, content: string) => {
    return await api.post(`plugins/news/${id}/comments`, {
        content,
    });
};

export const like = async (id: number) => {
    return await api.post(`plugins/news/${id}/like`, {});
};

export const unlike = async (id: number) => {
    return await api.post(`plugins/news/${id}/unlike`, {});
};
