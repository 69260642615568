import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, DatePicker, Modal, Typography, Tag, Col, Row, Card } from 'antd';
import { useTranslation } from "react-i18next";
import { useAuthState } from "../../../../../contexts";
import moment from 'moment';
import api from "../../../../../services/api";

const { Text, Paragraph } = Typography;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

type AffiliateProfileProps = {
    affiliate: NSApp.UserAffiliate,
    onSuccessSubmited: () => void,
};

const AffiliateProfile = (props: AffiliateProfileProps) => {
    const { affiliate, onSuccessSubmited } = props;
    const { t } = useTranslation();
    const { authState, } = useAuthState() as any;
    const { currentUser } = authState;
    const [ModalResult, contextHolder] = Modal.useModal();

    const [form] = Form.useForm();

    let affiliateStatusStr = '';
    let inputDisabled = !(affiliate.status === 'not-submited');

    if (affiliate.status === 'not-submited') {
        affiliateStatusStr = t('Chưa đăng ký');
    }

    if (affiliate.status === 'approved') {
        affiliateStatusStr = t('Đã xác nhận');
    }

    if (affiliate.status === 'rejected') {
        affiliateStatusStr = t('Từ chối');
    }

    if (affiliate.status === 'processing') {
        affiliateStatusStr = t('Đang xử lý');
    }

    const initialValues = {
        email: affiliate.email ? affiliate.email : currentUser.email,
        taxCode: affiliate.taxCode ? affiliate.taxCode : '',
        name: affiliate.name ? affiliate.name : currentUser.fullname,
        phone: affiliate.phone ? affiliate.phone : currentUser.phone,
        identityNumber: affiliate.identityNumber || '',
        dateOfBirth: affiliate.dateOfBirth ? moment(affiliate.dateOfBirth) : '',
        identityDate: affiliate.identityDate ? moment(affiliate.identityDate) : '',
        identityPlace: affiliate.identityPlace || '',
        address: affiliate.address || '',
        bankName: affiliate.bankName || '',
        bankBranch: affiliate.bankBranch || '',
        bankAccountName: affiliate.bankAccountName || '',
        bankAccountNumber: affiliate.bankAccountNumber || ''
    }

    useEffect(() => form.resetFields(), [form, initialValues]);

    const [readOnly, setReadOnly] = useState<boolean>(!(affiliate.status === 'not-submited'));

    const onFinishUpdateInfo = async (values: any) => {
        let [err, resp]: any = await api.post('/affiliate/register', {
            name: values?.name,
            phone: values?.phone,
            email: values?.email,
            taxCode: values?.taxCode,
            bankName: values?.bankName,
            bankAccountName: values?.bankAccountName,
            bankAccountNumber: values?.bankAccountNumber,
            bankBranch: values?.bankBranch,
            identityNumber: values?.identityNumber,
            dateOfBirth: values?.dateOfBirth?.format('YYYY-MM-DD'),
            identityDate: values?.identityDate?.format('YYYY-MM-DD'),
            identityPlace: values?.identityPlace,
            address: values?.address,
        });

        if (err) {
            return notification.error({
                message: t('Cập nhật thông tin lỗi, vui lòng thử lại'),
                description: err?.response?.data?.message || err.message,
            });
        }

        ModalResult.success({
            content: (
                <Text>
                    {t('Đăng ký thành công')}
                </Text>
            ),
            onOk() {
                onSuccessSubmited()
            }
        });
        // setAuthState({ currentUser: resp?.data?.userInfo });
        notification.success({
            message: t('Cập nhật thông tin thành công'),
        });
        setReadOnly(true)
    };

    return (
        <Row gutter={16}>
            <Col span={12}>
                <Form
                    {...layout}
                    form={form}
                    className="my-4 max-w-screen-sm"
                    size="middle"
                    name="updateInfo"
                    labelAlign="left"
                    onFinish={onFinishUpdateInfo}
                    initialValues={initialValues}
                >
                    <Form.Item label={t('Tình trạng hồ sơ')}>
                        <span className='text-primary'>{affiliateStatusStr}</span>
                    </Form.Item>
                    <Form.Item
                        label={t('Họ Tên')} name="name"
                        rules={[
                            { required: true, message: t('Không được để trống') },
                        ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Điện thoại')} name="phone" rules={[
                        { len: 10, message: t('Số điện thoại phải có 10 số') },
                        () => ({
                            validator(rule, value) {
                                if (!isNaN(value) && value.startsWith('0')) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(t('Số điện thoại phải bắt đầu bằng số 0'));
                                }
                            },
                        }),
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Email')} name="email" rules={[
                        { type: 'email', message: t('Email không đúng định dạng') },
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Mã số thuế')} name="taxCode" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Số CMND')} name="identityNumber" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Ngày sinh')} name="dateOfBirth" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <DatePicker placeholder="" style={{ width: '100%'}} disabled={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Ngày cấp')} name="identityDate" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <DatePicker placeholder="" style={{ width: '100%'}} disabled={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Nơi cấp')} name="identityPlace" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Địa chỉ liên hệ')} name="address" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Tên ngân hàng')} name="bankName" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Chi nhánh ngân hàng')} name="bankBranch" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Tên chủ tài khoản')} name="bankAccountName" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    <Form.Item label={t('Số tài khoản')} name="bankAccountNumber" rules={[
                        { required: true, message: t('Không được để trống') },
                    ]}>
                        <Input readOnly={readOnly} />
                    </Form.Item>

                    { affiliate.status === 'not-submited' &&
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">{t('Đăng ký')}</Button>
                        </Form.Item>
                    }

                    { affiliate.status === 'processing' &&
                        <Tag color="orange">{t('Tài khoản đang được xử lý bởi admin')}</Tag>
                    }

                    { affiliate.status === 'rejected' &&
                        <Tag color="red">{t('Tài khoản đã bị từ chối bởi admin')}</Tag>
                    }
                    {contextHolder}
                </Form>
            </Col>
            <Col span={6}>
                <Card className="my-4 max-w-screen-sm text-sm">
                    <Paragraph className='text-primary text-lg'>   
                        {t('Quy trình đăng kí CTV để nhận hoa hồng hằng tháng')}
                    </Paragraph>
                    <Paragraph>
                        {t('Bước 1: Cập nhật tất cả các trường ở form Hồ sơ đăng kí Cộng Tác Viên.')}
                    </Paragraph>
                    <Paragraph>
                        {t('Bước 2 : Sau khi đăng kí thành công, Fstock sẽ liên hệ với bạn để kí hợp đồng CTV')}
                    </Paragraph>
                    <Paragraph>
                        {t('Bước 3: In hợp đồng, kí tên và gửi theo đường bưu điện về văn phòng Fstock theo địa chỉ : “Công ty cổ phần công nghệ tài chính Fcapitatl, Tầng 09, Số 09 Tô Hiến Thành, Phường Nguyễn Du, Quận Hai Bà Trưng, Thành phố Hà Nội, Việt Nam”')}
                    </Paragraph>
                    <Paragraph>
                        {t('Ghi chú :  Sau khi xác nhận đăng kí, trường hợp muốn thay đổi thông tin, vui lòng liên hệ với chúng tôi qua số đt : (+84) 966 69 89 69')}
                    </Paragraph>
                </Card>
            </Col>
        </Row>
    );
};

export default AffiliateProfile;
