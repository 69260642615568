import React from 'react';
import UserACL from '../../../../../../components/UserACL';
import ArticleContainer from './container';

const ArticlesWindow = (props) => {
    return (
        // <UserACL feature="article">
            <div className="h-full px-1">
                <ArticleContainer {...props}/>
            </div>
        // </UserACL>
    )
};

export default ArticlesWindow;