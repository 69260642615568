import React, { useCallback } from "react";
import { Tabs, Radio } from "antd";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";

import { HeatMapType } from "../../../../../../../types/plugins/heatMap";
import { priceFormat, priceChangePercentFormat, localeFormat } from "../../../../../../../services/stock-price";
import { getStockColorClass, getChartColor } from "../../../../../../../services/utils";
import useStockDetailModalState from "../../../../../../../contexts/StockDetailModal";
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

import theme from "../../../../../../../themes/theme";
import Loading from "../../../../../../../components/Loading";

type HeatMapComponentProps = {
    width: number,
    height: number,
    heatMapType: string,
    heatMapTitle: string,
    heatMapDataKey: string,
    heatMapData: HeatMapType[],
    loading: boolean,
    setLoading: (loading: boolean) => void,
    changeExchange: (exchange: string) => void,
    changeHeatMapType: (heatMapType: string) => void
};

const HeatMapComponent = (props: HeatMapComponentProps) => {
    const {
        height,
        width,
        heatMapType,
        heatMapData,
        heatMapTitle,
        heatMapDataKey,
        loading,
        setLoading,
        changeExchange,
        changeHeatMapType
    } = props;

    const [themeMode,] = themeModeGlobalState();
    const [, setStockDetailModalState] = useStockDetailModalState();
    const { t } = useTranslation();

    const isDarkMode = themeMode === 'dark';
    let capitalStockType = heatMapType === "capitalStock";

    const onChangeExChange = (key) => {
        setLoading(true);
        changeExchange(key);
    };

    const onChangeStockType = (e) => {
        setLoading(true);
        changeHeatMapType(e.target.value);
    };

    const options = {
        series: [
            {
                type: 'treemap',
                width: '100%',
                height: '100%',
                visualMin: 0,
                visualMax: 4,
                visualDimension: 2,
                roam: false,
                nodeClick: false,
                data: heatMapData,
                breadcrumb: {
                    show: false,
                },
                upperLabel: {
                    formatter: '{b}',
                },
                label: {
                    formatter: (params: any) => {
                        const newLineChar = String.fromCharCode(10);
                        return `${params.name}${newLineChar}${params.value[1]}`;
                    },
                    fontWeight: 'bold',
                },
                labelLayout(params: any) {
                    const fontSizeByWidth = params.rect.width / 6;
                    const fontSizeByHeight = params.rect.height / 5;
                    const fontSize =
                        fontSizeByWidth < fontSizeByHeight
                            ? fontSizeByWidth
                            : fontSizeByHeight;
                    return {
                        fontSize: fontSize,
                    };
                },
                levels: [
                    {
                        itemStyle: {
                            gapWidth: 0.5,
                            borderWidth: 0,
                            borderColor: isDarkMode ? theme.colors.gray.text.light : theme.colors.darkGray,
                        },
                        upperLabel: {
                            show: false,
                        },
                    },
                    {
                        color: [
                            theme.colors.teal.background,
                            theme.colors.red.background,
                            theme.colors.yellow.background,
                            theme.colors.green.background,
                            theme.colors.purple.background,
                        ],
                        colorMappingBy: 'value',
                        itemStyle: {
                            borderColor: isDarkMode ? theme.colors.black : theme.colors.chartBackground.light,
                            borderWidth: 2,
                            gapWidth: 0.5,
                        },
                        upperLabel: {
                            show: true,
                            color: isDarkMode ? theme.colors.white : theme.colors.black,
                            backgroundColor: isDarkMode ? theme.colors.black : theme.colors.chartBackground.light,
                            height: 24,
                            padding: [0, 0, 0, 3],
                            fontWeight: '600',
                        },
                    },
                    {
                        itemStyle: {
                            borderWidth: 2,
                            gapWidth: 1,
                            borderColorSaturation: 0.7,
                        },
                    },
                ],
            },
        ],
        tooltip: {
            backgroundColor: isDarkMode ? theme.colors.componentBackground.dark : theme.colors.white,
            borderWidth: 0,
            padding: 0,
            formatter: function (params) {
                const { industryCode, stockCode } = params.data;
                const industry = heatMapData.find(industry => industry.industryCode === industryCode);
                const isBillionUnit = capitalStockType
                            || heatMapType === 'totalTradeValue'
                            || heatMapType === 'buyForeignValue'
                            || heatMapType === 'sellForeignValue';
                            const valueRatio =  isBillionUnit ? 1000000000 : 1;
                            const unitLabel = isBillionUnit ? ` (${t('Tỷ')})` : "";

                if (!industry) {
                    return;
                }

                const textColor = isDarkMode ? theme.colors.white : theme.colors.black;
                const borderColor = isDarkMode ? theme.colors.black : theme.colors.darkGray;

                return '<div className="tooltip">'
                    + `<div style="padding: 5px 10px; background-color: ${theme.colors.primary}; color: ${theme.colors.white}; font-weight: bold">` + industry.name + '</div>'
                    + `<div style="font-size: 11px; text-transform: uppercase; font-weight: bold; padding: 5px 10px; display: flex; color: ${textColor}; border-bottom: 0.5px solid ${borderColor}">`
                        + `<div style="width: 50px;">${t('Mã')}</div>`
                        + `<div style="width: 50px; text-align: right">${t('Giá')}</div>`
                        + '<div style="width: 70px; text-align: right">+/-(%)</div>'
                        + `<div style="width: 100px; text-align: right">${heatMapTitle}${unitLabel}</div>`
                    + '</div>'
                    + `<div style="font-size: 12px; color: ${textColor}; font-weight: bold;">`
                        + industry.children.map((stock: any) => {
                            const isCurrentStock = stockCode === stock.stockCode;
                            const { name, matchedPrice, pricePercentChange } = stock;

                            return `<div style="${isCurrentStock ? `background-color: ${getChartColor(stock, themeMode)}` : ''}; display: flex; padding: 5px 10px; border-bottom: 0.5px solid ${borderColor}">`
                                + `<div style="width: 50px; color: ${isCurrentStock ? theme.colors.white : getStockColorClass(stock, themeMode)}">` + name + '</div>'
                                + `<div style="width: 50px; color: ${isCurrentStock ? theme.colors.white : getStockColorClass(stock, themeMode)}; text-align: right">` + priceFormat(matchedPrice) + '</div>'
                                + `<div style="width: 70px; color: ${isCurrentStock ? theme.colors.white : getStockColorClass(stock, themeMode)}; text-align: right">${pricePercentChange > 0 ? '+' : ''}${priceChangePercentFormat(pricePercentChange)}%</div>`
                                + '<div style="width: 100px; text-align: right">' + localeFormat(stock[heatMapDataKey] / valueRatio) + '</div>'
                            + '</div>';
                        }).join('')
                    + '</div>'
                + '</div>';
            },
        },
    };

    const openStockDetailModal = useCallback((params) => {
        if (!params.data.stockCode) {
            return;
        }
        setStockDetailModalState({
            stockCode: params.data.stockCode,
            isVisible: true
        })
    }, [setStockDetailModalState]);

    const onEvents = {
        'click': openStockDetailModal,
    }

    return (
        <div className="plugin-wrapper h-full p-1">
            <Tabs
                defaultActiveKey="all"
                size="small"
                onChange={onChangeExChange}
                tabBarStyle={{ fontSize: 10 }}
            >
                <Tabs.TabPane tab={t('Toàn thị trường')} key="all"></Tabs.TabPane>
                <Tabs.TabPane tab="HOSE" key="hose"></Tabs.TabPane>
                <Tabs.TabPane tab="HNX" key="hnx"></Tabs.TabPane>
                <Tabs.TabPane tab="UPCOM" key="upcom"></Tabs.TabPane>
            </Tabs>

            <Radio.Group
                value={heatMapType}
                onChange={onChangeStockType}
                size="small"
                buttonStyle="solid"
                className="mb-1"
            >
                <Radio.Button className="mr-1 font-bold" value="totalTradeValue">{t('Giá trị')}</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="totalTrade">{t('Khối lượng')}</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="capitalStock">{t('Vốn hoá')}</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="buyForeignValue">{t('GTNN Mua')}</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="sellForeignValue">{t('GTNN Bán')}</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="buyForeignQtty">{t('KLNN Mua')}</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="sellForeignQtty">{t('KLNN Bán')}</Radio.Button>
            </Radio.Group>

            {loading ?
                <Loading />
                :
                <>
                    <ReactECharts
                        option={options}
                        onEvents={onEvents}
                        style={{
                            width: width,
                            height: height - 70
                        }}
                    />
                </>
            }
        </div>
    );
};

export default HeatMapComponent;
