import React, { useState, useEffect } from "react";
import { Card, Typography, Divider, Button, Space, Popconfirm, message } from "antd";
import { Link, useParams, useHistory } from "react-router-dom";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import api from "../../../../services/api";
import routes from "../../../../routes";
import { toRoute } from "../../../../services/utils";
import ChangeStatusButton from './_ChangeStatusButton';
import Loading from "../../../../components/Loading";

const { Title, Paragraph } = Typography;

const RecommendationDetail = () => {
    const history = useHistory();
    const { recommendationId } = useParams<{recommendationId: string}>();
    const [ recommendation, setRecommendation ] = useState<NSApp.Recommendation>();

    const fetchRecommendation = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/recommendation/${recommendationId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setRecommendation(resp.data);
    };

    const deleteRecommendation = async (recommendationId: number) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.delete(`plugins/recommendation/${recommendationId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Xóa thành công');
        history.replace(routes.ADMIN_RECOMMENDATION_LIST);
    };

    useEffect(() => {
        fetchRecommendation()
        // eslint-disable-next-line
    }, []);

    if (!recommendation) {
        return <Loading/>
    }

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Khuyến nghị', path: routes.ADMIN_RECOMMENDATION_LIST },
                { label: recommendation.title },
            ]} />

            <Typography className="mt-3">
                <Title>{recommendation.title}</Title>

                <Space>
                    <Link to={toRoute(routes.ADMIN_RECOMMENDATION_EDIT, { recommendationId: recommendation.id })}>
                        <Button type="primary">Sửa</Button>
                    </Link>

                    <Popconfirm
                        title="Bạn chắc chắn muốn xóa khuyến nghị này?"
                        onConfirm={() => deleteRecommendation(recommendation.id)}
                        okText="Xóa"
                        cancelText="Hủy"
                    >
                        <Button type="primary" danger>Xóa</Button>
                    </Popconfirm>

                    <ChangeStatusButton recommendation={recommendation} onChange={fetchRecommendation}/>

                </Space>

                <Divider />
                <Paragraph>
                    <div dangerouslySetInnerHTML={{__html: recommendation.content}}/>
                </Paragraph>
            </Typography>
        </Card>
    )
}

export default RecommendationDetail;