import React, { useReducer, useEffect, useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { Layout, Spin } from 'antd';

import { DashboardContext, LayoutContext } from "../../../contexts";
import reducer from "../../../reducer";
import Sider from "./components/Sider";
import PrivateHeader from "../../../components/PrivateHeader";
import PrivateContent from "./Content";
import { WatchListContext, initialWatchListStore } from './WatchList/store';
import StockDetailModal from '../../../components/StockDetailModal/wrapper';
import { useContextSelector } from 'use-context-selector';
import { StockContext } from '../../../contexts/StocksProvider';
import { MainIndexContext } from '../../../contexts/MainIndexProvider';
import { StockForeignerContext } from '../../../contexts/StocksForeignerProvider';


import useStocksDefaultState from '../../../contexts/StocksDefault';
import useMainIndexDefaultState from '../../../contexts/MainIndexDefault';
import _ from 'lodash';
import * as tablePriceService from './Dashboard/plugins/TablePrice/services';
import * as mainIndexService from './Dashboard/plugins/MainIndex/services';

import { subscribeStockPrice, subscribeMainIndex, subscribeStockPriceForeigner } from "../../../services/socket";
import * as config from '../../../config';

const ClientPage = () => {
    const setStockDefaultGlobalState = useContextSelector(
        StockContext,
        (s) => s[1],
    );

    const setMainIndexGlobalState = useContextSelector(
        MainIndexContext,
        (s) => s[1],
    );

    const setStockForeignerGlobalState = useContextSelector(
        StockForeignerContext,
        (s) => s[1],
    );

    const [stocksDefault, setStocksDefault] = useStocksDefaultState();
    const [mainIndexDefault, setMainIndexDefault] = useMainIndexDefaultState();

    const initSubscriptionStockPrice = useCallback(() => {
        subscribeStockPrice((stockList: NSApp.Stock[]) => {
            if (stockList.length) {
                let newStocksStateArr: any[] = [];

                for (const _stock of stockList) {
                    if (!_stock.stockCode) {
                        continue;
                    }

                    let newStockData: any = {
                        stockCode: _stock.stockCode,
                    };

                    const stock = _.pick(_stock, config.STOCK_REALTIME_FIELDS);

                    for (const [key, value] of Object.entries(stock)) {
                        if (_stock.stockCode.startsWith('vn30')) {
                            if (value) {
                                newStockData[key] = _.isNumber(value) ? Number(value) : value;
                            }

                            if (key === 'priceChange' || key === 'pricePercentChange') {
                                newStockData[key] = Number(value);
                            }
                        } else {
                            newStockData[key] = _.isNumber(value) ? Number(value) : value;
                        }
                    }

                    newStocksStateArr.push(newStockData);
                }

                setStockDefaultGlobalState((oldState: any) => {
                    let updatedState: any = {};

                    for (const data of newStocksStateArr) {
                        const oldData = oldState[data.stockCode];

                        for (const [key, value] of Object.entries(data)) {
                            if (Number(value) <= 0 && oldData && oldData[key]) {
                                data[key] = oldData[key];
                            }
                        }

                        updatedState[data.stockCode] = {
                            ...oldData,
                            ...data,
                        };

                    }
                    return {
                        ...oldState,
                        ...updatedState,
                    };
                });
            }
        });
    }, [setStockDefaultGlobalState])

    const initSubscriptionStockPriceForeigner = useCallback(() => {
        subscribeStockPriceForeigner((stockForeignerList: any) => {
            if (stockForeignerList.length < 1) {
                return;
            }

            setStockForeignerGlobalState((oldState: any) => {
                const updatedState = _.keyBy(stockForeignerList, 'stockCode');
                return {
                    ...oldState,
                    ...updatedState,
                };
            });
        });
    }, [setStockForeignerGlobalState])

    const initSubscriptionMainIndexSocket = useCallback(() => {
        subscribeMainIndex((mainIndexValue: any) => {
            const indexID = mainIndexValue.indexID.toLowerCase();
            // let mainIndexData = mainIndexStateRef.current[indexID];

            // let dataDefault = _.find(
            //     mainIndexDefault,
            //     (m) => m.indexID === indexID,
            // );

            let newMainIndexData = {
                [indexID]: {
                    ...mainIndexValue,
                    refValue: mainIndexValue.refValue,
                    indexID,
                    change: mainIndexValue.change,
                    ratioChange: mainIndexValue.ratioChange,
                },
            };

            setMainIndexGlobalState((oldState: any) => ({
                ...oldState,
                ...newMainIndexData,
            }));
        });
    }, [setMainIndexGlobalState]);

    const fetchAllDefaultStocks = async () => {
        if (stocksDefault.length) {
            return;
        }

        const stocks = await tablePriceService.fetchAllDefaultStocks();

        const _stocksForeigner = stocks.map(s => {
            return {
                stockCode: s.stockCode,
                amountForeignValue: s.amountForeignValue,
                amountForeignVolume: s.amountForeignVolume,
                foreignBuyValue: s.foreignBuyValue,
                foreignBuyVolume: s.foreignBuyVolume,
                foreignSellValue: s.foreignSellValue,
                foreignSellVolume: s.foreignSellVolume
            }
        })

        const tablePriceStoreData = _.keyBy(
            stocks,
            'stockCode',
        );

        const stocksForeignerStoreData = _.keyBy(
            _stocksForeigner,
            'stockCode',
        )

        setStocksDefault(stocks);
        setStockDefaultGlobalState(tablePriceStoreData);
        setStockForeignerGlobalState(stocksForeignerStoreData)

        return tablePriceStoreData;
    }

    const fetchMainIndex = async (indexID = "vnindex") => {
        let response = await mainIndexService.fetchMainIndex(indexID);

        let mainIndexData = response.data;
        let mainIndexState = {
            [mainIndexData.indexID]: mainIndexData,
        };

        setMainIndexGlobalState((oldState: any) => ({
            ...oldState,
            ...mainIndexState,
        }));

        return mainIndexData;
    };

    const fetchListMainIndex = async () => {
        let dataDefault: any[] = [];

        for (const mainIndex of config.MAIN_INDEX) {
            let data = await fetchMainIndex(mainIndex.indexId);
            dataDefault.push(data);
        }
        setMainIndexDefault(dataDefault);
    };

    useEffectOnce(() => {
        fetchAllDefaultStocks();
        fetchListMainIndex();
    });

    useEffect(() => {
        initSubscriptionStockPriceForeigner();
    }, [initSubscriptionStockPriceForeigner]);

    useEffect(() => {
        initSubscriptionStockPrice();
    }, [initSubscriptionStockPrice]);

    useEffect(() => {
        initSubscriptionMainIndexSocket();
    }, [initSubscriptionMainIndexSocket]);

    const initialDashboardState = {
        listDashboard: [],
        defaultDashboard: null,
        selectedDashboard: '',
        currentDashboard: null,
        layoutConfig: null,
    };

    const initialLayoutState = {
        siderCollapsed: true,
        pluginDrawerVisible: false,
    };

    const [ dashboardState, setDashboardState ] = useReducer(reducer, initialDashboardState);
    const [ layoutState, setLayoutState ] = useReducer(reducer, initialLayoutState);

    if (!stocksDefault.length || !mainIndexDefault.length) {
        return <Spin />;
    }

    return (
        <DashboardContext.Provider value={{ dashboardState, setDashboardState }}>
            <LayoutContext.Provider value={{ layoutState, setLayoutState }}>
                <WatchListContext.Provider value={initialWatchListStore}>
                    <Layout className="min-h-screen">
                        <Sider/>
                        <Layout className="home-layout">
                            <PrivateHeader/>
                            <PrivateContent/>
                        </Layout>
                        <StockDetailModal />
                    </Layout>
                </WatchListContext.Provider>
            </LayoutContext.Provider>
        </DashboardContext.Provider>
    );
};

export default ClientPage;
