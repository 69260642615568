import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce, useUnmount } from 'react-use';
import { useContextSelector } from 'use-context-selector';

import * as NotificationService from './services';
import { subscribeNewNotification } from '../../../../services/socket';
import { NotificationContext } from '../../../../contexts/NotificationProvider';
import * as config from '../../../../config';

import NotificationList from './components/list';

const NotificationContainer = (props: { type: string, isCompact?: boolean, setDropdownVisible: Function }) => {
    const { type, isCompact, setDropdownVisible } = props;
    const history = useHistory();

    const [notifications, setNotifications] = useState<NSApp.Notification[]>([]);
    const [newNotification, setNewNotification] = useState<NSApp.Notification>();
    const [isFetching, setIsFetching] = useState(true);
    const [isFullList, setIsFullList] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const notificationsRef = useRef<NSApp.Notification[]>([]);
    const notificationSocketRef = useRef<any>();

    const setNotificationGlobalState = useContextSelector(
        NotificationContext,
        (s) => s[1],
    );

    // const notificationState = useContextSelector(
    //     NotificationContext,
    //     (s) => s[0][type],
    // );

    const fetchNotifications = async (page: number = currentPage, pageSize = config.PAGE_SIZE) => {
        let resp: any = await NotificationService.fetchNotifications('app', type, page, pageSize);

        if (!resp.notifications.length) {
            setIsFetching(false);
            setIsFullList(true);
            setNotificationGlobalState((oldState: any) => ({
                ...oldState,
                [type]: 0,
            }));
            return;
        }

        if (resp.notifications.length < pageSize) {
            setIsFullList(true);
        }

        let listData =
            page > 1
                ? [...notifications, ...resp.notifications]
                : resp.notifications;

        setNotifications(listData);
        setNotificationGlobalState((oldState: any) => ({
            ...oldState,
            [type]: resp.totalUnread,
        }));

        notificationsRef.current = resp.notifications;
        setIsFetching(false);
    };

    const addNewNotification = useCallback(
        (notification: NSApp.Notification) => {
            let newNotificationList = [
                notification,
                ...notificationsRef.current,
            ];

            setNotifications(newNotificationList);
            notificationsRef.current = newNotificationList;
        },
        [],
    );

    useEffect(() => {
        if (newNotification) {
            addNewNotification(newNotification);
        }
    }, [addNewNotification, newNotification]);

    const initNewNotificationSubscription = () => {
        subscribeNewNotification((_notification: NSApp.Notification) => {
            if (_notification.platform === 'app' && _notification.type === type) {
                setNewNotification(_notification);
            }
        }).then((socket: any) => notificationSocketRef.current = socket);
    };

    useEffectOnce(() => {
        fetchNotifications();
        initNewNotificationSubscription();
    });

    useUnmount(() => {
        if (notificationSocketRef.current) {
            notificationSocketRef.current.close();
        }
    });

    const handleInfiniteOnLoad = async () => {
        if (isFetching || isFullList) {
            return;
        }

        setIsFetching(true);
        let page = currentPage;
        setCurrentPage(page + 1);
        await fetchNotifications(page + 1);
    };

    return <NotificationList
        type={type}
        notifications={notifications}
        history={history}
        isFullList={isFullList}
        handleInfiniteOnLoad={handleInfiniteOnLoad}
        isCompact={isCompact}
        setDropdownVisible={setDropdownVisible}
    />;
};

export default NotificationContainer;