import React from 'react';
import { priceFormat } from "../../../../../services/stock-price";
import { useContextSelector } from 'use-context-selector';
import { MainIndexContext } from '../../../../../contexts/MainIndexProvider';
import { stockColors } from "../../../../../themes/colors";

type DifferenceValueProps = {
    value: number;
}

const DifferenceValue = (props: DifferenceValueProps) => {
    const { value } = props;

    const mainIndexVn30 = useContextSelector(
        MainIndexContext,
        (s) => s[0]['vn30'],
    );

    let differentValue = 0;

    if (mainIndexVn30 && value) {
        differentValue = value - mainIndexVn30.indexValue;
    }

    let color = differentValue > 0 ? stockColors.increase : stockColors.reduced;
    return <span style={{ color }}>
        {priceFormat(differentValue, 1)}
    </span>
}

export default DifferenceValue;
