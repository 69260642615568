import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { useEffectOnce, useUnmount } from 'react-use';
import { notification as NotificationAntd, Typography } from 'antd';
import { useContextSelector } from 'use-context-selector';

import routes from '../../routes';
import { NotificationContext } from '../../contexts/NotificationProvider';
import * as NotificationService from '../../pages/private/client/Notification/services';
import { subscribeNewNotification, subscribeTotalUnreadNotification } from '../../services/socket';

import NotificationBellDropDown from './components/dropdown';

const NotificationBellContainer = () => {
    const [totalUnreadNotification, setTotalUnreadNotificationState] =
        useState(0);

    const history = useHistory();

    const notificationSocketRef = useRef<any>();

    const notificationState = useContextSelector(
        NotificationContext,
        (s) => s[0],
    );

    const setNotificationGlobalState = useContextSelector(
        NotificationContext,
        (s) => s[1],
    );

    const fetchTotalUnreadNotifications = async () => {
        let resp: any = await NotificationService.fetchTotalUnreadNotifications();
        setTotalUnreadNotificationState(resp.data.totalAppUnread);
    };

    const initNewNotificationSubscription = useCallback(() => {
        subscribeNewNotification((newData: NSApp.Notification) => {
            if (newData.platform === 'app') {
                NotificationAntd.open({
                    message: (
                        <Typography.Paragraph>
                            <div dangerouslySetInnerHTML={{__html: newData.content}}/>
                        </Typography.Paragraph>
                    ),
                    placement: 'bottomRight',
                    onClick: () => {
                        NotificationService.onNotificationClick(history, newData);
                    },
                });

                setNotificationGlobalState((oldState: any) => {
                    let unReadState = oldState[newData.type] + 1;
                    return {
                        ...oldState,
                        [newData.type]: unReadState,
                    };
                });
            }
        }).then((socket: any) => notificationSocketRef.current = socket);
    }, [history, setNotificationGlobalState]);

    useEffect(() => {
        initNewNotificationSubscription();
    }, [initNewNotificationSubscription]);

    useEffectOnce(() => {
        fetchTotalUnreadNotifications();
        subscribeTotalUnreadNotification((totalUnread: any) => {
            setTotalUnreadNotificationState(totalUnread);
        }).then((socket: any) => {
            notificationSocketRef.current = socket;
        });
    });

    useUnmount(() => {
        if (notificationSocketRef.current) {
            notificationSocketRef.current.close();
        }
    });

    const markAllNotificationAsRead = async () => {
        await NotificationService.markAllNotificationAsRead();

        // let { notifications } = notificationState;
        // notifications = notifications.map((n: NSApp.Notification) => {
        //     return {...n, readed: true};
        // });

        // setNotificationState({ notifications });
    }

    const onGoToNotificationsPage = () => {
        history.push(routes.NOTIFICATION);
    };

    const totalUnread = useMemo(
        () => {
            if ('new-recommendation' in notificationState && 'new-notification-info' in notificationState) {
                return notificationState['new-recommendation'] + notificationState['new-notification-info'];
            }
            return totalUnreadNotification;
        },
        [totalUnreadNotification, notificationState]
    );

    // console.log('totalUnread', totalUnread);
    return <NotificationBellDropDown
        totalUnread={totalUnread}
        onMarkAllNotificationAsRead={markAllNotificationAsRead}
        onGoToNotificationsPage={onGoToNotificationsPage}
    />
}

export default NotificationBellContainer;