import React, { useState } from "react";
import { useEffectOnce } from 'react-use';
import { Tabs } from "antd";
import { useNewsClient } from "../client";
import NewsListContainer from "../container/list";

type NewsContainerProps = {
    categoryName?: string,
    stockCodes?: string[],
    glContainer?: any,
    listHeight?: number
    listNoImage?: boolean
};

type NewsListProps = {
    category?: NSApp.NewsCategory,
    isExternal?: boolean,
    stockCodes?: string[],
    hasTitle?: boolean,
    enableWatchListFilter?: boolean,
    hasLatestNews?: boolean,
    isLatestTab?: boolean,
    horizontal?: boolean,
    listNoImage?: boolean,
    column?: number,
    pageSize?: number
}

const NEWS_CATEGORIES = [
    'Thế giới',
    'Kinh tế - Thị trường',
    'Doanh nghiệp',
    'Báo cáo phân tích',
];

const NewsCompositeContainer = (props: NewsContainerProps) => {
    const { categoryName, stockCodes, glContainer, listHeight, listNoImage } = props;

    const [ newsCategories, setNewsCategories ] = useState<NSApp.NewsCategory[]>([]);
    const [ activeKey, setActiveKey ] = useState("latest");

    const newsClient = useNewsClient();

    const fetchNewsCategories = async () => {
        // eslint-disable-next-line
        const [err, resp]: any = await newsClient.fetchNewsCategories({}, 1);
        if (err) {
            return;
        }

        const sortedCategories = sortCategoryByName(resp.categories);
        setNewsCategories(sortedCategories);
    };

    const sortCategoryByName = (data: any) => {
        let sortedCategories: any = [];

        for (let categoryName of NEWS_CATEGORIES) {
            const category = data.find(
                (entry: any) => entry.name === categoryName,
            );
            sortedCategories.push(category);
        }

        return sortedCategories;
    };

    const NewsList = (props: NewsListProps) => {
        const { isExternal, category, stockCodes, hasTitle, enableWatchListFilter, hasLatestNews, isLatestTab, horizontal ,listNoImage, column, pageSize } = props;
        let height = glContainer ? glContainer.height : listHeight;

        return (
            <NewsListContainer
                isExternal={isExternal}
                enableWatchListFilter={enableWatchListFilter}
                category={category}
                hasTitle={hasTitle}
                isLatestTab={isLatestTab}
                hasLatestNews={hasLatestNews}
                stockCodes={stockCodes}
                height={horizontal ? 350 : height}
                width={glContainer?.width}
                horizontal={horizontal}
                listNoImage={listNoImage}
                column={column}
                pageSize={pageSize}
            />
        );
    };

    const onChangeActiveKey = (key) => {
        setActiveKey(key);
    };

    useEffectOnce(() => {
        fetchNewsCategories();
    });

    return <>
        {categoryName ?
            <NewsList
                category={newsCategories[NEWS_CATEGORIES.indexOf(categoryName)]}
                stockCodes={stockCodes}
                hasTitle
                listNoImage={listNoImage}
                column={1}
            />
            :
            <Tabs
                className="mx-1" size="small"
                tabBarStyle={{ fontSize: 10 }}
                defaultActiveKey={activeKey}
                onChange={onChangeActiveKey}
            >
                <Tabs.TabPane tab="Mới nhất" key="latest">
                    <div className="overflow-auto" style={{height: glContainer.height - 48}}>
                        {newsCategories.map(category => {
                            return <NewsList
                                key={category.id}
                                category={category}
                                column={3}
                                enableWatchListFilter
                                hasTitle
                                horizontal
                                hasLatestNews
                                isLatestTab
                            />
                        })}
                        {/* <NewsList
                            isExternal={true}
                            column={3}
                            pageSize={16}
                            enableWatchListFilter
                            hasLatestNews
                            hasTitle
                            horizontal
                            isLatestTab
                        /> */}
                    </div>
                </Tabs.TabPane>

                {newsCategories.map(category => {
                    return <Tabs.TabPane tab={category.name} key={category.id}>
                        <NewsList
                            category={category}
                            column={3}
                            pageSize={16}
                            enableWatchListFilter
                            hasLatestNews
                        />
                    </Tabs.TabPane>
                })}
{/* 
                <Tabs.TabPane tab="Tin tức khác" key="google-news">
                    <NewsList
                        isExternal={true}
                        column={3}
                        pageSize={16}
                        enableWatchListFilter
                        hasLatestNews
                    />
                </Tabs.TabPane> */}
            </Tabs>
        }
    </>
};

export default NewsCompositeContainer;
