import React, {
    forwardRef, useEffect,
    useImperativeHandle, useState, Ref, useRef
} from "react";
import useChartLoaded from "../../../../../../contexts/ChartLoaded";
import themeModeGlobalState from "../../../../../../contexts/ThemeMode";
import api from "../../../../../../services/api";
// import {
//     widget
// } from "../../../../../../library/charting_library_v2/charting_library/charting_library.standalone";
import getDefaultOptions from "./Options";

export interface TradingViewWindowRef {
    getCurrentSymbol(),
    setSymbol: (symbol: string) => void,
};

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 123;

type TradingViewProps = {
    id?: string,
    symbol?: string,
    glContainer?: any,
    layoutWidth?: number,
    layoutHeight?: number,
    onChange?: (symbol: string, id?: string) => void,
}

const TradingViewWindow = (props: TradingViewProps, ref: Ref<TradingViewWindowRef>) => {
    let { id, glContainer, layoutWidth, layoutHeight, symbol, onChange } = props;

    const [themeMode,] = themeModeGlobalState();

    const chartID = id || '';
    let _symbol = symbol || "VNINDEX"
    let options = getDefaultOptions(chartID, _symbol);

    let width = layoutWidth && layoutWidth > 0 ? layoutWidth : window.innerWidth - SIDER_WIDTH;
    let height = layoutHeight && layoutHeight > 0 ? layoutHeight : window.innerHeight - HEADER_HEIGHT;

    if (glContainer) {
        width = glContainer.width > 0 ? glContainer.width : width;
        height = glContainer.height > 0 ? glContainer.height - 44 : height;
    }

    const tvWidgetRef = useRef<any>(undefined);

    const [ stockSymbol, setStockSymbol ] = useState<string>();
    // const [chartLoaded, setChartLoaded] = useChartLoaded();

    // useEffect(() => {
    //     if (symbol) {
    //         if (tvWidgetRef.current) {
    //             tvWidgetRef.current.activeChart().setSymbol(symbol, () => {
    //                 setStockSymbol(symbol);
    //             })
    //         }
    //     }
    // }, [symbol]);

    useImperativeHandle(ref, () => {
        return {
            getCurrentSymbol: () => {
                return stockSymbol
            },
            setSymbol: (symbol) => {
                tvWidgetRef.current?.onChartReady(async () => {
                    if (tvWidgetRef.current) {
                        tvWidgetRef.current.activeChart().setSymbol(symbol, () => {
                            setStockSymbol(symbol);
                            if (onChange) {
                                onChange(symbol, id);
                            }
                        })
                    }
                })
            }
        }
    });

    const saveChart = (onSuccessCallback = () => {}) => {
        tvWidgetRef.current.saveChartToServer(() => {
            onSuccessCallback();
        }, () => {
            onSuccessCallback();
        }, {
            defaultChartName: `autosave-${chartID}`,
        })
    };

    useEffect(() => {
        console.log('use effect charID', chartID)
        if (document.getElementById("wrapper_" + chartID)) {
            tvWidgetRef.current = new (window as any).TradingView.widget(options);
            // tvWidgetRef.current = tvWidget;

            tvWidgetRef.current.onChartReady(async () => {
                const [err, resp]: any = await api.get(
                    `tradingview/1.1/` + `charts/${chartID}`, {}
                );

                if (resp.data) {
                    try {
                        const chartParsed = JSON.parse(resp.data.content);
                        const contentParsed = JSON.parse(chartParsed.content)
                        tvWidgetRef.current.load(contentParsed);
                    } catch (err) {
                        console.log("err when loading chart", err)
                    }
                }

                // tvWidgetRef.current.loadChartFromServer({
                //     id: chartID,
                //     name: "autosave-" + chartID,
                //     image_url: chartID,
                //     interval: '' as any,
                //     modified_iso: 1600226718,
                //     short_symbol: '',
                // });

                tvWidgetRef.current.subscribe('onAutoSaveNeeded', () => saveChart())

                tvWidgetRef.current.activeChart().onSymbolChanged().subscribe(null, () => {
                    let currentSymbol = tvWidgetRef.current.activeChart().symbolExt().symbol;
                    console.log("CurrentSymbol", currentSymbol)
                    saveChart(() => {
                        setStockSymbol(currentSymbol);
                        if (onChange) {
                            onChange(currentSymbol, id);
                        }
                    });
                });

                tvWidgetRef.current.activeChart().onIntervalChanged().subscribe(null, () => {
                    saveChart()
                }, true)

                tvWidgetRef.current.changeTheme(themeMode);
            })
        }
        return function unsubscribe() {
            console.log("unmounting chart", chartID)
        }
    }, [width, height, chartID]); // eslint-disable-line

    useEffect(() => {
        if (tvWidgetRef.current && tvWidgetRef.current._innerWindow()?.changeTheme) {
            tvWidgetRef.current.changeTheme(themeMode);
        }
    }, [themeMode])

    return (
        <div id={"wrapper_" + chartID} style={{
            height: height
        }}/>
    )
}

export default forwardRef(TradingViewWindow);
