import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { useEffectOnce } from 'react-use';
import { useTranslation } from "react-i18next";
import { useAuthState } from "../../../../contexts";
import api from "../../../../services/api";

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

const UpdateInfo = () => {
    const [ referenceName, setReferenceName] = useState<string>('');
    const { t } = useTranslation();
    const { authState, setAuthState } = useAuthState() as any;
    const { currentUser } = authState;

    const [form] = Form.useForm();

    const initialValues = {
        email: currentUser.email,
        fullname: currentUser.fullname,
        phone: currentUser.phone,
        referenceCode: currentUser.referenceCode,
        referenceName: referenceName
    }

    useEffectOnce(() => {
        if (currentUser.referenceCode) {
            fetchReferenceProfile(currentUser.referenceCode)
        }
    });

    useEffect(() => form.resetFields(), [form, initialValues]);

    const fetchReferenceProfile = async (referenceCode : any) => {
        const [, response]: any = await api.get('/user/fetch-user', {
            referenceCode,
        })

        if (response) {
            setReferenceName(response.data.fullname);
        }
    }

    const onFinishUpdateInfo = async values => {
        let [err, resp]: any = await api.post('user/update-profile', {
            fullname: values?.fullname,
            phone: values?.phone,
        });

        if (err) {
            return notification.error({
                message: t('Cập nhật thông tin lỗi, vui lòng thử lại'),
                description: err?.response?.data?.message || err.message,
            });
        }

        setAuthState({ currentUser: resp?.data?.userInfo});
        notification.success({
            message: t('Cập nhật thông tin thành công'),
        });
    };

    if (!currentUser) return <></>;

    return (
        <Form
            {...layout}
            form={form}
            className="my-4 max-w-screen-sm"
            size="middle"
            name="updateInfo"
            labelAlign="left"
            onFinish={onFinishUpdateInfo}
            initialValues={initialValues}
        >
            <Form.Item label={t('Họ Tên')} name="fullname">
                <Input />
            </Form.Item>

            <Form.Item label={t('Điện thoại')} name="phone">
                <Input />
            </Form.Item>

            <Form.Item label={t('Email')} name="email">
                <Input readOnly />
            </Form.Item>

            <Form.Item label={t('Mã người giới thiệu')} name="referenceCode">
                <Input readOnly />
            </Form.Item>

            <Form.Item label={t('Tên người giới thiệu')} name="referenceName">
                <Input readOnly />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">{t('Cập nhật')}</Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateInfo;
