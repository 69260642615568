import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../../../../routes";
import PaymentManageTable from "./Table";

export default function Payment(){
    return(
        <div>
            <Switch>
                <Route path={routes.ADMIN_MANAGE_PAYMENT} component={PaymentManageTable}/>
            </Switch>
        </div>
    )
}