import React, { useState } from 'react';
import { Card, Radio } from 'antd';
import { useTranslation } from "react-i18next";
import theme from '../../../../../../themes/theme';
import themeModeGlobalState from "../../../../../../contexts/ThemeMode";
import HistoryStocks from './components/HistoryStocks';
import HistoryExchanges from './components/HistoryExchanges';

const ForeignHistory = (props) => {
    const { glContainer } = props;
    const itemWidth = glContainer.width - 40;
    const itemHeight = (glContainer.height - 75) / 2;
    const [exchange, setExchange] = useState('');
    const { t } = useTranslation();
    const [themeMode] = themeModeGlobalState();

    return (
        <div>
            <Radio.Group
                value={exchange}
                onChange={(e) => setExchange(e.target.value)}
                size="small"
                buttonStyle="solid"
                className="mb-1"
            >
                <Radio.Button className="mr-1 font-bold" value="">{t('Toàn thị trường')}</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="hose">HOSE</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="hnx">HNX</Radio.Button>
                <Radio.Button className="mr-1 font-bold" value="upcom">UPCOM</Radio.Button>
            </Radio.Group>

            <Card
                size="small"
                style={{ height: itemHeight, marginBottom: 4 }}
                bodyStyle={{ backgroundColor: theme.colors.chartBackground[themeMode] }}
            >
                <HistoryExchanges exchange={exchange} height={itemHeight} width={itemWidth} />
            </Card>

            <Card
                size="small"
                style={{ height: itemHeight }}
                bodyStyle={{ backgroundColor: theme.colors.chartBackground[themeMode] }}
            >
                <HistoryStocks exchange={exchange} height={itemHeight} width={itemWidth} />
            </Card>
        </div>
    )
};

export default ForeignHistory;
