import React, { useContext } from 'react';

const INITIAL_INDICE = {
    _id: "",
    exchange: "",
    closePrice: 0,
    currentPrice: 0,
    totalValue: 0,
    totalVolume: 0,
    netGain: 0,
    percentGain: 0,
    advances: 0,
    noChanges: 0,
    declines: 0,
    ceiling: 0,
    floor: 0,
    time: 0,
    __v: 0,
    qttyInTime: 0,
    formattedTime: "",
    id: 0,
    foreignBuyValue: 0,
    foreignSellValue: 0,
    foreignBuyVolume: 0,
    foreignSellVolume: 0
}

export const initialForeignerTradingStore: Context.ForeignerTradingContext = {
    indice: INITIAL_INDICE,
    stocks: [],
    foreignerTradingByTime: []
};

export const ForeignerTradingContext = React.createContext<Context.ForeignerTradingContext>(initialForeignerTradingStore);

export const useForeignerTradingState = () => {
    // @ts-ignore
    return useContext(ForeignerTradingContext);
};