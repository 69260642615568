import React from "react";
import { Layout, Avatar, Button, Dropdown, Space, Menu, notification } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import theme from "../../../../themes/theme";
import routes from "../../../../routes";
import localStorage from "../../../../services/local-storage";
import { useAuthState } from "../../../../contexts";

import NotificationBell from '../../../../components/NotificationBell';

const PrivateHeader = () => {
    // const [theme, setTheme] = useState('dark');
    const history = useHistory();
    const { authState, setAuthState } = useAuthState() as any;
    const { currentUser } = authState;

    const logout = () => {
        localStorage.remove("accessToken");

        setAuthState({ currentUser: null });

        notification.success({
            message: "Logout successfully!",
        });

        history.push(routes.LOGIN);
    };

    const AccountDropdownMenu = (
        <Menu>
            <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
                Đăng xuất
            </Menu.Item>
        </Menu>
    );

    return (
        <Layout.Header className="home-layout-background home-header flex justify-end">
            <Space className="header-right-component" size="middle" align="center">

                <NotificationBell />

                <Dropdown overlay={AccountDropdownMenu} placement="bottomRight" trigger={['click']}>
                    <Button type="text">
                        <Avatar
                            size="small"
                            icon={<UserOutlined />}
                            className="mr-2"
                            style={{ backgroundColor: theme.colors.primary }}
                        />
                        {currentUser?.fullname || currentUser?.email}
                    </Button>
                </Dropdown>
            </Space>
        </Layout.Header>
    );
};

export default PrivateHeader;
