export const DEFAULT_BREAKOUT_FILTERS = [
    {
        field: 'percentChangeTotalVolume30Days',
        sort: 'desc',
        operator: 'gte',
        value: 0,
        default: true,
    },
    {
        field: 'percentChangePrice5Days',
        sort: '',
        operator: 'gte',
        value: 0,
        default: true,
    },
    {
        field: 'averageTotalVolume90Days',
        sort: '',
        operator: 'gte',
        value: 100000,
        default: true,
    },
    {
        field: 'averagePrice5Days',
        sort: 'desc',
        operator: 'gte',
        value: 5000,
        default: true,
    },
    {
        field: 'pricePercentChange',
        sort: 'desc',
        operator: 'gt',
        value: 0,
        default: true,
    },
];

export const FSTOCK_BREAKOUT_FILTERS_ADVANCED = [
    ...DEFAULT_BREAKOUT_FILTERS,
    {
        field: 'eps',
        sort: 'desc',
        operator: 'gte',
        value: 500,
        default: true,
    },
    {
        field: 'pe',
        sort: 'desc',
        operator: 'lte',
        value: 20,
        default: true,
    },
    {
        field: 'pb',
        sort: 'desc',
        operator: 'lte',
        value: 3,
        default: true,
    },
    {
        field: 'roerecent',
        sort: 'desc',
        operator: 'gte',
        value: 0.1,
        default: true,
    },
    {
        field: 'averagePrice5Days',
        sort: 'desc',
        operator: 'gte',
        value: 7000,
        default: true,
    },
];

export const FSTOCK_BREAKOUT_FILTERS_PRO = [
    ...DEFAULT_BREAKOUT_FILTERS,
    {
        field: 'eps',
        sort: 'desc',
        operator: 'gte',
        value: 1000,
        default: true,
    },
    {
        field: 'bookvalue',
        sort: 'desc',
        operator: 'gte',
        value: 10,
        default: true,
    },
    {
        field: 'averagePrice5Days',
        sort: 'desc',
        operator: 'gte',
        value: 10000,
        default: true,
    },
    {
        field: 'pe',
        sort: 'desc',
        operator: 'lte',
        value: 15,
        default: true,
    },
    {
        field: 'pb',
        sort: 'desc',
        operator: 'lte',
        value: 2,
        default: true,
    },
    {
        field: 'roerecent',
        sort: 'desc',
        operator: 'gte',
        value: 0.1,
        default: true,
    },
];
