import React, { useState, useEffect } from 'react';
import { Input, Button, Typography, Space, Select, message } from 'antd';
import {FundOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import api from '../../../../../services/api';
import { toRoute } from '../../../../../services/utils';
import routes from '../../../../../routes';
import {useDashboardState} from "../../../../../contexts";

import _ from 'lodash';

const { Paragraph } = Typography;
const { Option } = Select;

type DashboardCreateFromProps = {
    initialValues?: NSApp.Dashboard | null,
    description?: string,
    isDefault?: boolean,
    cbCreate?: () => void,
    cbUpdate?: () => void,
    mode: 'add' | 'edit'
}

const DashboardCreateForm = (props: DashboardCreateFromProps) => {
    const { t } = useTranslation();
    let history = useHistory();
    const { dashboardState, setDashboardState } = useDashboardState() as any;
    const [ dashboardTemplateList, setDashboardTemplateList ] = useState<NSApp.DashboardTemplate[]>([]);
    const [ templateSelected, setTemplateSelected ] = useState<string>();
    const [ name, setName ] = useState<string>('');
    const [ template, setTemplate ] = useState<NSApp.DashboardTemplate>();
    const [ isNameError, setIsNameError ] = useState<boolean>(false);

    const resetForm = () => {
        setName('');
        setTemplate(undefined);
        setIsNameError(false);
    }

    const fetchDashboardTemplateList = async () => {
        const [err, resp]: any = await api.get('plugins/dashboard-template/list', {
            active: true
        });

        if (err) {
            return;
        }

        const _templateList = resp.data;

        if (_templateList.length) {
            setTemplate(_templateList[0]);
            setDashboardTemplateList(_templateList);

            let defaultSelected = _templateList.length ? _templateList[0].id.toString() : "customize";
            setTemplateSelected(defaultSelected);
        }
    };

    const createNewDashboard = async () => {
        let payloads: any = {isDefault: props?.isDefault};

        if (!name) {
            setIsNameError(true);
            return;
        }

        if (template) {
            payloads = {
                ...payloads,
                layoutConfig: template.layoutConfig
            }
        }

        // eslint-disable-next-line
        let [err, resp]: any = await api.post('plugins/add-new-dashboard', {
            ...payloads,
            name: name
        });

        if (err) {
            message.error({
                content: t('Có lỗi xảy ra khi tao mới bảng tổng quan'),
                key: 'create-dashboard-error'
            });
            return;
        }

        let dashboard = resp?.data;
        message.success({
            content: t('Tạo bảng tổng quan mới thành công'),
            key: 'create-dashboard-success'
        });

        let { listDashboard } = dashboardState;
        listDashboard.push(dashboard);
        setDashboardState({listDashboard});

        if (props.cbCreate) {
            props.cbCreate();
        }

        resetForm();

        history.replace(toRoute(routes.DASHBOARD_CONTENT, {dashboardId: dashboard.id}));
    };

    const updateDashBoard = async () => {
        if (!props.initialValues) {
            return;
        }

        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/dashboard/${props.initialValues.id}`, {
            name: name
        });

        if (err) {
            message.error({
                content: t('Có lỗi xảy ra'),
                key: 'update-dashboard-error'
            });
            return;
        }

        if (props.cbUpdate) {
            props.cbUpdate();
        }
    }

    useEffect(() => {
        fetchDashboardTemplateList();
        if (props.initialValues) {
            setName(props.initialValues.name);
        }
    }, [props.initialValues])

    let nameInputStyle = !isNameError ? {} : {borderColor: 'red'};

    return (
        <>
            <Space className="w-full mt-0" direction="vertical">
                {props?.description &&
                    <Paragraph>{props?.description}</Paragraph>
                }
                {props.mode === 'add' &&
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        value={templateSelected}
                        onChange={(value: string) => {
                            if (value !== 'customize') {
                                let dashboard = _.find(dashboardTemplateList, (t) => t.id.toString() === value);
                                if (dashboard) {
                                    setTemplate(dashboard);
                                    setName(dashboard.name);
                                    setIsNameError(false);
                                }
                            } else {
                                setTemplate(undefined);
                            }

                            setTemplateSelected(value);
                        }}
                    >
                        {dashboardTemplateList.map(template => {
                            return <Option key={template.id} value={template.id.toString()}>{template.name}</Option>
                        })}
                        <Option value={'customize'}>{t('Tùy chỉnh')}</Option>
                    </Select>
                }

                <Input
                    style={nameInputStyle}
                    onChange={(e) => {
                        if (e.target.value) {
                            setIsNameError(false);
                        }
                        setName(e.target.value);
                    }}
                    value={name}
                    prefix={<FundOutlined/>} placeholder={t('Tên bảng tổng quan')}
                />

                {isNameError &&
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{t('Tên không được để trống')}</div>
                    </div>
                }

                <Button type="primary" htmlType="submit" onClick={() => props.mode === 'add' ? createNewDashboard(): updateDashBoard()}>
                    {props.mode === 'add' ? t('Tạo mới') : t('Cập nhật')}
                </Button>
            </Space>
        </>
    )
}

export default DashboardCreateForm;
