import api from '../../../../../../../services/api';
import { getStockColorClass, getAnimationClass } from "../../../../../../../services/utils";
import colors from '../../../../../../../themes/colors';

export const fetchStocksByExchangeName = async (name: string) => {
    // eslint-disable-next-line
    const [err, resp]: any = await api.get('stock/get-by-exchange-name',  { name });

    if (err) return [];

    return resp.data;
};

export const fetchAllDefaultStocks = async () => {
    // eslint-disable-next-line
    const [err, resp]: any = await api.get('stock/get-all-default-stocks');

    if (err) return [];

    return resp.data;
};

export const fetchAllDefaultSymbols = async () => {
    // eslint-disable-next-line
    const [err, resp]: any = await api.get('symbol/all-default-symbols');

    if (err) return [];

    return resp.data;
};

export const fetchStocksByType = async (stockType: string) => {
    const [err, resp]: any = await api.get('stock/get-by-type', { stockType });

    if (err) return [];

    return resp.data;
}

// export const getCellColorAnimation = (column: string,  prevData: NSApp.Stock | undefined, currentData: NSApp.Stock) => {
//     // if (!prevData) {
//     //     return {
//     //         color: colors.white
//     //     };
//     // }

//     let columnColor = column;
//     let currentValue = currentData[column];
//     let prevValue = prevData ? prevData[column] : 0;

//     switch (column) {
//         case 'best3BidVol':
//             columnColor = 'best3Bid'
//             break;
//         case 'best2BidVol':
//             columnColor = 'best2Bid'
//             break;
//         case 'best1BidVol':
//             columnColor = 'best1Bid'
//             break;
//         case 'best1OfferVol':
//             columnColor = 'best1Offer'
//             break;
//         case 'best2OfferVol':
//             columnColor = 'best2Offer'
//             break;
//         case 'best3OfferVol':
//             columnColor = 'best3Offer'
//             break;
//         case 'priceChange':
//             columnColor = 'matchedPrice'
//             break;
//         case 'volume':
//             columnColor = 'matchedPrice'
//             break;
//         case 'pricePercentChange':
//             columnColor = 'matchedPrice'
//             break;
//         case 'underlyingStockData':
//             columnColor = 'matchedPrice'
//             prevValue = prevData ? prevData.underlyingStockData?.matchedPrice: 0;
//             currentValue = currentData.underlyingStockData?.matchedPrice
//             break;
//         default:
//             columnColor = column;
//             break;
//     }

//     let animation = getAnimation(currentData.referencePrice, currentData[columnColor]);
//     let color = getStockColorClass(currentData, themeMode, currentData[columnColor]);

//     if (column === 'underlyingStockData' && currentData.underlyingStockData) {
//         color = getStockColorClass(currentData.underlyingStockData, themeMode, currentData.underlyingStockData?.matchedPrice);
//     }

//     let listWhiteColumn = [
//         'totalVolume', 'totalValue', 'foreignBuyVolume', 'foreignSellVolume',
//         'remainForeignRoom', 'issuerName', 'exercisePrice', 'exerciseRatio',
//         'lastTradingDate', 'marketStatus', 'maturityDate'
//     ]

//     let listConstantValue = [
//         'ceilingPrice',  'referencePrice', 'floorPrice'
//     ]

//     if (listConstantValue.includes(columnColor)) {
//         animation = ""
//     }

//     if (listWhiteColumn.includes(columnColor)) {
//         color = colors.white
//     }

//     if (currentValue === prevValue) {
//         return {
//             animation: '',
//             color
//         }
//     }

//     return { animation, color };
// }

export const getCellColorAnimation = (column, prevValue, stockInfo: NSApp.Stock, themeMode) => {

    let listWhiteColumn = [
        'totalVolume', 'totalValue', 'foreignBuyVolume', 'foreignSellVolume',
        'remainForeignRoom', 'issuerName', 'exercisePrice', 'exerciseRatio',
        'lastTradingDate', 'marketStatus', 'maturityDate', 'openInterest',
        'averagePrice5Days', 'averagePrice10Days', 'averagePrice30Days',
        'averageTotalVolume5Days', 'averageTotalVolume10Days', 'averageTotalVolume30Days', 'averageTotalVolume60Days', 'averageTotalVolume90Days',
        'percentChangeTotalVolume5Days', 'percentChangeTotalVolume10Days', 'percentChangeTotalVolume30Days', 'percentChangeTotalVolume60Days', 'percentChangeTotalVolume90Days',
        'percentChangePrice5Days', 'percentChangePrice10Days', 'percentChangePrice30Days', 'percentChangePrice60Days', 'percentChangePrice90Days'
    ];

    let listConstantValue = [
        'stockCode', 'ceilingPrice',  'referencePrice', 'floorPrice'
    ]

    let columnName = column;

    switch (column) {
        case 'best3BidVol':
            columnName = 'best3Bid'
            break;
        case 'best2BidVol':
            columnName = 'best2Bid'
            break;
        case 'best1BidVol':
            columnName = 'best1Bid'
            break;
        case 'best1OfferVol':
            columnName = 'best1Offer'
            break;
        case 'best2OfferVol':
            columnName = 'best2Offer'
            break;
        case 'best3OfferVol':
            columnName = 'best3Offer'
            break;
        case 'priceChange':
            columnName = 'matchedPrice'
            break;
        case 'volume':
            columnName = 'matchedPrice'
            break;
        case 'pricePercentChange':
            columnName = 'matchedPrice'
            break;
        case 'underlyingStockData':
            columnName = 'matchedPrice'
            break;
        default:
            columnName = column;
            break;
    }

    let color = getStockColorClass(stockInfo, themeMode, stockInfo[columnName]);
    let animation = getAnimationClass(stockInfo, stockInfo[columnName]);

    if (listConstantValue.includes(column)) {
        return {
            animation: '',
            color
        }
    }

    if (listWhiteColumn.includes(column)) {
        return {
            animation: '',
            color: themeMode === 'dark' ? colors.white : colors.black,
        }
    }

    if (stockInfo[column] === prevValue) {
        return {
            animation: '',
            color
        }
    }

    return { animation, color };
}

export const getCellAnimation = () => {
    return "";
}
