import localStorage from "./local-storage";
import * as config from "../config";
import moment from "moment";

import { Manager, Socket } from 'socket.io-client';

const SocketManager = new Manager(config.SOCKET_BASE_URL, {
    reconnection: true,
    transports: ['websocket'],
    query: {
        accessToken: localStorage.get("accessToken"),
    },
});

export const mainIndexSocket = SocketManager.socket('/rtExchangeHistory');
export const stockPriceSocket = SocketManager.socket('/stockPrice')

export const stockForeignerSocket = SocketManager.socket('/stockForeign');
const notificationSocket = SocketManager.socket('/notification');

const tradingViewChannelToSubscription = new Map();

const getNextBarTime = (barTime: number, tradeTime: number, resolution: string) => {
    const date = moment(barTime);
    const tradeMoment = moment(tradeTime);

    if (resolution === "1D") {
        return tradeMoment > date.clone().add(1, "day").startOf("day") ? tradeMoment.clone().startOf('day').hour(7).toDate().getTime() : date.clone().add(1, "day").toDate().getTime();
    }

    return tradeMoment > date.clone().add(1, "minute").startOf("minute") ? tradeMoment.clone().startOf("minute").toDate().getTime() : date.clone().add(1, "minute").startOf("minute").toDate().getTime();
}

stockPriceSocket.on('newTradingViewData', (matchedOrders: any[]) => {
    matchedOrders.map(matchedOrder => {
        const {
            TradingDate,
            Time,
            Symbol,
            Close,
            Volume,
            RType
        } = matchedOrder

        if (RType !== "B") {
            // skip all non B 
            return;
        }

        const channelString = `0~${Symbol}`;
	    const subscriptionItem = tradingViewChannelToSubscription.get(channelString);

        if (subscriptionItem === undefined) {
            return;
        }

        const mainIndexs = [
            "vnindex",
            "hnxindex",
            "vn30",
            "hnx30",
            "upcomindex",
            "hnxupcomindex",
            "vnxall",
            "vnx50",
            "vnuti",
            "vnsml",
            "vnsi",
            "vnreal",
            "vnmid",
            "vnmat",
            "vnit",
            "vnind",
            "vnheal",
            "vnfinselect",
            "vnfinlead",
            "vnfin",
            "vnene",
            "vndiamond",
            "vncons",
            "vncond",
            "vn100",
        ]

        if (subscriptionItem.handlers) {
	        subscriptionItem.handlers.forEach(handler => {
                const symbolLowerCase = Symbol.toLowerCase();
                const tradePrice = mainIndexs.includes(symbolLowerCase) || symbolLowerCase.includes("vn30") ? parseFloat(Close) : parseFloat(Close) / 1000;
                const tradeTime = moment(`${TradingDate} ${Time}`, "DD/MM/YYYY HH:mm:ss").toDate().getTime();
                const lastBar = handler.lastBar;

                if (!handler.lastBar) {
                    return;
                }

                const resolution = handler.resolution;
                const nextBarTime = getNextBarTime(lastBar.time, tradeTime, resolution)

                let bar;

                if (tradeTime >= nextBarTime) {
                    bar = {
                        time: nextBarTime,
                        open: tradePrice,
                        high: tradePrice,
                        low: tradePrice,
                        close: tradePrice,
                        volume: parseInt(Volume)
                    };
                } else {
                    bar = {
                        ...lastBar,
                        high: Math.max(lastBar.high, tradePrice),
                        low: Math.min(lastBar.low, tradePrice),
                        volume: parseInt(lastBar.volume) + parseInt(Volume),
                        close: tradePrice,
                    };
                }
                handler.lastBar = bar;
                return handler.callback(bar)
            });
        }
    })
})

export const subscribeOnTradingViewStream = (
	symbolInfo,
	resolution,
	onRealtimeCallback,
	subscribeUID,
	onResetCacheNeededCallback,
	lastBar,
) => {
    const parsedSymbol = symbolInfo.ticker;
    const channelString = `0~${parsedSymbol}`

    console.log({
        parsedSymbol,
        resolution,
        lastBar
    })

	const handler = {
		id: subscribeUID,
		callback: onRealtimeCallback,
        resolution,
        lastBar
	};
	let subscriptionItem = tradingViewChannelToSubscription.get(channelString);
	if (subscriptionItem) {
		// already subscribed to the channel, use the existing subscription
		subscriptionItem.handlers.push(handler);
		return;
	}
	subscriptionItem = {
		subscribeUID,
		handlers: [handler],
	};
	tradingViewChannelToSubscription.set(channelString, subscriptionItem);
	console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
	// socket.emit('SubAdd', { subs: [channelString] });
}

export const unsubscribeOnTradingViewStream = (subscribeUID) => {
    console.log('[unsubscribeBars]: Unsubscribe streaming. Channel:', subscribeUID);
    tradingViewChannelToSubscription.forEach((subscriptionItem, channelString) => {
        subscriptionItem.handlers = subscriptionItem.handlers.filter(handler => handler.id !== subscribeUID);
        if (subscriptionItem.handlers.length === 0) {
            tradingViewChannelToSubscription.delete(channelString);
            // socket.emit('SubRemove', { subs: [channelString] });
        }
    });
}

export const subscribeMainIndex = (cb) => {
    mainIndexSocket.on('notifyIndexRealtime', mainIndexValue => cb(mainIndexValue));
}

export const subscribeStockPrice = (cb) => {
    stockPriceSocket.on('stockPrice', (stockList: NSApp.Stock[]) => cb(stockList));
    return stockPriceSocket;
}


export const subscribeStockPriceForeigner = async (cb: any) => {
    stockForeignerSocket.on('stockForeign', (stockForeignerList: any[]) => cb(stockForeignerList));

    return stockForeignerSocket;
}

export const subscribeNewMatchedStockOrder = async (cb: any) => {
    stockPriceSocket.on('newMatchedStockOrder', (matchedOrders: NSApp.MatchedStockOrder[]) => {
        cb(matchedOrders)
    });

    return stockPriceSocket;
}

export const subscribeNewNotification = async (cb: any) => {
    notificationSocket.on('newNotification', (data: { notification: NSApp.Notification }) => cb(data.notification));

    return notificationSocket;
};

export const subscribeTotalUnreadNotification = async (cb: any) => {
    notificationSocket.on('updateTotalUnreadNotification', (data: any) => {
        cb(data.totalUnread?.totalAppUnread);
    });

    return notificationSocket;
};

export default SocketManager;
