import React, { useState, useEffect }  from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Image, Tooltip, Button, Space, Input, Select, Tag, message } from "antd";

import api from "../../../../services/api";
import * as config from "../../../../config";
import routes from "../../../../routes";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import { NewsType } from "../../../../types/plugins/news";
import { useReducedState, toRoute } from "../../../../services/utils";
import { NEWS_ACCEPTED, NEWS_DENIED, NEWS_PENDING } from "../../../../types/plugins/news";
import AdminPermission from "../../../../components/AdminPermission";

const { Option } = Select;

const NewsListComponent = () => {
    const [ newsKeySearchFilter, setNewsKeySearchFilter ] = useState<string>('');
    const [ newsStatusFilter, setNewsStatusFilter ] = useState<string>('');
    const [ listNews, setListNews ] = useState([]);
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchNewsList = async (filter = {}) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/news', {
            filter, page: pagination.current, pageSize: config.PAGE_SIZE
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { news, total } = resp?.data;

        setListNews(news);
        setPagination({ total });
    };

    // const deleteNews = async (newsId) => {
    //     // eslint-disable-next-line
    //     let [err, resp]: any = await api.delete(`plugins/news/${newsId}`);

    //     if (err) {
    //         message.error(err?.response?.data?.message);
    //         return;
    //     }

    //     message.success('Xóa thành công');
    //     fetchNewsList();
    // }

    const setNewsStatus = async (newsId: string, status: string) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.patch(`plugins/news/${newsId}/set-news-status`, {status});

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (status === NEWS_ACCEPTED) {
            message.success('Đã duyệt bài viết');
        }
        if (status === NEWS_DENIED) {
            message.success('Từ chối bài viết');
        }
        fetchNewsList();
    };

    const btnSearchOnClick = () => {
        let filter = {};
        if (newsKeySearchFilter) {
            filter['title'] = { $regex: `.*${newsKeySearchFilter}.*` };
        }

        if ([NEWS_ACCEPTED, NEWS_DENIED, NEWS_PENDING].includes(newsStatusFilter)) {
            filter['status'] = newsStatusFilter;
        }

        fetchNewsList(filter);
    };

    useEffect(() => {
        fetchNewsList();
    }, [pagination.current]); // eslint-disable-line


    const columns: any = [
        {
            title: 'Tiêu đề',
            key: 'title',
            width: 300,
            textWrap: 'word-break',
            ellipsis: true,
            render: (news) => <Link to={toRoute(routes.ADMIN_NEWS_DETAIL, { newsId: news.id })}>
                <Tooltip placement="top" title={news?.title}>
                    <span>{news?.title}</span>
                </Tooltip>
            </Link>
        },
        {
            title: "Nguồn",
            dataIndex: 'provider',
            render: (provider) => <a href={provider?.link}>{provider?.name}</a>
        },
        {
            title: "Danh mục",
            dataIndex: 'category',
            textWrap: 'word-break',
            ellipsis: true,
            render: (category) => <Tooltip placement="top" title={category?.name}>
                <a href={category?.link}>{category?.name}</a>
            </Tooltip>
        },
        {
            title: "Ảnh",
            dataIndex: 'thumbnail',
            render: (thumbnail) => <Image width={100} src={thumbnail}/>
        },
        {
            title: "Ngày xuất bản",
            dataIndex: 'createdAt',
            render: (createdAt) => <span>{moment(createdAt).format('L')}</span>
        },
        {
            title: "Trạng thái",
            dataIndex: 'status',
            render: (status) => <span>
                {status === NEWS_PENDING &&
                    <Tag color="gold">Chờ duyệt</Tag>
                }

                {status === NEWS_ACCEPTED &&
                    <Tag color="green">Đã duyệt</Tag>
                }

                {status === NEWS_DENIED &&
                    <Tag color="red">Đã từ chối</Tag>
                }
            </span>
        },
        {
            title: 'Action',
            width: 200,
            render: (news) => {
                return <Space>
                    <Link to={toRoute(routes.ADMIN_NEWS_EDIT, { newsId: news.id })}>
                        <Button>Sửa</Button>
                    </Link>

                    <Button type="primary" onClick={() => setNewsStatus(news.id, NEWS_ACCEPTED)}>Phê duyệt</Button>
                    <Button type="primary" danger onClick={() => setNewsStatus(news.id, NEWS_DENIED)}>Từ chối</Button>

                    {/* <Popconfirm
                        title="Bạn chắc chắn muốn xóa bài viết này?"
                        onConfirm={() => deleteNews(news._id)}
                        okText="Xóa"
                        cancelText="Hủy"
                    >
                        <Button type="primary" danger>Xóa</Button>
                    </Popconfirm> */}
                </Space>
            }
        }
    ];

    return (
        <>
            <MyBreadcrumd items={[{ label: 'Tin tức' }]} />

            <div className="mt-2">
                <Space>
                    <Input placeholder="Tên bài viết" onChange={(e: any) => setNewsKeySearchFilter(e.target.value)} />
                    <Select defaultValue="select-status" onChange={(val: any) => setNewsStatusFilter(val)} style={{ width: 150 }}>
                        <Option value="select-status">Chọn trạng thái</Option>
                        <Option value={NEWS_PENDING}>Chờ phê duyệt</Option>
                        <Option value={NEWS_ACCEPTED}>Đã phê duyệt</Option>
                        <Option value={NEWS_DENIED}>Bị từ chối</Option>
                    </Select>

                    <Button type="primary" onClick={btnSearchOnClick}>Tìm kiếm</Button>

                    <Button type="primary">
                        <Link to={routes.ADMIN_NEWS_ADD}>Thêm mới tin tức</Link>
                    </Button>
                </Space>
            </div>

            <div className="mt-3">
                <Table
                    columns={columns}
                    dataSource={listNews}
                    rowKey={(r: NewsType) => r?._id}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                />
            </div>
        </>
    )
}

const NewsList = () => {
    return <AdminPermission roleName={[]}>
        <NewsListComponent />
    </AdminPermission>
}

export default NewsList;