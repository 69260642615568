// import { useEffect } from 'react';
import * as NewsServices from '../services';

export const useNewsClient = () => {
    const fetchNews = async (filter, page, pageSize) => {
        let [error, response] = await NewsServices.fetchNewsData(filter, page, pageSize);

        if (error) return [error, null];

        return [null, response];
    };

    const fetchNewsCategories = async (filter, page) => {
        let [error, response] = await NewsServices.fetchNewsCategories(filter, page);

        if (error) return [error, null];

        return [null, response];
    };

    return {
        fetchNews,
        fetchNewsCategories
    }
}
