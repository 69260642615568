import React, { useState, useEffect } from 'react';

import { Input, Button, Space, Checkbox } from 'antd';

import DashboardContentForm from "./components/DashboardContentForm";

type DashboardFormProps = {
    initialState?: NSApp.DashboardTemplate,
    onSubmit: (payloads: any) => void,
    mode: 'add' | 'edit',
}

const DashboardForm = (props: DashboardFormProps) => {
    const [ name, setName ] = useState('');
    const [ layoutConfig, setLayoutConfig ] = useState<any>();
    const [ active, setActive ] = useState(false);

    const onClickSubmitButton = () => {
        props.onSubmit({name, active, layoutConfig});
    }

    useEffect(() => {
        if (props.initialState) {
            setName(props.initialState.name);
            setActive(props.initialState.active);
            setLayoutConfig(props.initialState.layoutConfig);
        }
    }, [props.initialState])

    return <>
        <div className="w-3/4 mb-2">
            <Space>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                <Checkbox checked={active} onChange={(e) => setActive(e.target.checked)}>Active</Checkbox>
                <Button onClick={onClickSubmitButton}>
                    {props.mode === 'add' ? 'Thêm mới' : 'Cập nhật' }
                </Button>
            </Space>
        </div>
        {layoutConfig && props.mode === 'edit' ? (
            <DashboardContentForm key="edit-form" layoutConfig={layoutConfig} onChange={(value) => setLayoutConfig(value)}/>
        ): (
            <DashboardContentForm key="add-new-form" onChange={(value) => setLayoutConfig(value)}/>
        )}

    </>
}

export default DashboardForm;