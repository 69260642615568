import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Card, Button } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { useReducedState } from "../../../../services/utils";
import routes from "../../../../routes";
import * as config from "../../../../config/index";
import api from "../../../../services/api";
import LoadingComponent from '../../../../components/Loading';
import UserPlanList from './UserPlan';

const PlanManagement = () => {
    const [currentPlan, setCurrentPlan] = useState<{ planName: string, expiredAt: string, planKey: string}>({ planName: '', expiredAt: '', planKey: '' });
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabKey, setTabKey] = useState<string>('user-plan')

    const { t } = useTranslation();

    const [pagination, setPagination] = useReducedState({
        current: 1,
        pageSize: 5,
        total: 0
    });

    const fetchHistory = async () => {
        setLoading(true);
        const [error, response]: any = await api.get('/payment/history', {
            page: pagination.current, pageSize: pagination.pageSize
        });

        // console.log(error, response)
        if (error) {
            return;
        }

        if (response) {
            const { currentPlan, purchases, total } = response.data;
            setCurrentPlan(currentPlan);
            setHistory(purchases);
            setPagination({ total: total });
        }

        setLoading(false);
    }

    useEffect(() => {
        fetchHistory();
    }, [pagination.current]);

    if (loading) {
        return <LoadingComponent />
    }

    const columns: any = [
        {
            title: <span className="text-base">{t('Gói dịch vụ')}</span>,
            dataIndex: 'plan.name',
            key: 'name',
            render: (value, record) => (
                <div>
                    <div className="text-lg font-bold">{value}</div>
                    <div className="text-gray">{t('Kỳ hạn')}: {record.periodPlan} {t('Tháng')}</div>
                </div>
            ),
        },
        {
            title: <span className="text-base">{t('Số hoá đơn')}</span>,
            dataIndex: 'referenceCode',
            key: 'code',
            align: 'center',
        },
        {
            title: <span className="text-base">{t('Ngày thanh toán')}</span>,
            dataIndex: 'createdAtDate',
            key: 'date',
            align: 'center',
            render: (value) => moment(value).format(config.DATE_TIME_FORMAT.DATE),
        },
        {
            title: <span className="text-base">{t('Tổng tiền')}</span>,
            dataIndex: 'pricePlan',
            key: 'price',
            align: 'right',
            render: (value) => (
                <span className="text-base font-bold">
                    {value.toLocaleString('vi-VN', { style : 'currency', currency : 'VND' })}
                </span>
            )
        },
    ];

    const tabList = [
        {
            key: 'user-plan',
            tab: t('Gói dịch vụ khả dụng')
        },
        {
            key: 'history-purchase',
            tab: t('Lịch sử đơn hàng')
        }
    ]

    const contentList = {
        "user-plan": <UserPlanList currentPlan={currentPlan}/>,
        "history-purchase": <>
                    {/* <div className="text-xl font-bold mb-1">{t('Lịch sử đơn hàng')}</div> */}
                    <Table
                        bordered
                        size="large"
                        rowKey={(r: any) => r.id}
                        dataSource={history}
                        columns={columns}
                        // scroll={{ y : 500 }}
                        pagination={{
                            ...pagination,
                            onChange: page => {
                                setPagination({ current: page });
                            },
                        }}
                    />
        </>
    }

    const onTabChange = async (key) => {
        setTabKey(key);
    };

    return (
        <Row gutter={[8, 8]} >
            <Col xs={24} md={18}>
                <Card
                    tabList={tabList}
                    activeTabKey={tabKey}
                    onTabChange={(key) => onTabChange(key)}
                    >
                    {contentList[tabKey]}
                </Card>
            </Col>
            <Col xs={24} md={6}>
                <div className="text-xl font-bold mb-1">{t('Gói dịch vụ hiện tại')}</div>
                <Card>
                    <div className="flex justify-between items-center text-sm mb-3">
                        <div>{t('Tên gói')}:</div>
                        <strong>{currentPlan.planName}</strong>
                    </div>
                    <div className="flex justify-between items-center text-sm mb-3">
                        {currentPlan.planKey === 'basic' ? (
                            <strong className="ml-auto">Miễn phí</strong>
                        ) : (
                                <>
                                    <div>{t('Ngày kết thúc')}:</div>
                                    <strong>{moment(currentPlan.expiredAt).format(config.DATE_TIME_FORMAT.DATE)}</strong>
                                </>
                            ) 
                        }
                    </div>
                    <Link to={routes.UPGRADE_PLAN}>
                        <Button block size="large" type="primary" className="font-bold">{t('Mua/Nâng cấp')}</Button>
                    </Link>
                </Card>
            </Col>
        </Row>
    )
};

export default PlanManagement;