import React from "react";
import { Layout, Row, Col, Card, Form, Input, Button, message, Avatar, Space } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NormalFooter from "../../components/NormalFooter";
import api from "../../services/api";
import routes from "../../routes";
import themeModeGlobalState from "../../contexts/ThemeMode";
import LogoWhite from "../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../assets/image/logo/Fstock_black.svg";

const ResetPasswordPage = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const { recoveryPasswordToken } = useParams();

    const [themeMode,] = themeModeGlobalState();
    const logo = themeMode === 'dark' ? LogoWhite : LogoBlack;

    const resetPassword = async (formValues) => {
        let { password } = formValues;

        // eslint-disable-next-line
        let [err, resp]: any = await api.post('user/reset-password', {
            password,
            recoveryPasswordToken
        })

        if (err) {
            message.error({
                key: "reset-password-error",
                content: t('Có lỗi xảy ra, quý khách vui lòng thử lại!'),
            });
            return;
        }

        message.success({
            key: "reset-password-success",
            content: t('Khôi phục mật khẩu thành công!'),
        });

        history.push(routes.LOGIN)
    };

    return (
        <Layout className="auth-page">
            <Row justify="center" align="middle" className="h-full">
                <Col xs={22} sm={18} md={12} lg={10} xl={5}>
                    <Space className="brand flex justify-center mb-4" align="center">
                        <Avatar shape="square" size={128} src={logo} />
                    </Space>
                    <Card title={t('Khởi tạo lại mật khẩu')} headStyle={{ textAlign: "center" }}>
                        <Form
                            size="middle"
                            name="reset_password_form"
                            initialValues={{ remember: true }}
                            onFinish={resetPassword}
                        >
                            <Form.Item
                                name="password"
                                rules={[
                                    { required: true, message: t('Không được để trống') },
                                    { min: 5, message: t('Mật khẩu phải có ít nhất 5 ký tự') }
                                ]}
                                hasFeedback
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    type="password"
                                    placeholder={t('Mật khẩu mới')}
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirmPassword"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    { required: true, message: t('Không được để trống') },
                                    ({getFieldValue}) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(t('Mật khẩu không khớp'));
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon"/>}
                                    type="password"
                                    placeholder={t('Xác nhận mật khẩu mới')}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button className="mb-2" type="primary" htmlType="submit" block>
                                    {t('Khởi tạo mật khẩu')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <NormalFooter />
        </Layout>
    );
};

export default ResetPasswordPage;
