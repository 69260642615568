import React, { useState, useEffect } from "react";
import { Card, Table, Tooltip, Button, message, Space, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import api from "../../../../../services/api";
import { useReducedState, toRoute } from "../../../../../services/utils";
import * as config from "../../../../../config";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import routes from "../../../../../routes";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import AdminPermission from "../../../../../components/AdminPermission";

const NotificationSampleListComponent = () => {
	const [listNotificationSamples, setListNotificationSamples] = useState([]);
	const [pagination, setPagination] = useReducedState({
		current: 1,
		pageSize: config.PAGE_SIZE,
		total: 0,
	});

	const fetchNotificationSampleList = async () => {
		let [err, resp]: any = await api.get(
			"/notification/all-notifications-sample",
			{
				page: pagination.current,
				pageSize: config.PAGE_SIZE,
			}
		);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		let { notificationSamples, total } = resp.data;
		setListNotificationSamples(notificationSamples);
		setPagination({ total });
	};

	const handleDelete = async (sampleId: number) => {
		let [err]: any = await api.delete(`/notification/delete/${sampleId}`);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		fetchNotificationSampleList();
	};

	useEffect(() => {
		fetchNotificationSampleList();
	}, [pagination.current]); // eslint-disable-line

	const columns: ColumnsType<NSApp.NotificationSample> = [
		{
			title: "Tiêu đề",
			key: "title",
			width: 300,
			// textWrap: "word-break",
			ellipsis: true,
			render: (notificationSample) => (
				<Link
					to={toRoute(routes.ADMIN_EDIT_NOTIFICATION_SAMPLE, {
						notificationSampleId: notificationSample.id,
					})}
				>
					<Tooltip placement="top" title={notificationSample?.title}>
						<span>{notificationSample?.title}</span>
					</Tooltip>
				</Link>
			),
		},
		{
			title: "Mô tả",
			dataIndex: "description",
			className: "whitespace-normal-important",
			render: (description) => <span>{description}</span>,
		},
		{
			title: "Nền tảng",
			dataIndex: "platform",
			render: (platform) => <span>{platform}</span>,
		},
		{
			title: "Action",
			width: 200,
			render: (sample) => {
				return (
					<Space>
						<Popconfirm
							title="Bạn có chắn chắn muốn xóa"
							icon={
								<QuestionCircleOutlined
									style={{
										color: "red",
									}}
								/>
							}
							onConfirm={() => handleDelete(sample.id)}
						>
							<Button type="primary">Xóa</Button>
						</Popconfirm>
					</Space>
				);
			},
		},
	];

	return (
		<Card>
			<MyBreadcrumd items={[{ label: "Mẫu thông báo" }]} />

			<div className="mt-2">
				<Button type="primary">
					<Link to={routes.ADMIN_ADD_NOTIFICATION_SAMPLE}>
						Thêm mẫu thông báo
					</Link>
				</Button>
			</div>

			<div className="mt-3">
				<Table
					columns={columns}
					dataSource={listNotificationSamples}
					rowKey={(r: any) => r.id}
					pagination={{
						onChange: (page) => {
							setPagination({ current: page });
						},
						...pagination,
					}}
				/>
			</div>
		</Card>
	);
};

const NotificationSampleList = () => {
	return <AdminPermission roleName={[]}>
		<NotificationSampleListComponent />
	</AdminPermission>
}

export default NotificationSampleList;
