import React from "react";
import { Row, Col, Skeleton } from "antd";

type SkeletonProps = {
    column?: number
}

const SkeletonGrid = (props: SkeletonProps) => {
    const skeletonArray = new Array(props.column ? props.column : 1).fill(1);

    return <Row gutter={4}>
        {skeletonArray.map((value, index) => {
                return <Col flex={1} key={`skeleton-${index}`}>
                    <Skeleton active/>
                </Col>
            })}
    </Row>
};

export default SkeletonGrid;