import { UserType } from "../user";

export const RECOMMENDATION_ACCEPTED = "accepted";
export const RECOMMENDATION_PENDING = "pending";
export const RECOMMENDATION_DENIED = "denied";

export interface RecommendationCategoryType {
    _id: string,
    name: string,
    link: string
}

export interface RecommendationType {
    _id: string,
    title: string,
    link: string,
    description: string,
    content: string,
    thumbnail: string,
    createdAt: string,
    category?: RecommendationCategoryType,
    author: UserType,
    status: string
}