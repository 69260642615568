import React, { useMemo } from 'react';
import { ColumnsType } from "antd/lib/table";
import { useContext } from 'use-context-selector';

import TablePrice from '../components/TablePrice';
import _ from 'lodash';
import { DERIVATIVE_DEFAULT_LIST } from '../../../../../../../config';
import { StockContext } from '../../../../../../../contexts/StocksProvider';

type TablePriceContainerProps = {
    exchange?: string,
    stockType?: string,
    stockCodes?: string[],
    extendColumns?: ColumnsType<NSApp.Stock>,
    preColumns?: ColumnsType<NSApp.Stock>,
    columns?: ColumnsType<NSApp.Stock>,
    height?: number,
    priceRatio?: number,
    volumeRatio?: number,
    glContainer?: {
        height: number,
        width: number
    },
    tableProps?: any,
    sortBy?: { column: string, order: "asc" | "desc" },
    changeTradingView?: (stockSymbol: string) => void,
}

const TablePriceComponentContainer = (props: TablePriceContainerProps) => {
    const stocksContext = useContext(StockContext);
    const stocksDefault = _.values(stocksContext[0]);

    let stockFiltered = stocksDefault;

    if (props.exchange) {
        stockFiltered = _.filter(stocksDefault, (stockData) => {
            return stockData.exchange === props.exchange;
        });
    } else if (props.stockCodes) {
        let stockCodes = props.stockCodes;
        stockFiltered = _.filter(stocksDefault, (stockData) => {
            return stockCodes.includes(stockData.stockCode);
        });
    }

    if (props.stockType) {
        stockFiltered = _.filter(stockFiltered, (stockData: any) => {
            return stockData.stockType === props.stockType;
        });

        // if (props.stockType === 'derivative') {

        //     stockFiltered = _.pickBy(stockFiltered, (stockData: any) => {
        //         return !DERIVATIVE_DEFAULT_LIST.includes(stockData.stockCode)
        //     });

        //     let mainIndexVn30 = mainIndexClient.mainIndexState.vn30;
        //     if (mainIndexVn30) {
        //         let mainIndexVn30Value = mainIndexVn30.data.indexValue / 100;

        //         for (let stockCode in stockFiltered) {
        //             let stockData = stockFiltered[stockCode];

        //             if (stockData.matchedPrice) {
        //                 stockData = {...stockData, differentValue: stockData.matchedPrice - mainIndexVn30Value}
        //                 stockFiltered[stockCode] = stockData;
        //             }
        //         }
        //     }
        // }
    }


    // stocks = _.values(stockFiltered);
    let sortByColumn = props?.sortBy?.column ? props.sortBy.column : 'stockCode';
    let order = props?.sortBy?.order ?  props.sortBy.order : 'asc';

    stockFiltered = _.orderBy(stockFiltered, [(s) => {
        if (sortByColumn === 'stockCode') {
            return s[sortByColumn];
        }

        return _.isNumber(s[sortByColumn]) ? s[sortByColumn] : 0;
    } ], [order]);

    if (props.stockType === 'derivative') {
        stockFiltered = stockFiltered.map((sd, index) => {
            let relateFuCode = DERIVATIVE_DEFAULT_LIST[index];
            return {
                ...sd,
                relateFuCode
            };
        });
    }

    return useMemo(() => {
        return (
            <TablePrice
                {...props}
                {...props.tableProps}
                stocks={stockFiltered}
            />
        )
    }, [props])
}

export default TablePriceComponentContainer;