import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { Input, DatePicker, Table, Modal, Typography, Row, Col, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import api from "../../../../../services/api";
import * as config from "../../../../../config";
import { useReducedState } from "../../../../../services/utils";

const { Title } = Typography;

interface DataType {
    date: string;
    fullname: string;
    email: string;
    content: string;
    commission: number;
}


const InvitedAccount = () => {
    const { t } = useTranslation();
    const [transactions, setTransactions] = useState<NSApp.UserAffiliateTransaction[]>([]);
    const [dateRange, setDateRange] = useState<[string, string]>();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const onChangeRangePicker = (date: any, dateString: [string, string]) => {
        setDateRange(dateString);
    }

    const fetchTransactionHistories = async () => {
        const [err, resp]: any = await api.get('/affiliate/transactions', {
            type: 'commission',
            page: pagination.current,
            limit: pagination.pageSize,
            startDate: dateRange ? dateRange[0] : null,
            endDate: dateRange? dateRange[1] : null,
        });

        if (err) {
            return;
        }

        const _transactions = resp.data.transactions;
        const total = resp.data.pagination.total;
        setTransactions(_transactions);
        setPagination({ total });
    }

    useEffect(() => {
        fetchTransactionHistories();
    }, [pagination.current]);

    const columns: ColumnsType<NSApp.UserAffiliateTransaction> = [
        {
            title: t('Ngày'),
            dataIndex: 'createdAt',
            align: 'center',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: t('Nội dung'),
            align: 'center',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('Hoa hồng sau thuế TNCN'),
            key: 'amount',
            dataIndex: 'amount',
            align: 'center',
            render: (amount) => amount.toLocaleString(),
        },
    ];

    return (
        <div>

            <Row>
                <Col span={8}>
                    <Title level={3}>{t('Người được mời')}</Title>
                </Col>
                <Col span={8} offset={8} className="text-right">
                    <Input.Group compact>
                        <DatePicker.RangePicker style={{ width: '50%' }} onChange={onChangeRangePicker} />
                        <Button type="primary" style={{ width: '10%' }} onClick={fetchTransactionHistories}>Lọc</Button>
                    </Input.Group>
                </Col>
            </Row>
            <Table
                columns={columns} dataSource={transactions} bordered
                pagination={{
                    onChange: page => {
                        setPagination({ current: page });
                    },
                    ...pagination
                }}
            />
        </div>
    )
}

export default InvitedAccount;
