import React from "react";
import { Row, Col, Card, List, Tabs, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import { priceFormat, priceChangePercentFormat } from '../../../../../../../../services/stock-price';
import { getStockColorClass } from "../../../../../../../../services/utils";
import * as config from "../../../../../../../../config";
import Skeleton from "../../../../../../../../components/Skeleton";
import { t } from "i18next";

const TAB_HEADER_HEIGHT = 45;

type CompanyProfileProps = {
    layoutWidth: number;
    layoutHeight: number;
    companyProfile: NSApp.CompanyProfile | undefined,
    relatedCompanies: NSApp.RelatedCompanies | undefined,
    leaderships: NSApp.CompanyLeadership[],
    sameIndustryCompany: NSApp.CompanyProfile[]
};

const decodeHtml = (html) => {
    let txt = document.createElement('textarea');
    txt.innerHTML = html;
	return txt.value;
};

const CompanyProfile = (props: CompanyProfileProps) => {
    const { layoutHeight, layoutWidth, companyProfile, leaderships, relatedCompanies, sameIndustryCompany } = props;
    const [themeMode,] = themeModeGlobalState();

    if (!companyProfile) {
        return <Row gutter={[4, 4]} style={{height: layoutHeight - TAB_HEADER_HEIGHT}} className="overflow-auto">
            <Col span={6}>
                <Card bodyStyle={{padding: 4, height: (layoutHeight - 42)}}><Skeleton/></Card>
            </Col>
            <Col span={12}>
                <Card bodyStyle={{padding: 4, height: (layoutHeight - 42)}}><Skeleton/></Card>
            </Col>
            <Col span={6}>
                <Card bodyStyle={{padding: 4, height: (layoutHeight - 42)}}><Skeleton/></Card>
            </Col>
        </Row>
    };

    const relatedCompanyColumns: ColumnsType<NSApp.RelatedCompany> = [
        {
            title: t('Tên công ty'),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: t('Mã'),
            dataIndex: 'stockCode',
            key: 'stockCode',
            width: 80,
            ellipsis: true,
            render: (value) => value.toUpperCase()
        },
        {
            title: t('VĐL(Tỷ)'),
            dataIndex: 'charterCapital',
            key: 'charterCapital',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (value) => (value/1000000000).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 })
        },
        {
            title: t('TL nắm giữ'),
            dataIndex: 'percentage',
            key: 'percentage',
            align: 'right',
            width: 80,
            ellipsis: true,
            render: (value) => value && `${(value*100).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 })}%`
        },
    ];

    const sameCompanyColumns: ColumnsType<NSApp.CompanyProfile> = [
        {
            title: t('Mã'),
            dataIndex: 'stockCode',
            key: 'stockCode',
            width: 60,
            ellipsis: true,
            render: (value, record: any) => {
                return <span style={{color: getStockColorClass(record, themeMode)}}>{value && value.toUpperCase()}</span>
            }
        },
        {
            title: t('Giá'),
            dataIndex: 'matchedPrice',
            key: 'matchedPrice',
            align: 'right',
            ellipsis: true,
            render: (value, record: any) => <span style={{color: getStockColorClass(record, themeMode)}}>
                {value && priceFormat(value)} ({record.pricePercentChange > 0 && '+'}{priceChangePercentFormat(record.pricePercentChange)}%)
            </span>
        },
        {
            title: t('KLGD'),
            dataIndex: 'totalVolume',
            key: 'totalVolume',
            align: 'right',
            ellipsis: true,
            render: (value) => value && value.toLocaleString(config.LOCALE_NUMBER_FORMAT)
        }
    ];

    const profileData = [
        { title: t('Mã SIC'), value: companyProfile.stockCode.toUpperCase() },
        { title: t('Tên ngành'), value: companyProfile.industryNameL2 },
        { title: t('Mã ngành ICB'), value: companyProfile.industryCode },
        { title: t('Năm thành lập'), value: moment(companyProfile.foundingDate).format(config.DATE_TIME_FORMAT.DATE) },
        { title: t('VĐL(Tỷ)'), value: (companyProfile.charterCapital / 1000000000).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 }) },
        { title: t('Số lượng nhân viên'), value: companyProfile.numberOfEmployee.toLocaleString(config.LOCALE_NUMBER_FORMAT) },
        { title: t('Số lượng chi nhánh'), value: companyProfile.numberOfBranch.toLocaleString(config.LOCALE_NUMBER_FORMAT) }
    ];

    const listingData = [
        { title: t('Ngày niêm yết'), value: moment(companyProfile.listingDate).format(config.DATE_TIME_FORMAT.DATE) },
        { title: t('Nơi niêm yết'), value: companyProfile.exchange.toUpperCase() },
        { title: t('Giá chào sàn'), value: priceFormat(companyProfile.firstPrice) },
        { title: t('KL đang niêm yết'), value: companyProfile.issueShare.toLocaleString(config.LOCALE_NUMBER_FORMAT) },
        { title: t('Thị giá vốn(Tỷ)'), value: (companyProfile.listedValue / 1000000000).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 }) },
        { title: t('SLCP lưu hành'), value: companyProfile.issueShare.toLocaleString(config.LOCALE_NUMBER_FORMAT) },
    ];

    return <Row gutter={[4, 4]} style={{height: layoutHeight - TAB_HEADER_HEIGHT}} className="overflow-auto">
        <Col span={layoutWidth > config.RESPONSIVE_SCREEN.md ? 6 : 24}>
            <Card title={<strong>{t('Thông tin cơ bản')}</strong>} className="mb-1" bodyStyle={{ padding: 0 }} style={{height: (layoutHeight - 44)/2}}>
                <div className="overflow-auto" style={{height: (layoutHeight - 44)/2 - 50}}>
                    <List
                        size="small"
                        dataSource={profileData}
                        renderItem={(item) => (
                            <List.Item>
                                <div>{item.title}</div>
                                <div>{item.value}</div>
                            </List.Item>
                        )}
                    />
                </div>
            </Card>
            <Card title={<strong>{t('Thông tin niêm yết')}</strong>} bodyStyle={{ padding: 0 }} style={{height: (layoutHeight - 44)/2}}>
                <div className="overflow-auto" style={{height: (layoutHeight - 44)/2 - 50}}>
                    <List
                        size="small"
                        dataSource={listingData}
                        renderItem={(item) => (
                            <List.Item>
                                <div>{item.title}</div>
                                <div>{item.value}</div>
                            </List.Item>
                        )}
                    />
                </div>
            </Card>
        </Col>
        <Col span={layoutWidth > config.RESPONSIVE_SCREEN.md ? 12 : 24}>
            <Card bodyStyle={{ padding: 4 }} style={{height: layoutHeight - 40}}>
                <Tabs defaultActiveKey="introduce" tabBarStyle={{fontSize: 10}}>
                    <Tabs.TabPane tab={t('Giới thiệu')} key="introduce">
                        <div className="overflow-auto p-1" style={{height: layoutHeight - TAB_HEADER_HEIGHT*2}}>
                            <div dangerouslySetInnerHTML={{__html: decodeHtml(companyProfile.description) }} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('Ban lãnh đạo')} key="leaderships">
                        <div className="overflow-auto" style={{height: layoutHeight - TAB_HEADER_HEIGHT*2}}>
                            <List
                                size="small"
                                dataSource={leaderships}
                                renderItem={(item) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={item.fullname}
                                            description={item.positionName}
                                        />
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('Công ty con')} key="subCompany">
                        <Table
                            rowKey={(row: NSApp.RelatedCompany) => row?._id}
                            dataSource={relatedCompanies?.sub}
                            columns={relatedCompanyColumns}
                            pagination={false}
                            scroll={{ y: layoutHeight - 110 }}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('Công ty liên kết')} key="linkCompany">
                        <Table
                            rowKey={(row: NSApp.RelatedCompany) => row?._id}
                            dataSource={relatedCompanies?.link}
                            columns={relatedCompanyColumns}
                            pagination={false}
                            scroll={{ y: layoutHeight - 110 }}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </Col>
        <Col span={layoutWidth > config.RESPONSIVE_SCREEN.md ? 6 : 24}>
            <Card title={<strong>{t('Công ty cùng ngành')}</strong>} bodyStyle={{ padding: 4 }} style={{height: layoutHeight - TAB_HEADER_HEIGHT}}>
                <Table
                    rowKey={(row: NSApp.CompanyProfile) => row?._id}
                    dataSource={sameIndustryCompany}
                    columns={sameCompanyColumns}
                    pagination={false}
                    scroll={{ y: layoutHeight - 110 }}
                />
            </Card>
        </Col>
    </Row>
};

export default CompanyProfile;
