import React from 'react';
import { Button, Modal, notification } from 'antd';
import { useTranslation } from "react-i18next";
import api from "../../../../services/api";

const LockAccount = () => {
    const { t } = useTranslation();

    const doLockAccount = async () => {
        const [err,]: any = await api.post('user/request-lock-account', {});

        if (err) {
            notification.error({
                message: t('Yêu cầu không hợp lệ'),
            });
            return;
        }

        notification.success({
            message: t('Yêu cầu tạm khoá tài khoản được gửi thành công. Xin vui lòng kiểm tra hòm thư email của bạn để xác nhận'),
            duration: 5000,
        });
    }

    const showConfirmModal = () => {
        Modal.confirm({
            title: t('Bạn chắc chắn muốn khoá tài khoản này?'),
            okText: t('Xác nhận'),
            cancelText: t('Hủy'),
            onOk() { doLockAccount() },
        });
    };

    return (
        <div>
            <div className='text-sm mb-2'>
                {t('Khi yêu cầu khoá tài khoản. Bạn sẽ không thể sử dụng tài khoản này nữa. Liên hệ admin để được mở khoá tài khoản')}.
            </div>
            <Button
                type="primary"
                size="middle"
                onClick={showConfirmModal}
            >
                {t('Yêu cầu khóa tài khoản')}
            </Button>
        </div>
    )
};

export default LockAccount;