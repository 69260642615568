import React from "react";
import { Menu, Modal } from "antd";
import { LockOutlined } from '@ant-design/icons';

const PluginsList = (props) => {
    const { plugins } = props;

    const onSelect = () => {
        Modal.info({
            title: 'Để sử dụng plugin vui lòng kéo thả chúng vào màn hình chính',
            onOk() {},
        });
    };

    return (
        <Menu mode="inline" className="plugins-menu" onSelect={onSelect}>
            {plugins.map((group) => {
                return (
                    <Menu.ItemGroup key={group?.key} title={group?.group_name}>
                        {group?.plugins.map((plugin) => {
                            return (
                                <Menu.Item
                                    className="flex justify-between items-center"
                                    id={`${plugin?.key}`}
                                    key={plugin?.key}
                                    disabled={plugin?.disabled}
                                >
                                    {plugin?.title}
                                    {plugin?.disabled && <LockOutlined/>}
                                </Menu.Item>
                            );
                        })}
                    </Menu.ItemGroup>
                )
            })}
            {/* <Menu.Item key="market" disabled>- Kinh tế - Thị trường</Menu.Item>
            <Menu.Item key="enterprise" disabled>- Doanh nghiệp</Menu.Item>
            <Menu.Item key="analysis" disabled>- Báo cáo phân tích</Menu.Item> */}
        </Menu>
    );
};

export default PluginsList;
