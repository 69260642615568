import api from '../../../../../../../services/api';
import * as config from "../../../../../../../config";

export const fetchRecommendations = async (categoryId, filter, page) => {
    // eslint-disable-next-line
    let [error, response]: any = await api.get(`plugins/recommendation/get-by-category/${categoryId}`, { filter, page, pageSize: config.PAGE_SIZE });

    if (error) {
        return [error.response.data.message, null];
    }

    return [null, response.data];
};

export const fetchRecommendationDetail = async (recommendationId) => {
    //eslint-disable-next-line
    let [error, response]: any = await api.get(`plugins/recommendation/${recommendationId}`);

    if (error) {
        return [error.response.data.message, null];
    }

    return [null, response.data];
}