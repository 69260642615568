import React, { useState, useEffect } from "react";
import { Card, Typography, Divider, Button, Space, Popconfirm, message } from "antd";
import { Link, useParams, useHistory } from "react-router-dom";

import api from "../../../../services/api";
import routes from "../../../../routes";
import { toRoute } from "../../../../services/utils";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading"

const { Title, Paragraph } = Typography;

const NewsDetail = () => {
    const history = useHistory();
    const { newsId } = useParams();
    const [ news, setNews ]: any = useState();

    const fetchNews = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/news/${newsId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setNews(resp?.data?.news);
    };

    const deleteNews = async (newsId) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.delete(`plugins/news/${newsId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Xóa thành công');
        history.replace(routes.ADMIN_NEWS_LIST);
    };

    useEffect(() => {
        fetchNews();
        // eslint-disable-next-line
    }, []);

    if (!news) {
        return <Loading/>
    }

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Tin tức', path: routes.ADMIN_NEWS_LIST },
                { label: news.title },
            ]} />

            <Typography className="mt-3">
                <Title>{news.title}</Title>

                <Space>
                    <Link to={toRoute(routes.ADMIN_NEWS_EDIT, { newsId: news._id })}>
                        <Button type="primary">Sửa</Button>
                    </Link>

                    <Popconfirm
                        title="Bạn chắc chắn muốn xóa bài viết này?"
                        onConfirm={() => deleteNews(news._id)}
                        okText="Xóa"
                        cancelText="Hủy"
                    >
                        <Button type="primary" danger>Xóa</Button>
                    </Popconfirm>
                </Space>

                <Divider />
                <Paragraph>
                    <div dangerouslySetInnerHTML={{__html: news.content}}/>
                </Paragraph>
            </Typography>
        </Card>
    )
}

export default NewsDetail;