export default {
    PUBLIC: '/public',
    LOGIN: '/user/login',
    REGISTER: '/user/register',
    REGISTER_SUCCESSFULLY: '/user/register-successfully',
    USER_ACTIVE: '/user/active/:token',
    USER_LOCK_ACCOUNT: '/user/lock-account/:token',
    USER_DELETE_ACCOUNT: '/user/delete-account/:token',
    FORGOT_PASSWORD: '/user/forgot-password',
    RESET_PASSWORD: '/user/reset-password/:recoveryPasswordToken',
    POLICY_EN: '/en/privacy_policy',
    POLICY_VI: '/vi/privacy_policy',

    HOME: '/',
    PRIVATE: '/private',
    PLUGINS: '/plugins',
    NEWS_PLUGINS_DETAIL: '/plugins/news/:newsId',
    TOPICS: '/topics',
    PROFILE: '/profile',
    FAVOURITES: '/favourites',
    WATCH_LIST: '/watch-list',
    USER_MANUAL: '/user-manual',
    HOW_TO_USE: 'https://www.youtube.com/playlist?list=PL9KaEltseXXxvzi9p5xTA8HfOkFkBVWeP',
    CONTACT: '/contact-and-support',

    UPGRADE: '/upgrade',
    UPGRADE_PLAN: '/upgrade/plans',
    UPGRADE_PAYMENT_INFO: '/upgrade/payment/:planId/:period/:price',
    UPGRADE_PAYMENT_RESULT: '/upgrade/result',

    DASHBOARD: '/dashboard',
    DASHBOARD_CONTENT: '/dashboard/:dashboardId',

    STOCK_DETAIL: '/stock/:symbol',

    RECOMMENDATION_DETAIL: '/recommendation/:recommendationId',

    ARTICLE: '/articles',
    ARTICLE_DETAIL: '/article/:articleId',

    NOTIFICATION_INFO_DETAIL: '/notification/:notificationId',
    NOTIFICATION: '/notification',

    TRADINGVIEW: '/tradingview/:symbol',
    DASHBOARD_NEWS: '/dashboard-default/news',
    DASHBOARD_MACROECONOMICS: '/dashboard-default/macroeconomics',
    DASHBOARD_MARKET_ANALYSIS: '/dashboard-default/market-analysis',
    // DASHBOARD_DERIVATIVE_SECURITIES: '/dashboard-default/derivative-securities',
    DASHBOARD_DERIVATIVES: '/dashboard-default/derivatives',

    ADMIN_DASHBOARD: '/admin',

    ADMIN_NEWS: '/admin/news',
    ADMIN_NEWS_LIST: '/admin/news/list',
    ADMIN_NEWS_ADD: '/admin/news/add',
    ADMIN_NEWS_DETAIL: '/admin/news/:newsId',
    ADMIN_NEWS_EDIT: '/admin/news/:newsId/edit',
    ADMIN_NEWS_CATEGORY_LIST: '/admin/news-category/list',
    ADMIN_NEWS_CATEGORY_ADD: '/admin/news-category/add',
    ADMIN_NEWS_CATEGORY_EDIT: '/admin/news-category/:categoryId/edit',
    ADMIN_NEWS_PROVIDER_LIST: '/admin/news-provider/list',
    ADMIN_NEWS_PROVIDER_ADD: '/admin/news-provider/add',
    ADMIN_NEWS_PROVIDER_EDIT: '/admin/news-provider/:providerId/edit',

    ADMIN_RECOMMENDATION: '/admin/recommendation',
    ADMIN_RECOMMENDATION_LIST: '/admin/recommendation/list',
    ADMIN_RECOMMENDATION_ADD: '/admin/recommendation/add',
    ADMIN_RECOMMENDATION_DETAIL: '/admin/recommendation/:recommendationId',
    ADMIN_RECOMMENDATION_EDIT: '/admin/recommendation/:recommendationId/edit',
    ADMIN_RECOMMENDATION_CATEGORY_LIST: '/admin/recommendation-category/list',
    ADMIN_RECOMMENDATION_CATEGORY_ADD: '/admin/recommendation-category/add',
    ADMIN_RECOMMENDATION_CATEGORY_EDIT: '/admin/recommendation-category/:categoryId/edit',

    ADMIN_ARTICLE: '/admin/article',
    ADMIN_ARTICLE_LIST: '/admin/article/list',
    ADMIN_ARTICLE_ADD: '/admin/article/add',
    ADMIN_ARTICLE_DETAIL: '/admin/article/:articleId',
    ADMIN_ARTICLE_EDIT: '/admin/article/:articleId/edit',
    ADMIN_ARTICLE_CATEGORY_LIST: '/admin/article-category/list',
    ADMIN_ARTICLE_CATEGORY_ADD: '/admin/article-category/add',
    ADMIN_ARTICLE_CATEGORY_EDIT: '/admin/article-category/:categoryId/edit',

    ADMIN_USER_ROLES: '/admin/user-role',
    ADMIN_USER_ROLES_LIST: '/admin/user-role/list',
    ADMIN_USER_ROLES_EDIT: '/admin/user-role/:userRoleId/edit',

    ADMIN_USER: '/admin/user',
    ADMIN_USER_LIST: '/admin/user/list',
    ADMIN_USER_EDIT: '/admin/user/:userId/edit',
    ADMIN_USER_DETAIL: '/admin/user/:userId',
    
    ADMIN_DASHBOARD_MANAGER: '/admin/dashboard-manager',
    ADMIN_DASHBOARD_MANAGER_LIST: '/admin/dashboard-manager/list',
    ADMIN_DASHBOARD_MANAGER_ADD: '/admin/dashboard-manager/add',
    ADMIN_DASHBOARD_MANAGER_EDIT: '/admin/dashboard-manager/:dashboardId/edit',

    ADMIN_PUSH_NOTIFICATION: '/admin/notification-sample/:notificationSampleId/push',
    ADMIN_NOTIFICATION_LIST: '/admin/notification/list',
    ADMIN_NOTIFICATION_SAMPLE_LIST: '/admin/notification-sample/list',
    ADMIN_ADD_NOTIFICATION_SAMPLE: '/admin/add-notification-sample',
    ADMIN_EDIT_NOTIFICATION_SAMPLE: '/admin/notification-sample/:notificationSampleId/edit',
    ADMIN_DELETE_NOTIFICATION_SAMPLE: '/admin/notification-sample/:notificationSampleId/delete',
    ADMIN_EDIT_NOTIFICATION: '/admin/notification/:notificationId/edit',

    ADMIN_QUEUE_JOBS_LIST: '/admin/queue-jobs',
    ADMIN_PLAN_LIST: '/admin/plans',
    ADMIN_PLAN_ADD: '/admin/plans/add',
    ADMIN_PLAN_EDIT: '/admin/plans/edit/:planId',

    ADMIN_FEATURE_LIST: '/admin/features',
    ADMIN_FEATURE_ADD: '/admin/features/add',
    ADMIN_FEATURE_EDIT: '/admin/features/edit/:featureId',

    ADMIN_UPLOAD_DATA_LIST: '/admin/upload-data',
    ADMIN_MANAGE_PAYMENT: '/admin/payment',
    ADMIN_MANAGE_AFFILIATE: '/admin/affiliate',
    ADMIN_MANAGE_AFFILIATE_LIST: '/admin/affiliate/list',
    ADMIN_MANAGE_AFFILIATE_WITHDRAW: '/admin/affiliate/withdraw',
    ADMIN_MANAGE_AFFILIATE_USER_DETAILS: '/admin/affiliate/:userId'
}
