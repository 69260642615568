import { Layout } from "antd";
import React from "react";
import UpgradeHeader from "../../../components/UpgradeHeader";
import UpgradeFooter from "../../../components/UpgradeFooter";
import PlanInfo from "./PlanInfo";

const UpgradePublicPage = () => {
    return (
        <Layout className="justify-between light-theme">
            <div className="bg-gray-200">
                <UpgradeHeader />
                <PlanInfo />
            </div>
            <UpgradeFooter />
        </Layout>
    );
};

export default UpgradePublicPage;