import * as MacroeconomicsServices from '../services';

export const useMacroeconomicsClient = () => {
    const fetchMacroeconomics = async () => {
        let [error, response] = await MacroeconomicsServices.fetchMacroeconomics();

        if (error) return [error, null];

        return [null, response];
    };

    return {
        fetchMacroeconomics,
    }
};