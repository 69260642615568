import React, { useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import api from "../../../../services/api";
import { useReducedState } from "../../../../services/utils";
import moment from "moment";
import * as config from "../../../../config/index";



const UserPlanList = (props: any) => {
    const currentPlan = props.currentPlan;
    const [userPLansList, setUserPLansList] = useState([]);
    
    const { t } = useTranslation();
    const [pagination, setPagination] = useReducedState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const fetchUserPlans = async () => {
        const [error, response]: any = await api.get('/user-plan/userplans', {
            page: pagination.current, pageSize: pagination.pageSize
        });

        // console.log(error, response)
        if (error) {
            return;
        }

        if (response) {
            const { userPlans, total } = response.data;
            setUserPLansList(userPlans)
            setPagination({ total: total });
        }
    };



    const columns: any = [
        {
            title: <span className="text-base">{t("Gói dịch vụ")}</span>,
            dataIndex: 'plan',
            align: 'center',
            render: (plan) => <span>{plan.name}</span>
        },
        {
            title: <span className="text-base">{t("Ngày bắt đầu")}</span>,
            dataIndex: 'startedAt',
            align: 'center',
            render: (value) => moment(value).format(config.DATE_TIME_FORMAT.DATE)
        },
        {
            title: <span className="text-base">{t("Ngày kết thúc")}</span>,
            dataIndex: 'expiredAt',
            align: 'center',
            render: (value) => moment(value).format(config.DATE_TIME_FORMAT.DATE)
        },
        {
            title: <span className="text-base">{t("Hình thức kích hoạt")}</span>,
            dataIndex: 'type',
            align: 'center',
            render: (type, record) => {
                if( record.purchaseId === 'trial') {
                    return <span>Dùng thử</span>
                }
                if(type === 'vnpay') {
                    return <span>Thanh toán Vnpay</span>
                }
                else if(type === 'fpoint') {
                    return <span>Quy đổi Fpoint</span>
                }
                else if (type === 'APP_STORE') {
                    return <span>App store</span>
                }
                else if (type === 'PLAY_STORE') {
                    return <span>Play Store</span>
                }
                return <span></span>
            }
        },
        {
            title: <span className="text-base">{t("Trạng thái")}</span>,
            dataIndex: 'purchaseId',
            align: 'center',
            render: (value, record) => {
                
                if(currentPlan?.planName?.toString() === record?.plan.name?.toString() && currentPlan?.expiredAt?.toString() === record?.expiredAt?.toString()) {
                    return  <Tag color={"#389e0d"}> {"Đang hoạt động"}</Tag>
                }
                else return  <Tag color={"#1d39c4"}> {"Chưa sử dụng"}</Tag>
            }
        }
    ];

    useEffect(() => {
        fetchUserPlans();
    }, [pagination.current]);

    return (
        <>
            {/* <div className="text-xl font-bold mb-1">
                {t("Lịch sử kích hoạt dịch vụ")}
            </div> */}
            <Table
                bordered
                size="large"
                rowKey={(r: any) => r.id}
                dataSource={userPLansList}
                columns={columns}
                scroll={{ y : 500 }}
                pagination={{
                    ...pagination,
                    onChange: (page) => {
                        setPagination({ current: page });
                    },
                }}
            />
        </>
    );
};

export default UserPlanList;
