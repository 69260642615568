import React, { useState } from 'react';
import { useEffectOnce, useUnmount } from "react-use";
import _ from 'lodash';
import { useTranslation } from "react-i18next";

import api from "../../../../../../../services/api";
import EtfListTable from "../components/EtfListTable";

const EtfPortfolio = (props: any) => {
    const { glContainer } = props;

    const [ etfList, setEtfList ] = useState<NSApp.EtfDetail[]>([])

    const fetchEtfList = async () => {
        const [err, resp]: any = await api.get('stock/etf/list');

        // console.log('fetchEtfList', err, resp);
        if (err) {
            return;
        }

        const etfGroupByStatus = await _.groupBy(resp.data, e => e?.status);
        const etfs = etfGroupByStatus?.listed?.concat(etfGroupByStatus?.unlisted);

        setEtfList(etfs);
    };

    useEffectOnce(() => {
        fetchEtfList();
    });

    return (
        <div>
            <EtfListTable stocks={etfList} layoutHeight={glContainer.height} />
        </div>
    );
};

export default EtfPortfolio;
