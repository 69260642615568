import React, { useState, useRef } from "react";
import { useEffectOnce, useUnmount } from "react-use";
import _ from 'lodash';
import { useTranslation } from "react-i18next";

import localStorage from "../../../../../../../services/local-storage";

import api from "../../../../../../../services/api";
import { DEFAULT_BREAKOUT_FILTERS, FSTOCK_BREAKOUT_FILTERS_ADVANCED, FSTOCK_BREAKOUT_FILTERS_PRO } from "../utils";

import BreakoutCriteria from "../components/BreakoutCriteria";
import BreakoutTable from "../components/BreakoutTable";
import { Button } from "antd";
import { Link } from 'react-router-dom';
import routes from "../../../../../../../routes"
import { useAuthState } from "../../../../../../../contexts";

const UserACLContainer = (props) => {
    const planName = props?.userACLName;
    const { t } = useTranslation();
    return (
    <div className="flex flex-col justify-center items-center h-full" >
        {!!planName && <div className="text-2xl font-bold mb-1">{t('Tính năng')} {planName}</div>}
    <div className="text-sm text-gray mb-4">{t('Vui lòng nâng cấp tài khoản để sử dụng tính năng này')}</div>
        <Link to={routes.UPGRADE_PLAN}>
            <Button type="primary" size="large" className="font-bold">{t('Nâng cấp tài khoản')}</Button>
        </Link>
    </div>)
}

const BreakoutContainer = (props) => {
    const { authState } = useAuthState() as any;
    const { t } = useTranslation();
    const userACLKey = authState?.currentUser?.userACL?.key;

    const BASIC_LABEL = t('Tiêu chí A.I Fstock Basic');
    const ADVANCED_LABEL = t('Tiêu chí A.I Fstock Advanced');
    const PRO_LABEL = t('Tiêu chí A.I Fstock Pro');

    const { glContainer } = props;
    const [filterList, setFilterList] = useState<any[]>(DEFAULT_BREAKOUT_FILTERS);
    const [stocks, setStocks] = useState<NSApp.StockBreakout[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isDefaultFilters, setIsDefaultFilters] = useState(true);
    const [defaultFiltersLabel, setDefaultFiltersLabel] = useState<string>('');
    const [criteriaExpanded, setCriteriaExpanded] = useState(true);
    const [planName, setPlanName] = useState<string>(userACLKey === 'advanced' ? 'Chuyên nghiệp' : 'gói Nâng cao và gói Chuyên nghiệp');
    
    const _isDisplayBreakoutTable = userACLKey === 'basic' ? false : true;
    const [isDisplayBreakoutTable, setIsDisplayBreakoutTable] = useState<boolean>(_isDisplayBreakoutTable);    


    const dataIntervalRef = useRef<any>(null);

    const fetchStockBreakout = async (_filter: any) => {
        // const _pagination = paginationRef.current;
        const [err, resp]: any = await api.get('screener/stock-breakout', {
            filter: _filter,
            page: 1,
            pageSize: 1000,
        });

        if (err) {
            setStocks([]);
            return;
        }

        if (!resp.data) {
            setStocks([]);
            return;
        }

        setStocks(resp.data.rows);
        // setPagination({ total: resp.data.count });
        setIsLoading(false);
        return resp.data;
    };

    const fetchMainIndexDataInterval = async () => {
        if (dataIntervalRef.current) {
            clearInterval(dataIntervalRef.current);
        }

        dataIntervalRef.current = setInterval(async () => {
            let _breakoutFilter = await localStorage.get('breakoutFilter');
            await fetchStockBreakout(_breakoutFilter);
        }, 60 * 1000);
    };

    const initData = async () => {
        let _breakoutFilter = await localStorage.get('breakoutFilter');
        const isSync = await localStorage.get('isSyncFilterV7');

        if (!_breakoutFilter || !isSync) {
            localStorage.set('breakoutFilter', filterList);
            localStorage.set('isSyncFilterV7', 'synced');

            _breakoutFilter = filterList;
        }

        const _isDefaultFilters = _.isEqual(DEFAULT_BREAKOUT_FILTERS, _breakoutFilter);
        const _isAdvancedDefaultFilters = _.isEqual(FSTOCK_BREAKOUT_FILTERS_ADVANCED, _breakoutFilter);
        const _isProDefaultFilters = _.isEqual(FSTOCK_BREAKOUT_FILTERS_PRO, _breakoutFilter);

        if (_isDefaultFilters) {
            setDefaultFiltersLabel(BASIC_LABEL);
        }

        if (_isAdvancedDefaultFilters) {
            setDefaultFiltersLabel(ADVANCED_LABEL);

        }

        if (_isProDefaultFilters) {
            setDefaultFiltersLabel(PRO_LABEL);
        }

        setIsDefaultFilters(_isDefaultFilters || _isAdvancedDefaultFilters || _isProDefaultFilters);

        await fetchStockBreakout(_breakoutFilter);
        setFilterList(_breakoutFilter);
    };

    useEffectOnce(() => {
        initData();
        fetchMainIndexDataInterval();
    });

    useUnmount(() => {
        if (dataIntervalRef.current) {
            clearInterval(dataIntervalRef.current);
            dataIntervalRef.current = undefined;
        }
    })

    const addFilter = async (filterItem: any) => {
        let breakoutFilter = await localStorage.get('breakoutFilter');
        const _isDefaultFilters = filterItem.isDefault;

        if (_isDefaultFilters) {
            breakoutFilter = filterItem.valuesDefault;
            let filterDefaultLabel = '';
            switch (filterItem.field) {
                case 'ai-fstock-basic':
                    filterDefaultLabel = BASIC_LABEL;
                    if(userACLKey === 'advanced' || userACLKey === 'professional') { setIsDisplayBreakoutTable(true)};
                    setPlanName("gói Nâng cao và gói Chuyên nghiệp");
                    break;
                case 'ai-fstock-advanced':
                    filterDefaultLabel = ADVANCED_LABEL;
                    if(userACLKey === 'advanced' || userACLKey === 'professional') { setIsDisplayBreakoutTable(true)};
                    setPlanName("gói Nâng cao và gói Chuyên nghiệp");
                    break;
                case 'ai-fstock-pro':
                    filterDefaultLabel = PRO_LABEL;
                    if(userACLKey !== 'professional') { setIsDisplayBreakoutTable(false) }
                    setPlanName("Chuyên nghiệp");
                    break;
                default:
                    filterDefaultLabel = BASIC_LABEL;
                    if(userACLKey === 'advanced' || userACLKey === 'professional') { setIsDisplayBreakoutTable(true)};
                    setPlanName("gói Nâng cao và gói Chuyên nghiệp");
            }

            setDefaultFiltersLabel(filterDefaultLabel);
        } else {
            if (_.find(breakoutFilter, (f: any) => f.field === filterItem.field)) {
                return;
            }

            breakoutFilter = [...(breakoutFilter || []), filterItem];
        }

        setIsDefaultFilters(_isDefaultFilters);
        setFilterList(breakoutFilter);

        localStorage.set('breakoutFilter', breakoutFilter);
        await fetchStockBreakout(breakoutFilter);
    };

    const removeFilter = async (filterItems: any, isDefault?: boolean) => {
        let _filterList: any = [];

        setIsDefaultFilters(!!isDefault);

        if (isDefault) {
            setFilterList([]);
            await fetchStockBreakout([]);
            localStorage.set('breakoutFilter', _filterList);
            return;
        }

        if (filterItems.length > 1) {
            _filterList = _.pullAllWith(filterList, filterItems, _.isEqual);
        } else {
            _filterList = _.filter(filterList, (f) => {
                return !_.isEqual(f, filterItems);
            });
        }

        setFilterList(_filterList);
        localStorage.set('breakoutFilter', _filterList);
        await fetchStockBreakout(_filterList);
    };

    const setDefaultFilters = async () => {
        setIsDefaultFilters(true);
        setFilterList(DEFAULT_BREAKOUT_FILTERS);
        setDefaultFiltersLabel('Tiêu chí A.I Fstock Basic')
        localStorage.set('breakoutFilter', DEFAULT_BREAKOUT_FILTERS);
        await fetchStockBreakout(DEFAULT_BREAKOUT_FILTERS);
    };

    return <div>
        <BreakoutCriteria
            filterList={filterList}
            isDefaultFilters={isDefaultFilters}
            defaultFiltersLabel={defaultFiltersLabel}
            addFilter={addFilter}
            removeFilter={removeFilter}
            setDefaultFilters={setDefaultFilters}
            setIsDefaultFilters={setIsDefaultFilters}
            criteriaExpanded={criteriaExpanded}
            setCriteriaExpanded={setCriteriaExpanded}
            BASIC_LABEL={BASIC_LABEL}
            ADVANCED_LABEL={ADVANCED_LABEL}
            PRO_LABEL={PRO_LABEL}
        />
        <div className="mt-1">
            { isDisplayBreakoutTable ? 
            (<BreakoutTable
                    stocks={stocks}
                    isLoading={isLoading}
                    layoutHeight={glContainer.height}
                    criteriaExpanded={criteriaExpanded}
            />) : (<UserACLContainer userACLName = {planName}/>)}
        </div>
    </div>;
};

export default BreakoutContainer;