import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ArticleListPage from "./List";
import ArticleAddPage from "./Add";
import ArticleEditPage from "./Edit";
import ArticleDetailPage from "./Detail";

import ArticleCategoryListPage from "./Category/List";
import ArticleCategoryAddPage from "./Category/Add";
import ArticleCategoryEditPage from "./Category/Edit";

import routes from "../../../../routes";

const NewsDashboard = () => {
    return (
        <div>
            <Switch>
                <Route path={routes.ADMIN_ARTICLE_CATEGORY_LIST} component={ArticleCategoryListPage} />
                <Route path={routes.ADMIN_ARTICLE_CATEGORY_ADD} component={ArticleCategoryAddPage} />
                <Route path={routes.ADMIN_ARTICLE_CATEGORY_EDIT} component={ArticleCategoryEditPage} />

                <Route path={routes.ADMIN_ARTICLE_LIST} component={ArticleListPage} />
                <Route path={routes.ADMIN_ARTICLE_ADD} component={ArticleAddPage} />
                <Route path={routes.ADMIN_ARTICLE_EDIT} component={ArticleEditPage} />
                <Route path={routes.ADMIN_ARTICLE_DETAIL} component={ArticleDetailPage} />

                <Route path={routes.ADMIN_ARTICLE}>
                    <Redirect to={routes.ADMIN_ARTICLE_LIST} />
                </Route>
            </Switch>
        </div>
    )
}

export default NewsDashboard;