import api from "../../../../../../../services/api";

export const fetchMainIndex = async (indexID: string) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get(`plugins/main-index/${indexID}`);

    if (err) return;

    return resp.data;
};

export const fetchMainIndexValues = async (indexID: string) => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get(`plugins/main-index/values/${indexID}`);

    if (err) return [];

    return resp.data;
};

export const fetchMainIndexForeignValue = async () => {
    let [err, resp]: any = await api.get('plugins/main-index-foreign');

    if (err) return [];

    return resp.data;
}