import React, { useState } from "react";

import api from '../../../../../services/api';
import AddStockInput from '../components/_AddStockInput';

type AddStockInputContainerProps = {
    placeholder: string,
    style: object,
    watchlist?: NSApp.Watchlist,
    addStockWatchlist: (stockCode: string) => void,
}
const AddStockInputContainer = (props: AddStockInputContainerProps) => {
    const { placeholder, style, watchlist, addStockWatchlist } = props;

    const [ loading, setLoading ] = useState(true);
    const [ stockCode, setStockCode ] = useState<string>('');
    const [ stocksList, setStocksList ] = useState<Array<NSApp.Stock>>([]);

    const fetchListStockByCode = async (code: string) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('symbol', { code });

        // TODO: handle error
        if (err) return;

        setStocksList(resp.data);
        setLoading(false);
    };

    const handleSearch = (value: string) => {
        if (value) {
            fetchListStockByCode(value);
        } else {
            setStocksList([]);
        }
    };

    const handleChange = (value: string) => {
        setStockCode(value);
    };

    if (!watchlist) {
        return <></>
    }

    return <AddStockInput
        placeholder={placeholder}
        style={style}
        value={stockCode}
        watchlist={watchlist}
        stocksList={stocksList}
        loading={loading}
        onSearch={handleSearch}
        onChange={handleChange}
        onSelect={addStockWatchlist}
    />
}

export default AddStockInputContainer;