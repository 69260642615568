import api from "../../../../../../services/api";

export default {
    saveTab: async (data) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.post('tradingview/1.1/tabs', data);
        return resp.data;
    },
    getTab: async (id) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`tradingview/1.1/tabs/${id}`);
        return resp.data;
    }
}
