import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Input, Card, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import api from "../../../../../services/api";
import DraftEditor from "../../../../../components/DraftEditor";
import Loading from "../../../../../components/Loading";

const { TextArea } = Input;
const { confirm } = Modal;

type NotificationFormProps = {
	initialValues?: any;
	onFinish: (values: any) => void;
};

const NotificationForm = (props: NotificationFormProps) => {
	const showConfirmEditNotif = (formValues: any) => {
		confirm({
			title: "Bạn có chắc chắn muốn chỉnh sửa thông báo này không?",
			icon: <ExclamationCircleOutlined />,
			content: formValues.title,
			onOk: async () => {
				await props.onFinish(formValues);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	const { initialValues } = props;

	return (
		<Card>
			<MyBreadcrumd
				items={[
					{ label: "Thông báo" },
					{ label: "Chỉnh sửa thông báo" },
				]}
			/>

			<div className="m-auto mt-5 w-3/6">
				<Form
					layout="vertical"
					name="push_notification_form"
					className="push-notification-form"
					initialValues={initialValues}
					validateMessages={{
						// eslint-disable-next-line no-template-curly-in-string
						required: "${label} không được để trống",
					}}
					onFinish={showConfirmEditNotif}
				>
					<Form.Item
						name="title"
						label="Tiêu đề"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="Tiêu đề" />
					</Form.Item>

					<Form.Item
						name="description"
						label="Mô tả"
						rules={[
							{
								required: true,
							},
						]}
					>
						<TextArea placeholder="Mô tả" />
					</Form.Item>

					<Form.Item
						name="content"
						label="Nội dung"
						rules={[
							{
								required: true,
							},
						]}
					>
						<DraftEditor initEditorState={initialValues.content} />
					</Form.Item>
					<Form.Item>
						<Button
							className="mb-2"
							type="primary"
							htmlType="submit"
							block
						>
							Cập nhật
						</Button>
					</Form.Item>
				</Form>
			</div>
		</Card>
	);
};

function EditNotification() {
	const [notification, setNotification] = useState();
	const { notificationId } = useParams<{ notificationId: string }>();

	const fetchNotification = async () => {
		let [err, resp]: any = await api.get(`/notification/${notificationId}`);
		if (err) return;
		setNotification(resp.data);
	};

	const onFinish = async (formValues) => {
		// eslint-disable-next-line
		let [err, resp]: any = await api.put(
			`notification/${notificationId}`,
			formValues
		);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		message.success("Cập nhật thông báo thành công");
	};

	useEffect(() => {
		fetchNotification();
	}, []); // eslint-disable-line

	if (!notification) return <Loading />;

	return (
		<NotificationForm
			initialValues={notification}
			onFinish={onFinish}
		></NotificationForm>
	);
}

export default EditNotification;
