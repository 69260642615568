import React from "react";
import MarketReportsContainer from "./container";
import UserACL from '../../../../../../components/UserACL';

const MarketReportsWindow = (props) => {
    let { glContainer } = props;

    return (
        <UserACL feature="market_reports">
            <MarketReportsContainer glContainer={glContainer} />
        </UserACL>
    )
};

export default MarketReportsWindow;