export const NEWS_ACCEPTED = "accepted";
export const NEWS_DENIED = "denied";
export const NEWS_PENDING = "pending";

export enum NewsStatus {
    NEWS_ACCEPTED,
    NEWS_DENIED,
    NEWS_PENDING
}

export interface NewsProviderType {
    _id: string,
    name: string,
    link: string
}

export interface NewsCategoryType {
    _id: string,
    name: string,
    link: string
}

export interface NewsType {
    _id: string,
    title: string,
    link: string,
    description: string,
    content: string,
    thumbnail: string,
    createdAt: string,
    status: NewsStatus,
    provider?: NewsProviderType,
    category?: NewsCategoryType
}