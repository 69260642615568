import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, message } from "antd";

import NewsForm from "./_Form";
import api from "../../../../services/api";
import routes from "../../../../routes";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";

const ArticleEdit = () => {
    const { articleId } = useParams<any>();
    const [ article, setArticle ]: any = useState();

    const fetchArticle = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/article/${articleId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        const article = resp.data.article;
        setArticle(article);
    }

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/article/${articleId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật thông tin bài viết thành công");
    };

    useEffect(() => {
        fetchArticle();
        // eslint-disable-next-line
    }, []);

    if (!article) {
        return <Loading/>;
    }

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Bài viết', path: routes.ADMIN_ARTICLE_LIST },
                { label: article.title },
                { label: 'Chỉnh sửa' },
            ]} />

            <div className="m-auto mt-5 w-3/6">
                <NewsForm mode='edit' onFinish={onFinish} initialValues={article}/>
            </div>
        </Card>
    )
}

export default ArticleEdit;
