import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, message } from "antd";

import NewsForm from "./_Form";
import api from "../../../../services/api";
import routes from "../../../../routes";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";

const NewsEdit = () => {
    const { newsId } = useParams();
    const [ news, setNews ]: any = useState();

    const fetchNews = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/news/${newsId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setNews(resp?.data?.news);
    };

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/news/${newsId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật thông tin tin tức thành công");
    };

    useEffect(() => {
        fetchNews()
        // eslint-disable-next-line
    }, []);

    if (!news) {
        return <Loading/>;
    }

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Tin tức', path: routes.ADMIN_NEWS_LIST },
                { label: news.title },
                { label: 'Chỉnh sửa' },
            ]} />

            <div className="m-auto mt-5 w-3/6">
                <NewsForm
                    mode='edit' onFinish={onFinish} initialValues={news}/>
            </div>
        </Card>
    )
}

export default NewsEdit;
