import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";

import themeModeGlobalState from "../../../contexts/ThemeMode";
import routes from "../../../routes";
import Dashboard from "./Dashboard";
import DashboardContent from "./Dashboard/DashboardContent";
import NotificationPage from "./Notification";
import Profile from "./Profile";
import WatchList from "./WatchList";
import StockDetail from "./StockDetail";
import theme from "../../../themes/theme";
import RecommendationDetailContainer from './Dashboard/plugins/Recommendations/container/detail';
import NewsDetailContainer from './Dashboard/plugins/News/container/detail';
import NotificationDetail from './Notification/components/detail';
import DashboardDefault from './DashboardDefault';
import UserManual from './UserManual';
import Contact from './Contact';
import ArticlesWindow from "./Dashboard/plugins/Article";
import ArticleDetailContainer from "./Dashboard/plugins/Article/container/details";

const PrivateContent = () => {
    const [themeMode,] = themeModeGlobalState();

    return (
        <Layout.Content className="home-layout-background home-content relative overflow-hidden">
            <Switch>
                <Route path={routes.NOTIFICATION_INFO_DETAIL}>
                    <div className="overflow-auto h-full"
                        style={{backgroundColor: theme.colors.componentBackground[themeMode]}}
                    >
                        <NotificationDetail/>
                    </div>
                </Route>

                <Route path={routes.NOTIFICATION}>
                    <NotificationPage/>
                </Route>

                <Route path={routes.RECOMMENDATION_DETAIL}>
                    <div className="overflow-auto h-full"
                        style={{backgroundColor: theme.colors.componentBackground[themeMode]}}
                    >
                        <RecommendationDetailContainer/>
                    </div>
                </Route>

                <Route path={routes.NEWS_PLUGINS_DETAIL}>
                    <div className="overflow-auto h-full"
                        style={{backgroundColor: theme.colors.componentBackground[themeMode]}}
                    >
                        <NewsDetailContainer />
                    </div>
                </Route>

                <Route path={routes.ARTICLE}>
                    <DashboardDefault key='articles' dashboardType="articles" />
                </Route>

                <Route path={routes.ARTICLE_DETAIL}>
                    <div className="overflow-auto h-full"
                        style={{backgroundColor: theme.colors.componentBackground[themeMode]}}
                    >
                        <ArticleDetailContainer/>
                    </div>
                </Route>

                <Route path={routes.DASHBOARD_MACROECONOMICS}>
                    <DashboardDefault key='dashboard_macroeconomics' dashboardType="macroeconomics" />
                </Route>

                <Route path={routes.DASHBOARD_MARKET_ANALYSIS}>
                    <DashboardDefault key='dashboard_market_analysis' dashboardType="market_analysis" />
                </Route>

                {/* <Route path={routes.DASHBOARD_DERIVATIVE_SECURITIES}>
                    <DashboardDefault key='dashboard_derivative_securities' dashboardType="derivative_securities" />
                </Route> */}

                <Route path={routes.DASHBOARD_DERIVATIVES}>
                    <DashboardDefault key='dashboard_derivatives' dashboardType="derivatives" />
                </Route>

                <Route path={routes.DASHBOARD_NEWS}>
                    <DashboardDefault key='dashboard_news' dashboardType="news" />
                </Route>

                <Route path={routes.DASHBOARD_CONTENT}>
                    <DashboardContent/>
                </Route>

                <Route path={routes.PROFILE}>
                    <div className="overflow-auto h-full">
                        <Profile/>
                    </div>
                </Route>

                <Route path={routes.WATCH_LIST}>
                    <WatchList/>
                </Route>

                <Route path={routes.USER_MANUAL}>
                    <UserManual/>
                </Route>

                <Route path={routes.CONTACT}>
                    <Contact/>
                </Route>

                <Route path={routes.STOCK_DETAIL}>
                    <StockDetail/>
                </Route>
                <Route path={routes.DASHBOARD}>
                    <Dashboard/>
                </Route>
                <Route
                    exact
                    path="/"
                    render={() => {
                        return <Redirect to={routes.DASHBOARD_MARKET_ANALYSIS} />;
                    }}
                />
            </Switch>
        </Layout.Content>
    );
};

export default PrivateContent;
