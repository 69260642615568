import React from 'react';
import { Button, Space, message } from 'antd';
import { ARTICLE_ACCEPTED, ARTICLE_DENIED, ARTICLE_PENDING } from "../../../../types/plugins/article";
import api from "../../../../services/api";

type ChangeStatusButtonProps = {
    article: NSApp.Article,
    onChange: Function
}

const ChangeStatusButton = (props: ChangeStatusButtonProps) => {
    const { article } = props;

    const setArticleStatus = async (articleId: string, status: string) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.patch(`plugins/article/${articleId}/set-article-status`, {status});

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (status === ARTICLE_ACCEPTED) {
            message.success('Đã duyệt bài viết');
        }
        if (status === ARTICLE_DENIED) {
            message.success('Từ chối bài viết');
        }

        props.onChange();
    };

    return <Space>
        {article.status === ARTICLE_PENDING &&
            <>
                <Button type="primary" onClick={() => setArticleStatus(article.id, ARTICLE_ACCEPTED)}>Phê duyệt</Button>
                <Button type="primary" danger onClick={() => setArticleStatus(article.id, ARTICLE_DENIED)}>Từ chối</Button>
            </>
        }

        {article.status !== ARTICLE_PENDING &&
            <>
                {article.status === ARTICLE_DENIED ?
                    <Button type="primary" onClick={() => setArticleStatus(article.id, ARTICLE_ACCEPTED)}>Phê duyệt</Button>
                    :
                    <Button type="primary" danger onClick={() => setArticleStatus(article.id, ARTICLE_DENIED)}>Từ chối</Button>
                }
            </>
        }
    </Space>
}

export default ChangeStatusButton;