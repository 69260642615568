import { Tabs, Input } from "antd";
import React, { useState } from "react";
import colors from "../../../../../../../themes/colors";
import * as ArticleServices from '../service'
import { useEffectOnce } from "react-use";
import ArticleList from "../components/list";
import ArticleListContainer from "./list";

type ArticleContainerProps = {
    categoryName?: string,
    stockCodes?: string[],
    glContainer?: any,
    listHeight?: number
};

const { Search } = Input

const ArticleContainer = (props: ArticleContainerProps) => {
    const { categoryName, stockCodes, glContainer, listHeight } = props;
    const [ activeKey, setActiveKey ] = useState()
    let height = glContainer ? glContainer.height : listHeight;
    const [categories, setCategory] = useState<NSApp.ArticleCategory[]>([])
    const [articleSearchKey, setArticleKeySearch] = useState<string>()
    const fetchCategory = async () => {
        const [err, resp] = await ArticleServices.fetchArticleCategory({}, 1)
        setCategory(resp.categories)
    }
    
    const onChangeActiveKey = (key) => {
        setActiveKey(key);
    };

    const SearchInput = {
        right:  <Search
                    allowClear
                    placeholder="Nhập từ khóa tìm kiếm"
                    onChange={(e: any) => setArticleKeySearch(e.target.value)}
                />
    }
    useEffectOnce(() => {
        fetchCategory();
    })

    return(
        <Tabs
            className="mx-1" size="small"
            tabBarStyle={{ fontSize: 10 }}
            defaultActiveKey={activeKey}
            onChange={onChangeActiveKey}
            tabBarExtraContent={SearchInput}
        >   
            <Tabs.TabPane tab="Tổng hợp" key="latest">
                <div className="overflow-auto" style={{height: glContainer.height - 48}}>
                    {categories.map(category => {
                        return(
                            <ArticleListContainer
                                searchKey={articleSearchKey}
                                width={glContainer?.width}
                                category={category}
                                horizontal
                                isLatestTab
                                height={height}
                            />
                        )
                    })}
                </div>
            </Tabs.TabPane>
            {categories?.map(category => {
                return <Tabs.TabPane tab={category.name} key={category.id}>
                            <ArticleListContainer
                                height={height}
                                category={category}
                                searchKey={articleSearchKey}
                                width={glContainer?.width}
                                column={3}
                                pageSize={16}
                            />
                        </Tabs.TabPane>
            })}

        </Tabs>
    )
}

export default ArticleContainer