import React, { useState } from "react";
import { Card } from "antd";
import { useTranslation } from "react-i18next";

import UpdateInfo from "./UpdateInfo";
import UpdatePassword from "./UpdatePassword";
import PlanManagement from "./PlanManagement";
import NotificationCenter from "./NotificationCenter";
import Display from "./Display";
import Fpoint from "./Fpoint";
import Affiliate from "./Affiliate";
import LockAccount from "./LockAccount";
import { useEffectOnce } from "react-use";
import localStorage from "../../../../services/local-storage";
import _ from "lodash";

const Profile = () => {
    const [userProfileTabKey , setUserProfileTabKey ] = useState<string>("info");
    const { t } = useTranslation();

    const tabList = [
        {
            key: "info",
            tab: t('Thông tin cá nhân'),
        },
        {
            key: "security",
            tab: t('Đổi mật khẩu'),
        },
        {
            key: "plan_management",
            tab: t('Dịch vụ'),
        },
        {
            key: "notification_center",
            tab: t('Thông báo'),
        },
        {
            key: "display",
            tab: t('Giao diện'),
        },
        {
            key: "fpoint",
            tab: t('Fpoint'),
        },
        {
            key: "affiliate",
            tab: t('Cộng tác viên'),
        },
        {
            key: "lock_account",
            tab: t('Khoá tài khoản'),
        },
    ];

    const contentList = {
        info: <UpdateInfo />,
        security: <UpdatePassword />,
        plan_management: <PlanManagement />,
        notification_center: <NotificationCenter />,
        display: <Display />,
        fpoint: <Fpoint />,
        affiliate: <Affiliate />,
        lock_account: <LockAccount />
    };

    const saveTabKey = async () => {
        const _tabKey = await localStorage.get('userProfileTabKey')
        if(_.find(tabList, (t) => t.key === _tabKey)){
            setUserProfileTabKey(_tabKey)
        } else {
            setUserProfileTabKey("info")
        }
    }

    const onTabChange = async (key) => {
        setUserProfileTabKey(key);
        localStorage.set('userProfileTabKey',key)
    };

    useEffectOnce(() => {
        saveTabKey()
    })

    return (
        <Card
            className="h-full"
            tabList={tabList}
            activeTabKey={userProfileTabKey}
            onTabChange={(key) => onTabChange(key)}
        >
            {contentList[userProfileTabKey]}
        </Card>
    );
};

export default Profile;
