import React from 'react';

import { Switch, Route, Redirect } from "react-router-dom";

import UserListPage from "./List";
import UserEditPage from "./Edit";
import routes from "../../../../routes";
import UserDetail from './Details';

const User = () => {
    return <div>
        <Switch>
            <Route path={routes.ADMIN_USER_LIST} component={UserListPage} />
            <Route path={routes.ADMIN_USER_EDIT} component={UserEditPage} />
            <Route path={routes.ADMIN_USER_DETAIL} component={UserDetail}/>

            <Route path={routes.ADMIN_USER}>
                <Redirect to={routes.ADMIN_USER_LIST} />
            </Route>
        </Switch>
    </div>
}

export default User;