import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { useTranslation } from "react-i18next";
import { foreignValueFormat } from "../services";
import moment from "moment";
import theme from "../../../../../../../themes/theme";
import animateGlobalState from '../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

type LineChartProps = {
    stocks: NSApp.Stock[],
    foreignerTradingByTime: NSApp.ExchangeDetail[],
    exchange: string,
    sortBy: string,
    sortByValue: boolean,
    checkedStock: string,
    width: number,
    height: number,
    buyKey: string,
    sellKey: string,
    amountKey: string,
    titleSortBy: string,
    titleUnitSortBy: string,
    changeCheckedStock: (stockCode) => void,
};

const ForeignerTradingLineChart = (props: LineChartProps) => {
    const { stocks, foreignerTradingByTime, sortByValue, checkedStock, width, height, buyKey, sellKey, amountKey, titleSortBy, titleUnitSortBy } = props;
    const [ chartData, setChartData ] = useState<NSApp.Stock[]>([]);
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();
    const { t } = useTranslation();

    let chartLeftTitleDisplay = height > 95 ? "block" : "none";
    const formatType = sortByValue ? 'value' : 'volume';

    const formatData = (records) => {
        return records.map(record => {
            record.time = moment(record.time).valueOf()
            record.formattedBuyForeignValue = parseFloat(record?.foreignBuyValue);
            record.formattedBuyForeignQtty = parseFloat(record?.foreignBuyVolume);
            record.formattedSellForeignValue = parseFloat(record?.foreignSellValue);
            record.formattedSellForeignQtty = parseFloat(record?.foreignSellVolume);

            if (checkedStock === 'all') {
                record.date = record?.time;
                record.amountForeignValue = record.formattedBuyForeignValue - record.formattedSellForeignValue;
                record.amountForeignVolume = record.formattedBuyForeignQtty - record.formattedSellForeignQtty;
            } else {
                record.amountForeignValue = parseFloat(record?.amountForeignValue)
                record.amountForeignVolume = parseFloat(record?.amountForeignVolume)
            }

            return record;
        });
    };

    const generateTimelineTicks = (startTime) => {
        let tickValuesInit = new Array(7).fill(1);

        let tickValues = tickValuesInit.map((tick, index) => {
            if (index === 0) {
                return moment(startTime).valueOf();
            }
            return moment(startTime).add(index, "hours").valueOf();
        });
        return tickValues;
    };

    useEffect(() => {
        if (foreignerTradingByTime && foreignerTradingByTime.length > 0) {
            setChartData(formatData(foreignerTradingByTime));
        }
    }, [stocks, checkedStock, foreignerTradingByTime]); // eslint-disable-line

    const startTime = moment(chartData[0]?.date).valueOf();
    const endTime = moment(startTime).add(6, "hours").valueOf();

    // const onChangeCheckedStock = (value) => {
    //     changeCheckedStock(value);
    // };

    // get min + max amount value
    let minValue = Math.min(
        ...chartData.map((stock) => sortByValue ? stock?.amountForeignValue : stock?.amountForeignVolume)
    );
    let maxValue = Math.max(
        ...chartData.map((stock) => sortByValue ? stock?.amountForeignValue : stock?.amountForeignVolume)
    );

    // gradient color for area chart
    const gradientOffset = () => {
        if (maxValue <= 0) {
            return 0; // 100% red line
        }
        if (minValue >= 0) {
            return 1; // 100% green line
        }
        return (
            maxValue / (maxValue - minValue)
        ); // percent of red line / Difference of maxIndex and minIndex
    };

    const renderTooltip = ({ active, payload, label }) => {

        if (active && payload && payload.length) {
            return (
                <div className="custom-chart-tooltip">
                    <div className="text-black"><ClockCircleOutlined /> <strong>{moment(label).format("HH:mm")}</strong></div>
                    <div style={{color: payload[0]?.color}}><strong>{payload[0]?.name}: {foreignValueFormat(payload[0]?.value, formatType)} {titleUnitSortBy}</strong></div>
                    <div style={{color: payload[1]?.color}}><strong>{payload[1]?.name}: {foreignValueFormat(payload[1]?.value, formatType)} {titleUnitSortBy}</strong></div>
                    <div style={{color: payload[2]?.value < 0 ? theme.colors.red.text[themeMode] : theme.colors.green.text[themeMode]}}>
                        <strong>{payload[2]?.name}: {foreignValueFormat(payload[2]?.value, formatType)} {titleUnitSortBy}</strong>
                    </div>
                </div>
            )
        }

        return null;
    }

    // if (loading || !chartData) {
    //     return <Skeleton active />;
    // }
    //
    //

    return (
        <Card
            size="small"
            bodyStyle={{
                padding: 0,
                paddingTop: 20,
                paddingBottom: 20,
                backgroundColor: theme.colors.chartBackground[themeMode],
            }}
        >
            {/*
            <div className="p-1 mb-2">
                <Select
                    style={{width: 100}}
                    value={checkedStock ? checkedStock : "all"}
                    size="small"
                    onChange={onChangeCheckedStock}
                >
                    <Select.Option key="all" value="all">{exchange.toUpperCase()}</Select.Option>
                    {stocks.map(stock =>
                        <Select.Option key={stock?.stockCode} value={stock?.stockCode}>
                            {stock?.stockCode.toUpperCase()}
                        </Select.Option>
                    )}
                </Select>
            </div>*/}

            <div className="chart-wrapper-has-left-title">
                <h4 className="chart-left-title" style={{width: height, display: chartLeftTitleDisplay}}>{titleUnitSortBy}</h4>
                <ComposedChart
                    width={width}
                    height={height - 44}
                    data={chartData}
                >
                    <defs>
                        <linearGradient id={`${checkedStock}_${width}_${height}`} x1="0" y1="0" x2="0" y2="1">
                            <stop
                                offset={gradientOffset()}
                                stopColor={theme.colors.green.background}
                            />
                            <stop
                                offset={gradientOffset()}
                                stopColor={theme.colors.red.background}
                            />
                        </linearGradient>
                    </defs>

                    <XAxis
                        type="number"
                        dataKey="date"
                        interval={"preserveStartEnd"}
                        domain={[startTime, endTime]}
                        ticks={generateTimelineTicks(startTime)}
                        // offset 0 because timestamp from exchange is GMT+7.
                        tickFormatter={(tickValue) => `${moment(tickValue).format("HH")}h`}
                        tickLine={false}
                        stroke={theme.colors.chartAxis[themeMode]}
                        strokeWidth={0.5}
                        height={20}
                    />

                    <YAxis
                        stroke={theme.colors.chartAxis[themeMode]}
                        strokeWidth={0.5}
                        tick={{fontSize: 10}}
                        tickLine={false}
                        tickFormatter={(tickValue) => foreignValueFormat(tickValue, formatType)}
                    />

                    <Tooltip
                        cursor={false}
                        content={chartData => renderTooltip(chartData)}
                    />

                    <CartesianGrid
                        vertical={false}
                        stroke={theme.colors.gray.background}
                        strokeWidth={0.2}
                    />

                    <Legend
                        iconType="circle"
                        wrapperStyle={{fontSize: theme.fontSizes.small}}
                    />

                    <Line
                        type="monotone"
                        isAnimationActive={animate}
                        dataKey={buyKey}
                        name={`${titleSortBy} ${t('Mua')}`}
                        stroke={theme.colors.teal.background}
                        dot={false}
                    />
                    <Line
                        type="monotone"
                        isAnimationActive={animate}
                        dataKey={sellKey}
                        name={`${titleSortBy} ${t('Bán')}`}
                        stroke={theme.colors.orange.background}
                        dot={false}
                    />

                    <Area
                        type="monotone"
                        isAnimationActive={animate}
                        dataKey={amountKey}
                        name={`${titleSortBy} ${t('Ròng')}`}
                        fill={`url(#${checkedStock}_${width}_${height})`}
                        stroke={`url(#${checkedStock}_${width}_${height})`}
                    />
                </ComposedChart>
            </div>
        </Card>
    )
};

export default ForeignerTradingLineChart;
