import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { Typography, Tooltip, notification } from "antd";
import { CalendarOutlined, ClockCircleOutlined, LinkOutlined, FacebookFilled} from "@ant-design/icons";
import copy from 'copy-to-clipboard';
import moment from "moment";
import { useTranslation } from "react-i18next";

import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import Loading from "../../../../../../../components/Loading";
import api from "../../../../../../../services/api";
import theme from '../../../../../../../themes/theme';

type NewDetailProps = {
    newDetail: NSApp.New | undefined | any;
};

const { Title, Paragraph, Text } = Typography;

const NewDetail = (props: NewDetailProps) => {
    const { newDetail } = props;
    const { t } = useTranslation();

    const [shareLink, setShareLink] = useState('');
    const [shareFBLink, setShareFBLink] = useState('');
    const [themeMode,] = themeModeGlobalState();
    const isDarkMode = themeMode === 'dark';

    const getNewsShareLink = async () => {
        if (shareLink) {
            return shareLink;
        }

        if (!newDetail) {
            return "";
        }

        const [error, response]: any = await api.get(`plugins/news/${newDetail.id}/get-short-link`)
        if (error) {
            return "";
        }

        setShareLink(response.data.shortUrl);
        setShareFBLink(response.data.longUrl);
        return response.data.shortUrl;
    }

    const getCopyLink = async () => {
        const link = await getNewsShareLink();

        if(!link) {
            notification.error({
                message: 'Có lỗi xảy ra. Xin vui lòng thử lại.',
            });
            return;
        }

        copy(link);
    }

    useEffectOnce(() => {
        getNewsShareLink();
    });

    if (!newDetail) return <Loading />;

    const styleInline = `<head>
        <style>
            body {
                font-size: 1em;
                font-family: Arial;
                line-height: 1.5;
                background-color: ${
                    isDarkMode ? theme.colors.white : theme.colors.black
                };
                color: ${isDarkMode ?  theme.colors.black : theme.colors.white}
            }

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 75%;
                height: auto;
            }

            figcaption {
                text-align: center;
                margin-top: 10px;
            }

            p {
                font-size: 17px;
            }

            table {
                margin-left: auto;
                margin-right: auto;
                width: 75%;
                height: auto;
            }
        </style>
    </head>`;

    return (
        <Typography>
            <Title level={2}>{newDetail.title}</Title>
            <Paragraph>
                <Text className="text-description">
                    <CalendarOutlined /> {moment(newDetail.createdAt).format("L")}{" "}
                    <ClockCircleOutlined /> {moment(newDetail.createdAt).format("LT")}
                    <Tooltip title="Sao chép đường dẫn bài báo">
                        <LinkOutlined className="ml-5" onClick={getCopyLink} />
                    </Tooltip>
                    <div className="fb-share-button inline-block" data-href={shareFBLink} data-layout="button_count" data-size="small">
                        <a
                            target="_blank" rel="noopener noreferrer"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareFBLink)}`}
                            style={{color: isDarkMode ? theme.colors.white : theme.colors.black}}
                            className={`fb-xfbml-parse-ignore rounded-sm ml-3 bg-blue-600 text-${isDarkMode ? 'white' : 'black'} p-1`}
                        >
                            <FacebookFilled /> {t('Chia sẻ')}
                        </a>
                    </div>
                </Text>
            </Paragraph>
            <Paragraph>
                {newDetail.description}
            </Paragraph>
            <Paragraph>
                <div dangerouslySetInnerHTML={{ __html: `${styleInline} ${newDetail.content}` }}/>
            </Paragraph>
            <a
                target="_blank" rel="noopener noreferrer"
                href={newDetail.link}
            >
                {t('Đến bài viết gốc')}
            </a>
        </Typography>
    );
};

export default NewDetail;