import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Divider, List, Comment } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import moment from "moment";

import { getNewsDetail, postComment, getComments, like, unlike } from "../services";
import { useAuthState } from "../../../../../../../contexts";

type NewCommentsProps = {
    newId: number | undefined;
}

const NewComments = (props: NewCommentsProps) => {
    const { newId } = props;
    const [liked, setLiked] = useState<boolean>(false);
    const [likeCount, setLikeCount] = useState<number>(0);
    const [comments, setComments] = useState<NSApp.Comment[]>([]);

    const { authState } = useAuthState() as any;
    const { currentUser } = authState;

    const [ postForm ] = Form.useForm();

    const fetchNewsDetail = async (id: number) => {
        const [error, response]: any = await getNewsDetail(id);

        if (error) {
            return;
        }

        if (response) {
            const { liked, likeCount } = response.data.news;
            setLiked(liked);
            setLikeCount(likeCount);
        }
    }

    const fetchComments = async (id: number) => {
        const [error, response]: any = await getComments(id);

        if (error) {
            return;
        }
        if (response) {
            setComments(response.data);
        }
    };

    useEffect(() => {
        if (newId) {
            fetchNewsDetail(newId);
            fetchComments(newId)
        }
    }, [newId]);

    const doLike = async () => {
        if (newId) {
            // eslint-disable-next-line
            let [error, response]: any = await like(newId);
        }
        return;
    };

    const doUnlike = async () => {
        if (newId) {
            // eslint-disable-next-line
            let [error, response]: any = await unlike(newId);
        }
        return;
    };

    const doPostComment = async (values) => {
        if (newId) {
            // eslint-disable-next-line
            let [error, response]: any = await postComment(
                newId,
                values.content,
            );

            const newComment = {
                _id: moment().format(),
                author: { fullname: currentUser.fullname },
                content: values.content,
                createdAt: moment().format(),
            };

            setComments([newComment, ...comments]);
            postForm.resetFields();
        }
    };

    const renderComment = (comment: NSApp.Comment) => {
        return (
            <li>
                <Comment
                    author={<span className="font-bold">{comment.author?.fullname}</span>}
                    content={<p>{comment.content}</p>}
                    datetime={<span> - {moment(comment.createdAt).fromNow()}</span>}
                />
            </li>
        );
    };

    const actionLike = () => {
        setLiked(!liked);
        if (liked) {
            doUnlike();
            setLikeCount(likeCount-1);
        } else {
            doLike();
            setLikeCount(likeCount+1);
        }
    }

    return (
        <div>
            <Divider />
            <div className="flex flex-row justify-between items-center">
                <Button type={liked ? 'primary' : 'default'} onClick={actionLike}>
                    Thích
                </Button>
                <span className="text-base text-gray">
                    <LikeOutlined /> {likeCount}
                </span>
            </div>
            <Divider />
            <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={comments}
                renderItem={renderComment}
            />
            <Form
                form={postForm}
                className="my-4 max-w-screen-sm"
                name="postComment"
                onFinish={doPostComment}
            >
                <Form.Item name="content">
                    <Input.TextArea placeholder="Viết bình luận..." />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Bình luận
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
};

export default NewComments;
