import React, { useEffect, useState } from 'react';
import { Table, Tag, message } from 'antd';
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import api from "../../../../services/api";
import { setInterval } from 'timers';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table/interface';
import AdminPermission from '../../../../components/AdminPermission';

const QueueJobsListComponent = () => {
    const [queueJobs, setQueueJobs] = useState<NSApp.QueueJobs[]>([]);
    const fetchQueueJobs = async () => {
        const [err, resp]: any = await api.get('queue-job');
        if (err) {
            // message.error('Có lỗi khi tải danh sách queue');
            return;
        }
        setQueueJobs(resp.data);
    }

    useEffect(() => {
        fetchQueueJobs();
    }, [])

    setInterval(() => { fetchQueueJobs() }, 2 * 60 * 1000);

    const columns: ColumnsType<NSApp.QueueJobs> = [
        {
            title: 'Job name',
            width: 600,
            dataIndex: 'name',
            render: (name) => <span>{name}</span>
        },
        {
            title: 'Last success',
            width: 300,
            align: "center",
            dataIndex: 'last_time_success',
            render: (last_time_success) => <span>{moment.unix(last_time_success / 1000).format('DD-MM-YYYY HH:mm')}</span>
        },
        {
            title: 'Status',
            width: 200,
            align: "center",
            dataIndex: 'is_active',
            render: (is_active) => <span>
                {!is_active &&
                    <Tag color="red">INACTIVE</Tag>
                }
                {is_active &&
                    <Tag color="green">ACTIVE</Tag>
                }
            </span>
        }
    ];

    return <>
        <MyBreadcrumd items={[{ label: 'Quản lý queue' }]} />
        <div className="mt-2">
            <Table
                columns={columns}
                dataSource={queueJobs}
            />
        </div>
    </>
};

const QueueJobsList = () => {
    return <AdminPermission roleName={[]}>
        <QueueJobsListComponent />
    </AdminPermission>
}

export default QueueJobsList;