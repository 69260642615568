import React, { useState, useEffect } from "react";
import { Form, Button, Input, TreeSelect, Space, message } from "antd";

import api from "../../../../../services/api";

type NewsCategoryFormProps = {
    initialValues?: any,
    parent?: any,
    mode: "add" | "edit",
    onFinish: (values: any) => void,
}

type CategoryItem = {
    id: string,
    pId: string,
    value: string,
    title: string
}

const CategoryForm = (props: NewsCategoryFormProps) => {
    const [categoryList, setCategoryList] = useState<Array<CategoryItem>>([]);

    const { initialValues, mode, onFinish } = props;


    const fetchCategories = async (filter) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/news/category', { filter });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { categories = [] } = resp?.data;
        return categories;
    }

    const initData = async () => {
        let categories = await fetchCategories({ parent: null });
        categories = categories.map(c => {
            return {
                id: c._id,
                value: c._id,
                title: c.name,
                pId: 0
            }
        })
        setCategoryList(categories);
    }

    const onLoadData = (treeNode): Promise<void> => {
        return new Promise(async resolve => {

            let childCategories = await fetchCategories({ parent: treeNode.value });

            childCategories = childCategories.map(c => {
                return {
                    id: c._id,
                    value: c._id,
                    title: c.name,
                    pId: treeNode.value
                }
            })

            setCategoryList(categoryList.concat(childCategories));
            resolve();
        })
    }

    useEffect(() => {
        initData();
        //eslint-disable-next-line
    }, []);

    return (
        <Form
            layout="vertical"
            name="news_category_form"
            className="news-category-form"
            initialValues={initialValues}
            onFinish={onFinish}
        >
            <Form.Item
                name="name"
                label="Tên danh mục"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input placeholder="Tên danh mục" />
            </Form.Item>

            <Form.Item
                name="parent"
                label={
                    <Space>
                        <span>Danh mục</span>
                        {mode === 'edit' && props?.parent &&
                            <span>{props?.parent?.name}</span>
                        }
                    </Space>
                }
            >
                <TreeSelect
                    value={''}
                    treeDataSimpleMode
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Danh mục cha"
                    treeData={categoryList}
                    loadData={onLoadData}
                />
            </Form.Item>

            <Form.Item
                name="link"
                label="Đường dẫn"
            >
                <Input placeholder="Đường dẫn" />
            </Form.Item>

            <Form.Item>
                <Button className="mb-2" type="primary" htmlType="submit" block>
                    {mode === 'add' ? 'Thêm mới' : 'Cập nhật'}
                </Button>
            </Form.Item>
        </Form>
    )
}

export default CategoryForm;