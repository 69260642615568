import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'antd';
import { useTranslation } from "react-i18next";
import { useReducedState } from "../../../../../services/utils";
import * as config from "../../../../../config";
import api from "../../../../../services/api";


const InviteFriend = () => {
    const { t } = useTranslation();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const [invitedFriends, setInvitedFriends] = useState<NSApp.User[]>([]);

    const fetchInvitedFriends = useCallback(async () => {
        const [error, response]: any = await api.get(
            '/user/get-invited-friends',
            {
                page: pagination.current,
                pageSize: pagination.pageSize,
            },
        );

        if (error) {
            return;
        }

        const users = response.data.users;
        setInvitedFriends(users);
        setPagination({ total: response.data.total });
    }, [pagination.current, setPagination]); // eslint-disable-line

    useEffect(() => {
        fetchInvitedFriends();
    }, [fetchInvitedFriends]);

    const columns: any = [
        {
            title: t('Họ và tên'),
            dataIndex: 'fullname',
        },
        {
            title: t('Email'),
            dataIndex: 'email',
        },
    ];

    return (
        <Table
            bordered
            columns={columns}
            dataSource={invitedFriends}
            rowKey={(r: NSApp.User) => r?.id}
            pagination={{
                ...pagination,
                onChange: page => {
                    setPagination({ current: page });
                },
            }}
        />
    );
};

export default InviteFriend;
