import React from 'react';
import { Switch, Route } from "react-router-dom";

import PlanList from './List';
import EditPlanForm from './Edit';
import routes from "../../../../routes";
import AddPlan from './Add';


const PlanDashboard = () => {
    return <div>
        <Switch>
            <Route path={routes.ADMIN_PLAN_ADD} component={AddPlan}/>
            <Route path={routes.ADMIN_PLAN_EDIT} component={EditPlanForm}/>
            <Route path={routes.ADMIN_PLAN_LIST} component={PlanList}/>
        </Switch>
    </div>
}

export default PlanDashboard;