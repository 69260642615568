import React from "react";
import { Card } from "antd";
import { ComposedChart, Bar, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Text } from "recharts";
import theme from "../../../../../../../../themes/theme";
import useConfig from "../../../../../../../../contexts/useConfig";
import animateGlobalState from '../../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";

const colors = [
    theme.colors.orange.background,
    theme.colors.green.background,
    theme.colors.purple.background,
    theme.colors.yellow.background,
    theme.colors.teal.background,
];

const ChartCard = (props) => {
    const { title, width, height, data, chartType, YAxisLabel, YAxisUnit, secondYAxis, secondYAxisLabel, secondYAxisUnit, chartData } = props;

    const [config] = useConfig();
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();

    // const formattedData = data.map((entry, index) => {
    //     // const label = filterBy === 'year' && useCustomXAxisLabel && index === data.length - 1 ? `${config.CURRENT_YEAR_CHART_XAXIS_LABEL}${entry.date}` : entry.date;
    //     // entry.dateLabel = label;
    //     entry.dateLabel = entry.date;
    //     return entry;
    // });

    const renderLabel = (type) => {
        const left = type === "left";

        return (
            <Text
                x={left ? 0 : width-35} y={0}
                dx={10} dy={height/2} angle={left ? -90 : 90}
                fill={theme.colors.gray.text[themeMode]}
                textAnchor="middle"
            >
                {left ? YAxisLabel : secondYAxisLabel}
            </Text>
    )};

    const tickFormatter = (value: number, unit: string) => {
        if (unit === "billion" || unit === " Tỷ") {
            if (Number(value) === 0) return Number(value);
            if (Number(value) !== 0) return (Number(value)/1000000000).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 0 });
        } else if (unit === "%") {
            return Number(value * 100).toLocaleString(config.LOCALE_NUMBER_FORMAT);
        } else {
            return Number(value).toLocaleString(config.LOCALE_NUMBER_FORMAT);
        }
    };

    return (
        <Card bodyStyle={{ backgroundColor: theme.colors.chartBackground[themeMode] }}>
            <p className="uppercase font-bold">{title}</p>

            {chartType === 'composed' &&
            <ComposedChart
                width={width}
                height={height}
                data={data}
                margin={{top: 5, right: 15, left: 5, bottom: 0}}
            >
                <XAxis
                    dataKey="date"
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    tick={{fontSize: 9}}
                    tickLine={false}
                />
                <YAxis
                    yAxisId="left"
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => tickFormatter(tickValue, YAxisUnit)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    label={renderLabel("left")}
                />
                {secondYAxis &&
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => tickFormatter(tickValue, secondYAxisUnit)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    label={renderLabel("right")}
                />
                }
                <CartesianGrid
                    vertical={false}
                    stroke={theme.colors.gray.background}
                    strokeWidth={0.2}
                />
                <Tooltip
                    cursor={false}
                    itemStyle={{padding: 0}}
                    labelStyle={{ color: 'black', fontWeight: 'bold' }}
                    wrapperStyle={{fontWeight: 'bold'}}
                    formatter={(value, name, props) => tickFormatter(value, props.unit)}
                />
                <Legend wrapperStyle={{fontSize: theme.fontSizes.tiny}}/>

                {chartData?.bar.length && chartData?.bar.map((item) => {
                    return (
                        <Bar
                            connectNulls
                            yAxisId="left"
                            isAnimationActive={animate}
                            key={item?.dataKey}
                            dataKey={item?.dataKey}
                            name={item?.name}
                            unit={item?.unit}
                            fill={theme.colors.orange.background}
                            radius={theme.chart.bar.radius.vertical}
                        />
                    )
                })}

                {chartData?.line.length && chartData?.line.map((item, index) => {
                    return (
                        <Line
                            connectNulls
                            type="monotone"
                            isAnimationActive={animate}
                            key={item?.dataKey}
                            dataKey={item?.dataKey}
                            yAxisId={item?.yAxisType}
                            name={item?.name}
                            unit={item?.unit}
                            stroke={colors[index+chartData.bar.length]}
                            strokeWidth={1.5}
                        />
                    )
                })}
            </ComposedChart>
            }

            {chartType === 'bar' &&
            <ComposedChart
                width={width}
                height={height}
                data={data}
                margin={{top: 5, right: 20, left: 5, bottom: 0}}
                stackOffset="sign"
            >
                <XAxis
                    dataKey="date"
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    tick={{fontSize: 10}}
                    tickLine={false}
                />
                <YAxis
                    yAxisId="left"
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => tickFormatter(tickValue, YAxisUnit)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    label={renderLabel("left")}
                />
                {secondYAxis &&
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tick={{fontSize: 10}}
                    tickLine={false}
                    tickFormatter={(tickValue) => tickFormatter(tickValue, secondYAxisUnit)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.5}
                    label={renderLabel("right")}
                />
                }
                <CartesianGrid
                    vertical={false}
                    stroke={theme.colors.gray.background}
                    strokeWidth={0.2}
                />
                <Tooltip
                    cursor={false}
                    itemStyle={{padding: 0}}
                    labelStyle={{ color: 'black', fontWeight: 'bold' }}
                    wrapperStyle={{fontWeight: 'bold'}}
                    formatter={(value, name, props) => tickFormatter(value, props.unit)}
                />
                <Legend wrapperStyle={{fontSize: theme.fontSizes.tiny}}/>

                {chartData?.bar.map((item, index) => {
                    return (
                        <Bar
                            connectNulls
                            yAxisId="left"
                            stackId="stack"
                            isAnimationActive={animate}
                            key={item?.dataKey}
                            dataKey={item?.dataKey}
                            name={item?.name}
                            unit={item?.unit}
                            barSize={20}
                            fill={colors[index]}
                        />
                    )
                })}
                {chartData?.line.length && chartData?.line.map((item, index) => {
                    return (
                        <Line
                            connectNulls
                            type="monotone"
                            isAnimationActive={animate}
                            key={item?.dataKey}
                            dataKey={item?.dataKey}
                            yAxisId={item?.yAxisType}
                            name={item?.name}
                            unit={item?.unit}
                            stroke={colors[index+chartData.bar.length]}
                            strokeWidth={1.5}
                        />
                    )
                })}
            </ComposedChart>
            }
        </Card>
    )
};

export default ChartCard;
