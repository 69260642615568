import React, { useState } from "react";
import { Button, Card, message, Popconfirm, Space, Table }from "antd"
import api from "../../../../services/api";
import { useEffect } from 'react';
import { QuestionCircleOutlined } from "@ant-design/icons";
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import { Link } from 'react-router-dom';
import routes from "../../../../routes";
import { toRoute } from '../../../../services/utils';


const FeatureList = () => {
    const [listFeatures, setListFeature] = useState([]);

    const fetchFeatureList = async () => {
		let [err, resp]: any = await api.get(
			"/plan/list-features"
		);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		let features = resp.data;
		setListFeature(features);
	};

    const handleDelete = async (featureId: string) => {
        let [err, resp]: any = await api.delete(
			`/plan/delete-feature/${featureId}`
		);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		if(resp.data) {
            message.success("Xoá tính năng thành công");
            fetchFeatureList();
        }
    }

    useEffect(() => {
        fetchFeatureList();
    },[]);
    
    const colums: any = [
        {
            title: "ID",
            key: "id",
            dataIndex: "id"
        },
        {
            title: "Name",
            key: "name",
            dataIndex: "name",
            textWrap: "word-break",
        },
        {
            title: "Code",
            key: "code",
            dataIndex: "code"
        },
        {
            title: "Group",
            key: "group",
            dataIndex: "group"
        },
        {
            title: "Action",
            render: (feature) => {


				return (
					<Space>
                        <Link to={toRoute(routes.ADMIN_FEATURE_EDIT, {featureId: feature.id})}>
                            <Button type="primary">Sửa</Button>
                        </Link>
                        
						<Popconfirm
							title="Bạn có chắn chắn muốn xóa"
							icon={
								<QuestionCircleOutlined
									style={{
										color: "red",
									}}
								/>
							}
							onConfirm={() => handleDelete(feature.id)}
						>
							<Button >Xóa</Button>
						</Popconfirm>
					</Space>
				);
			},
        }
    ];

    return <Card>
        <div>
            <MyBreadcrumd items={[{ label: "Quản lý tính năng" }]}/>
            <Link to={routes.ADMIN_FEATURE_ADD}>
                <Button type="primary">
                    Thêm mới
                </Button>
            </Link>
            
            <Table
                columns={colums}
                dataSource={listFeatures}
                rowKey={(r: any) => r.id}
                pagination={false}
            >
            </Table>
        </div>
    </Card>
}

export default FeatureList;