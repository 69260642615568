import React from "react";
import { Modal } from 'antd';
import moment from "moment";

const TrialModal = (props: any) => {
    const {visible, handleOk, handleCancel, plan} = props;

    return <Modal
        centered
        title={<span className="text-xl font-bold">Chúc mừng bạn!</span>}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Nâng cấp"
        cancelText="Đóng"
        okButtonProps={{ size: 'large' }}
        cancelButtonProps={{ size: 'large' }}
    >
        <p className="text-base">
            Bạn đã được ưu đãi sử dụng miễn phí gói <b className="text-primary">Chuyên nghiệp</b> đến ngày <b>{moment(plan?.expiredAt).format("DD/MM/YYYY")}</b>, sau ngày này tài khoản của bạn sẽ chuyển thành gói <b className="text-primary">Cơ bản</b> (miễn phí).
        </p>
        <p className="text-base">Để tiếp tục duy trì gói <b className="text-primary">Chuyên nghiệp</b>, vui lòng nâng cấp tài khoản.</p>
    </Modal>
}

export default TrialModal;
