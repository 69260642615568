import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecommendationsClient } from '../client';
import RecommendationDetail from '../components/detail';

type RecommendationDetailContainerProps = {
    recommendation?: NSApp.Recommendation | undefined | null,
    backToRecommendationsList?: Function | undefined | null,
};

const RecommendationDetailContainer = (props: RecommendationDetailContainerProps) => {
    const { recommendationId } = useParams();
    const { backToRecommendationsList } = props;

    const [ recommendation, setRecommendation ] = useState<NSApp.Recommendation | undefined | null>();
    const [ errorMessage, setErrorMessage ] = useState('');
    let recommendationsClient = useRecommendationsClient();
    const { t } = useTranslation();

    const hasBreadcrumb = recommendationId ? false : true;

    const fetchRecommendationDetail = async () => {
        let [err, resp] = await recommendationsClient.fetchRecommendationDetail(recommendationId);

        if (err) {
            setErrorMessage(t('Có lỗi xảy ra khi tải khuyến nghị hoặc tài khoản của bạn chưa được kích hoạt plugin này. Vui lòng liên hệ admin để biết thêm chi tiết.'));
            return;
        }

        if (resp) {
            setRecommendation(resp);
        }
    };

    useEffect(() => {
        if (props.recommendation) {
            setRecommendation(props.recommendation);
        }

        if (recommendationId) {
            fetchRecommendationDetail();
        }
        //eslint-disable-next-line
    }, [recommendationId]);

    return (
        <RecommendationDetail
            recommendation={recommendation}
            backToRecommendationsList={backToRecommendationsList}
            errorMessage={errorMessage}
            hasBreadcrumb={hasBreadcrumb}
        />
    )
};

export default RecommendationDetailContainer;