import _ from "lodash";
import * as config from "../config";

export const priceFormat = (value: number, ratio: number = 1000, fixed=2, hideZeroValue?: boolean) => {
    if (Number(value) === 0 && hideZeroValue) return "";

    return (Number(value)/ratio).toFixed(fixed);
};

// export const pricePercentChangeFormat = (value: number) => {
//     return value ? value.toFixed(2) : 0;
// };

export const nmTotalTradedValueFormat = (value: number) => {
    if (Number(value) <= 0) return "";
    return (Number(value) / 1000000).toLocaleString();
};

export const nmTotalTradedFormat = (value: number) => {
    if (Number(value) <= 0) return "";
    return Number(value).toLocaleString();
};

export const priceChangePercentFormat = (value: number) => {
    return Number(value).toFixed(2);
};

export const localeFormat = (value: number) => {
    if (Number.isInteger(value)) {
        return value.toLocaleString(config.LOCALE_NUMBER_FORMAT);
    } else {
        return Number(value).toLocaleString(config.LOCALE_NUMBER_FORMAT, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
};

export const aggregateVolumeByStockPrice = (matchedStockOrders: NSApp.MatchedStockOrder[]) => {
    let groupByPrice = _.groupBy(matchedStockOrders, 'price')
    let result: any[] = [];

    for (const [price, listStockOrder] of Object.entries<any>(groupByPrice)) {
        let totalVolumeByPrice = _.reduce(listStockOrder, (sum, n) => {
            return sum + n.volume;
        }, 0);

        // let referencePrice = listStockOrder[0].referencePrice;

        result.push({
            price: price,
            totalVolume: totalVolumeByPrice,
            referencePrice: 0,
        });
    }

    return result.sort((a, b) => {return a.price - b.price});
}
