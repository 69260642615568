import React from 'react';
import { Row, Col, Switch } from 'antd';
import { useTranslation } from "react-i18next";

import animateGlobalState from '../../../../contexts/Animate';
import localStorage from "../../../../services/local-storage";

const DisplaySettings = () => {
    const [animate, setAnimate] = animateGlobalState();
    const { t } = useTranslation();

    const onAnimateChange = async (checked: boolean) => {
        setAnimate(checked);
        await localStorage.set('animate', String(checked));
    };

    return (
        <div>
            <Row className="my-2">
                <Col span={3}>
                    <span className="font-bold">{t('Biểu đồ động')}</span>
                </Col>
                <Col>
                    <Switch
                        size="default"
                        defaultChecked={animate}
                        onChange={onAnimateChange}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default DisplaySettings;