import React, { useState, useEffect } from "react";
import { Form, Button, Input, Select, message } from "antd";

import UploadImage from "../components/UploadImage";
import DraftEditor from "../../../../components/DraftEditor";
import api from "../../../../services/api";

const { Option } = Select;

type RecommendationFormProps = {
    initialValues?: any,
    mode: "add" | "edit",
    onFinish: (values: any) => void,
}

const ArticleForm = (props: RecommendationFormProps) => {
    const [listCategory, setListCategory] = useState([]);

    const fetchCategories = async (name: string) => {
        let filter = {}

        if (name) {
            filter['name'] = name;
        }

        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/article/category', { filter });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { categories } = resp?.data

        setListCategory(categories);
    };

    useEffect(() => {
        fetchCategories("");
    }, []);

    const onSearchCategory = (val) => {
        fetchCategories(val);
    }

    const onFilterSelectInput = (input, option: any) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const { initialValues, mode, onFinish } = props;

    return (
        <Form
            layout="vertical"
            name="article_form"
            className="article-form"
            initialValues={initialValues}
            onFinish={onFinish}
        >
            <Form.Item
                name="title"
                label="Tiêu đề"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input placeholder="Tiêu đề" />
            </Form.Item>

            <Form.Item
                name="description"
                label="Mô tả"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input placeholder="Mô tả" />
            </Form.Item>

            <Form.Item
                name="categoryId"
                label="Danh mục"
                hasFeedback
                rules={[
                    { required: true },
                ]}
            >
                <Select
                    showSearch
                    onSearch={onSearchCategory}
                    placeholder="Chọn danh mục"
                    filterOption={onFilterSelectInput}
                >
                    {listCategory.map((category: any) => <Option key={category?.id} value={category?.id}>{category?.name}</Option>)}
                </Select>
            </Form.Item>

            <Form.Item
                label='Hình minh họa'
                name='thumbnail'
                valuePropName='file'
            >
                <UploadImage defaultImage={initialValues?.thumbnail} name='thumbnail' url='media/thumbnail/upload' />
            </Form.Item>

            <Form.Item
                label="Nội dung"
                name="content"
            >
                <DraftEditor defaultState={initialValues?.content}/>
            </Form.Item>

            <Form.Item>
                <Button className="mb-2" type="primary" htmlType="submit" block>
                    {mode === 'add' ? 'Thêm mới' : 'Cập nhật'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ArticleForm;
