import React, { useState, useEffect }  from "react";
import moment from "moment";
import { Table, Tooltip, Button, Space, Input, Select, Tag, message, Popconfirm, Modal } from "antd";

import api from "../../../../services/api";
import * as config from "../../../../config";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import { useReducedState } from "../../../../services/utils";
import AdminPermission from "../../../../components/AdminPermission";

const { Option } = Select;
const { TextArea } = Input;

export const USER_AFFILIATE_TRANSACTION_STATUS = {
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILED: 'failed'
}

const WithDrawListPageComponent = () => {
    const [ statusFilter, setStatusFilter ] = useState<string>('');
    const [ listTransaction, setListTransaction ] = useState<any[]>([]);
    const [ cancelNote, setCancelNote ] = useState<string>();
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ cancelTransId, setCancelTransId ] = useState<any>();

    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchUser = async (userId: string) => {
        let [err, resp]: any = await api.get(`/user/${userId}`);

        if (err) return;

        return resp.data;
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const fetchTransactions = async () => {
        // eslint-disable-next-line
        let filter = {};

        if (statusFilter) {
            filter['status'] = statusFilter;
        }

        let [err, resp]: any = await api.get('/affiliate/transactions-admin', {
            ...filter,
            type: 'withdraw',
            page: pagination.current, limit: config.PAGE_SIZE
        });

        console.log('fetchTransactions', err, resp);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        let { transactions, pagination: _pagination } = resp?.data;
        let _listTransaction: any[] = [];

        for (const t of transactions) {
            let user = await fetchUser(t.userId);

            let transItem = {
                ...t,
                user,
            }

            _listTransaction.push(transItem);
        }

        setListTransaction(_listTransaction);
        setPagination({ total: _pagination.total });
    };

    const approveWithdrawTransaction = async (id: any) => {
        let [err, resp]: any = await api.post('/affiliate/confirm-withdraw', {
            id
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Phê duyệt thành công!');
        fetchTransactions();
    }

    const revertWithdrawTransaction = async (id: any) => {
        let [err, resp]: any = await api.post('/affiliate/revert-withdraw', {
            id
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Khôi phục trạng thái thành công!');
        fetchTransactions();
    }

    const rejectWithdrawTransaction = async () => {

        console.log('id, note', cancelTransId, cancelNote);
        if (!cancelNote) {
            message.error('Nội dung hủy không được bỏ trống');
        }

        if (!cancelTransId) {
            message.error('Không tìm được id transaction');
        }

        let [err, resp]: any = await api.post('/affiliate/reject-withdraw', {
            id: cancelTransId, note: cancelNote
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Từ chối thành công!');
        fetchTransactions();
        setIsModalOpen(false);
        setCancelTransId(undefined);
    }

    const btnSearchOnClick = () => {
        fetchTransactions();
    };

    useEffect(() => {
        fetchTransactions();
    }, [pagination.current]); // eslint-disable-line


    const columns: any = [
        {
            title: 'Tên',
            dataIndex: 'user',
            textWrap: 'word-break',
            ellipsis: true,
            align: 'center',
            render: (user) => user.fullname,
        },
        {
            title: 'Email',
            dataIndex: 'user',
            textWrap: 'word-break',
            ellipsis: true,
            align: 'center',
            render: (user) => <Tooltip title={user.email}>{user.email}</Tooltip>,
        },
        {
            title: 'SDT',
            dataIndex: 'user',
            textWrap: 'word-break',
            ellipsis: true,
            align: 'center',
            render: (user) => <Tooltip title={user.phone}>{user.phone}</Tooltip>,
        },
        {
            title: 'Ngày yêu cầu',
            dataIndex: 'date',
            textWrap: 'word-break',
            ellipsis: true,
            align: 'center',
            render: (date) => <Tooltip title={moment(date).format('DD/MM/YYYY HH:mm:ss')}>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</Tooltip>,
        },
        {
            title: "Số tiền",
            align: 'center',
            dataIndex: 'amount',
            render: (amount) => amount.toLocaleString(),
        },
        {
            title: "Trạng thái",
            dataIndex: 'status',
            render: (status) => <span>
                {status === USER_AFFILIATE_TRANSACTION_STATUS.PENDING &&
                    <Tag color="gold">Chờ xử lý</Tag>
                }

                {status === USER_AFFILIATE_TRANSACTION_STATUS.SUCCESS &&
                    <Tag color="green">Đã thực hiện</Tag>
                }

                {status === USER_AFFILIATE_TRANSACTION_STATUS.FAILED &&
                    <Tag color="red">Hủy</Tag>
                }
            </span>
        },
        {
            title: "Mô tả",
            dataIndex: 'description',
            textWrap: 'word-break',
        },
        {
            title: "Ghi chú",
            dataIndex: 'note',
            textWrap: 'word-break',
        },
        {
            title: 'Action',
            width: 300,
            render: (trans) => {
                return <Space>
                    <Popconfirm
                        title={`Bạn có chắc muốn chuyển trạng thái chờ xử lý ?`}
                        onConfirm={() => revertWithdrawTransaction(trans.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button>Revert</Button>
                    </Popconfirm>
                    <Popconfirm
                        title={`Bạn có chắc muốn chuyển trạng thái đã thực hiện ?`}
                        onConfirm={() => approveWithdrawTransaction(trans.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button type="primary">Đã thực hiện</Button>
                    </Popconfirm>

                    <Button type="primary" danger onClick={() => {
                        showModal();
                        setCancelTransId(trans.id);
                    }}>Hủy</Button>
                </Space>
            }
        }
    ];

    return (
        <>
            <MyBreadcrumd items={[{ label: 'Cộng tác viên' }, { label: 'Yêu cầu rút tiền' }]} />
            <div className="mt-3">
                <Space>
                    <Select defaultValue="" onChange={(val: any) => setStatusFilter(val)} style={{ width: 150 }}>
                        <Option value=''>Chọn trạng thái</Option>
                        <Option value={USER_AFFILIATE_TRANSACTION_STATUS.PENDING}>Chờ xử lý</Option>
                        <Option value={USER_AFFILIATE_TRANSACTION_STATUS.SUCCESS}>Đã thực hiện</Option>
                        <Option value={USER_AFFILIATE_TRANSACTION_STATUS.FAILED}>Hủy</Option>
                    </Select>

                    <Button type="primary" onClick={btnSearchOnClick}>Tìm kiếm</Button>
                </Space>
            </div>
            <div className="mt-2">
                <Table
                    columns={columns}
                    dataSource={listTransaction}
                    rowKey={(r: any) => r?.id}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                />
            </div>

            <Modal title="Hủy lệnh rút tiền" visible={isModalOpen} onOk={() => rejectWithdrawTransaction()} onCancel={handleCancel} destroyOnClose>
                <TextArea placeholder="Nội dung ghi chú" onChange={(e) => setCancelNote(e.target.value)} />
            </Modal>
        </>
    )
}

const WithDrawListPage = () => {
    return <AdminPermission roleName={['validator']}>
        <WithDrawListPageComponent />
    </AdminPermission>
}

export default WithDrawListPage;