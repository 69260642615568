import React, { useState, useRef } from "react";
import { Card } from "antd";
import { useEffectOnce, useUnmount } from 'react-use';
import { ComposedChart, Line, YAxis, XAxis, CartesianGrid, Legend, Tooltip } from "recharts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { roundedNumber } from "../../../../../../../services/utils";
import theme from "../../../../../../../themes/theme";
import Skeleton from "../../../../../../../components/Skeleton";
import * as StockMarketOverviewService from "../services/index";
import * as config from "../../../../../../../config";
import animateGlobalState from '../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

type LiquidityChartProps = {
    width: number,
    height: number,
    indexID: string,
    exchange: string,
    position?: boolean, //true: bottom
};

const RATIO = 1000 * 1000 * 1000;
const LiquidityChart = (props: LiquidityChartProps) => {
    const { width, height, indexID, position } = props;
    const [ last5daysData, setLast5daysData ] = useState<NSApp.MainIndexInfoPerMin[]>([]);
    const [ yesterdayData, setYesterdayData ] = useState<NSApp.MainIndexInfoPerMin[]>([]);
    const [ currentData, setCurrentData ] = useState<NSApp.MainIndexInfoPerMin[]>([]);
    const [loading, setLoading] = useState(false);
    const dataIntervalRef = useRef<any>(null);
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();
    const { t } = useTranslation();

    const itemHeight = height && height > 150 ? height + 10 : 160;

    const fetchMainIndexDataAverage = async () => {
        let last5SessionsData = await StockMarketOverviewService.fetchMainIndexDataAverage(indexID);

        last5SessionsData = last5SessionsData.map(v => {
            return {
                ...v,
                totalValue: v.totalValue / RATIO,
            }
        });
        setLast5daysData(last5SessionsData);
    };

    const fetchMainInDexDataPrevSession = async () => {
        let prevSessionData = await StockMarketOverviewService.fetchMainInDexDataPrevSession(indexID);

        prevSessionData = prevSessionData.map(v => {
            return {
                ...v,
                totalValue: v.totalValue / RATIO,
                time: moment(v.time).valueOf()
            }
        });
        setYesterdayData(prevSessionData);
    };

    const fetchMainInDexDataPerMin = async () => {
        let currentSessionData = await StockMarketOverviewService.fetchMainInDexDataPerMin(indexID);

        currentSessionData = currentSessionData.map(v => {
            return {
                ...v,
                totalValue: v.totalValue / RATIO,
                time: moment(v.time).valueOf()
            }
        });
        setCurrentData(currentSessionData);
    };

    const fetchDataPerMinInterval = async () => {
        if (dataIntervalRef.current) {
            clearInterval(dataIntervalRef.current);
        }

        dataIntervalRef.current = setInterval(async () => {
            fetchMainInDexDataPerMin();
        }, 60 * 1000);
    }

    const fetchData = async () => {
        setLoading(true);
        await Promise.all([fetchMainIndexDataAverage(), fetchMainInDexDataPrevSession(), fetchMainInDexDataPerMin()]);
        setLoading(false);
    }

    useEffectOnce(() => {
        fetchData();
        fetchDataPerMinInterval();
    });

    useUnmount(() => {
        if (dataIntervalRef.current) {
            clearInterval(dataIntervalRef.current);
            dataIntervalRef.current = undefined;
        }
    });

    const generateTimelineTicks = (startTime) => {
        let tickValuesInit = new Array(7).fill(1);

        return tickValuesInit.map((tick, index) => {
            if (index === 0) {
                return startTime;
            }
            return moment(startTime).add(index, "hours").valueOf();
        });
    };

    // exchange stock trade time from 9:00 to 15:00 GMT +0
    // iboard data tra ve record time +7 tieng so vs thoi diem hien tai
    // const startTime = todayData[0]?.time;
    // const endTime = moment(startTime).add(6, "hours").valueOf();

    // if (!loading) {
    //     return <div style={{height: itemHeight, width: '100%'}}><Skeleton /></div>
    // }

    // if (!yesterdayData.length || !last5daysData.length) {
    //     return <div className="flex justify-center items-center text-gray" style={{height: itemHeight}}>Không có dữ liệu</div>
    // }

    const yesterdayStartTime = yesterdayData.length ? yesterdayData[0].time : moment().valueOf();
    const yesterdayEndTime = moment(yesterdayStartTime).add(6, "hours").valueOf();

    const todayStartTime = currentData.length ? currentData[0].time : moment().valueOf();
    const todayEndTime = moment(todayStartTime).add(6, "hours").valueOf();

    const startTime = moment().set({hour: 2}).startOf('hour');
    const ticks = generateTimelineTicks(startTime);

    return (
        <Card
            size="small"
            bodyStyle={{
                padding: 0,
                backgroundColor: theme.colors.chartBackground[themeMode]
            }}
        >
            {loading
                ? <div className="p-1" style={{height: itemHeight}}><Skeleton /></div>
                : <>
                    {(!yesterdayData.length || !last5daysData.length)
                        ? <div className="flex justify-center items-center text-gray" style={{height: itemHeight}}>{t('Không có dữ liệu')}</div>
                        : <>
                            <ComposedChart
                                key={props.indexID}
                                width={width}
                                height={itemHeight}
                                margin={{top: 20, right: 15, left: 0, bottom: 5}}
                                data={last5daysData}
                            >
                                <XAxis
                                    hide
                                    xAxisId="last5days"
                                    dataKey="hour_minute"
                                    type="category"
                                    domain={["auto", "auto"]}
                                    interval={"preserveStartEnd"}
                                    ticks={ticks}
                                    tickFormatter={(tickValue) => `${moment(tickValue).format("HH:mm")}`}
                                />

                                <XAxis
                                    xAxisId="yesterday"
                                    dataKey="time"
                                    type="number"
                                    interval={"preserveStartEnd"}
                                    domain={[yesterdayStartTime, yesterdayEndTime]}
                                    tick={{fontSize: 11}}
                                    ticks={generateTimelineTicks(yesterdayStartTime)}
                                    tickLine={false}
                                    tickFormatter={(tickValue) => `${moment(tickValue).format("HH")}:00`}
                                    stroke={theme.colors.chartAxis[themeMode]}
                                    strokeWidth={0.5}
                                    height={20}
                                />

                                <XAxis
                                    hide
                                    xAxisId="today"
                                    dataKey="time"
                                    type="number"
                                    interval={"preserveStartEnd"}
                                    domain={[todayStartTime, todayEndTime]}
                                    ticks={generateTimelineTicks(todayStartTime)}
                                />

                                <YAxis
                                    tickFormatter={(tickValue) => tickValue.toLocaleString(config.LOCALE_NUMBER_FORMAT)}
                                    tick={{fontSize: 11}}
                                    tickLine={false}
                                    stroke={theme.colors.chartAxis[themeMode]}
                                    strokeWidth={0.5}
                                />

                                <CartesianGrid
                                    vertical={false}
                                    stroke={theme.colors.gray.background}
                                    strokeWidth={0.05}
                                />

                                <Legend wrapperStyle={{fontSize: theme.fontSizes.tiny}}/>

                                <Tooltip
                                    cursor={false}
                                    itemStyle={{padding: 0}}
                                    labelStyle={{ color: 'black', fontWeight: 'bold' }}
                                    labelFormatter={(label) => moment(label, 'HH:mm').add(7, 'hours').format("H:mm")}
                                    formatter={(value) => roundedNumber(value, 2)}
                                    position={{y: position ? height : -100}}
                                    wrapperStyle={{zIndex: 1}}
                                />

                                <Line
                                    data={last5daysData}
                                    isAnimationActive={animate}
                                    xAxisId="last5days"
                                    name={t('TB 5 ngày trước')}
                                    connectNulls
                                    type="monotone"
                                    dataKey="totalValue"
                                    stroke={theme.colors.orange.background}
                                    strokeWidth={1.5}
                                    dot={false}
                                    unit={` ${t('Tỷ')}`}
                                />

                                <Line
                                    data={yesterdayData}
                                    isAnimationActive={animate}
                                    xAxisId="yesterday"
                                    name={t('Hôm qua')}
                                    connectNulls
                                    type="monotone"
                                    dataKey="totalValue"
                                    stroke={theme.colors.green.background}
                                    strokeWidth={1.5}
                                    dot={false}
                                    unit={` ${t('Tỷ')}`}
                                />

                                <Line
                                    data={currentData}
                                    isAnimationActive={animate}
                                    xAxisId="today"
                                    name={t('Hiện tại')}
                                    connectNulls
                                    type="monotone"
                                    dataKey="totalValue"
                                    stroke={theme.colors.purple.background}
                                    strokeWidth={1.5}
                                    dot={false}
                                    unit={` ${t('Tỷ')}`}
                                />
                            </ComposedChart>
                        </>
                    }
                </>
            }

            <div className="text-center mb-1">
                <span className={`text-${themeMode === 'dark' ? 'white' : 'black'} font-bold`}>{t('Thanh khoản')} ({t('Tỷ VNĐ')})</span>
            </div>
        </Card>
    )
};

export default LiquidityChart;