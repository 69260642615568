import React from 'react';
import { Row, Col } from 'antd';

import * as config from "../../../../../../config";
import { MAIN_INDEX } from './utils';
import MainIndexContainer from './container';
import UserACL from '../../../../../../components/UserACL';

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 80;

const MainIndex = (props) => {
    let { glContainer, width, height } = props;

    let layoutWidth = window.innerWidth - SIDER_WIDTH;
    let layoutHeight = window.innerHeight - HEADER_HEIGHT;

    if (glContainer) {
        layoutWidth = glContainer.width > 0 ? glContainer.width : layoutWidth;
        layoutHeight = glContainer.height > 0 ? glContainer.height : layoutHeight;
    }

    if (width > 0) {
        layoutWidth = width;
    }

    if (height > 0) {
        layoutHeight = height;
    }

    let column = 1;
    switch (true) {
        case (0 < layoutWidth && layoutWidth <= config.RESPONSIVE_SCREEN.sm):
            column = 1;
            break;
        case (config.RESPONSIVE_SCREEN.sm < layoutWidth && layoutWidth <= config.RESPONSIVE_SCREEN.lg):
            column = 2;
            break;
        case (layoutWidth > config.RESPONSIVE_SCREEN.lg):
            column = 3;
            break;
    }

    let chartItemColSpan = 24 / column;
    let chartWidth = ((layoutWidth - 12) / column) - 22;
    let chartHeight = layoutWidth > config.RESPONSIVE_SCREEN.lg ? ((layoutHeight - 18) / 2) - 55 : chartWidth * 9 / 16;


    return (
        <UserACL feature="main_index">
            <Row className="overflow-y-auto p-1" gutter={[4, 4]} style={{height: layoutHeight}}>
                {MAIN_INDEX.map(mainIndex => {
                    return <Col key={mainIndex.indexId} span={chartItemColSpan}>
                            <MainIndexContainer
                                indexLabel={mainIndex.label}
                                indexId={mainIndex.indexId}
                                width={chartWidth}
                                height={chartHeight}
                            />
                    </Col>
                })}
            </Row>
        </UserACL>
    )
}

export default MainIndex;
