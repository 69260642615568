import React, { useState } from 'react';
import { Card, Col, Row, Table, Tag, Typography } from 'antd';
import { useTranslation } from "react-i18next";

import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useReducedState } from '../../../../../services/utils';
import * as config from "../../../../../config";
import api from "../../../../../services/api";
import { useEffectOnce } from 'react-use';

const { Title } = Typography;

const InvitedAccount = (props: {userId: string}) => {
    const { userId } = props
    const [commission, setCommision] = useState<NSApp.UserAffiliateTransaction[]>([])
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });

    const fetchCommissionHistory = async () => {
        const [error, response]: any = await api.get(`/affiliate/transactions-user/`,{
            type: 'commission',
            page: pagination.current,
            limit: pagination.pageSize,
            userId: userId
        });

        if (error) {
            return;
        }
        setCommision(response.data.transactions);
    }

    const columns: ColumnsType<NSApp.UserAffiliateTransaction> = [
        {
            title: 'Ngày',
            dataIndex: 'createdAt',
            align: 'center',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
            title: 'Nội dung',
            align: 'center',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Hoa hồng sau thuế TNCN',
            key: 'amount',
            dataIndex: 'amount',
            align: 'center',
            render: (amount) => amount.toLocaleString(),
        },
    ];

    useEffectOnce(() => {
        fetchCommissionHistory()
    })
    return(
        <div>
            <Title level={3}>{'Người được mời'}</Title>
            <Table
                columns={columns} dataSource={commission} bordered
                pagination={{
                    onChange: page => {
                        setPagination({ current: page });
                    },
                    ...pagination
                }}
            />
        </div>
    )
}

export default InvitedAccount