import React from "react";
import { Layout } from "antd";

import AdminDashboard from "./Dashboard";
import DashboardManager from "./DashboardManager";
import NewsPage from "./News";
import RecommendationPage from "./Recommendation";
import ArticlePage from "./Article";
import UserPage from "./User";
import UserRolePage from "./UserRole";
import Notification from "./Notification";
import QueueJobsManager from "./QueueJobs";
import Plan from "./Plan";
import Feature from "./Feature";
import UploadData from "./UploadData";
import Payment from "./Payment";
import Affiliate from "./Affiliate";

const { Content } = Layout;

const PrivateRouteContent = () => {
    return (
        <Content className="home-layout-background home-content relative">
            <NewsPage />
            <ArticlePage />
            <RecommendationPage />
            <UserPage />
            <UserRolePage />
            <AdminDashboard />
            <DashboardManager />
            <Notification />
            <QueueJobsManager />
            <Plan />
            <Feature />
            <UploadData />
            <Payment />
            <Affiliate />
        </Content>
    );
};

export default PrivateRouteContent;
