import React, { useState } from "react";
import { Button, message, Select } from "antd";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import _ from "lodash";

import Loading from "../../../components/Loading";
import api from "../../../services/api";
import { roundedNumber, toRoute } from "../../../services/utils";
import routes from "../../../routes";

type SelectPeriodProps = {
    planId : string;
}

const BuyButton = ({ planId, period, price }) => {
    const { t } = useTranslation();

    return (
        <Link to={toRoute(routes.UPGRADE_PAYMENT_INFO, { planId, period, price })}>
            <Button block type="primary" className="w-5/6 h-10 px-4 py-2 text-sm font-bold">{t('MUA')}</Button>
        </Link>
    )
}

const SelectPeriod = (props: SelectPeriodProps) => {
    const [periodPrice, setPeriodPrice]: any = useState();
    const [price, setPrice]: any  = useState();
    const [truePrice, setTruePrice]: any  = useState();
    const [period, setPeriod]: any = useState();
    const [promoPercent, setPromoPercent]: any = useState();
    const { t } = useTranslation();

    const fetchPlan = async () => {
        const [err, resp]: any = await api.get(`/plan/${props.planId}`);
        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        const _data = _.sortBy(resp.data.plan_prices, 'price');
        setPeriodPrice(_data);
    }

    useEffectOnce(() => {
        fetchPlan();
    })

    if (!periodPrice) {
        return <Loading />
    }

    if (!price) {
        if (!periodPrice.length) {
            return <span>{t('Chưa có thông tin')}</span>
        }
        setPrice(periodPrice[0].price);
        setPeriod(periodPrice[0].period);
    }

    const handlePeriodChange = (value: any) => {
        const infoPlan = _.find(periodPrice, p => p.period === value)
        const price = periodPrice[0].price*infoPlan.period
        const promoPercent = roundedNumber(100-infoPlan.price/price*100,0)
        if(infoPlan === periodPrice[0]){
            setTruePrice()
            setPromoPercent()
        } else {
            setTruePrice(price)
            setPromoPercent(promoPercent)
        }
        setPrice(infoPlan.price);
        setPeriod(infoPlan.period);
    }

    const optionPeriod = _.map(periodPrice, p => ({
        label: `${p.period} ${t('tháng')}`,
        value: p.period
    }))

    return <div className="py-2">
        <Select
            size="middle"
            className="light-theme"
            style={{width: 100}}
            defaultValue={periodPrice[0].period}
            onChange={handlePeriodChange}
            dropdownStyle={{backgroundColor: 'white'}}
        >
            {optionPeriod.map(option => {
                return <Select.Option className="text-black" key={option.value} value={option.value}>{option.label}</Select.Option>
            })}
        </Select>
        <div className="mt-3">
            <span className="text-base line-through">{truePrice?.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}</span>
            <span className="text-sm text-primary">{promoPercent ? ` (-${promoPercent?.toString()}%)` : null}</span>
        </div>
        <div className="text-2xl font-bold mb-3">{price?.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}</div>
        <BuyButton planId={props.planId} period={period} price={price} />
    </div>
}

export default SelectPeriod;