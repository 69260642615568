import { useEffect } from 'react';
import { useForeignerTradingState } from '../store';
import { useReducedState } from "../../../../../../../services/utils";
import * as ForeignerTradingServices from '../services';
import * as MainIndexServices from '../../MainIndex/services';

export const useForeignerTradingClient = () => {
    const useForeignerTradingData = useForeignerTradingState();
    const [ foreignerTradingState, setForeignerTradingState ] = useReducedState(useForeignerTradingData);

    useEffect(() => {
        //initIndiceData();
        //initForeignerTradingData();
    }, []); // eslint-disable-line

    // const initIndiceData = async () => {
    //     const indice = await fetchIndiceData('hose');
    //     const foreignerTradingByTime = indice[indice.length-1];
    //     setForeignerTradingState({ indice, foreignerTradingByTime });
    // };

    // const initForeignerTradingData = async () => {
    //     const stocks = await fetchForeignerTradingData('hose', 'value');
    //     setForeignerTradingState({ stocks });
    // };

    const fetchTotalForeignValue = async () => {
        const data = await MainIndexServices.fetchMainIndexForeignValue();
        return data;
    }

    const fetchForeignerTopStocks = async (exchange, orderBy) => {
        let stocks = await ForeignerTradingServices.fetchForeignTradingTopStocks(exchange, orderBy);
        setForeignerTradingState({ stocks });
        return stocks;
    };

    const fetchExchangeData = async (exchange) => {
        let indexID;
        if (exchange === 'hnx') {
            indexID = 'hnxindex'
        } else if (exchange === 'upcom') {
            indexID = 'upcomindex'
        } else {
            indexID = 'vnindex'
        }
        let indice = await MainIndexServices.fetchMainIndex(indexID);
        let {data} = indice;
        return data
    };

    const fetchForeignerTradingByTime = async (stockCode) => {
        let foreignerTradingByTime = await ForeignerTradingServices.fetchForeignerTradingByTime(stockCode);
        setForeignerTradingState({ foreignerTradingByTime });
        return foreignerTradingByTime;
    };

    const fetchIndiceDataByTime = async (exchange) => {
        let indexID;
        if (exchange === 'hnx') {
            indexID = 'hnxindex'
        } else if (exchange === 'upcom') {
            indexID = 'upcomindex'
        } else {
            indexID = 'vnindex'
        }
        let foreignDataByTime = await ForeignerTradingServices.fetchIndiceDataByTime(indexID);
        return foreignDataByTime;
    }

    return {
        foreignerTradingState,
        setForeignerTradingState,
        fetchForeignerTopStocks,
        fetchExchangeData,
        fetchForeignerTradingByTime,
        fetchIndiceDataByTime,
        fetchTotalForeignValue
    }
}
