import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, message } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useWatchlistClient } from '../client';
import Watchlist from '../components/Watchlist';
import $ from "jquery";
import NewsList from "../../Dashboard/plugins/News/container";
import NewsComposite from "../../Dashboard/plugins/News/container/composite";
import { useAuthState } from "../../../../../contexts";
import localStorage from "../../../../../services/local-storage";

const WatchlistContainer = () => {
    const { authState, setAuthState } = useAuthState() as any;

    const parentComponentHeight = $(window).height() - 93;

    const INIT_TABLE_COMPONENT = {
        height: parentComponentHeight/5*3,
        display: "block"
    };

    const INIT_NEWS_COMPONENT = {
        height: parentComponentHeight/5*2,
        display: "flex"
    };

    const INIT_CONTROL_BUTTON = {
        top: parentComponentHeight/5*3 - 20,
        position: "center"
    };

    const [ activeWatchList, setActiveWatchList ] = useState<NSApp.Watchlist>();
    const [ watchListModalVisible, setWatchListModalVisible ] = useState(false);
    const [ controlButton, setControlButton ] = useState(INIT_CONTROL_BUTTON);
    const [ tableComponent , setTableComponent ] = useState(INIT_TABLE_COMPONENT);
    const [ newsComponent , setNewsComponent ] = useState(INIT_NEWS_COMPONENT);

    const { t } = useTranslation();
    const watchlistClient = useWatchlistClient();
    const { watchlists } = watchlistClient.watchListState;

    const calculateComponentHeight = (parentHeight) => {
        if (controlButton.position === "top") {
            setTableComponent({
                height: 0,
                display: tableComponent.display
            });
            setNewsComponent({
                height: parentHeight,
                display: newsComponent.display
            });
            setControlButton({ position: "top", top: 0});
        }
        if (controlButton.position === "center") {
            setTableComponent({
                height: parentHeight/5*3,
                display: tableComponent.display
            });
            setNewsComponent({
                height: parentHeight/5*2,
                display: newsComponent.display
            });
            setControlButton({
                position: "center",
                top: parentComponentHeight/5*3 - 20
            });
        }
        if (controlButton.position === "bottom") {
            setTableComponent({
                height: parentHeight,
                display: tableComponent.display
            });
            setNewsComponent({
                height: 0,
                display: newsComponent.display
            });
            setControlButton({
                position: "bottom",
                top: parentComponentHeight - 50
            });
        }
    };

    useEffect( () => {
        async function fetchInitialWatchList() {
            if (watchlists.length > 0 && !activeWatchList) {
                if (authState.selectedWatchlist) {
                    setActiveWatchList(authState.selectedWatchlist);
                } else {
                    setActiveWatchList(watchlists[0]);
                }
            }
        }
        fetchInitialWatchList();
    }, [watchlists]); // eslint-disable-line

    useEffect( () => {
        calculateComponentHeight(parentComponentHeight);
    }, [parentComponentHeight]) // eslint-disable-line

    const addWatchList = async (name: string) => {
        let [err, resp]: any = await watchlistClient.addWatchList(name);

        if (err) {
            message.error(err);
            return;
        }

        setActiveWatchList(resp);
        message.success(<span>{t('Đã tạo danh mục')} <strong>{name}</strong></span>);
    };

    const updateWatchList = async (newName: string) => {
        if (!activeWatchList) return;

        // eslint-disable-next-line
        let [err, resp]: any = await watchlistClient.updateWatchList(activeWatchList, newName);

        if (err) {
            message.error(err);
            return;
        }

        setWatchListModalVisible(false);
        message.success(<span>{t('Chỉnh sửa danh mục thành công')}</span>);
    };

    const deleteWatchList = async (id: string) => {
        let [err, resp]: any = await watchlistClient.deleteWatchList(id);

        if (err) {
            message.error(err);
            return;
        }

        // let newWatchlist = await fetchWatchList();
        // setActiveWatchList(newWatchlist[0]);

        if (id === authState.selectedWatchlist.id) {
            await localStorage.set('selectedWatchlist', watchlists[0]);
            setAuthState({ selectedWatchlist: watchlists[0] });
        }
        if (watchlists.length) {
            setActiveWatchList(watchlists[0]);
        }
        message.success(<span>{t('Đã xoá danh mục')} <strong>{resp?.name}</strong></span>);
    };

    const removeStockFromWatchList = async (stockCode: string) => {
        if (!activeWatchList) return;

        // eslint-disable-next-line
        let [err, resp]: any = await watchlistClient.updateStockWatchList(activeWatchList, stockCode, 'remove');

        if (err) {
            message.error(err);
            return;
        }

        setActiveWatchList(resp);
        message.success(<span>{t('Đã xoá mã')} <strong>{stockCode.toUpperCase()}</strong> {t('khỏi danh mục')} <strong>{activeWatchList.name}</strong>!</span>);
    };

    const addStockToWatchList = async (stockCode: string) => {
        if (!activeWatchList) return;

        // eslint-disable-next-line
        let [err, resp]: any = await watchlistClient.updateStockWatchList(activeWatchList, stockCode, 'add');

        if (err) {
            message.error(err);
            return;
        }

        setActiveWatchList(resp);
        message.success(<span>{t('Đã thêm mã')} <strong>{stockCode.toUpperCase()}</strong> {t('vào danh mục')} <strong>{activeWatchList.name}</strong>!</span>);
    };

    const changePosition = (action: string) => {
        let unChange = action === "up" ?  controlButton.position === "top" : controlButton.position === "bottom";
        let changeToCenter = action === "up" ? controlButton.position === "bottom" : controlButton.position === "top";

        if (unChange) return;
        if (controlButton.position === "center") {
            if (action === "up") {
                setTableComponent({ height: 0, display: "none" });
                setNewsComponent({ height: parentComponentHeight, display: "flex" });
                setControlButton({ position: "top", top: 0});
            } else {
                setTableComponent({ height: parentComponentHeight, display: "block" });
                setNewsComponent({ height: 0, display: "none" });
                setControlButton({ position: "bottom", top: parentComponentHeight - 50 });
            };
        };
        if (changeToCenter) {
            setTableComponent({ height: parentComponentHeight/5*3, display: "block" });
            setNewsComponent({ height: parentComponentHeight/5*2, display: "flex"});
            setControlButton({ position: "center", top: parentComponentHeight/5*3 - 20});
        };
    };

    return (
        <Card className="h-full" bodyStyle={{ padding: 0 }} bordered={false}>
            <div
                className="table-component"
                style={{ height: tableComponent.height, display: tableComponent.display }}
            >
                <Watchlist
                    height={tableComponent.height + 2}
                    watchlists={watchlists}
                    activeWatchlist={activeWatchList}
                    watchListModalVisible={watchListModalVisible}
                    addWatchList={addWatchList}
                    updateWatchList={updateWatchList}
                    deleteWatchList={deleteWatchList}
                    removeStockFromWatchList={removeStockFromWatchList}
                    addStockWatchList={addStockToWatchList}
                    changeActiveList={setActiveWatchList}
                    changeWatchListModalVisible={setWatchListModalVisible}
                />
            </div>

            {watchlists.length > 0 && <>
                <div className="change-height-component-button" style={{top: controlButton.top }}>
                    <CaretUpOutlined onClick={() => changePosition("up")} />
                    <CaretDownOutlined onClick={() => changePosition("down")} />
                </div>
                <Row className="news-component" gutter={[4, 4]} style={{ height: newsComponent.height, display: newsComponent.display }}>
                    <Col xs={24} lg={12}>
                        <div className="news-tab-card" style={{ height: newsComponent.height - 4 }}>
                            <NewsComposite
                                categoryName={t('Báo cáo phân tích')}
                                stockCodes={activeWatchList?.stocks}
                                listHeight={newsComponent.height + 37}
                                listNoImage
                            />
                        </div>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="news-tab-card" style={{ height: newsComponent.height - 4 }}>
                            <Typography.Text className="text-xl" style={{ lineHeight: 1.5 }} strong>{t('Tin tức')}</Typography.Text>
                            <NewsList stockCodes={activeWatchList?.stocks} height={newsComponent.height + 36} listNoImage/>
                        </div>
                    </Col>
                </Row>
            </>}
        </Card>
    );
};

export default WatchlistContainer;