import React, { useState, useEffect } from "react";
import { Row, Col, List, Typography, Card, Modal, Space } from "antd";
import { CalendarOutlined, ClockCircleOutlined, ForwardOutlined } from "@ant-design/icons";
import moment from "moment";

import colors from "../../../../../../../themes/colors";
import * as config from '../../../../../../../config';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";

import Skeleton from "../../../../../../../components/Skeleton";
import CustomEmpty from "../../../../../../../components/CustomEmpty";
import DefaultThumbnail from "../../../../../../../assets/image/default/FStock_thumbnail.png";
import NewDetail from "./detail";
import NewComments from "./comments";

type NewsListProps = {
    newsList: NSApp.New[],
    latestNews: NSApp.New[],
    errorMessage: string,
    pagination: any,
    height: number,
    width?: number,
    category?: NSApp.NewsCategory,
    isExternal?: boolean,
    hasTitle?: boolean,
    listNoImage?: boolean,
    hasLatestNews?: boolean,
    horizontal?: boolean,
    column?: number,
    loading: boolean,
    changePagination: (pagination) => void,
    onChangeQueryFilter?: (filter) => void,
}

const NewsList = (props: NewsListProps) => {
    const {
        newsList, latestNews, errorMessage, pagination, category,
        hasTitle, hasLatestNews, height, width, horizontal, listNoImage, column, loading, isExternal,
        changePagination,
        // onChangeQueryFilter
    } = props;

    const [ newsListData, setNewsListData ] = useState<NSApp.New[]>([]);
    const [ newDetail, setNewDetail ] = useState<NSApp.New>();
    const [ isModalVisible, setIsModalVisible ] = useState(false);
    const [ firstLatestNews, setFirstLatestNews ] = useState<NSApp.New>();
    const [themeMode,] = themeModeGlobalState();

    useEffect(() => {
        setNewsListData(newsList);

        if (latestNews && latestNews.length) {
            setFirstLatestNews(latestNews.shift())
        }
    }, [newsList]); // eslint-disable-line

    const goToNewsDetail = (news: NSApp.New) => {
        if (isExternal) {
            window.open(news.link, "_blank")
            return;
        }
        setNewDetail(news);
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    }

    const onErrorThumbnail = (e) => {
        e.target.src = DefaultThumbnail;
    };

    // const onChangeSelectWatchListInput = (stocks: string[]) => {
    //     let stockCodeFilter = stocks.map(stockCode => {
    //         return stockCode.toLowerCase()
    //     });

    //     if (onChangeQueryFilter) {
    //         let queryFilter = !stockCodeFilter.length ? {} : {
    //             tags: {
    //                 $in: stockCodeFilter
    //             }
    //         };

    //         onChangeQueryFilter(queryFilter);
    //     }
    // };

    if (errorMessage) {
        return <div className="text-center p-2">
            <Typography.Text>{errorMessage}</Typography.Text>
        </div>
    }

    let cardProps: any = {};

    if (category || isExternal) {
        cardProps = {
            ...cardProps,
            title: <div className="flex justify-between items-center">
                {category && hasTitle &&
                    <Typography.Text className="text-xl leading-6" strong>{category.name}</Typography.Text>
                }

                {isExternal && hasTitle &&
                    <Typography.Text className="text-xl leading-6" strong>Tin tức khác</Typography.Text>
                }
            </div>
        };
    }

    const renderLatestNews = () => {
        return <Row className="my-1">
            <Col span={8}>
                <div
                    className="overflow-hidden relative"
                    style={{ height: width && (width > config.RESPONSIVE_SCREEN.xxl) ? 180 : 150 }}
                >
                    <img
                        className="rounded-sm w-full absolute m-auto inset--100"
                        // height={width && (width > config.RESPONSIVE_SCREEN.xxl) ? 230 : 130}
                        src={firstLatestNews?.thumbnail || ""}
                        alt={firstLatestNews?.title}
                        onError={onErrorThumbnail}
                    />
                </div>
                <div className="flex flex-col">
                    <div className="text-two-line">
                        <span className="cursor-pointer text-base font-medium hover:text-orange-500"
                            onClick={() => firstLatestNews && goToNewsDetail(firstLatestNews)}
                        >
                            {firstLatestNews?.title}
                        </span>
                    </div>
                    <Space className="mt-2 text-description">
                        {firstLatestNews?.sourceName &&
                            <span className="font-bold">{firstLatestNews?.sourceName}</span>
                        }
                        <span>{moment(firstLatestNews?.createdAt).fromNow()}</span>
                    </Space>
                </div>
            </Col>
            <Col span={16}>
                <List
                    size="small"
                    split={false}
                    dataSource={latestNews}
                    grid={{ column: 2 }}
                    renderItem={(item: any) => renderNew(item, height/6+10, true)}
                />
            </Col>
        </Row>
    }

    const renderNew = (item: NSApp.New, imageWidth?: number, isLatestNew?: boolean, noImage?: boolean) => {
        const width = imageWidth ? imageWidth : 125;
        const height = width * 9 / 16;

        return <List.Item key={item.id} className="py-1">
            <List.Item.Meta
                avatar={(listNoImage || noImage) ? <></> :
                    <div
                        className="overflow-hidden relative"
                        style={{ height: height }}
                    >
                        <img
                            className="rounded-sm"
                            width={width}
                            // height={height}
                            src={item.thumbnail || ""}
                            alt={item.title}
                            onError={onErrorThumbnail}
                            style={{ width: width }}
                        />
                    </div>
                }
                title={
                    <div className="text-two-line">
                        <span className="cursor-pointer hover:text-orange-500"
                            onClick={() => goToNewsDetail(item)}>
                            {item?.title}
                        </span>
                    </div>
                }
                description={
                    <Space>
                        <span className="font-bold">{item?.sourceName}</span>
                        {isLatestNew ?
                            <span>{moment(item?.createdAt).fromNow()}</span>
                            :
                            <>
                                <span><CalendarOutlined/> {moment(item?.createdAt).format("L")}</span>
                                <span><ClockCircleOutlined/> {moment(item?.createdAt).format("LT")}</span>
                            </>
                        }
                    </Space>
                }
            />
        </List.Item>
    };

    let latestNewsColSpan = 24;
    if (width && width > config.RESPONSIVE_SCREEN.md) latestNewsColSpan = 12;
    if (width && width > config.RESPONSIVE_SCREEN.lg) latestNewsColSpan = 8;
    let newsListColSpan = (width && width > config.RESPONSIVE_SCREEN.lg) ? 8 : 24;

    if (loading) {
        return (
            <Card
                bordered={false}
                size="small"
                headStyle={{
                    padding: "0 0.25rem",
                    backgroundColor: colors.customTabBackground[themeMode],
                }}
                bodyStyle={{ padding: 4, height: height - 77, overflow: 'auto' }}
                {...cardProps}
            >
                <Skeleton column={column}/>
            </Card>
        )
    }

    return <>
        <Card
            bordered={false}
            size="small"
            headStyle={{
                padding: "0 0.25rem",
                backgroundColor: colors.customTabBackground[themeMode],
            }}
            bodyStyle={{ padding: 4, height: height - 77, overflow: 'auto' }}
            {...cardProps}
        >
            {newsListData.length ? <>
                {horizontal ?
                    <Row gutter={8}>
                        <Col span={latestNewsColSpan}>
                            <div
                                className="overflow-hidden mt-1 relative"
                                style={{ height: 165 }}
                            >
                                <img
                                    className="rounded-sm w-full absolute m-auto inset--100"
                                    // height={height/2-20}
                                    src={firstLatestNews?.thumbnail || ""}
                                    alt={firstLatestNews?.title}
                                    onError={onErrorThumbnail}
                                />
                            </div>
                            <div className="flex flex-col">
                                <div className="text-two-line">
                                    <span className="cursor-pointer text-base font-medium hover:text-orange-500"
                                        onClick={() => firstLatestNews && goToNewsDetail(firstLatestNews)}
                                    >
                                        {firstLatestNews?.title}
                                    </span>
                                </div>
                                <Space className="mt-2 text-description">
                                    {firstLatestNews?.sourceName && <span className="font-bold">{firstLatestNews?.sourceName}</span>}
                                    <span>{moment(firstLatestNews?.createdAt).fromNow()}</span>
                                </Space>
                            </div>
                        </Col>
                        <Col span={latestNewsColSpan}>
                            {latestNews.map((item, index) => {
                                if (index < 3) {
                                    return <div key={item.id}>{renderNew(item, 140, true)}</div>
                                }
                                return <div key={`"empty"+${index}`}></div>;
                            })}
                        </Col>
                        <Col span={newsListColSpan}>
                            <List
                                size="small"
                                split={false}
                                dataSource={newsListData.slice(0, 5)}
                                renderItem={(item: any) => (
                                    <List.Item key={item._id}>
                                        <List.Item.Meta
                                            title={<div className="text-one-line">
                                                    <span className="cursor-pointer hover:text-orange-500"
                                                        onClick={() => goToNewsDetail(item)}>
                                                        <ForwardOutlined /> {item?.title}
                                                    </span>
                                                </div>
                                            }
                                            description={<Space>
                                                {item?.sourceName && <span className="font-bold">{item.sourceName}</span>}
                                                <span><CalendarOutlined/> {moment(item?.createdAt).format("L")}</span>
                                                <span><ClockCircleOutlined/> {moment(item?.createdAt).format("LT")}</span>
                                            </Space>}
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                    :
                    <>
                        {hasLatestNews && renderLatestNews()}
                        <List
                            className={column === 3 ? "mt-4 -ml-2" : ""}
                            dataSource={newsListData}
                            grid={{ gutter: 8, column: column || 1 }}
                            renderItem={item => renderNew(item, 100, false, true)}
                            pagination={newsListData.length ?
                                {
                                    onChange: (page) => {
                                        changePagination({ current: page });
                                    },
                                    pageSize: config.PAGE_SIZE,
                                    total: pagination.total,
                                } : false
                            }
                        />
                    </>
                }
            </> : <CustomEmpty/>}
        </Card>

        <Modal
            visible={isModalVisible}
            onCancel={hideModal}
            footer={null}
            width={992}
            style={{ top: 50 }}
        >
            {newDetail && (
                <>
                    <NewDetail newDetail={newDetail} />
                    <NewComments newId={newDetail.id} />
                </>
            )}
        </Modal>
    </>;
};

export default NewsList;