import React, { useState } from "react";
import { Card } from "antd";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { useParams } from "react-router-dom";

import Dashboard from "./Dashboard";
import Profile from "./Profile";

import api from "../../../../services/api";
import routes from "../../../../routes";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";

const UserDetailAffiliate = () => {
    const [tabKey , setUserTabKey ] = useState<string>("profile");
    const { userId } = useParams<any>()
    const [userAffiliate, setUserAffiliateProfile] = useState<NSApp.UserAffiliate>();

    const { t } = useTranslation();

    const fetchAffiliateProfile = async () => {
        const [error, response]: any = await api.get(`/affiliate/user/${userId}`);

        if (error) {
            return;
        }

        setUserAffiliateProfile(response.data);
    };

    useEffectOnce(() => {
        fetchAffiliateProfile();
    });

    const tabList = [
        {
            key: "profile",
            tab: t('Hồ sơ'),
        },
        {
            key: "dashboard",
            tab: t('Hoa hồng'),
        },
    ];

    const contentList = {
        profile: userAffiliate ? <Profile affiliate={userAffiliate}/> : <Loading />,
        dashboard: userAffiliate ? <Dashboard userId={userId} affiliate={userAffiliate}/> : <Loading />,
    };

    const onTabChange = async (key) => {
        setUserTabKey(key);
    };

    if (!userAffiliate) {
        return <Loading/>
    }

    return (
        <Card
            className="h-full"
            bodyStyle={{ overflow: 'scroll' }}
            tabList={tabList}
            activeTabKey={tabKey}
            onTabChange={(key) => onTabChange(key)}
        >
            <MyBreadcrumd items={[
                { label: 'Cộng tác viên' , path: routes.ADMIN_MANAGE_AFFILIATE_LIST },
                { label: userAffiliate.name },
            ]} />
            {contentList[tabKey]}
        </Card>
    );
};

export default UserDetailAffiliate;