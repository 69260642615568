import React, { useEffect, useState, useRef, useCallback } from "react";
import { useUnmount } from 'react-use';
import { useTranslation } from "react-i18next";
import { HeatMapType } from "../../../../../../types/plugins/heatMap";
import api from "../../../../../../services/api";
import Empty from '../../../../../../components/CustomEmpty';

import HeatMap from './components/Heatmap';
import { localeStringTwoDigits, getStockColor } from "../../../../../../services/utils";

const SIDER_WIDTH = 210;
const HEADER_HEIGHT = 80;

const HeatMapContainer = (props) => {
    const { glContainer } = props;
    const [ exchange, setExchange ] = useState<string>('all');
    const [ heatMapType, setHeatMapType ] = useState<string>('totalTradeValue');
    const [ heatMapData, setHeatMapData ] = useState<HeatMapType[]>([]);
    const [ loading, setLoading ] = useState<boolean>(true);

    const { t } = useTranslation();
    const heatMapIntervalRef = useRef<any>(null);

    let heatMapTitle = "";
    let heatMapDataKey = "";

    switch (heatMapType) {
        case 'totalTradeValue':
            heatMapTitle = t('Giá trị');
            heatMapDataKey = 'totalValue';
            break;
        case "totalTrade":
            heatMapTitle = t('Khối lượng');
            heatMapDataKey = "totalVolume";
            break;
        case "capitalStock":
            heatMapTitle = t('Vốn hoá');
            heatMapDataKey = "marketCapital";
            break;
        case "buyForeignValue":
            heatMapTitle = t('GTNN Mua');
            heatMapDataKey = "foreignBuyValue";
            break;
        case "sellForeignValue":
            heatMapTitle = t('GTNN Bán');
            heatMapDataKey = "foreignSellValue";
            break;
        case "buyForeignQtty":
            heatMapTitle = t('KLNN Mua');
            heatMapDataKey = "foreignBuyVolume";
            break;
        case "sellForeignQtty":
            heatMapTitle = t('KLNN Bán');
            heatMapDataKey = "foreignSellVolume";
            break;
    }

    const formatHeatMapData = (heatMapData: any) => {
        for (let industry of heatMapData) {
            industry.name = industry.children[0].industryName;
            industry.industryCode = industry.children[0].industryCode;

            for (let stock of industry.children) {
                stock.name = stock.stockCode.toUpperCase();
                const change =
                    stock.pricePercentChange !== null
                        ? `${
                              stock.pricePercentChange > 0 ? '+' : ''
                          }${localeStringTwoDigits(stock.pricePercentChange)}%`
                        : '';

                stock.value = [stock[heatMapDataKey], change, getStockColor(stock)];
            }
        }
        return heatMapData;
    };

    const fetchHeatMap = useCallback(async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/heat-map`, {
            type: heatMapType,
            exchange: exchange,
        });

        const _heatmapData = formatHeatMapData(resp?.data?.heatMap);
        setHeatMapData(_heatmapData);
        setLoading(false);
    }, [exchange, heatMapType]) // eslint-disable-line

    const fetchHeatmapDataInterval = useCallback(async () => {
        if (heatMapIntervalRef.current) {
            clearInterval(heatMapIntervalRef.current);
        }

        heatMapIntervalRef.current = setInterval(async () => {
            await fetchHeatMap();
        }, 60 * 1000);
    }, [fetchHeatMap]);

    useEffect(() => {
        fetchHeatMap();
        fetchHeatmapDataInterval();
    }, [fetchHeatMap, fetchHeatmapDataInterval]);

    useUnmount(() => {
        if (heatMapIntervalRef.current) {
            clearInterval(heatMapIntervalRef.current);
            heatMapIntervalRef.current = undefined;
        }
    })

    const onChangeExChange = (key) => {
        setExchange(key);
    };

    const onChangeHeatMapType = (value) => {
        setLoading(true);
        setHeatMapType(value);
    };

    if (!heatMapData) {
        return <Empty/>
    }

    const chartWidth = glContainer.width > 0 ? glContainer.width : window.innerWidth - SIDER_WIDTH;
    const chartHeight = glContainer.height > 0 ? glContainer.height : window.innerHeight - HEADER_HEIGHT;

    return (
        <HeatMap
            width={chartWidth}
            height={chartHeight}
            heatMapType={heatMapType}
            heatMapData={heatMapData}
            heatMapTitle={heatMapTitle}
            heatMapDataKey={heatMapDataKey}
            loading={loading}
            changeExchange={onChangeExChange}
            changeHeatMapType={onChangeHeatMapType}
            setLoading={setLoading}
        />
    );
};

export default HeatMapContainer;
