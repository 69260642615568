import React, { useState } from "react";
import { Typography, Breadcrumb, Space } from "antd";
import { LeftOutlined, CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from 'react-use';
import { useParams } from "react-router-dom";
import moment from "moment";
import theme from "../../../../../../../themes/theme";
import Loading from "../../../../../../../components/Loading";
import api from "../../../../../../../services/api";
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import routes from "../../../../../../../routes";

type ArticleDetailProps = {
    articleDetail: any;
}

const { Title, Paragraph, Text } = Typography;

const ArticleDetailPage = (props: ArticleDetailProps) => {
    const { articleDetail } = props
    const [themeMode,] = themeModeGlobalState();
    const isDarkMode = themeMode === 'dark';

    if (!articleDetail) {
        return <Loading/>;
    }

    const styleInline = `<head>
        <style>
            body {
                font-size: 1em;
                font-family: Arial;
                line-height: 1.5;
                background-color: ${
                    isDarkMode ? theme.colors.white : theme.colors.black
                };
                color: ${isDarkMode ?  theme.colors.black : theme.colors.white}
            }

            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
                height: auto;
            }

            figcaption {
                text-align: center;
                margin-top: 10px;
            }

            p {
                font-size: 17px;
            }

            table {
                margin-left: auto;
                margin-right: auto;
                width: 75%;
                height: auto;
            }

            em {
                display: flex;
                justify-content: center;
                width: 100%;
                height: auto;
            }
        </style>
    </head>`;

    return (
        <Typography>
            <Title level={2}>{articleDetail?.title}</Title>
            <Paragraph>
                <Text className="text-description">
                    <CalendarOutlined /> {moment(articleDetail?.createdAt).format("L")}{" "}
                    <ClockCircleOutlined /> {moment(articleDetail?.createdAt).format("LT")}
                </Text>
            </Paragraph>
            <Paragraph>
                <div dangerouslySetInnerHTML={{ __html: `${styleInline} ${articleDetail?.content}` }}/>
            </Paragraph>
        </Typography>
    );
}

export default ArticleDetailPage;