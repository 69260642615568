import api from "../../../../../../../services/api"
import * as config from "../../../../../../../config";

export const fetchArticleCategory = async (filter, page) => {
    let [error, response]: any = await api.get(`plugins/article/category`, {
        filter,
        page,
        pageSize: config.PAGE_SIZE,
    });

    if (error) {
        return [error.response.data.message, null];
    }

    return [null, response.data];
}

export const fetchArticleData = async (filter, page, pageSize) => {
    // eslint-disable-next-line
    let [error, response]: any = await api.get(`plugins/article`, {
        filter,
        page,
        pageSize,
    });

    if (error) {
        return [error.response.data.message, null];
    }

    return [null, response.data];
};

export const getArticleDetail = async (id: number) => {
    return await api.get(`plugins/article/${id}`);
};