import React, { useState, useRef, useEffect, useCallback } from 'react';
import { message } from 'antd';
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useLayoutState } from '../../../../contexts';
import { fetchDashboardPlugins } from "../../../../services/dashboard";
import api from "../.././../../services/api";

// import { GoldenLayoutComponent } from "../../../../components/GoldenLayout/GoldenLayoutComponent";
import GoldenLayoutWrapper from "../../../../components/GoldenLayout/GoldenLayoutWrapper";
import { DEFAULT_DASHBOARDS } from "./constant";
import Loading from "../../../../components/Loading";

type DashboardTypeProps = {
    dashboardType: "macroeconomics" | "market_analysis" | "derivatives" | "news" | "articles";
}

const DashboardDefault = (props: DashboardTypeProps) => {
    const { dashboardType } = props;
    const { t } = useTranslation();
    const { layoutState } = useLayoutState() as any;
    const { siderCollapsed } = layoutState;

    const [ dimensions, setDimensions] = React.useState({
        height: $(window).height(),
        width: $(window).width()
    });

    const [ dashboardPlugins, setDashboardPlugins ] = useState<any>();
    const [ dashboardConfig, setDashboardConfig ] = useState<any>();
    let dashboardLayout: any = useRef(null);
    let dashboardIdRef: any = useRef(null);

    const MARGIN_HEIGHT = 60;
    const MARGIN_WIDTH = siderCollapsed ? 58 : 210; // = SIDER_WIDTH + 10px padding

    const createNewDashboard = useCallback(async (layoutConfig) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.post('plugins/add-new-dashboard', {
            layoutConfig,
            typeName: dashboardType,
            isLayoutFixed: true,
            name: dashboardType
        });

        if (err) {
            message.error({
                content: t('Có lỗi xảy ra khi tao mới bảng tổng quan'),
                key: 'create-dashboard-error'
            });
            return;
        }

        let dashboard = resp?.data;
        dashboardIdRef.current = dashboard.id;
        // message.success({
        //     content: "Tạo bảng tổng quan mới thành công.",
        //     key: 'create-dashboard-success'
        // });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardType]);

    const saveLayout = async (layoutConfig) => {
        // eslint-disable-next-line
        const _dashboardId = dashboardIdRef.current;
        let [err, ]: any = await api.patch(
            `plugins/dashboard/${_dashboardId}`, { layoutConfig }
        );

        if (err) {
            // message.error({
            //     content: "Có lỗi xảy ra",
            //     key: "save-layout-error",
            // });
            return;
        }
    };

    const initDashboardPlugins = useCallback(async () => {
        let plugins = await fetchDashboardPlugins();
        setDashboardPlugins(plugins);
    }, []);

    const fetchDashboardConfig = useCallback(async () => {
        if (!dashboardType) {
            return;
        }

        let [err, resp]: any = await api.get(
            `plugins/dashboard/get-by-type-name/${dashboardType}`
        );

        if (err) {
            const config = DEFAULT_DASHBOARDS[dashboardType];
            createNewDashboard(config);
            setDashboardConfig(config);
            return;
        }

        let dashboard = resp?.data;

        setDashboardConfig(dashboard.layoutConfig);
        dashboardIdRef.current = dashboard.id;
    }, [createNewDashboard, dashboardType])

    useEffect(() => {
        if (dashboardLayout.current) {
            dashboardLayout.current.updateSize($(window).width() - MARGIN_WIDTH, $(window).height() - MARGIN_HEIGHT);
        }

        setDimensions({
            height: $(window).height(),
            width: $(window).width()
        })
        function handleResize(e) {
            e.preventDefault();
            if (!window.screenTop && !window.screenY) {
                return;
            }

            dashboardLayout.current.updateSize($(window).width() - MARGIN_WIDTH, $(window).height() - MARGIN_HEIGHT);

            setDimensions({
                height: $(window).height(),
                width: $(window).width()
            })
        }

        window.addEventListener('resize', handleResize);
    }, [MARGIN_WIDTH]);

    useEffect(() => {
        initDashboardPlugins();
        fetchDashboardConfig();
    }, [fetchDashboardConfig, initDashboardPlugins]);

    if (!dashboardPlugins) {
        return <Loading/>
    }

    if (!dashboardConfig) {
        return <Loading/>
    }

    return (
        <div className="site-drawer-render-in-current-wrapper">
            <GoldenLayoutWrapper
                htmlAttrs={{
                    style: {
                        height: dimensions.height - MARGIN_HEIGHT,
                        width: '100%',
                        // width: dimensions.width - MARGIN_WIDTH,
                    },
                }}
                config={dashboardConfig}
                registerComponents={(layout) => {
                    // assign ref of golden layout
                    dashboardLayout.current = layout;
                    // eslint-disable-next-line
                    dashboardPlugins.map((group) => {
                        group?.plugins.map((plugin) => {
                            try{
                                layout.registerComponent(
                                    plugin?.key,
                                    plugin?.component
                                );
                            } catch (err) {
                                // do nothing
                            }
                        });
                    });

                    layout.on("stateChanged", () => {
                        if (layout.isInitialised) {
                            let layoutConfig = layout.toConfig();
                            saveLayout(layoutConfig);
                        }
                    });

                    layout.on("componentCreated", (item) => {
                        if (!item.config.id || item.config.id.length <= 0) {
                            item.addId(uuidv4());
                        }
                    });
                }}
            />
        </div>
    )
};

export default DashboardDefault;
