import React from 'react';
import { Button, notification } from 'antd';
import { GoogleOutlined } from "@ant-design/icons";
import GoogleLogin from 'react-google-login';
import { useTranslation } from "react-i18next";
import api from "../../../services/api";

type GoogleLoginButtonProps = {
    referenceCode?: string,
    onSuccess: (accessToken: string, user: any) => void,
    onError: (error: any) => void,
}

const GoogleLoginButton = (props: GoogleLoginButtonProps) => {
    const { t } = useTranslation();

    const loginByGoogle = async (authResponse) => {
        const ggAccessToken = authResponse.accessToken;

        let payloads: any = {
            accessToken: ggAccessToken,
        }

        if (props.referenceCode) {
            payloads = {
                ...payloads,
                referenceCode: props.referenceCode
            }
        }

        const [error, response]: any = await api.post('user/google/signin', payloads);

        if (error) {
            props.onError(error);
        }

        let {accessToken, user} = response.data;

        props.onSuccess(accessToken, user);
    }

    return (
        <GoogleLogin
            render={renderProps => (
                <Button
                    block
                    onClick={renderProps.onClick}
                    size="middle"
                    className="btn-login-google"
                    icon={<GoogleOutlined />}
                >
                    Google
                </Button>
            )}
            clientId="185996501067-420mtf3srce0cjjlcl67990hnlcfugpm.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={(response)=>{loginByGoogle(response)}}
            onFailure={(err)=>{
                return notification.error({
                    message: t('Đăng nhập thất bại'),
                });
            }}
            cookiePolicy={'single_host_origin'}
        />
    );
}

export default GoogleLoginButton;
