import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, message } from "antd";

import RecommendationForm from "./_Form";
import api from "../../../../services/api";
import routes from "../../../../routes";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";

const RecommendationEdit = () => {
    const { recommendationId } = useParams<{recommendationId: string}>();
    const [ recommendation, setRecommendation ]: any = useState();

    const fetchRecommendation = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/recommendation/${recommendationId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setRecommendation(resp.data);
    }

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/recommendation/${recommendationId}`, formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật thông tin khuyến nghị thành công");
    };

    useEffect(() => {
        fetchRecommendation();
        // eslint-disable-next-line
    }, []);

    if (!recommendation) {
        return <Loading/>;
    }

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Khuyến nghị', path: routes.ADMIN_RECOMMENDATION_LIST },
                { label: recommendation.title },
                { label: 'Chỉnh sửa' },
            ]} />

            <div className="m-auto mt-5 w-3/6">
                <RecommendationForm mode='edit' onFinish={onFinish} initialValues={recommendation}/>
            </div>
        </Card>
    )
}

export default RecommendationEdit;
