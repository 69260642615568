import React from "react";
import { Layout } from "antd";
import { Redirect } from "react-router-dom";

import { useAuthState } from "../../../contexts";
import routes from "../../../routes";

import Sider from "./components/Sider";
import PrivateAdminHeader from "./components/PrivateAdminHeader";
import PrivateRouteContent from "./Content";
import Loading from "../../../components/Loading";


const Home = () => {
    const { authState } = useAuthState() as any;
    const {currentUser} = authState;

    if (!currentUser) {
        return <Loading />
    }

    if (!currentUser.isModerator && !currentUser.isSupervisor) {
        return <Redirect to={routes.DASHBOARD} />
    }

    return (
        <Layout className="min-h-screen">
            <Sider/>

            <Layout className="home-layout">
                <PrivateAdminHeader/>
                <PrivateRouteContent/>
            </Layout>
        </Layout>
    );
};

export default Home;
