import fontSizes from './fontsizes';
import elementSizes from './elementSizes';
import colors, { stockColors } from './colors';

const chart = {
    bar: {
        radius: {
            vertical: [4, 4, 0, 0],
            horizontal: [0, 4, 4, 0],
        }
    }
}

export default {
    fontSizes,
    elementSizes,
    colors,
    stockColors,
    chart,
}