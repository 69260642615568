import React from "react";
import NewsContainer from "./container/composite";
import UserACL from '../../../../../../components/UserACL';

const NewsWindow = (props) => {
    return (
        <UserACL feature="news">
            <NewsContainer {...props}/>
        </UserACL>
    )
};

export default NewsWindow;
