import React, { useState, useEffect } from 'react';

import { Form, Button, Input, Select, Card } from 'antd';
import api from "../../../../services/api";

const { Option } = Select;

type UserFormProps = {
    initialValues?: any,
    onFinish: (values: any) => void
}

const UserForm = (props: UserFormProps) => {
    const [userRoleList, setUserRoleList] = useState<NSApp.Role[]>();

    const fetchUserRoles = async () => {
        let [err, resp]: any = await api.get('/permission/roles');

        if (err) return;

        setUserRoleList(resp.data);
    };

    useEffect(() => {
        fetchUserRoles();
    }, []);

    const { initialValues } = props;

    return <Card>
        <div className="m-auto mt-5 w-3/6">
            <Form
                layout="vertical"
                name="user_form"
                className="user-form"
                initialValues={initialValues}
                onFinish={props.onFinish}
            >
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { type: 'email' },
                        { required: true },
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                    name="fullname"
                    label="Họ và tên"
                    rules={[
                        { required: true },
                    ]}
                >
                    <Input placeholder="Họ và tên" />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="SĐT"
                >
                    <Input placeholder="SĐT" />
                </Form.Item>

                {userRoleList &&
                    <Form.Item
                        name="roleId"
                        label="Nhóm người dùng"
                        rules={[
                            { required: true },
                        ]}
                    >
                        <Select placeholder="Nhóm người dùng">
                            {userRoleList.map((role: NSApp.Role) => {
                                return <Option key={role.id} value={role.id}>{role.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                }

                <Form.Item
                    name='actived'
                    label="Trạng thái kích hoạt"
                >
                    <Select>
                        <Option value={true}>Đã kích hoạt</Option>
                        <Option value = {false}>Chưa kích hoạt</Option>
                    </Select>
                </Form.Item>

                <Form.Item>

                    <Button className="mb-2" type="primary" htmlType="submit" block>
                        Cập nhật
                    </Button>
                </Form.Item>
            </Form>
        </div>
    </Card>
}

export default UserForm;