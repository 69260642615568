import React from 'react';
import WatchListContainer from "../../../WatchList/container";
import UserACL from '../../../../../../components/UserACL';

const WatchlistWindow = (props) => {
    const { glContainer } = props;

    return (
        <UserACL feature="favourites">
            <div className="p-1 overflow-auto" style={{height: glContainer.height}}>
                <WatchListContainer/>
            </div>
        </UserACL>
    )
};

export default WatchlistWindow;