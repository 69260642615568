import React, { useState, useEffect } from "react";
import { Form, Button, Input, Select, message } from "antd";
import UploadImage from '../components/UploadImage';
import DraftEditor from '../../../../components/DraftEditor';
import api from '../../../../services/api';

const { Option } = Select;

type NewsFormProps = {
    initialValues?: any,
    mode: "add" | "edit",
    onFinish: (values: any) => void,
}

const NewsForm = (props: NewsFormProps) => {
    const [listProvider, setListProvider] = useState([]);
    const [listCategory, setListCategory] = useState([]);

    const fetchProviders = async (name: string) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/news/provider', {
            params: { name }
        });

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setListProvider(resp.data?.providers);
    };

    const fetchCategories = async (name: string) => {
        let filter = {}

        if (name) {
            filter['name'] = name;
        }

        // eslint-disable-next-line
        let [err, resp]: any = await api.get('plugins/news/category', {filter});

        if (err) {
            message.error(err.response?.data?.message);
            return;
        }

        let { categories } = resp?.data

        setListCategory(categories);
    };

    useEffect(() => {
        fetchProviders("");
        fetchCategories("");
    }, []);

    const onSearchProvider = (val) => {
        fetchProviders(val);
    }

    const onSearchCategory = (val) => {
        fetchCategories(val);
    }

    const onFilterSelectInput = (input, option: any) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

    const { initialValues, mode, onFinish } = props;

    return (
        <Form
            layout="vertical"
            name="news_form"
            className="news-form"
            initialValues={initialValues}
            onFinish={onFinish}
        >
            <Form.Item
                name="title"
                label="Tiêu đề"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input placeholder="Tiêu đề" />
            </Form.Item>

            <Form.Item
                name="description"
                label="Mô tả"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input placeholder="Mô tả" />
            </Form.Item>

            <Form.Item
                name="provider"
                label="Nguồn cung cấp"
                hasFeedback
                rules={[
                    { required: true },
                ]}
            >
                <Select
                    showSearch
                    onSearch={onSearchProvider}
                    filterOption={onFilterSelectInput}
                    placeholder="Chọn nhà cung cấp"
                >
                    {listProvider.map((provider: any) =>
                        <Option key={provider?._id} value={provider?._id}>{provider?.name}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                name="category"
                label="Danh mục"
                hasFeedback
                rules={[
                    { required: true },
                ]}
            >
                <Select
                    showSearch
                    onSearch={onSearchCategory}
                    placeholder="Chọn danh mục"
                    filterOption={onFilterSelectInput}
                >
                    {listCategory.map((category: any) =>
                        <Option key={category?._id} value={category?._id}>{category?.name}</Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                label='Hình minh họa'
                name='thumbnail'
                valuePropName='file'
            >
                <UploadImage defaultImage={initialValues?.thumbnail} name='thumbnail' url='media/thumbnail/upload' />
            </Form.Item>

            <Form.Item
                label="Nội dung"
                name="content"
            >
                <DraftEditor defaultState={initialValues?.content}/>
            </Form.Item>

            {/* <Form.Item name="isPublish" valuePropName="checked">
                <Checkbox>Xuất bản</Checkbox>
            </Form.Item> */}

            <Form.Item>
                <Button className="mb-2" type="primary" htmlType="submit" block>
                    {mode === 'add' ? 'Thêm mới' : 'Cập nhật'}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default NewsForm;
