import React from "react";
import { Row, Col, Card, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import * as config from "../../../../../../../../config";
import Skeleton from "../../../../../../../../components/Skeleton";
import StructureChart from "./StructureChart";

const TAB_HEADER_HEIGHT = 45;

type ShareholdersProps = {
    layoutWidth: number;
    layoutHeight: number;
    shareholders: NSApp.Shareholders | undefined,
    shareHoldersStructure: NSApp.ShareHoldersStructure | undefined,
};

const Shareholders = (props: ShareholdersProps) => {
    const { layoutWidth, layoutHeight, shareholders, shareHoldersStructure } = props;
    const { t } = useTranslation();

    if (!shareholders || !shareHoldersStructure) {
        return <Row gutter={[4, 4]} style={{height: layoutHeight - TAB_HEADER_HEIGHT}} className="overflow-auto">
            <Col span={6}>
                <Card bodyStyle={{padding: 4, height: (layoutHeight - 42)}}><Skeleton/></Card>
            </Col>
            <Col span={10}>
                <Card bodyStyle={{padding: 4, height: (layoutHeight - 42)}}><Skeleton/></Card>
            </Col>
            <Col span={8}>
                <Card bodyStyle={{padding: 4, height: (layoutHeight - 42)}}><Skeleton/></Card>
            </Col>
        </Row>
    }

    const shareholdersColumns: ColumnsType<NSApp.Shareholder> = [
        {
            title: t('Tên'),
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: t('Số CP'),
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (value) => value && value.toLocaleString(config.LOCALE_NUMBER_FORMAT)
        },
        {
            title: t('Tỷ lệ'),
            dataIndex: 'percentage',
            key: 'percentage',
            align: 'right',
            width: 60,
            ellipsis: true,
            render: (value) => value && `${(value * 100).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 })}%`
        },
        {
            title: t('Ngày cập nhật'),
            dataIndex: 'publishedDate',
            key: 'publishedDate',
            align: 'right',
            ellipsis: true,
            width: 100,
        }
    ];

    return (
        <Row gutter={[4, 4]} style={{height: layoutHeight - TAB_HEADER_HEIGHT}} className="overflow-auto">
            <Col span={layoutWidth > config.RESPONSIVE_SCREEN.md ? 6 : 24}>
                <Card
                    title={<strong>{t('Cơ cấu cổ đông')}</strong>}
                    bodyStyle={{ padding: 0 }}
                    style={{height: (layoutHeight - TAB_HEADER_HEIGHT)}}
                >
                    <StructureChart
                        layoutWidth={layoutWidth}
                        layoutHeight={layoutHeight}
                        shareHoldersStructure={shareHoldersStructure}
                    />
                </Card>
            </Col>

            <Col span={layoutWidth > config.RESPONSIVE_SCREEN.md ? 10 : 24}>
                <Card
                    title={<strong>{t('Tổ chức')}</strong>}
                    bodyStyle={{ padding: 4 }}
                    style={{height: (layoutHeight - TAB_HEADER_HEIGHT)}}
                >
                    <Table
                        size="small"
                        dataSource={shareholders.companyShareHolders}
                        columns={shareholdersColumns}
                        rowKey={(record: NSApp.Shareholder) => record._id}
                        pagination={false}
                        scroll={{ y: layoutHeight - 110 }}
                    />
                </Card>
            </Col>

            <Col span={layoutWidth > config.RESPONSIVE_SCREEN.md ? 8 : 24}>
                <Card
                    title={<strong>{t('Cá nhân')}</strong>}
                    bodyStyle={{ padding: 4 }}
                    style={{height: (layoutHeight - TAB_HEADER_HEIGHT)}}
                >
                    <Table
                        size="small"
                        dataSource={shareholders.individualShareHolders}
                        columns={shareholdersColumns}
                        rowKey={(record: NSApp.Shareholder) => record._id}
                        pagination={false}
                        scroll={{ y: layoutHeight - 110 }}
                    />
                </Card>
            </Col>
        </Row>
    )
};

export default Shareholders;