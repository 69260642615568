import React from 'react';
import TablePriceContainer from './container'
import UserACL from '../../../../../../components/UserACL';

const TablePricePlugin = (props) => {
    const { glContainer, activeTab, isDerivativesDashboard } = props;

    return (
        <UserACL feature="table_price">
            <TablePriceContainer
                glContainer={glContainer}
                activeTab={activeTab}
                isDerivativesDashboard={isDerivativesDashboard}
            />
        </UserACL>
    )
}

export default TablePricePlugin;