import React from "react";
import { message } from "antd";
import api from "../../../../services/api";
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Form from "./Form";
import { useHistory } from 'react-router-dom';
import routes from "../../../../routes";


const AddFeature = () => {
    const history = useHistory();
    const onFinish = async (formValues: any) => {

        let [err, resp]: any = await api.post("/plan/create-feature", formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        if (resp.data) {
            message.success("Thêm mới thành công");
            history.replace(routes.ADMIN_FEATURE_LIST);
        }
    };

    return <div>
            <MyBreadcrumd
                items={[
                    { label: "Quản lý tính năng" },
                    { label: "Thêm mới" },
                ]}
            />
            <Form mode="add" onFinish={onFinish}/>
    </div>
}

export default AddFeature;