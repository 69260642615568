import React, { useState } from 'react';
import { Switch, Image } from 'antd';
import { useThemeSwitcher } from "react-css-theme-switcher";
import localStorage from "../services/local-storage";
import themeModeGlobalState from "../contexts/ThemeMode";

import Dark from "../assets/image/theme/dark.png";
import Light from "../assets/image/theme/light.png";

const ImageIcon = ({ icon }) => {
    return (
        <div className="toggle-theme-icon">
            <Image width={16} src={icon} preview={false} />
        </div>
    );
};

const SwitchTheme = () => {
    const [themeMode, setThemeMode] = themeModeGlobalState();
    const [isDarkMode, setIsDarkMode] = useState(themeMode === 'dark' ? true : false);
    const { switcher, themes } = useThemeSwitcher();

    const switchTheme = async (isDarkMode) => {
        const _themeMode = isDarkMode ? 'dark' : 'light';

        setIsDarkMode(isDarkMode);
        setThemeMode(_themeMode);
        switcher({ theme: isDarkMode ? themes.dark : themes.light });
        await localStorage.set('themeMode', _themeMode);
    };

    return (
        <Switch
            size="default"
            // checkedChildren="🌜"
            // unCheckedChildren="🌞"
            checkedChildren={<ImageIcon icon={Dark} />}
            unCheckedChildren={<ImageIcon icon={Light} />}
            checked={isDarkMode}
            onChange={switchTheme}
        />
    );
};

export default SwitchTheme;