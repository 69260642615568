import React from 'react';
import { Switch, Route } from "react-router-dom";


import routes from "../../../../routes";
import FeatureList from './List';
import AddFeature from './Add';
import EditFeature from "./Edit";

const FeatureDashboard = () => {
    return <div>
        <Switch>
            <Route path={routes.ADMIN_FEATURE_EDIT} component={EditFeature}/>
            <Route path={routes.ADMIN_FEATURE_ADD} component={AddFeature}/>
            <Route path={routes.ADMIN_FEATURE_LIST} component={FeatureList}/>
        </Switch>
    </div>
}

export default FeatureDashboard;