import React, { useState, useEffect } from "react";
import { Card, Typography, Divider, Button, Space, Popconfirm, message } from "antd";
import { Link, useParams, useHistory } from "react-router-dom";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import api from "../../../../services/api";
import routes from "../../../../routes";
import { toRoute } from "../../../../services/utils";
import ChangeStatusButton from './_ChangeStatusButton';
import Loading from "../../../../components/Loading";

const { Title, Paragraph } = Typography;

const RecommendationDetail = () => {
    const history = useHistory();
    const { articleId } = useParams<any>();
    const [ article, setArticle ] = useState<NSApp.Article>();

    const fetchArticle = async () => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/article/${articleId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        setArticle(resp?.data?.article);
    };

    const deleteArticle = async (recommendationId: string) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.delete(`plugins/article/${recommendationId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success('Xóa thành công');
        history.replace(routes.ADMIN_ARTICLE_LIST);
    };

    useEffect(() => {
        fetchArticle()
        // eslint-disable-next-line
    }, []);

    if (!article) {
        return <Loading/>
    }

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Bài viết', path: routes.ADMIN_ARTICLE_LIST },
                { label: article.title },
            ]} />

            <Typography className="mt-3">
                <Title>{article.title}</Title>

                <Space>
                    <Link to={toRoute(routes.ADMIN_ARTICLE_EDIT, { articleId: article.id })}>
                        <Button type="primary">Sửa</Button>
                    </Link>

                    <Popconfirm
                        title="Bạn chắc chắn muốn xóa khuyến nghị này?"
                        onConfirm={() => deleteArticle(article.id)}
                        okText="Xóa"
                        cancelText="Hủy"
                    >
                        <Button type="primary" danger>Xóa</Button>
                    </Popconfirm>

                    <ChangeStatusButton article={article} onChange={fetchArticle}/>

                </Space>

                <Divider />
                <Paragraph>
                    <div dangerouslySetInnerHTML={{__html: article.content}}/>
                </Paragraph>
            </Typography>
        </Card>
    )
}

export default RecommendationDetail;