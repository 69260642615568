import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import StockDetail from "../Dashboard/plugins/StockDetail";
import { v4 as uuidv4 } from "uuid";

const SIDER_WIDTH = 200;
const HEADER_HEIGHT = 64;

const StockDetailPage = () => {
    const [ height, setHeight ] = useState(window.innerHeight);
    const [ width, setWidth ] = useState(window.innerWidth);

    const handleResize = (e) => {
        e.preventDefault();
        if (!window.screenTop && !window.screenY) {
            return;
        }

        setHeight(window.innerHeight);
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    let { symbol } = useParams<{symbol: string}>();

    const componentId = uuidv4();

    return <Card bodyStyle={{padding: 0}}>
        <StockDetail
            id={componentId}
            symbol={symbol.toLowerCase()}
            width={width - SIDER_WIDTH}
            height={height - HEADER_HEIGHT}
        />
    </Card>
}

export default StockDetailPage;