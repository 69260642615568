import React, { useState, useEffect, useRef } from 'react';

import { Row, Col, Skeleton } from 'antd';
import $ from "jquery";

// import { GoldenLayoutComponent } from '../../../../../components/GoldenLayout/GoldenLayoutComponent';
import GoldenLayoutWrapper from "../../../../../components/GoldenLayout/GoldenLayoutWrapper";
import { fetchDashboardPlugins } from "../../../../../services/dashboard";
import PluginsList from "./PluginList";

type DashboardContentProps = {
    layoutConfig?: any,
    onChange: (value: any) => void
}

const DashboardContent = (props: DashboardContentProps) => {
    const [ plugins, setPlugins ] = useState<any>([]);
    const windowHeight = $(window).height();

    const [ layoutConfigState, setLayoutConfigState ] = useState<any>(props?.layoutConfig);

    const dashboardLayout = useRef();

    const initListPlugins = async () => {
        let plugins = await fetchDashboardPlugins();
        setPlugins(plugins);
    }

    useEffect(() => {
        initListPlugins();
    }, [])

    // useEffect(() => {
    //     if (props.layoutConfig) {
    //         setLayoutConfigState(props.layoutConfig);
    //     }
    // }, [props.layoutConfig])

    useEffect(() => {
        props.onChange(layoutConfigState);
    }, [layoutConfigState]) //eslint-disable-line

    if (!plugins.length) {
        return <Skeleton />
    }

    return <>
        <Row>
            <Col span={21}>
                <GoldenLayoutWrapper
                    htmlAttrs={{
                        style: {
                            height: windowHeight - 100,
                            width: '100%',
                        },
                    }}
                    config={layoutConfigState}
                    registerComponents={(layout) => {
                        // assign ref of golden layout
                        dashboardLayout.current = layout;
                        // eslint-disable-next-line
                        plugins.map((group) => {
                            group?.plugins.map((plugin) => {
                                try{
                                    layout.registerComponent(
                                        plugin?.key,
                                        plugin?.component
                                    );
                                } catch (err) {
                                    // do nothing
                                }

                                let element = $(`#${plugin?.key}`);
                                let pluginConfig = {
                                    type: "react-component",
                                    title: plugin?.title,
                                    componentName: plugin?.title,
                                    component: plugin?.key,
                                };
                                layout.createDragSource(element, pluginConfig);
                            });
                        });

                        layout.on("stateChanged", () => {
                            try {
                                if (layout.isInitialised) {
                                    let layoutConfig = layout.toConfig();
                                    setLayoutConfigState(layoutConfig);
                                }
                            } catch (err) {
                                setLayoutConfigState(layoutConfigState);
                            }
                        });
                    }}
                />
            </Col>
            <Col span={3}>
                <PluginsList plugins={plugins}/>
            </Col>
        </Row>
    </>
}

export default DashboardContent;