import React from 'react';
import { Card, Row, Col } from 'antd';
import { Redirect } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import DashboardForm from './components/DashboardForm';

import { useDashboardState } from '../../../../contexts';
import { toRoute } from '../../../../services/utils';
import routes from '../../../../routes';

const DashboardIndex = () => {
    const { dashboardState } = useDashboardState() as any;
    let { defaultDashboard } = dashboardState;
    const { t } = useTranslation();

    return (
        <>
            {defaultDashboard ?
                <Redirect to={toRoute(routes.DASHBOARD_CONTENT, {dashboardId :defaultDashboard.id})}/>
                :
                <Row justify="center" align="middle">
                    <Col span={8}>
                        <Card className="w-auto">
                            <DashboardForm
                                mode="add"
                                description={t('Bạn chưa có một bảng tổng quan nào. Hãy tạo mới và tùy chỉnh theo ý thích')}
                                isDefault={true}
                            />
                        </Card>
                    </Col>
                </Row>
            }
        </>
    )
}

export default DashboardIndex;
