import React from 'react';
import TradingView from './index';

const TradingViewWrapper = (props) => {
    const ref = React.createRef();

    return <TradingView {...props} ref={ref}/>
}

export default TradingViewWrapper;
