import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AffiliateListPage from "./List";
import AffiliateWithDrawPage from "./WithDraw";
import AffiliateUserDetail from "./Details"
// import ArticleAddPage from "./Add";
// import ArticleEditPage from "./Edit";
// import ArticleDetailPage from "./Detail";

// import ArticleCategoryListPage from "./Category/List";
// import ArticleCategoryAddPage from "./Category/Add";
// import ArticleCategoryEditPage from "./Category/Edit";

import routes from "../../../../routes";

const AffiliateDashboard = () => {
    return (
        <div>
            <Switch>
                <Route path={routes.ADMIN_MANAGE_AFFILIATE_LIST} component={AffiliateListPage} />
                <Route path={routes.ADMIN_MANAGE_AFFILIATE_WITHDRAW} component={AffiliateWithDrawPage} />
                <Route path={routes.ADMIN_MANAGE_AFFILIATE_USER_DETAILS} component={AffiliateUserDetail}/>

                <Route path={routes.ADMIN_MANAGE_AFFILIATE}>
                    <Redirect to={routes.ADMIN_MANAGE_AFFILIATE_LIST} />
                </Route>
            </Switch>
        </div>
    )
}

export default AffiliateDashboard;