import React, { useState, useCallback, useEffect } from 'react';
import { Select } from 'antd';
import { ComposedChart, Bar, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Cell } from "recharts";
import moment from 'moment';
import { useTranslation } from "react-i18next";

import animateGlobalState from '../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import * as config from "../../../../../../../config";
import * as ForeignTradingServices from '../services';
import theme from '../../../../../../../themes/theme';
import Skeleton from '../../../../../../../components/Skeleton';

const History = (props) => {
    const { exchange, height, width } = props;
    const [period, setPeriod] = useState('daily');
    const [historyTradingData, setHistoryTradingData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();
    const { t } = useTranslation();

    const componentHeight = height - 60;
    const coefficient = period === 'yearly' ? 1 : 1000 * 1000 * 1000;

    const fetchHistoryTradingAllExchange = useCallback(async () => {
        setLoading(true);
        const hsxResp = await ForeignTradingServices.fetchHistoryExchanges('hose', period);

        const hnxResp = await ForeignTradingServices.fetchHistoryExchanges('hnx', period);

        const upcomResp = await ForeignTradingServices.fetchHistoryExchanges('upcom', period);

        let timeFormat = config.DATE_TIME_FORMAT.DATE;
        if (period === 'monthly') {
            timeFormat = config.DATE_TIME_FORMAT.MONTH;
        }
        if (period === 'yearly') {
            timeFormat = config.DATE_TIME_FORMAT.YEAR;
        }

        let hsxData: any = [];
        let hnxData = [];
        let upcomData = [];

        if (hsxResp) {
            hsxData = hsxResp.data.map((r: any) => {
                return { ...r, time: moment(r.time).format(timeFormat) };
            });
        }

        if (hnxResp) {
            hnxData = hnxResp.data.map((r: any) => {
                return { ...r, time: moment(r.time).format(timeFormat) };
            });
        }

        if (upcomResp) {
            upcomData = upcomResp.data.map((r: any) => {
                return { ...r, time: moment(r.time).format(timeFormat) };
            });
        }

        if (hsxData && hnxData && upcomData) {
            let result: any = Object.values(
                [...hsxData, ...hnxData, ...upcomData].reduce(
                    (
                        entry,
                        {
                            time,
                            foreignBuyValue,
                            foreignSellValue,
                            amountForeignValue,
                        },
                    ) => {
                        entry[time] = {
                            time: moment(time, timeFormat).format(),
                            foreignBuyValue:
                                (entry[time]
                                    ? entry[time].foreignBuyValue
                                    : 0) + foreignBuyValue,
                            foreignSellValue:
                                (entry[time]
                                    ? entry[time].foreignSellValue
                                    : 0) + foreignSellValue,
                            amountForeignValue:
                                (entry[time]
                                    ? entry[time].amountForeignValue
                                    : 0) + amountForeignValue,
                        };
                        return entry;
                    },
                    {},
                ),
            ).sort((a: any, b: any) => {
                return moment(a.time).valueOf() - moment(b.time).valueOf();
            });

            setHistoryTradingData(result);
        }
        setLoading(false);
    }, [period]);

    const fetchHistoryTradingData = useCallback(async () => {
        setLoading(true);
        const response: any = await ForeignTradingServices.fetchHistoryExchanges(exchange, period);

        if (response) {
            const data = response.data.reverse();
            setHistoryTradingData(data);
        }
        setLoading(false);
    }, [exchange, period]);

    useEffect(() => {
        if (exchange === '') {
            fetchHistoryTradingAllExchange();
        } else {
            fetchHistoryTradingData();
        }
    }, [exchange, fetchHistoryTradingAllExchange, fetchHistoryTradingData]);

    const timeFormatter = (value: string) => {
        if (period === "daily") {
            return moment(value).format(config.DATE_TIME_FORMAT.DATE);
        }
        if (period === "monthly") {
            return `T${moment(value).format(config.DATE_TIME_FORMAT.MONTH)}`;
        }
        if (period === "yearly") {
            return moment(value).format(config.DATE_TIME_FORMAT.YEAR);
        }
        return value;
    }

    const tickFormatter = (value: number) => {
        return Math.round(Number(value / coefficient)).toLocaleString(config.LOCALE_NUMBER_FORMAT);
    };

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-chart-tooltip-opacity-75">
                    <div><strong>{timeFormatter(label)}</strong></div>
                    <div style={{color: payload[0]?.value < 0 ? theme.colors.red.text[themeMode] : theme.colors.green.text[themeMode]}}>
                        <strong>{payload[0].name}: {formatValue(payload[0].value)} {t('Tỷ')}</strong>
                    </div>
                    {payload.slice(1).map((entry) => {
                        return (
                            <div key={`tooltip-${entry.name}`} style={{color: entry.color}}>
                                <strong>{entry.name}: {formatValue(entry.value)} {t('Tỷ')}</strong>
                            </div>
                        )
                    })}
                </div>
            )
        }
        return null;
    }

    const CustomizedLabel = (props) => {
        const { x, y, width, value } = props;
        const textColor = themeMode === 'dark' ? theme.colors.white : theme.colors.black;

        return (
            <text x={x + width / 2} y={y} dy={value < 0 ? 15 : -5} fontSize="12" fontWeight="600" fill={textColor} textAnchor="middle">
                {formatValue(value)}
            </text>
        );
    }

    const formatValue = (value) => {
        if (Math.abs(value / coefficient) < 1) {
            return (value / coefficient).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 })
        }
        return Math.round(value / coefficient).toLocaleString(config.LOCALE_NUMBER_FORMAT);
    }

    return (
        <div>
            <div className="flex justify-between items-center mb-1">
                <span className="text-base font-bold">{t('Giao dịch mua - bán ròng')} ({t('Tỷ VNĐ')})</span>
                <Select
                    style={{width: 120}}
                    value={period}
                    size="middle"
                    onChange={(value) => setPeriod(value)}
                >
                    <Select.Option value="daily">{t('Theo ngày')}</Select.Option>
                    <Select.Option value="monthly">{t('Theo tháng')}</Select.Option>
                    <Select.Option value="yearly">{t('Theo năm')}</Select.Option>
                </Select>
            </div>
            {loading ? (
                <div style={{height: componentHeight}}><Skeleton/></div>
            ) : (
                <>
                    {!historyTradingData.length ? (
                        <div style={{height: componentHeight}}>{t('Không có dữ liệu')}</div>
                    ) : (
                        <ComposedChart
                            width={width}
                            height={componentHeight}
                            data={historyTradingData}
                            margin={{top: 5, right: 0, left: 5, bottom: 0}}
                        >
                            <defs>
                                <linearGradient id={`tradingHistoryChart`} x1="0" y1="0" x2="1" y2="1">
                                    <stop offset={0.5} stopColor={theme.colors.green.background}/>
                                    <stop offset={0.5} stopColor={theme.colors.red.background}/>
                                </linearGradient>
                            </defs>

                            <XAxis
                                dataKey={'time'}
                                stroke={theme.colors.chartAxis[themeMode]}
                                strokeWidth={0.5}
                                tick={{fontSize: 11}}
                                tickLine={false}
                                tickFormatter={(tickValue) => timeFormatter(tickValue)}
                            />
                            <YAxis
                                yAxisId="left"
                                tick={{fontSize: 11}}
                                tickLine={false}
                                tickFormatter={(tickValue) => tickFormatter(tickValue)}
                                stroke={theme.colors.chartAxis[themeMode]}
                                strokeWidth={0.5}
                                domain={["auto", "auto"]}
                                padding={{ bottom: 30 }}
                            />
                            <CartesianGrid
                                vertical={false}
                                stroke={theme.colors.gray.background}
                                strokeWidth={0.2}
                            />

                            <Tooltip
                                cursor={false}
                                content={data => renderTooltip(data)}
                            />

                            <Legend wrapperStyle={{fontSize: theme.fontSizes.small}} />

                            <Bar
                                connectNulls
                                isAnimationActive={animate}
                                yAxisId={'left'}
                                key={'amountForeignValue'}
                                dataKey={'amountForeignValue'}
                                name={t('Giá trị ròng')}
                                unit={''}
                                fill={`url(#tradingHistoryChart)`}
                                label={<CustomizedLabel />}
                                barSize={50}
                                radius={theme.chart.bar.radius.vertical}
                            >
                                {historyTradingData.map((entry, index) => <Cell
                                    key={`cell-${index}`}
                                    fill={entry['amountForeignValue'] > 0 ? theme.colors.green.background : theme.colors.red.background}
                                />)}
                            </Bar>

                            <Line
                                connectNulls
                                isAnimationActive={animate}
                                type="monotone"
                                key={'foreignBuyValue'}
                                dataKey={'foreignBuyValue'}
                                yAxisId={'left'}
                                name={t('Giá trị mua')}
                                unit={''}
                                stroke={theme.colors.orange.background}
                                strokeWidth={2}
                            />

                            <Line
                                connectNulls
                                isAnimationActive={animate}
                                type="monotone"
                                key={'foreignSellValue'}
                                dataKey={'foreignSellValue'}
                                yAxisId={'left'}
                                name={t('Giá trị bán')}
                                unit={''}
                                stroke={theme.colors.purple.background}
                                strokeWidth={2}
                            />
                        </ComposedChart>
                    )}
                </>
            )}
        </div>
    )
}

export default History;