import React, { useState } from "react";
import { Select, Skeleton } from "antd";
import { SearchOutlined, StarFilled } from "@ant-design/icons";
import api from "../../../../../services/api";
import theme from "../../../../../themes/theme";
import _ from 'lodash';

const { Option } = Select;

type AddSymbolToWatchListProps = {
    placeholder?: string,
    style?: object,
    value: string,
    loading: boolean,
    onSearch: (value: string) => void,
    onChange: (value: string) => void,
    onSelect: (value: string) => void,
    stocksList: NSApp.Stock[],
    watchlist: NSApp.Watchlist,
}

const AddStockInput = (props: AddSymbolToWatchListProps) => {
    const { placeholder, style, watchlist, onSelect } = props;
    // eslint-disable-next-line

    // const [ loading, setLoading ] = useState(true);
    const [ stockCode, setStockCode ] = useState<string|undefined>();
    const [ stocksList, setStocksList ] = useState<NSApp.Stock[]>([]);

    const fetchStockMarket = async (code: string) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.get('symbol', { code });

        // TODO: handle error
        if (err) return;

        setStocksList(resp.data);
        // setLoading(false);
    };

    const handleSearch = (value: string) => {
        if (value) {
            fetchStockMarket(value);
        } else {
            setStocksList([]);
        }
    };

    const handleChange = (value: string) => {
        setStockCode(value);
    };

    if (!watchlist) return <Skeleton/>;

    const options = (
        stocksList.map((stock: any) => {
            let inWatchList = _.includes(watchlist.stocks, stock.code);
            let starColor = inWatchList ? theme.colors.yellow.background : theme.colors.blurWhite;

            return (
                <Option className="select-item-option-wrap" disabled={inWatchList} key={stock.code} value={stock.code}>
                    <StarFilled style={{color: starColor, marginRight: '0.5rem'}} />
                    <strong>{stock.code?.toUpperCase()}</strong> - {stock.name} - {stock.exchange?.toUpperCase()}
                </Option>
            )
        })
    )

    return (<>
        <Select
            showSearch
            value={stockCode}
            placeholder={placeholder}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onSelect={onSelect}
            notFoundContent={null}
            suffixIcon={<SearchOutlined />}
            style={style}
            dropdownStyle={{ minWidth: 400 }}
        >
            {options}
        </Select>
    </>)
};

export default AddStockInput;
