import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Card, Button, Tag } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { useReducedState } from "../../../../services/utils";
import routes from "../../../../routes";
import * as config from "../../../../config/index";
import api from "../../../../services/api";

import LoadingComponent from '../../../../components/Loading';
import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";

import Dashboard from "./Affiliate/Dashboard";
import Profile from "./Affiliate/Profile";
// import Fpoint from './Fpoint';


const UserDetail = () => {
    const {userId} = useParams<any>()

    const [currentPlan, setCurrentPlan] = useState<{ planName: string, expiredAt: string, planKey: string}>({ planName: '', expiredAt: '', planKey: '' });
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tabKey, setTabKey] = useState<string>('user-plan')
    const [userDetail, setUserDetail] = useState<any>()
    const [userAffiliate, setUserAffiliateProfile] = useState<NSApp.UserAffiliate>();

    const [pagination, setPagination] = useReducedState({
        current: 1,
        pageSize: 5,
        total: 0
    });

    const fetchHistory = async () => {
        setLoading(true);
        const [error, response]: any = await api.get('/payment/history-user', {
            page: pagination.current, 
            pageSize: pagination.pageSize,
            userId
        });

        if (error) {
            return;
        }
        if (response) {
            const { currentPlan, purchases, total } = response.data;
            setCurrentPlan(currentPlan);
            setHistory(purchases);
            setPagination({ total: total });
        }

        setLoading(false);
    }

    const fetchUserDetail = async () => {
        const [error, response]: any = await api.get('/user/detail', {
            page: pagination.current, pageSize: pagination.pageSize, id: userId
        });

        if (response) {
            setUserDetail(response.data)
        }

        setLoading(false);
    }

    const fetchAffiliateProfile = async () => {
        const [error, response]: any = await api.get(`/affiliate/user/${userId}`);

        if (error) {
            return;
        }

        setUserAffiliateProfile(response.data);
    };

    useEffect(() => {
        fetchHistory();
        fetchUserDetail();
        fetchAffiliateProfile();
    }, [pagination.current]);

    if (loading) {
        return <LoadingComponent />
    }

    const columnsPlan: any = [
        {
            title: <span className="text-base">Gói dịch vụ</span>,
            dataIndex: 'planName',
            align: 'center',
            render: (plan) => <span>{plan}</span>
        },
        {
            title: <span className="text-base">Ngày bắt đầu</span>,
            dataIndex: 'startedAt',
            align: 'center',
            render: (value) => moment(value).format(config.DATE_TIME_FORMAT.DATE)
        },
        {
            title: <span className="text-base">Ngày kết thúc</span>,
            dataIndex: 'expiredAt',
            align: 'center',
            render: (value) => moment(value).format(config.DATE_TIME_FORMAT.DATE)
        },
        {
            title: <span className="text-base">Hình thức kích hoạt</span>,
            dataIndex: 'type',
            align: 'center',
            render: (type, record) => {
                if( record.purchaseId === 'trial') {
                    return <span>Dùng thử</span>
                }
                if(type === 'vnpay') {
                    return <span>Thanh toán Vnpay</span>
                }
                else if(type === 'fpoint') {
                    return <span>Quy đổi Fpoint</span>
                }
                else if (type === 'APP_STORE') {
                    return <span>App store</span>
                }
                else if (type === 'PLAY_STORE') {
                    return <span>Play Store</span>
                }
                return <span></span>
            }
        },
        {
            title: <span className="text-base">Trạng thái</span>,
            dataIndex: 'purchaseId',
            align: 'center',
            render: (value, record) => {
                
                if(currentPlan?.planName?.toString() === record?.planName?.toString() && currentPlan?.expiredAt?.toString() === record?.expiredAt?.toString()) {
                    return  <Tag color={"#389e0d"}> {"Đang hoạt động"}</Tag>
                }
                else return  <Tag color={"#1d39c4"}> {"Chưa sử dụng"}</Tag>
            }
        }
    ];

    const columnsHistory: any = [
        {
            title: <span className="text-base">Gói dịch vụ</span>,
            dataIndex: 'plan.name',
            key: 'name',
            render: (value, record) => (
                <div>
                    <div className="text-lg font-bold">{value}</div>
                    <div className="text-gray">Kỳ hạn: {record.periodPlan} Tháng</div>
                </div>
            ),
        },
        {
            title: <span className="text-base">Số hoá đơn</span>,
            dataIndex: 'referenceCode',
            key: 'code',
            align: 'center',
        },
        {
            title: <span className="text-base">Ngày thanh toán</span>,
            dataIndex: 'createdAtDate',
            key: 'date',
            align: 'center',
            render: (value) => moment(value).format(config.DATE_TIME_FORMAT.DATE),
        },
        {
            title: <span className="text-base">Tổng tiền</span>,
            dataIndex: 'pricePlan',
            key: 'price',
            align: 'right',
            render: (value) => (
                <span className="text-base font-bold">
                    {value.toLocaleString('vi-VN', { style : 'currency', currency : 'VND' })}
                </span>
            )
        },
    ];

    const tabList = [
        {
            key: 'user-plan',
            tab: 'Gói dịch vụ khả dụng'
        },
        {
            key: 'history-purchase',
            tab: 'Lịch sử đơn hàng'
        },
        // {
        //     key: 'fpoint',
        //     tab: 'Fpoint'
        // },
        {
            key: 'affiliate-profile',
            tab: 'Hồ sơ'
        },
        {
            key: 'withdraw-history',
            tab: 'Hoa hồng'
        }
    ]

    const contentList = {
        "user-plan": 
                <Table
                    bordered
                    size="large"
                    rowKey={(r: any) => r.id}
                    dataSource={userDetail?.userPlanAvailable}
                    columns={columnsPlan}
                    // scroll={{ y : 500 }}
                    pagination={{
                        ...pagination,
                        onChange: page => {
                            setPagination({ current: page });
                        },
                    }}
                />,
        "history-purchase": <>
                    <Table
                        bordered
                        size="large"
                        rowKey={(r: any) => r.id}
                        dataSource={history}
                        columns={columnsHistory}
                        // scroll={{ y : 500 }}
                        pagination={{
                            ...pagination,
                            onChange: page => {
                                setPagination({ current: page });
                            },
                        }}
                    />
        </>,
        // 'fpoint': <Fpoint/>,
        'affiliate-profile': userAffiliate ? <Profile affiliate={userAffiliate}/> : <Loading />,
        'withdraw-history': userAffiliate ? <Dashboard userId={userId} affiliate={userAffiliate}/> : <Loading />
    }

    const onTabChange = async (key) => {
        setTabKey(key);
    };

    if(!userDetail) {
        return <LoadingComponent/>
    }

    return (
        <Card
            tabList={tabList}
            activeTabKey={tabKey}
            onTabChange={(key) => onTabChange(key)}
            >
                <MyBreadcrumd items={[
                    { label: 'Người dùng' , path: routes.ADMIN_USER_LIST },
                    { label: `${userDetail.fullname} - ${userDetail.email}`},
                ]} />
            {contentList[tabKey]}
        </Card>
    )
};

export default UserDetail;