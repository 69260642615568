import React from "react";
import { Row, Col, Button, Modal, Space, Select } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import TablePriceContainer from '../../Dashboard/plugins/TablePrice/container/TablePrice';
import _ from 'lodash';
import AddStockInput from "../container/AddStockInput";
import WatchListForm from "./_WatchListForm";

type WatchListProps = {
    height?: number,
    watchlists: NSApp.Watchlist[],
    activeWatchlist: NSApp.Watchlist | undefined,
    watchListModalVisible: boolean,
    addWatchList: (name: string) => void,
    updateWatchList: (name: string) => void,
    deleteWatchList: (id: string) => void,
    removeStockFromWatchList: (stockCode: string) => void,
    addStockWatchList: (stockCode: string) => void,
    changeActiveList: (activeList) => void,
    changeWatchListModalVisible: (watchListModalVisible) => void,
}

const DEFAULT_WATCHLIST = [
    'VN30',
    'HNX30',
    'FU',
];

const                                       WatchList = (props: WatchListProps) => {
    const {
        height,
        watchlists,
        activeWatchlist,
        watchListModalVisible,
        addWatchList,
        updateWatchList,
        deleteWatchList,
        removeStockFromWatchList,
        changeActiveList,
        changeWatchListModalVisible,
        addStockWatchList
    } = props;

    const onChangeActiveList = (key) => {
        let activeList = _.find(watchlists, (w: NSApp.Watchlist)=> w.id === key);
        changeActiveList(activeList);
    };

    const confirmDeleteWatchList = (watchList: NSApp.Watchlist) => {
        return (
            Modal.confirm({
                title: <span>Bạn có chắc chắn muốn xoá danh mục <strong>{watchList.name}</strong>?</span>,
                icon: <ExclamationCircleOutlined/>,
                okText: 'Có',
                okButtonProps: { type: 'primary', danger: true },
                cancelText: 'Huỷ',
                onOk() {
                    deleteWatchList(watchList.id)
                },
            })
        )
    };

    const confirmRemoveStockFromWatchList = (stockCode: string) => {
        if (!activeWatchlist) return;

        return (
            Modal.confirm({
                title: <span>Bạn có chắc chắn muốn xoá mã <strong>{stockCode.toUpperCase()}</strong> khỏi danh mục <strong>{activeWatchlist.name}</strong>?</span>,
                icon: <ExclamationCircleOutlined/>,
                okText: 'Có',
                okButtonProps: { type: 'primary', danger: true },
                cancelText: 'Huỷ',
                onOk() {
                    removeStockFromWatchList(stockCode)
                },
            })
        )
    };

    const extendColumns: ColumnsType<NSApp.Stock> = activeWatchlist && DEFAULT_WATCHLIST.includes(activeWatchlist.id) ? [] : [
        {
            width: 50,
            title: '',
            key: 'action',
            align: 'center',
            render: (record) => (
                <Button
                    type="text"
                    onClick={() => confirmRemoveStockFromWatchList(record.stockCode)}
                >
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    return (
        <>
            <Row className="my-1">
                <Col flex={1}>
                    <Space>
                        <WatchListForm
                            mode='add'
                            onFinish={(formValue) => {
                                let { name } = formValue;
                                addWatchList(name);
                            }}
                        />
                        {watchlists && watchlists.length > 0  &&
                            <Select defaultValue={watchlists[0].id} style={{ width: 150 }} onChange={onChangeActiveList} value={activeWatchlist?.id}>
                                {watchlists.map((list: NSApp.Watchlist) => {
                                    return <Select.Option key={list.id} value={list.id}>{list.name}</Select.Option>
                                })}
                            </Select>
                        }
                        {activeWatchlist && !DEFAULT_WATCHLIST.includes(activeWatchlist.id) &&
                        <>
                            <Button onClick={() => changeWatchListModalVisible(true)}>
                                <EditOutlined /> Sửa danh mục
                            </Button>
                            <Button onClick={() => confirmDeleteWatchList(activeWatchlist)}>
                                <DeleteOutlined/> Xoá danh mục
                            </Button>
                            <AddStockInput
                                placeholder="Thêm mã chứng khoán"
                                style={{ width: 200 }}
                                watchlist={activeWatchlist}
                                addStockWatchlist={addStockWatchList}
                            />
                        </>
                        }
                    </Space>
                </Col>
            </Row>

            {activeWatchlist && <>
                <TablePriceContainer
                    height={height}
                    stockCodes={activeWatchlist.stocks || []}
                    extendColumns={extendColumns}
                    priceRatio={activeWatchlist.id === 'FU' ? 1 : 1000}
                    stockType={activeWatchlist.id === 'FU' ? 'derivative' : ''}
                />
                <Modal
                    title={<h3 className="mb-0">Chỉnh sửa danh mục</h3>}
                    visible={watchListModalVisible}
                    onCancel={() => changeWatchListModalVisible(false)}
                    closable={true}
                    footer={null}
                    forceRender
                >
                    <WatchListForm
                        initialValues={activeWatchlist}
                        onFinish={(newName) => updateWatchList(newName)}
                        mode='edit'
                    />
                </Modal>
            </>}
        </>
    );
};

export default WatchList;
