import React, { useState, useEffect } from 'react';

import { message } from 'antd';
import { useParams } from "react-router-dom";

import DashboardForm from './_Form';
import api from "../../../../services/api";
import AdminPermission from '../../../../components/AdminPermission';

const DashboardEditPageComponent = () => {
    const { dashboardId } = useParams<{ dashboardId: string }>();

    const [ dashboardTemplate, setDashboardTemplate ] = useState<NSApp.DashboardTemplate>();

    const fetchDashboardTemplate = async () => {
        //eslint-disable-next-line
        let [err, resp]: any = await api.get(`plugins/dashboard-template/${dashboardId}`);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        setDashboardTemplate(resp.data);
    }

    useEffect(() => {
        fetchDashboardTemplate();
    }, []) //eslint-disable-line

    const editDashboard = async (payloads: any) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.put(`plugins/dashboard-template/${dashboardId}`, payloads);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Cập nhật dashboard thành công");
    }

    return <>
        <DashboardForm initialState={dashboardTemplate} onSubmit={editDashboard} mode="edit" />
    </>
}

const DashboardEditPage = () => {
    return <AdminPermission roleName={[]}>
        <DashboardEditPageComponent />
    </AdminPermission>
}

export default DashboardEditPage;
