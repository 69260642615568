import i18n from '../../../../../../../../i18n';

export const GenCharts = ({ isBank, filterBy }) => {
    const staticCharts = [
        {
            chartType: "composed",
            title: isBank ? i18n.t('Tổng thu nhập hoạt động') : i18n.t('Doanh thu'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: isBank ? "totaloperatingincome" : "revenue", name: isBank ? i18n.t('Tổng thu nhập hoạt động') : i18n.t('Doanh thu'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: isBank ? "totaloperatingincomegrowthrate" : "salegrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Lợi nhuận sau thuế'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: "parentcompanyprofit", name: i18n.t('Lợi nhuận'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: "profitparentgrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
                ]
            }
        },
    ];

    const bankFee = {
        chartType: "composed",
        title: i18n.t('Chi phí dự phòng rủi ro tín dụng'),
        YAxisLabel: i18n.t('Tỷ VND'),
        YAxisUnit: "billion",
        secondYAxis: true,
        secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
        secondYAxisUnit: "%",
        chartData: {
            bar: [{ dataKey: "expenseforcreditrisk", name: i18n.t('Chi phí dự phòng rủi ro tín dụng'), unit: ` ${i18n.t('Tỷ')}` }],
            line: [
                { dataKey: "expenseforcreditriskgrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
            ]
        }
    };

    const otherStockFeeYearly = {
        chartType: "bar",
        title: i18n.t('Chi phí'),
        YAxisLabel: i18n.t('Tỷ VND'),
        YAxisUnit: "billion",
        secondYAxis: true,
        secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
        secondYAxisUnit: "%",
        chartData: {
            bar: [
                { dataKey: "financefee", name: i18n.t('Chi phí tài chính'), unit: ` ${i18n.t('Tỷ')}` },
                { dataKey: "salefee", name: i18n.t('Chi phí bán hàng'), unit: ` ${i18n.t('Tỷ')}` },
                { dataKey: "managementfee", name: i18n.t('Chi phí quản lý'), unit: ` ${i18n.t('Tỷ')}` },
                { dataKey: "otherfee", name: i18n.t('Chi phí khác'), unit: ` ${i18n.t('Tỷ')}` }
            ],
            line: [
                { dataKey: "growthratetotalfee", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
            ]
        }
    };

    const otherStockFeeQuarterly = {
        chartType: "composed",
        title: i18n.t('Tổng chi phí'),
        YAxisLabel: i18n.t('Tỷ VND'),
        YAxisUnit: "billion",
        secondYAxis: true,
        secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
        secondYAxisUnit: "%",
        chartData: {
            bar: [{ dataKey: "totalfee", name: i18n.t('Tổng chi phí'), unit: ` ${i18n.t('Tỷ')}` }],
            line: [
                { dataKey: "growthratetotalfee", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
            ]
        }
    };

    let charts = [
        ...staticCharts,
        isBank ? bankFee : filterBy === 'year' ? otherStockFeeYearly: otherStockFeeQuarterly,
        {
            chartType: "composed",
            title: i18n.t('Biên lợi nhuận'),
            YAxisLabel: `${i18n.t('Phần trăm')} (%)`,
            YAxisUnit: "%",
            secondYAxis: false,
            chartData: {
                bar: [],
                line: [
                    { dataKey: "grossprofitmargin", name: i18n.t('Biên LN gộp'), yAxisType: "left", unit: "%" },
                    { dataKey: "netprofitmargin", name: i18n.t('Biên LN thuần'), yAxisType: "left", unit: "%" },
                    { dataKey: "ebitmargin", name: i18n.t('Biên EBIT'), yAxisType: "left", unit: "%" }
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Hiệu suất sinh lời'),
            YAxisLabel: `${i18n.t('Phần trăm')} (%)`,
            YAxisUnit: "%",
            secondYAxis: false,
            chartData: {
                bar: [],
                line: [
                    { dataKey: "roe", name: i18n.t('ROE'), yAxisType: "left", unit: "%" },
                    { dataKey: "roa", name: i18n.t('ROA'), yAxisType: "left", unit: "%" },
                    { dataKey: "roic", name: i18n.t('ROIC'), yAxisType: "left", unit: "%" }
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Tổng tài sản'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: "asset", name: i18n.t('Tổng tài sản'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: "assetgrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" }
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Nguồn vốn'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: "ownerequity", name: i18n.t('Nguồn vốn'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: "ownerequitygrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" }
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Tổng nợ'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: "debt", name: i18n.t('Tổng nợ'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: "debtgrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" }
                ]
            }
        },
        {
            chartType: "bar",
            title: i18n.t('Lưu chuyển tiền tệ'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: false,
            chartData: {
                bar: [
                    { dataKey: "cashflowfromsale", name: i18n.t('CFO'), unit: ` ${i18n.t('Tỷ')}` },
                    { dataKey: "cashflowfrominvest", name: i18n.t('CFI'), unit: ` ${i18n.t('Tỷ')}` },
                    { dataKey: "cashflowfromfinancial", name: i18n.t('CFF'), unit: ` ${i18n.t('Tỷ')}` }
                ],
                line: []
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Cấu trúc tài sản'),
            YAxisLabel: i18n.t('Lần'),
            YAxisUnit: "times",
            secondYAxis: false,
            chartData: {
                bar: [],
                line: [
                    { dataKey: "debtassetratio", name: i18n.t('Nợ/Tổng tài sản'), yAxisType: "left", unit: ` ${i18n.t('Lần')}` },
                    { dataKey: "debtequityratio", name: i18n.t('Nợ/Vốn'), yAxisType: "left", unit: ` ${i18n.t('Lần')}` },
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Hệ số thanh toán'),
            YAxisLabel: i18n.t('Lần'),
            YAxisUnit: "times",
            secondYAxis: false,
            chartData: {
                bar: [],
                line: [
                    { dataKey: "quickratio", name: i18n.t('Thanh toán nhanh'), yAxisType: "left", unit: ` ${i18n.t('Lần')}` },
                    { dataKey: "currentratio", name: i18n.t('Thanh toán hiện thời'), yAxisType: "left", unit: ` ${i18n.t('Lần')}` },
                ]
            }
        },
    ];

    const extra = [
        {
            chartType: "composed",
            title: i18n.t('Thu nhập lãi thuần'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: "netinterestincome", name: i18n.t('Thu nhập lãi thuần'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: "netinterestincomegrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Tiền gửi không kì hạn'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: "demanddeposit", name: i18n.t('Tiền gửi không kì hạn'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: "demanddepositgrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Tiền gửi khách hàng'),
            YAxisLabel: i18n.t('Tỷ VND'),
            YAxisUnit: "billion",
            secondYAxis: true,
            secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            secondYAxisUnit: "%",
            chartData: {
                bar: [{ dataKey: "customerdeposit", name: i18n.t('Tiền gửi khách hàng'), unit: ` ${i18n.t('Tỷ')}` }],
                line: [
                    { dataKey: "customerdepositgrowthrate", name: i18n.t('Tăng trưởng'), yAxisType: "right", unit: "%" },
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('Tỷ lệ dư nợ/tiền gửi (LDR)'),
            YAxisLabel: `${i18n.t('Phần trăm')} (%)`,
            YAxisUnit: "%",
            secondYAxis: false,
            // secondYAxisLabel: `${i18n.t('Tăng trưởng')} (%)`,
            // secondYAxisUnit: "%",
            chartData: {
                bar: [],
                line: [
                    { dataKey: "loantodeposit", name: i18n.t('Tỷ lệ dư nợ/tiền gửi (LDR)'), yAxisType: "left", unit: "%" },
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('CAR NIM NPL'),
            YAxisLabel: `${i18n.t('Phần trăm')} (%)`,
            YAxisUnit: "%",
            secondYAxis: false,
            chartData: {
                bar: [],
                line: [
                    { dataKey: "car", name: i18n.t('Tỷ lệ an toàn vốn tối thiểu (CAR)'), yAxisType: "left", unit: "%" },
                    { dataKey: "netinterestmargin", name: i18n.t('Biên lãi suất ròng (NIM)'), yAxisType: "left", unit: "%" },
                    { dataKey: "nonperformingloan", name: i18n.t('Tỷ lệ nợ xấu (NPL)'), yAxisType: "left", unit: "%" }
                ]
            }
        },
        {
            chartType: "composed",
            title: i18n.t('CIR và Tỷ lệ trích lập dự phòng'),
            YAxisLabel: `${i18n.t('Phần trăm')} (%)`,
            YAxisUnit: "%",
            secondYAxis: false,
            chartData: {
                bar: [],
                line: [
                    { dataKey: "costinrevenue", name: i18n.t('Tỷ lệ chi phí/thu nhập (CIR)'), yAxisType: "left", unit: "%" },
                    { dataKey: "provisionpernonperformingloan", name: i18n.t('Tỷ lệ trích lập dự phòng/nợ xấu'), yAxisType: "left", unit: "%" },
                ]
            }
        },
    ];

    if (isBank) {
        charts = [
            ...charts.slice(0, -2),
            ...extra,
        ];
    };

    return charts;
}