import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";
import { useTranslation } from "react-i18next";
import theme from "../../../../../../../../themes/theme";
import * as config from "../../../../../../../../config";
import animateGlobalState from '../../../../../../../../contexts/Animate';

type ShareHoldersStructureProps = {
    shareHoldersStructure: NSApp.ShareHoldersStructure,
    layoutWidth: number,
    layoutHeight: number
};

const ShareholdersStructureChart = (props: ShareHoldersStructureProps) => {
    const { shareHoldersStructure, layoutWidth, layoutHeight } = props;
    const [ structureData, setStructureData ] = useState([]);
    const [animate,] = animateGlobalState();
    const { t } = useTranslation();

    const COLORS = [
        theme.colors.orange.background,
        theme.colors.green.background,
        theme.colors.purple.background
    ];

    const formatStructureData = () => {
        const data: any = [
            { name: t('Cổ đông nhà nước'), value: shareHoldersStructure.statePercentage },
            { name: t('Cổ đông nước ngoài'), value: shareHoldersStructure.foreignerPercentage },
            { name: t('Cổ đông khác'), value: shareHoldersStructure.otherPercentage },
        ];

        setStructureData(data);
    }

    useEffect(() => {
        formatStructureData();
    }, [shareHoldersStructure]); // eslint-disable-line

    const formatPercent = (value: number) => {
        return `${(value*100).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits: 2 })}%`
    }

    return (
        <PieChart
            width={layoutWidth > config.RESPONSIVE_SCREEN.md ? layoutWidth/4 : layoutWidth}
            height={layoutHeight/2}
        >
            <Pie
                dataKey="value"
                isAnimationActive={animate}
                data={structureData}
                innerRadius={60}
                outerRadius={80}
                cornerRadius={4}
                paddingAngle={4}
            >
                {structureData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
            </Pie>
            <Legend
                layout="vertical"
                formatter={(value, entry) => `${value} (${formatPercent(entry.payload.percent)})`}
            />
            <Tooltip
                contentStyle={{ fontWeight: 'bold' }}
                formatter={(value) => formatPercent(value)}
            />
        </PieChart>
    )
};

export default ShareholdersStructureChart;