import api from "../../../../../../../services/api";

export const fetchMacroeconomics = async () => {
    // eslint-disable-next-line
    let [err, resp]: any = await api.get(`financial-report/macroeconomy`);

    if (err) {
        return [err, null];
    }

    return [null, resp.data];
};