import React, { useState, useEffect } from "react";
import { Row, Col, Collapse, Switch, message } from "antd";
import { useTranslation } from "react-i18next";
import api from "../../../../services/api";
import { NOTIFICATION_SETTING_TYPES, PLATFORMS_SETTING } from "../../../../config";

const { Panel } = Collapse;

type NotificationSettingItem = {
    type: string,
    enabled: boolean
}

type NotificationSetting = {
    notifications: NotificationSettingItem[],
    platforms: string[]
}

const NotificationCenter = () => {
    const notificationSettingStateInitial = {
        notifications: [],
        platforms: []
    };

    const [notificationSetting, setNotificationSetting] = useState<NotificationSetting>(notificationSettingStateInitial);
    const { t } = useTranslation();

    const fetchNotificationSetting = async () => {
        const [err, resp]: any = await api.get('/notification/get-setting');

        if (err) {
            // handle error
            return;
        }
        setNotificationSetting(resp.data);
    }

    const saveSetting = async (data) => {
        const [err, resp]: any = await api.post('/notification/save-setting', data);

        if (err) {
            message.error(t('Đã có lỗi xảy ra. Xin vui lòng thử lại'));
            return;
        }
        message.success(t('Cập nhật thành công'));
        setNotificationSetting(resp.data);
    }

    const onSettingChange = async (settingItemType: string, settingType: string, enabled: boolean) => {
        let { notifications, platforms } = notificationSetting;

        if (settingType === 'notifications') {
            notifications = NOTIFICATION_SETTING_TYPES.map(item => {
                const _notification = notificationSetting.notifications.find(n => n.type === item.type);
                let _enabled = item.type === settingItemType ? enabled : Boolean(_notification?.enabled);

                return {
                    type: item.type,
                    enabled: _enabled
                }
            });
        }

        if (settingType === 'platforms') {
            if (!platforms.includes(settingItemType) && enabled) {
                platforms.push(settingItemType);
            }

            if (platforms.includes(settingItemType) && !enabled) {
                platforms = platforms.filter((p) => p !== settingItemType);
            }
        }

        await saveSetting({notifications, platforms})
    }

    useEffect(() => {
        fetchNotificationSetting();
    }, []);

    // if (!notifications.length || !platforms.length) {
    //     return <Spin />
    // }

    return <div id="NotificationCenter">
        <Row>
            <Col xs={24} md={8}>
                <Collapse defaultActiveKey={['receive-notification', 'receive-notification-way']}>
                    <Panel header={t('Loại thông báo muốn nhận')} key="receive-notification">
                        {NOTIFICATION_SETTING_TYPES.map(item => {
                            // let notification = _notificationSettings[notifItem.type];
                            let notification = notificationSetting.notifications.find(n => n.type === item.type);
                            let enabled = notification ? notification.enabled : false;

                            return <div className="flex justify-between items-center my-2" key={item.type}>
                                <span className="font-bold">{t(`${item.label}`)}</span>
                                <Switch
                                    size="default" checked={enabled}
                                    onChange={(checked) => onSettingChange(item.type, 'notifications', checked)}
                                />
                            </div>
                        })}
                    </Panel>
                    <Panel header={t('Thông báo qua')} key="receive-notification-way">
                        {PLATFORMS_SETTING.map(platformItem => {
                            let enable = platformItem ? notificationSetting.platforms.includes(platformItem.type) : false;

                            return <div className="flex justify-between items-center" key={platformItem.type}>
                                <span className="font-bold">{t(`${platformItem.label}`)}</span>
                                <Switch
                                    size="default" checked={enable}
                                    onChange={(checked) => onSettingChange(platformItem.type, 'platforms', checked)}
                                />
                            </div>
                        })}
                    </Panel>
                </Collapse>
            </Col>
        </Row>
    </div>
}

export default NotificationCenter;