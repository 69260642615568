import React, { useEffect, useState } from 'react';
import { Card, Select } from 'antd';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Cell, Tooltip } from "recharts";
import moment from 'moment';
import { useTranslation } from "react-i18next";

import animateGlobalState from '../../../../../../../../contexts/Animate';
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import theme from "../../../../../../../../themes/theme";
import { genChartPaddingBottom, roundedNumber } from '../../../../../../../../services/utils';
import { DATE_TIME_FORMAT } from '../../../../../../../../config';

type ForeignProps = {
    layoutWidth: number;
    layoutHeight: number;
    foreignData: any;
    fetchForeignData: Function;
};

const StockDetailForeign = (props: ForeignProps) => {
    const { layoutHeight, layoutWidth, foreignData, fetchForeignData } = props;
    const [period, setPeriod] = useState('daily');
    const { t } = useTranslation();
    const [animate,] = animateGlobalState();
    const [themeMode,] = themeModeGlobalState();

    const amountForeignValueKey = 'amountForeignValue';

    const ratio = 1000 * 1000 * 1000;

    const formatValue = (value) => {
        return roundedNumber(value/ratio, 2);
    }

    const renderTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-chart-tooltip">
                    <div className="text-black">
                        <div><strong>{moment(label).format(period === "monthly" ? DATE_TIME_FORMAT.MONTH : DATE_TIME_FORMAT.DATE)}</strong></div>
                        <div>{t('Giá trị mua')}: <strong className="text-green">{formatValue(payload[0]?.payload.foreignBuyValue)} {t('Tỷ VNĐ')}</strong></div>
                        <div>{t('Giá trị bán')}: <strong className="text-red">{formatValue(payload[0]?.payload.foreignSellValue)} {t('Tỷ VNĐ')}</strong></div>
                        <div>{t('Giá trị mua - bán ròng')}: <strong className={`text-${payload[0]?.value < 0 ? 'red' : 'green'}`}>{formatValue(payload[0]?.value)} {t('Tỷ VNĐ')}</strong></div>
                    </div>
                </div>
            )
        }

        return null;
    };

    useEffect(() => {
        fetchForeignData(period);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    return (
        <Card bordered={false} style={{ backgroundColor: theme.colors.chartBackground[themeMode] }} bodyStyle={{ height: layoutHeight }}>
            <div className="flex justify-between items-center">
                <div className="text-lg font-bold">{t('Giá trị nước ngoài mua - bán ròng')} (t{('Tỷ VNĐ')})</div>
                <Select
                    style={{width: 120}}
                    value={period}
                    size="middle"
                    onChange={(value) => setPeriod(value)}
                >
                    <Select.Option value="daily">{t('Theo ngày')}</Select.Option>
                    <Select.Option value="monthly">{t('Theo tháng')}</Select.Option>
                </Select>
            </div>

            <BarChart
                width={layoutWidth - 25}
                height={300}
                data={foreignData}
                margin={{top: 30, right: 0, left: 0, bottom: 0}}
            >
                <defs>
                    <linearGradient id={`stockForeignTrading`} x1="0" y1="0" x2="1" y2="1">
                        <stop offset={0.5} stopColor={theme.colors.green.background}/>
                        <stop offset={0.5} stopColor={theme.colors.red.background}/>
                    </linearGradient>
                </defs>

                <XAxis
                    dataKey={'date'}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0.1}
                    tick={{fontSize: theme.fontSizes.small}}
                    tickLine={false}
                    tickFormatter={(tickValue) => {
                        return period === "monthly" ? moment(tickValue).format(DATE_TIME_FORMAT.MONTH) : moment(tickValue).format('DD/MM')
                    }}
                />
                <YAxis
                    orientation="right"
                    tick={{fontSize: theme.fontSizes.small}}
                    tickLine={false}
                    tickFormatter={(tickValue) => formatValue(tickValue)}
                    stroke={theme.colors.chartAxis[themeMode]}
                    strokeWidth={0}
                    padding={{ bottom: genChartPaddingBottom(foreignData, amountForeignValueKey) }}
                />
                <CartesianGrid
                    vertical={false}
                    stroke={theme.colors.gray.background}
                    strokeWidth={0.1}
                />

                <Tooltip cursor={{ fill: theme.colors.blurWhite, opacity: 0.2 }} content={renderTooltip} />

                <Bar
                    connectNulls
                    isAnimationActive={animate}
                    key={amountForeignValueKey}
                    dataKey={amountForeignValueKey}
                    name={t('Giá trị ròng')}
                    fill={`url(#stockForeignTrading)`}
                    radius={theme.chart.bar.radius.vertical}
                >
                    {foreignData.map((entry, index) => <Cell
                        key={`cell-${index}`}
                        fill={entry.amountForeignValue > 0 ? theme.colors.green.background : theme.colors.red.background}
                    />)}
                </Bar>
            </BarChart>
        </Card>
    )
};

export default StockDetailForeign;