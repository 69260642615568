import React from 'react';
import { Card } from "antd";

import theme from "../../../../../../themes/theme";
import themeModeGlobalState from "../../../../../../contexts/ThemeMode";
import MainIndexChart from './components/MainIndexChart';

export const dataDefault: any = {}

type MainIndexContainerProps = {
    width: number;
    height: number;
    indexId: string;
    indexLabel?: string;
};

const MainIndexContainer = (props: MainIndexContainerProps) => {
    let { width, height, indexId, indexLabel } = props;
    const [themeMode,] = themeModeGlobalState();

    return (
        <Card
            className="main-index-card"
            size="small"
            bodyStyle={{
                padding: '0.5rem',
                backgroundColor: theme.colors.chartBackground[themeMode]
            }}
        >
            <MainIndexChart
                indexId={indexId}
                indexLabel={indexLabel}
                height={height}
                width={width}
            />
        </Card>
    )
};

export default MainIndexContainer;