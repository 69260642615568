import React, { useEffect, useState } from 'react';
import { Card, Table, Select, Space, DatePicker, Button } from 'antd';
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import moment from 'moment';

import * as stockDetailService from '../../services';
import * as config from '../../../../../../../../config';
import { useReducedState } from '../../../../../../../../services/utils';

type CorporateActionsProps = {
    symbol: string,
    height?: number,
}

const { Option } = Select;

const CorporationActions = (props: CorporateActionsProps) => {
    const { symbol, height } = props;

    const [ actions, setActions ] = useState<NSApp.CorporateActions[]>([]);
    const [ dateType, setDateType ] = useState<string>();
    const [ dateStart, setDateStart ] = useState<string>();
    const [ dateEnd, setDateEnd ] = useState<string>();
    const [ eventName, setEventName ] = useState<string>();
    const { t } = useTranslation();

    const [ pagination, setPagination ] = useReducedState({
        total: 0,
        current: 1,
        pageSize: config.PAGE_SIZE
    });

    const fetchCorporateActions = async () => {
        const filter = {
            dateType, dateStart, dateEnd, eventName
        };
        let [err, resp]: any = await stockDetailService.fetchCorporateActions(symbol, {
            ...filter, page: pagination.current, pageSize: config.PAGE_SIZE
        });

        // handle error
        if (err) {
            return;
        }

        const { data } = resp;
        const { actions, total } = data;

        setActions(actions);
        setPagination({ total });
    };

    const onClickSearchButton = async () => {
        await fetchCorporateActions();
    }

    useEffect(() => {
        fetchCorporateActions();
    }, [pagination.current, symbol]); // eslint-disable-line

    const columns: ColumnsType<NSApp.CorporateActions> = [
        {
            title: t('Loại sự kiện'),
            dataIndex: 'eventName',
            key: 'eventName',
            render: (value) => <div className="whitespace-normal" title={value}>
                <div className="text-one-line">{value}</div>
            </div>
        },
        {
            title: t('Ngày GDKHQ'),
            dataIndex: 'exDividendDate',
            render: (value) => {
                return <>{value && moment(value).format(config.DATE_TIME_FORMAT.DATE)}</>
            },
            key: 'exDividendDate',
        },
        {
            title: t('Ngày chốt'),
            dataIndex: 'closeDate',
            render: (value) => {
                return <>{value && moment(value).format(config.DATE_TIME_FORMAT.DATE)}</>
            },
            key: 'closeDate',
        },
        {
            title: t('Ngày thực hiện'),
            dataIndex: 'executionDate',
            render: (value) => {
                return <>{value && moment(value).format(config.DATE_TIME_FORMAT.DATE)}</>
            },
            key: 'executionDate',
        },
        {
            title: t('Nội dung'),
            dataIndex: 'title',
            key: 'title',
            render: (value) => <div className="whitespace-normal" title={value}>
                <div className="text-one-line">{value}</div>
            </div>
        },
        {
            title: t('Ngày công bố'),
            dataIndex: 'publishedDate',
            render: (value) => {
                return <>{value && moment(value).format(config.DATE_TIME_FORMAT.DATE)}</>
            },
            key: 'publishedDate',
        },
    ];

    const renderTableFilter = () => {
        return (
            <Space>
                <Select
                    style={{width: 150}}
                    placeholder={t('Loại ngày')}
                    onChange={(value: string) => {
                        setDateType(value);
                    }}
                    allowClear
                >
                    <Option value="exDividendDate">{t('Ngày GDKHQ')}</Option>
                    <Option value="publishedDate">{t('Ngày công bố')}</Option>
                </Select>

                <DatePicker
                    placeholder={t('Từ ngày')}
                    onChange={(date) => {
                        setDateStart(date ? moment(date).format('YYYY-MM-DD'): '');
                    }}
                    format={config.DATE_TIME_FORMAT.DATE}
                />
                <DatePicker
                    placeholder={t('Đến ngày')}
                    onChange={(date) => {
                        setDateEnd(date ? moment(date).format('YYYY-MM-DD'): '');
                    }}
                    format={config.DATE_TIME_FORMAT.DATE}
                />

                <Select
                    style={{width: 200}}
                    placeholder={t('Nhóm sự kiện')}
                    onChange={(value: string) => {
                        setEventName(value);
                    }}
                    allowClear
                >
                    <Option value="Đại hội Đồng Cổ đông">{t('Đại hội Đồng Cổ Đông')}</Option>
                    <Option value="Niêm yết">{t('Niêm yết')}</Option>
                    <Option value="GD nội bộ">{t('GD nội bộ')}</Option>
                    <Option value="Trả cổ tức bằng tiền mặt">{t('Trả cổ tức')}</Option>
                    <Option value="Kết quả kinh doanh">{t('Kết quả kinh doanh')}</Option>
                    <Option value="Sự kiện khác">{t('Sự kiện khác')}</Option>
                </Select>

                <Button onClick={onClickSearchButton}>{t('Tìm kiếm')}</Button>
            </Space>
        )
    }

    return (
        <Card bodyStyle={{ padding: 4 }} title={<strong>{t('Sự kiện')}</strong>} style={{height: height}}>
            <Table
                bordered
                dataSource={actions} columns={columns}
                rowKey={(r: NSApp.CorporateActions) => r?._id}
                pagination={{
                    onChange: (page) => {
                        setPagination({ current: page });
                    },
                    pageSize: config.PAGE_SIZE,
                    total: pagination.total,
                }}
                title={renderTableFilter}
            />
        </Card>
    )
}

export default CorporationActions;
