import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import UserRoleListPage from "./List";
import UserRoleEditPage from "./Edit";
import routes from "../../../../routes";

const UserRoleDashboard = () => {
    return <div>
        <Switch>
            <Route path={routes.ADMIN_USER_ROLES_LIST} component={UserRoleListPage} />
            <Route path={routes.ADMIN_USER_ROLES_EDIT} component={UserRoleEditPage} />

            <Route path={routes.ADMIN_USER_ROLES}>
                <Redirect to={routes.ADMIN_USER_ROLES_LIST} />
            </Route>
        </Switch>
    </div>
}

export default UserRoleDashboard;