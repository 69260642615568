import React from 'react';
import { Layout, Row, Col, Space, Avatar, Card, Typography } from 'antd';
import themeModeGlobalState from "../../../contexts/ThemeMode";
import LogoWhite from "../../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../../assets/image/logo/Fstock_black.svg";

const { Title, Paragraph } = Typography;

export default function PolicyVi() {
    const [themeMode,] = themeModeGlobalState();
    const logo = themeMode === 'dark' ? LogoWhite : LogoBlack;

    return <Layout className="auth-page">
        <Row justify="center" align="middle" className="h-full">
            <Col xs={22} sm={16} lg={10}>
                <Space className="brand flex justify-center mb-4" align="center">
                    <Avatar shape="square" size={128} src={logo} />
                </Space>
                <Card
                    title="Chính sách bảo mật"
                    headStyle={{ textAlign: 'center', fontWeight: 'bold' }}
                    bodyStyle={{ maxHeight: 500, overflow: 'auto' }}
                >
                    <Paragraph>
                        <strong>Fstock.vn</strong> đã xây dựng Trang Web và Ứng dụng (App) Fstock dưới dạng một Dịch vụ miễn phí. Trang thông tin này được dùng để thông báo cho người sử dụng dịch vụ hiểu về các chính sách của chúng tôi về việc thu thập, sử dụng và bảo mật thông tin cá nhân (nếu có) đối với bất cứ ai quyết định sử dụng dịch vụ của chúng tôi.
                    </Paragraph>
                    <Paragraph>
                        Nếu Bạn chọn cài đặt và sử dụng Trang Web hoặc Ứng dụng của chúng tôi có nghĩa là Bạn đồng ý với việc cho phép <strong>FStock</strong> thu thập và sử dụng thông tin liên quan đến chính sách bảo mật thông tin này. Thông tin cá nhân mà chúng tôi thu thập được chỉ sử dụng để cung cấp và cải thiện chất lượng Dịch vụ. Chúng tôi sẽ không sử dụng hoặc chia sẻ thông tin của Bạn với bất kỳ bên nào khác trừ khi điều đó được mô tả rõ trong Chính sách bảo mật này.
                    </Paragraph>
                    <Paragraph>
                        Các điều khoản được sử dụng trong Chính sách bảo mật này có cùng ý nghĩa như trong Điều khoản và Điều kiện của chúng tôi, và có thể truy cập tại trang Web FStock.VN trừ khi được nêu riêng trong Chính sách bảo mật này.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Thu thập và sử dụng thông tin</Title>
                        Để có trải nghiệm tốt hơn, trong khi sử dụng Dịch vụ của chúng tôi, chúng tôi có thể yêu cầu Bạn cung cấp cho chúng tôi thông tin nhận dạng cá nhân nhất định, bao gồm nhưng không giới hạn gồm Họ và Tên, Số điện thoại và Địa Chỉ Email. Thông tin mà chúng tôi yêu cầu sẽ được chúng tôi lưu giữ và sử dụng với mục đích như được mô tả trong Chính Sách Bảo Mật này. Trong một số trường hợp, Ứng dụng này có thể sử dụng dịch vụ của bên thứ ba và có thể thu thập thông tin để nhận dạng Bạn.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Dữ liệu đăng nhập</Title>
                        Chúng tôi muốn thông báo cho Bạn rằng bất cứ khi nào Bạn sử dụng Dịch vụ của chúng tôi, trong trường hợp xuất hiện lỗi trong ứng dụng, chúng tôi sẽ thu thập dữ liệu và thông tin (thông qua các sản phẩm của bên thứ ba) trên điện thoại của Bạn có tên là Dữ liệu nhật ký. Dữ liệu nhật ký này có thể bao gồm thông tin như địa chỉ Giao thức Internet (IP của thiết bị), tên thiết bị, phiên bản hệ điều hành, cấu hình của ứng dụng khi sử dụng Dịch vụ của chúng tôi, thời gian và ngày sử dụng Dịch vụ của Bạn và các thống kê khác.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Cookies</Title>
                        Cookies là các tệp có một lượng nhỏ dữ liệu thường được sử dụng làm định danh duy nhất ẩn danh. Chúng được gửi đến trình duyệt của Bạn từ các trang web Bạn truy cập và được lưu trên bộ nhớ trong của thiết bị. Dịch vụ này không sử dụng các cookie một cách rõ ràng. Tuy nhiên, ứng dụng có thể sử dụng mã và thư viện của bên thứ ba để dùng cookie thu thập thông tin và cải thiện dịch vụ của họ. Bạn có tùy chọn chấp nhận hoặc từ chối các cookie này và biết khi nào cookie được gửi đến thiết bị của Bạn. Nếu Bạn chọn từ chối cookie của chúng tôi, Bạn không thể sử dụng một số phần của Dịch vụ này.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Các nhà cung cấp dịch vụ</Title>
                        Chúng tôi có thể sử dụng các bên công ty và cá nhân thứ ba vì những lý do sau:
                        <ul>
                            <li>Để tạo sự thuận tiện cho Dịch vụ của chúng tôi</li>
                            <li>Để thay chúng tôi cung cấp Dịch vụ</li>
                            <li>Để thực hiện các dịch vụ liên quan đến Dịch vụ</li>
                            <li>Để hỗ trợ chúng tôi phân tích cách thức Dịch vụ của chúng tôi được sử dụng</li>
                        </ul>
                        Chúng tôi muốn thông báo cho người dùng Dịch vụ này rằng các bên thứ ba này có quyền truy cập vào Thông tin cá nhân của Bạn. Lý do là để thay mặt chúng tôi thực hiện các nhiệm vụ được giao cho họ. Tuy nhiên, họ có nghĩa vụ không tiết lộ hoặc không sử dụng thông tin cho bất kỳ mục đích nào khác.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>An ninh</Title>
                        Chúng tôi trân trọng sự tin tưởng của Bạn trong việc cung cấp cho chúng tôi Thông tin cá nhân của Bạn, do đó chúng tôi đang cố gắng sử dụng các phương tiện bảo vệ thương mại được chấp nhận về mặt thương mại. Tuy nhiên không có phương thức truyền qua internet, hoặc phương pháp lưu trữ điện tử nào an toàn và đáng tin cậy 100%, và chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của nó.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Liên kết đến các trang web khác</Title>
                        Dịch vụ này có thể chứa các liên kết đến các trang web khác. Nếu Bạn nhấp vào liên kết của bên thứ ba, Bạn sẽ được chuyển đến trang web đó. Lưu ý rằng các trang web bên ngoài không được vận hành bởi chúng tôi. Do đó, chúng tôi khuyên Bạn nên xem lại Chính sách bảo mật của các trang web này. Chúng tôi không kiểm soát và không chịu trách nhiệm về nội dung, chính sách bảo mật hoặc thực tiễn của bất kỳ trang web hoặc dịch vụ của bên thứ ba nào.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Quyền riêng tư của trẻ em</Title>
                        Các Dịch vụ này không cung cấp cho bất kỳ ai dưới 13 tuổi. Chúng tôi không cố ý thu thập thông tin nhận dạng cá nhân từ trẻ em dưới 13 tuổi. Nếu Bạn là cha mẹ hoặc người giám hộ và Bạn biết rằng con Bạn đã cung cấp cho chúng tôi thông tin cá nhân, vui lòng liên hệ với chúng tôi để chúng tôi có thể thực hiện các hành động cần thiết.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Thay đổi chính sách bảo mật này</Title>
                        Chúng tôi có thể cập nhật Chính sách bảo mật của chúng tôi theo thời gian. Vì vậy, Bạn nên xem lại trang này định kỳ để cập nhật các nội thay đổi. Chúng tôi sẽ thông báo cho Bạn về bất kỳ thay đổi nào bằng cách đăng Chính sách bảo mật mới trên trang này. Những thay đổi này có hiệu lực ngay sau khi chúng được đăng trên trang này.
                    </Paragraph>
                    <Paragraph>
                        <Title level={5}>Liên hệ chúng tôi</Title>
                        Nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào về Chính sách bảo mật của chúng tôi, đừng ngần ngại liên hệ với chúng tôi theo thông tin mục Liên hệ.
                    </Paragraph>
                </Card>
            </Col>
        </Row>
    </Layout>
};