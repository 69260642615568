import React, { useEffect, useState } from 'react';

import { Link } from "react-router-dom";
import { Card, Table, Button, Space, Popconfirm, Tag, notification, Input, Select, Tooltip } from "antd";
import { useReducedState, toRoute } from "../../../../services/utils";

import api from "../../../../services/api";
import routes from "../../../../routes";
import { UserRoleType } from '../../../../types/modules/permission';
import * as config from "../../../../config";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import Loading from "../../../../components/Loading";
import AdminPermission from '../../../../components/AdminPermission';
import { FilterOutlined } from '@ant-design/icons';

const UserListPageComponent = () => {
    const [userList, setUserList] = useState<Array<NSApp.User>>();
    const [searchKey, setSearchKey] = useState<String>('');
    // const [filter, setFilter]=useState<any>({isSupervisor: false});
    const [ pagination, setPagination ] = useReducedState({
        current: 1,
        pageSize: config.PAGE_SIZE,
        total: 0
    });
    const [planKey, setPlanKey] = useState<String>()

    const plans = [
        {
            label: 'Cơ bản',
            value: 'basic',
        },
        {
            label: 'Nâng cao',
            value: 'advanced',
        },
        {
            label: 'Chuyên nghiệp',
            value: 'professional',
        }
    ]

    const fetchUsers = async (filter={isSupervisor: false}) => {
        let [err, resp]: any = await api.get('/user', {
            filter: filter,
            page: pagination.current, pageSize: config.PAGE_SIZE,
            planKey,
        });

        if (err) return;
        let {users, total} = resp.data;

        setUserList(users);
        setPagination({total});
    };

    const requestResendActiveMail = async (user: NSApp.User) => {
        let [err, resp]: any = await api.post('/user/resend-user-mail-active', {
            email: user.email,
            fullname: user.fullname
        });

        if (err) {
            notification.error({
                message: `Gửi mail kích hoạt tài khoàn ${user.email} không thành công`,
                description: JSON.stringify(err.response.data),
            });
            return;
        }

        notification.success({
            message: `Gửi mail kích hoạt tài khoàn ${user.email} thành công`,
            description: JSON.stringify(resp.data),
        });
    }

    const searchOnClick = async () => {
        let filter: any  = {
            isSupervisor: false
        }
        if(searchKey) {
            filter.email = searchKey
        }
        fetchUsers(filter);
        setPagination({current : 1})
    }

    const filterModeratorOnClick = async () => {
        let filter: any  = {
            isModerator: true
        }
        fetchUsers(filter);
        setPagination({current : 1})
    }

    useEffect(() => {
        fetchUsers();
    }, [pagination.current]); // eslint-disable-line

    const columns: any = [
        {
            title: 'Email',
            textWrap: 'word-break',
            render: (user: NSApp.User) => <Link to={toRoute(routes.ADMIN_USER_DETAIL, { userId: user.id })}>
                <Tooltip placement="top" title={user.email}>
                    <span>{user.email}</span>
                </Tooltip>
            </Link>
        },
        {
            title: 'Họ và Tên',
            textWrap: 'word-break',
            dataIndex: 'fullname',
            render: (fullname: string) => <span>{fullname}</span>
        },
        {
            title: 'SĐT',
            textWrap: 'word-break',
            dataIndex: 'phone',
            render: (phone: string) => <span>{phone}</span>
        },
        {
            title: 'Gói hiện tại',
            textWarp: 'word-break',
            dataIndex: 'currentUserPLan',
            render: (plan: string) => <span>{plan}</span>
        },
        {
            title: 'Role',
            textWrap: 'word-break',
            dataIndex: 'role',
            render: (role: UserRoleType) => <span>{role?.name}</span>
        },
        {
            title: 'Trạng thái',
            textWrap: 'word-break',
            dataIndex: 'actived',
            render: (actived: boolean) => <span>{actived ? <Tag color="green">Đã kích hoạt</Tag>: <Tag color="red">Chưa kích hoạt</Tag>}</span>
        },
        {
            title: 'Action',
            render: (user: NSApp.User) => {

                return <Space>
                    <Link to={toRoute(routes.ADMIN_USER_EDIT, { userId: user.id })}>
                        <Button type="primary">Chỉnh sửa</Button>
                    </Link>
                    <Popconfirm
                        title="Bạn có chắc chắn muốn gửi mail kích hoạt cho tài khoản này?"
                        onConfirm={() => requestResendActiveMail(user)}
                        okText="Có"
                        cancelText="Không"
                    >
                    <Button type="primary" danger>Gửi lại mail active</Button>
                    </Popconfirm>
                </Space>
            }
        }
    ];

    const handleChangePlanKey = (value) => {
        setPlanKey(value)
    }

    if (!userList) return <Loading/>

    return (
        <Card>
            <MyBreadcrumd items={[{ label: 'Người dùng' }]} />
            <div className="m-5 container mx-auto">
                <Space>
                    <Input 
                        allowClear placeholder="Email" 
                        onChange={(e: any) => {
                            setSearchKey(e.target.value?.trim())
                        }}
                    />

                    <Select
                        onChange={handleChangePlanKey}
                        defaultValue=''
                    >
                        <Select.Option className="text-white" key="" value="">Tất cả các gói</Select.Option>
                        {plans.map(plan => {
                            return <Select.Option className="text-white" key={plan.value} value={plan.value}>{plan.label}</Select.Option>
                        })}
                    </Select>

                    <Button onClick={searchOnClick} type='primary'>Tìm kiếm</Button>

                    <Button className='mx-10' onClick={filterModeratorOnClick} icon={<FilterOutlined />}> Nhóm quản trị viên</Button>
                </Space>
                <div className="my-2">Tổng số người dùng: {pagination.total} </div>
            </div>

            <div className="mt-2">
                
                <Table
                    columns={columns}
                    dataSource={userList}
                    rowKey={(r: NSApp.User) => r.id}
                    pagination={{
                        onChange: page => {
                            setPagination({ current: page });
                        },
                        ...pagination
                    }}
                    onChange={(r:any) => console.log(r)}
                />
            </div>
        </Card>
    )
}

const UserListPage = () => {
    return  <AdminPermission roleName={[]}>
        <UserListPageComponent />
    </AdminPermission>
}

export default UserListPage;