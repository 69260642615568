import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";

const { confirm } = Modal;

type FeatureFormProps = {
    initialValues?: any;
    onFinish: (values: any) => void;
    mode?: "add" | "edit";
};

const FormFeature = (props: FeatureFormProps) => {
    const { initialValues, mode, onFinish } = props;


    const showConfirmSubmit = (formValues: any) => {
        confirm({
            title: `Bạn có chắc chắn muốn ${
                props.mode === "add" ? "thêm" : "chỉnh sửa"
            } tính năng này không?`,
            icon: <ExclamationCircleOutlined />,
            content: formValues.title,
            onOk: async () => {
                onFinish(formValues);
            },
            onCancel() {
                console.log("Hủy");
            },
        });
    };

    return <div>
        <Card>
        <Form
            layout="vertical"
            className="m-auto mt-5 w-3/6"
            title="Chỉnh sửa thông tin tính năng"
            onFinish={showConfirmSubmit}
            initialValues={initialValues}
        >
            <Form.Item
                name="id"
                label="ID"
            >
                <Input
                    type="number"
                    placeholder="Nhập ID"
                />
            </Form.Item>

            <Form.Item
                name="name"
                label="Name"
            >
                <TextArea
                    placeholder="Nhập tên tính năng"
                    rows={3}
                />
            </Form.Item>

            <Form.Item
                name="code"
                label="Code"
            >
                <Input
                    placeholder="Nhập code"
                />
            </Form.Item>

            <Form.Item
                name="group"
                label="Group"
            >
                <TextArea
                    placeholder="Tên nhóm chức năng"
                    rows={2}
                />
            </Form.Item>

            <Button
                    className="mb-2 float-right"
                    type="primary"
                    htmlType="submit"
                >
                    {mode === "edit" ? "Chỉnh sửa" : "Thêm mới"}
            </Button>
            
        </Form>
        </Card>
    </div>
}

export default FormFeature;