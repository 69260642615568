import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, Card, List, Radio } from "antd";
import { useContextSelector } from 'use-context-selector';
import { useTranslation } from "react-i18next";

import { StockContext } from  '../../../../../../../../contexts/StocksProvider';
import ChartCard from "./ChartCard";
import Skeleton from "../../../../../../../../components/Skeleton";
import { subscribeNewMatchedStockOrder } from "../../../../../../../../services/socket";
import { localeFormat } from "../../../../../../../../services/stock-price";
import * as StockDetailServices from '../../services';
import * as config from "../../../../../../../../config";
import { GenCharts } from "./utils";

type EconomicInfoProps = {
    layoutWidth: number;
    layoutHeight: number;
    symbol: string;
    financialBasic: NSApp.FinancialBasic,
    financialReportsYearly: NSApp.FinancialReportsYearly[],
    financialReportsQuarterly: NSApp.FinancialReportsYearly[],
    companyProfile: NSApp.CompanyProfile | undefined,
    onChange: (symbol: string) => void,
};

const EconomicInfo = (props: EconomicInfoProps) => {
    let {
        layoutWidth,
        layoutHeight,
        symbol,
        financialBasic,
        financialReportsYearly,
        financialReportsQuarterly,
        companyProfile
    } = props;

    const { t } = useTranslation();
    const [ filterBy, setFilterBy ] = useState('year');
    const [ financialReports, setFinancialReports ] = useState(financialReportsYearly);
    const [ stockDeals, setStockDeals ] = useState<NSApp.MatchedStockOrder[]>([]);
    const [ newMatchedStockOrders, setNewMatchedStockOrders ] = useState<NSApp.MatchedStockOrder[]>([]);

    // Check Stock is bank
    const isBank = companyProfile?.industryCode === '8355';

    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][symbol],
    );

    const fetchStockDealsList = async (symbol: string) => {
        // eslint-disable-next-line
        let [err, resp] = await StockDetailServices.fetchStockDeals(symbol)

        if (err) {
            return;
        }

        const { stockDeals } = resp;

        setStockDeals(stockDeals);
    };

    const initSocket = useCallback(() => {
        subscribeNewMatchedStockOrder((matchedOrders: NSApp.MatchedStockOrder[]) => {
            let matchedOrdersFiltered = matchedOrders.filter(matchedOrder => matchedOrder.stockCode === symbol);
            if (matchedOrdersFiltered.length) {
                setNewMatchedStockOrders(matchedOrdersFiltered);
            }
        })
    }, [symbol]);

    useEffect(() => {
        if (filterBy === "quarter") setFinancialReports(financialReportsQuarterly);
        if (filterBy === "year") setFinancialReports(financialReportsYearly);
    }, [filterBy, financialReportsQuarterly, financialReportsYearly]); // eslint-disable-line


    useEffect(() => {
        fetchStockDealsList(symbol);
    }, [symbol]);

    useEffect(() => {
        initSocket();
    }, [initSocket]);

    useEffect(() => {
        if (newMatchedStockOrders.length) {
            let newStockDeals = [...newMatchedStockOrders, ...stockDeals];
            // newStockDeals = _.uniqBy(newStockDeals, 'totalVolume');

            setStockDeals(newStockDeals);
        }
    }, [newMatchedStockOrders]); // eslint-disable-line

    const infoData = [
        { title: t('KLGD'), value: stockState.totalVolume },
        { title: `${t('GTGD')} (${t('Tỷ VNĐ')})`, value: stockState.totalValue / (1000 * 1000 * 1000) },
        { title: t('KLTB3T'), value: financialBasic.volumeaveragethreemonth, unit: '' },
        { title: `${t('GTTB3T')} (${t('Tỷ VNĐ')})`, value: financialBasic.valueaveragethreemonth  / (1000 * 1000 * 1000), unit: '' },
        { title: t('P/E'), value: financialBasic.pe },
        { title: t('P/B'), value: financialBasic.pb },
        { title: t('ROA'), value: financialBasic.roa * 100, unit: '%' },
        { title: t('ROE'), value: financialBasic.roe * 100, unit: '%' },
        { title: `${t('Giá trị sổ sách/CP')} (${t('VNĐ')})`, value: financialBasic.bookvalue },
        { title: t('EPS'), value: financialBasic.eps, unit: '' },
        { title: t('SLCP lưu hành'), value: financialBasic.sharesoutstanding },
        { title: `${t('Vốn hoá')} (${t('Tỷ VNĐ')})`, value: financialBasic.marketcap / (1000 * 1000 * 1000) },
        { title: t('SH Nước ngoài'), value: financialBasic.foreignpercent * 100, unit: '%' },
        { title: t('Room NN còn lại'), value: financialBasic.foreignvolumeroom },
    ];

    let staticChildComponentWidth = layoutWidth > config.RESPONSIVE_SCREEN.lg ? (layoutWidth - 100) / 4 - 10 : (layoutWidth - 100) / 2 - 10;
    let dynamicChildComponentWidth = layoutWidth > config.RESPONSIVE_SCREEN.lg ? (layoutWidth - 100) / 3 - 10 : (layoutWidth - 100) / 2 - 10;
    let staticChildComponentHeight = staticChildComponentWidth * 9 / 16;
    let dynamicChildComponentHeight = dynamicChildComponentWidth * 9 / 16;
    let chartItemColSpan = layoutWidth > config.RESPONSIVE_SCREEN.lg ? 8 : 12;

    const onChangeFilter = (e) => {
        setFilterBy(e.target.value);
    };

    const renderInfo = (item) => {
        let value = item.value ? localeFormat(item.value) : '-';
        let unit = item.value && item.unit ? item.unit : "";

        return <List.Item>
            <List.Item.Meta title={item.title}/>
            <div className="value">
                {value}{unit}
            </div>
        </List.Item>
    };

    return (
        <div className="overflow-y-auto" style={{height: layoutHeight - 37}}>
            <div className="flex justify-end items-center">
                <Radio.Group className="mr-4" value={filterBy} onChange={onChangeFilter} buttonStyle="solid">
                    <Radio.Button value="year">{t('Theo Năm')}</Radio.Button>
                    <Radio.Button value="quarter">{t('Theo Quý')}</Radio.Button>
                </Radio.Group>
            </div>
            <Card className="my-1">
                <Row gutter={10}>
                    <Col span={layoutWidth > config.RESPONSIVE_SCREEN.lg ? 12 : 24}>
                        {!financialBasic ? <Skeleton/>
                        : <>
                            <div className={`mb-4 px-3 ${layoutWidth < 1400 && 'flex justify-between items-center'}`}>
                                <div className="text-3xl font-bold uppercase leading-tight">
                                    {companyProfile?.stockCode}: {companyProfile?.exchange}
                                </div>
                                <div>
                                    <div className="text-sm font-bold">{companyProfile?.name}</div>
                                    <div>{t('Ngành')}: {companyProfile?.industryNameL4?.slice(0, -3)}</div>
                                </div>
                            </div>
                            <List
                                size="small"
                                className="list-grid-item-horizontal"
                                grid={{ gutter: 4, column: 2 }}
                                dataSource={infoData}
                                renderItem={renderInfo}
                            />
                        </>}
                    </Col>
                    <Col span={layoutWidth > config.RESPONSIVE_SCREEN.lg ? 12 : 24}>
                        <Row gutter={8}>
                            {GenCharts({ isBank, filterBy }).slice(0, 2).map((item) => {
                                return <Col span={12} key={item?.title}>
                                    <ChartCard
                                        title={item.title}
                                        width={staticChildComponentWidth}
                                        height={staticChildComponentHeight + 30}
                                        data={financialReports}
                                        filterBy={filterBy}
                                        chartType={item.chartType}
                                        chartData={item.chartData}
                                        YAxisLabel={item.YAxisLabel}
                                        YAxisUnit={item.YAxisUnit}
                                        secondYAxis={item.secondYAxis}
                                        secondYAxisLabel={item.secondYAxisLabel}
                                        secondYAxisUnit={item.secondYAxisUnit}
                                        useCustomXAxisLabel={item.useCustomXAxisLabel}
                                    />
                                </Col>
                            })}
                        </Row>
                    </Col>
                </Row>
            </Card>

            <Card className="my-2">
                {financialReports ?
                    <Row gutter={[10, 10]} style={{marginBottom: '0 !important'}}>
                        {GenCharts({ isBank, filterBy }).slice(2).map((item) => {
                            return <Col span={chartItemColSpan} key={item?.title}>
                                <ChartCard
                                    title={item.title}
                                    width={dynamicChildComponentWidth}
                                    height={dynamicChildComponentHeight}
                                    data={financialReports}
                                    filterBy={filterBy}
                                    chartType={item.chartType}
                                    chartData={item.chartData}
                                    YAxisLabel={item.YAxisLabel}
                                    YAxisUnit={item.YAxisUnit}
                                    secondYAxis={item.secondYAxis}
                                    secondYAxisLabel={item.secondYAxisLabel}
                                    secondYAxisUnit={item.secondYAxisUnit}
                                    useCustomXAxisLabel={item.useCustomXAxisLabel}
                                />
                            </Col>
                        })}
                    </Row>
                : <Skeleton column={4} />}
            </Card>
        </div>
    )
};

export default EconomicInfo;
