import React, { useState, useEffect, useCallback, useRef } from "react";
import { Card, Table, Row, Col, Radio } from "antd";
import { ColumnsType } from "antd/lib/table";
import _ from 'lodash';
import { useTranslation } from "react-i18next";

import { convertStringToDateTime, getStockColorClass, formatNumberWithComma, roundedNumber } from "../../../../../../../../services/utils";
import { priceFormat, priceChangePercentFormat } from "../../../../../../../../services/stock-price";
import { useContextSelector } from 'use-context-selector';
import { StockContext } from '../../../../../../../../contexts/StocksProvider';
import { StockForeignerContext } from '../../../../../../../../contexts/StocksForeignerProvider';
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import * as StockDetailServices from '../../services';

import * as config from '../../../../../../../../config';
import theme from "../../../../../../../../themes/theme";
import colors from "../../../../../../../../themes/colors";

import { subscribeNewMatchedStockOrder } from "../../../../../../../../services/socket";

import VirtualTable from '../../../../../../../../components/VirtualTable';
import Skeleton from "../../../../../../../../components/Skeleton";
import fontSizes from "../../../../../../../../themes/fontsizes";
import CustomEmpty from "../../../../../../../../components/CustomEmpty";

type SalesInfoProps = {
    width: number;
    height: number;
    stockSymbol: string;
    stockType: string;
};

const SalesInfo = React.memo((props: SalesInfoProps) => {
    const { height, stockSymbol, stockType } = props;

    const [loading, setLoading] = useState(false);
    const [stockDeals, setStockDeals] = useState<NSApp.MatchedStockOrder[]>([]);
    const [newMatchedStockOrders, setNewMatchedStockOrders] = useState<NSApp.MatchedStockOrder[]>([]);
    const [viewType, setViewType] = useState('price_depth');
    const matchedOrdersSocketRef = useRef<any>();
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const stockDetail = useContextSelector(
        StockContext,
        (s) => s[0][stockSymbol],
    );

    const stockForeignerState = useContextSelector(
        StockForeignerContext,
        (s) => s[0][stockSymbol],
    );

    let uniqueId = 0;
    let priceRatio = stockType === 'derivative' ? 1 : config.PRICE_RATIO;
    let priceFixed = stockType === 'derivative' ? 1 : 2;


    const fetchStockDealsList = async (symbol: string) => {
        setLoading(true);
        // eslint-disable-next-line
        let [err, resp] = await StockDetailServices.fetchStockDeals(symbol);
        setLoading(false);

        if (err) {
            return;
        }

        const { stockDeals } = resp;

        setStockDeals(stockDeals);
    };

    useEffect(() => {
        fetchStockDealsList(stockSymbol);
    }, [stockSymbol]);

    const initMatchedOrderSocket = useCallback(() => {
        subscribeNewMatchedStockOrder((matchedOrders: NSApp.MatchedStockOrder[]) => {
            let matchedOrdersFiltered = matchedOrders.filter(matchedOrder => matchedOrder.stockCode === stockSymbol);
            if (matchedOrdersFiltered.length) {
                setNewMatchedStockOrders(matchedOrdersFiltered);
            }
        }).then((socket) => matchedOrdersSocketRef.current = socket);
    }, [stockSymbol]);

    useEffect(() => {
        initMatchedOrderSocket();
    }, [initMatchedOrderSocket]);

    useEffect(() => {
        if (newMatchedStockOrders.length) {
            let newStockDeals = [...newMatchedStockOrders, ...stockDeals];
            setStockDeals(newStockDeals);
        }
    }, [newMatchedStockOrders]); // eslint-disable-line

    // useUnmount(() => {
    //     if (matchedOrdersSocketRef.current) {
    //         matchedOrdersSocketRef.current.close();
    //     }
    // });

    const salesColumns: ColumnsType<NSApp.Stock> = [
        {
            title: <div className="text-one-line text-center font-bold" title={t('Khối lượng mua')}>{t('KL Mua')}</div>,
            dataIndex: 'BestBidVol',
            key: 'BestBidVol',
            align: 'center',
            render: (value) => <div className="text-one-line font-bold">{formatNumberWithComma(value)}</div>
        },
        {
            title: <div className="text-one-line text-center font-bold" title={t('Giá mua')}>{t('Giá Mua')}</div>,
            dataIndex: 'BestBid',
            key: 'BestBid',
            align: 'right',
            className: 'table-price-column-wrapper left',
            render: (value, record) => renderPriceChartColumn(value, record, "left")
        },
        {
            title: <div className="text-one-line text-center font-bold" title={t('Giá bán')}>{t('Giá Bán')}</div>,
            dataIndex: 'BestOffer',
            key: 'BestOffer',
            className: 'table-price-column-wrapper right',
            render: (value, record) => renderPriceChartColumn(value, record, "right")
        },
        {
            title: <div className="text-one-line text-center font-bold" title={t('Khối lượng bán')}>{t('KL Bán')}</div>,
            dataIndex: 'BestOfferVol',
            key: 'BestOfferVol',
            align: 'center',
            render: (value) => <div className="text-one-line font-bold">{formatNumberWithComma(value)}</div>
        }
    ];

    const historyColumns: ColumnsType<any> = [
        {
            title: <div className="text-one-line text-center font-bold" title={t('Thời gian')}>{t('TG')}</div>,
            dataIndex: 'date',
            key: 'date',
            align: 'center',
            render: (date) => <span>
                {convertStringToDateTime(date)}
            </span>
        },
        {
            title: <div className="text-one-line text-center font-bold" title={t('Giá')}>{t('Giá')}</div>,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (price) => {
                if (!stockDetail) {
                    return <></>
                }

                return <span style={{color: getStockColorClass(stockDetail, themeMode, price)}}>{priceFormat(price, priceRatio, priceFixed)}</span>
            }
        },
        {
            title: <div className="text-one-line text-center font-bold" title={t('Khối lượng')}>{t('KL')}</div>,
            dataIndex: 'volume',
            key: 'volume',
            align: 'right',
            render: (volume) => <span className="mr-2">{formatNumberWithComma(volume)}</span>
        },
        // {
        //     title: <div className="text-one-line text-center" title="Khối lượng tích luỹ">KL tích luỹ</div>,
        //     dataIndex: 'totalVolume',
        //     key: 'totalVolume',
        //     align: 'right',
        //     render: (totalVolume) => <span>{formatNumberWithComma(totalVolume)}</span>
        // }
    ];

    if (loading) {
        return <Card bordered={false} style={{ height, background: theme.colors.chartBackground[themeMode] }}><Skeleton/></Card>
    }

    if (!stockDetail) {
        return <Card bordered={false} style={{ height, background: theme.colors.chartBackground[themeMode] }}><CustomEmpty height={height} /></Card>
    }

    let bestPrices: any[] = [];
    let bestVol = 0;

    for (let i = 1; i < 11; i++) {
        if ( (stockDetail[`best${i}Bid`] && stockDetail[`best${i}BidVol`]) || (stockDetail[`best${i}Offer`] && stockDetail[`best${i}OfferVol`]) ) {
            bestPrices.push({
                BestBid: stockDetail[`best${i}Bid`],
                BestBidVol: stockDetail[`best${i}BidVol`],
                BestOffer: stockDetail[`best${i}Offer`],
                BestOfferVol: stockDetail[`best${i}OfferVol`],
            })
        }
    }

    if (bestPrices.length) {
        bestPrices.map((price) => {
            const _bestVol = Math.max(price.BestBidVol, price.BestOfferVol);
            if (bestVol < _bestVol) {
                bestVol = _bestVol;
            }
            return bestVol;
        })
    }

    const renderPriceChartColumn = (value, record, type) => {
        let leftColumn = type === 'left';
        let barChartClass = leftColumn ? 'table-price-column left' : 'table-price-column right';
        let valueCompare = leftColumn ? record?.BestBid : record?.BestOffer;
        let percent = leftColumn ? record?.BestBidVol / bestVol : record?.BestOfferVol / bestVol;
        let priceHasVol = leftColumn ? record?.BestBidVol : record?.BestOfferVol;

        if (!priceHasVol) {
            return <></>;
        }

        return (
            <div className={barChartClass}>
                <div className="bar" style={{width: `${percent * 100}%`, backgroundColor: getStockColorClass(stockDetail, themeMode, value), opacity: .2}}></div>
                <span style={{color: getStockColorClass(stockDetail, themeMode, valueCompare)}}>{priceFormat(value, priceRatio)}</span>
            </div>
        )
    };

    const renderBestPricesVolumeChart = (_bestPrices: any) => {

        const buyTotalVolume = _.sumBy(bestPrices, (b) => b.BestBidVol);
        const sellTotalVolume = _.sumBy(bestPrices, (b) => b.BestOfferVol);
        const totalVolume = buyTotalVolume + sellTotalVolume;

        const buyVolumeRatio = (buyTotalVolume / totalVolume) * 100;

        if (!totalVolume) {
            return <></>;
        }

        return (
            <div className="relative mt-4">
                <div className="h-6 flex">
                    <div style={{ width: `${buyVolumeRatio}%`, backgroundColor: colors.green.background }} />
                    <div style={{ width: `${100 - buyVolumeRatio}%`, backgroundColor: colors.red.background }} />
                </div>
                <div className="absolute top-0 w-full h-6 flex justify-between items-center px-2">
                    <span className="text-white font-bold">{buyTotalVolume.toLocaleString()}</span>
                    <span className="text-white font-bold">{sellTotalVolume.toLocaleString()}</span>
                </div>
            </div>
        )
    };

    const renderCardTitle = (
        <>
            <div className="flex justify-between">
                <span className="text-lg font-bold uppercase">{stockSymbol}</span>
                <span className="text-lg font-bold" style={{color: getStockColorClass(stockDetail, themeMode)}}>
                    {priceFormat(stockDetail.totalVolume ? stockDetail.matchedPrice : stockDetail.referencePrice, priceRatio)}
                </span>
            </div>

            <div className="flex justify-between mb-4">
                <span className="text-gray">{t('KL')} <span className={`text-${themeMode === 'dark' ? 'white' : 'black'}`}>{formatNumberWithComma(stockDetail.totalVolume)}</span></span>
                <span className="font-bold" style={{color: getStockColorClass(stockDetail, themeMode)}}>
                    {stockDetail.priceChange > 0 && '+'}{priceFormat(stockDetail.priceChange, priceRatio)}{' '}
                    ({stockDetail.priceChange > 0 && '+'}{priceChangePercentFormat(stockDetail.pricePercentChange)}%)
                </span>
            </div>

            <Row>
                <Col span={10} className="text-xs text-gray">{t('TC')} <span className="font-bold text-yellow">{priceFormat(stockDetail.referencePrice, priceRatio)} {stockDetail[`best10OfferVol`]}</span></Col>
                <Col span={8} className="text-xs text-gray">{t('Trần')} <span className="font-bold text-purple">{priceFormat(stockDetail.ceilingPrice, priceRatio)}</span></Col>
                <Col span={6} className="text-xs text-gray text-right">{t('Sàn')} <span className="font-bold text-teal">{priceFormat(stockDetail.floorPrice, priceRatio)}</span></Col>
            </Row>

            <Row>
                <Col span={10} className="text-xs text-gray">
                    {t('TB')} <span className="font-bold" style={{color: getStockColorClass(stockDetail, themeMode, stockDetail.averagePrice)}}>{priceFormat(stockDetail.averagePrice, priceRatio)}</span>
                </Col>
                <Col span={8} className="text-xs text-gray">
                    {t('Cao')} <span className="font-bold" style={{color: getStockColorClass(stockDetail, themeMode, stockDetail.highestPrice)}}>{priceFormat(stockDetail.highestPrice, priceRatio)}</span>
                </Col>
                <Col span={6} className="text-xs text-gray text-right">
                    {t('Thấp')} <span className="font-bold" style={{color: getStockColorClass(stockDetail, themeMode, stockDetail.lowestPrice)}}>{priceFormat(stockDetail.lowestPrice, priceRatio)}</span>
                </Col>
            </Row>
        </>
    );

    const radioButtonStyles = {
        width: `${100/3}%`,
        height: 25,
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: fontSizes.small,
    };

    const genColorClass = (value) => {
        let colorClass = 'white';
        if (value > 0) {
            return 'green';
        }
        if (value < 0) {
            return 'red';
        }
        return colorClass;
    };

    const formatValue = (value) => {
        return roundedNumber(value/ (1000 * 1000 * 1000), 2);
    };


    return (
        <Card
            size="small"
            bordered={false}
            title={renderCardTitle}
            style={{height, background: theme.colors.chartBackground[themeMode] }}
            bodyStyle={{marginTop: 1, padding: 0 }}
        >
            <div className="m-2">
                <Radio.Group
                    className="w-full custom-radio"
                    defaultValue="price_depth"
                    buttonStyle="solid"
                    onChange={(e) => setViewType(e.target.value)}
                >
                    <Radio.Button style={radioButtonStyles} value="price_depth">{t('Dư mua/Dư bán')}</Radio.Button>
                    <Radio.Button style={radioButtonStyles} value="price_history">{t('Khớp lệnh')}</Radio.Button>
                    <Radio.Button style={radioButtonStyles} value="foreign">{t('Thống kê')}</Radio.Button>
                </Radio.Group>
            </div>

            {viewType === 'price_depth' && (
                <>
                    <Table
                        className="table-price-depth"
                        pagination={false}
                        dataSource={bestPrices}
                        columns={salesColumns}
                        rowKey={(record:any) => {
                            if (!record.__uniqueId) record.__uniqueId = ++uniqueId;
                            return record.__uniqueId;
                        }}
                    />
                    {renderBestPricesVolumeChart(bestPrices)}
                    </>
            )}

            {viewType === 'price_history' && (
                <div className="table-price-history table-custom-background">
                    <VirtualTable
                        bordered
                        pagination={false}
                        dataSource={stockDeals}
                        columns={historyColumns}
                        rowKey={(record:any) => {
                            if (!record.__uniqueId) record.__uniqueId = ++uniqueId;
                            return record.__uniqueId;
                        }}
                        scroll={{ x: "max-content", y:  height - 184 }}
                    />
                </div>
            )}

            {viewType === 'foreign' && (
                <div className="p-2">
                    <div className="text-sm font-bold mb-1">{t('Giao dịch NĐT Nước ngoài')}</div>
                    <Row>
                        <Col span={8} className="text-sm">
                            <div className="text-gray">{t('KL Mua')}</div>
                            <div className="text-green font-bold">{stockForeignerState?.foreignBuyVolume?.toLocaleString()}</div>
                        </Col>
                        <Col span={8} className="text-sm">
                            <div className="text-gray">{t('KL Bán')}</div>
                            <div className="text-red font-bold">{stockForeignerState?.foreignSellVolume?.toLocaleString()}</div>
                        </Col>
                        <Col span={8} className="text-sm">
                            <div className="text-gray">{t('KL Mua - Bán')}</div>

                            <div className={`font-bold text-${genColorClass(stockForeignerState?.amountForeignVolume)}`}>{stockForeignerState?.amountForeignVolume?.toLocaleString()}</div>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8} className="text-sm">
                            <div className="text-gray">{t('GT Mua')}</div>
                            <div className="text-green font-bold">{formatValue(stockForeignerState?.foreignBuyValue)} tỷ</div>
                        </Col>
                        <Col span={8} className="text-sm">
                            <div className="text-gray">{t('GT Bán')}</div>
                            <div className="text-red font-bold">{formatValue(stockForeignerState?.foreignSellValue)} tỷ</div>
                        </Col>
                        <Col span={8} className="text-sm">
                            <div className="text-gray">{t('GT Mua - Bán')}</div>
                            <div className={`font-bold text-${genColorClass(stockForeignerState?.amountForeignValue)}`}>{formatValue(stockForeignerState?.amountForeignValue)} tỷ</div>
                        </Col>
                    </Row>
                </div>
            )}
        </Card>
    )
}, (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
});

export default SalesInfo;
