import React, { useEffect } from "react";
import { Form, Input, Button } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

const WatchListForm = (props: any) => {

    const { mode } = props;
    const [ watchListForm ] = Form.useForm();

    const onFinish = (formValue) => {
        props.onFinish(formValue);
        watchListForm.resetFields();
    }

    useEffect(() => {
        watchListForm.resetFields();
    }, [props.initialValues]);  // eslint-disable-line

    return (
        <Form
            form={watchListForm}
            name="watch-list-form"
            initialValues={props.initialValues}
            layout={mode ==='add' ? "inline" : "vertical"}
            onFinish={onFinish}
        >
            <Form.Item name="name" style={{marginRight: 5}} rules={[{ required: true, message: 'Trường trên không được bỏ trống' }]}>
                <Input placeholder={mode ==='add' ? "Tạo danh mục mới" : "Nhập tên danh mục"}/>
            </Form.Item>

            {mode === 'add' ?
                <Button htmlType="submit"><PlusOutlined /></Button>
                :
                <Form.Item>
                    <Button type="primary" htmlType="submit">Cập nhật</Button>
                </Form.Item>
            }
        </Form>
    )
}

export default WatchListForm;