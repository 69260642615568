import { useReducer } from "react";
import moment from "moment";
import _ from "lodash";
import theme from "../themes/theme";
import * as config from "../config";

export const roundedNumber = (value: number, maximumFractionDigits: number) => {
    return Number(value).toLocaleString(config.LOCALE_NUMBER_FORMAT, { maximumFractionDigits });
};

export const formatNumberWithComma = (volumeNumber: number, ratio = config.VOLUME_RATIO) => {
    if (_.isNaN(volumeNumber) || _.isNull(volumeNumber) || _.isUndefined(volumeNumber)) return "";

    if (Number(volumeNumber) === 0) {
        return "";
    }

    let formattedNumber = volumeNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // let formattedNumber = volumeNumber.toLocaleString()

    // if (ratio !== config.VOLUME_RATIO) {
    //     return formattedNumber;
    // }

    // ratio = 10
    // if (formattedNumber.length > 1) {
    //     return formattedNumber.slice(0, -1)
    // }

    return formattedNumber
};

export const convertTimestampToDateTime = (value: number) => {
    return moment(value).utc().format("DD/MM/YYYY h:mm a")
};

export const convertStringToDateTime = (value: string) => {
    return moment(value).format("HH:mm:ss")
};

export const toRoute = (baseRoute, params) => {
    let route = baseRoute;
    if (params) {
        Object.keys(params).forEach(k => {
            const value = params[k];
            route = route.replace(':' + k, value);
        });
    }
    return route;
};

export const useReducedState = (initialState) => {
    const reducer = (prev, updated) => ({...prev, ...updated});
    return useReducer(reducer, initialState);
};

export const getStockColorClass = (stock: NSApp.Stock, themeMode: string, valueCompare?: number) => {
    if (!stock) return themeMode === 'dark' ? theme.colors.white : theme.colors.black;
    let { ceilingPrice, floorPrice, matchedPrice, referencePrice } = stock;

    valueCompare = valueCompare || matchedPrice;

    if (stock.matchedPrice === 0 && stock.volume === 0) {
        return theme.stockColors.keep[themeMode];
    }

    if (valueCompare < referencePrice) {
        if (valueCompare === floorPrice) return theme.stockColors.floor[themeMode];
        return theme.stockColors.reduced[themeMode];
    } else if (valueCompare > referencePrice) {
        if (valueCompare === ceilingPrice) return theme.stockColors.ceiling[themeMode];
        return theme.stockColors.increase[themeMode];
    }

    return theme.stockColors.keep[themeMode];
};

export const getColorStatus = (refValue: number, valueCompare: number, themeMode) => {
    if (valueCompare < refValue) {
        return theme.stockColors.reduced[themeMode];
    } else if (valueCompare > refValue) {
        return theme.stockColors.increase[themeMode];
    }

    return theme.stockColors.keep[themeMode];
}

export const getAnimationClass = (stockInfo: NSApp.Stock, value: number) => {
    let { ceilingPrice, floorPrice, referencePrice } = stockInfo;

    if (value < referencePrice) {
        if (value === floorPrice) return "animation-floor";
        return "animation-reduced";
    } else if (value > referencePrice) {
        if (value === ceilingPrice) return "animation-ceiling";
        return "animation-increase";
    } else {
        return "animation-keep";
    }
};

export const getAnimation = (prevNetGain: number | undefined, currentNetGain: number) => {
    if (!prevNetGain) {
        return "";
    }

    if (currentNetGain > prevNetGain) {
        return "animation-increase";
    } else if (currentNetGain < prevNetGain) {
        return "animation-reduced";
    } else {
        return "animation-keep";
    }
};

export const getChartColor = (stock: NSApp.Stock, themeMode, valueCompare?: number) => {
    if (!stock) return themeMode === 'dark' ? theme.colors.black : theme.colors.white;
    let { ceilingPrice, floorPrice, matchedPrice, referencePrice } = stock;

    valueCompare = valueCompare || matchedPrice;

    if (valueCompare < referencePrice) {
        if (valueCompare === floorPrice) return theme.colors.teal.background;
        return theme.colors.red.background;
    } else if (valueCompare > referencePrice) {
        if (valueCompare === ceilingPrice) return theme.colors.purple.background;
        return theme.colors.green.background;
    }

    return theme.colors.yellow.background;
};

export const getTableRowClass = (record: NSApp.Stock, hoveredStockSymbol) => {
    let { stockCode, ceilingPrice, floorPrice, matchedPrice, referencePrice } = record;

    if (hoveredStockSymbol === stockCode) {
        if (matchedPrice < referencePrice) {
            if (matchedPrice === floorPrice) return 'specific-row floor-row';
            return 'specific-row reduced-row';
        } else if (matchedPrice > referencePrice) {
            if (matchedPrice === ceilingPrice) return 'specific-row ceiling-row';
            return 'specific-row increase-row';
        }
        return 'specific-row keep-row';
    } else return '';
};

export const localeStringTwoDigits = (value: number) => {
    return value.toLocaleString(config.LOCALE_NUMBER_FORMAT, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

export const getStockColor = (stock: NSApp.Stock) => {
    const { matchedPrice, ceilingPrice, floorPrice, referencePrice } = stock;

    if (matchedPrice < referencePrice) {
        if (matchedPrice === floorPrice) {
            return 0;
        }
        return 1;
    }

    if (matchedPrice > referencePrice) {
        if (matchedPrice === ceilingPrice) {
            return 4;
        }
        return 3;
    }

    return 2;
};

export const compactNumberFormat = (value: number, isAxis?: boolean) => {
    let coefficient = 1;
    let unit = '';
    if (Math.abs(value) >= 1000) {
        coefficient = 1000;
        unit = 'K';
    }
    if (Math.abs(value) >= 1000000) {
        coefficient = 1000000;
        unit = 'M';
    }
    if (Math.abs(value) >= 1000000000) {
        coefficient = 1000000000;
        unit = 'B';
    }

    if (isAxis) {
        return `${Math.round(value / coefficient)}${unit}`;
    }

    return `${(value / coefficient).toLocaleString(config.LOCALE_NUMBER_FORMAT, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}${unit}`;
};

export const genChartPaddingBottom = (data, key) => {
    let marginBottom = 0;

    if (data.length) {
        const maxValue = Math.max(...data.map(e => e[key]));
        const maxAbs = Math.max(...data.map(e => Math.abs(e[key])));

        if (maxAbs > maxValue) {
            marginBottom = 40;
        }
    }

    return marginBottom;
}

export const genPeriod = ({ value, type, total = 4, period = 1 }) => {
    const periods: string[] = [];
    let momentType = {
        day: { subtract: 'days', diff: 'days', format: 'DD/MM/YYYY', add: 'day' },
        month: { subtract: 'months', diff: 'months', format: 'M/YYYY', add: 'month' },
        quarter: { subtract: 'quarters', diff: 'quarters', format: 'Q/YYYY', add: 'quarter' },
        year: { subtract: 'years', diff: 'years', format: 'YYYY', add: 'year' }
    };

    const end = moment(value, momentType[type].format);
    const start = moment(end).subtract((total - 1) * period, momentType[type].subtract);

    while (end.diff(start, momentType[type].diff) >= 0) {
        periods.push(start.format(momentType[type].format));
        start.add(period, momentType[type].add);
    }

    return periods;
}