import React, { useState } from "react";
import { Tabs, Tag, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useAuthState } from "../../../../../../../../contexts";
import themeModeGlobalState from "../../../../../../../../contexts/ThemeMode";
import * as config from "../../../../../../../../config";
import theme from "../../../../../../../../themes/theme";

import VolumeByStockPriceChart from "./VolumeByStockPriceChart";
import Info from "./Info";
import WatchList from './WatchList';
import TechnicalAnalysis from "./TechnicalAnalysis";
import Indices from "../../../Indices";
import localStorage from "../../../../../../../../services/local-storage";

type TransactionInfoProps = {
    id: string;
    symbol: string;
    companyProfile: any;
    stockType: string;
    glContainer: any;
    layoutWidth: number;
    layoutHeight: number;
    tradingViewEle: any;
    onChange?: (symbol:string) => void;
}

const TransactionInfo = (props: TransactionInfoProps) => {
    let { id, symbol, stockType, tradingViewEle, companyProfile, layoutWidth, layoutHeight } = props;
    const { authState } = useAuthState() as any;
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const _activeTab = localStorage.get(`${id}_transaction_info_active_tab`);
    const [activeTab, setActiveTab] = useState(_activeTab || "transaction_info_analytics");

    const onChangeTab = (tabKey: string) => {
        setActiveTab(tabKey);
        localStorage.set(`${id}_transaction_info_active_tab`, tabKey);
    }

    let column = 1;
    if (layoutWidth > config.RESPONSIVE_SCREEN.md) {
        column = 2;
    }
    if (layoutWidth > config.RESPONSIVE_SCREEN.lg) {
        column = 3;
    }
    if (layoutWidth > config.RESPONSIVE_SCREEN.xxl) {
        column = 4;
    }
    if (layoutWidth > config.RESPONSIVE_SCREEN.xxxl) {
        column = 6;
    }
    let childComponentWidth = (layoutWidth - 12) / column - 40;
    let childComponentHeight = (layoutHeight - 5) / 3 - 15;
    let childFullHeight = layoutHeight - 45;

    const onStockSymbolClick = (stockCode) => {
        if (tradingViewEle && tradingViewEle.current) {
            tradingViewEle.current.setSymbol(stockCode);
        }
        onChangeTab('transaction_info_watchlist');
    }

    const renderTag = (feature) => {
        if (authState?.currentUser?.userACL?.features.includes(feature)) {
            return <></>;
        }
        return <Tag className="ml-1" color="orange"><strong>{t('Pro')}</strong></Tag>;
    }

    return (
        <Tabs
            className="vertical-tabs"
            size="middle"
            type="card"
            tabPosition="right"
            moreIcon={false}
            activeKey={activeTab}
            onChange={(activeKey) => onChangeTab(activeKey)}
        >
            <Tabs.TabPane tab={t('Phân tích')} key="transaction_info_analytics">
                <TechnicalAnalysis
                    widgetSymbol={companyProfile?.exchange ? `${companyProfile?.exchange.trim()}:${symbol}` : symbol}
                    symbol={symbol}
                    width={childComponentWidth}
                    height={childComponentHeight}
                    stockType={stockType}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('Cung cầu')} key="transaction_info_supply_demand_tab">
                <Info
                    key={`info-${symbol}`}
                    width={childComponentWidth}
                    height={childFullHeight}
                    stockSymbol={symbol}
                    stockType={stockType}
                />
            </Tabs.TabPane>

            <Tabs.TabPane tab={t('Khớp lệnh theo giá')} key="transaction_info_matched_order_by_price">
                <VolumeByStockPriceChart
                    width={childComponentWidth - 20}
                    height={childFullHeight}
                    priceRatio={stockType === 'derivative' ? 1: config.PRICE_RATIO}
                    symbol={symbol}
                />
            </Tabs.TabPane>

            <Tabs.TabPane tab={t('Danh mục')} key="transaction_info_watchlist">
                <Card
                    bordered={false}
                    style={{ height: childFullHeight, background: theme.colors.chartBackground[themeMode] }}
                    bodyStyle={{ padding: 4 }}
                >
                    {/* {stockType === 'stock' && */}
                        <WatchList height={childFullHeight} onStockSymbolClick={(stockCode) => onStockSymbolClick(stockCode)} />
                    {/* } */}

                    {/* {['derivative', 'warrant'].includes(stockType) &&
                        <StockListByTypeTable
                            stockType={stockType}
                            height={childFullHeight}
                            onStockSymbolClick={(stockCode) => onStockSymbolClick(stockCode)}
                        />
                    } */}
                </Card>
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={<span>{t('Chỉ số')} {renderTag('indices')}</span>}
                key="transaction_info_indices"
            >
                <Indices height={childFullHeight} />
            </Tabs.TabPane>
        </Tabs>
    )
};

export default TransactionInfo;
