import React, { useState } from "react";
import { Table, Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import { CloseOutlined } from "@ant-design/icons";

import { useContextSelector } from 'use-context-selector';
import { useTranslation } from "react-i18next";

import { localeFormat, priceFormat, priceChangePercentFormat } from "../../../../../../../services/stock-price";
import { StockContext } from '../../../../../../../contexts/StocksProvider';
import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import { getStockColorClass } from "../../../../../../../services/utils";

import NameWithChartIcon from "../../../../components/NameWithChartIcon";
import EtfStockList from "../../StockDetail/components/EtfStockList";

const PADDING = 40;
const WIDTH = window.innerWidth - (PADDING * 2);
const HEIGHT = window.innerHeight - (PADDING * 2);

const EtfStockListModal = (props: {etfCode: string, isVisible: boolean, setVisible: any}) => {

    const { etfCode, isVisible, setVisible } = props;

    return (
        <Modal
            visible={isVisible}
            footer={null}
            destroyOnClose={true}
            width={WIDTH}
            bodyStyle={{ padding: 4 }}
            maskStyle={{ backgroundColor: 'rgba(15, 15, 15, 0.8)' }}
            style={{ top: PADDING }}
            closeIcon={<CloseOutlined style={{ fontSize: 20 }} />}
            onCancel={() => setVisible(false)}
        >
            <EtfStockList
                etfCode={etfCode}
                layoutHeight={HEIGHT}
            />
        </Modal>
    )
}

const CellValueHasColor = ({ value, stock, columnKey }) => {
    const [ etfStockListVisible, setEtfListVisible ] = useState(false);
    const [themeMode,] = themeModeGlobalState();
    const stockState = useContextSelector(
        StockContext,
        (s) => s[0][stock.stockCode],
    );

    const _stockData = stockState ? stockState : stock;

    if (columnKey === 'stockCode') {
        return <>
            <NameWithChartIcon
                color={getStockColorClass(_stockData, themeMode)}
                symbol={_stockData.stockCode.toUpperCase()}
                onClick={() => { setEtfListVisible(true) }}
            />
            <EtfStockListModal etfCode={_stockData.stockCode} isVisible={etfStockListVisible} setVisible={setEtfListVisible} />
        </>
    }

    if (columnKey === 'matchedPrice') {
        return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>
            {stock.status === 'listed' ? priceFormat(_stockData.matchedPrice, 1000, 2, true): 'OTC'}
        </span>
    }

    if (columnKey === 'pricePercentChange') {
        return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>
            {stock.status === 'listed' ? <>
                {_stockData.priceChange > 0 && '+'}
                {`${priceChangePercentFormat(_stockData.pricePercentChange)}%`}
            </>: 'OTC'}
        </span>
    }

    return <span style={{ color: getStockColorClass(_stockData, themeMode), fontWeight: 'bold' }}>{localeFormat(value)}</span>
};

const EtfListTable = (props) => {
    const { stocks, isLoading, layoutHeight, criteriaExpanded } = props;
    const _criteriaHeight = criteriaExpanded ? 421 : 144;
    const tableContentHeight = layoutHeight - _criteriaHeight > 300 ? layoutHeight - _criteriaHeight : 300;
    const { t } = useTranslation();


    const renderNormalValue = (value) => {
        return <span className="font-bold">{localeFormat(value)}</span>;
    }

    const renderPercentFormat = (value) => {
        return <span className="font-bold">{priceChangePercentFormat(value)}%</span>;
    }

    const renderColorText = (value, stock, columnKey) => {
        return <CellValueHasColor value={value} stock={stock} columnKey={columnKey} />
    }

    let columns: ColumnsType<NSApp.StockBreakout> = [
        {
            width: 50,
            title: t('Mã CK'),
            key: 'stockCode',
            dataIndex: 'stockCode',
            showSorterTooltip: false,
            fixed: 'left',
            render: (value, stock) => renderColorText(value, stock, 'stockCode'),
        },
        {
            width: 30,
            title: t('Giá'),
            key: 'matchedPrice',
            dataIndex: 'matchedPrice',
            showSorterTooltip: false,
            align: 'right',
            render: (value, stock) => renderColorText(value, stock, 'matchedPrice'),
        },
        {
            width: 30,
            title: '+/-(%)',
            key: 'pricePercentChange',
            dataIndex: 'pricePercentChange',
            sorter: (a: NSApp.StockBreakout, b: NSApp.StockBreakout) => a.pricePercentChange - b.pricePercentChange,
            showSorterTooltip: false,
            align: 'right',
            render: (value, stock) => renderColorText(value, stock, 'pricePercentChange'),
        },
        {
            width: 50,
            title: t('Tổng giá trị cổ phiếu'),
            key: 'totalShareValue',
            dataIndex: 'totalShareValue',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('Tiền, tương dương tiền'),
            key: 'totalCash',
            dataIndex: 'totalCash',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('Tài sản khác'),
            key: 'totalOtherAssets',
            dataIndex: 'totalOtherAssets',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 50,
            title: t('Tổng giá trị danh mục'),
            key: 'totalPortfolioValue',
            dataIndex: 'totalPortfolioValue',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderNormalValue(value),
        },
        {
            width: 30,
            title: t('% tổng giá trị cổ phiếu'),
            key: 'ratioShare',
            dataIndex: 'ratioShare',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 30,
            title: t('% tiền, tương đương tiền'),
            key: 'ratioCash',
            dataIndex: 'ratioCash',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
        {
            width: 30,
            title: t('% tài sản khác'),
            key: 'ratioOtherAssets',
            dataIndex: 'ratioOtherAssets',
            showSorterTooltip: false,
            align: 'right',
            render: (value) => renderPercentFormat(value),
        },
    ];

    return <div>
        <Table
            {...props.tableProps}
            bordered
            className="etf-list-table"
            priceRatio={props.priceRatio}
            volumeRatio={props.volumeRatio}
            scroll={{ y: tableContentHeight }}
            rowKey={(record: NSApp.StockBreakout) => record.stockCode}
            columns={columns}
            dataSource={stocks}
            pagination={false}
            layoutHeight={tableContentHeight}
            loading={isLoading}
            // footer={() => <small className="text-one-line">{t('Giá')} x 1,000 {t('VNĐ')}. {t('Khối lượng')} x 1 {t('CP')}. {t('Vốn hoá')} x 1 {t('Tỷ VNĐ')}</small>}
        />
    </div>
};

export default EtfListTable;