import { createGlobalState } from 'react-use';

type StockDetailModalState = {
    stockCode: string;
    isVisible: boolean;
}

const useStockDetailModalState = createGlobalState<StockDetailModalState>({
     stockCode: 'hpg',
     isVisible: false,
});

export default useStockDetailModalState;
