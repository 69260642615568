import api from '../../../../../services/api';

export const fetchWatchlist = async () => {
    // eslint-disable-next-line
    let [error, response]: any = await api.get('watch-list');

    if (error) return;

    return response.data;
};

export const getStocksWatchListDetail = async (id: string) => {
    // eslint-disable-next-line
    let [error, response]: any = await api.get(`watch-list/${id}`);

    if (error) return;

    return response.data;
};

export const addWatchList = async (name: string) => {
    let [error, response]: any = await api.post('watch-list/add', { name });

    if (error) {
        return [error?.response?.data?.message, null];
    }

    return [null, response.data];
};

export const updateWatchList = async (watchList: NSApp.Watchlist, newName: string) => {
    // eslint-disable-next-line
    let [error, response]: any = await api.put(`watch-list/${watchList.id}`, newName);

    if (error) {
        return [error?.response?.data?.message, null];
    }

    return [null, response.data];
}

export const deleteWatchList = async (id: string) => {
    let [error, response]: any = await api.delete(`watch-list/${id}`);

    if (error) {
        return [error?.response?.data?.message, null];
    }

    return [null, response.data];
};

export const updateStockToWatchList = async (id: string, stockCode: string, action: string) => {
    let [error, response]: any = await api.patch(`watch-list/${id}/edit-stocks`, {code: stockCode, action});

    if (error) {
        return [error?.response?.data?.message, null];
    }

    return [null, response.data];
}