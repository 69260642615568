import React from "react";
import { Form, Button, Input, Card, Modal, message, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import MyBreadcrumd from "../../../../../components/MyBreadcrumb";
import api from "../../../../../services/api";
import DraftEditor from "../../../../../components/DraftEditor";
const { Option } = Select;

const { TextArea } = Input;
const { confirm } = Modal;

const AddNotificationSampleForm = () => {
	const addNotification = async (formValues: any) => {
		const [err]: any = await api.post(
			"/notification/add-notification-sample",
			formValues
		);

		if (err) {
			message.error(err?.response?.data?.message);
			return;
		}

		message.success("Thêm mẫu thông báo thành công");
	};

	const showConfirmAddNotifSample = (formValues: any) => {
		confirm({
			title: "Bạn có chắc chắn muốn thêm mẫu thông báo này không?",
			icon: <ExclamationCircleOutlined />,
			content: formValues.title,
			onOk: async () => {
				await addNotification(formValues);
			},
			onCancel() {
				console.log("Cancel");
			},
		});
	};

	return (
		<Card>
			<MyBreadcrumd
				items={[
					{ label: "Mẫu thông báo" },
					{ label: "Thêm mẫu thông báo" },
				]}
			/>

			<div className="m-auto mt-5 w-3/6">
				<Form
					layout="vertical"
					name="notification_sample_form"
					className="notification-sample-form"
					validateMessages={{
						// eslint-disable-next-line no-template-curly-in-string
						required: "${label} không được để trống",
					}}
					onFinish={showConfirmAddNotifSample}
				>
					<Form.Item
						name="title"
						label="Tiêu đề"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="Tiêu đề" />
					</Form.Item>

					<Form.Item
						name="description"
						label="Mô tả"
						rules={[
							{
								required: true,
							},
						]}
					>
						<TextArea placeholder="Mô tả" rows={3} />
					</Form.Item>

					<Form.Item name="platform" label="Nền tảng">
						<Select defaultValue={"all"} style={{ width: 150 }}>
							<Option value="all">Tất cả</Option>
							<Option value="web">Web</Option>
							<Option value="email">Email</Option>
							<Option value="app">App</Option>
						</Select>
					</Form.Item>

					<Form.Item
						label="Nội dung"
						name="content"
						rules={[
							{
								required: true,
							},
						]}
					>
						<DraftEditor />
					</Form.Item>

					<Form.Item>
						<Button
							className="mb-2"
							type="primary"
							htmlType="submit"
							block
						>
							Thêm mới mẫu
						</Button>
					</Form.Item>
				</Form>
			</div>
		</Card>
	);
};

export default AddNotificationSampleForm;
