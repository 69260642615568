import React from 'react';
import { useAuthState } from "../contexts";

const AdminPermission = ({ children, roleName, ...props }) => {
    const { authState } = useAuthState() as any;
    const styles = { ...props.style };
    const currentRole = authState?.currentUser[`role.name`];

    if (authState?.currentUser?.isSupervisor) {
        return <div style={styles}>{children}</div>;
    }

    if (!roleName?.includes(currentRole)) {
        return (
            <div className="flex flex-col justify-center items-center h-full">
                Bạn không có quyền truy cập vào mục này!
            </div>
        );
    }

    return <div style={styles}>{children}</div>;
};

export default AdminPermission;