import React, { useCallback, useEffect, useState, useRef } from 'react';
import { DatePicker, Space, Select } from 'antd';
import moment from 'moment';
import { useUnmount } from 'react-use';
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";

import themeModeGlobalState from "../../../../../../../contexts/ThemeMode";
import useStockDetailModalState from "../../../../../../../contexts/StockDetailModal";
import * as ForeignTradingServices from '../services';
import { localeFormat } from "../../../../../../../services/stock-price";
import { localeStringTwoDigits } from "../../../../../../../services/utils";
import theme from "../../../../../../../themes/theme";
import { DATE_TIME_FORMAT, RESPONSIVE_SCREEN } from '../../../../../../../config';
import Loading from "../../../../../../../components/Loading";

const HistoryStocks = (props) => {
    const { exchange, height, width } = props;
    const [historyTradingData, setHistoryTradingData] = useState([]);
    const [sortBy, setSortBy] = useState('desc');
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [loading, setLoading] = useState(true);
    const [, setStockDetailModalState] = useStockDetailModalState();
    const { t } = useTranslation();
    const [themeMode,] = themeModeGlobalState();

    const isDarkMode = themeMode === 'dark';
    const intervalRef = useRef<any>(null);
    const valueRatio = 1000 * 1000 * 1000;
    const componentHeight = height - 65;

    const fetchHistoryTradingData = useCallback(async () => {
        setLoading(true);
        const response: any = await ForeignTradingServices.fetchHistoryStocks(
            exchange,
            sortBy,
            moment(startDate).format(DATE_TIME_FORMAT.DATE),
            moment(endDate).format(DATE_TIME_FORMAT.DATE)
        );

        if (response) {
            const _data = formatHeatMapData(response.data);
            setHistoryTradingData(_data);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchange, sortBy, startDate, endDate]);

    const formatHeatMapData = (data: any) => {
        let returnArr: any = [];

        for (let stock of data) {
            const { total_amount_foreign_value, total_foreign_buy_value, total_foreign_sell_value } = stock;

            // ban rong
            if (sortBy === 'asc' &&  total_amount_foreign_value > 0) {
                continue;
            }

            // mua rong
            if (sortBy === 'desc' &&  total_amount_foreign_value < 0) {
                continue;
            }

            if (total_amount_foreign_value || total_foreign_buy_value || total_foreign_sell_value) {
                stock.name = stock.stockCode.toUpperCase();
                const _total_amount_foreign_value = stock.total_amount_foreign_value !== null ? localeStringTwoDigits(stock.total_amount_foreign_value / valueRatio) : '';
                const _value = sortBy === 'asc' ? Math.abs(stock.total_amount_foreign_value) : stock.total_amount_foreign_value;
                stock.value = [_value, _total_amount_foreign_value];

                returnArr.push(stock);
            }
        }

        return returnArr;
    };

    const fetchInterval = useCallback(async () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(async () => {
            await fetchHistoryTradingData();
        }, 60 * 1000);
    }, [fetchHistoryTradingData]);

    useEffect(() => {
        fetchHistoryTradingData();
        fetchInterval();
    }, [fetchHistoryTradingData, fetchInterval]);

    useUnmount(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = undefined;
        }
    });

    const options = {
        series: [
            {
                type: 'treemap',
                width: '100%',
                height: '100%',
                visualMin: 0,
                visualMax: 4,
                visualDimension: 2,
                roam: false,
                nodeClick: false,
                data: historyTradingData,
                breadcrumb: {
                    show: false,
                },
                upperLabel: {
                    formatter: '{b}',
                },
                label: {
                    formatter: (params: any) => {
                        const newLineChar = String.fromCharCode(10);
                        return `${params.name}${newLineChar}${params.value[1]}`;
                    },
                    fontWeight: 'bold',
                },
                labelLayout(params: any) {
                    const fontSizeByWidth = params.rect.width / 6;
                    const fontSizeByHeight = params.rect.height / 5;
                    const fontSize =
                        fontSizeByWidth < fontSizeByHeight
                            ? fontSizeByWidth
                            : fontSizeByHeight;
                    return {
                        fontSize: fontSize,
                    };
                },
                levels: [
                    {
                        color: [theme.colors.orange.light],
                        itemStyle: {
                            borderColor: isDarkMode ? theme.colors.black : theme.colors.white,
                            borderWidth: 1,
                            gapWidth: 0.5,
                        },
                        upperLabel: {
                            show: false,
                        },
                    },
                    {
                        itemStyle: {
                            borderWidth: 2,
                            gapWidth: 1,
                            borderColorSaturation: 0.7,
                        },
                    },
                ],
            },
        ],
        tooltip: {
            backgroundColor: isDarkMode ? theme.colors.componentBackground.dark : theme.colors.chartBackground.light,
            borderWidth: 0,
            padding: 0,
            formatter: function (params) {
                const { name, company_profile, total_amount_foreign_value, total_foreign_buy_value, total_foreign_sell_value, total_amount_foreign_volume, total_foreign_buy_volume, total_foreign_sell_volume } = params.data;

                if (!name) {
                    return <></>;
                }

                const backgroundColor = isDarkMode ? theme.colors.black : theme.colors.white;
                const textColor = isDarkMode ? theme.colors.white : theme.colors.black;
                const borderColor = isDarkMode ? theme.colors.customTabBackground.dark : theme.colors.darkGray;

                return '<div className="tooltip">'
                    + `<div style="padding: 5px 10px; white-space: normal; background: ${backgroundColor}; border-top-left-radius: 4px; border-top-right-radius: 4px;border-bottom: 0.2px solid ${borderColor}">`
                        + `<div style="width: 280px; color: ${textColor}"><span style="font-weight: bold">${name}</span>${company_profile.name ? ' - ' + company_profile.name : ''}</div>`
                    + '</div>'
                    + `<div style="display: flex; padding: 4px 10px;">`
                        + `<div style="width: 100px; color: ${textColor}">${t('Giá trị mua')}</div>`
                        + `<div style="width: 180px; color: ${textColor}; text-align: right; font-weight: bold">` + localeFormat(total_foreign_buy_value / valueRatio) + ` ${t('Tỷ')}</div>`
                    + '</div>'
                    + `<div style="display: flex; padding: 4px 10px;">`
                        + `<div style="width: 100px; color: ${textColor}">${t('Giá trị bán')}</div>`
                        + `<div style="width: 180px; color: ${textColor}; text-align: right; font-weight: bold">` + localeFormat(total_foreign_sell_value / valueRatio) + ` ${t('Tỷ')}</div>`
                    + '</div>'
                    + `<div style="display: flex; padding: 4px 10px;">`
                        + `<div style="width: 100px; color: ${textColor}">${t('Giá trị ròng')}</div>`
                        + `<div style="width: 180px; color: ${textColor}; text-align: right; font-weight: bold">` + localeFormat(total_amount_foreign_value / valueRatio) + ` ${t('Tỷ')}</div>`
                    + '</div>'
                    + `<div style="display: flex; padding: 4px 10px;">`
                        + `<div style="width: 100px; color: ${textColor}">${t('Khối lượng mua')}</div>`
                        + `<div style="width: 180px; color: ${textColor}; text-align: right; font-weight: bold">` + localeFormat(total_foreign_buy_volume) + ` ${t('CP')}</div>`
                    + '</div>'
                    + `<div style="display: flex; padding: 4px 10px;">`
                        + `<div style="width: 100px; color: ${textColor}">${t('Khối lượng bán')}</div>`
                        + `<div style="width: 180px; color: ${textColor}; text-align: right; font-weight: bold">` + localeFormat(total_foreign_sell_volume) + ` ${t('CP')}</div>`
                    + '</div>'
                    + `<div style="display: flex; padding: 4px 10px;">`
                        + `<div style="width: 100px; color: ${textColor}">${t('Khối lượng ròng')}</div>`
                        + `<div style="width: 180px; color: ${textColor}; text-align: right; font-weight: bold">` + localeFormat(total_amount_foreign_volume) + ` ${t('CP')}</div>`
                    + '</div>'
                + '</div>';
            },
        },
    };

    const openStockDetailModal = useCallback((params) => {
        if (!params.data.stockCode) {
            return;
        }
        setStockDetailModalState({
            stockCode: params.data.stockCode,
            isVisible: true
        })
    }, [setStockDetailModalState]);

    const onEvents = {
        'click': openStockDetailModal,
    }

    const onChangeStartDate = (date) => {
        setStartDate(date);
    }

    const onChangeEndDate = (date) => {
        setEndDate(date);
    }

    const onChangeSort = (value) => {
        setSortBy(value);
    };

    return (
        <div>
            <div className={`${width > RESPONSIVE_SCREEN.lg && 'flex'} justify-between items-center mb-2`}>
                <span className="text-base font-bold">{t('Giao dịch mua - bán ròng theo cổ phiếu')} ({t('Tỷ VNĐ')})</span>
                <Space>
                    <span className="text-gray">{t('Giá trị')}</span>
                    <Select
                        size="middle"
                        value={sortBy}
                        style={{ width: 120 }}
                        onChange={onChangeSort}
                        options={[
                            { value: 'desc', label: t('Mua ròng') },
                            { value: 'asc', label: t('Bán ròng') },
                        ]}
                    />
                    <span className="text-gray">{t('Từ')}</span>
                    <DatePicker
                        size="middle"
                        format={DATE_TIME_FORMAT.DATE}
                        placeholder={t('Từ ngày')}
                        allowClear={false}
                        value={startDate}
                        onChange={onChangeStartDate}
                        style={{ width: 120 }}
                        disabledDate={current => current && current > moment(endDate).endOf('day')}
                    />
                    <span className="text-gray">{t('Đến')}</span>
                    <DatePicker
                        size="middle"
                        format={DATE_TIME_FORMAT.DATE}
                        placeholder={t('Đến ngày')}
                        allowClear={false}
                        value={endDate}
                        onChange={onChangeEndDate}
                        style={{ width: 120 }}
                        disabledDate={current => current && (current < moment(startDate).endOf('day').subtract(1, 'days') || current > moment().endOf('day'))}
                    />
                </Space>
            </div>
            {loading ? (
                <div style={{height: componentHeight}}><Loading/></div>
            ) : (
                <>
                    {!historyTradingData.length ? (
                        <div style={{height: componentHeight}}>{t('Không có dữ liệu')}</div>
                    ) : (
                        <ReactECharts
                            option={options}
                            onEvents={onEvents}
                            style={{
                                width: width,
                                height: componentHeight
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default HistoryStocks;