import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

export const StockForeignerContext = createContext<any>(null);

const StocksForeignerProvider = ({ children }: { children: any }) => {
    const [state, setState] = useState({});

    return (
        <StockForeignerContext.Provider value={[state, setState]}>
            {children}
        </StockForeignerContext.Provider>
    );
};

export default StocksForeignerProvider;