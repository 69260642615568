import React, { useState } from 'react';
import { Layout, Avatar, Menu } from 'antd';
import {
    StarOutlined, FundOutlined, InfoCircleOutlined,
    BarChartOutlined, PieChartOutlined, FileTextOutlined, ContactsOutlined, EditOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDashboardState, useLayoutState } from '../../../../contexts';
import themeModeGlobalState from "../../../../contexts/ThemeMode";
import FLogo from "../../../../assets/image/logo/F.svg";
import LogoWhite from "../../../../assets/image/logo/Fstock_white.svg";
import LogoBlack from "../../../../assets/image/logo/Fstock_black.svg";
import DashboardMenuItem from './DashboardMenuItem';
import routes from '../../../../routes';

const PrivateRouteSider = () => {
    const { t } = useTranslation();
    const { dashboardState } = useDashboardState() as any;
    const { layoutState, setLayoutState } = useLayoutState() as any;
    const { siderCollapsed } = layoutState;
    let { selectedDashboard } = dashboardState;
    const [themeMode,] = themeModeGlobalState();
    const isDarkMode = themeMode === 'dark';
    const logoFull = isDarkMode ? LogoWhite : LogoBlack;

    const [selectedItemKey, setSelectedItemKey] = useState(selectedDashboard);

    const onCollapse = () => {
        setLayoutState({ siderCollapsed: !siderCollapsed })
    };

    return (
        <Layout.Sider
            collapsible
            className="home-sidebar"
            onCollapse={onCollapse}
            collapsed={siderCollapsed}
            collapsedWidth={50}
        >
            <div className="logo">
                <Avatar shape="square" size={siderCollapsed ? 50 : 64} src={logoFull}/>
            </div>

            <Menu
                mode="inline"
                selectedKeys={[selectedItemKey]}
                // defaultOpenKeys={['listDashboard']}
                triggerSubMenuAction="hover"
                onClick={(item) => setSelectedItemKey(item.key)}
            >
                <DashboardMenuItem key="listDashboard"/>

                <Menu.Item key="macro-economic" icon={<PieChartOutlined />}>
                    <Link to={routes.DASHBOARD_MACROECONOMICS}>{t('Kinh tế vĩ mô')}</Link>
                </Menu.Item>

                <Menu.Item key="market-analysis" icon={<BarChartOutlined />}>
                    <Link to={routes.DASHBOARD_MARKET_ANALYSIS}>{t('Phân tích thị trường')}</Link>
                </Menu.Item>

                <Menu.Item key="derivatives" icon={<FundOutlined />}>
                    <Link to={routes.DASHBOARD_DERIVATIVES}>{t('Chứng khoán phái sinh')}</Link>
                </Menu.Item>

                <Menu.Item key="news" icon={<FileTextOutlined />}>
                    <Link to={routes.DASHBOARD_NEWS}>{t('Tin tức tổng hợp')}</Link>
                </Menu.Item>

                <Menu.Item key="article" icon={<EditOutlined />}>
                    <Link to={routes.ARTICLE}>{t('Kiến thức - Kinh nghiệm')}</Link>
                </Menu.Item>

                <Menu.Item key="watchlist" icon={<StarOutlined/>}>
                    <Link to={routes.WATCH_LIST}>{t('Danh mục quan tâm')}</Link>
                </Menu.Item>

                <Menu.Item key="user_manual" icon={<InfoCircleOutlined/>}>
                    {/* <Link to={routes.USER_MANUAL}>{t('Hướng dẫn sử dụng')}</Link> */}
                    <a href={routes.HOW_TO_USE} target='blank'>{t('Hướng dẫn sử dụng')}</a>
                </Menu.Item>

                <Menu.Item key="contact_support" icon={<ContactsOutlined />}>
                    <Link to={routes.CONTACT}>{t('Liên hệ - Hỗ trợ')}</Link>
                </Menu.Item>
            </Menu>
        </Layout.Sider>
    )
};

export default PrivateRouteSider;