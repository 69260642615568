import React, {useState, useEffect } from 'react';
import MyBreadcrumb from "../../../../components/MyBreadcrumb";
import { Col, Row, Button, Upload, Divider, Form, Input, Modal, Card, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import LoadingComponent from '../../../../components/LoadingOverfloat';
// import api from '../../../../services/api';
import * as config from "../../../..//config";
import localStorage from "../../../../services/local-storage";
import api from '../../../../services/api';
import Loading from '../../../../components/Loading';
import AdminPermission from '../../../../components/AdminPermission';

const AdminUploadDataListComponent = () => {
    const [loading, setLoading] = useState(false);
    const [isResultModalOpen, setIsResultModalOpen] = useState<boolean>(false);
    const [fileDataType, setFileDataType] = useState<string>('');
    const [dataForm, setDataForm]: any = useState();

    const [etfResult, setEtfResult] = useState<string[]>([]);
    const [etfErrors, setEtfErrors] = useState<string[]>([]);

    const getUploadUrl = (name, type) => {
        const url = new URL(`/upload-data/${name}?type=${type}`, config.API_BASE_URL);
        return url.href
    }

    const getUploadProprietaryDerivativeUrl = () => {
        const url = new URL(`/upload-data/upload-proprietary-derivative`, config.API_BASE_URL);
        return url.href
    }

    const getUploadEtfData = () => {
        setFileDataType('etfData');
        const url = new URL(`/upload-data/upload-etf-data`, config.API_BASE_URL);
        return url.href
    }

    const accessToken = localStorage.get('accessToken');

    const props: UploadProps = {
        name: 'file',
        headers: {
            authorization: `Bearer ${accessToken}`,
        },
        onChange(info) {
            if (info.file.status === 'uploading') {
                setLoading(true);
            } else {
                setLoading(false);
                if (info.file.status === 'done') {
                    const response = info.file.response;
                    if (fileDataType === 'etfData') {
                        console.log('response', response);
                        setEtfResult(response.result);
                        setEtfErrors(response.errors);
                        setIsResultModalOpen(true);
                    }
                    // const newStockList = response;
                    // setStockList(newStockList);
                    // message.success(`Import ${info.file.name} thành công`);
                } else if (info.file.status === 'error') {
                    // const messageStr = info.file.response.message;

                    // message.error(`Import ${info.file.name} thất bại.`);
                    // message.error(messageStr);
                }
            }
        },
    };

    const SubmitForm = async (formValue: any) => {
        let data: any = [];
        for (let key in formValue) {
                data.push({
                    key: key,
                    value: formValue[`${key}`] ? formValue[`${key}`] : ''
                })
        }

        const [err, resp]: any = await api.post('/config/update', data);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        if (resp.data === 'OKE') {
            message.success('Cập nhật thành công');
            return;
        }
    }

    const fetchFormData = async () => {
        const [err, resp]: any = await api.get('/config/parameters');
        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }
        setDataForm(resp.data);
    }

    useEffect(() => {
        fetchFormData();
    }, [])

    if (dataForm === undefined) {
        return <Loading></Loading>
    }

    return <>
        <MyBreadcrumb items={[{ label: 'Upload dữ liệu' }]} />

        {loading && <LoadingComponent />}

        <Row className="mt-8 p-1">
            <Col xs={24} sm={12} md={4}>
                <div className="flex justify-between items-center">
                    <span>GDTD ròng HSX</span>
                    <Upload {...props} action={getUploadUrl("upload-proprietary-trading", "hose")}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>

                <Divider />

                <div className="flex justify-between items-center">
                    <span>GDTD ròng HNX</span>
                    <Upload {...props} action={getUploadUrl("upload-proprietary-trading", "hnx")}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>

                <Divider />

                <div className="flex justify-between items-center">
                    <span>GDTD ròng UPCOM</span>
                    <Upload {...props} action={getUploadUrl("upload-proprietary-trading", "upcom")}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>

                <Divider />

                <div className="flex justify-between items-center">
                    <span>GDTD Cổ phiếu HSX</span>
                    <Upload {...props} action={getUploadUrl("upload-proprietary-stock", "hose")}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>

                <Divider />

                <div className="flex justify-between items-center">
                    <span>GDTD cổ phiếu HNX</span>
                    <Upload {...props} action={getUploadUrl("upload-proprietary-stock", "hnx")}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>

                <Divider />

                <div className="flex justify-between items-center">
                    <span>GDTD cổ phiếu UPCOM</span>
                    <Upload {...props} action={getUploadUrl("upload-proprietary-stock", "upcom")}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>

                <Divider />

                <div className="flex justify-between items-center">
                    <span>GDTD Phái sinh</span>
                    <Upload {...props} action={getUploadProprietaryDerivativeUrl}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>

                <Divider />

                <div className="flex justify-between items-center">
                    <span>ETF Data</span>
                    <Upload {...props} action={getUploadEtfData}>
                        <Button size="large" icon={<UploadOutlined />}>Chọn File</Button>
                    </Upload>
                </div>


                <Divider />

                <AdminPermission roleName={[]}>
                <Form layout="vertical" onFinish={SubmitForm} initialValues={dataForm}>
                    <Form.Item name="TOTAL_ENTERPRISE_BY_TIME" label="Số lượng doanh nghiệp đến tháng: [M/YYYY]">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item name="STOCK_MARKET_SHARE_BY_TIME" label="Thị phần môi giới cổ phiếu và chứng chỉ quỹ đến quý: [Q/YYYY]">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item name="BOND_MARKET_SHARE_BY_TIME" label="Thị phần môi giới trái phiếu đến quý: [Q/YYYY]">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item name="GDP_STRUCTURE" label="GDP Doanh nghiệp đến năm: [YYYY]">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item name="CURRENT_YEAR_CHART_XAXIS_LABEL" label="Năm hiện tại: [YYYY]">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item>
                        <Button size="large" htmlType='submit' type='primary' className='float-right'>Lưu</Button>
                    </Form.Item>
                </Form>
                </AdminPermission>
            </Col>
        </Row>

        <Modal title="Kết quả cập nhật ETF data" visible={isResultModalOpen} footer={null} destroyOnClose={true} closable={true} onCancel={() => setIsResultModalOpen(false)}>
            <Card title="Kết quả" style={{ width: 500, height: 300, overflow: 'scroll' }}>
                {etfResult.map(r => {
                    return <p>{r}</p>
                })}
            </Card>
            <Card title="Lỗi" style={{ width: 500, height: 300, overflow: 'scroll' }}>
                {etfErrors.map(e => {
                    return <p>{e}</p>
                })}
            </Card>
        </Modal>
    </>
};

const AdminUploadDataList = () => {
    return <AdminPermission roleName={['editor', 'moderator']}>
        <AdminUploadDataListComponent />
    </AdminPermission>
}

export default AdminUploadDataList;