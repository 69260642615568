import React from "react";
import { useHistory } from "react-router-dom";
import { Card, message } from "antd";

import MyBreadcrumd from "../../../../components/MyBreadcrumb";
import ArticleForm from "./_Form";
import api from "../../../../services/api";
import routes from "../../../../routes";

const ArticleAddPage = () => {
    const history = useHistory();

    const onFinish = async (formValues) => {
        // eslint-disable-next-line
        let [err, resp]: any = await api.post('plugins/article/add', formValues);

        if (err) {
            message.error(err?.response?.data?.message);
            return;
        }

        message.success("Thêm mới bài viết thành công");
        history.replace(routes.ADMIN_ARTICLE_LIST);
    };

    return (
        <Card>
            <MyBreadcrumd items={[
                { label: 'Bài viết', path: routes.ADMIN_ARTICLE_LIST },
                { label: 'Thêm mới' },
            ]} />

            <div className="m-auto mt-5 w-3/6">
                <ArticleForm mode='add' onFinish={onFinish}/>
            </div>
        </Card>
    )
}

export default ArticleAddPage;