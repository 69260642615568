import React, { useState } from "react";
import { Card, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import TablePriceContainer from '../../TablePrice/container/TablePrice';

type TopStocksTableProps = {
    exchange: string,
    height: number,
};

const TopStockTable = (props: TopStocksTableProps) => {
    const { exchange, height } = props;
    const { t } = useTranslation();

    const defaultColumns: ColumnsType<NSApp.Stock> = [
        {
            title: <div className="text-one-line text-center" title={t('CK')}>{t('CK')}</div>,
            dataIndex: 'stockCode',
            key: 'stockCode',
            // width: 80,
        },
        {
            title: <div className="text-one-line text-center" title={t('Giá')}>{t('Giá')}</div>,
            dataIndex: 'matchedPrice',
            key: 'matchedPrice',
            align: 'right',
        },
        {
            title: <div className="text-one-line text-center" title="+/-">+/-</div>,
            dataIndex: 'priceChange',
            key: 'priceChange',
            align: 'right',
        },
        {
            title: <div className="text-one-line text-center" title="+/-(%)">+/-(%)</div>,
            dataIndex: 'pricePercentChange',
            key: 'pricePercentChange',
            align: 'right',
        },
    ];

    const DEFAULT_SORT_BY: any = {column: 'totalVolume', order: 'desc'};
    const [ sortBy, setSortBy ] = useState<{column: string, order: "asc" | "desc"}>(DEFAULT_SORT_BY)
    const [ columns, setColumns ] = useState(defaultColumns);

    const onChangeFilter = async (sortBy: string = 'totalVolume-desc') => {
        let sortByValues: any = sortBy.split('-');

        let sortByState = {
            column: sortByValues[0],
            order: sortByValues[1]
        };

        setSortBy(sortByState);
        onChangeColumns(sortByState);
    };

    const onChangeColumns = (sortBy) => {
        let tableColumns: any[] = defaultColumns;

        if (sortBy.column === 'totalValue') {
            tableColumns = [
                ...defaultColumns,
                {
                    title: <div className="text-one-line text-center" title={t('GT')}>{t('GT')}</div>,
                    dataIndex: 'totalValue',
                    key: 'totalValue',
                    align: 'right',
                }
            ];
        }

        if (sortBy.column === 'totalVolume') {
            tableColumns = [
                ...defaultColumns,
                {
                    title: <div className="text-one-line text-center" title={t('KL')}>{t('KL')}</div>,
                    dataIndex: 'totalVolume',
                    key: 'totalVolume',
                    align: 'right',
                }
            ];
        }

        // if (sortBy.column === 'priceChange') {
        //     tableColumns = [
        //         ...defaultColumns,
        //         {
        //             title: <div className="text-one-line text-center" title="+/-">+/-</div>,
        //             dataIndex: 'priceChange',
        //             key: 'priceChange',
        //             align: 'right',
        //         }
        //     ]
        // }

        setColumns(tableColumns);
    }

    const renderFilter = () => {
        return (
            <div className="flex justify-between items-center">
                <span className="text-one-line">{t('Top cổ  phiếu')}</span>
                <Select style={{width: 200}} defaultValue={'totalVolume-desc'} size="middle" onChange={onChangeFilter}>
                    <Select.Option value="totalVolume-desc">{t('Khối lượng giao dịch')}</Select.Option>
                    <Select.Option value="totalValue-desc">{t('Giá trị giao dịch')}</Select.Option>
                    <Select.Option value="pricePercentChange-desc">{t('% tăng giá')}</Select.Option>
                    <Select.Option value="pricePercentChange-asc">{t('% giảm giá')}</Select.Option>
                    <Select.Option value="priceChange-desc">{t('Tăng giá')}</Select.Option>
                    <Select.Option value="priceChange-asc">{t('Giảm giá')}</Select.Option>
                </Select>
            </div>
        )
    };

    return (
        <Card
            className="mb-2 overflow-auto top-stock-card"
            bodyStyle={{padding: 0, overflow: 'hidden'}}
            bordered={false}
        >
            <TablePriceContainer
                height={height > 150 ? height + 84 : 235}
                tableProps={{
                    minWidth: 300,
                    className: "table-stock-price",
                    scroll: { y: height },
                    title: renderFilter,
                }}
                columns={columns}
                exchange={exchange}
                sortBy={sortBy}
                stockType={'stock'}
            />
        </Card>
    )
}
export default TopStockTable