import React, { useState } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import * as config from "../../../../config";
import api from "../../../../services/api";

const UploadImage = (props) => {
    const { name, url, defaultImage, disabled } = props;

    const [ imagePreview, setImagePreview ] = useState(defaultImage);
    const [ isLoading, setIsLoading ] = useState(false);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const uploadButton = (
        <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div>
        </div>
    );

    const handleFileChange = (info) => {
        if (info.file.status !== 'uploading') {
            setIsLoading(true);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            getBase64(info.file.originFileObj, imageUrl => {
                setImagePreview(imageUrl);
                setIsLoading(false);
            });
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

        props.onChange(info?.file?.response?.path);
    };

    const uploadProps = {
        name: name,
        className: "avatar-uploader",
        action: `${config.API_BASE_URL}${url}`,
        headers: {
            authorization: `bearer ${api.accessToken}`,
        },
        onChange: handleFileChange,
        showUploadList: false,
        disabled
    };

    return <Upload {...uploadProps} listType='picture-card'>
        {imagePreview
            ? <img src={imagePreview} alt="avatar" style={{ width: '100%' }} />
            : uploadButton
        }
    </Upload>
};

export default UploadImage;